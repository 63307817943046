export const SessionIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      {...props}
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M6.008 9.361a.385.385 0 0 0-.385-.384H4.28a.385.385 0 0 0-.385.384v1.344c0 .213.173.385.385.385h1.344a.385.385 0 0 0 .385-.385V9.361zM9.367 9.361a.385.385 0 0 0-.384-.384H7.638a.385.385 0 0 0-.385.384v1.344c0 .213.173.385.385.385h1.345a.385.385 0 0 0 .384-.385V9.361zM12.726 9.361a.385.385 0 0 0-.385-.384h-1.344a.385.385 0 0 0-.385.384v1.344c0 .213.173.385.385.385h1.344a.385.385 0 0 0 .385-.385V9.361zM6.008 12.72a.385.385 0 0 0-.385-.384H4.28a.385.385 0 0 0-.385.385v1.343c0 .213.173.385.385.385h1.344a.385.385 0 0 0 .385-.385v-1.343zM9.367 12.72a.385.385 0 0 0-.384-.384H7.638a.385.385 0 0 0-.385.385v1.343c0 .213.173.385.385.385h1.345a.385.385 0 0 0 .384-.385v-1.343zM12.726 12.72a.385.385 0 0 0-.384-.384h-1.345a.385.385 0 0 0-.385.385v1.343c0 .213.173.385.385.385h1.345a.385.385 0 0 0 .384-.385v-1.343z" />
        <path d="M15.13 1.89v2.052c0 .928-.753 1.675-1.68 1.675h-1.06c-.928 0-1.69-.747-1.69-1.675v-2.06H5.921v2.06c0 .928-.763 1.675-1.69 1.675h-1.06c-.928 0-1.68-.747-1.68-1.675V1.889A1.528 1.528 0 0 0 .013 3.41v12.03c0 .84.681 1.53 1.521 1.53h13.55a1.53 1.53 0 0 0 1.521-1.53V3.408c0-.824-.666-1.495-1.476-1.52zm-.493 12.8a.658.658 0 0 1-.657.657H2.612a.658.658 0 0 1-.658-.658V8.476c0-.363.295-.658.658-.658H13.98c.363 0 .657.295.657.658v6.213z" />
        <path d="M3.168 4.517h1.048a.576.576 0 0 0 .576-.576V.576A.576.576 0 0 0 4.216 0H3.168a.576.576 0 0 0-.577.576v3.365c0 .318.258.576.577.576zM12.376 4.517h1.048A.576.576 0 0 0 14 3.94V.576A.576.576 0 0 0 13.424 0h-1.048a.576.576 0 0 0-.576.576v3.365c0 .318.258.576.576.576z" />
      </g>
    </svg>
  )
}
