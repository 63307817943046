import React from "react";

export const GetIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    {...props}
  >
    <path
      fill="#000"
      fillRule="nonzero"
      d="M.26 9.74a.87.87 0 0 0 .631.26.87.87 0 0 0 .63-.26L5 6.26l3.478 3.48a.87.87 0 0 0 .63.26.892.892 0 0 0 .63-1.522L6.262 5l3.478-3.478A.892.892 0 0 0 8.48.26L5 3.739 1.522.261A.892.892 0 0 0 .26 1.52L3.739 5 .261 8.478a.892.892 0 0 0 0 1.261z"
    />
  </svg>
);
