import { NewModal } from '@/entities/ui/NewModal/NewModal'
import { ModalsEnum, useModalSelector } from '@/store/modal/ModalStore'
import { create } from 'zustand'
import React, { useEffect } from 'react'
import styles from './RidersImportModal.module.less'
import { InitialStep } from '@/entities/modals/RidersImportModal/components/InitialStep/InitialStep'
import { MappingStep } from '@/entities/modals/RidersImportModal/components/MappingStep/MappingStep'
import { ProcessStep } from '@/entities/modals/RidersImportModal/components/ProcessStep/ProcessStep'
import { useLazyImportCancelQuery } from '@/store/routeTool/routeTool'
import { IImportRidersStore } from '@/entities/modals/RidersImportModal/RidersImportModal.types'
import { useAppDispatch } from '@/redux'
import { getChildren } from '@/redux/children/actions'
import { useImportSocketResponse } from '@/features/containers/SocketConnect/store'

export const baseSetting = {
  fileList: [],
  tryClose: false,
  uploading: false,
  isShowAlert: false,
  delimiter: ',',
  enclosure: '"',
  total: 0,
  escape: '\\',
  importResponse: {
    file: null,
    headers: [],
    mappingForm: null,
    xlsxDefault: null,
  },
}

export const useImportRidersStore = create<IImportRidersStore>((set, get) => ({
  ...baseSetting,
  step: 'initial',
  onDeleteFile: (index: number) => {
    const result = get().fileList.filter((_, idx) => idx !== index)
    set({ fileList: result })
  },
  onAddFile: (file) =>
    set((state) => ({ fileList: [...state.fileList, file] })),
  setImportRidersStore: (params) => {
    set(() => ({ ...get(), ...params }))
  },
}))

export const RidersImportModal = () => {
  const { onCloseModal, isOpen, state } = useModalSelector(
    ModalsEnum.IMPORTS_RIDERS
  )

  return isOpen ? (
    <_RidersImportModal
      sessionId={state?.sessionId || ''}
      isOpen={true}
      onCloseModal={onCloseModal}
    />
  ) : null
}
export const _RidersImportModal = ({
  isOpen,
  onCloseModal,
  sessionId,
}: {
  isOpen: boolean
  onCloseModal: () => void
  sessionId?: number
}) => {
  const step = useImportRidersStore((state) => state.step)

  const [cancelRequest, cancelResponse] = useLazyImportCancelQuery()

  const setImportRidersStore = useImportRidersStore(
    (state) => state.setImportRidersStore
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      useImportSocketResponse.getState().reset()
      useImportRidersStore
        .getState()
        .setImportRidersStore({ ...baseSetting, step: 'initial' })
    }
  }, [])
  const isEnd = useImportSocketResponse((state) => state.process === 'end')

  const handleClose = () => {
    useImportSocketResponse.getState().setStore({ uidImport: '' })
    const { tryClose, step } = useImportRidersStore.getState()
    if (step !== 'process' || isEnd) {
      onCloseModal()
    } else if (!tryClose) {
      setImportRidersStore({ tryClose: true })
    } else {
      cancelRequest(sessionId)
      const pathName = window.location.pathname
      console.log('pathName', pathName)
      if (pathName.includes('/riders')) {
        console.log('riders')
        dispatch(getChildren(sessionId))
      }
    }

    setImportRidersStore({ tryClose: true })
  }
  return (
    <NewModal
      className={styles.root}
      title="Riders import"
      open={isOpen}
      width={660}
      onClose={handleClose}
    >
      {step === 'initial' && <InitialStep />}
      {step === 'mapping' && <MappingStep sessionId={sessionId} />}
      {step === 'process' && (
        <ProcessStep
          onCloseModal={handleClose}
          cancelProcess={cancelResponse.isFetching}
        />
      )}
    </NewModal>
  )
}
