import { Controller, FieldValues, RegisterOptions } from 'react-hook-form'
import { InputProps } from './NewAntInput'
import { BaseInput } from '@/entities/ui/Inputs/BaseInput/BaseInput'
import React from 'react'

export type IRules = Omit<
  RegisterOptions<FieldValues, string>,
  'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
>
export const FormInput = ({
  name = '',
  rules,
  ...rest
}: InputProps & { rules?: IRules }) => {
  return (
    <Controller
      rules={rules}
      name={name}
      render={({ field, fieldState }) => (
        <BaseInput
          {...{ ...field, ...rest }}
          status={
            typeof fieldState.error?.message === 'string' ? 'error' : undefined
          }
          error={rest.error ? rest.error : fieldState.error?.message}
        />
      )}
    />
  )
}
