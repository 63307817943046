export const LegendAsIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="38"
    viewBox="0 0 10 17"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#3CB0E7"
        stroke="#268AB9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".25"
        d="M7.702 6.928c.715-1.142.798-1.821.798-2.738C8.5 1.876 6.524 0 4.238 0 1.924 0 0 1.876 0 4.19c0 .917.072 1.596.786 2.738.318.51 3.452 3.834 3.452 8.93 0-5.096 3.146-8.42 3.464-8.93z"
        transform="translate(1 1)"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M6.032 4.139H2.494V2.898h3.538v1.24zm-.442 1.49c-.245 0-.443-.167-.443-.373s.198-.372.443-.372c.244 0 .442.166.442.372s-.198.372-.442.372zm-2.653 0c-.245 0-.443-.167-.443-.373s.198-.372.443-.372c.244 0 .442.166.442.372s-.198.372-.442.372zM1.905 5.38c0 .219.115.415.295.551v.442c0 .137.132.248.294.248h.295c.163 0 .295-.11.295-.248v-.248h2.358v.248c0 .137.132.248.295.248h.295c.163 0 .295-.11.295-.248v-.442c.18-.136.294-.332.294-.55V2.897c0-.87-1.055-.993-2.358-.993-1.303 0-2.358.124-2.358.993V5.38z"
        transform="translate(1 1)"
      />
    </g>
  </svg>
);
