import React, { Component } from 'react'
import Modal from '../../../../../../components/BaseModal'
import _ from 'lodash'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Select from '../../../../../../components/customSelect/index'
import { connect } from 'react-redux'
import ErrorModal from '../RiderAddress/ErrorModal'
import ErrorModalCapacity from '../RiderAddress/ErrorModalCapacity'
import capacityCheck from '../RiderAddress/capacityCheck'

class AddressModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeRun: this.props.activeRun,
      routes: this.props.routes,
      stopId: 0,
      showWarning: false,
    }
  }

  handleChange = (value) => {
    this.setState({ stopId: value })
  }

  getStops = () => {
    if (!this.props.activeRun) return false
    let stops = []
    let runActive
    this.state.routes.forEach((gr) => {
      gr.routes.find((x) => {
        if (x.id === this.props.activeRun) {
          runActive = x
        }
      })
    })

    if (runActive) {
      stops = runActive.points.map((x) => {
        if (x.type === 2) return x
      })

      return _.compact(stops)
    }
  }

  getTypeRun = () => {
    let route

    this.props.routes.forEach((r) => {
      r.routes.forEach((x) => {
        if (x.id === this.props.activeRun) route = x
      })
    })

    return route.is_morning
  }

  checkRules = (checkCapacity = true) => {
    let { buses, activeRun, routes } = this.props
    let conf = {
      buses: buses,
      routes: routes,
      activeRun: activeRun,
      addCount: 1,
    }
    let difference = capacityCheck(conf)

    if (!checkCapacity) {
      this.setState({
        showWarningCapacity: false,
      })
    }

    if (checkCapacity && difference < 0) {
      this.setState({
        showWarningCapacity: true,
        showWarningCapacityDifference: difference * -1,
      })
      return false
    }

    this.checkType()
  }

  checkType = () => {
    let targetAddress = this.props.targetAddress

    if (this.getTypeRun() !== targetAddress.type) {
      this.setState({
        showWarning: targetAddress.type,
      })
      return false
    }

    this.save()
  }

  save = () => {
    let targetAddress = this.props.targetAddress
    let { stopId } = this.state

    if (stopId) {
      let data = {
        pointId: stopId,
        childAddressId: targetAddress.id,
        routeId: this.state.activeRun,
        childId: targetAddress.child_id,
      }

      this.props.addRiderInStop(data)
    }

    this.props.closeFn()
  }

  closeWarning = () => {
    this.setState({ showWarning: false })
    this.props.closeFn()
  }

  closeAll = () => {
    this.setState({ showWarningCapacity: false })
    this.props.closeFn()
  }

  render() {
    let { showWarning, showWarningCapacity, showWarningCapacityDifference } =
      this.state
    let stops = this.getStops()
    if (_.isEmpty(stops)) this.props.closeFn()

    return (
      <Modal id="copyRiders" closeFn={this.props.closeFn}>
        <div className=" Modal">
          <div className={'anchor-block'}>
            <Row>
              <Col span={24}>
                <h3 className={'title txt'}>
                  <b>Add Rider to a Stop</b>
                </h3>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <h3 className={'body txt'}>
                  You need to Select a Stop you want to add Rider
                </h3>
              </Col>
            </Row>

            <Row className={'margin6'}>
              <Col span={24}>
                <label>Select a Stop</label>
                <Select
                  data-testid="stopSel"
                  value={this.state.stopId}
                  onChange={(stopId) => this.handleChange(stopId)}
                  style={{ width: '100%' }}
                >
                  <option value={0}>Select stop...</option>
                  {stops.map((stop) => (
                    <option key={stop.id} value={stop.id}>
                      {stop.busStopTitle}
                    </option>
                  ))}
                </Select>
              </Col>
            </Row>

            <div className="anchor-block-footer">
              <button className="btn btn-grey" onClick={this.props.closeFn}>
                <b>Cancel</b>
              </button>
              <button
                data-testid="addRiderToStop"
                className="btn btn-orange"
                onClick={() => this.checkRules(true)}
              >
                <b>Add</b>
              </button>
            </div>
          </div>
        </div>

        {showWarning && (
          <ErrorModal
            close={this.closeWarning}
            save={this.save}
            addressType={showWarning}
          />
        )}

        {showWarningCapacity && (
          <ErrorModalCapacity
            close={this.closeAll}
            save={() => this.checkRules(false)}
            difference={showWarningCapacityDifference}
          />
        )}
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    routes: state.routesView.routes,
    buses: state.buses,
  }
}

export default connect(mapStateToProps)(AddressModal)
