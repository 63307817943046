import React, { FC } from 'react'
import './style.less'
import { ColorLine } from '@/pages/Gps/NewGpsDetails/components/ColorLine'
import { Hours } from '@/pages/Gps/NewGpsDetails/components/Hours'
import classnames from 'classnames'
import { useGpsDetailsStore } from '@/pages/Gps/NewGpsDetails/store'
import { getTimestampWithOffset } from '@/pages/Gps/NewGpsDetails/helpers/common'
import { calculateEndTime } from '@/pages/Gps/NewGpsDetails/helpers/calculateEndTime'

export const TimeLine: FC = () => {
  const run = useGpsDetailsStore.getState().gpsInfo

  if (!run?.startTime || !run?.timeZone) return null

  const lastStopScheduledTime = run.points[run.points?.length - 1].scheduledTime

  const start = getTimestampWithOffset(run.startTime, run.timeZone)
  const end = calculateEndTime(
    +run.endTime,
    lastStopScheduledTime,
    run.timeZone
  )

  return (
    <div
      id="GPS_TIME_LINE"
      className={classnames('time-line-gps', 'root', { hidden: !run?.runName })}
    >
      <Hours startTime={start} endTime={end} showEnd={!!run?.endTime} />
      <ColorLine start={start} end={end} />
    </div>
  )
}
