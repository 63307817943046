import React, { Component } from 'react'
import { connect } from 'react-redux'
import PointDetails from '../PointDetails/index'
import './index.less'
import Riders from './components/Riders'
import Additional from '../Additional'
import moment from 'moment'
import ModalCopyRiders from './components/ModalCopyRiders'
import * as fieldTripRequestsFn from '../../../../../redux/fieldTrip/requests/actions'
import { totalTime } from '../../../../../components/RepeatFunctions/timeWork'
import _ from 'lodash'
import { RightIcon } from '../../../../../img/icons'

class BusDepot extends Component {
  constructor(props) {
    super(props)

    this.state = {
      point: props.point,
      edit: false,
      copyModal: false,
      isExpanded: {
        riders: false,
        adults: false,
      },
      showBlockMore: false,
      type: this.props.point.type,
      viewOnly: this.props.viewOnly,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.point !== nextProps.point) {
      this.setState({
        point: nextProps.point,
      })
    }

    let href = window.location.href.split('/')
    let ftId = href[href.length - 1] * 1
    let element = nextProps.fieldTripRequests.find((x) => x.id === ftId)
    if (!element) return false
    let stops = element.fieldTrip.fieldTripRuns[0].fieldTripPoints.filter(
      (x) => x.type !== 1
    )
    this.setState({
      fTid: ftId,
      activeRuns: element.fieldTrip.fieldTripRuns,
      selectedRun: element.fieldTrip.fieldTripRuns[0].id,
      selectedStops: stops,
      fieldTripRequests: nextProps.fieldTripRequests,
    })
  }

  modalCopyRiders = () => {
    this.setState({ copyModal: !this.state.copyModal })
  }

  formatDate = (date, timeZone) => {
    return moment(date).tz(timeZone)?.format('MM/DD/YYYY HH:mm A')
  }

  renderStopAttributes() {
    let { point } = this.state
    let pointOsrmLeg = this.props.pointOsrmLeg
    let timeZone = ''
    this.props.fieldTripRequests.forEach((r) => {
      r.fieldTrip?.fieldTripRuns?.forEach((i) => {
        if (i.id === point.run_id) {
          timeZone = i.tz
        }
      })
    })
    if (!point) return false

    let time,
      waittime = 0
    if (pointOsrmLeg) {
      time = pointOsrmLeg.time
      waittime = point.waittime
    }

    //time to editModal
    this.state.point.osrmTime = time

    return (
      <div className="stop-attributes">
        <div className={'flex'}>
          {time > 0 && (
            <span className="FtStartDate">
              Start <b>{this.formatDate(new Date(time * 1000), timeZone)}</b>
            </span>
          )}

          {waittime > 0 && (
            <span className="FtWait">
              Wait Time <b>{totalTime(waittime)}</b>
            </span>
          )}
        </div>
        <div className="flex marginTop16">
          {this.getRidersCountAndCopyBtn()}
        </div>
      </div>
    )
  }

  getRidersCountAndCopyBtn = () => {
    let point = this.state.point
    if (
      point.fieldTripRiders.length === 0 &&
      point.fieldTripRiderAdults.length === 0
    ) {
      return false
    }
    let count = point.fieldTripRiders.length + point.fieldTripRiderAdults.length

    return (
      <span className="allRiders">
        <span className="text">All Riders</span>
        <span className={'countRiders'}>{count}</span>

        {!this.props.isReadonly && !this.props.viewOnlyButton && (
          <div className={'icons'} onClick={this.modalCopyRiders}>
            <span className="copy" />
            <span>Copy</span>
          </div>
        )}
      </span>
    )
  }

  renderAddress() {
    let { point } = this.state

    return (
      <div className="stop-attributes">
        {point.address && point.address.address ? (
          <span className="FtWait">
            Address <span>{point.address.address}</span>
          </span>
        ) : (
          point.address
        )}
      </div>
    )
  }

  editToggle = () => {
    this.setState({
      edit: !this.state.edit,
    })
  }

  saveRiders = (data) => {
    let { fTid } = this.state

    let runs = this.state.fieldTripRequests.find((x) => x.id === fTid).fieldTrip
      .fieldTripRuns
    let newRun = runs.find((x) => x.id === data.newRunId)

    let newPoint = newRun.fieldTripPoints.find(
      (x) => x.id === data.newSelectedPoint
    )

    let ridersArrDiff = _.differenceBy(
      this.state.point.fieldTripRiders,
      newPoint.fieldTripRiders,
      'child_id'
    )
    ridersArrDiff = ridersArrDiff.map((x) => {
      return { child_id: x.child_id, point_id: newPoint.id }
    })
    newPoint.fieldTripRiders = _.concat(newPoint.fieldTripRiders, ridersArrDiff)

    newPoint.fieldTripRiderAdults = this.state.point.fieldTripRiderAdults.map(
      (x) => {
        return { ...x, id: null, point_id: newPoint.id }
      }
    )

    this.props.dispatch(
      fieldTripRequestsFn.editPoint(newPoint, fTid, data.newRunId)
    )
  }

  render() {
    let { type, viewOnly, point, copyModal, activeRuns, selectedStops } =
      this.state
    let id
    let pointDetails
    if (type === 1) {
      id = 'BusDeport'
      pointDetails = (
        <PointDetails
          modalType={'modalDepot'}
          point={point}
          id={point.id}
          handleEditStop={this.props.toggleModalEditFn}
          handleAnchor={this.props.toggleModalAnchorFn}
          handleDelete={this.props.toggleModalDeletePointFn}
          handleSetTitle={this.props.handleSetTitle}
          isReadonly={this.props.isReadonly}
          position={this.props.point.position}
        />
      )
    } else if (type === 2) {
      id = 'Stop'
      pointDetails = (
        <PointDetails
          modalType={'modalStop'}
          point={this.state.point}
          handleAnchor={this.props.toggleModalAnchorFn}
          handleEditStop={this.props.toggleModalEditFn}
          handleDelete={this.props.toggleModalDeletePointFn}
          isReadonly={this.props.isReadonly}
          position={this.props.point.position}
        />
      )
    } else if (type === 3) {
      id = 'School'
      pointDetails = (
        <PointDetails
          modalType={'modalSchool'}
          point={this.state.point}
          handleEditStop={this.props.toggleModalEditFn}
          handleAnchor={this.props.toggleModalAnchorFn}
          handleDelete={this.props.toggleModalDeletePointFn}
          isReadonly={this.props.isReadonly}
          position={this.props.point.position}
        />
      )
    }

    let index = this.props.index

    index = index + 1

    return (
      <div id={id} className={'ListDiv'}>
        <div
          data-testid="childPoint"
          className="childPoint"
          onClick={(e) => this.editToggle(e)}
        >
          <div
            className="buttonTrigger"
            onClick={(e) => this.editToggle(e)}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              left: -14,
            }}
          >
            <div className="triggerArrow">
              <RightIcon
                className={this.state.edit ? 'Arrows rotate90' : 'Arrows'}
                style={{ color: 'white', margin: 0 }}
              />
            </div>
          </div>
          {!viewOnly && pointDetails}
          <div className="Head" style={{ padding: '0px' }}>
            <div className="big">
              {index && index + '.'} {this.props.point.title}
            </div>
          </div>
          {this.props.pointOsrmLeg ? (
            <div className="firstLine" style={{ marginTop: '5px' }}>
              <div className={'flex'}>{this.renderStopAttributes()}</div>
            </div>
          ) : null}
          {this.state.edit && (
            <div
              className="firstLine"
              style={{ marginTop: '5px', marginBottom: '5px' }}
            >
              <div className="flex" style={{ width: '100%' }}>
                {this.renderAddress()}
              </div>
            </div>
          )}
          <div className="firstLine rline">
            <div className="flex" style={{ width: '100%' }}>
              {this.state.point.fieldTripRiders &&
                this.state.point.fieldTripRiders.length > 0 && (
                  <Riders
                    viewOnlyButton={this.props.viewOnlyButton}
                    childrenList={this.props.childrenList}
                    deleteRiderFn={this.props.deleteRiderFn}
                    point={this.state.point}
                    isReadonly={this.props.isReadonly}
                  />
                )}
            </div>
          </div>
          <div className="firstLine rline">
            <div className="flex" style={{ width: '100%' }}>
              {this.state.point.fieldTripRiderAdults &&
              this.state.point.fieldTripRiderAdults.length ? (
                <Additional
                  deleteRiderFn={this.props.deleteRiderFn}
                  point={this.state.point}
                  isReadonly={this.props.isReadonly}
                />
              ) : null}
            </div>
          </div>
        </div>
        {copyModal && (
          <ModalCopyRiders
            activeRuns={activeRuns}
            point={point}
            saveRiders={this.saveRiders}
            selectedStops={selectedStops}
            close={this.modalCopyRiders}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return { fieldTripRequests: state.fieldTripRequests.data }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(BusDepot)
