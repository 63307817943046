import * as L from "leaflet";
/**
 * Created by yury on 26.06.18.
 */
(function () {

    var __onAdd = L.Polyline.prototype.onAdd,
        __onRemove = L.Polyline.prototype.onRemove,
        __updatePath = L.Polyline.prototype._updatePath,
        __bringToFront = L.Polyline.prototype.bringToFront;


    var PolylineTextPath = {

        onAdd: function (map) {
            __onAdd.call(this, map);
            this._textRedraw();
        },

        onRemove: function (map) {
            map = map || this._map;
            if (map && this._textNode && map._renderer._container){
              for (let i = 0 ; i<this._textNode.length; i++){
                map._renderer._container.removeChild(this._textNode[i]);
              }
            }


            __onRemove.call(this, map);
        },

        bringToFront: function () {
            __bringToFront.call(this);
            this._textRedraw();
        },

        _updatePath: function () {
            __updatePath.call(this);
            this._textRedraw();
        },

        _textRedraw: function () {
            var text = this._text,
                options = this._textOptions;
            if (text) {
                this.setText(null).setText(text, options);
            }
        },

        setText: function (text, options) {
            this._text = text;
            this._textOptions = options;
            var attr;
            /* If not in SVG mode or Polyline not added to map yet return */
            /* setText will be called by onAdd, using value stored in this._text */
            if (!L.Browser.svg || typeof this._map === 'undefined') {
                return this;
            }

            var defaults = {
                repeat: false,
                fillColor: 'black',
                attributes: {},
                below: false,
            };
            options = L.Util.extend(defaults, options);

            /* If empty text, hide */
            if (!text) {
              if(!this._textNode)    return this;
              for (let i = 0 ; i<this._textNode.length; i++){
                if (this._textNode[i] && this._textNode[i].parentNode) {
                  this._map._renderer._container.removeChild(this._textNode[i]);
                  /* delete the node, so it will not be removed a 2nd time if the layer is later removed from the map */
                  delete this._textNode[i];
                }
              }
              return this;
            }

            text = text.replace(/ /g, '');  // Non breakable spaces
            let id = 'pathdef-' + L.Util.stamp(this);
            let svg = this._map._renderer._container;
            this._path.setAttribute('id', id);
            this._textNode = []
            // if (options.repeat) {
            //     /* Compute single pattern length */
            //     let pattern = L.SVG.create('text');
            //     for (attr in options.attributes)
            //         pattern.setAttribute(attr, options.attributes[attr]);
            //     pattern.appendChild(document.createTextNode(text));
            //     svg.appendChild(pattern);
            //     let alength = pattern.getComputedTextLength();
            //     svg.removeChild(pattern);
            //
            //     /* Create string as long as path */
            //     text = new Array(Math.ceil(this._path.getTotalLength() / alength)).join(text);
            // }
            /* Put it along the path using textPath */

            let count = Math.ceil(this._path.getTotalLength() / 60 )

            for(let i= 0 ;  i < count; i++ ) {
              let textNode = L.SVG.create('text'),
                textPath = L.SVG.create('textPath');

              let dy = options.offset || this._path.getAttribute('stroke-width');
              let offset = i * 80

              textPath.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", '#'+id);
              textPath.setAttribute('startOffset', offset)

              textNode.setAttribute('dy', dy);
              for (attr in options.attributes)
                textNode.setAttribute(attr, options.attributes[attr]);
              textPath.appendChild(document.createTextNode(text));
              textNode.appendChild(textPath);
              this._textNode.push(textNode)

              // if (options.below) {
              //   svg.insertBefore(textNode, svg.firstChild);
              // }
              // else {
              //   svg.appendChild(textNode);
              // }
              svg.appendChild(textNode);
            }

            /* Center text according to the path's bounding box */
            // if (options.center) {
            //     let textLength = textNode.getComputedTextLength();
            //     let pathLength = this._path.getTotalLength();
            //     /* Set the position for the left side of the textNode */
            //     textNode.setAttribute('dx', ((pathLength / 2) - (textLength / 2)));
            // }

            /* Change label rotation (if required) */
            // if (options.orientation) {
            //     let rotateAngle = 0;
            //     switch (options.orientation) {
            //         case 'flip':
            //             rotateAngle = 180;
            //             break;
            //         case 'perpendicular':
            //             rotateAngle = 90;
            //             break;
            //         default:
            //             rotateAngle = options.orientation;
            //     }
            //
            //     let rotatecenterX = (textNode.getBBox().x + textNode.getBBox().width / 2);
            //     let rotatecenterY = (textNode.getBBox().y + textNode.getBBox().height / 2);
            //     textNode.setAttribute('transform','rotate(' + rotateAngle + ' '  + rotatecenterX + ' ' + rotatecenterY + ')');
            // }

            /* Initialize mouse events for the additional nodes */
            // if (this.options.clickable) {
            //     if (L.Browser.svg || !L.Browser.vml) {
            //         textPath.setAttribute('class', 'leaflet-clickable');
            //     }
            //
            //     L.DomEvent.on(textNode, 'click', this._onMouseClick, this);
            //
            //     let events = ['dblclick', 'mousedown', 'mouseover',
            //         'mouseout', 'mousemove', 'contextmenu'];
            //     for (let i = 0; i < events.length; i++) {
            //         L.DomEvent.on(textNode, events[i], this._fireMouseEvent, this);
            //     }
            // }
            return this;
        }
    };

    L.Polyline.include(PolylineTextPath);

    L.LayerGroup.include({
        setText: function(text, options) {
            for (let layer in this._layers) {
                if (typeof this._layers[layer].setText === 'function') {
                    this._layers[layer].setText(text, options);
                }
            }
            return this;
        }
    });



})();