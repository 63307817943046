import { IRegistrationItem } from '@/store/routeTool/routeTool.types'
import styles from '@/entities/modals/RouteToolRegistrations/RTRegistrationsOpenedModal/screens/RejectScreen/RejectScreen.module.less'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { useLazyAutoAcceptRegistrationRequestQuery } from '@/store/routeTool/routeTool'
import { useRTRegistrationsStore } from '@/entities/modals/RouteToolRegistrations/RTRegistrationsOpenedModal/store'

export const AutoAcceptScreen = (item: IRegistrationItem) => {
  const [onReject, response] = useLazyAutoAcceptRegistrationRequestQuery()
  const onSubmit = () => {
    onReject(item.id)
  }

  const setScreen = useRTRegistrationsStore.getState().setScreen

  return (
    <div>
      <p>
        You are going to accept the guardian’s request. Changes will be applied
        to the rider record immediately!
      </p>

      <div className={styles.footer}>
        <Button
          onClick={() => setScreen('main')}
          buttonColor="gray"
          type="button"
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          isLoading={response.isFetching}
          type="button"
        >
          Save
        </Button>
      </div>
    </div>
  )
}
