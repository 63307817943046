import { FormType } from '@/pages/Sessions/components/modals/AddNewSessionModal/AddNewSessionModal.types'

export const format = 'DD-MM-YYYY'

export const defaultValues: FormType = {
  weeks: '',
  title: '',
  start_date: null,
  end_date: null,
}

export const rules = (num: number) => {
  return {
    required:
      num === 0
        ? { value: true, message: 'This field is required' }
        : undefined,
  }
}
