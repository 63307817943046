import * as types from './actionTypes'

import _ from 'lodash'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.TRANCPORTATION_CATEGORIES_LIST: {
      let newArr = _.cloneDeep(state)

      newArr.list = [...action.payload]
      return newArr
    }

    case types.TRANCPORTATION_CATEGORIES: {
      let newArr = _.cloneDeep(state)
      newArr.data = action.payload
      return newArr
    }

    case types.TRANCPORTATION_CATEGORIES_LIST_ADD: {
      let newArr = _.cloneDeep(state)
      let data = action.payload

      data.forEach((item) => {
        newArr.data.push(item)
      })

      return _.cloneDeep(newArr)
    }

    case types.TRANCPORTATION_CATEGORIES_ADD: {
      let newArr = _.cloneDeep(state)
      let data = action.payload
      newArr.data.push(data)
      return _.cloneDeep(newArr)
    }

    case types.TRANCPORTATION_CATEGORIES_DELETE: {
      let newArr = _.cloneDeep(state)
      let list = []
      const id = action.payload
      let arr = newArr.data.filter((item) => {
        if (item.id !== id) {
          return item
        } else {
          if (newArr.list) {
            list = newArr.list.push(item.name)
          }
        }
      })

      if (newArr.list) {
        newArr.list.sort()
      }

      return { data: arr, list: newArr.list }
    }

    case types.TRANCPORTATION_CATEGORIES_UPDATE: {
      let newArr = _.cloneDeep(state)
      let data = action.payload
      newArr.data.forEach((item, i) => {
        if (item.id === data.id) {
          newArr.data[i] = data
        }
      })
      return _.cloneDeep(newArr)
    }
    case types.CHILDREN_ENABLED_PHONES: {
      let enabledPhones = action.payload
      return {
        ...state,
        enabledPhones,
      }
    }
    default:
      return state
  }
}
