import { useAppSelector } from '@/redux'
import { useMemo } from 'react'

export const formFields = [
  { key: 'cid', value: 'ID' },
  { key: 'full_name', value: 'Full Name' },
  { key: 'gender', value: 'Gender' },

  { key: 'birth_date', value: 'Birth Date' },
  { key: 'is_nurse', value: 'Requires Nurse' },
  { key: 'is_attendant', value: 'Requires Attendant' },

  { key: 'special_instructions', value: 'Special Instructions' },
  { key: 'transportation_category', value: 'Transportation Category' },
  { key: 'empty', value: '' },

  { key: 'first_guardian', value: 'First Guardian Name' },
  { key: 'first_guardian_phone', value: 'First Guardian Phone' },
  { key: 'first_guardian_work_phone', value: 'First Guardian Work Phone' },

  { key: 'first_guardian_email', value: 'First Guardian Email' },
  { key: 'second_guardian', value: 'Second Guardian Name' },
  { key: 'second_guardian_phone', value: 'Second Guardian Phone' },

  { key: 'second_guardian_work_phone', value: 'Second Guardian Work Phone' },
  { key: 'second_guardian_email', value: 'Second Guardian Email' },
  { key: 'pick_up_house_number', value: 'Pick Up House Number' },

  { key: 'pick_up_addres', value: 'Pick Up Address' },
  { key: 'pick_up_city', value: 'Pick Up City' },
  { key: 'pick_up_zip', value: 'Pick Up Zip' },

  { key: 'weekly_pu', value: 'Weekly Pickup Days' },
  { key: 'school_name_am', value: 'School Name - AM' },
  { key: 'drop_off_house_number', value: 'Drop off House Number' },

  { key: 'drop_off_address', value: 'Drop off Street Address' },
  { key: 'drop_off_city', value: 'Drop off City' },
  { key: 'drop_off_zip', value: 'Drop off Zip' },

  { key: 'weekly_do', value: 'Weekly Drop off Days' },
  { key: 'school_name_pm', value: 'School Name - PM' },
  { key: 'site_name', value: 'Site Name' },

  { key: 'classs', value: 'Grade' },
  { key: 'route', value: 'Route/Run Name' },
  { key: 'additional_rider1', value: 'Additional Rider 1' },

  { key: 'additional_rider2', value: 'Additional Rider 2' },
  { key: 'program_type', value: 'Program Type' },
  { key: 'service_start_date', value: 'Service Start Date' },

  { key: 'service_end_date', value: 'Service End Date' },
  { key: 'vendor_start_date', value: 'Vendor Start Date' },
  { key: 'vendor_end_date', value: 'Vendor End Date' },

  { key: 'pick_up_rate', value: 'Pick Up Rate' },
  { key: 'drop_off_rate', value: 'Drop Off Rate' },
  { key: 'pick_up_pricing_tier', value: 'Pick Up Pricing Tier' },

  { key: 'drop_off_pricing_tier', value: 'Drop Off Pricing Tier' },
  { key: 'alert_note', value: 'Alert Note' },
  { key: 'emergency_contact', value: 'Emergency Contact' },

  { key: 'emergency_contact_phone', value: 'Emergency Contact Phone' },
  { key: 'empty', value: '' },
  { key: 'empty', value: '' },

  {
    key: 'emergency_contact_work_phone',
    value: 'Emergency Contact Work Phone',
  },
  { key: 'empty', value: '' },
  { key: 'empty', value: '' },

  { key: 'weeks', value: 'Camping Weeks' },
  { key: 'empty', value: '' },
  { key: 'riding', value: 'Riding' },
]

export const useImportField = () => {
  const camp_mode = useAppSelector((state) => !!state.auth?.camp_mode)

  const fields = useMemo(
    () =>
      camp_mode
        ? formFields.filter((field) => field.key !== 'weeks')
        : formFields,
    [camp_mode]
  )

  const fieldKeys = useMemo(() => fields.map((item) => item.key), [fields])

  return {
    fieldKeys,
    formFields: camp_mode
      ? formFields.filter((field) => !['weeks', 'empty'].includes(field.key))
      : formFields,
  }
}
