import { GpsRunPoint } from '@/redux/gps/types'

/***
 * After receiving geometry strings array we are detecting each part late or not with points factTime and scheduledTime difference
 * @param geometry Array of legs geometries from passed stops and current one in the live mode
 * @param points Array of run stops
 */
export const geometryToColorPolylineMapper = (
  geometry: { geometry: string }[],
  points: GpsRunPoint[]
) => {
  const lateArr: { late: boolean }[] = []
  points
    .filter((p) => p.stopType !== 4)
    .forEach((x, i, arr) => {
      const prevPointLate =
        i > 0 &&
        arr[i - 1].factTime &&
        arr[i - 1].factTime > arr[i - 1].scheduledTime
      lateArr[i] = { late: prevPointLate }

      if (x.factTime && x.factTime > x.scheduledTime) lateArr[i].late = true
    })

  const geometryFill: GeometryFill[] = []
  if (lateArr) {
    lateArr.forEach((x, i, arr) => {
      const nextLate = arr.length - 1 === i || arr[i + 1].late
      const obj = {
        geometry: geometry[i] && geometry[i].geometry,
        late: [x.late, nextLate] as [boolean, boolean],
        nextLate,
      }
      obj.geometry && geometryFill.push(obj)
    })
  }

  return geometryFill
}

type GeometryFill = {
  geometry: string
  late: [boolean, boolean]
  nextLate: boolean
}
