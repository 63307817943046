import * as types from './actionTypes'
import api from '../apiConfig'
import moment from 'moment'
import axios from 'axios'
import { loaderFinish, loaderStart } from '../common/actions'
import * as commonTypes from '../common/actionTypes'
import { useGpsDetailsStore } from '@/pages/Gps/NewGpsDetails/store'
import { getUrlWithSearchParams } from '@/helpers/getUrlWithSearchParams'
import * as Sentry from '@sentry/react'
const rq = api.gps
const rqv2 = api.gpsV2

export function getGpsRecords(timestamp) {
  return function (dispatch) {
    axios
      .get(`gps-routes?expand=bus.lastGps,history&timestamp=${timestamp}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.GPS_GET_RECORDS,
            payload: response.data,
          })
        } else {
          console.warn('error')
        }
      })
  }
}

export function getGpsRecordsV2(timestamp) {
  return function (dispatch) {
    rqv2.get(`/route/gps?timestamp=${timestamp}`).then((response) => {
      if (response.status === 200) {
        const runs = response.data.sort((a, b) => a.id - b.id)
        useGpsDetailsStore.getState().setGpsStore({ gpsTimestamp: runs })

        dispatch({
          type: types.GPS_GET_RECORDS,
          payload: runs,
        })
      } else {
        console.warn('error')
      }
    })
  }
}

export function getGpsRecordsV2Clean() {
  return function (dispatch) {
    dispatch({
      type: types.GPS_RECORDS_CLEAN,
    })
  }
}

export function getGpsRecordById(id, timestamp) {
  timestamp = timestamp || moment().utc(false).format('X')

  return function (dispatch) {
    dispatch(loaderStart())
    rq.get(
      `gps-routes/${id}?expand=bus.lastGps,history.timeline&timestamp=${timestamp}`
    ).then((response) => {
      if (response.status === 200) {
        dispatch(loaderFinish())
        dispatch({
          type: types.GPS_GET_RECORD_BY_ID,
          payload: response.data,
        })
      } else {
        console.warn('error')
      }
    })
  }
}

export function getGpsRunData(id, timestamp) {
  return function (dispatch) {
    useGpsDetailsStore.getState().setGpsStore({ isReload: true })
    dispatch({ type: commonTypes.PENDING_START })
    rqv2
      .get(
        getUrlWithSearchParams(`/route/gps/view/${id}`, {
          timestamp,
        })
      )
      .then((response) => {
        if (response.status === 200) {
          const payload = response.data
          dispatch({
            type: types.GPS_GET_RUN,
            payload,
          })
          dispatch({ type: commonTypes.PENDING_END })
          useGpsDetailsStore.getState().setGpsInfo(payload)
        }
      })
      .catch(Sentry.captureException)
      .finally(() => {
        useGpsDetailsStore.getState().setGpsStore({ isReload: false })
      })
  }
}
