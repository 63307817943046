import React, { FC } from 'react'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import { mapKey } from '@/redux/configLocal'
import { BasemapLayer } from 'react-esri-leaflet/v2'
import { useAppSelector } from '@/redux'

type EsriGoogleMapLayerProps = {
  mapType: 'roadmap' | 'satellite' | 'traffic'
}

export const EsriGoogleMapLayer: FC<EsriGoogleMapLayerProps> = ({
  mapType,
}) => {
  const mapLayer = useAppSelector((state) =>
    state.auth.companyOptions
      ? state.auth.companyOptions.base_map_layer
      : 'google'
  )

  const googleRoadmap = mapType === 'roadmap' && mapLayer === 'google'
  const esriRoadMap = mapType === 'roadmap' && mapLayer === 'esri'

  return (
    <div>
      {esriRoadMap && <BasemapLayer name={'Streets'} />}
      {googleRoadmap && <ReactLeafletGoogleLayer {...roadMapProps} />}
      {mapType === 'traffic' && <ReactLeafletGoogleLayer {...trafficProps} />}
      {mapType === 'satellite' && (
        <ReactLeafletGoogleLayer {...setelliteProps} />
      )}
    </div>
  )
}

const roadMapProps: any = {
  googleMapsLoaderConf: { KEY: mapKey, LIBRARIES: ['geometry'] },
  type: 'roadmap',
} as any

const trafficProps: any = {
  googleMapsLoaderConf: { KEY: mapKey, LIBRARIES: ['geometry'] },
  googleMapsAddLayers: [{ name: 'TrafficLayer' }],
  type: 'roadmap',
} as any

const setelliteProps: any = {
  googleMapsLoaderConf: { KEY: mapKey, LIBRARIES: ['geometry'] },
  type: 'satellite',
} as any
