import React, { useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'

export const MapContext = React.createContext()

export const useMap = () => {
  const context = useContext(MapContext)

  //TODO HOT FIX , NEEDD REPLACE IN ALL COMPONENTS WITH THIS HOOK
  return context ? useContext(MapContext) : { mapType: 'roadmap' }
}

export const MapProvider = ({ children }) => {
  const [mapType, setMapType] = useState('roadmap')
  const [drawTool, setDrawTool] = useState(null)
  const [selectedToolType, setSelectedTool] = useState(null)
  const mapRef = useRef(null)
  const figureClick = useRef(null)
  const scope = useRef(null)
  const onCreatedFn = useRef(null)
  const [popUp, setPopUp] = useState(null)
  const ridersOnMap = useRef([])
  const [openModal, setOpenModal] = useState(false)

  const zoomIn = () => {
    mapRef.current.contextValue.map.zoomIn()
  }

  const zoomOut = () => {
    mapRef.current.contextValue.map.zoomOut()
  }

  const areBoundsEqual = (bound) => {
    if (bound.length >= 2) {
      const referenceBound = bound[0]
      return _.every(bound, (currentBound) =>
        _.isEqual(referenceBound, currentBound)
      )
    } else {
      return false
    }
  }

  const centerMapToBounds = (bound) => {
    if (bound) {
      mapRef.current.contextValue.map.fitBounds(bound)
      const currentZoom = mapRef.current.contextValue.map.getZoom()
      const samePosition = areBoundsEqual(bound)
      if ((bound.length === 1 && currentZoom > 14) || samePosition) {
        mapRef.current.contextValue.map.setZoom(14)
      }
    }
  }

  return (
    <MapContext.Provider
      value={{
        mapType: mapType,
        setMapType: setMapType,
        mapRef: mapRef,
        mapRef2: mapRef,
        zoomIn,
        zoomOut,
        centerMapToBounds,
        setDrawTool,
        drawTool,
        setSelectedTool,
        selectedToolType,
        scope,
        setPopUp,
        popUp,
        onCreatedFn,
        figureClick,
        ridersOnMap,
        setOpenModal,
        openModal,
      }}
    >
      {children}
    </MapContext.Provider>
  )
}
