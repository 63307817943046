import React, { Component } from "react";
import { connect } from "react-redux";
import BaseModal from "../../../../../../../components/BaseModal";
import Select from "../../../../../../../components/customSelect/index";
import _ from "lodash";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStops: this.props.selectedStops,
      selectedRun: this.props.activeRuns[0].id,
      selectedPoint: this.props.selectedStops[0].id,
      isError: false
    };
  }

  selectRun = el => {
    let activeRun = this.props.activeRuns.find(x => x.id === el);

    let fieldTripPoints = _.sortBy(activeRun.fieldTripPoints, "position");
    if (fieldTripPoints.length) {
      this.setState({
        selectedRun: el,
        selectedStops: fieldTripPoints,
        selectedPoint: fieldTripPoints[0].id,
        isError: false
      });
    } else {
      this.setState({
        selectedRun: el,
        selectedStops: fieldTripPoints,
        isError: true,
      })
    }
  };

  selectStop = el => {
    this.setState({
      selectedPoint: el
    });
  };

  saveRiders = () => {
    const { selectedRun, selectedPoint, selectedStops } = this.state;

    if (selectedStops.length) {
      let data = {
        newRunId: selectedRun,
        newSelectedPoint: selectedPoint,
        pointOld: this.state.point,
        newPointId: selectedPoint
      };

      this.props.saveRiders(data);
      this.props.close();
    }
  };

  render() {
    let { close, activeRuns, point } = this.props;
    let { selectedStops, selectedPoint, selectedRun } = this.state;
    let count =
      point.fieldTripRiders.length + point.fieldTripRiderAdults.length;

    selectedStops = _.sortBy(selectedStops, "position");
    return (
      <BaseModal
        id={"copyModal"}
        title="Copy Riders to a Stop"
        save={this.saveRiders}
        closeFn={close}
      >
        <div className="line flex">
          <span className="textModal">
            You need to Select a Stop you want to add {count} Riders to:
          </span>
        </div>

        <div className="line flexSelectLine">
          <span> Select a Run</span>
          <Select
            value={selectedRun}
            style={{ height: "35px" }}
            onChange={this.selectRun}
          >
            {activeRuns.map((x, i) => (
              <option value={x.id} key={x.id}>
                {x.name}
              </option>
            ))}
          </Select>
          {this.state.isError && <span className='error-message'>You need to add at least one stop to selected run</span>}
        </div>

        <div className="line flexSelectLine">
          <span>Select a Stop</span>
          <Select
            style={{ height: "35px" }}
            onChange={this.selectStop}
            value={selectedStops.length ? selectedPoint : 'Please add stops to selected run'}
            disabled={!selectedStops.length}
          >
            {selectedStops.map(x => {
              return (
                <option value={x.id} key={x.id}>
                  {x.title}
                </option>
              );
            })}
          </Select>
        </div>

        <div className="center">
          <div className="buttons">
            <button className="cancel" onClick={close}>
              Cancel
            </button>
            <button onClick={this.saveRiders} className="save">
              Add
            </button>
          </div>
        </div>
      </BaseModal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
