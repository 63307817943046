import React from 'react'
import { useContextBuilder } from '../../context'

export default function ({ routePointRoutes , routeId }) {
  let {allRoutes} = useContextBuilder()
  let title = ''
  if(typeof routePointRoutes !== "undefined"){
    routePointRoutes.forEach(x => {
      if(x.route_id !== routeId && allRoutes && x.route_id) {
        let chainRun = allRoutes.find(r=>r.id === x.route_id)
        title = chainRun.title
      }
    })
  }

  return (
    <div>
      <div className="firstLine">
        <div className="flex">
          <span className="gray topRun ">Run</span>{" "}
          <b className={`extTransfer textOver`}>{title} </b>
        </div>
      </div>
    </div>
  )
}