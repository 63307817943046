import React, { Component } from 'react'
import { connect } from 'react-redux'

import './index.less'
import './components/BuildFieldTrip/index.less'
import './components/MapElements/index.less'

import * as companyActions from '../../../redux/auth/actions'

import * as fieldTripRequestsActions from '../../../redux/fieldTrip/requests/actions'
import * as childrenActions from '../../../redux/children/actions'
import * as busesActions from '../../../redux/auth/buses/actions'
import * as schoolsActions from '../../../redux/schools/actions'

import UnassignedRiders from './components/UnassignedRiders'
import MapFTBuilder from './components/MapFTBuilder/index'
import ModalAddSchool from './components/ModalAddSchool/index'
import ModalAddStop from './components/ModalAddStop/index'
import ModalAddDepot from './components/ModalAddDepot/index'
import ModalAddRider from './components/ModalAddRider/index'
import BuildFieldTrip from './components/BuildFieldTrip'

import ModalAnchor from './components/ModalAnchor/index'
import ModalDeleteStop from './components/ModalDeleteStop'

import MapElements from './components/MapElements'
import { CreatePointRequest, StopPoint } from './models'
import { useGetBusDepotListQuery } from '@/store/resources/resources'
import _ from 'lodash'

class _TripBuilder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSessionId: props.seasonsID,
      modalText: '',
      title: '',
      fieldTripRequests: props.fieldTripRequests,
      buses: props.buses,
      sessionId: props.activeSeasonId ? props.activeSeasonId.id : 0,
      drivers: props.drivers,
      schools: props.schools,
      depots: props.depots,
      activeTripId: 0,
      activeRunId: 0,
      activeRunStops: [],
      modalStop: false,
      modalSchool: false,
      modalDepot: false,
      modalRider: false,
      modalAnchor: false,
      modalDeleteStop: false,
      editData: null,
      anchorPoint: null,
      deletePoint: null,
      paramId: props.params.single,
      maptype: 'roadmap',
      childrenList: [],
      viewChildrenList: [],
      osrm: {},
      filters: {
        name: '',
        address: '',
        addresses: 'All',
        trans: 'All',
        grade: 'All',
        schools: 'All',
        routes: [],
        sessions: [],
        active_adrress_hover_id: 0,
      },
    }
    this.map = React.createRef()
  }

  componentDidMount() {
    let { activeSeasonId, dispatch } = this.props
    dispatch(fieldTripRequestsActions.getRequestsBuilder())
    dispatch(childrenActions.getChildren(activeSeasonId))
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.activeSessionId !== nextProps.seasonsID) {
      this.changeSession(nextProps.seasonsID)
    }

    const activeTrip = nextProps.fieldTripRequests.data.find(
      (x) => x.id === this.state.activeTripId
    )

    let activeRunId = 0

    if (
      activeTrip &&
      activeTrip.fieldTrip &&
      activeTrip.fieldTrip.fieldTripRuns &&
      activeTrip.fieldTrip.fieldTripRuns.length > 0
    ) {
      if (this.state.activeRunId) {
        if (
          !activeTrip.fieldTrip.fieldTripRuns.find(
            (x) => x.id === this.state.activeRunId
          )
        ) {
          activeRunId = activeTrip.fieldTrip.fieldTripRuns[0].id
        } else {
          activeRunId = this.state.activeRunId
        }
      } else {
        activeRunId = activeTrip.fieldTrip.fieldTripRuns[0].id
      }
    }

    if (this.state.viewChildrenList !== nextProps.childrenList) {
      if (this.state.filters.schools === 'All') {
        this.setState({
          viewChildrenList: nextProps.childrenList,
        })
      }
    }

    this.setState(
      {
        fieldTripRequests: nextProps.fieldTripRequests,
        childrenList: nextProps.childrenList,
        buses: nextProps.buses,
        drivers: nextProps.drivers,
        schools: nextProps.schools,
        depots: nextProps.depots,
        activeRunId: activeRunId,
        activeSessionId: nextProps.seasonsID,
        sessionId: nextProps.activeSeasonId,
      },
      () => {
        if (this.state.activeTripId && this.state.activeRunId) {
          let activeTrip = nextProps.fieldTripRequests.data.find(
            (x) => x.id === this.state.activeTripId
          )
          if (!activeTrip || !activeTrip.fieldTrip) return
          let activeRun = activeTrip.fieldTrip.fieldTripRuns.find(
            (x) => x.id === this.state.activeRunId
          )
          if (!activeRun) return
          let points = activeRun.fieldTripPoints

          this.setActiveRunPoints(points)
        }
      }
    )
  }

  changeSession = (sessionId) => {
    this.props.dispatch(childrenActions.getChildren(sessionId))
  }

  submitTrip = (tripId) => {
    this.props.dispatch(fieldTripRequestsActions.submitTrip(tripId))
  }

  setActiveTrip = (tripID) => {
    this.setState({
      activeTripId: tripID,
    })
  }

  setActiveRun = (runID) => {
    this.setState({
      activeRunId: runID,
    })
  }

  setActiveRunPoints = (points) => {
    this.setState({
      activeRunStops: points.filter((x) => x.type !== 1),
    })
  }

  toggleModalDeletePoint = (pointId) => {
    if (!this.isTripActive()) return

    let activeTrip = [...this.state.fieldTripRequests.data].find(
      (x) => x.id === this.state.activeTripId
    )

    let activeRun = activeTrip.fieldTrip.fieldTripRuns.find(
      (x) => x.id === this.state.activeRunId
    )

    let point = activeRun.fieldTripPoints.find((x) => x.id === pointId)

    if (!point) {
      return
    }

    this.setState(
      {
        deletePoint: point,
      },
      () => {
        this.setState({
          modalDeleteStop: true,
        })
      }
    )
  }

  toggleModalAnchor = (pointId) => {
    if (!this.isTripActive()) return

    let activeTrip = [...this.state.fieldTripRequests.data].find(
      (x) => x.id == this.state.activeTripId
    )

    let activeRun = activeTrip.fieldTrip.fieldTripRuns.find(
      (x) => x.id == this.state.activeRunId
    )

    let point = activeRun.fieldTripPoints.find((x) => x.id == pointId)

    if (!point) {
      return
    }

    this.setState(
      {
        anchorPoint: point,
      },
      () =>
        this.setState({
          modalAnchor: true,
        })
    )
  }

  toggleModalEdit = (type, point) => {
    if (!this.isTripActive()) return

    this.setState({
      [type]: !this.state[type],
      editData: point,
    })
  }

  toggleModal = (type) => {
    if (!this.isTripActive()) return

    this.setState({
      [type]: !this.state[type],
      editData: null,
    })
  }

  addRun = (tripID) => {
    if (!this.isTripActive()) return

    let activeTrip = [...this.state.fieldTripRequests.data].find(
      (x) => x.id == tripID
    )

    const dateStart = new Date()
    const dateEnd = new Date()

    dateEnd.setHours(dateStart.getHours() + 1)

    let runs = this.removeAddresses(activeTrip.fieldTrip.fieldTripRuns)
    let newRun = {
      name: `Run ${runs.length + 1}`,
      description: `Run ${runs.length + 1}`,
      date_start: parseInt((dateStart.getTime() / 1000).toString()),
      date_end: parseInt((dateEnd.getTime() / 1000).toString()),
    }

    runs.push(newRun)

    const request = { fieldTripRuns: runs }
    this.props.dispatch(
      fieldTripRequestsActions.editTrip(activeTrip.fieldTrip.id, request)
    )
  }

  removeRun = (tripID = null, targetKey = null, runID = null) => {
    if (!this.isTripActive()) return

    const trip = this.state.fieldTripRequests.data.find((x) => x.id == tripID)
    let runs = this.removeAddresses(trip.fieldTrip.fieldTripRuns)

    if (targetKey) {
      targetKey = parseInt(targetKey)
      runs = runs.filter((x) => x.id != runs[targetKey].id)
    } else if (runID) {
      runs = runs.filter((x) => x.id != runID)
    }

    const request = { fieldTripRuns: runs }
    this.props.dispatch(
      fieldTripRequestsActions.editTrip(trip.fieldTrip.id, request)
    )
  }

  deletePoint = (pointId) => {
    if (!this.state.activeRunId) return

    this.props.dispatch(
      fieldTripRequestsActions.deletePoint(
        pointId,
        this.state.activeTripId,
        this.state.activeRunId
      )
    )
  }

  closeModalAnchor = () => {
    this.setState({
      anchorPoint: null,
      modalAnchor: false,
    })
  }

  closeModalDelete = () => {
    this.setState({
      deletePoint: null,
      modalDeleteStop: false,
    })
  }

  markPointAsAnchor = (pointId, anchorTime, isNew) => {
    if (!this.isTripActive()) return

    const request = { fieldTripRuns: [] }
    const trip = this.state.fieldTripRequests.data.find(
      (x) => x.id == this.state.activeTripId
    )
    const runs = this.removeAddresses(trip.fieldTrip.fieldTripRuns)
    const run = runs.find((x) => x.id == this.state.activeRunId)

    if (!run) {
      return
    }

    runs.forEach((x) => request.fieldTripRuns.push(x))

    if (isNew) {
      run.fieldTripPoints.find((x) => x.id == pointId).is_anchor = 1

      run.fieldTripPoints.find((x) => x.id == pointId).time = anchorTime
    } else {
      run.fieldTripPoints.find((x) => x.id == pointId).is_anchor = 0
    }

    run.fieldTripPoints.map((point) => delete point.address)

    request.fieldTripRuns.find(
      (x) => x.id == this.state.activeRunId
    ).fieldTripPoints = run.fieldTripPoints

    this.props.dispatch(
      fieldTripRequestsActions.editTrip(trip.fieldTrip.id, request)
    )
  }

  removeAddresses = (fieldTripRuns) => {
    fieldTripRuns.map((run) => {
      run.fieldTripPoints.map((point) => {
        delete point.address
        return point
      })

      return run
    })

    return [...fieldTripRuns]
  }

  createPoint = (data = new CreatePointRequest()) => {
    if (!data.run_id && !data.type) return
    if (!this.state.activeRunId) return

    data.run_id = this.state.activeRunId
    this.props.dispatch(
      fieldTripRequestsActions.createPoint(
        data,
        this.state.activeTripId,
        this.state.activeRunId
      )
    )
  }

  editPoint = (point = new StopPoint(), isAddressChange = false) => {
    if (!this.state.activeRunId) return
    point.run_id = this.state.activeRunId

    if (!isAddressChange) {
      delete point.address
    }

    this.props.dispatch(
      fieldTripRequestsActions.editPoint(
        point,
        this.state.activeTripId,
        this.state.activeRunId
      )
    )
  }

  addInRun = (
    type,
    title,
    time,
    waittime,
    address,
    position,
    pointId = null
  ) => {
    if (!this.isTripActive()) return

    const request = { fieldTripRuns: [] }
    const trip = this.state.fieldTripRequests.data.find(
      (x) => x.id == this.state.activeTripId
    )
    const runs = this.removeAddresses(trip.fieldTrip.fieldTripRuns)
    const run = runs.find((x) => x.id == this.state.activeRunId)

    if (!run) {
      return
    }

    runs.forEach((x) => request.fieldTripRuns.push(x))

    if (pointId) {
      let point = request.fieldTripRuns
        .find((x) => x.id == run.id)
        .fieldTripPoints.find((x) => x.id == pointId)

      point.time = time
      point.waittime = waittime
      point.title = title
      point.run_id = run.id

      if (address) {
        point.address = address
      }

      if (!position) {
        point.position = run.fieldTripPoints.length + 1
      } else {
        point.position = parseInt(position)
      }

      if (!time) {
        point.is_anchor = 0
      } else {
        point.is_anchor = 1
      }

      this.props.dispatch(
        fieldTripRequestsActions.editTrip(trip.fieldTrip.id, request)
      )
      return
    }

    const newPoint = {
      time: time,
      waittime: waittime,
      address: address,
      type: 3,
      title: title,
      run_id: run.id,
    }

    if (position) {
      newPoint.position = parseInt(position)
    } else {
      newPoint.position = run.fieldTripPoints.length + 1
    }

    if (time) {
      newPoint.is_anchor = 1
    } else {
      newPoint.is_anchor = 0
    }

    switch (type) {
      case 'school': {
        newPoint.type = 3
        break
      }
      case 'stop': {
        newPoint.type = 2
        break
      }
      case 'depot': {
        newPoint.type = 1
        break
      }
      default: {
        newPoint.type = 1
        break
      }
    }

    request.fieldTripRuns
      .find((x) => x.id == run.id)
      .fieldTripPoints.push(newPoint)

    this.props.dispatch(
      fieldTripRequestsActions.editTrip(trip.fieldTrip.id, request)
    )
  }

  deleteRider = (stopId, riderId, isAdditional) => {
    if (!this.isTripActive()) return

    const trip = this.state.fieldTripRequests.data.find(
      (x) => x.id == this.state.activeTripId
    )

    const run = trip.fieldTrip.fieldTripRuns.find(
      (x) => x.id == this.state.activeRunId
    )

    const point = run.fieldTripPoints.find((x) => x.id == stopId)
    delete point.address

    if (isAdditional) {
      point.fieldTripRiderAdults = point.fieldTripRiderAdults.filter(
        (rider) => rider.id !== riderId
      )
    } else {
      point.fieldTripRiders = point.fieldTripRiders.filter(
        (rider) => rider.id !== riderId
      )
    }

    this.props.dispatch(
      fieldTripRequestsActions.removeRiders(point, trip.id, run.id)
    )
  }

  isTripActive = () => {
    if (!this.state.activeTripId) {
      return false
    }

    return true
  }

  addRiders = (stopId, riders) => {
    if (!this.isTripActive()) return

    const trip = this.state.fieldTripRequests.data.find(
      (x) => x.id == this.state.activeTripId
    )

    const run = trip.fieldTrip.fieldTripRuns.find(
      (x) => x.id == this.state.activeRunId
    )

    const point = run.fieldTripPoints.find((x) => x.id == stopId)
    delete point.address

    riders.forEach((rider) => {
      point.fieldTripRiders.push({ child_id: rider.id, point_id: stopId })
    })

    this.props.dispatch(
      fieldTripRequestsActions.addRiders(point, trip.id, run.id)
    )
  }

  additionalRider = (point_id, rider_name, phone, note = null) => {
    if (!this.isTripActive()) return

    const request = { fieldTripRuns: [] }
    const trip = this.state.fieldTripRequests.data.find(
      (x) => x.id == this.state.activeTripId
    )

    const runs = this.removeAddresses(trip.fieldTrip.fieldTripRuns)
    runs.forEach((x) => request.fieldTripRuns.push(x))

    request.fieldTripRuns
      .find((x) => x.id == this.state.activeRunId)
      .fieldTripPoints.find((x) => x.id == point_id)
      .fieldTripRiderAdults.push({
        name: rider_name,
        phone: phone,
        notes: note,
      })

    request.fieldTripRuns
      .find((x) => x.id == this.state.activeRunId)
      .fieldTripPoints.map((point) => delete point.address)

    this.props.dispatch(
      fieldTripRequestsActions.editTrip(trip.fieldTrip.id, request)
    )
  }

  switchMap = () => {
    let maptype = this.state.maptype === 'roadmap' ? 'satellite' : 'roadmap'

    this.setState({
      maptype: maptype,
    })
  }

  zoomParam = (param) => {
    let data = [Math.random(), param]
    this.setState({
      mapZoom: data,
    })
  }

  targetRun = () => {
    this.setState({
      activeTarget: Math.random(),
    })
  }

  getChangeInputArray = (filters) => {
    let newArr = this.state.filters

    filters.forEach((filter) => {
      newArr[filter.type] = filter.e
    })

    this.getUnassignedList(newArr)
  }

  getUnassignedList = (filters) => {
    let viewChildrenList = this.state.childrenList

    if (filters.schools.length && filters.schools !== 'All') {
      viewChildrenList = viewChildrenList.filter((child) => {
        return child.addresses.find(
          (address) =>
            address && address.school && address.school.name === filters.schools
        )
      })
    }

    if (filters.grade.length && filters.grade != 'All') {
      viewChildrenList = viewChildrenList.filter((child) => {
        return child.classs == filters.grade
      })
    }

    if (filters.trans.length && filters.trans != 'All') {
      viewChildrenList = viewChildrenList.filter((child) => {
        return child.transportation_category == filters.trans
      })
    }

    if (filters.name.length) {
      viewChildrenList = viewChildrenList.filter((child) => {
        return !!(
          (child.full_name && child.full_name.includes(filters.name)) ||
          (child.id && child.id.toString().includes(filters.name))
        )
      })
    }

    this.setState({ viewChildrenList })
  }

  getOsrm = (osrm) => {
    this.setState({
      osrm: osrm,
    })
  }

  render() {
    let {
      modalSchool,
      modalStop,
      modalDepot,
      modalRider,
      modalAnchor,
      modalDeleteStop,
      activeTripId,
      activeRunId,
      activeRun,
      editData,
      mapZoom,
    } = this.state

    return (
      <div className="map">
        <div className="mapp" style={{ overflow: 'hidden' }}>
          <UnassignedRiders
            childrenList={this.state.viewChildrenList}
            addRidersFn={this.addRiders}
            stops={this.state.activeRunStops}
            seasonsId={this.state.activeSessionId}
            activeSchool={this.state.fieldTripRequests.data}
          />

          <MapFTBuilder
            activeTrip={activeTripId}
            activeRun={activeRunId}
            mapZoom={mapZoom}
            activeRuns={activeRun}
            maptype={this.state.maptype}
            osrm={this.getOsrm}
            activeTarget={this.state.activeTarget}
          />

          <MapElements
            getChangeInputArray={this.getChangeInputArray}
            switchMap={this.switchMap}
            maptype={this.state.maptype}
            zoomIn={() => this.zoomParam(1)}
            zoomOut={() => this.zoomParam(-1)}
            activeTripId={activeTripId}
            targetRun={this.targetRun}
          />

          <BuildFieldTrip
            buses={this.state.buses}
            childrenList={this.state.childrenList}
            drivers={this.state.drivers}
            toggleModalFn={this.toggleModal}
            toggleModalEditFn={this.toggleModalEdit}
            toggleModalAnchorFn={this.toggleModalAnchor}
            toggleModalDeletePointFn={this.toggleModalDeletePoint}
            setActiveRun={this.setActiveRun}
            setActiveRunPoints={this.setActiveRunPoints}
            setActiveTrip={this.setActiveTrip}
            submitTripFn={this.submitTrip}
            addRunFn={this.addRun}
            deletePointFn={this.deletePoint}
            removeRunFn={this.removeRun}
            transitionId={this.state.paramId}
            deleteRiderFn={this.deleteRider}
            osrm={this.state.osrm}
          />

          {modalSchool && (
            <ModalAddSchool
              createPointFn={this.createPoint}
              editPointFn={this.editPoint}
              editData={editData}
              schools={this.state.schools}
              close={() => this.toggleModal('modalSchool')}
            />
          )}

          {modalStop && (
            <ModalAddStop
              createPointFn={this.createPoint}
              editPointFn={this.editPoint}
              editData={editData}
              close={() => this.toggleModal('modalStop')}
            />
          )}

          {modalDepot && (
            <ModalAddDepot
              createPointFn={this.createPoint}
              editPointFn={this.editPoint}
              depots={this.state.depots}
              editData={editData}
              close={() => this.toggleModal('modalDepot')}
            />
          )}

          {modalRider && (
            <ModalAddRider
              addInRun={this.additionalRider}
              stops={this.state.activeRunStops}
              close={() => this.toggleModal('modalRider')}
            />
          )}

          {modalAnchor && (
            <ModalAnchor
              markAsAnchorFn={this.markPointAsAnchor}
              point={this.state.anchorPoint}
              close={this.closeModalAnchor}
            />
          )}

          {modalDeleteStop && (
            <ModalDeleteStop
              deleteStopFn={this.deletePoint}
              text={this.state.modalText}
              Riders={this.state.Riders}
              title={this.state.title}
              type={this.state.type}
              point={this.state.deletePoint}
              close={this.closeModalDelete}
            />
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    fieldTripRequests: state.fieldTripRequests,
    childrenList: state.children.childrenList,
    schools: state.schools,
    buses: state.buses.buses,
    drivers: state.drivers.drivers,
    seasonsID: state.sessions.activeSeason.id,
    activeSeason: state.sessions.activeSeason,
    activeSeasonId: state.sessions.activeSeason?.id,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fieldTripRequestsActions,
    busesActions,
    schoolsActions,
    companyActions,
    dispatch,
  }
}

const TripBuilder = (props) => {
  const depots = useGetBusDepotListQuery()

  return (
    <_TripBuilder {...props} depots={_.cloneDeep(depots.data?.data ?? [])} />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TripBuilder)
