import React from 'react'

export const WarningIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M7.5 0a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15zm0 12.287c-.586 0-.987-.451-.987-1.037 0-.602.418-1.037.987-1.037.602 0 .988.435.988 1.037 0 .586-.386 1.037-.988 1.037zm.389-3.961c-.15.509-.62.518-.777 0-.18-.598-.823-2.866-.823-4.34 0-1.943 2.432-1.952 2.432 0 0 1.483-.677 3.81-.832 4.34z"
    />
  </svg>
)
