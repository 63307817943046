import { create } from 'zustand'

export interface IRegistrationsStore {
  tab: 'opened' | 'closed'
  setTab: (tab: IRegistrationsStore['tab']) => void
  setShowRefresh: (setShowRefresh: boolean) => void
  isShowRefresh: boolean
}

export const useRegistrationsStore = create<IRegistrationsStore>((set) => ({
  tab: 'opened',
  isShowRefresh: false,
  setShowRefresh: (isShowRefresh) => set({ isShowRefresh }),
  setTab: (tab) => set({ tab }),
}))
