import { create } from 'zustand'

export interface IRequestsStore {
  tab: 'opened' | 'closed'
  setTab: (tab: IRequestsStore['tab']) => void
  setShowRefresh: (setShowRefresh: boolean) => void
  isShowRefresh: boolean
}

export const useRequestsStore = create<IRequestsStore>((set) => ({
  tab: 'opened',
  isShowRefresh: false,
  setShowRefresh: (isShowRefresh) => set({ isShowRefresh }),
  setTab: (tab) => set({ tab }),
}))
