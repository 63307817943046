import React from 'react'
import {
  DownOutlined,
  LeftOutlined,
  MinusCircleFilled,
  RightOutlined,
  UpOutlined,
} from '@ant-design/icons'

export const UpIcon = UpOutlined
export const MinusCircleIcon = MinusCircleFilled
export const LeftIcon = LeftOutlined
export const RightIcon = RightOutlined
export const DownIconOutlined = DownOutlined

export const ArrowSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="6"
    viewBox="0 0 11 6"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M10.698.733a.318.318 0 0 1 0 .462L6.017 5.876a.318.318 0 0 1-.462 0L.874 1.196a.318.318 0 0 1 0-.463l.502-.502a.318.318 0 0 1 .462 0l3.948 3.948L9.733.23a.318.318 0 0 1 .462 0l.503.502z"
    />
  </svg>
)
const FilterToolSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M4.521 5.67a.74.74 0 0 1 .195.502v5.456a.37.37 0 0 0 .631.265l1.522-1.745c.204-.244.316-.365.316-.607V6.173c0-.185.07-.364.195-.501l4.368-4.74A.556.556 0 0 0 11.34 0H.562a.556.556 0 0 0-.409.932L4.521 5.67z"
    />
  </svg>
)

const DropdownSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="6"
    viewBox="0 0 11 6"
  >
    <path
      fill="#000000"
      fillRule="nonzero"
      d="M10.698.733a.318.318 0 0 1 0 .462L6.017 5.876a.318.318 0 0 1-.462 0L.874 1.196a.318.318 0 0 1 0-.463l.502-.502a.318.318 0 0 1 .462 0l3.948 3.948L9.733.23a.318.318 0 0 1 .462 0l.503.502z"
    />
  </svg>
)

const LeftArrowSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="6"
    viewBox="0 0 11 6"
    style={{ transform: 'rotate(90deg)' }}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M10.698.733a.318.318 0 0 1 0 .462L6.017 5.876a.318.318 0 0 1-.462 0L.874 1.196a.318.318 0 0 1 0-.463l.502-.502a.318.318 0 0 1 .462 0l3.948 3.948L9.733.23a.318.318 0 0 1 .462 0l.503.502z"
    />
  </svg>
)

const RightArrowSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="6"
    viewBox="0 0 11 6"
    {...props}
    style={{ transform: 'rotate(-90deg)', ...(props?.style || {}) }}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M10.698.733a.318.318 0 0 1 0 .462L6.017 5.876a.318.318 0 0 1-.462 0L.874 1.196a.318.318 0 0 1 0-.463l.502-.502a.318.318 0 0 1 .462 0l3.948 3.948L9.733.23a.318.318 0 0 1 .462 0l.503.502z"
    />
  </svg>
)

export const FieldTripRequestsSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M7.5 0a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15zm0 12.287c-.586 0-.987-.451-.987-1.037 0-.602.418-1.037.987-1.037.602 0 .988.435.988 1.037 0 .586-.386 1.037-.988 1.037zm.389-3.961c-.15.509-.62.518-.777 0-.18-.598-.823-2.866-.823-4.34 0-1.943 2.432-1.952 2.432 0 0 1.483-.677 3.81-.832 4.34z"
    />
  </svg>
)

export const AnchorIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
    >
      <path
        fill="#1E334D"
        fillRule="nonzero"
        d="M6.301 2.73a.412.412 0 0 0 .128-.301.412.412 0 0 0-.128-.302A.412.412 0 0 0 6 2a.412.412 0 0 0-.301.127.412.412 0 0 0-.128.302c0 .116.043.216.128.3A.412.412 0 0 0 6 2.858a.412.412 0 0 0 .301-.127zM12 8.643V11c0 .098-.045.165-.134.2a.366.366 0 0 1-.08.014.24.24 0 0 1-.154-.06l-.623-.623c-.531.639-1.242 1.144-2.133 1.517-.89.373-1.85.56-2.876.56a7.373 7.373 0 0 1-2.876-.56c-.89-.373-1.602-.878-2.133-1.517l-.623.623a.209.209 0 0 1-.154.06.366.366 0 0 1-.08-.013C.044 11.165 0 11.098 0 11V8.643c0-.063.02-.114.06-.154.04-.04.092-.06.154-.06h2.357c.099 0 .166.044.201.133.036.085.02.163-.047.235l-.67.67c.3.406.723.748 1.27 1.027a5.611 5.611 0 0 0 1.818.553V6.714H3.857a.412.412 0 0 1-.301-.127.412.412 0 0 1-.127-.301v-.857c0-.117.042-.217.127-.302A.412.412 0 0 1 3.857 5h1.286V3.908a1.743 1.743 0 0 1-.623-.619 1.645 1.645 0 0 1-.234-.86c0-.474.167-.878.502-1.212A1.652 1.652 0 0 1 6 .714c.473 0 .877.168 1.212.503.335.334.502.738.502 1.212 0 .312-.078.599-.234.86a1.743 1.743 0 0 1-.623.62V5h1.286c.116 0 .216.042.301.127a.412.412 0 0 1 .127.302v.857a.412.412 0 0 1-.127.301.412.412 0 0 1-.301.127H6.857v4.333a5.611 5.611 0 0 0 1.818-.553c.547-.279.97-.621 1.27-1.027l-.67-.67c-.067-.072-.083-.15-.047-.235.035-.089.102-.133.2-.133h2.358c.062 0 .114.02.154.06.04.04.06.091.06.154z"
      />
    </svg>
  )
}
export const EditIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        fill="#1E334D"
        fillRule="nonzero"
        d="M8.154 2.185l2.668 2.668-6.752 6.752-2.667-2.667 6.751-6.753zm4.692-.643l-1.19-1.19a1.18 1.18 0 0 0-1.667 0l-1.14 1.14 2.668 2.667 1.33-1.33a.91.91 0 0 0 0-1.287zM.007 12.687a.304.304 0 0 0 .368.361l2.972-.72L.681 9.66l-.674 3.027z"
      />
    </svg>
  )
}

export const TrashIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="12"
      viewBox="0 0 11 12"
      {...props}
    >
      <path
        fill="#ED4D49"
        fillRule="nonzero"
        d="M4 9.75v-5.5a.243.243 0 0 0-.07-.18.243.243 0 0 0-.18-.07h-.5a.243.243 0 0 0-.18.07.243.243 0 0 0-.07.18v5.5c0 .073.023.133.07.18.047.047.107.07.18.07h.5a.243.243 0 0 0 .18-.07.243.243 0 0 0 .07-.18zm2 0v-5.5a.243.243 0 0 0-.07-.18.243.243 0 0 0-.18-.07h-.5a.243.243 0 0 0-.18.07.243.243 0 0 0-.07.18v5.5c0 .073.023.133.07.18.047.047.107.07.18.07h.5a.243.243 0 0 0 .18-.07.243.243 0 0 0 .07-.18zm2 0v-5.5a.243.243 0 0 0-.07-.18.243.243 0 0 0-.18-.07h-.5a.243.243 0 0 0-.18.07.243.243 0 0 0-.07.18v5.5c0 .073.023.133.07.18.047.047.107.07.18.07h.5a.243.243 0 0 0 .18-.07.243.243 0 0 0 .07-.18zM3.75 2h3.5l-.375-.914A.22.22 0 0 0 6.742 1H4.266a.22.22 0 0 0-.133.086L3.75 2zm7.25.25v.5a.243.243 0 0 1-.07.18.243.243 0 0 1-.18.07H10v7.406c0 .433-.122.806-.367 1.121-.245.315-.54.473-.883.473h-6.5c-.344 0-.638-.152-.883-.457C1.122 11.238 1 10.87 1 10.438V3H.25a.243.243 0 0 1-.18-.07.243.243 0 0 1-.07-.18v-.5c0-.073.023-.133.07-.18A.243.243 0 0 1 .25 2h2.414L3.211.695c.078-.192.219-.356.422-.492C3.836.068 4.042 0 4.25 0h2.5c.208 0 .414.068.617.203.203.136.344.3.422.492L8.336 2h2.414c.073 0 .133.023.18.07.047.047.07.107.07.18z"
      />
    </svg>
  )
}

export const FilterToolIcon = (props) => <FilterToolSvg {...props} />

export const DropdownIcon = (props) => <DropdownSvg {...props} />

export const RightArrowIcon = (props) => <RightArrowSvg {...props} />

export const LeftArrowIcon = (props) => <LeftArrowSvg {...props} />

export const FieldTripRequestsIcon = (props) => (
  <FieldTripRequestsSvg {...props} />
)

export const CalendarIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
    <g fill="#1E334D" fillRule="nonzero">
      <path d="M6.362 9.912a.408.408 0 0 0-.408-.407H4.531a.408.408 0 0 0-.408.407v1.423c0 .225.183.408.408.408h1.423a.408.408 0 0 0 .408-.408V9.912zM9.918 9.912a.407.407 0 0 0-.407-.407H8.088a.408.408 0 0 0-.408.407v1.423c0 .225.183.408.408.408H9.51a.408.408 0 0 0 .407-.408V9.912zM13.475 9.912a.408.408 0 0 0-.408-.407h-1.423a.408.408 0 0 0-.408.407v1.423c0 .225.183.408.408.408h1.423a.408.408 0 0 0 .408-.408V9.912zM6.362 13.469a.407.407 0 0 0-.408-.408H4.531a.407.407 0 0 0-.408.408v1.422c0 .226.183.408.408.408h1.423a.408.408 0 0 0 .408-.408V13.47zM9.918 13.469a.407.407 0 0 0-.407-.408H8.088a.407.407 0 0 0-.408.408v1.422c0 .226.183.408.408.408H9.51a.408.408 0 0 0 .407-.408V13.47zM13.475 13.469a.407.407 0 0 0-.407-.408h-1.424a.407.407 0 0 0-.408.408v1.422c0 .226.183.408.408.408h1.424a.408.408 0 0 0 .407-.408V13.47z" />
      <path d="M16.02 2v2.174c0 .982-.797 1.774-1.78 1.774h-1.122c-.982 0-1.79-.792-1.79-1.774V1.993H6.27v2.18c0 .983-.807 1.775-1.79 1.775H3.358a1.775 1.775 0 0 1-1.78-1.774V2A1.618 1.618 0 0 0 .016 3.61v12.736c0 .89.721 1.62 1.61 1.62h14.348a1.62 1.62 0 0 0 1.61-1.62V3.61c0-.873-.705-1.584-1.564-1.61zm-.522 13.553a.696.696 0 0 1-.696.697H2.766a.696.696 0 0 1-.697-.697V8.974c0-.384.312-.696.697-.696h12.036c.385 0 .696.312.696.696v6.58z" />
      <path d="M3.354 4.783h1.11a.61.61 0 0 0 .61-.61V.61a.61.61 0 0 0-.61-.61h-1.11a.61.61 0 0 0-.61.61v3.563c0 .337.273.61.61.61zM13.104 4.783h1.11a.61.61 0 0 0 .61-.61V.61a.61.61 0 0 0-.61-.61h-1.11a.61.61 0 0 0-.61.61v3.563c0 .337.273.61.61.61z" />
    </g>
  </svg>
)

export const ClearIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fill="#A6A6A6"
      fillRule="nonzero"
      d="M.313 11.687c.209.209.47.313.757.313.287 0 .547-.104.756-.313L6 7.513l4.174 4.174c.209.209.47.313.756.313a1.07 1.07 0 0 0 .757-1.826L7.513 6l4.174-4.174A1.07 1.07 0 0 0 10.174.313L6 4.487 1.826.313A1.07 1.07 0 0 0 .313 1.826L4.487 6 .313 10.174a1.07 1.07 0 0 0 0 1.513z"
    />
  </svg>
)

export const TimeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    {...props}
  >
    <path
      fill="#1E334D"
      fillRule="nonzero"
      d="M9 5.036v4.5c0 .093-.03.17-.09.23a.313.313 0 0 1-.231.091H5.464a.313.313 0 0 1-.23-.09.313.313 0 0 1-.091-.231v-.643c0-.094.03-.17.09-.231.06-.06.138-.09.231-.09h2.25V5.035c0-.094.03-.171.09-.231.061-.06.138-.09.232-.09h.643c.093 0 .17.03.23.09s.091.137.091.23zm3.445 6.278a5.341 5.341 0 0 0 .734-2.743c0-.99-.245-1.905-.734-2.742a5.447 5.447 0 0 0-1.989-1.989 5.341 5.341 0 0 0-2.742-.733c-.99 0-1.905.245-2.742.733a5.447 5.447 0 0 0-1.989 1.99A5.341 5.341 0 0 0 2.25 8.57c0 .992.244 1.906.733 2.743a5.447 5.447 0 0 0 1.99 1.988 5.341 5.341 0 0 0 2.741.734c.991 0 1.905-.245 2.742-.734a5.447 5.447 0 0 0 1.99-1.988zm1.949-6.61a7.52 7.52 0 0 1 1.035 3.867c0 1.4-.345 2.69-1.035 3.873a7.68 7.68 0 0 1-2.808 2.807 7.546 7.546 0 0 1-3.872 1.035c-1.4 0-2.69-.345-3.872-1.035a7.68 7.68 0 0 1-2.807-2.807A7.546 7.546 0 0 1 0 8.57c0-1.4.345-2.69 1.035-3.872a7.68 7.68 0 0 1 2.807-2.807A7.546 7.546 0 0 1 7.714.857c1.4 0 2.69.345 3.872 1.035a7.668 7.668 0 0 1 2.808 2.812z"
    />
  </svg>
)

export const LockIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="14"
    viewBox="0 0 12 14"
    {...props}
  >
    <path
      fill="#000"
      fillRule="nonzero"
      d="M11.195 6.642a.92.92 0 0 0-.676-.278H10.2v-1.91c0-1.22-.438-2.266-1.313-3.141S6.966 0 5.746 0 3.48.438 2.604 1.313 1.292 3.235 1.292 4.454v1.91H.974a.92.92 0 0 0-.677.278.92.92 0 0 0-.278.676v5.727c0 .265.093.491.278.677A.921.921 0 0 0 .974 14h9.545a.92.92 0 0 0 .676-.278.922.922 0 0 0 .278-.677V7.318a.92.92 0 0 0-.278-.676zm-2.903-.278H3.2v-1.91c0-.702.248-1.302.746-1.8a2.453 2.453 0 0 1 1.8-.745c.702 0 1.302.249 1.799.746.497.497.746 1.097.746 1.8v1.909z"
    />
  </svg>
)

export const UnlockIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="14"
    viewBox="0 0 11 14"
    {...props}
  >
    <path
      fill="#ED5551"
      fillRule="nonzero"
      d="M10.27 7.255A.844.844 0 0 0 9.65 7H2.944V4.083c0-.644.228-1.194.683-1.65a2.248 2.248 0 0 1 1.65-.683c.644 0 1.194.228 1.65.684.456.455.683 1.005.683 1.65a.56.56 0 0 0 .174.41.561.561 0 0 0 .41.173h.583a.561.561 0 0 0 .41-.174.562.562 0 0 0 .173-.41c0-1.124-.4-2.085-1.198-2.884C7.361.399 6.401 0 5.276 0 4.152 0 3.19.4 2.391 1.199c-.799.799-1.198 1.76-1.198 2.884V7H.9a.844.844 0 0 0-.62.255.843.843 0 0 0-.255.62v5.25c0 .243.085.45.255.62.17.17.377.255.62.255h8.75c.243 0 .45-.085.62-.255a.844.844 0 0 0 .255-.62v-5.25a.843.843 0 0 0-.255-.62z"
    />
  </svg>
)

export const ADoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    {...props}
  >
    <defs>
      <filter
        id="a"
        width="163.2%"
        height="163.2%"
        x="-31.6%"
        y="-31.6%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.280400815 0"
        />
      </filter>
      <circle id="b" cx="9.5" cy="9.5" r="9.5" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(4 4)">
      <use fill="#000" filter="url(#a)" xlinkHref="#b" />
      <use fill="#F6BF4F" xlinkHref="#b" />
      <path
        fill="#737373"
        fillRule="nonzero"
        d="M5.699 10.934h.997c.162 0 .319-.026.47-.078.153-.052.288-.139.407-.26.118-.121.213-.279.285-.474.07-.195.107-.432.107-.712 0-.257-.025-.488-.075-.695a1.38 1.38 0 00-.246-.53 1.1 1.1 0 00-.453-.34 1.81 1.81 0 00-.694-.117h-.798v3.207zM4.58 6.788h2.195c.327 0 .633.052.915.156.283.105.527.262.734.47.207.21.368.471.485.784.116.314.174.682.174 1.105 0 .37-.047.712-.142 1.026a2.266 2.266 0 01-.431.812 2.035 2.035 0 01-.72.538c-.287.13-.626.196-1.015.196H4.58V6.788zm6.178 2.565c0 .214.024.421.074.623.05.202.13.384.24.545.108.162.25.291.427.389.175.097.39.146.64.146.253 0 .466-.05.642-.146.176-.098.318-.227.428-.389.109-.161.188-.343.238-.545a2.778 2.778 0 000-1.272 1.746 1.746 0 00-.238-.559 1.222 1.222 0 00-.428-.392 1.312 1.312 0 00-.641-.146c-.252 0-.466.049-.641.146a1.213 1.213 0 00-.428.392 1.7 1.7 0 00-.239.56c-.05.208-.074.424-.074.648zm-1.12 0c0-.38.058-.733.172-1.058.114-.326.278-.61.492-.852.213-.242.476-.432.787-.57a2.562 2.562 0 011.05-.206c.395 0 .747.068 1.055.206.31.138.57.328.784.57.214.243.378.526.492.852.114.325.17.678.17 1.058 0 .37-.056.716-.17 1.036-.114.321-.278.6-.492.838a2.262 2.262 0 01-.784.559c-.308.135-.66.203-1.054.203-.39 0-.74-.068-1.051-.203a2.236 2.236 0 01-.787-.56 2.463 2.463 0 01-.492-.837 3.068 3.068 0 01-.171-1.036z"
      />
    </g>
  </svg>
)

export const FinishedDOIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="27"
    height="27"
    viewBox="0 0 27 27"
  >
    <defs>
      <filter
        id="a"
        width="163.2%"
        height="163.2%"
        x="-31.6%"
        y="-31.6%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.280400815 0"
        />
      </filter>
      <circle id="b" cx="9.5" cy="9.5" r="9.5" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(4 4)">
      <use fill="#000" filter="url(#a)" xlinkHref="#b" />
      <use fill="#000000" xlinkHref="#b" />
      <path
        fill="#FFFFFF"
        fillRule="nonzero"
        d="M5.699 10.934h.997c.162 0 .319-.026.47-.078.153-.052.288-.139.407-.26.118-.121.213-.279.285-.474.07-.195.107-.432.107-.712 0-.257-.025-.488-.075-.695a1.38 1.38 0 00-.246-.53 1.1 1.1 0 00-.453-.34 1.81 1.81 0 00-.694-.117h-.798v3.207zM4.58 6.788h2.195c.327 0 .633.052.915.156.283.105.527.262.734.47.207.21.368.471.485.784.116.314.174.682.174 1.105 0 .37-.047.712-.142 1.026a2.266 2.266 0 01-.431.812 2.035 2.035 0 01-.72.538c-.287.13-.626.196-1.015.196H4.58V6.788zm6.178 2.565c0 .214.024.421.074.623.05.202.13.384.24.545.108.162.25.291.427.389.175.097.39.146.64.146.253 0 .466-.05.642-.146.176-.098.318-.227.428-.389.109-.161.188-.343.238-.545a2.778 2.778 0 000-1.272 1.746 1.746 0 00-.238-.559 1.222 1.222 0 00-.428-.392 1.312 1.312 0 00-.641-.146c-.252 0-.466.049-.641.146a1.213 1.213 0 00-.428.392 1.7 1.7 0 00-.239.56c-.05.208-.074.424-.074.648zm-1.12 0c0-.38.058-.733.172-1.058.114-.326.278-.61.492-.852.213-.242.476-.432.787-.57a2.562 2.562 0 011.05-.206c.395 0 .747.068 1.055.206.31.138.57.328.784.57.214.243.378.526.492.852.114.325.17.678.17 1.058 0 .37-.056.716-.17 1.036-.114.321-.278.6-.492.838a2.262 2.262 0 01-.784.559c-.308.135-.66.203-1.054.203-.39 0-.74-.068-1.051-.203a2.236 2.236 0 01-.787-.56 2.463 2.463 0 01-.492-.837 3.068 3.068 0 01-.171-1.036z"
      />
    </g>
  </svg>
)

export const APuIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    {...props}
  >
    <defs>
      <filter
        id="a"
        width="163.2%"
        height="163.2%"
        x="-31.6%"
        y="-31.6%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.280400815 0"
        />
      </filter>
      <circle id="b" cx="9.5" cy="9.5" r="9.5" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(4 4)">
      <use fill="#000" filter="url(#a)" xlinkHref="#b" />
      <use fill="#F6BF4F" xlinkHref="#b" />
      <path
        fill="#737373"
        fillRule="nonzero"
        d="M6.094 9.182h.87c.128 0 .251-.01.37-.029a.88.88 0 00.314-.11.608.608 0 00.217-.232c.054-.1.082-.23.082-.392a.824.824 0 00-.082-.392.6.6 0 00-.218-.231.85.85 0 00-.313-.11 2.228 2.228 0 00-.37-.029h-.87v1.525zM4.976 6.788H7.27c.318 0 .589.046.812.139a1.422 1.422 0 01.852.887 1.925 1.925 0 010 1.207 1.414 1.414 0 01-.852.891 2.11 2.11 0 01-.812.139H6.094v1.824H4.976V6.788zm9.07 3.163c0 .689-.193 1.2-.577 1.536-.385.335-.917.502-1.596.502-.69 0-1.222-.166-1.6-.499-.378-.332-.566-.845-.566-1.539V6.788h1.118V9.95c0 .138.012.273.036.406.024.133.073.25.15.353a.831.831 0 00.317.25c.135.064.317.096.545.096.399 0 .674-.09.826-.268.152-.178.228-.457.228-.837V6.788h1.119V9.95z"
      />
    </g>
  </svg>
)

export const DoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    {...props}
  >
    <defs>
      <filter
        id="a"
        width="163.2%"
        height="163.2%"
        x="-31.6%"
        y="-31.6%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.280400815 0"
        />
      </filter>
      <circle id="b" cx="9.5" cy="9.5" r="9.5" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(4 4)">
      <use fill="#000" filter="url(#a)" xlinkHref="#b" />
      <use fill="#F5F5F5" xlinkHref="#b" />
      <path
        fill="#737373"
        fillRule="nonzero"
        d="M5.699 10.934h.997c.162 0 .319-.026.47-.078.153-.052.288-.139.407-.26.118-.121.213-.279.285-.474.07-.195.107-.432.107-.712 0-.257-.025-.488-.075-.695a1.38 1.38 0 00-.246-.53 1.1 1.1 0 00-.453-.34 1.81 1.81 0 00-.694-.117h-.798v3.207zM4.58 6.788h2.195c.327 0 .633.052.915.156.283.105.527.262.734.47.207.21.368.471.485.784.116.314.174.682.174 1.105 0 .37-.047.712-.142 1.026a2.266 2.266 0 01-.431.812 2.035 2.035 0 01-.72.538c-.287.13-.626.196-1.015.196H4.58V6.788zm6.178 2.565c0 .214.024.421.074.623.05.202.13.384.24.545.108.162.25.291.427.389.175.097.39.146.64.146.253 0 .466-.05.642-.146.176-.098.318-.227.428-.389.109-.161.188-.343.238-.545a2.778 2.778 0 000-1.272 1.746 1.746 0 00-.238-.559 1.222 1.222 0 00-.428-.392 1.312 1.312 0 00-.641-.146c-.252 0-.466.049-.641.146a1.213 1.213 0 00-.428.392 1.7 1.7 0 00-.239.56c-.05.208-.074.424-.074.648zm-1.12 0c0-.38.058-.733.172-1.058.114-.326.278-.61.492-.852.213-.242.476-.432.787-.57a2.562 2.562 0 011.05-.206c.395 0 .747.068 1.055.206.31.138.57.328.784.57.214.243.378.526.492.852.114.325.17.678.17 1.058 0 .37-.056.716-.17 1.036-.114.321-.278.6-.492.838a2.262 2.262 0 01-.784.559c-.308.135-.66.203-1.054.203-.39 0-.74-.068-1.051-.203a2.236 2.236 0 01-.787-.56 2.463 2.463 0 01-.492-.837 3.068 3.068 0 01-.171-1.036z"
      />
    </g>
  </svg>
)

export const PuIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    {...props}
  >
    <defs>
      <filter
        id="a"
        width="163.2%"
        height="163.2%"
        x="-31.6%"
        y="-31.6%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.280400815 0"
        />
      </filter>
      <circle id="b" cx="9.5" cy="9.5" r="9.5" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(4 4)">
      <use fill="#000" filter="url(#a)" xlinkHref="#b" />
      <use fill="#F5F5F5" xlinkHref="#b" />
      <path
        fill="#737373"
        fillRule="nonzero"
        d="M6.094 9.182h.87c.128 0 .251-.01.37-.029a.88.88 0 00.314-.11.608.608 0 00.217-.232c.054-.1.082-.23.082-.392a.824.824 0 00-.082-.392.6.6 0 00-.218-.231.85.85 0 00-.313-.11 2.228 2.228 0 00-.37-.029h-.87v1.525zM4.976 6.788H7.27c.318 0 .589.046.812.139a1.422 1.422 0 01.852.887 1.925 1.925 0 010 1.207 1.414 1.414 0 01-.852.891 2.11 2.11 0 01-.812.139H6.094v1.824H4.976V6.788zm9.07 3.163c0 .689-.193 1.2-.577 1.536-.385.335-.917.502-1.596.502-.69 0-1.222-.166-1.6-.499-.378-.332-.566-.845-.566-1.539V6.788h1.118V9.95c0 .138.012.273.036.406.024.133.073.25.15.353a.831.831 0 00.317.25c.135.064.317.096.545.096.399 0 .674-.09.826-.268.152-.178.228-.457.228-.837V6.788h1.119V9.95z"
      />
    </g>
  </svg>
)

export const RiderIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="82"
    height="82"
    viewBox="0 0 82 82"
    {...props}
  >
    <defs>
      <circle id="a" cx="41" cy="41" r="41" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use />
      </mask>
      <use fill="#A0A0A0" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M42 17c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15zm-.5 38C57.799 55 71 66.038 71 79.667V92H12V79.667C12 66.038 25.201 55 41.5 55z"
        mask="url(#b)"
      />
    </g>
  </svg>
)

export const ViewFilterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="15"
    viewBox="0 0 40 15"
    {...props}
  >
    <path
      fill="#63AC12"
      d="M2.606.727H5.05l-2.182 11.21h6.404L8.848 14.2H0L2.606.727zm10.746 0h2.444L13.17 14.2h-2.444L13.352.727zm3.94 0h2.625l1.515 10.463L26.927.727h2.706L22.16 14.2h-2.605L17.29.727zm13.998 0h8.383l-.445 2.222H33.29l-.627 3.211h4.384l-.424 2.222h-4.384l-.687 3.556h6.404L37.51 14.2h-8.827L31.29.727z"
    />
  </svg>
)

export const ModalCloneIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fill="#ED4D49"
      fillRule="nonzero"
      d="M6 0a6 6 0 1 0 0 12A6 6 0 0 0 6 0zm0 10.875a4.875 4.875 0 1 1 0-9.75 4.875 4.875 0 0 1 0 9.75zm0-8.244c-.43 0-.755.225-.755.587V6.54c0 .363.326.586.755.586.42 0 .755-.232.755-.586V3.218c0-.354-.335-.587-.755-.587zm0 5.244a.75.75 0 0 0 0 1.498.75.75 0 0 0 0-1.498z"
    />
  </svg>
)

export const UploadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    {...props}
  >
    <path
      fill="#FFF"
      fillRule="nonzero"
      d="M7.969 7.977C8.656 7.289 9 6.464 9 5.5a3.37 3.37 0 0 0-1.027-2.473A3.37 3.37 0 0 0 5.5 2a3.37 3.37 0 0 0-2.473 1.027A3.37 3.37 0 0 0 2 5.5a3.37 3.37 0 0 0 1.027 2.473A3.37 3.37 0 0 0 5.5 9c.964 0 1.786-.341 2.469-1.023zM13 12c0 .27-.099.505-.297.703A.961.961 0 0 1 12 13a.928.928 0 0 1-.703-.297l-2.68-2.672A5.35 5.35 0 0 1 5.5 11a5.404 5.404 0 0 1-2.137-.434 5.5 5.5 0 0 1-1.758-1.171A5.5 5.5 0 0 1 .434 7.637 5.404 5.404 0 0 1 0 5.5c0-.745.145-1.457.434-2.137a5.5 5.5 0 0 1 1.171-1.758A5.5 5.5 0 0 1 3.363.434 5.404 5.404 0 0 1 5.5 0c.745 0 1.457.145 2.137.434a5.5 5.5 0 0 1 1.758 1.171 5.5 5.5 0 0 1 1.171 1.758c.29.68.434 1.392.434 2.137a5.35 5.35 0 0 1-.969 3.117l2.68 2.68c.193.193.289.427.289.703z"
    />
  </svg>
)

export const APuBsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    {...props}
  >
    <defs>
      <filter
        id="a"
        width="163.2%"
        height="163.2%"
        x="-31.6%"
        y="-31.6%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.280400815 0"
        />
      </filter>
      <circle id="b" cx="9.5" cy="9.5" r="9.5" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(4 4)">
      <use fill="#000" filter="url(#a)" xlinkHref="#b" />
      <use fill="#F6BF4F" xlinkHref="#b" />
      <path
        fill="#737373"
        fillRule="nonzero"
        d="M5.699 10.934h.997c.162 0 .319-.026.47-.078.153-.052.288-.139.407-.26.118-.121.213-.279.285-.474.07-.195.107-.432.107-.712 0-.257-.025-.488-.075-.695a1.38 1.38 0 00-.246-.53 1.1 1.1 0 00-.453-.34 1.81 1.81 0 00-.694-.117h-.798v3.207zM4.58 6.788h2.195c.327 0 .633.052.915.156.283.105.527.262.734.47.207.21.368.471.485.784.116.314.174.682.174 1.105 0 .37-.047.712-.142 1.026a2.266 2.266 0 01-.431.812 2.035 2.035 0 01-.72.538c-.287.13-.626.196-1.015.196H4.58V6.788zm6.178 2.565c0 .214.024.421.074.623.05.202.13.384.24.545.108.162.25.291.427.389.175.097.39.146.64.146.253 0 .466-.05.642-.146.176-.098.318-.227.428-.389.109-.161.188-.343.238-.545a2.778 2.778 0 000-1.272 1.746 1.746 0 00-.238-.559 1.222 1.222 0 00-.428-.392 1.312 1.312 0 00-.641-.146c-.252 0-.466.049-.641.146a1.213 1.213 0 00-.428.392 1.7 1.7 0 00-.239.56c-.05.208-.074.424-.074.648zm-1.12 0c0-.38.058-.733.172-1.058.114-.326.278-.61.492-.852.213-.242.476-.432.787-.57a2.562 2.562 0 011.05-.206c.395 0 .747.068 1.055.206.31.138.57.328.784.57.214.243.378.526.492.852.114.325.17.678.17 1.058 0 .37-.056.716-.17 1.036-.114.321-.278.6-.492.838a2.262 2.262 0 01-.784.559c-.308.135-.66.203-1.054.203-.39 0-.74-.068-1.051-.203a2.236 2.236 0 01-.787-.56 2.463 2.463 0 01-.492-.837 3.068 3.068 0 01-.171-1.036z"
      />
    </g>
  </svg>
)

export const ADOBSIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    {...props}
  >
    <defs>
      <filter
        id="a"
        width="163.2%"
        height="163.2%"
        x="-31.6%"
        y="-31.6%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.280400815 0"
        />
      </filter>
      <circle id="b" cx="9.5" cy="9.5" r="9.5" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(4 4)">
      <use fill="#000" filter="url(#a)" xlinkHref="#b" />
      <use fill="#F6BF4F" xlinkHref="#b" />
      <path
        fill="#737373"
        fillRule="nonzero"
        d="M6.094 9.182h.87c.128 0 .251-.01.37-.029a.88.88 0 00.314-.11.608.608 0 00.217-.232c.054-.1.082-.23.082-.392a.824.824 0 00-.082-.392.6.6 0 00-.218-.231.85.85 0 00-.313-.11 2.228 2.228 0 00-.37-.029h-.87v1.525zM4.976 6.788H7.27c.318 0 .589.046.812.139a1.422 1.422 0 01.852.887 1.925 1.925 0 010 1.207 1.414 1.414 0 01-.852.891 2.11 2.11 0 01-.812.139H6.094v1.824H4.976V6.788zm9.07 3.163c0 .689-.193 1.2-.577 1.536-.385.335-.917.502-1.596.502-.69 0-1.222-.166-1.6-.499-.378-.332-.566-.845-.566-1.539V6.788h1.118V9.95c0 .138.012.273.036.406.024.133.073.25.15.353a.831.831 0 00.317.25c.135.064.317.096.545.096.399 0 .674-.09.826-.268.152-.178.228-.457.228-.837V6.788h1.119V9.95z"
      />
    </g>
  </svg>
)

export const PuBsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    {...props}
  >
    <defs>
      <filter
        id="a"
        width="163.2%"
        height="163.2%"
        x="-31.6%"
        y="-31.6%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.280400815 0"
        />
      </filter>
      <circle id="b" cx="9.5" cy="9.5" r="9.5" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(4 4)">
      <use fill="#000" filter="url(#a)" xlinkHref="#b" />
      <use fill="#F5F5F5" xlinkHref="#b" />
      <path
        fill="#737373"
        fillRule="nonzero"
        d="M6.094 9.182h.87c.128 0 .251-.01.37-.029a.88.88 0 00.314-.11.608.608 0 00.217-.232c.054-.1.082-.23.082-.392a.824.824 0 00-.082-.392.6.6 0 00-.218-.231.85.85 0 00-.313-.11 2.228 2.228 0 00-.37-.029h-.87v1.525zM4.976 6.788H7.27c.318 0 .589.046.812.139a1.422 1.422 0 01.852.887 1.925 1.925 0 010 1.207 1.414 1.414 0 01-.852.891 2.11 2.11 0 01-.812.139H6.094v1.824H4.976V6.788zm9.07 3.163c0 .689-.193 1.2-.577 1.536-.385.335-.917.502-1.596.502-.69 0-1.222-.166-1.6-.499-.378-.332-.566-.845-.566-1.539V6.788h1.118V9.95c0 .138.012.273.036.406.024.133.073.25.15.353a.831.831 0 00.317.25c.135.064.317.096.545.096.399 0 .674-.09.826-.268.152-.178.228-.457.228-.837V6.788h1.119V9.95z"
      />
    </g>
  </svg>
)

export const DoBSIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    {...props}
  >
    <defs>
      <filter
        id="a"
        width="163.2%"
        height="163.2%"
        x="-31.6%"
        y="-31.6%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.280400815 0"
        />
      </filter>
      <circle id="b" cx="9.5" cy="9.5" r="9.5" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(4 4)">
      <use fill="#000" filter="url(#a)" xlinkHref="#b" />
      <use fill="#F5F5F5" xlinkHref="#b" />
      <path
        fill="#737373"
        fillRule="nonzero"
        d="M5.699 10.934h.997c.162 0 .319-.026.47-.078.153-.052.288-.139.407-.26.118-.121.213-.279.285-.474.07-.195.107-.432.107-.712 0-.257-.025-.488-.075-.695a1.38 1.38 0 00-.246-.53 1.1 1.1 0 00-.453-.34 1.81 1.81 0 00-.694-.117h-.798v3.207zM4.58 6.788h2.195c.327 0 .633.052.915.156.283.105.527.262.734.47.207.21.368.471.485.784.116.314.174.682.174 1.105 0 .37-.047.712-.142 1.026a2.266 2.266 0 01-.431.812 2.035 2.035 0 01-.72.538c-.287.13-.626.196-1.015.196H4.58V6.788zm6.178 2.565c0 .214.024.421.074.623.05.202.13.384.24.545.108.162.25.291.427.389.175.097.39.146.64.146.253 0 .466-.05.642-.146.176-.098.318-.227.428-.389.109-.161.188-.343.238-.545a2.778 2.778 0 000-1.272 1.746 1.746 0 00-.238-.559 1.222 1.222 0 00-.428-.392 1.312 1.312 0 00-.641-.146c-.252 0-.466.049-.641.146a1.213 1.213 0 00-.428.392 1.7 1.7 0 00-.239.56c-.05.208-.074.424-.074.648zm-1.12 0c0-.38.058-.733.172-1.058.114-.326.278-.61.492-.852.213-.242.476-.432.787-.57a2.562 2.562 0 011.05-.206c.395 0 .747.068 1.055.206.31.138.57.328.784.57.214.243.378.526.492.852.114.325.17.678.17 1.058 0 .37-.056.716-.17 1.036-.114.321-.278.6-.492.838a2.262 2.262 0 01-.784.559c-.308.135-.66.203-1.054.203-.39 0-.74-.068-1.051-.203a2.236 2.236 0 01-.787-.56 2.463 2.463 0 01-.492-.837 3.068 3.068 0 01-.171-1.036z"
      />
    </g>
  </svg>
)

export const DownIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="6"
    viewBox="0 0 10 6"
    {...props}
  >
    <path
      fill="#474747"
      fillRule="nonzero"
      d="M9.924.603a.318.318 0 0 1 0 .462l-4.68 4.68a.318.318 0 0 1-.463 0l-4.68-4.68a.318.318 0 0 1 0-.462L.602.1a.318.318 0 0 1 .462 0l3.947 3.948L8.96.1a.318.318 0 0 1 .462 0l.502.503z"
    />
  </svg>
)
export const ShowIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="11"
    viewBox="0 0 6 11"
    {...props}
  >
    <path
      fill="#474747"
      fillRule="nonzero"
      d="M.692 1.011a.318.318 0 0 1 .462 0l4.68 4.681a.318.318 0 0 1 0 .462l-4.68 4.68a.318.318 0 0 1-.462 0l-.502-.501a.318.318 0 0 1 0-.462l3.947-3.948L.19 1.975a.318.318 0 0 1 0-.462l.502-.502z"
    />
  </svg>
)
