export const localUrl = process.env.REACT_APP_API_URL
export const localUrlGeo = process.env.REACT_APP_API_URL_GEO
export const mapKey = process.env.REACT_APP_MAP_KEY
export const socketIOClientIp = process.env.REACT_APP_SOCKETIO_IP
export const priorityRuLoad = process.env.REACT_APP_PRIORITY_RU_LOAD
export const DSN_SENTRY = {
  load: process.env.REACT_APP_DSN_SENTRY_LOAD,
  dsn: process.env.REACT_APP_DSN_SENTRY_DSN,

  tracingOrigins: [
    'localhost',
    process.env.REACT_APP_DSN_SENTRY_TRACING_ORIGINS,
    /^\//,
  ],
}
