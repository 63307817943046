import { Button } from '@/entities/ui/NewButton/NewButton'
import { useUpdateBuseMutation } from '@/store/resources/resources'
import { FC } from 'react'
import styles from './EditBusWarningModal.module.less'
import { Alert } from 'antd'
import { closeModal } from '@/features/containers/ModalContainer/modalConfig'
export interface IProps {
  action: () => void
  type: 'edit' | 'disabling'
}

export const EditBusWarningModal: FC<IProps> = (props) => {
  const [, responseUpdateBus] = useUpdateBuseMutation({
    fixedCacheKey: 'edit-disabling-bus',
  })

  return (
    <section>
      <div className={styles.alert}>
        <Alert message={texts[props.type]} closable={false} type={'error'} />
      </div>
      <footer className={styles.footer}>
        <Button onClick={props.action} isLoading={responseUpdateBus.isLoading}>
          Edit
        </Button>
        <Button onClick={closeModal} buttonColor={'gray'}>
          Cancel
        </Button>
      </footer>
    </section>
  )
}

const texts: Record<string, string> = {
  edit: 'This bus is on the run(s) currently. Any change to the bus will affect runs as well (capacity, bus name, etc).',
  disabling:
    'This bus is on the run(s) currently. Consider removing bus from the run(s) before disabling.',
}
