import { useAppSelector } from '@/redux'
import { useModalStore } from '@/store/modal/ModalStore'
import { useCallback, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FormType } from '@/pages/Sessions/components/modals/AddNewSessionModal/AddNewSessionModal.types'
import {
  useAddSessionMutation,
  useAddSessionWithExistMutation,
} from '@/store/session/sessionApi'
import moment, { Moment } from 'moment'
import {
  getNewDateWeeksAmount,
  InputChangingValues,
} from '@/pages/Sessions/components/modals/AddNewSessionModal/helpers'

export const useAddNewSessionConfig = (
  methods: UseFormReturn<FormType, any, undefined>
) => {
  const seasons: ISeason[] = useAppSelector((state) => state.sessions.seasons)
  const { setValue } = methods
  const seasonOptions = seasons.map((season) => ({
    label: season.title,
    value: season.id,
  }))

  const onCloseModal = useModalStore.getState().onCloseModal
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false)
  const [formType, setFormType] = useState(0)

  const [requestSessionWithExist, responseSessionWithExist] =
    useAddSessionWithExistMutation()
  const [requestSession, responseSession] = useAddSessionMutation()

  const onSubmit = useCallback(
    (data) => {
      const payload = {
        id: data.id,
        weeks: Math.floor(Number(data.weeks)),
        sub: 0,
        start_date: data.start_date.unix(),
        end_date: data.end_date.unix(),
        title: data.title,
        school_year: String(data.start_date.year()),
        base_id: null,
      }
      if (formType === 1) {
        requestSessionWithExist(payload)
      } else {
        requestSession(payload)
      }
    },
    [formType, requestSession, requestSessionWithExist]
  )

  const onChange = useCallback(
    (
      start: Moment,
      end: Moment,
      weeksValue: string,
      changing: InputChangingValues
    ) => {
      const { startDate, endDate, weeks } = getNewDateWeeksAmount(
        start,
        end,
        weeksValue,
        changing
      )
      startDate && setValue(InputChangingValues.start, moment(startDate))
      endDate && setValue(InputChangingValues.end, moment(endDate))
      setValue(InputChangingValues.weeks, weeks)
    },
    [setValue]
  )

  return useMemo(
    () => ({
      seasonOptions,
      onSubmit,
      onChange,
      onCloseModal,
      setFormType,
      formType,
      isOpenDatePicker,
      setIsOpenDatePicker,
      isLoading:
        responseSessionWithExist.isLoading || responseSession.isLoading,
    }),
    [
      onChange,
      formType,
      isOpenDatePicker,
      onCloseModal,
      onSubmit,
      responseSession.isLoading,
      responseSessionWithExist.isLoading,
      seasonOptions,
    ]
  )
}

export interface ISeason {
  seasonWeeks: ISeasonWeek[]
  base_id: number
  childrenCount: string
  company_id: number
  date: number
  deleting: number
  end_date: number
  id: number
  isDeleted: number
  is_console: number
  is_copied: number
  is_parent_app: number

  modified: number
  school_year: null
  show_points: number
  start_date: number
  status: number
  subSessions: []
  title: string
  tripsCount: string
  weeks: number
}

export interface ISeasonWeek {
  id: number
  week_number: number
  start_date: string
  end_date: string
}
