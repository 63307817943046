import styles from './ErrorAlert.module.less'
import { Icon } from '@/entities/ui/Icon/Icon'
import { useSelector } from 'react-redux'
import { getChildrenList } from '@/redux/children/selectors'
import { useMemo } from 'react'
import { useCloneRunStore } from '@/entities/modals/CloneRunModal/store'
import { TCloneTypes } from '@/entities/modals/CloneRunModal/CloneRunModal'

export interface IErrorAlertProps {
  options?: { name: string; stop: number }[]
  runId: number
}
export const ErrorAlert = () => {
  // const options = useErrorsList()
  const cloneVariant = useCloneRunStore((state) => state.cloneVariant)
  const errorsRepeatList = useRepeatErrorsList()
  const errorsList = useErrorsList()
  const erorsListsVariants: Record<TCloneTypes, any> = useMemo(
    () => ({
      base: errorsList,
      mirror: errorsList,
      repeat: errorsRepeatList,
      reverse: errorsList,
    }),
    [errorsList, errorsRepeatList]
  )

  const options = erorsListsVariants[cloneVariant]

  if (options.length === 0) {
    return <></>
  }

  return (
    <article className={styles.root}>
      {options.map((option, index) => (
        <div key={index} className={styles.row}>
          <Icon className={styles.warningIcon} icon={'warningShape'} />
          <span>Stop {option.position}.</span>
          <span className={styles.name}>{option.name} can not be cloned</span>
        </div>
      ))}
    </article>
  )
}

export const useErrorsList = () => {
  const { currentRun, currentRunType } = useCloneRunStore((state) => state)
  const points = currentRun?.points ?? []
  const childrenList = useSelector(getChildrenList)

  //список детей в трансфер стопе с ключем - child_id
  const riderFromTransferStops = points
    .filter((point) => point.type === 5)
    .flatMap((item) => item?.routePointChildren ?? [])
    ?.reduce((acc, item) => ({ ...acc, [item.child_id]: item }), {})

  return useMemo(() => {
    const errors = []
    points.forEach((point) => {
      //ТРАНСФЕР СТОП
      if (point.type === 5) {
        errors.push({
          name: point?.busStopTitle,
          position: point?.position - 1,
          rider: point,
        })
      }
      //редирекшен стоп
      if (point.type === 4) {
        errors.push({
          name: point?.busStopTitle,
          position: point?.position - 1,
          rider: point,
        })
      }

      // ОСТАНОВКА - РЕБЕНОК
      if (point.type === 1) {
        const { address2, riderFromList } = getAddress2(
          {
            child_id: point.object.id,
            childaddress: point.routePointChildren[0]?.childaddress,
          },
          childrenList
        )

        if (!address2 || riderFromTransferStops?.[riderFromList?.id]) {
          errors.push({
            name: riderFromList?.full_name,
            position: point?.position - 1,
            rider: riderFromList,
          })
        }
        //ОСТАНОВКА С ДЕНЬМИ
      } else if (point.type === 2 && !!point?.object?.length) {
        point?.routePointChildren.forEach((rider) => {
          const { address2, riderFromList } = getAddress2(rider, childrenList)
          if (!address2) {
            errors.push({
              name: riderFromList?.full_name,
              position: point?.position - 1,
              rider: riderFromList,
            })
          }
        })
      }
    })
    return errors
  }, [
    childrenList,
    currentRun?.points,
    currentRunType,
    points,
    riderFromTransferStops,
  ])
}

const getAddress2 = (rider, childrenList) => {
  const pointTypeAddress = rider.childaddress.type
  const riderTypeAddress = rider.childaddress.is_primary

  const riderFromList = childrenList.find(
    (riderFromList) => riderFromList.id === rider?.child_id
  )

  const address2 = riderFromList?.addresses?.find((a) => {
    if (
      a.type !== pointTypeAddress &&
      !a.is_use &&
      !a.is_school &&
      riderTypeAddress === a.is_primary
    )
      return a
  })
  return { riderFromList, address2 }
}

export const useRepeatErrorsList = () => {
  const currentRun = useCloneRunStore((state) => state.currentRun)
  const getRiderFromList = useCloneRunStore.getState().getRiderFromList
  return useMemo(() => {
    const errors = []

    ;(currentRun?.points ?? []).forEach((point) => {
      // ОСТАНОВКА - РЕБЕНОК
      if (point.type === 1) {
        const riderFromList = getRiderFromList(point.object.id)

        errors.push({
          name: riderFromList?.full_name,
          position: point?.position - 1,
          rider: riderFromList,
        })

        //ОСТАНОВКА С ДЕНЬМИ
      } else if (point.type === 2 && !!point?.object?.length) {
        point?.routePointChildren.forEach((rider) => {
          const riderFromList = getRiderFromList(rider.child_id)

          errors.push({
            name: riderFromList?.full_name,
            position: point?.position - 1,
            rider: riderFromList,
          })
          // }
        })
      } else if (point.type === 5) {
        errors.push({
          name: point?.busStopTitle,
          position: point?.position - 1,
          rider: point,
        })
      } else if (point.type === 4) {
        errors.push({
          name: point?.busStopTitle,
          position: point?.position - 1,
          rider: point,
        })
      }
    })
    return errors
  }, [currentRun?.points, getRiderFromList])
}
