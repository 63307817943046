import api from '../../apiConfig'
import * as types from './actionTypes'

const common = api.common

// Get Buses
export function getBuses() {
  return function (dispatch) {
    common.get(`buses`).then(function (response) {
      if (response.status === 200) {
        dispatch({
          payload: response.data,
          type: types.GET_BUSES,
        })
      } else {
        console.warn('error')
      }
    })
  }
}

// Get Bus Drivers
export function getBusDrivers() {
  return function (dispatch) {
    common.get(`bus-drivers`).then(function (response) {
      if (response.status === 200) {
        dispatch({
          payload: response.data,
          type: types.GET_DRIVERS,
        })
      } else {
        console.warn('error')
      }
    })
  }
}
