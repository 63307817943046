import { IErrorAlertProps } from '@/entities/modals/CloneRunModal/components/ErrorAlert/ErrorAlert'

export const options = [
  {
    label: 'Reverse',
    value: 'reverse',
  },
  {
    label: 'Mirror',
    value: 'mirror',
  },
  {
    label: 'Repeat',
    value: 'repeat',
  },
]

export const alertOptions: IErrorAlertProps['options'] = [
  {
    stop: 1,
    name: 'reverse',
  },
  {
    stop: 2,
    name: 'mirror',
  },
  {
    stop: 3,
    name: 'repeat',
  },
]
