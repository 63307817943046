import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "../BaseModal";

import Row from "antd/es/row/index";
import Col from "antd/es/col/index";

import "./index.less";

class Success extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      text: props.text
    };
  }

  handleSave = () => {
    console.log("Save");
  };

  handleCancel = () => {
    console.log("Cancel");
  };

  render() {
    const suffix = !this.state.ValidError ? (
      <span className="NoValid" />
    ) : (
      <span className="Valid" />
    );
    return (
      <Modal
        id={"confirm"}
        title={"Email Run"}
        saveBtn={"Ok"}
        closeFn={this.props.close}
        class={"ModalClose open"}
      >
        <div className="profile">
          <div>
            <Row>
              <Col span={24}>
                <span className={"text"}>Email sent</span>
              </Col>
            </Row>
          </div>
        </div>

        <div className="center" style={{ width: "100%" }}>
          <div className="buttons">
            <button
              className="save"
              onClick={this.props.close}
              style={{ whitespace: "pre" }}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default Success;
