import React, { Component } from 'react'
import './index.less'
import { connect } from 'react-redux'
import { UpIcon } from '../../../../../../../img/icons'
import { DownOutlined } from '@ant-design/icons'

class Riders extends Component {
  constructor(props) {
    super(props)

    this.state = {
      point: props.point,
      isExpanded: {
        riders: false,
        adults: false,
      },
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.point !== nextProps.point) {
      this.setState({
        point: nextProps.point,
      })
    }

    let href = window.location.href.split('/')
    let ftId = href[href.length - 1] * 1
    let element = nextProps.fieldTripRequests.find((x) => x.id === ftId)

    if (!element) return false
    let stops = element.fieldTrip.fieldTripRuns[0].fieldTripPoints.filter(
      (x) => x.type !== 1
    )
    this.setState({
      fTid: ftId,
      activeRuns: element.fieldTrip.fieldTripRuns,
      selectedRun: element.fieldTrip.fieldTripRuns[0].id,
      selectedStops: stops,
      fieldTripRequests: nextProps.fieldTripRequests,
    })
  }

  handleExpand = (type) => {
    const isExpanded = this.state.isExpanded
    isExpanded[type] = !isExpanded[type]

    this.setState({
      isExpanded: isExpanded,
    })
  }

  render() {
    let { point, isExpanded } = this.state

    return (
      <div
        className="firstLine rline fullWidth top5"
        style={{ paddingLeft: '0' }}
      >
        <div className="flex fullWidth">
          <div id="Riders">
            <span className="link" onClick={() => this.handleExpand('riders')}>
              <span className="IconRiders" />
              <span className="linkRiders ridersIcons">
                Riders {point.fieldTripRiders.length}
                {isExpanded.riders ? (
                  <UpIcon className="icon-right" style={{ fontSize: '12px' }} />
                ) : (
                  <DownOutlined style={{ fontSize: '12px' }} />
                )}
              </span>
              <span className="IconUser" />
            </span>
            {isExpanded.riders && (
              <div className="List Top">
                {point.fieldTripRiders.map((rider, index) => {
                  return (
                    <div className="ListUsers" key={index}>
                      <span className="Name">
                        <div className="sn-mark">
                          {' '}
                          {rider.child ? rider.child.full_name : ''}{' '}
                          {rider.child &&
                            rider.child.transportation_category && (
                              <i>{rider.child.transportation_category}</i>
                            )}{' '}
                          {!this.props.isReadonly &&
                            !this.props.viewOnlyButton && (
                              <span
                                className="deleteIcon"
                                onClick={() =>
                                  this.props.deleteRiderFn(
                                    point.id,
                                    rider.id,
                                    false
                                  )
                                }
                              >
                                <span className={'DeLine'} />
                              </span>
                            )}
                        </div>
                      </span>

                      <span className="Classes">
                        {rider.child.classs && (
                          <span className="Types">
                            <span className="GradeNumber">
                              {rider.child.classs.replace('th', '')}th
                            </span>
                            <span className="text">Grade</span>
                          </span>
                        )}
                      </span>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    fieldTripRequests: state.fieldTripRequests.data,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Riders)
