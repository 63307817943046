import InputMask from 'react-input-mask'
import classnames from 'classnames'
import styles from './NewInput.module.less'
import { BaseInputProps } from '@/entities/ui/Inputs/Inputs.types'
import { forwardRef, Ref } from 'react'
import { InputRef } from 'antd'

export const NewMaskInput = forwardRef(
  (
    {
      error,
      showErrorText = true,
      ...props
    }: BaseInputProps & {
      mask: string
      maskChar: string
      autocomplete?: string
    },
    ref: Ref<InputRef>
  ) => {
    return (
      <article>
        {props.label && (
          <label
            data-error={!!error}
            className={classnames(styles.label, props.labelClassName, {
              [styles.errorMessage]: !!error || typeof error === 'string',
            })}
          >
            {props.label}
          </label>
        )}
        <InputMask
          {...props}
          ref={ref}
          data-error={!!error}
          className={classnames(styles.maskInput, props?.className)}
        />
        {error && showErrorText && (
          <p className={classnames(styles.errorMessage, styles.error)}>
            {error}
          </p>
        )}
      </article>
    )
  }
)
