import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as fieldTripDetail from '../../../../../redux/fieldTrip/requests/actions'
import { CloseOutlined } from '@ant-design/icons'
import { NewCheckbox } from '../../../../../entities/ui/NewCheckbox/NewCheckbox'

let i = 0

class TripApproval extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fieldTrip: this.props.data.fieldTrip,
      availableApprovals: [],
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.data.fieldTrip !== nextProps.fieldTripRequests.data) {
      let data = nextProps.fieldTripRequests.data

      let tmp = []

      data.forEach((x) => {
        if (x.fieldTrip === null) {
          return false
        }
        if (x.fieldTrip.id === this.state.fieldTrip.id) {
          tmp.push(x)
        }
      })

      this.setState({
        availableApprovals: tmp[0].availableApprovals,
        fieldTrip: nextProps.data.fieldTrip,
      })
    }
  }

  componentDidMount() {
    this.props.dispatch(
      fieldTripDetail.availableApprovals(this.props.data.fieldTrip.id)
    )
  }

  pendingApprovalsList = () => {
    if (!this.props.data.fieldTrip.fieldTripApprovals) {
      return false
    }

    let fieldTripApprovals = this.props.data.fieldTrip.fieldTripApprovals

    return (
      <div>
        {fieldTripApprovals.map((approver) => {
          i++

          let name = `${approver?.userRelation?.user?.first_name} ${approver?.userRelation?.user?.last_name}`

          const noUser = !approver?.userRelation?.user
          if (approver.is_approved === 1) {
            return (
              <div
                style={noUser ? { backgroundColor: '#ffd8d7' } : {}}
                className="row approval-block approval-block-approved"
              >
                <div className="approval-block-header">
                  <span>
                    <NewCheckbox checked />
                  </span>
                  <span className="value">
                    {noUser ? 'The user has been deleted' : name}
                  </span>
                </div>

                <div className="approval-block-caption">
                  <span>Approved</span>
                </div>

                {approver.description && (
                  <div className="approval-block-note">
                    <div className="approval-block-note-label">
                      Note / Request
                    </div>
                    <div className="approval-block-note-text">
                      {approver.description}
                    </div>
                  </div>
                )}
              </div>
            )
          }

          if (approver.is_approved === null) {
            //pending
            return (
              <div
                key={i}
                style={noUser ? { backgroundColor: '#ffd8d7' } : {}}
                className="row approval-block approval-block-pending"
              >
                <div className="approval-block-header">
                  <span>
                    <NewCheckbox
                      disabled={noUser}
                      className="approveCheckbox"
                      checked={false}
                    />
                  </span>
                  <span className="value">
                    {noUser ? 'The user has been deleted' : name}
                  </span>
                </div>

                <div className="approval-block-caption">
                  <span>Pending Approval</span>
                </div>
              </div>
            )
          }
          if (approver.is_approved === 0) {
            //reject
            return (
              <div
                style={noUser ? { backgroundColor: '#ffd8d7' } : {}}
                className="row approval-block approval-block-rejected"
              >
                <div className="approval-block-header">
                  <span>
                    <CloseOutlined
                      style={{ fontSize: '18px', color: '#ed4d49' }}
                    />
                  </span>
                  <span className="value">
                    {noUser ? 'The user has been deleted' : name}
                  </span>
                </div>

                <div className="approval-block-caption">
                  <span>Rejection</span>
                </div>

                {approver.description && (
                  <div className="approval-block-note">
                    <div className="approval-block-note-label">
                      Note / Request
                    </div>
                    <div className="approval-block-note-text">
                      {approver.description}
                    </div>
                  </div>
                )}
              </div>
            )
          }
        })}
      </div>
    )
  }

  approvalsList() {
    let approvals = this.state.availableApprovals
    if (!approvals) {
      return (
        <div
          className="row approval-block approval-block-pending"
          style={{ borderBottom: 'none' }}
        >
          <div id="caps">
            <span className={'approvalBlock'} />
            <div className={'approval-block_text'}>
              There are no Approvers for
              <span>{this.props.data.school.name}</span>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          {approvals.map((approver) => {
            return (
              <div
                key={approver.id}
                className="row approval-block approval-block-pending"
              >
                <div className="approval-block-header">
                  <span>
                    <NewCheckbox checked={false} />
                  </span>
                  <span className="value">{`${
                    approver.user &&
                    approver.user.first_name &&
                    approver.user.first_name
                  } ${
                    approver.user &&
                    approver.user.last_name &&
                    approver.user.last_name
                  }`}</span>
                </div>
              </div>
            )
          })}
        </div>
      )
    }
  }

  render() {
    let ft = this.state.fieldTrip

    if (ft.status === 2) {
      return this.pendingApprovalsList()
    } else {
      return this.approvalsList()
    }
  }
}

function mapStateToProps(state) {
  return {
    fieldTripRequests: state.fieldTripRequests,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(TripApproval)
