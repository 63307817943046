import Select from 'antd/es/select'
import { SuffixIcon } from '@/img/iconsComponents/SuffixIcon'
import { sortItemsByNumAbc } from '@/helpers/arrayWork'
import { NewSingleDatePicker } from '@/entities/ui/NewSingleDatePicker/NewSingleDatePicker'
import moment from 'moment/moment'
import './index.less'
import { ViewFilterLive } from '@/pages/Gps/NewGps/components/ViewFilterLive'
import Button from 'antd/es/button'
import React, { FC, useCallback, useMemo } from 'react'
import ButtonGroup from 'antd/es/button/button-group'
import { useDispatch, useSelector } from 'react-redux'
import { getGpsRuns } from '@/redux/gps/selectors'
import { browserHistory } from 'react-router'
import { checkIfToday } from '@/pages/Gps/NewGps/helpers'
import { getGpsRecordsV2Clean } from '@/redux/gps/actions'
import { Filter } from '@/pages/Gps/NewGps'
import { AppDispatch } from '@/redux'

const timeWindows = [
  { id: 1, value: '15 min' },
  { id: 2, value: '30 min' },
  { id: 3, value: '45 min' },
  { id: 4, value: '1 hour' },
  { id: 5, value: '12 hours' },
]

type GpsFilterProps = {
  filter: Filter
  timeJump: number
  setFilter: (val: Filter) => void
}

export const GpsFilter: FC<GpsFilterProps> = ({
  filter,
  timeJump,
  setFilter,
}) => {
  const dispatch = useDispatch<AppDispatch>()
  const gpsRuns = useSelector(getGpsRuns)

  const isToday = checkIfToday(timeJump)

  const handleChangeDate = useCallback(
    (value) => {
      if (!value) value = moment()
      dispatch(getGpsRecordsV2Clean())
      browserHistory.push(`/client/gps/d/${value.utc().format('X')}`)
    },
    [dispatch]
  )

  const value = useMemo(() => moment(timeJump * 1000), [timeJump])

  return (
    <div id={'filterMap'}>
      <div className={'el1'}>
        <Select
          style={{ boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)' }}
          placeholder="Show all started runs"
          onChange={(v) => {
            setFilter({ runId: v, driver: '', timeWindow: filter.timeWindow })
          }}
          value={filter.runId}
          suffixIcon={<SuffixIcon />}
        >
          <Select.Option key={0} value={0}>
            Show all started runs
          </Select.Option>
          {gpsRuns.sort(sortItemsByNumAbc).map((x) => (
            <Select.Option key={x.id} value={x.id}>
              {x.title}
            </Select.Option>
          ))}
        </Select>
      </div>

      <div className={'el2'}>
        <Select
          style={{ boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)' }}
          placeholder="Select Driver"
          value={filter.driver}
          onChange={(v) => {
            setFilter({
              runId: null,
              driver: v,
              timeWindow: filter.timeWindow,
            })
          }}
          suffixIcon={<SuffixIcon />}
        >
          <Select.Option key={0} value={''}>
            Select Driver
          </Select.Option>
          {Array.from(new Set(gpsRuns.map((i) => i.driverName))).map((name) => (
            <Select.Option key={name} value={name}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </div>

      <NewSingleDatePicker
        format={'MM-DD-YYYY'}
        value={value}
        showToday={false}
        className="datePickerStyle withBoxShadow noHover "
        onChange={handleChangeDate}
        disabledDate={(m) => m.isAfter(moment(), 'day')}
      />

      {isToday && (
        <>
          <div className={'el3'}>
            <ViewFilterLive />
          </div>

          <div className={`el4`}>
            <div className={'blockFilter filterShow'}>
              <label>Show Time Window</label>
              <ButtonGroup style={{ display: 'flex' }}>
                {timeWindows.map((w) => (
                  <Button
                    key={w.id}
                    onClick={() => setFilter({ ...filter, timeWindow: w.id })}
                    className={filter.timeWindow === w.id ? 'active' : null}
                  >
                    <span className={'btnClick'}>{w.value}</span>
                  </Button>
                ))}
              </ButtonGroup>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
