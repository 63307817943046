import { ColumnsType } from 'antd/lib/table'
import { IBus } from '@/store/resources/resources.types'
import styles from '@/pages/Resources/buses/Buses.module.less'
import { EditIcon, TrashIcon } from '@/img/icons'
import { NewSwitch } from '@/entities/ui/NewSwitch/NewSwitch'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { ModalsEnum, useModalStore } from '@/store/modal/ModalStore'
import {
  useGetBusDepotListQuery,
  useGetBusesQuery,
  useUpdateBuseMutation,
} from '@/store/resources/resources'
import { useMemo } from 'react'
import { FilterKeys, useBusesStore } from '@/pages/Resources/buses/store'
import { useAppSelector } from '@/redux'
import { getCompanyPermissions } from '@/redux/auth/selectors'
import {
  closeModal,
  openModal,
} from '@/features/containers/ModalContainer/modalConfig'
import { create } from 'zustand'
import {
  busDepotSorter,
  capacityFilter,
  capacitySort,
} from '@/pages/Resources/buses/helpers'


interface IStore {
  loadingList: number[]
  setLoadingList: (id: number) => void
}
export const useBusesListStore = create<IStore>((set, get) => ({
  loadingList: [],
  setLoadingList: (id) => {
    const list = get().loadingList
    if (list.includes(id)) {
      console.log([...list].filter((item) => item !== id))
      set({ loadingList: [...list].filter((item) => item !== id) })
    } else {
      set({ loadingList: [...list, id] })
    }
  },
}))


export const useBusesConfig = () => {
  const { data, isFetching } = useGetBusesQuery()
  const filters = useBusesStore((state) => state.filters)
  const permissions = useAppSelector(getCompanyPermissions)
  const onChangeFilter = (key: FilterKeys, value: string) => {
    const setFilter = useBusesStore.getState().setFilter
    setFilter({ [key]: value })
  }
  const loadingBusesList = useBusesListStore((state) => state.loadingList)

  const depotsList = useGetBusDepotListQuery()
  const [updateBus, responseUpdateBus] = useUpdateBuseMutation({
    fixedCacheKey: 'edit-disabling-bus',
  })

  const handleDisableBus = (bus: IBus) => {
    if (bus.isDepends) {
      openModal({
        currentModal: 'editBusWarningModal',
        modalSettings: {
          title: 'Warning!',
          width: 400,
        },
        modalProps: {
          type: 'disabling',
          action: () => updateBus({ ...bus, is_active: bus.is_active ? 0 : 1 }),
        },
      })
    } else {
      updateBus({ ...bus, is_active: bus.is_active ? 0 : 1 })
    }
  }

  const handleEditBus = (bus: IBus) => {
    if (bus.isDepends) {
      openModal({
        currentModal: 'editBusWarningModal',
        modalSettings: {
          title: 'Warning!',
          width: 400,
        },
        modalProps: {
          type: 'edit',
          action: async () => {
            await closeModal()

            await useModalStore.getState().onOpenModal({
              currentModal: ModalsEnum.ADD_BUS_MODAL,
              state: { bus },
            })
          },
        },
      })
    } else {
      useModalStore.getState().onOpenModal({
        currentModal: ModalsEnum.ADD_BUS_MODAL,
        state: { bus },
      })
    }
  }

  const columns: ColumnsType<IBus> = useMemo(
    () => [
      {
        title: 'Bus Number',
        dataIndex: 'bus_number',
        key: 'bus_number',
        // width: 140,
        filteredValue: [filters.busNumber],
        sorter: (a, b) => {
          if (a.bus_number && b.bus_number) {
            return a.bus_number.localeCompare(b.bus_number)
          }
          return a.bus_number ? -1 : 1
        },
        showSorterTooltip: false,
        defaultSortOrder: 'ascend',
        onFilter: (value, bus) =>
          value
            ? String(bus.bus_number).toLocaleString().includes(String(value))
            : true,
        render: (bus_number, bus) => (
          <div className={styles.busNumberCell}>
            <p>{bus_number ?? ' - '}</p>
            {!!permissions.write_resources && (
              <EditIcon onClick={() => handleEditBus(bus)} />
            )}
          </div>
        ),
      },
      {
        title: 'Capacity',
        dataIndex: 'capacity',
        key: 'capacity',
        // width: 140,
        sorter: capacitySort,
        filteredValue: [filters.capacityFrom, filters.capacityTo],
        onFilter: (_, bus) => capacityFilter(bus, filters),
        render: (capacity) => capacity ?? ' - ',
      },
      {
        title: 'Bus Depot',
        dataIndex: 'depotName',
        key: 'depotName',
        // width: 260,
        filteredValue: [+filters.depot],
        sorter: busDepotSorter,
        showSorterTooltip: false,
        // defaultSortOrder: 'ascend',
        onFilter: (value: string, record) =>
          +value ? +record?.bus_depot_id === +value : true,
        render: (depotName) => <span>{depotName ?? ' - '}</span>,
      },
      {
        title: 'VIN Number',
        dataIndex: 'vin_number',
        key: 'vin_number',

        width: 200,
        filteredValue: [filters.vinNumber],
        onFilter: (value, record) =>
          value
            ? String(record.vin_number).toLocaleString().includes(String(value))
            : true,
        render: (vin_number) => vin_number ?? ' - ',
      },
      {
        title: 'Bus Type',
        dataIndex: 'type',
        key: 'type',
        width: 120,
        render: (type) => (type ? type : ' - '),
      },

      {
        title: '',
        dataIndex: 'is_active',
        key: 'is_active',

        render: (is_active, bus) => (
          <NewSwitch
            controlled
            loading={loadingBusesList.includes(bus.id)}
            disabled={!permissions.write_resources}
            onChange={() => handleDisableBus(bus)}
            checked={!!is_active}
          />
        ),
      },
      {
        title: '',
        dataIndex: 'is_active',
        key: 'is_active',
        width: 90,
        filteredValue: [filters.show],
        onFilter: (value: string, record) =>
          value ? +record.is_active === +value : true,
        render: (is_active, bus) =>
          !is_active && !bus?.isDepends ? (
            <Button
              data-testid="busDeleteBtn"
              onClick={() =>
                useModalStore.getState().onOpenModal({
                  currentModal: ModalsEnum.DELETE_BUS_MODAL,
                  state: {
                    id: bus.id,
                  },
                })
              }
              variant="text"
              buttonColor="red"
              style={{ marginRight: 16 }}
            >
              <TrashIcon style={{ marginRight: 4 }} />
              Delete
            </Button>
          ) : (
            <></>
          ),
      },
    ],
    [
      filters.busNumber,
      filters.capacityFrom,
      filters.capacityTo,
      filters.depot,
      filters.id,
      filters.show,
      filters.vinNumber,
      handleDisableBus,
      loadingBusesList,
      permissions.write_resources,
    ]
  )

  const depotOptions = [
    ...new Set(data?.data.map((el) => el?.bus_depot_id)),
  ].map((id) => ({
    label: depotsList?.data?.data.find((depot) => depot.id === id)?.name,
    value: id,
  }))

  const showOptions = [
    { label: 'Show All', value: '' },
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' },
  ]

  return {
    depotOptions,
    columns,
    onChangeFilter,
    data: (data?.data || []).map((item) => ({
      ...item,
      depotName:
        depotsList.data?.data.find((depot) => depot.id === item.bus_depot_id)
          ?.name ?? '',
    })),
    isFetching,
    showOptions,
  }
}

export interface IOptions {
  label: any
  value: any
}
