import * as types from './actionTypes'
import { SESSIONS_EDIT_YEAR } from './actionTypes'
import _ from 'lodash'

const initialState = {
  activeSeason: null,
  seasons: [],
}
export default (state = initialState, action) => {
  switch (action.type) {
    case types.SESSIONS_GET_ALL: {
      let activeSessions = {}
      let data = action.payload
      let id = localStorage.getItem('ActiveSessionId')
      activeSessions = _.find(data, ['id', parseInt(id)])
      // if (!activeSessions) {
      //   activeSessions = {}
      // }

      if (!activeSessions && typeof data?.[0]?.id === 'number') {
        localStorage.setItem('ActiveSessionId', data[0].id)
        activeSessions = data[0]
      }

      return {
        seasons: action.payload,
        activeSeason: activeSessions,
      }
    }
    case types.SESSIONS_ADD_ITEM: {
      const item = action.payload
      let newArr = { ...state }
      newArr.seasons.push(item)
      console.log(item)
      return _.cloneDeep(newArr)
    }
    case types.SESSIONS_RELOAD: {
      let newArr = { ...state }
      newArr.seasons = []
      return _.cloneDeep(newArr)
    }

    case types.ACTIVE_MENU: {
      const sessId = action.payload
      let newArr = { ...state }
      let activeSess = newArr.seasons.find((x) => x.id === parseInt(sessId))
      if (activeSess) {
        localStorage.setItem('ActiveSessionId', activeSess.id)
        newArr.activeSeason = activeSess
      }
      return newArr
    }

    case types.SESSIONS_EDIT_ITEM: {
      const closedItem = action.payload
      let newArr = { ...state }
      newArr.seasons.forEach((item, i) => {
        if (item.id === closedItem.id) {
          newArr.seasons[i] = { ...closedItem }
        }
        if (newArr.activeSeason.id === closedItem.id) {
          newArr.activeSeason = closedItem
        }
      })
      return newArr
    }

    case types.SESSIONS_EDIT_ITEMS: {
      const closedItem = action.payload
      let newArr = { ...state }

      newArr.seasons.forEach((item, i) => {
        if (item.id === closedItem.id) {
          newArr.seasons[i] = { ...closedItem }
        }
        if (newArr.activeSeason.id === closedItem.id) {
          newArr.activeSeason = closedItem
        }
      })

      return _.cloneDeep(newArr)
    }
    case SESSIONS_EDIT_YEAR: {
      const { id, value } = action.payload
      const seasons = state.seasons.map((el) => {
        if (el.id === id) {
          return { ...el, is_next_year: value ? 1 : 0 }
        }
        return { ...el, is_next_year: 0 }
      })

      return { ...state, seasons }
    }

    case types.SESSIONS_REMOVE_ITEM: {
      let activeSeason = state.activeSeason
      const id = action.payload
      let newArr = ''

      newArr = state.seasons.filter((item) => {
        return parseInt(item.id) !== parseInt(id)
      })

      if (activeSeason.id === id) {
        activeSeason = {}
      }

      return {
        activeSeason: activeSeason,
        seasons: _.cloneDeep(newArr),
      }
    }

    case types.SESSIONS_CURRENT: {
      const id = action.payload
      let newArr = { ...state }

      let activeSeason = state.seasons.filter((item) => {
        return item.id === id
      })

      if (activeSeason.length > 0) {
        newArr.activeSeason = activeSeason[0]
      }
      return newArr
    }

    case types.SESSIONS_CHANGE_ACTIVE: {
      const id = action.payload
      let newArr = { ...state }
      let activeSeason = state.seasons.filter((item) => {
        return item.id === id
      })
      newArr.activeSeason = activeSeason[0]
      return newArr
    }

    case types.SUBMENU_TITLE: {
      state.title = action.payload
      if (state) {
        return state
      }
    }

    case types.SESSIONS_FILE_LOG: {
      let payload = action.payload
      const logs = payload.logs
      let newArr = { ...state }
      newArr.seasons.map((ses) => {
        if (ses.id === payload.season_id) {
          ses.files.map((file) =>
            file.id === payload.id ? (file['logs'] = logs) : ''
          )
        }
        return null
      })
      return newArr
    }

    case types.SESSIONS_GET_TOKEN: {
      return {
        ...state,
        token: action.payload,
      }
    }
    case types.SESSIONS_REFRESH_IMPORT: {
      let newArr = { ...state }
      newArr.seasons.map((ses) => {
        if (ses.id === action.payload.seasonId) {
          ses.importConfig.status = action.payload.status
          ses.importConfig.time = action.payload.time
        }
        return null
      })
      return newArr
    }
    default:
      return state
  }
}
