export const TRANCPORTATION_CATEGORIES = 'settings.TRANCPORTATION_CATEGORIES'
export const TRANCPORTATION_CATEGORIES_LIST_ADD = 'settings.TRANCPORTATION_CATEGORIES_LIST_ADD'
export const TRANCPORTATION_CATEGORIES_LIST = 'settings.TRANCPORTATION_CATEGORIES_lIST'
export const TRANCPORTATION_CATEGORIES_UPDATE =
  'settings.TRANCPORTATION_CATEGORIES_UPDATE'
export const TRANCPORTATION_CATEGORIES_ADD =
  'settings.TRANCPORTATION_CATEGORIES_ADD'
export const TRANCPORTATION_CATEGORIES_DELETE =
  'settings.TRANCPORTATION_CATEGORIES_DELETE'
export const CHILDREN_ENABLED_PHONES = 'settings.CHILDREN_ENABLED_PHONES'
