import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Marker, Popup } from 'react-leaflet'
import {
  fnMouseOut,
  fnMouseOver,
  getIconBusStop,
} from '../../../../../../components/MapDefault/mapSpecialFns'
import { useRulerStore } from '@/components/MapDefault/ruler/rulerStore'

class _SchoolsPins extends Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 0,
    }
  }

  getAbbreviation = (name) => {
    if (!name) name = ''
    let arr = name.split(' ')
    let abbr = ''
    arr.forEach((w, i) => {
      if (i > 2) return false
      abbr += w[0]
    })
    if (abbr === '') {
      return 'SCH'
    }
    return abbr.toUpperCase()
  }

  contentClick = (e) => {
    this.setState({
      mapPointModal: false,
    })
  }

  render() {
    let { schools } = this.props

    const isRule = this.props.isRule
    return (
      <Fragment key={+isRule}>
        {schools
          .filter((school) => !!school?.address)
          .map((school) => (
            <Marker
              key={school.id}
              zIndexOffset={400}
              onContextMenu={this.contentClick}
              position={[school.address.lat * 1, school.address.lon * 1]}
              icon={getIconBusStop()}
              interactive={!isRule}
              onMouseOver={(e) => !isRule && fnMouseOver(e)}
              onMouseOut={(e) => !isRule && fnMouseOut(e)}
            >
              <Popup
                offset={[0, -12]}
                direction="top"
                onMouseOut={(e) => fnMouseOut(e)}
              >
                <div className="full_name"> {school.name}</div>
              </Popup>
            </Marker>
          ))}
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    schools: state.schools,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}
const SchoolsPins = (props) => {
  const isRule = useRulerStore((state) => state.isRule)

  return <_SchoolsPins isRule={isRule} {...props} />
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolsPins)
