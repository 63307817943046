import {
  browserHistory,
  IndexRoute,
  Redirect,
  Route,
  Router,
} from 'react-router'

import { WithSuspense } from './WithSuspense'
import React, { Component, lazy } from 'react'
import * as Sentry from '@sentry/react'
import { _RootLayout } from '@/features/layouts/RootLayout'
import { AuthorizedLayout } from '@/features/layouts/AuthorizedLayout'
import { MainContainer } from '@/features/containers/MainContainer/MainContainer'

import PageNotFound from './pages/404'

import { AuthBackdoor } from './pages/Auth/AuthBackdoor'
import { GpsPage } from '@/pages/Gps/NewGps'

import { NewGpsDetailPage } from '@/pages/Gps/NewGpsDetails'
// const GpsDetailPage = lazy(() => import('./pages/Gps/component/GpsDetailPage'))
// const Gps = lazy(() => import('./pages/Gps/index'))
import FTRun from './pages/FieldTrip/Run'

// const FTRun = lazy(() => import('./pages/FieldTrip/Run'))
import RoutesView from './pages/RoutesView/RoutesViewWrap'
// const RoutesView = lazy(() => import('./pages/RoutesView/RoutesViewWrap'))
import Routes from './pages/Run'
// const Routes = lazy(() => import('./pages/Run'))
import Zones from './pages/Zones'
// const Zones = lazy(() => import('./pages/Zones'))

const FTRoutesView = lazy(() => import('./pages/FieldTrip/RoutesView'))
const Riders = lazy(() => import('./pages/Riders'))
const FieldTripApprove = lazy(
  () => import('./features/FieldRequest/FieldTripApprove/fieldTripApprove')
)
const RequestForm = lazy(
  () => import('./features/FieldRequest/RequestForm/requestForm')
)
const Dashboard = lazy(() => import('./pages/Dashboard'))
const ForgotPage = lazy(() => import('./pages/Auth/Forgot/Forgot'))
const SettingsV2 = lazy(() => import('./pages/Lc/settings/Settings'))
const LoginPage = lazy(() => import('./pages/Auth/Login/LoginForm'))

const LogPrint = lazy(() => import('./pages/Log'))
const Schools = lazy(() => import('./pages/Resources/school'))
const Console = lazy(() => import('./pages/Resources/console'))
const FTAttendance = lazy(() => import('./pages/FieldTrip/Attendance'))
const Attendance = lazy(() => import('./pages/Attendance'))
const FieldTripRequests = lazy(
  () => import('./pages/FieldTrip/Requests/FieldTripRequests')
)
const NewDepots = lazy(() => import('./pages/Resources/depots/Depots'))
const UiKit = lazy(() => import('./pages/UiKit/UiKit'))
const RTRegistrations = lazy(
  () => import('./pages/FieldTrip/Registrations/RTRegistrations')
)
const RTRequests = lazy(() => import('./pages/Requests/Requests'))
const NewDrivers = lazy(() => import('./pages/Resources/drivers/Drivers'))
const Buses = lazy(() => import('./pages/Resources/buses/Buses'))
const Sessions = lazy(() => import('./pages/Sessions/index'))
const SessionsV2 = lazy(() => import('./pages/Sessions/Sessions'))

const Plans = lazy(() => import('./pages/Lc/billing'))
const Overview = lazy(() => import('./pages/Lc/overview'))
const Profile = lazy(() => import('./pages/Lc/profile'))

class ErrorBoundary extends Component {
  constructor(props: any) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      eventId: '',
    }
  }

  componentDidCatch(error, errorInfo) {
    window.console.log(error, errorInfo)
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId, errorInfo })
    })

    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    })
  }

  render() {
    const handleReload = () => {
      window.location.reload()
    }

    if ((this.state as { hasError: boolean }).hasError) {
      return (
        <div className="errorBoundary">
          <div className="errorBoundary_content">
            <h1>Something went wrong. Please reload the page.</h1>
            <button onClick={handleReload}>Reload page</button>
          </div>
        </div>
      )
    }
    return (this.props as any).children
  }
}

export const getRoutes = () => (
  <ErrorBoundary>
    <Router history={browserHistory}>
      <Redirect from={'/client'} to={'/client/RouteTool'} />

      <Route
        path="client"
        component={(props: any) => (
          <WithSuspense>
            <_RootLayout {...props} />
          </WithSuspense>
        )}
      >
        <Route
          path="ui-kit"
          component={(props: any) => (
            <WithSuspense>
              <UiKit {...props} />
            </WithSuspense>
          )}
        />
        <Route
          path="log/print/:start/:end"
          component={(props: any) => (
            <WithSuspense>
              <LogPrint {...props} />
            </WithSuspense>
          )}
        />
        <Route
          path="signin"
          component={(props: any) => (
            <WithSuspense>
              <LoginPage {...props} />
            </WithSuspense>
          )}
        />
        <Route
          path="forgot"
          component={(props: any) => (
            <WithSuspense>
              <ForgotPage {...props} />
            </WithSuspense>
          )}
        />
        <Route
          path="signin/:id/:hash"
          component={(props: any) => (
            <WithSuspense>
              <AuthBackdoor {...props} />
            </WithSuspense>
          )}
        />
        <Route
          path="fieldRequest/:id"
          component={(props: any) => (
            <WithSuspense>
              <RequestForm {...props} />
            </WithSuspense>
          )}
        />

        <Route
          path="fieldRequest/a/:app"
          component={(props: any) => (
            <WithSuspense>
              <FieldTripApprove {...props} />
            </WithSuspense>
          )}
        />
        <Route
          path="fieldRequest/r/:rej"
          component={(props: any) => (
            <WithSuspense>
              <FieldTripApprove {...props} />
            </WithSuspense>
          )}
        />

        <Route
          path=""
          component={(props: any) => (
            <WithSuspense>
              <AuthorizedLayout>{props.children}</AuthorizedLayout>
            </WithSuspense>
          )}
        >
          <Route
            path="Dashboard"
            component={(props: any) => (
              <WithSuspense>
                <MainContainer withMenu={false}>{props.children}</MainContainer>
              </WithSuspense>
            )}
          >
            <IndexRoute
              component={(props: any) => (
                <WithSuspense>
                  <Dashboard {...props} />
                </WithSuspense>
              )}
            />

            <Route>
              <Route
                path="main"
                component={(props: any) => (
                  <WithSuspense>
                    <Dashboard {...props} />
                  </WithSuspense>
                )}
              />
            </Route>
          </Route>
          <Redirect from="RouteTool" to="RouteTool/sessions" />
          <Route
            path="RouteTool"
            component={(props: any) => (
              <MainContainer>{props.children}</MainContainer>
            )}
          >
            <Route>
              <Route
                path="sessions"
                component={(props: any) => (
                  <WithSuspense>
                    <SessionsV2 {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="sessions1"
                component={(props: any) => (
                  <WithSuspense>
                    <Sessions {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="routes"
                component={(props: any) => (
                  <WithSuspense>
                    <Routes {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="routes/:single"
                component={(props: any) => (
                  <WithSuspense>
                    <Routes {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="routes/:run1/:run2"
                component={(props: any) => (
                  <WithSuspense>
                    <Routes {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="routes-view"
                component={(props: any) => (
                  <WithSuspense>
                    <RoutesView {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="routes-view/:id"
                component={(props: any) => (
                  <WithSuspense>
                    <RoutesView {...props} />
                  </WithSuspense>
                )}
              />

              <Route
                path="schools"
                component={(props: any) => (
                  <WithSuspense>
                    <Schools {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="attendance"
                component={(props: any) => (
                  <WithSuspense>
                    <Attendance {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="requests"
                component={(props: any) => (
                  <WithSuspense>
                    <RTRequests {...props} />
                  </WithSuspense>
                )}
              />

              <Route
                path="parentRegistrations"
                component={(props: any) => (
                  <WithSuspense>
                    <RTRegistrations {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="attendance/:id"
                component={(props: any) => (
                  <WithSuspense>
                    <Attendance {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="riders"
                component={(props: any) => (
                  <WithSuspense>
                    <Riders {...props} />
                  </WithSuspense>
                )}
              />
              {/*<Route*/}
              {/*  path="riders1"*/}
              {/*  component={(props: any) => (*/}
              {/*    <WithSuspense>*/}
              {/*      <RidersV2 {...props} />*/}
              {/*    </WithSuspense>*/}
              {/*  )}*/}
              {/*/>*/}

              <Route
                path="*"
                component={(props: any) => (
                  <WithSuspense>
                    <PageNotFound {...props} />
                  </WithSuspense>
                )}
              />
            </Route>
          </Route>
          <Redirect from="FieldTrips" to="FieldTrips/requests" />
          <Route
            path="FieldTrips"
            component={(props: any) => (
              <MainContainer>{props.children}</MainContainer>
            )}
          >
            <Route>
              <Route
                path="requests"
                component={(props: any) => (
                  <WithSuspense>
                    <FieldTripRequests {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="run"
                component={(props: any) => (
                  <WithSuspense>
                    <FTRun {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="run/:single"
                component={(props: any) => (
                  <WithSuspense>
                    <FTRun {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="routes-view"
                component={(props: any) => (
                  <WithSuspense>
                    <FTRoutesView {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="routes-view/:id"
                component={(props: any) => (
                  <WithSuspense>
                    <FTRoutesView {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="attendance"
                component={(props: any) => (
                  <WithSuspense>
                    <FTAttendance {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="attendance/:id"
                component={(props: any) => (
                  <WithSuspense>
                    <FTAttendance {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="*"
                component={(props: any) => (
                  <WithSuspense>
                    <PageNotFound {...props} />
                  </WithSuspense>
                )}
              />
            </Route>
          </Route>

          <Route
            path="gps"
            component={(props: any) => (
              <WithSuspense>
                <MainContainer withMenu={false}>{props.children}</MainContainer>
              </WithSuspense>
            )}
          >
            <IndexRoute
              component={(props: any) => (
                <WithSuspense>
                  {/*<Gps {...props} />*/}
                  <GpsPage {...props} />
                </WithSuspense>
              )}
            />
            <Route>
              <Route
                path="d/:time"
                component={(props: any) => (
                  <WithSuspense>
                    {/*<Gps {...props} />*/}
                    <GpsPage {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path=":id"
                component={(props: any) => (
                  <WithSuspense>
                    {/*<GpsDetailPage {...props} />*/}
                    <NewGpsDetailPage {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path=":id/:day"
                component={(props: any) => (
                  <WithSuspense>
                    {/*<GpsDetailPage {...props} />*/}
                    <NewGpsDetailPage {...props} />
                  </WithSuspense>
                )}
              />
            </Route>
          </Route>
          <Redirect from="Resources" to="Resources/depots" />

          <Route
            path="Resources"
            component={(props: any) => (
              <WithSuspense>
                <MainContainer>{props.children}</MainContainer>
              </WithSuspense>
            )}
          >
            <Route>
              <Route
                path="depots"
                component={(props: any) => (
                  <WithSuspense>
                    <NewDepots {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="buses"
                component={(props: any) => (
                  <WithSuspense>
                    <Buses {...props} />
                  </WithSuspense>
                )}
              />

              <Route
                path="drivers"
                component={(props: any) => (
                  <WithSuspense>
                    <NewDrivers {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="ss"
                component={(props: any) => (
                  <WithSuspense>
                    <Schools {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="console"
                component={(props: any) => (
                  <WithSuspense>
                    <Console {...props} />
                  </WithSuspense>
                )}
              />
            </Route>
          </Route>

          <Route
            path="Zones"
            component={(props: any) => (
              <WithSuspense>
                <MainContainer withMenu={false}>{props.children}</MainContainer>
              </WithSuspense>
            )}
          >
            <IndexRoute
              component={(props: any) => (
                <WithSuspense>
                  <Zones {...props} />
                </WithSuspense>
              )}
            />
          </Route>
          <Redirect from="lc" to="lc/profile" />
          <Route
            path="lc"
            component={(props: any) => (
              <WithSuspense>
                <MainContainer>{props.children}</MainContainer>
              </WithSuspense>
            )}
          >
            <Route>
              <Route
                path="profile"
                component={(props: any) => (
                  <WithSuspense>
                    <Profile {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="overview"
                component={(props: any) => (
                  <WithSuspense>
                    <Overview {...props} />
                  </WithSuspense>
                )}
              />

              <Redirect from="billing" to="billing/plans" />
              <Route
                path="billing/plans"
                component={(props: any) => (
                  <WithSuspense>
                    <Plans {...props} />
                  </WithSuspense>
                )}
              />
              <Route
                path="billing/history"
                component={(props: any) => (
                  <WithSuspense>
                    <Plans {...props} />
                  </WithSuspense>
                )}
              />

              <Route
                path="settings"
                component={(props: any) => (
                  <WithSuspense>
                    <SettingsV2 {...props} />
                  </WithSuspense>
                )}
              />
            </Route>
          </Route>
        </Route>
      </Route>
    </Router>
  </ErrorBoundary>
)
