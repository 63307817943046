import { fieldTripApi } from '@/store/fieldTrip/FieldTripApi'
import { createSelector } from 'reselect'
import { ftRequestsEnum } from '@/pages/FieldTrip/Requests/constants'

const selectFtRequestsResult = fieldTripApi.endpoints.getFtRequests.select()
export const selectFtRequestsData = createSelector(
  selectFtRequestsResult,
  (ftRequestsResult) =>
    (ftRequestsResult.data?.data ?? [])?.filter(
      (item) => item?.status === ftRequestsEnum.STATUS_NEW
    )?.length ?? 0
)
