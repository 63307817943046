import React from 'react'
import Modal from "../../../components/BaseModal";
import Row from 'antd/es/row'
import Col from 'antd/es/col'

export default function (props){

  function save(){
    props.save()
  }

  return (
    <Modal
      title={'Warning!'}
      saveBtn={"Save"}
      closeBtn={"Cancel"}
      close={"closeM DeleteStop "}
      closeFn={props.close}
      class={"ModalClose open"}
    >
      <div className="profile">
        <div>
          <Row>
            <Col span={24}>
              <span className="Text">
                Some of the pins are outside of recommended radius from the nearest stops. Do you want them to be added anyway?
              </span>
            </Col>
          </Row>
        </div>
      </div>

      <div className="center">

          <div className="buttons">
            <button
              className="cancel"
              onClick={props.no}
            >
              No
            </button>

            <button
              className="save"
              style={{ whitespace: "pre" }}
              onClick={save}
            >
              Yes
            </button>
          </div>



      </div>
    </Modal>
  )
}