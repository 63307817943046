import React, { Fragment, memo } from 'react'
import classNames from 'classnames'
import styles from './Menu.module.less'
import { MenuSvg } from '@/components/LeftMenu/icons'
import { Link } from 'react-router'
import { useMenuList } from '@/features/containers/components/Menu/useMenu'
import { useMenuStore } from '@/features/containers/components/Menu/store'

export const Menu = memo(({ className }: { className?: string }) => {
  const menuOptions = useMenuList()
  const { isMenuExpanded, setMenuExpand } = useMenuStore()

  return (
    <menu className={classNames(styles.root, className)}>
      <ul
        className={classNames(styles.list, {
          [styles.expandedMenu]: isMenuExpanded,
        })}
      >
        <li
          onClick={() => setMenuExpand()}
          className={classNames(
            styles.menuItem,
            styles.withoutBackground,
            styles.menu,
            { [styles.extendMenuButton]: isMenuExpanded }
          )}
        >
          <div className={styles.iconWrapper}>
            <MenuSvg />
          </div>
          {!isMenuExpanded && <span>Menu</span>}
        </li>
        {menuOptions.map(({ Icon, Component, ...option }, index) => (
          <Fragment key={option?.to ?? index}>
            {Component ? (
              <Component />
            ) : (
              <Link
                activeClassName={styles.activeLink}
                onlyActiveOnIndex={false}
                to={option.to}
              >
                <li
                  className={classNames(styles.menuItem, {
                    [styles.active]: option.active,
                    [styles.withoutBackground]: !option.haveBackground,
                  })}
                >
                  <div className={styles.iconWrapper}>
                    <Icon />
                  </div>
                  <span className={styles.menuText}>
                    {option.text}
                    {!!option.count && !isMenuExpanded && (
                      <div className={classNames(styles.countItem)}>
                        {option.count}
                      </div>
                    )}
                  </span>

                  {!!option.count && isMenuExpanded && (
                    <div
                      className={classNames(styles.countItem, {
                        [styles.countItemExtended]: isMenuExpanded,
                      })}
                    >
                      {option.count}
                    </div>
                  )}
                </li>
              </Link>
            )}
          </Fragment>
        ))}
      </ul>
    </menu>
  )
})
