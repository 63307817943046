import React from 'react'
import { LegendLtIcon } from '@/img/iconsComponents/LegendLtIcon'
import { LegendOnIcon } from '@/img/iconsComponents/LegendOnIcon'
import { LegendInIcon } from '@/img/iconsComponents/LegendInIcon'
import { LegendAsIcon } from '@/img/iconsComponents/LegendAsIcon'

export const Legend = () => {
  return (
    <div className="legend legendGpsStyle">
      <span className="spanSvg">
        <LegendOnIcon />
      </span>
      <span className="legendSpan">On time</span>
      <span className="spanSvg">
        <LegendLtIcon />
      </span>
      <span className="legendSpan">Late</span>
      <span className="spanSvg">
        <LegendInIcon />
      </span>
      <span className="legendSpan">Inactive</span>
      <span className="spanSvg">
        <LegendAsIcon />
      </span>
      <span className="legendSpan">About to start</span>
    </div>
  )
}
