import React from 'react'

export const SuffixIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="6"
    viewBox="0 0 11 6"
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M10.698.733a.318.318 0 0 1 0 .462L6.017 5.876a.318.318 0 0 1-.462 0L.874 1.196a.318.318 0 0 1 0-.463l.502-.502a.318.318 0 0 1 .462 0l3.948 3.948L9.733.23a.318.318 0 0 1 .462 0l.503.502z"
    />
  </svg>
)
