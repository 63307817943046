import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import styles from './Filter.module.less'
import { CheckOutlined } from '@ant-design/icons'
import classnames from 'classnames'
import RadialProgress from '@/pages/Riders/components/RadialProgress'
import { useAppSelector } from '@/redux'
import { create } from 'zustand'

export interface IStore {
  type: string | null
  setType: (st: string | null) => void
}
export const useResetNewFilter = create<IStore>((set, get) => ({
  type: null,
  setType: (type) => set({ type }),
}))

export const FilterNew = ({ arrayId, filteredRidersList, activeFilter }) => {
  const [blockType, setBlockType] = useState(activeFilter ?? 'total')
  const childrenList = useAppSelector((state) => state.children.childrenList)
  const data = getData(childrenList)
  const { total, cancelled, inRoute, unassignedChild } = data

  useEffect(() => {
    childrenList?.length && filteredRidersList(total, activeFilter ?? 'total')
  }, [childrenList, filteredRidersList])

  const typeFromStore = useResetNewFilter((state) => state.type)
  useEffect(() => {
    if (typeFromStore) {
      setBlockType(typeFromStore)
      filteredRidersList(data[typeFromStore], typeFromStore)
    }
  }, [typeFromStore])

  const params = useMemo(
    () => ({
      total: {
        title: 'Total',
        percentage: 100,
        color: '#3d6aa6',
        count: total.length,
      },
      unassignedChild: {
        title: 'Unassigned',
        percentage: Math.floor((100 * unassignedChild.length) / total.length),
        color: '#f75e53',
        count: unassignedChild.length,
      },
      inRoute: {
        title: 'In Run',
        percentage: Math.floor((100 * inRoute.length) / total.length),
        color: '#F6BF4F',
        count: inRoute.length,
      },
      cancelled: {
        title: 'Not Riding',
        percentage: Math.floor((100 * cancelled.length) / total.length),
        color: '#8e8e8e',
        count: cancelled.length,
      },
    }),
    [cancelled.length, inRoute.length, total.length, unassignedChild.length]
  )
  const selected = useMemo(
    () => Math.floor((arrayId.length / (total.length || 1)) * 100),
    [arrayId.length, total.length]
  )

  return (
    <div className={styles.filterRoot}>
      {['total', 'unassignedChild', 'inRoute', 'cancelled'].map((item) => (
        <div
          className={classnames(styles.filterBlock, styles[item], {
            [styles.active]: blockType === item,
          })}
          onClick={() => {
            setBlockType(item)
            filteredRidersList(data[item], item)
          }}
          key={item}
        >
          <div className={styles.circleBlock}>
            <RadialProgress
              percentage={params[item].percentage}
              color={params[item].color}
            />
          </div>
          <div>
            <h3 className={styles.title}> {params[item].title}</h3>
            <span className={styles.count}> {params[item].count}</span>
          </div>
          <div
            className={classnames(styles.icon, {
              [styles.activeIcon]: blockType === item,
            })}
          >
            <CheckOutlined />
          </div>
        </div>
      ))}

      <div
        style={{ cursor: 'default' }}
        className={styles.filterBlock}
        key="count"
      >
        <div className={styles.circleBlock}>
          <RadialProgress percentage={selected} color={'#4f9b2e'} />
        </div>
        <div>
          <h3 className={styles.title}>Selected</h3>
          <span style={{ color: '#4f9b2e' }} className={styles.count}>
            {arrayId.length || 0}
          </span>
        </div>
      </div>
    </div>
  )
}

export const getData = (childrenList) => {
  const total = []
  let unassigned = []
  const inRoute = []
  const cancelled = []

  childrenList.forEach((item) => {
    total.push(item.id)

    const addressInRoute = _.some(item.addresses, (address) => {
      if (address.is_use && !address.is_school) {
        return true
      }
    })

    if (addressInRoute) {
      inRoute.push(item.id)
    }
    if (item.status !== 1) {
      cancelled.push(item.id)
    }
  })

  unassigned = _.unionBy(inRoute)

  const unassignedChild = []

  childrenList.forEach((item) => {
    if (unassigned.find((x) => x === item.id)) {
      return
    }
    unassignedChild.push(item.id)
  })
  return {
    total,
    unassignedChild,
    inRoute,
    cancelled,
  }
}
