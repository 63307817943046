import { Select } from 'antd'
import styles from './NewSelect.module.less'
import { SelectProps } from 'antd/lib/select'

import { Controller } from 'react-hook-form'
import { IRules } from '../NewInput/FormInput'
import { SuffixIcon } from '@/img/iconsComponents/SuffixIcon'

import React, { forwardRef, memo, Ref, useState } from 'react'
import classNames from 'classnames'

export interface INewSelect extends SelectProps {
  label?: string
  labelClassName?: string
  error?: string
  wrapperClassName?: string
  showErrorText?: boolean
  sideEffect?: (v?: SelectProps['value']) => void
  withSort?: boolean
}
export const NewSelect = forwardRef((props: INewSelect, ref: Ref<any>) => {
  const {
    options,
    showSearch = true,
    withSort = true,
    error,
    labelClassName,
    label,
    className,
    wrapperClassName,
    onChange,
    sideEffect,
    showErrorText = true,
    style,
    ...rest
  } = props

  const [open, setOpen] = useState(!!props.open)
  console.log(error)
  return (
    <article
      style={style}
      className={classNames(styles.label, wrapperClassName)}
    >
      {label && (
        <label
          className={classNames(labelClassName, {
            [styles.errorLabel]: !!error,
          })}
        >
          {label}
        </label>
      )}
      <Select
        showSearch={showSearch}
        ref={ref}
        className={classNames(styles.root, className)}
        status={error ? 'error' : undefined}
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        filterOption={(input, option) =>
          String(option?.label ?? '').includes(input)
        }
        filterSort={
          withSort
            ? (optionA, optionB) =>
                String(optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare(String(optionB?.label ?? '').toLowerCase())
            : undefined
        }
        options={options || []}
        suffixIcon={
          <SuffixIcon
            className={classNames(styles.suffixIcon, { [styles.open]: open })}
          />
        }
        onChange={(value, option) => {
          onChange(value, option)
          sideEffect?.(value)
        }}
        {...rest}
      />
      {error && showErrorText && <p className={styles.errorMessage}>{error}</p>}
    </article>
  )
})
interface IFormSelect extends INewSelect {
  name: string
  rules?: IRules
}
export const FormSelect = memo(({ name, rules, ...rest }: IFormSelect) => {
  return (
    <Controller
      rules={rules}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <NewSelect
            {...rest}
            {...field}
            status={fieldState.error?.message ? 'error' : undefined}
            error={fieldState.error?.message || ''}
          />
        )
      }}
    />
  )
})
