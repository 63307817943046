import { Spin } from 'antd'
import { LogoIcon } from '@/assets/icons/logo'
import React, { useEffect } from 'react'
import styles from './Auth.module.less'
import { useLazyOnBackdoorSignInQuery } from '@/store/auth/AuthApi'

export const AuthBackdoor = ({ params }) => {
  const [onBackDoor] = useLazyOnBackdoorSignInQuery()

  useEffect(() => {
    if (params?.id && params?.hash) {
      localStorage.removeItem('token')
      onBackDoor({ hash: params.hash, id: params.id })
    }
  }, [params?.id, params?.hash, onBackDoor])

  return (
    <section className={styles.root}>
      <div className={styles.content}>
        <div className={styles.imgWrapper}>
          <LogoIcon />
        </div>
        <Spin tip="...loading" />
      </div>
    </section>
  )
}
