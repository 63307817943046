import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModalParent from '../../../../../../components/BaseModal'
import moment from 'moment'
import i18n from '../../../../../../i18n'
import * as runActions from '../../../../../../redux/routesView/actions'
import { momentTzHelper } from '../../../../../../helpers/momentTz'
import { NewSingleDatePicker } from '../../../../../../entities/ui/NewSingleDatePicker/NewSingleDatePicker'
import { ClearIcon, TimeIcon } from '../../../../../../img/icons'

class StopModalAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formErrors: {
        busStopTitle: false,
        address: false,
      },
      formEmpty: {
        busStopTitle: true,
        address: true,
      },
    }

    this.modal = React.createRef()
  }

  getTimeUtcAnchor = (timeA) => {
    let tz = this.props.activeRun.time_zone
    let time = moment().unix() //время сейчас no UTC
    let localUTCOffset = momentTzHelper()(time * 1000, tz)._offset

    if (localUTCOffset > 0) {
      return moment(timeA, 'h:mm A').add(-localUTCOffset, 'm').format('h:mm A')
    } else {
      return moment(timeA, 'h:mm A')
        .add(Math.abs(localUTCOffset), 'm')
        .format('h:mm A')
    }
  }

  addStopInRun = () => {
    let errors = this.state.formErrors

    if (this.state.formEmpty.address) {
      errors.address = true

      this.setState({
        formErrors: errors,
      })
    }

    if (this.state.formEmpty.busStopTitle) {
      errors.busStopTitle = true

      this.setState({
        formErrors: errors,
      })
    }

    if (this.state.formErrors.busStopTitle || this.state.formErrors.address)
      return

    let { busStopTitle, address, routeWaittime, position, anchor_time } =
      this.state

    const points =
      this.props.activeRun &&
      this.props.activeRun.points.length &&
      this.props.activeRun.points
    let newPosition = this.props.getStopPosition(position, points)

    const time = anchor_time ? this.getTimeUtcAnchor(anchor_time) : ''

    //TODO ANCHOR STOP BUS CREATE
    let busStop = {
      name: busStopTitle,
      address: address,
      waittime: routeWaittime,
      position: +newPosition,
      // position ? +position + 1 : null,
      time: time ? moment.utc(time, 'h:mm A').format('HH:mm') : '',
      isAnchor: !!anchor_time,
      children: [],
    }

    this.props.addInRun('busStop', busStop, undefined, () => this.props.close())
  }

  handleChange = (type, e) => {
    e.preventDefault()
    let { dispatch } = this.props
    dispatch(runActions.setGeoAddressResponse())

    this.setState({
      [type]: e.target.value,
    })

    if (type === 'routeWaittime') {
      this.setState({
        [type]: e.target.value * 60,
      })
    }

    let errors = this.state.formErrors
    let empty = this.state.formEmpty

    if (!e.target.value || e.target.value === '') {
      errors[type] = true
      empty[type] = true
    } else {
      errors[type] = false
      empty[type] = false
    }

    this.setState({
      formErrors: errors,
      formEmpty: empty,
    })
  }

  handleChangeAnchor = (value) => {
    this.setState({
      anchor_time: value,
    })
  }

  disabledDate = (current) => {
    return current && current < moment().subtract(1, 'days')
  }

  componentDidMount() {}

  render() {
    let { busStopTitle, address, routeWaittime, position } = this.state

    const close = this.props.close
    return (
      <ModalParent
        title={i18n.t('Add a New Bus Stop')}
        closeFn={close}
        save={this.addStopInRun}
      >
        <div
          className={
            this.state.formErrors.busStopTitle
              ? 'line-header error'
              : 'line-header'
          }
        >
          {i18n.t('Bus Stop Name')}
        </div>

        <div className="line">
          <input
            data-testid="busStopNameInp"
            autoFocus={true}
            type="text"
            className={this.state.formErrors.busStopTitle ? 'error' : ''}
            value={busStopTitle}
            onChange={(e) => this.handleChange('busStopTitle', e)}
            placeholder={i18n.t('Name a New Bus Stop')}
          />
        </div>
        <div
          className={
            this.state.formErrors.address ||
            (this.props.addressGeo && this.props.addressGeo.errorMessage)
              ? 'line-header error'
              : 'line-header'
          }
        >
          {i18n.t('Address')}
        </div>
        <div className="line">
          <input
            data-testid="busStopAddressInp"
            type="text"
            className={
              this.state.formErrors.address ||
              (this.props.addressGeo && this.props.addressGeo.errorMessage)
                ? 'error'
                : ''
            }
            value={address}
            onChange={(e) => {
              this.handleChange('address', e)
            }}
            placeholder={i18n.t('Ex: 5th Ave & E 8 Street, New York, 10011')}
          />
        </div>
        <div className="line flex">
          <div className="half">
            <span>{i18n.t('Wait Time')}</span>
            <input
              data-testid="waitTimeInp"
              value={routeWaittime ? routeWaittime / 60 : null}
              onChange={(e) => this.handleChange('routeWaittime', e)}
              type="text"
              placeholder={i18n.t('In Minutes')}
            />
          </div>
          <div className="half">
            <span>{i18n.t('Enter Stop Number')}</span>
            <input
              data-testid="busStopNumberInp"
              value={position}
              onChange={(e) => this.handleChange('position', e)}
              type="text"
              placeholder={i18n.t('or Stop Will Be Placed Last on the List')}
            />
          </div>
        </div>

        <div className="line flex">
          <div className="half">
            <span>{i18n.t('Choose an Anchor Time if needed')}</span>
            <div style={{ position: 'relative' }}>
              <span id="runBuildModal">
                <NewSingleDatePicker
                  style={{ width: '100%' }}
                  use12Hours
                  id={'runBuildModal'}
                  format="h:mm A"
                  placeholder="Select Time"
                  disabledHours={() => this.state.disabledTime}
                  defaultOpenValue={moment('00:00:00', 'HH:mm')}
                  className={
                    this.state.anchor_time
                      ? 'ant-calendar-picker ant-time-picker-panel-select changes'
                      : 'ant-calendar-picker ant-time-picker-panel-select'
                  }
                  onChange={(e) =>
                    this.handleChangeAnchor(e ? e.format('HH:mm') : null)
                  }
                  picker="time"
                  clearIcon={<ClearIcon />}
                  suffixIcon={<TimeIcon />}
                  divider
                />
              </span>
            </div>
          </div>
        </div>

        <div className="center">
          <div className="buttons">
            <button className="cancel" onClick={() => close('modalStop')}>
              {i18n.t('Cancel')}
            </button>
            <button
              data-testid="busStopSaveBtn"
              onClick={this.addStopInRun}
              className="save"
            >
              {i18n.t('Save')}
            </button>
          </div>
        </div>
      </ModalParent>
    )
  }
}

function mapStateToProps(state) {
  return {
    addressGeo: state.routesView.addressGeo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(StopModalAdd)
