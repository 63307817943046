import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as runActions from '../../../../../../redux/routesView/actions'
import i18n from '../../../../../../i18n'
import { NewAntInput } from '../../../../../../entities/ui/NewInput/NewAntInput'
import { NewModal } from '../../../../../../entities/ui/NewModal/NewModal'
import styles from './index.module.less'
class ModalDepotAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: props.data,
      range: [0, props.data.points.length - 1],
      max: props.data.points.length - 1,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props
    if (this.state.data !== data) {
      this.setState({
        data,
        range: [0, data.points.length - 1],
        max: data.points.length - 1,
      })
    }
  }

  optimize = () => {
    let { data, range } = this.state
    let { company, setIsOptimized, dispatch, closeFn, setIsDraggedPin } =
      this.props
    setIsOptimized(true)
    setIsDraggedPin(false)
    dispatch(runActions.optimizeRun(data, range, company))
    closeFn()
  }

  rangeInput = (input, type) => {
    let { range } = this.state
    let val = input.target.value
    range[type] = val * 1
    this.setState({
      range: range,
    })
  }

  render() {
    let { range } = this.state
    const { closeFn } = this.props

    return (
      <>
        <NewModal
          width={400}
          title={i18n.t('Optimize Run')}
          onClose={closeFn}
          open={true}
        >
          <div>
            <p className={styles.description}>
              {i18n.t('This process may remove redirection points')}
            </p>

            <p className={styles.subtitle}>
              {i18n.t('Select Range of Stops for Optimization')}
            </p>

            <div className={styles.inputsWrapper}>
              <div>
                <label className={'inputRangeLabel'}>{i18n.t('From')}</label>
                <NewAntInput
                  type="text"
                  className={'inputRange'}
                  onChange={(e) => this.rangeInput(e, 0)}
                  value={range[0]}
                />
              </div>

              <div>
                <label>{i18n.t('To')}</label>
                <NewAntInput
                  type="text"
                  className={'inputRange'}
                  onChange={(e) => this.rangeInput(e, 1)}
                  defaultValue={range[1]}
                />
              </div>
            </div>

            <div className={styles.buttonsWrapper}>
              <button className={styles.cancelButton} onClick={closeFn}>
                {i18n.t('Cancel')}
              </button>
              <button onClick={this.optimize} className={styles.saveButton}>
                {i18n.t('Optimize')}
              </button>
            </div>
          </div>
        </NewModal>
        {/*<ModalParent*/}
        {/*  title={i18n.t('Optimize Run')}*/}
        {/*  closeFn={closeFn}*/}
        {/*  save={this.optimize}*/}
        {/*  // dopClass={'optimizeRunRange'}*/}
        {/*>*/}
        {/*  <div style={{ margin: '0 auto' }}>*/}
        {/*    <div className={'line-header'}>*/}
        {/*      {i18n.t('This process may remove redirection points')}*/}
        {/*    </div>*/}

        {/*    <div style={styleRow}>*/}
        {/*      {i18n.t('Select Range of Stops for Optimization')}*/}
        {/*    </div>*/}

        {/*    <div className={`divRangeBlock`}>*/}
        {/*      <Row>*/}
        {/*        <Col span={8}>*/}
        {/*          <div className={'inputRangeLabel'}>{i18n.t('From')}</div>*/}
        {/*          <NewInput*/}
        {/*            type="text"*/}
        {/*            className={'inputRange'}*/}
        {/*            onChange={(e) => this.rangeInput(e, 0)}*/}
        {/*            value={range[0]}*/}
        {/*          />*/}
        {/*        </Col>*/}
        {/*        <Col span={8}>*/}
        {/*          <div className={'inputRangeLabel'}>{i18n.t('To')}</div>*/}
        {/*          <NewInput*/}
        {/*            type="text"*/}
        {/*            className={'inputRange'}*/}
        {/*            onChange={(e) => this.rangeInput(e, 1)}*/}
        {/*            defaultValue={range[1]}*/}
        {/*          />*/}
        {/*        </Col>*/}
        {/*      </Row>*/}
        {/*    </div>*/}

        {/*    <div className="center">*/}
        {/*      <div className="buttons">*/}
        {/*        <button className="cancel" onClick={closeFn}>*/}
        {/*          {i18n.t('Cancel')}*/}
        {/*        </button>*/}
        {/*        <button onClick={this.optimize} className="save">*/}
        {/*          {i18n.t('Optimize')}*/}
        {/*        </button>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</ModalParent>*/}
      </>
    )
  }
}

const styleRow = {
  fontFamily: `SBM-Medium`,
  fontSize: `16px`,
  lineHeight: `40px`,
  letterSpacing: `normal`,
  marginTop: `30px`,
  color: `#000000`,
}

function mapStateToProps(state) {
  return {
    depots: state.auth.depots,
    company: state.auth.company,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDepotAdd)
