import { useAppSelector } from '@/redux'
import { useMemo } from 'react'
import { routeLinks } from '@/shared/routeLinks'
import { selectFtRequestsData } from '@/store/fieldTrip/FieldTripApi.selectors'
import { userInfoSelector } from '@/store/common/common.selectors'

export const useMenuLinks = () => {
  const ftRequestsCount = useAppSelector(selectFtRequestsData)
  const userInfo = useAppSelector(userInfoSelector)
  const permissions = userInfo?.permissions

  return useMemo(
    () =>
      [
        { to: routeLinks.dashboard, text: 'Dashboard', show: true },
        { to: routeLinks.routeTool, text: 'Route Tool', show: true },
        {
          to: routeLinks.fieldTrips,
          text: 'Field Trips',
          count: ftRequestsCount,
          show: !!permissions?.read_field_trip,
        },
        {
          to: routeLinks.gps,
          text: 'GPS Tracker',
          show: !!permissions?.read_gps_track,
        },
        {
          to: routeLinks.resources,
          text: 'Resources',
          show: !!permissions?.read_resources,
        },
        {
          to: routeLinks.zones,
          text: 'Zones Tool',
          show: !!permissions?.read_zone_tool || !!permissions?.write_zone_tool,
        },
      ].filter((item) => !!item.show),
    [
      ftRequestsCount,
      permissions?.read_field_trip,
      permissions?.read_gps_track,
      permissions?.read_resources,
      permissions?.read_zone_tool,
      permissions?.write_zone_tool,
    ]
  )
}
