import styles from './AdditionalToggle.module.less'
import { Fragment, useEffect, useRef, useState } from 'react'
import {
  TCloneTypes,
  useCloneRunStore,
} from '@/entities/modals/CloneRunModal/store'

export interface IProps {
  width?: number
  options?: { label: string; value: string }[]
}

export const AdditionalToggle = ({ options, width }: IProps) => {
  const cloneVariant = useCloneRunStore((state) => state.cloneVariant)
  const onChange = (cloneVariant: TCloneTypes) =>
    useCloneRunStore.getState().setStore({ cloneVariant })
  const [activeStyle, setActiveStyle] = useState({})
  const activeRef = useRef<HTMLSpanElement | null>(null)

  useEffect(() => {
    const activeOption = activeRef.current
    if (activeOption) {
      setActiveStyle({
        width: activeOption.offsetWidth + 6,
        left: activeOption.offsetLeft - 3,
      })
    }
  }, [cloneVariant])

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div style={{ ...activeStyle }} className={cn(styles.active)} />
        {(options ?? []).map((option, index, array) => (
          <Fragment key={option.value}>
            <span
              ref={option.value === cloneVariant ? activeRef : null}
              style={{ width }}
              className={cn({
                [styles.activeText]: cloneVariant === option.value,
              })}
              onClick={() => onChange(option.value as TCloneTypes)}
            >
              {option.label}
            </span>
            {array.length !== index + 1 && <div className={styles.devider} />}
          </Fragment>
        ))}
      </div>
    </div>
  )
}
