import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { axiosBaseQuery } from '@/helpers/axiosBaseQuery'
import { notification } from 'antd'
import axios from 'axios'

import * as types from '../../../redux/auth/actionTypes'
import { useModalStore } from '../../modal/ModalStore'
import { getUrlWithSearchParams } from '@/helpers/getUrlWithSearchParams'
import {
  IBaseSchool,
  ICreateUserError,
  IEditUser,
  ILogInfo,
  IRole,
  IRoleRequestParams,
  ISettingsUser,
  ISetUserRoleParams,
  ITransCat,
  IUpdateTransCatParams,
  IUserActivityFilter,
} from '@/store/lc/settings/settings.types'
import { ICreateUserForm } from '@/entities/modals/CreateUserModal/CreateUserModal.types'
import { ErrorOption, FieldPath } from 'react-hook-form'
import { Fragment } from 'react'
import { SCHOOLS_GET_ITEMS } from '@/redux/schools/actionTypes'
import { useTransCatStore } from '@/pages/Lc/settings/tabs/transportationCategories/store'

import _ from 'lodash'
import { closeModal } from '@/features/containers/ModalContainer/modalConfig'
import { createSelector } from 'reselect'
import {
  TRANCPORTATION_CATEGORIES,
  TRANCPORTATION_CATEGORIES_ADD,
  TRANCPORTATION_CATEGORIES_DELETE,
  TRANCPORTATION_CATEGORIES_LIST,
  TRANCPORTATION_CATEGORIES_LIST_ADD,
  TRANCPORTATION_CATEGORIES_UPDATE,
} from '@/redux/settings/actionTypes'
import { commonApi } from '@/store/common/common'

export const lcSettingsApi = createApi({
  reducerPath: 'lcSettingsApi',
  baseQuery: axiosBaseQuery(axios),
  tagTypes: ['users', 'roles'],
  endpoints: (build) => ({
    updateCompany: build.query({
      query: ({
        data,
      }: {
        data: Record<string, any>
        params: { successNotifyMessage: string; errorNotifyMessage?: string }
      }) => {
        const newData = {}
        Object.keys(data).forEach((key) => {
          if (data[key] !== '') {
            newData[key] = data?.[key]
          }
        })

        return {
          url: 'companies/update',
          method: 'put',
          data: newData,
        }
      },
      onQueryStarted: async ({ params }, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled
          notification.success({
            message: params.successNotifyMessage,
          })
          const modalStore = useModalStore.getState()
          dispatch({
            type: types.COMPANY_EDIT,
            payload: response.data,
          })

          if (modalStore.currentModal) {
            modalStore.onCloseModal()
          }
          // const disp = useRootZustandStore.getState().dispatch
          dispatch(
            commonApi.util.updateQueryData('getUserInfo', undefined, (data) => {
              console.log({ ...response.data })
              data.data.company = {
                ...data.data.company,
                ...response.data?.data,
              }
            })
          )
          const userInfoRequest = commonApi.endpoints.getUserInfo.initiate()
          const userInfoResponse = await dispatch(userInfoRequest).unwrap()
          console.log('User Info:', userInfoResponse)
        } catch (e) {
          notification.error({
            message: params.errorNotifyMessage,
          })
        }
      },
    }),
    getUsers: build.query<{ data: ISettingsUser[] }, void>({
      providesTags: ['users'],
      query: () => {
        return {
          url: getUrlWithSearchParams('/users', {
            expand: 'userRelation,role',
          }),
        }
      },
      keepUnusedDataFor: 0,
    }),

    getRoles: build.query<{ data: IRole[] }, void>({
      providesTags: ['roles'],
      query: () => {
        return {
          url: getUrlWithSearchParams('/roles'),
        }
      },
      keepUnusedDataFor: 0,
    }),

    getUsersInfo: build.query<void, void>({
      query: () => {
        return {
          url: getUrlWithSearchParams('/users', {
            expand: 'userRelation,role',
          }),
        }
      },
      keepUnusedDataFor: 0,
    }),

    getSchools: build.query<{ data: IBaseSchool[] }, void>({
      query: () => ({
        url: getUrlWithSearchParams('../api/v2/school/index'),
      }),
      onQueryStarted: async (__, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled
          dispatch({
            type: SCHOOLS_GET_ITEMS,
            payload: _.cloneDeep(data.data),
          })
        } catch (e) {
          //
        }
      },
      keepUnusedDataFor: 0,
    }),

    getDistricts: build.query<{ data: any[] }, void>({
      query: () => ({
        url: getUrlWithSearchParams('/districts', {
          expand: 'address,schools',
        }),
      }),
      keepUnusedDataFor: 0,
    }),

    setProfileSettings: build.query<
      { data: ISettingsUser },
      IEditUser & { password?: string }
    >({
      query: ({ id, ...rest }) => ({
        url: `/users/${id}`,
        method: 'put',
        data: rest,
      }),
      onQueryStarted: async ({ id, ...rest }, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled
          dispatch(
            lcSettingsApi.util.updateQueryData(
              'getUsers',
              undefined,
              (data) => {
                data.data = data.data.map((item) =>
                  item.id === id ? { ...item, ...rest } : item
                )
              }
            )
          )
        } catch (e) {
          notification.error({ message: 'Server error' })
        }
      },
    }),
    createUser: build.query<
      { data: ISettingsUser },
      ICreateUserForm & {
        setError: (
          name: FieldPath<ICreateUserForm> | `root.${string}` | 'root',
          error: ErrorOption,
          options?: { shouldFocus: boolean }
        ) => void
      }
    >({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ setError, ...data }) => ({
        url: getUrlWithSearchParams('users', { expand: 'role' }),
        method: 'post',
        data,
      }),
      keepUnusedDataFor: 0,
      onQueryStarted: async ({ setError }, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled
          dispatch(
            lcSettingsApi.util.updateQueryData(
              'getUsers',
              undefined,
              (data) => {
                data.data = [...data.data, response.data.data]
              }
            )
          )
          notification.success({
            message: 'The user has been successfully created',
          })
          closeModal()
        } catch (axiosError) {
          const { error } = axiosError as ICreateUserError

          error?.data.forEach((item) => {
            setError(item.field, { message: item.message })
          })

          const errorMessages = error.data.map(({ field, message }) => (
            <Fragment key={field}>
              <span>
                <b>{field}</b>: {message}
              </span>
              <br />
            </Fragment>
          ))
          notification.error({ message: errorMessages })
        }
      },
    }),
    deleteUser: build.query<void, number>({
      query: (id) => ({
        url: getUrlWithSearchParams(`users/${id}`),
        method: 'delete',
      }),
      keepUnusedDataFor: 0,
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(
            lcSettingsApi.util.updateQueryData(
              'getUsers',
              undefined,
              (data) => {
                data.data = [...data.data].filter((item) => item.id !== id)
              }
            )
          )
          notification.success({ message: 'The user was successfully deleted' })
        } catch (e) {
          notification.error({
            message: 'An error occurred when deleting a user',
          })
        }
        //
      },
    }),
    // updateUserPassword: build.query<
    //   { data: { result: boolean } },
    //   Omit<IUserPasswordForm, 'new_confirm_password'> & {
    //     confirm_password: string
    //     reset: () => void
    //   }
    // >({
    //   query: (data) => ({
    //     url: 'users/change-password',
    //     method: 'post',
    //     data,
    //   }),
    //   onQueryStarted: async ({ reset }, { queryFulfilled }) => {
    //     try {
    //       await queryFulfilled
    //       reset()
    //     } catch (e) {
    //       notification.error({
    //         message: e?.error?.data?.message ?? 'Server error',
    //       })
    //     }
    //   },
    // }),

    getUserActivity: build.query<{ data: ILogInfo[] }, IUserActivityFilter>({
      query: (filters) => ({
        url: getUrlWithSearchParams('/general-logs', filters),
        method: 'get',
      }),
    }),

    setUserRole: build.mutation<void, ISetUserRoleParams>({
      query: (data) => ({
        url: getUrlWithSearchParams('/user/set-role'),
        method: 'put',
        data,
      }),
      invalidatesTags: ['users'],
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled

          notification.success({ message: 'The role was successfully changed' })
        } catch (e) {
          notification.error({
            message: 'An error occurred. The role could not be changed.',
          })
        }
      },
    }),
    createRole: build.mutation<{ data: IRole }, string>({
      query: (name) => ({
        url: getUrlWithSearchParams('/roles'),
        method: 'post',
        data: { name },
      }),
    }),
    updateRole: build.mutation<{ data: IRole }, IRoleRequestParams>({
      query: (data) => ({
        url: getUrlWithSearchParams('/roles/update'),
        method: 'put',
        data,
      }),
      onQueryStarted: async (params, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled
          dispatch(
            lcSettingsApi.util.updateQueryData(
              'getRoles',
              undefined,
              (data) => {
                data.data = [...data.data, response.data.data]
              }
            )
          )
          if (params.new_name) {
            notification.success({
              message: 'The role was successfully updated',
            })
          } else {
            notification.success({
              message: 'The new role has been successfully created',
            })
          }
          closeModal()
        } catch (e) {
          notification.error({
            message: 'Server error',
          })
        }
      },
    }),
    deleteRole: build.query<void, string>({
      query: (nameText) => ({
        url: getUrlWithSearchParams('/roles/delete', { name: nameText }),
        method: 'delete',
      }),
      onQueryStarted: async (nameText, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(
            lcSettingsApi.util.updateQueryData(
              'getRoles',
              undefined,
              (data) => {
                data.data = [...data.data].filter(
                  (role) => role.role.nameText !== nameText
                )
              }
            )
          )

          notification.success({ message: 'The role was successfully deleted' })
        } catch (e) {
          notification.error({
            message: 'An error has occurred . The role could not be deleted',
          })
        }
      },
    }),
    getTransCatList: build.query<{ data: string[] }, void>({
      query: () => ({
        url: getUrlWithSearchParams(
          '/transportation-categories/list-not-install'
        ),
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const res = await queryFulfilled
          dispatch({
            type: TRANCPORTATION_CATEGORIES_LIST,
            payload: res.data.data,
          })
        } catch (e) {
          //
        }
      },
    }),

    getTransCat: build.query<{ data: ITransCat[] }, void>({
      query: () => ({
        url: getUrlWithSearchParams('/transportation-categories'),
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const res = await queryFulfilled
          dispatch({
            type: TRANCPORTATION_CATEGORIES,
            payload: res.data.data,
          })
        } catch (e) {
          //
        }
      },
    }),
    deleteTransCat: build.query<string[], { id: number; name: string }>({
      query: ({ id }) => ({
        url: getUrlWithSearchParams(`/transportation-categories/${id}`),
        method: 'delete',
      }),

      onQueryStarted: async ({ id, name }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled

          dispatch({ type: TRANCPORTATION_CATEGORIES_DELETE, payload: id })

          notification.success({
            message: 'Transportation category was successfully deleted',
          })

          dispatch(
            lcSettingsApi.util.updateQueryData(
              'getTransCat',
              undefined,
              (data) => {
                data.data = [...data.data].filter((item) => item.id !== id)
              }
            )
          )
          dispatch(
            lcSettingsApi.util.updateQueryData(
              'getTransCatList',
              undefined,
              (data) => {
                data.data = [...data.data, name]
              }
            )
          )
        } catch (e) {
          notification.error({
            message: 'Server error. Transportation category was not deleted',
          })
        }
      },
    }),

    updateTransCat: build.query<{ data: ITransCat }, IUpdateTransCatParams>({
      query: ({ id, ...rest }) => ({
        url: getUrlWithSearchParams(`/transportation-categories/${id}`),
        method: 'put',
        data: rest,
      }),
      onQueryStarted: async (params, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled
          notification.success({
            message: 'Transportation category has been successfully updated',
          })
          dispatch({
            type: TRANCPORTATION_CATEGORIES_UPDATE,
            payload: response.data.data,
          })
          dispatch(
            lcSettingsApi.util.updateQueryData(
              'getTransCat',
              undefined,
              (data) => {
                data.data = data.data.map((item) =>
                  item.id === params.id ? response.data.data : item
                )
              }
            )
          )
          closeModal()
        } catch (e) {
          notification.error({
            message: 'Server error. Transportation category was not updated',
          })
        }
      },
    }),

    createTransCat: build.query<
      { data: ITransCat },
      Pick<IUpdateTransCatParams, 'name' | 'short_name'>
    >({
      query: (data) => ({
        url: getUrlWithSearchParams(`/transportation-categories`),
        method: 'post',
        data,
      }),
      onQueryStarted: async (params, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled
          notification.success({
            message: 'Transportation category has been successfully created',
          })
          dispatch({
            type: TRANCPORTATION_CATEGORIES_ADD,
            payload: response.data.data,
          })
          dispatch(
            lcSettingsApi.util.updateQueryData(
              'getTransCat',
              undefined,
              (data) => {
                data.data = [...data.data, response.data.data]
              }
            )
          )
          closeModal()
        } catch (e) {
          notification.error({
            message: 'Server error. Transportation category was not created',
          })
        }
      },
    }),
    addTransCatToMainList: build.query<{ data: ITransCat[] }, string[]>({
      query: (names) => ({
        url: getUrlWithSearchParams('transportation-categories/install'),
        method: 'post',
        data: { names },
      }),
      onQueryStarted: async (names, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled
          dispatch({
            type: TRANCPORTATION_CATEGORIES_LIST_ADD,
            payload: response.data.data,
          })

          //добавление новых категорий в основной список список
          dispatch(
            lcSettingsApi.util.updateQueryData(
              'getTransCat',
              undefined,
              (data) => {
                data.data = [...data.data, ...(response.data?.data ?? [])]
              }
            )
          )
          dispatch(
            lcSettingsApi.util.updateQueryData(
              'getTransCatList',
              undefined,
              (data) => {
                data.data = [...data.data].filter(
                  (name) => !names.includes(name)
                )
              }
            )
          )
          useTransCatStore.getState().reset()
          notification.success({
            message:
              names.length === 1
                ? 'transportation category has been successfully added'
                : 'Transportation categories have been successfully added',
          })
        } catch (e) {
          notification.error({ message: 'Server error' })
        }
      },
    }),
  }),
})
export const {
  useLazyAddTransCatToMainListQuery,
  useLazyUpdateTransCatQuery,
  useLazyCreateTransCatQuery,
  useLazyDeleteTransCatQuery,
  useSetUserRoleMutation,
  useGetUserActivityQuery,
  useGetDistrictsQuery,
  useLazyDeleteUserQuery,
  useGetSchoolsQuery,
  useLazyUpdateCompanyQuery,
  useGetRolesQuery,
  useGetUsersQuery,
  useGetUsersInfoQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useLazySetProfileSettingsQuery,
  useLazyCreateUserQuery,
  useLazyDeleteRoleQuery,
  useGetTransCatListQuery,
  useGetTransCatQuery,
} = lcSettingsApi

export const lcSettingsSchools = lcSettingsApi.endpoints.getSchools.select()
export const schoolsOptionsSelector = createSelector(
  lcSettingsSchools,
  (schoolsResult) => {
    return (schoolsResult.data?.data ?? [])
      .filter((school) => !!school?.address && !!school?.name)
      .map((school) => ({
        label: school.name,
        value: school.id,
      }))
  }
)

const transCatSelector = lcSettingsApi.endpoints.getTransCat.select()

export const normalizeTransCatNamesSelector = createSelector(
  transCatSelector,
  (store) => {
    return (
      store.data?.data?.reduce(
        (acc, item) => ({
          ...acc,
          ...(item?.name ? { [item?.name]: item } : {}),
        }),
        {}
      ) ?? null
    )
  }
)
export const normalizeTransCatShortNamesSelector = createSelector(
  transCatSelector,
  (store) => {
    return (
      store.data?.data?.reduce(
        (acc, item) => ({
          ...acc,
          ...(item?.short_name ? { [item?.short_name]: item } : {}),
        }),
        {}
      ) ?? null
    )
  }
)
