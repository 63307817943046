import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input'
import React, { useState, useEffect, useContext } from 'react'
import './index.less'
import { edit, del } from '../crud'
import _ from 'lodash'
import Context from '../context'

export default function ({activeID, setActiveID, userPermissions, isLoading}) {
  const [open, setOpen] = useState(true)
  const [filter, setFilter] = useState('')
  const [editId, setEditId] = useState(null)
  const [editName, setEditName] = useState(null)
  let { list, setList } = useContext(Context)
  const [ activeIDState, setActiveIDState ] = useState(undefined)

  const [isEmptyName, setEmptyName] = useState(false)

  useEffect(() => {
    if (activeIDState !== activeID) {
      setActiveIDState(activeID)
    }
  }, [activeID])

  function handleFind (e) {
    setFilter(e.target.value)
  }

  const writeZoneTool = userPermissions && userPermissions['write_zone_tool'] === 1

  function changeZoneName (item) {
    if (isEmptyName) return
    item.name = editName
    edit(editId, item)
    setEdit()
  }

  function setEdit (id = null, name = null) {
    setEditId(id)
    setEditName(name)
  }

  function setNameFn (value) {
    setEditName(value)
    setEmptyName(value.trim() === '')
  }

  function editBlock (item) {
    return (
      <div>
        <div className='editBlock'>
          <Input
            className={isEmptyName ? 'errorBorder' : ''}
            type='text'
            value={editName}
            onChange={e => setNameFn(e.target.value)}
          />
          <span
            onClick={() => changeZoneName(item)}
            className='save'
          />

          <span
            onClick={setEdit}
            className='cancel'
          />
        </div>
        {isEmptyName && (
          <span className='errorSpan'>Zone name can't be empty</span>
        )}
      </div>
    )
  }

  function deleteRecord (id) {
    del(id)
    setList(old => old.filter(x => x.id !== id))
    setActiveID(undefined)
  }

  function setActive (e, id) {
    if (e.target.className === `editAction` || e.target.className === `deleteBtn` ||
      e.target.className.includes('eyeBlock') ||
      e.target.className.includes('save') ||
      e.target.className.includes('cancel')) return false

    if (activeIDState === id) return
    setActiveIDState(id)
    setActiveID(id)
    setEditId(null)
  }

  function zonesListView (zones = null) {
    if (filter) list = list.filter(x => x.name.includes(filter))

    let newList = !_.isEmpty(list) && list.map((x, i) => {

      x.title = (
        <div className={`oneZone ${isLoading ? 'disabled-zone' : ''}`} key={`k${i}`}>
          <div
            className={`oneZoneInner ${activeIDState === x.id ? 'zoneNameActive' : ''} `}
            onClick={(e) => !isLoading && setActive(e, x.id)}
          >
            <span className='zoneName'>{editId !== x.id && x.name}</span>
            {x.id === editId && editBlock(x)}
            {activeIDState === x.id && x.id !== editId && writeZoneTool &&
            <div className='zonesBtnBlock'>
              <span
                onClick={() => !isLoading && setEdit(x.id, x.name)}
                title={'Edit Zone Name'}
                className='zonesEditAction'
              />

              <span className='deleteBtn'
                title={'Delete Zone'}
                onClick={() => !isLoading && deleteRecord(x.id)} />
            </div>
            }
          </div>
          <div className='border1px' />
        </div>
      )

      x.key = 'l' + i

      return x
    })

    return (
      <div>
        {newList && newList.map((value, index, arr) => value.title) }
      </div>
    )
  }

  return (
    <div
      ref={el => { el && el.addEventListener('mousedown', (e) => { e.stopPropagation() }) }}
      id='ZonesLeftMenu'
      className={`menuMax  ${!open ? 'openMin' : 'openMax'}`}
    >
      <div className='collapseMenu' onClick={() => setOpen(!open)}>
        <div>
          <span
            id='CollapseIcon'
            className={!open ? 'leftIcon' : 'rightIcon'}
          />
        </div>
      </div>

      <Row className={'flex alignCenterFlex pad10'} >
        <Col span={6} >
          <span className='title unassignedTitle'>Zones</span>
        </Col>
        <Col span={18}>
          <div className='filters-line'>
            <div className='filters-block-2x'>
              <div className='filter-input'>
                <Input
                  style={{ backgroundColor: 'rgba(0,0,0,0)' }}
                  value={filter}
                  placeholder='Filter by Name'
                  onChange={handleFind}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <div className='borderPadding' />

      {zonesListView()}

    </div>
  )
}
