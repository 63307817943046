import _ from "lodash";
import * as L from "leaflet";
import ReactDOMServer from "react-dom/server";
import mapIcon from "../../../../../components/RepeatFunctions/mapicon";
import { Popup } from "react-leaflet";
import React from "react";

export function getRouteMarkersFT(route) {
  let i = 1,
    c = 0;
  if (route && !_.isEmpty(route)) {
    return route.fieldTripPoints.map(point => {
      let type = "";
      if (point.type * 1 === 1) {
        type = "dpt";
      } else if (point.type * 1 === 3) {
        type = "school";
      } else if (point.type * 1 === 2) {
        type = "b";
      }
      let id = "routemarker-" + c;
      if (point.address) {
        let marker = {
          id: id,
          location: [point.address.lat, point.address.lon],
          icon: getIcon(type, i),
          object: point,
          sequence: i
        };
        i++;
        c++;
        return marker;
      }
      return false;
    });
  } else {
    return [];
  }
}

export function fnMouseOver(e) {
  e.originalEvent.preventDefault();
  e.sourceTarget.openPopup();
}
export function fnMouseOut(e) {
  e.originalEvent.preventDefault();
  e.sourceTarget.closePopup();
}

export function directionsPathOptions() {
  return {
    weight: 7,
    opacity: 0.8,
    color: `rgba(60, 176, 231, 0.8)`
  };
}
export function assignedPopup(marker, readOnly) {
  if (marker) {
    // this.childRiders(marker)
  }

  let title = marker.sequence + ". ";
  let anchor = "";

  if (marker.object.type * 1 === 1) {
    title += marker.object && marker.object.title;
  } else if (marker.object.type * 1 === 2 || marker.object.type * 1 === 5) {
    title += marker.object.busStopTitle ? marker.object.busStopTitle : "Stop";
  } else if (marker.object.type * 1 === 3 || marker.object.type * 1 === 6) {
    title += marker.object && marker.object.title;
  } else if (marker.object.type * 1 === 4) {
    title += marker.object.object && marker.object.object.name;
  } else {
    return "";
  }

  if (marker.object.isAnchor) {
    anchor = (
      <div className="anchor">
        <div className="anchor_icon" />
        <div className="anchor_time">{marker.object.routeTime}</div>
        <div className="anchor_edit" />
        <div className="anchor_delete" />
      </div>
    );
  }

  if (marker.object.type === 4) {
    return (
      <Popup offset={[0, 0]} direction="top">
        <div>
          <div className=" full_name">
            Delete redirection point
            <span
              onClick={() => this.removeStopInRun(marker.object)}
              className="removeFromRun"
            >
              &nbsp;
            </span>
          </div>
        </div>
        {!marker.object.readOnly &&
          this &&
          this.childRiders(marker, marker.object.readOnly)}
        {!marker.object.readOnly &&
          this &&
          this.childRidersAddition(marker, marker.object.readOnly)}
        {marker.object.view === "mapFtView" &&
          this &&
          this.childRidersAdditions(marker, marker.object.readOnly)}
      </Popup>
    );
  }

  return (
    <Popup offset={[0, -30]} direction="top">
      <div className=" full_name">
        {" "}
        {title}
        {!marker.object.readOnly && (
          <span
            onClick={() => this.removeStopInRun(marker.object)}
            className="removeFromRun"
          >
            &nbsp;
          </span>
        )}
      </div>
      {anchor}
      <div className="assigned_address">{marker.object.address.address}</div>

      {this && this.childRiders(marker, marker.object.readOnly)}
      {!marker.object.readOnly &&
        this &&
        this.childRidersAddition(marker, marker.object.readOnly)}
      {marker.object.view === "mapFtView" &&
        this &&
        this.childRidersAdditions(marker, marker.object.readOnly)}
    </Popup>
  );
}

export function getIcon(type, text) {
  if (type * 1 === 1) {
    type = "dpt";
  } else if (type * 1 === 3) {
    type = "school";
  } else if (type * 1 === 2) {
    type = "ft";
  }
  let size = [24, 43];
  let anchor = [12, 43];
  let BASE_URL = "https://demo.app.schoolbusmanager.com/";
  let url = "";
  if (type) {
    switch (type) {
      case "b":
        url = encodedIcon(text, "#00aaaa", "#005555", "#333");
        break;
      case "busdepot":
        size = [32, 37];
        anchor = [16, 37];
        url = BASE_URL + "img/busstop.png";
        break;
      case "school":
        url = encodedIcon(text, "#f57a20", "#f57a20", "#333");
        break;
      case "ft":
        url = encodedIcon(text, "#3cb0e7", "#3cb0e7", "#333");
        break;
      case "dpt":
        url = encodedIcon(text, "#1672A3", "#1672A3", "#000");
        break;
      default:
        url =
          BASE_URL +
          "route/mapiconsvg?text=P&bgstart=%231075a9&bgend=%231075a9&color=%23fff";
        break;
    }
  } else {
    url = encodedIcon(text, "#85d03d", "#69b921", "#333");
  }

  return new L.Icon({
    iconUrl: url,
    iconSize: size,
    iconAnchor: anchor,
    tooltipAnchor: [12, -25]
  });
}

export function encodedIcon(
  text,
  startcolor,
  endcolor,
  textcolor,
  iconed,
  strokeColor
) {
  return (
    "data:image/svg+xml;base64," +
    btoa(
      ReactDOMServer.renderToString(
        mapIcon(text, startcolor, endcolor, textcolor, iconed, strokeColor)
      )
    )
  );
}
