import { Alert } from 'antd'
import styles from '../../RidersImportModal.module.less'
import { Button } from '@/entities/ui/NewButton/NewButton'
import {
  DeleteOutlined,
  PaperClipOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { FieldsForMappingButton } from '@/entities/modals/RidersImportModal/components/FieldsForMappingButton'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { useImportRidersStore } from '@/entities/modals/RidersImportModal/RidersImportModal'
import { BaseInput } from '@/entities/ui/Inputs/BaseInput/BaseInput'

export const InitialStep = () => {
  const {
    setImportRidersStore,
    delimiter,
    enclosure,
    escape,
    isShowAlert,
    fileList,
    onAddFile,
    onDeleteFile,
  } = useImportRidersStore((state) => state)

  const onDrop = (files: File[]) => {
    onAddFile(files[0])
  }

  const { open } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: {
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'text/csv': ['.csv'],
    },
    onDrop,
  })
  return (
    <section>
      {isShowAlert && (
        <Alert
          className={styles.alert}
          message="If you want to cancel import press stop"
          type="error"
          closable
          afterClose={() => setImportRidersStore({ isShowAlert: false })}
        />
      )}
      <p className={styles.paragraph}>
        <span className={styles.orangeCircle} /> Click <b>Browse File</b> below
        to select a <b>CSV</b> or an Excel file for import.
      </p>
      <p className={styles.paragraph}>
        <span className={styles.orangeCircle} /> Once a valid file is selected,
        the system will show an Orange button.
      </p>
      <div className={styles.button}>
        <div className={styles.buttonsWrapper}>
          <Button
            disabled={!!fileList.length}
            onClick={open}
            buttonColor="blue"
            data-testid="browseFileBtn"
          >
            <SearchOutlined /> Browse File
          </Button>
          {!!fileList.length && <FieldsForMappingButton />}
        </div>

        {!!fileList.length && (
          <div className={styles.loadFilesWrapper}>
            {fileList.map((el, index) => (
              <div className={styles.loadedFile} key={index}>
                <PaperClipOutlined />
                <p data-testid="fileName">{el.name}</p>
                <button
                  data-testid="deleteFileBtn"
                  onClick={() => onDeleteFile(index)}
                >
                  <DeleteOutlined />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      <p className={cn(styles.paragraph, styles.subtitle)}>
        The following are conventions for importing from a CSV file
      </p>
      <div className={styles.inputsWrapper}>
        <BaseInput
          value={delimiter}
          type="text"
          onChange={(e) => setImportRidersStore({ delimiter: e.target.value })}
          label="Delimiter"
        />
        <BaseInput
          value={enclosure}
          type="text"
          onChange={(e) => setImportRidersStore({ enclosure: e.target.value })}
          label="Enclosure"
        />
        <BaseInput
          value={escape}
          type="text"
          onChange={(e) => setImportRidersStore({ escape: e.target.value })}
          label="Escape"
        />
      </div>
    </section>
  )
}
