import React from 'react'
import { Trans } from 'react-i18next'
import { useContextBuilder } from '../../context'
import { capacityInfo } from '../../../UnassignedRiders/components/RiderAddress/capacityCheck'
import { totalInfoByOsrm } from '@/pages/Run/components/BuildRun/components/totalInfoByOsrm'

function RunStatistic() {
  let { activeRun, buses, routes } = useContextBuilder()
  let osrm = getOsrm(activeRun)

  let { time, dist, speed, countRiders, countPoints } = totalInfoByOsrm(
    osrm,
    activeRun.points
  )

  function getOsrm(route) {
    if (route.hasOwnProperty('osrm') && typeof route.osrm === 'object') {
      return route.osrm
    } else {
      return false
    }
  }

  let capacityCheck = () => {
    let activeRunId = activeRun.id
    if (!activeRunId || buses.buses.length === 0) return 0

    let conf = {
      buses: buses,
      routes: routes,
      activeRun: activeRunId,
      addCount: 0,
    }

    let { difference, busCapacity } = capacityInfo(conf)
    if (busCapacity) {
      return (
        <span
          data-testid="assignedRidersCount"
          className={difference < 0 ? `overSizeCount` : ''}
        >
          {countRiders} of {busCapacity}
        </span>
      )
    } else {
      return (
        <span data-testid="assignedRidersCount">
          {countRiders ? countRiders : 0}
        </span>
      )
    }
  }

  return (
    <div style={{ marginTop: '2px' }}>
      <div className="block riders">
        <span>
          <Trans>Riders</Trans>
        </span>
        {capacityCheck()}
      </div>
      <div className="block stop">
        <span>
          <Trans>Stops</Trans>
        </span>
        <span data-testid="stopCountInRun">{countPoints}</span>
      </div>
      <div className="block time">
        <span>
          <Trans>Total time</Trans>
        </span>
        <span className="TotalTime">{time}</span>
      </div>
      <div className="block dist">
        <span>
          <Trans>Total dist</Trans>
        </span>
        <span>{dist}</span>
      </div>
      <div className="block speed">
        <span>
          <Trans>Avg Speed</Trans>
        </span>
        <span>{speed}</span>
      </div>
    </div>
  )
}

export default RunStatistic
