import { mapKey } from "../../redux/configLocal";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import React from "react";
import { useSelector } from "react-redux";
import { BasemapLayer } from "react-esri-leaflet/v2";
import { GOOGLE } from "../../helpers/constants";

export const BaseGoogleLayer = () => {
  const mapLayer = useSelector(state =>
    state.auth.companyOptions
      ? state.auth.companyOptions.base_map_layer
      : GOOGLE
  );

  return (
    <React.Fragment>
      {mapLayer === 'google' && <ReactLeafletGoogleLayer {...roadMapProps} />}
      {mapLayer === 'esri' && <BasemapLayer name={'Streets'} />}
    </React.Fragment>
  )
}

const roadMapProps = {
  googleMapsLoaderConf: { KEY: mapKey, LIBRARIES: ['geometry'] },
  type: 'roadmap',
}
