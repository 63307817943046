import * as types from "./actionTypes";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case types.DEPOT_GET: {
      return [...action.payload];
    }
    case types.DEPOT_ADD: {
      let newArr = [...state];
      newArr.push(action.payload);
      newArr = newArr.sort(depot => depot.id);
      return newArr;
    }
    case types.DEPOT_UPDATE: {
      let newArr = [...state];
      let index = newArr.findIndex(depot => depot.id === action.payload.id);
      newArr.splice(index, 1, action.payload);
      return newArr;
    }
    case types.DEPOT_DELETE: {
      let newArr = [...state];
      newArr = newArr.filter(depot => depot.id !== action.payload);
      return newArr;
    }
    default:
      return state;
  }
};
