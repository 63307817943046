export enum newRequestStatuses {
  New = 1,
  'In Review' = 2,
  Accepted = 3,
  Rejected = 4,
}

export const openedOptions = [
  { value: 1, label: 'New' },
  { value: 2, label: 'In Review' },
]
export const closedOptions = [
  { value: 3, label: 'Accepted' },
  { value: 4, label: 'Rejected' },
]

export const modalConstants = {
  create: 'Create',
  delete: 'Delete',
  update: 'Update',
}

export const requestFields = {
  firstGuardianName: 'First Guardian Name',
  firstGuardianPhone: 'First Guardian Phone',
  firstGuardianEmail: 'First Guardian Email',
  secondGuardianName: 'Second Guardian Name',
  secondGuardianPhone: 'Second Guardian Phone',
  secondGuardianEmail: 'Second Guardian Email',
  noteText: 'Notes to transportation',
  alertNote: 'Special needs',
  schoolName: 'School Name',
  address: 'Address',
  fullName: 'Full Name',
  type: 'Type',
  pickupAddress: 'Pick Up Address',
  dropoffAddress: 'Drop Off Address',
  guardian: 'Guardian',
  childName: 'Rider',
  riderId: 'ID',
  isRiding: 'Is Riding',
}
