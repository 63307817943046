import React, { Component } from 'react'
import { connect } from 'react-redux'
import BaseModal from '../../../../components/BaseModal'
import api from '../../../../redux/apiConfig'
import { getProfileSettings } from '../../../../redux/userSettings/actions'
import Select from '../../../../components/customSelect/index'
import _ from 'lodash'
import './index.less'
import { Trans } from 'react-i18next'
import i18n from '../../../../i18n'

import moment from 'moment-with-locales-es6'
import { NewCheckbox } from '../../../../entities/ui/NewCheckbox/NewCheckbox'
import { NewSingleDatePicker } from '../../../../entities/ui/NewSingleDatePicker/NewSingleDatePicker'
import { CalendarIcon, ClearIcon } from '../../../../img/icons'
import { getUrlWithSearchParams } from '../../../../helpers/getUrlWithSearchParams'

let rq = api.rtEmail

const re =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

class Modal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      map_section: false,
      stoplist_section: false,
      deleteEmpty: false,
      turnbyturn_section: false,
      student_cards: false,
      activeDate: Math.round(moment() / 1000),
      emails: [],
      emailsSelected: [],
      title: this.props.title,
      errorEmail: false,
      showResult: false,
      dateError: false,
      emailError: false,
      open: false,
      opens: false,
      isLoading: false,
    }

    this.selectInput = React.createRef()
    this.check = React.createRef()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.validEmail()
  }

  componentDidMount() {
    this.props.dispatch(getProfileSettings())
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.profile) {
      let emails = this.state.emails
      if (re.test(nextProps.profile.email)) {
        emails.push(nextProps.profile.email)
        this.setState({
          emails: emails,
          emailsSelected: emails,
        })
      }
    }
  }

  handleBindOption = () => {
    document
      .querySelector('.ant-select1-dropdown')
      .addEventListener('mouseleave', (event) => {
        if (event.offsetY > -1) {
          console.log(event)
        }
      })
  }

  handleChange = (type, e) => {
    e.preventDefault()
    this.setState({
      [type]: e.target.value,
    })
  }

  onChange = (type, e) => {
    if (type === 'activeDate') {
      this.setState({
        [type]: e ? e.unix() : '',
        dateError: false,
      })
    } else {
      this.setState({
        [type]: e.target.checked,
      })
    }
  }

  handleResult = () => {
    this.setState({
      showResult: true,
    })
  }

  saveBtn = () => {
    this.setState({ isLoading: true })
    let {
      map_section,
      stoplist_section,
      turnbyturn_section,
      emailsSelected,
      title,
      student_cards,
      activeDate,
    } = this.state

    if (emailsSelected.length === 0) {
      this.setState({
        errorEmail: true,
        emailError: true,
      })
      return
    }
    if (!activeDate) {
      this.setState({
        dateError: true,
      })
      return
    }

    let canPrint =
      map_section || stoplist_section || turnbyturn_section || student_cards

    if (!canPrint) {
      return false
    }

    emailsSelected.map((item) => {
      rq.get(
        getUrlWithSearchParams('/route-prints/send', {
          date: activeDate,
          email: item,
          title,
          id: this.props.id,
          map_section: map_section * 1,
          stoplist_section: stoplist_section * 1,
          turnbyturn_section: turnbyturn_section * 1,
          student_cards: student_cards * 1,
        })
      )
        .then((response) => {
          if (response.status === 200) {
            this.props.openSuccess()
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => this.setState({ isLoading: false }))
    })
  }

  handleChangeSelect = (value) => {
    this.setState({
      emailsSelected: value,
      emailError: false,
      open: false,
    })
  }

  validEmail = () => {
    if (this.selectInput.current) {
      let obj = this.selectInput.current.rcSelect
      if (obj && obj.state.value.length > 0) {
        _.map(obj.state.value, (item, index) => {
          if (!re.test(String(item).toLowerCase())) {
            obj.state.value.splice(index, 1)
            this.setState({
              deleteEmpty: true,
            })
          }
        })
      }
    }
  }

  renderOption = () => {
    return _.map(this.state.emails, (item, index) => {
      return (
        <option key={index} value={item} label={item} title={item}>
          <span role="img" />
          {item}
        </option>
      )
    })
  }

  handleSave = () => {}

  handleKey = (e) => {
    if (e.keyCode === 13) {
    }
  }

  render() {
    let closeFn = this.props.closeFn
    let { dateError, emailError, emails, isLoading } = this.state

    let { map_section, stoplist_section, turnbyturn_section, student_cards } =
      this.state
    let canPrint =
      map_section || stoplist_section || turnbyturn_section || student_cards

    if (!emails) {
      return false
    }
    return (
      <div>
        <BaseModal
          title={i18n.t('Email Run')}
          closeFn={() => closeFn('email_trip', '')}
          save={this.saveBtn}
        >
          <div id="modalEmail" className="content">
            <div className="modal-body">
              <div className="form-group">
                <span id={'select'} className="inputHeader">
                  <Trans>Enter Email address</Trans>:
                </span>

                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  ref={this.selectInput}
                  className={emailError ? 'emailError' : 'select'}
                  placeholder={i18n.t('Email Address')}
                  optionLabelProp="label"
                  onChange={this.handleChangeSelect}
                  value={this.state.emailsSelected}
                  onInputKeyDown={this.handleKey}
                  optionFilterProp="children"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {this.renderOption()}
                </Select>

                <div style={{ height: '8px' }} />
                <div className="dateBlock">
                  <NewSingleDatePicker
                    getCalendarContainer={(trigger) => trigger.parentNode}
                    allowClear={true}
                    className={`datePicker  ${dateError ? 'dateError' : ''} ${
                      this.state.activeDate ? 'changes' : ''
                    }  `}
                    onChange={(t) => this.onChange('activeDate', t)}
                    defaultValue={moment()}
                    clearIcon={<ClearIcon />}
                    suffixIcon={<CalendarIcon />}
                    divider
                  />
                </div>
                <span className="dt_text">
                  <Trans>Select section(s) to send</Trans>
                </span>

                <div className="form-group">
                  <NewCheckbox
                    onChange={(e) => this.onChange('map_section', e)}
                  >
                    <Trans>Map overview</Trans>
                  </NewCheckbox>
                  <br />
                  <NewCheckbox
                    onChange={(e) => this.onChange('stoplist_section', e)}
                  >
                    <Trans>Stop list</Trans>
                  </NewCheckbox>
                  <br />
                  <NewCheckbox
                    onChange={(e) => this.onChange('turnbyturn_section', e)}
                  >
                    <Trans>Turn-by-turn directions</Trans>
                  </NewCheckbox>
                  <br />
                  <NewCheckbox
                    onChange={(e) => this.onChange('student_cards', e)}
                  >
                    <Trans>Student cards</Trans>
                  </NewCheckbox>
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className="center">
            <div className="buttons">
              <button
                className="cancel"
                onClick={() => closeFn('email_trip', '')}
              >
                <Trans>Cancel</Trans>
              </button>

              <button
                disabled={!canPrint || isLoading}
                onClick={this.saveBtn}
                className={`save ${!canPrint ? 'blockBtn' : ''}`}
              >
                <Trans>Submit</Trans>
              </button>
            </div>
          </div>
        </BaseModal>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    profile: state.userSettings.profile,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
