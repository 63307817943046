import React, { Component } from 'react'
import { connect } from 'react-redux'
import { renderChildrenListBuildRun } from '../../../../components/RepeatFunctions/filter'
import * as childrenActions from '../../../../redux/children/actions'
import * as schoolsActions from '../../../../redux/schools/actions'
import RiderAddress from './components/RiderAddress'
import _ from 'lodash'
import ModalAddToStop from './components/ModalAddToStop/index'
import i18n from '../../../../i18n'

import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized'
import 'react-virtualized/styles.css'

import Row from 'antd/es/row'
import Col from 'antd/es/col'
import './style.less'
import Input from 'antd/es/input'
import { Icon } from '@/entities/ui/Icon/Icon'

class UnassignedRiders extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      open: true,
      activeRun: 0,
      childrenList: this.props.childrenList,
      filters: this.props.filters,
      addressModal: false,
    }

    this.listRef = React.createRef()
    this._cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 50,
    })

    this.rowRenderer = this.rowRenderer.bind(this)

    this.unassignedList = []
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.activeRun !== nextProps.activeRun && this.listRef.current) {
      this.setState(
        {
          activeRun: nextProps.activeRun,
        },
        () => {
          this._cache.clearAll()
          this.listRef?.current?.forceUpdate?.()
        }
      )
    }

    if (this.state.childrenList !== nextProps.childrenList) {
      this.setState({
        childrenList: nextProps.childrenList,
      })
    }

    if (this.state.filters !== nextProps.filters) {
      this._cache.clearAll()

      this.setState({
        filters: nextProps.filters,
      })
    }

    //TODO При переключении сессии отчищать страницу
  }

  collapse = () => {
    this.setState({
      open: !this.state.open,
    })
    this.props.paddingLegend(!this.state.open)
  }

  getUnassignedList = () => {
    let r = renderChildrenListBuildRun.call(this, true, 1)

    let rez = r.map((x) => {
      let r2 = x.addresses.find((y) => {
        if (y.is_use === 0 && y.is_school === 0) {
          return true
        }
      })
      if (r2) return x
    })
    return _.compact(rez)
  }

  addressModal = (address) => {
    this.setState({
      addressModal: !this.state.addressModal,
      targetAddress: address,
    })
  }

  getTrueCount = (unassignedList) => {
    let r = unassignedList.filter((child) => {
      return child.addressesView.find((a) => {
        if (!a.is_use) return true
      })
    })

    this.unassignedList = _.compact(r)
    return _.compact(r)
  }

  handleFind = (e) => {
    this.props.ct.updateFilter({ riderName: e.target.value })
  }

  rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
    parent,
  }) => {
    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}
      >
        {({ measure, registerChild }) => {
          return (
            <div ref={registerChild} style={style}>
              {this.riderRender(this.unassignedList[index], key, index)}
            </div>
          )
        }}
      </CellMeasurer>
    )
  }

  getSchoolsName = (addresses) => {
    if (!addresses) return false
    let schools = this.props.schools
    if (schools.length < 1) return false

    let result = addresses.map((x) => {
      let r = null
      if (!x.is_school && x.childSchool) {
        r = schools.find(
          (s) => x.childSchool && s.id === x.childSchool.school_id
        )
      }
      if (r && r.name) return r.name
    })
    return _.union(_.compact(result))
  }

  riderRender = (child, key, i, style) => {
    let schools = this.getSchoolsName(child.addresses)
    if (!child.addressesView) return false

    return (
      <div
        data-testid={child.id}
        className={`${i === 0 ? 'Margin12 dopSep' : 'dopSep'}`}
        style={style}
      >
        <Row>
          <Col span={4} />
          <Col span={20}>
            <span className="Title displayInline" style={{ margin: 0 }}>
              {child.full_name}{' '}
              <span className="specialN">{child.special_instructions}</span>
            </span>
            {child.classs && (
              <span className="SubTitle">
                <span className="Gray" style={{ margin: 0 }}>
                  Grade:{' '}
                </span>
                <b>{child.classs}</b>
              </span>
            )}
          </Col>
        </Row>
        {!!this.props.auth.camp_mode && !!child.weeks && (
          <Row>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'flex-start',
                paddingRight: 5,
                color: '#8d8d8d',
              }}
              span={4}
            >
              <Icon
                icon="calendar"
                style={{ marginRight: 6, marginTop: 3 }}
                width={14}
                height={14}
              />
            </Col>
            <Col span={19}>{child.weeks || ' - '}</Col>
          </Row>
        )}

        {schools &&
          schools.map((x) => (
            <Row className="Margin7 fontLight">
              <Col span={4}>
                <span className="School" />
              </Col>
              <Col span={20}>
                <span className="SchoolName">{x}</span>
              </Col>
            </Row>
          ))}

        {child.addressesView.map((address, index) => {
          return (
            <RiderAddress
              props={this.props}
              address={address}
              key={index}
              index={index}
              child={child}
              activeRun={this.state.activeRun}
              addRiderInRun={(a, b, c) => {
                this._cache.clearAll()
                this.listRef?.current?.forceUpdate?.()
                this.props.addRiderInRun(a, b, c)
              }}
              hoveredAddress={this.props.hoveredAddress}
              active_address_hover_id={this.props.active_address_hover_id}
              addressModal={this.addressModal}
            />
          )
        })}

        <div className=" lineSep">
          <hr className="LineBottom" />
        </div>
      </div>
    )
  }

  getList = () => {
    let unassignedList = this.getUnassignedList()

    //update list view
    setTimeout(() => {
      this._cache.clearAll()
      if (this.listRef.current) this.listRef?.current?.forceUpdate?.()
    }, 100)
    return [...this.getTrueCount(unassignedList)]
  }

  render() {
    let { activeRun, addressModal, filters } = this.state
    let unassignedList = this.getList()
    return (
      <div
        id="UnassignedRiderBuldRun"
        className={`listMenuMax scrollListMenuMax  ${
          !this.state.open ? 'transMin' : 'transMax'
        }`}
      >
        <div className="collapseMenu" onClick={this.collapse}>
          <div>
            <span
              id="CollapseIcon"
              className={!this.state.open ? 'leftIcon' : 'rightIcon'}
            />
          </div>
        </div>
        <Row className={'flex alignCenterFlex'}>
          <Col span={6}>
            <span className="title unassignedTitle">
              {i18n.t('Unassigned.Riders')} ({unassignedList.length})
            </span>
          </Col>
          <Col span={18}>
            <div className="filters-line">
              <div className="filters-block-2x">
                <div className="filter-input">
                  <Input
                    style={{ backgroundColor: 'rgba(0,0,0,0)' }}
                    value={filters.riderName}
                    placeholder={i18n.t('First Name,  Last Name, ID')}
                    onChange={this.handleFind}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={4} />
          <Col span={20}></Col>
        </Row>

        {unassignedList.length > 0 && (
          <div className="Unassigned" data-testid="unassigned-riders">
            <div className="list">
              <AutoSizer>
                {({ height, width }) => {
                  return (
                    <List
                      rand={Math.random()}
                      ref={this.listRef}
                      rowCount={unassignedList.length}
                      rowRenderer={this.rowRenderer}
                      width={width}
                      height={height}
                      rowHeight={this._cache.rowHeight}
                    />
                  )
                }}
              </AutoSizer>
            </div>
          </div>
        )}

        {addressModal && (
          <ModalAddToStop
            closeFn={this.addressModal}
            activeRun={activeRun}
            targetAddress={this.state.targetAddress}
            addRiderInStop={this.props.addRiderInStop}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    childrenList: state.children.childrenList,
    schools: state.schools,
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { childrenActions, schoolsActions, dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnassignedRiders)
