import { create } from 'zustand'
import api from '../../../../redux/apiConfig'
import { getUrlWithSearchParams } from '../../../../helpers/getUrlWithSearchParams'
import * as commonTypes from '../../../../redux/common/actionTypes'
import _ from 'lodash'

const rqp = api.routesPdf
export interface IRootZustandStore {
  dispatch: any
  setDispatch: (v: any) => void
}
export const useRootZustandStore = create<IRootZustandStore>((set) => ({
  dispatch: null,
  setDispatch: (dispatch) => set({ dispatch }),
}))

export interface IAttendanceStore {
  print: {
    isLoading: boolean
    printRequest: (id: string) => void
  }
  tripAttendance: {
    isLoading: boolean
    tripAttendance: any[]
    getTripAttendance: (fieldTripId: string | number) => void
    invalidateData: (v: any[]) => void
  }
  trips: {
    tripSelected: number
    isLoading: boolean
    getFieldTrips: () => void
    setTripSelected: (id: number) => void
    fieldTrips: any[]
  }
  onCheckAll: (tripId: string, checkedAll: boolean) => void
  updateAttendance: (tripId, data) => void
}

export const useAttendanceStore = create<IAttendanceStore>((set, get) => ({
  print: {
    isLoading: false,
    printRequest: async (id) => {
      set((state) => ({ print: { ...state.print, isLoading: true } }))
      try {
        const response = await rqp.get(
          getUrlWithSearchParams('/field-trip-prints/pdf', { id })
        )

        const file = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)

        await window.open(fileURL)
      } catch (e) {
        //
      } finally {
        set((state) => ({ print: { ...state.print, isLoading: false } }))
      }
    },
  },
  tripAttendance: {
    isLoading: false,
    tripAttendance: [],
    invalidateData: (tripAttendance) =>
      set((state) => ({
        ...state,
        tripAttendance: { ...state.tripAttendance, tripAttendance },
      })),
    getTripAttendance: async (fieldTripId) => {
      const dispatch = useRootZustandStore.getState().dispatch
      dispatch({ type: commonTypes.PENDING_START })

      set((state) => ({
        tripAttendance: { ...state.tripAttendance, isLoading: true },
      }))

      try {
        const response = await api.runV2.get(
          getUrlWithSearchParams('/field-trip/attendance/index', {
            fieldTripId,
          })
        )
        set((state) => ({
          tripAttendance: {
            ...state.tripAttendance,
            tripAttendance: response.data,
          },
        }))
      } catch (e) {
        //
      } finally {
        dispatch({ type: commonTypes.PENDING_END })

        set((state) => ({
          tripAttendance: { ...state.tripAttendance, isLoading: false },
        }))
      }
    },
  },
  trips: {
    tripSelected: 0,
    setTripSelected: (id) => {
      if (!id) {
        return
      }
      set((state) => ({
        ...state,
        trips: { ...state.trips, tripSelected: id },
      }))
      const getTripAttendance = get().tripAttendance.getTripAttendance
      id && getTripAttendance(id)
    },
    isLoading: false,
    fieldTrips: [],
    getFieldTrips: async () => {
      set((state) => ({ ...state, trips: { ...state.trips, isLoading: true } }))
      try {
        const response = await api.routes.get(
          getUrlWithSearchParams('/field-trips', {
            expand: [
              'finishApproval',
              'startDateText',
              'countRiders',
              'countRiderAdults',
            ],
          })
        )
        set((state) => ({
          ...state,
          trips: { ...state.trips, fieldTrips: response.data },
        }))
      } catch (e) {
        //
      } finally {
        set((state) => ({
          ...state,
          trips: { ...state.trips, isLoading: false },
        }))
      }
    },
  },
  updateAttendance: async (tripId, checked) => {
    try {
      await api.runV2.put(
        getUrlWithSearchParams(`field-trip/attendance/update/${tripId}`),
        { attend: checked }
      )

      const { tripSelected } = get().trips
      const { getTripAttendance } = get().tripAttendance

      getTripAttendance(tripSelected)
    } catch (e) {
      //
    } finally {
      //
    }
  },
  onCheckAll: async (tripId, checkedAll) => {
    const dispatch = useRootZustandStore.getState().dispatch
    dispatch({ type: commonTypes.PENDING_START })
    try {
      const checked = checkedAll ? 'uncheck-all' : 'check-all'
      const { tripSelected } = get().trips
      const { getTripAttendance } = get().tripAttendance
      await api.runV2.put(
        getUrlWithSearchParams(`field-trip/attendance/${checked}/${tripId}`)
      )
      await getTripAttendance(tripSelected)
    } catch (e) {
      //
    } finally {
      dispatch({ type: commonTypes.PENDING_END })
    }
    return ''
  },
}))

export const selectedTripSelector = (state: IAttendanceStore) => {
  const selectedId = state.trips.tripSelected

  const filteredTrips = state.trips.fieldTrips.filter((x) => x.status === 3)
  return filteredTrips?.find((x) => Number(x.id) === Number(selectedId))
}

export const tableDataSelector = (state: IAttendanceStore) => {
  const trip = selectedTripSelector(state)
  let date = 9560286800
  const uniqChild = []
  const uniqAdults = []
  let uniqArrIds = []

  trip?.fieldTripRuns.forEach((x) => {
    x.fieldTripPoints.forEach((y) => {
      y.fieldTripRiders.forEach((z) => {
        if (!uniqArrIds.includes(z.id)) {
          uniqArrIds.push(z.id)
          uniqChild.push(z)
        }
      })

      uniqArrIds = []
      y.fieldTripRiderAdults.forEach((z) => {
        if (!uniqArrIds.includes(z.id)) {
          uniqArrIds.push(z.id)
          uniqAdults.push(z)
        }
      })

      if (date > y.time) {
        date = y.time
      }
    })
  })
  if (trip?.date_start) {
    trip.date_start = date
  }

  const riders = []

  state.tripAttendance.tripAttendance.forEach((attendance) => {
    ;[
      ..._.uniqBy(uniqChild, 'child_id'),
      ..._.uniqBy(uniqAdults, 'name'),
    ].forEach((rider) => {
      if (rider?.child_id === attendance?.childId) {
        riders.push({
          ...rider,
          attendance: attendance.attend,
          attendanceId: attendance.id,
        })
      }
    })
  })

  const selectedTrip = selectedTripSelector(state)

  return {
    riders: riders.sort((a, b) => a.id - b.id),
    selectedTrip: selectedTrip
      ? { ...selectedTrip, date_start: date }
      : undefined,
  }
}

export const checkAllSelector = (state: IAttendanceStore) => {
  const { riders } = tableDataSelector(state)
  const checkedArr = riders.filter((el) => !!el.attendance)
  return checkedArr?.length === riders?.length
}
