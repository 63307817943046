import { Trans } from 'react-i18next'
import React, { FC, Fragment, useEffect, useState } from 'react'
import { useAppSelector } from '@/redux'
import { ISeason } from '@/pages/Sessions/components/modals/AddNewSessionModal/useAddNewSessionConfig'
import { Brand } from '@/types/types'
import { selectSessionById } from '@/redux/sessions/selectors'
import { ModalsEnum, useModalSelector } from '@/store/modal/ModalStore'
import Row from 'antd/es/row'
import { NewRangePicker } from '@/entities/ui/NewRangePicker/NewRangePicker'
import moment from 'moment/moment'
import { CalendarIcon } from '@/img/icons'
import { NewModal } from '@/entities/ui/NewModal/NewModal'
import styles from './EditSessionWeekModal.module.less'
import { makeValueForDatePicker } from '@/helpers/dates'
import { useEditSessionWeekDateMutation } from '@/store/session/sessionApi'
import { userInfoSelectorPermissions } from '@/store/common/common.selectors'
import { ModalFooter } from '@/entities/ui/NewModal/components/ModalFooter'

export type SessionId = Brand<number, 'session_id'>

type EditSessionWeekModalProps = {
  id: SessionId
  isOpen: boolean
  onClose: () => void
}

export const EditSessionWeekModal = () => {
  const { onCloseModal, isOpen, state } = useModalSelector(
    ModalsEnum.EDIT_SESSION_WEEK
  )

  return isOpen && state?.sessionId ? (
    <_EditSessionWeekModal
      id={state.sessionId}
      isOpen={isOpen}
      onClose={onCloseModal}
    />
  ) : (
    <Fragment />
  )
}

type WeekState = {
  [id: string]: [moment.Moment, moment.Moment]
}

export const _EditSessionWeekModal: FC<EditSessionWeekModalProps> = ({
  id,
  isOpen,
  onClose,
}) => {
  const season: ISeason = useAppSelector(selectSessionById(id))

  const [editSessionWeekDate, { isLoading }] = useEditSessionWeekDateMutation()

  const [value, setValue] = useState<WeekState>({})

  useEffect(() => {
    if (season?.seasonWeeks?.length) {
      const newDates = {}
      for (const w of season.seasonWeeks) {
        newDates[w.id] = makeValueForDatePicker(w.start_date, w.end_date)
      }
      setValue(newDates)
    }
  }, [season?.seasonWeeks])

  const addSession = () => {
    for (const w of season.seasonWeeks) {
      const startDate = value[w.id][0].format('YYYY-MM-DD')
      const endDate = value[w.id][1].format('YYYY-MM-DD')
      if (w.start_date !== startDate || w.end_date !== endDate) {
        editSessionWeekDate({
          weekId: w.id,
          data: { startDate, endDate },
        })
      }
    }
  }

  const permissions = useAppSelector(userInfoSelectorPermissions)

  return (
    <NewModal
      open={isOpen}
      title={'Edit Session Weeks'}
      onClose={onClose}
      isLoading={isLoading}
    >
      <p className={styles.subtitle}>
        If you make any modifications, please make sure all dates are accurate
        before you click SAVE
      </p>
      {season?.seasonWeeks?.map((w, i) => (
        <Row
          data-testid="weekRow"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          value={w.id}
          key={w.id}
          className={styles.row}
        >
          <Trans>{`Week #${++i}`}</Trans>
          <NewRangePicker
            format={'MM/DD/YYYY'}
            value={value[w.id]}
            disabled={!permissions?.write_sessions}
            onChange={(e: [moment.Moment, moment.Moment]) => {
              setValue({ ...value, [w.id]: e })
            }}
            style={{ width: 300, marginLeft: 20 }}
            placement="bottomLeft"
            suffixIcon={<CalendarIcon />}
          />
        </Row>
      ))}
      <ModalFooter
        buttonWidth={90}
        success={{
          isLoading,
          text: 'Save',
          action: addSession,
          disabled: !permissions?.write_sessions,
        }}
      />
    </NewModal>
  )
}
