import React, { Component } from 'react'
import { connect } from 'react-redux'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import ModalUnassign from '../UnassignedRiders/components/Modal/index'
import RiderElement from './components/RiderElement/index'
import * as childrenActions from '../../../../../redux/children/actions'
import * as routeAction from '../../../../../redux/routesView/actions'
import './style.less'
import _ from 'lodash'
import Popover from 'antd/es/popover'

import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized'
import 'react-virtualized/styles.css'

import { NewCheckbox } from '../../../../../entities/ui/NewCheckbox/NewCheckbox'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { Icon } from '@/entities/ui/Icon/Icon'

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 50,
})

class UnassignedRiders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      childrenList: props.childrenList,
      open: true,
      seasonsId: props.seasonsId,
      activeRun: this.props.activeRun,
      modalAddChild: false,
      checkedAll: false,
      checkedCount: 0,
      checkedRiders: [],
      flag: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.childrenList !== nextProps.childrenList) {
      this.setState({
        childrenList: nextProps.childrenList,
      })
    }
    this.setState({
      activeRun: nextProps.activeRun,
      seasons: nextProps.seasons,
    })
  }

  handleChangeAll = (e) => {
    let { childrenList } = this.state
    let checked = e.target.checked

    if (checked) {
      childrenList.forEach((child) => (child.checked = true))

      this.setState({
        checkedAll: true,
        checkedCount: childrenList.length,
        childrenList: childrenList,
      })
    } else {
      childrenList.forEach((child) => (child.checked = false))
      this.setState({
        checkedAll: false,
        checkedCount: 0,
        childrenList: childrenList,
      })
    }
  }

  modalAddChild = (e) => {
    if (!e) {
      this.setState({
        modalAddChild: !this.state.modalAddChild,
        checkedRiders: false,
      })
      return
    }

    if (e.target.className.indexOf('clearBtn') === -1) {
      if (this.state.checkedCount > 0) {
        const riders = []

        if (!this.state.modalAddChild) {
          this.state.childrenList.map((rider) => {
            if (rider.checked) {
              riders.push(rider)
            }
          })
        }

        this.setState({
          modalAddChild: !this.state.modalAddChild,
          checkedRiders: riders,
        })
      }
    }
  }

  collapse = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  checkUser = (id) => {
    let checkedCount = this.state.checkedCount
    let childrenList = this.state.childrenList

    let checkedAll = false
    let rezult = _.map(childrenList, (child) => {
      if (child.id === id) {
        if (!child.checked) {
          checkedCount++
          if (checkedCount === childrenList.length) {
            checkedAll = true
          }
        } else {
          checkedCount--
        }
        child.checked = !child.checked
      }
      return child
    })

    this.setState({
      childrenList: rezult,
      checkedCount: checkedCount,
      checkedAll: checkedAll,
    })
  }

  addRiders = (stopId) => {
    const riders = []
    const stop = this.props.stops.find((x) => x.id == stopId)

    this.state.childrenList.map((rider) => {
      if (
        !stop.fieldTripRiders.find((x) => x.child_id == rider.id) &&
        rider.checked
      ) {
        riders.push(rider)
      }
    })

    this.props.addRidersFn(stopId, riders)
  }

  unCheck = () => {
    let childrenList = this.state.childrenList
    childrenList.forEach((child) => {
      child.checked = false
    })
    this.setState({
      childrenList: childrenList,
      checkedAll: false,
      checkedCount: 0,
    })
  }

  closeRider = () => {
    this.setState({
      modalAddChild: false,
    })
  }

  render() {
    let { childrenList, open, modalAddChild, checkedAll, checkedCount } =
      this.state

    let text = ''
    let active = true
    if (
      (checkedCount && !this.props.stops.length) ||
      (!checkedCount && !this.props.stops.length)
    ) {
      text = 'You need to choose Field Trip with stops'
      active = false
    } else if (!checkedCount && this.props.stops.length) {
      text = 'You need to choose Riders to add them to the Run'
      active = false
    }

    const content = <p className="TextPopover">{text}</p>

    const renderHeader = () => (
      <input type="checkbox" checked={this.state.checkedAll} />
    )

    return (
      <div
        id={'FieldTripUnassigned'}
        className={`listMenuMaxFT scrollListMenuMax ${
          !open ? 'transMin' : 'transMax'
        }`}
      >
        <div className="collapseMenu" onClick={this.collapse}>
          <span
            id="CollapseIcon"
            className={!open ? 'leftIcon' : 'rightIcon'}
          />
        </div>
        <Row>
          <Col span={1}>
            <NewCheckbox
              className={'ridersCheckbox'}
              checked={checkedAll}
              onChange={(e) => this.handleChangeAll(e)}
            />
          </Col>
          <Col span={10} className="padd3 marginBotton3">
            <span className="title">Riders ({childrenList.length})</span>
          </Col>

          <Col span={13} style={{ position: 'relative', bottom: '5px' }}>
            <Popover
              placement="bottomLeft"
              content={!active ? content : ''}
              id={'popoverUnassigned'}
              trigger="hover"
            >
              <div
                style={{
                  display: 'flex',
                  marginLeft: 'auto',
                  width: 'fit-content',
                }}
              >
                <Button
                  withBorderRadius={false}
                  buttonColor={'green'}
                  style={{
                    padding: '0 8px',
                    borderRadius: checkedCount > 0 ? '2px 0 0 2px' : '2px',
                  }}
                  onClick={(e) => {
                    active && this.modalAddChild(e)
                  }}
                >
                  {checkedCount > 0 && <span> {checkedCount + ' '} </span>} Add
                  to Run
                </Button>
                {checkedCount > 0 && (
                  <Button
                    style={{
                      borderRadius: '0 2px  2px 0 ',
                    }}
                    onClick={this.unCheck}
                    withBorderRadius={false}
                    buttonColor="green"
                  >
                    <Icon width={8} icon={'clear'} />
                  </Button>
                )}
              </div>
            </Popover>
          </Col>
        </Row>

        {childrenList.length > 0 && (
          <div className="Unassigned">
            <div className="list" style={{ borderTop: 'none', marginTop: '0' }}>
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    rowCount={childrenList.length}
                    width={width}
                    height={height}
                    rowHeight={cache.rowHeight}
                    headerRenderer={renderHeader}
                    deferredMeasurementCache={cache}
                    rowRenderer={this.rowRenderer}
                    data={this.state.checkedAll}
                  />
                )}
              </AutoSizer>
            </div>
          </div>
        )}

        {modalAddChild && (
          <ModalUnassign
            stops={this.props.stops}
            checkedRiders={this.state.checkedRiders}
            addRidersFn={this.addRiders}
            closeFn={this.closeRider}
            count={this.state.checkedCount || 0}
          />
        )}
      </div>
    )
  }

  rowRenderer = ({ index, parent, key, style }) => {
    let childrenList = this.state.childrenList

    return (
      <CellMeasurer
        parent={parent}
        key={key}
        cache={cache}
        columnIndex={0}
        rowIndex={index}
      >
        <div key={key} style={style}>
          <RiderElement
            rowIndex={index}
            key={childrenList[index].id}
            checkUserFn={this.checkUser}
            RiderElement={childrenList[index]}
            isChecked={childrenList[index].checked}
          />
        </div>
      </CellMeasurer>
    )
  }
}

function mapStateToProps(state) {
  return {
    seasons: state.sessions,
    sessionsId: state.sessions.activeSeason.id,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { childrenActions, routeAction, dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnassignedRiders)
