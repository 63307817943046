import { RightArrowIcon } from '@/img/icons'
import { Popover } from 'antd'
import React, { useCallback, useState } from 'react'
import styles from '../../Menu.module.less'
import classnames from 'classnames'
import { ChronometerIcon } from '@/assets/icons/ChronometerIcon'
import { useAppDispatch, useAppSelector } from '@/redux'
import { currentSession } from '@/redux/sessions/actions'
import { useMenuStore } from '@/features/containers/components/Menu/store'
import { sessionsSelector } from '@/store/session/sessionApi'

export const CurrentSessionMenuItem = () => {
  const seasons = useAppSelector(sessionsSelector)
  const isMenuExpanded = useMenuStore((state) => state.isMenuExpanded)
  const activeSeason = useAppSelector((state) => state.sessions.activeSeason)
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)

  const changeActiveSession = useCallback(
    (id: number) => {
      dispatch(currentSession(id))
      localStorage.setItem('ActiveSessionId', String(id))
    },
    [dispatch]
  )

  return (
    <Popover
      data-testid="currentSessionMenu"
      placement="rightTop"
      open={open}
      onOpenChange={setOpen}
      align={{ offset: [-6, 24] }}
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      content={
        <div className={styles.content}>
          {seasons?.map((item) => {
            return (
              <div
                data-testid="sessionMenuItem"
                key={item.id}
                data-item={item.id}
                className={cn(styles.subMenu, {
                  [styles.activeSubMenu]: activeSeason?.id === item.id,
                })}
                onClick={() => {
                  changeActiveSession(item.id)
                  setOpen(false)
                }}
              >
                {item.title}
              </div>
            )
          })}
        </div>
      }
    >
      <li
        className={classnames(styles.menuItem, styles.currentSession, {
          [styles.expandedSession]: isMenuExpanded,
        })}
      >
        <div
          className={classnames({
            [styles.expandedSessionIcons]: isMenuExpanded,
          })}
        >
          <ChronometerIcon className={styles.chromaterIcon} />
          {isMenuExpanded && <RightArrowIcon className={styles.arrow} />}
        </div>
        <div>
          <p>
            Current <br />
            Session
          </p>

          {!isMenuExpanded && (
            <p
              data-testid="currentSessionName"
              className={styles.sessionName}
              data-item={activeSeason?.id}
            >
              {activeSeason?.title}
            </p>
          )}
        </div>

        {!isMenuExpanded && <RightArrowIcon className={styles.arrow} />}
      </li>
    </Popover>
  )
}
