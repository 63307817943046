import { useEffect, useState } from 'react'
import { getRunTotalHours } from '@/pages/Gps/NewGpsDetails/helpers/common'

export const useGetTimelineData = (start: number, end: number) => {
  const [timeLineHeight, setTimeLineHeight] = useState(0)

  const runTotalHours = getRunTotalHours(start, end)
  const runTotalMinutes = Math.floor((end - start) / 60)
  // Extra empty hours on start and end
  const minuteHeight = timeLineHeight / (runTotalHours + 2) / 60

  const handleResize = () => {
    const pageHeight = document.documentElement.clientHeight
    setTimeLineHeight(pageHeight - 220)
  }

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return { timeLineHeight, minuteHeight, runTotalMinutes }
}
