import React from 'react'
import Modal from '../../../../../../components/BaseModal'
import Row from 'antd/es/row'
import Col from 'antd/es/col'

export default function (props) {
  let arr = [`Drop off`, `Pickup`]
  if (props.addressType === 1) {
    arr = [`Pickup`, `Drop off` ]
  }

  function save () {
    props.save()
    props.close()
  }
  return (
    <Modal
      title={'Warning!'}
      saveBtn={'Save'}
      closeBtn={'Cancel'}
      close={'closeM DeleteStop '}
      closeFn={props.close}
      class={'ModalClose open'}
    >
      <div className='profile'>
        <div>
          <Row>
            <Col span={24}>
              <span className='Text'>
                  You are adding a <b>{arr[0]} address</b> to a <b>{arr[1]} Run</b>, Are you Sure?
              </span>
            </Col>
          </Row>
        </div>
      </div>

      <div className='center'>
        <div className='buttons'>
          <button
            className='cancel'
            onClick={props.close}
          >
            No
          </button>

          <button
            className='save'
            style={{ whitespace: 'pre' }}
            onClick={save}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  )
}
