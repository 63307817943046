import React, { Component, setState } from 'react'
import { connect } from 'react-redux'
import L from 'leaflet'
import PolylineText from '../../../../../components/MapDefault/src/polyline-text/PolylineText'
import {
  assignedPopup,
  directionsPathOptionsAnyColors,
  getRouteMarkers,
  getRouteBounds,
  fnMouseOver,
  fnMouseOut,
  redirectionIcon,
} from '../../../../../components/MapDefault/mapSpecialFns'
import { Marker, Popup } from 'react-leaflet'
import _ from 'lodash'

let redirection = {
  mapIconUrl:
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">\n' +
    '    <defs>\n' +
    '        <circle id="b" cx="360.5" cy="526.5" r="5.5"/>\n' +
    '        <filterMainPage id="a" width="145.5%" height="145.5%" x="-22.7%" y="-13.6%" filterUnits="objectBoundingBox">\n' +
    '            <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter1"/>\n' +
    '            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>\n' +
    '            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5"/>\n' +
    '            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>\n' +
    '            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.105632649 0"/>\n' +
    '        </filterMainPage>\n' +
    '    </defs>\n' +
    '    <g fill="none" fill-rule="evenodd" transform="translate(-353 -520)">\n' +
    '        <use fill="#000" filterMainPage="url(#a)" xlink:href="#b"/>\n' +
    '        <use fill="#FFE23D" stroke="#7B7B7B" xlink:href="#b"/>\n' +
    '    </g>\n' +
    '</svg>\n',
  mapIconColor: '',
  mapIconColorInnerCircle: '',
  pinInnerCircleRadius: 0,
}

let iconRedirection = L.divIcon({
  className: 'leaflet-data-marker',
  html: L.Util.template(redirection.mapIconUrl, {}),
  iconSize: [15, 15],
  popupAnchor: [0, 0],
})

class RouteActive extends Component {
  arrowOptions = {
    repeat: true,
    offset: 3,
    attributes: {
      fill: 'white',
      'font-weight': 'normal',
      'font-size': '12',
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      activeRoute: {},
      runsActive: [],
      childrenList: [],
      checkedList: [],
      routesView: props.routesView,
      compareStart: false,
      isLoading: false,
    }

    this.popupRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loader !== this.props.loader) {
      this.setState({
        isLoading: this.props.loader === 'loadingStart',
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.checkedList !== nextProps.checkedList) {
      this.setState(
        {
          checkedList: nextProps.checkedList,
        },
        () => {
          this.getRunsActive()
        }
      )
    }

    if (this.state.routesView !== nextProps.routesView) {
      this.setState(
        {
          routesView: nextProps.routesView,
        },
        () => {
          this.getRunsActive()
        }
      )
    }

    if (
      !_.isEmpty(nextProps.activeRoute) &&
      this.state.activeRoute !== nextProps.activeRoute
    ) {
      this.setState(
        {
          activeRoute: nextProps.activeRoute,
          compareStart: false,
        },
        () => {
          this.getRunsActive()
        }
      )
    }

    if (this.state.compareStart !== nextProps.compareStart) {
      let checkedList = this.state.checkedList
      if (!nextProps.compareStart) {
        checkedList = []
      }

      this.setState(
        {
          checkedList: checkedList,
          compareStart: nextProps.compareStart,
          // activeRoute: {}
        },
        () => {
          this.getRunsActive()
        }
      )
    }

    this.setState({
      childrenList: nextProps.childrenList,
    })
  }

  getRunById = (id) => {
    let run = null
    this.state.routesView.forEach((gr) => {
      gr.routes.forEach((x) => {
        if (id === x.id) {
          run = x
        }
      })
    })

    return run
  }

  childRiders = (marker, readOnly) => {
    let { isLoading } = this.state
    let riders = []
    let ridersTransfer = []

    let activeRoute = this.getRunById(marker.object.routeId)

    if (!activeRoute) {
      return <div></div>
    }

    let sc = null
    if (activeRoute.points) {
      sc = activeRoute.points.filter((item) => item.type === 3)
    }

    if (marker.object.type * 1 === 3) {
      if (!_.isEmpty(sc)) {
        let obj = (sc || []).find((x) => x.id === marker.object.id)
        if (!obj) {
          return
        }

        if (!obj.routePointChildren) {
          return
        }

        let pickUpChild = obj.routePointChildren.filter((x) => {
          if (x.childaddress && x.childaddress.type === 1) return true
        })
        let dropOffChild = obj.routePointChildren.filter((x) => {
          if (x.childaddress && x.childaddress.type === 2) return true
        })

        pickUpChild.map((address) => {
          let child = (this.props.childrenList || []).find(
            (child) => child.id === address.childaddress.child_id
          )
          if (!child) return false
          child.address = address
          riders.push({
            name: child.full_name,
            id: child.id,
            address: child.address,
          })
        })

        dropOffChild.map((address) => {
          if (this.props.childrenList.length === 0) return false

          let child = (this.props.childrenList || []).find(
            (child) => child.id === address.childaddress.child_id
          )
          if (!child) return false
          child.address = address
          riders.push({
            name: child.full_name,
            id: child.id,
            address: child.address,
          })
        })
      }
    }

    if (marker.object.type * 1 === 5) {
      if (!marker.object) {
        return
      }

      if (_.isEmpty(marker.object.routePointTransferChild)) {
        return
      }

      let pickUpChild = marker.object.routePointTransferChild.filter(
        (x) => x.childAddress.type === 1
      )
      let dropOffChild = marker.object.routePointTransferChild.filter(
        (x) => x.childAddress.type === 2
      )

      if (pickUpChild) {
        pickUpChild.map((item) => {
          let child = item.childAddress.child
          item.routeId = marker.object.routeId

          ridersTransfer.push({
            name: child.full_name,
            id: child.id,
            address: item.address,
            data: item,
          })
        })
      }

      if (dropOffChild) {
        dropOffChild.map((item) => {
          let child = item.childAddress.child
          item.routeId = marker.object.routeId

          ridersTransfer.push({
            name: child.full_name,
            id: child.id,
            address: item.address,
            data: item,
          })
        })
      }

      if (ridersTransfer) {
        return ridersTransfer.map((item) => {
          return (
            <div className="lineBottom">
              <span className="riderIcon flex1">
                {item.name}{' '}
                {!readOnly && (
                  <div className="marginLeft flex1">
                    {' '}
                    {item.data && item.data.childAddress.type === 1 ? (
                      <span className="infoDo">DO</span>
                    ) : (
                      <span className="infoDo">PU</span>
                    )}{' '}
                    <button
                      disabled={isLoading}
                      onClick={(e) => this.childRemoveTransfer(item)}
                      className="removeFromRun disabledBtn"
                    >
                      &nbsp;
                    </button>{' '}
                  </div>
                )}
              </span>
            </div>
          )
        })
      }
    }

    if (marker.object.type * 1 === 2) {
      if (!marker.object) {
        return
      }

      let pickUpChild = marker.object.routePointChildren.filter((x) => {
        if (x.childaddress && x.childaddress.type === 1) return true
      })
      let dropOffChild = marker.object.routePointChildren.filter((x) => {
        if (x.childaddress && x.childaddress.type === 2) return true
      })

      pickUpChild.map((address) => {
        let child = (marker.object.object || []).find(
          (child) => child.id === address.childaddress.child_id
        )
        if (child) {
          child.address = address
          riders.push({
            name: child.full_name,
            id: child.id,
            address: child.address,
          })
        } else {
          riders.push({ name: 'warning', id: 'warning', address: 'warning' })
        }
      })

      dropOffChild.map((address) => {
        let child = (marker.object.object || []).find(
          (child) => child.id === address.childaddress.child_id
        )
        if (child) {
          child.address = address
          riders.push({
            name: child.full_name,
            id: child.id,
            address: child.address,
          })
        } else {
          riders.push({ name: 'warning', id: 'warning', address: 'warning' })
        }
      })
    }

    return riders.map((item) => {
      return (
        <div className="lineBottom">
          <span className="riderIcon">
            {item.name}{' '}
            {!readOnly && (
              <span
                onClick={(e) => this.childRemoveModal(item)}
                className="removeFromRun"
              >
                &nbsp;
              </span>
            )}
          </span>
        </div>
      )
    })
  }

  getRunsActive = () => {
    let { compareStart, checkedList, activeRoute, routesView } = this.state

    if (!routesView.length) return false

    let activeRoutes = []

    if (compareStart) {
      activeRoutes = checkedList
    } else {
      if (checkedList.length === 0) {
        this.setState({
          runsActive: [],
        })
      }
      if (!activeRoute || _.isEmpty(activeRoute)) return false

      activeRoutes = [activeRoute.id]
    }

    let routesGenerate = activeRoutes.map((x) => {
      let route = this.getRunById(x)
      if (!route) return false
      route.markers = getRouteMarkers(route)
      route.bounds = getRouteBounds(route)

      return route
    })

    let boundsAll = []

    routesGenerate.forEach((x) => {
      if (x.bounds) {
        x.bounds.forEach((x) => boundsAll.push(x))
      }
    })

    if (boundsAll.length > 1) {
      this.props.boundsCb(boundsAll)
    }

    routesGenerate = _.compact(routesGenerate)

    this.setState({
      runsActive: routesGenerate,
    })
  }

  clickActiveRun = () => {}

  onMouseEvent = (e, r, off) => {
    if (off && this.popupRef.current) {
      if (this.popupRef.current._close) this.popupRef.current._close()
      return false
    }

    let block = `<div className={"popUpRunView"}>
        ${r.title}
      </div>`

    if (this.props.map.current) {
      let popup = L.popup()
        .setLatLng(e.latlng)
        .setContent(block)
        .openOn(this.props.map.current.leafletElement)

      popup.openPopup()
      this.popupRef.current = popup
    }
  }

  render() {
    this.map = this.props.map
    let { runsActive, isLoading } = this.state
    let assignedPopupBind = assignedPopup.bind(this)
    return (
      <div>
        {runsActive &&
          runsActive.map((r, i) => {
            if (r.osrm && r.osrm.geometry) {
              return (
                <div key={r.id}>
                  <PolylineText
                    arrowOptions={this.arrowOptions}
                    pathOptions={directionsPathOptionsAnyColors(i)}
                    geometry={r.osrm.geometry}
                    onClick={() => this.clickActiveRun(r.id)}
                    onMouseOver={(e) => this.onMouseEvent(e, r)}
                    onMouseOut={(e) => this.onMouseEvent(e, r, true)}
                  />

                  {r.markers &&
                    r.markers.map((marker) => {
                      let index = 1000
                      if (marker && marker.object) {
                        index += marker.object.position * 100

                        return (
                          <Marker
                            key={marker.id}
                            onMouseOver={(e) => fnMouseOver(e)}
                            onMouseOut={(e) => fnMouseOut(e)}
                            zIndexOffset={index}
                            position={marker.location}
                            icon={
                              marker.object.type === 4
                                ? redirectionIcon()
                                : marker.icon
                            }
                          >
                            {assignedPopupBind(marker, true, isLoading)}
                          </Marker>
                        )
                      }
                    })}
                </div>
              )
            }
          })}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    routesView: state.routesView.routes,
    childrenList: state.children.childrenList,
    loader: state.headerLoader.loading,
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteActive)
