import { MapControl, withLeaflet } from 'react-leaflet'
import L from 'leaflet'
import './index.less'
import { totalDistanceWithYd } from '@/helpers/totalInfoByOsrmWithYd'
import { createRef } from 'react'

class MapInfo extends MapControl {
  constructor(props, context) {
    super(props)
    this.obj = this.props
  }

  createLeafletElement(opts) {
    var th = this

    const MapInfo = L.Control.extend({
      options: {
        position: 'topright',
        circleMarker: {
          color: 'black',
          radius: 5,
        },
        lineStyle: {
          color: 'black',
          dashArray: '1,6',
        },
        lengthUnit: {
          display: 'mi',
          decimal: 2,
          factor: 0.621371,
          label: ':',
        },
      },

      onAdd: function (map) {
        this._map = map
        this._container = L.DomUtil.create('div', 'leaflet-bar hide')
        this._container.classList.add('leaflet-ruler')
        L.DomEvent.disableClickPropagation(this._container)
        this._allLayers = L.layerGroup()
        return this._container
      },
      _clear: function (id) {
        this._allLayers.eachLayer((x) => {
          if (x._leaflet_id === id) {
            this._map.removeLayer(x)
            this._map.closePopup()
          }
        })
      },
      onRemove: function () {
        L.DomEvent.off(this._container, 'click', this._toggleMeasure, this)
      },
      _onContecxt: function (e) {
        return false
        // this._map.callbacks()

        if (e.button != 2) {
        }
        e.stopPropagation()

        if (this._drawing) {
          document.querySelector('.zone').className = 'zone'
          document.querySelector('.ruler').className = 'ruler'

          this._toggleMeasure()

          if (this._clickCount > 0) {
            this._closePath()
          } else {
            this._choice = true
          }
          this._drawing = false
          th.props.callbacks(false)
        }
      },
      _toggleMeasure: function (forceDisable = false) {
        if (this._lastToggle && Date.now() - this._lastToggle < 200) {
          return
        }
        this._lastToggle = Date.now()
        this._choice = forceDisable ? false : !this._choice
        this._clickedLatLong = null
        this._clickedPoints = []
        this._totalLength = 0
        this._clickCount = 0
        this._drawing = false

        if (this._choice) {
          this._map.doubleClickZoom.disable()
          this._map._container.style.cursor = 'crosshair'
          L.DomEvent.on(this._map._container, 'keydown', this._escape, this)
          // L.DomEvent.on(this._map._container, 'dblclick', this._closePath, this)
          this._container.classList.add('leaflet-ruler-clicked')

          let ruler = new L.layerGroup()
          this._tempLine = L.featureGroup().addTo(ruler)
          this._tempPoint = L.featureGroup().addTo(ruler)
          this._pointLayer = L.featureGroup().addTo(ruler)
          this._polylineLayer = L.featureGroup().addTo(ruler)

          ruler.addTo(this._allLayers)

          L.DomEvent.on(
            this._pointLayer,
            'contextmenu',
            (e) => this._contextmenu(e, ruler),
            this
          )

          ruler.addTo(this._map)

          this._map.on('click', this._clicked, this)
          this._map.on('mousemove', this._moving, this)
        } else {
          this._map.doubleClickZoom.enable()
          L.DomEvent.off(this._map._container, 'keydown', this._escape, this)
          L.DomEvent.off(
            this._map._container,
            'dblclick',
            this._closePath,
            this
          )
          this._container.classList.remove('leaflet-ruler-clicked')
          this._map._container.style.cursor = 'pointer'
          this._map.off('click', this._clicked, this)
          this._map.off('mousemove', this._moving, this)

          // Ensure temporary layers are removed when disabling the tool
          if (this._tempLine) {
            this._map.removeLayer(this._tempLine)
            this._tempLine = null
          }
          if (this._tempPoint) {
            this._map.removeLayer(this._tempPoint)
            this._tempPoint = null
          }

          // Reset other states to ensure no conflicts with other tools
          this._clickedLatLong = null
          this._clickedPoints = []
          this._totalLength = 0
          this._clickCount = 0
          this._drawing = false
        }
      },
      _contextmenu: function (e, ruler) {
        L.DomEvent.stopPropagation(e)
        let id = `ruler${ruler._leaflet_id}`
        let popup = L.popup()
          .setLatLng(e.latlng)
          .setContent(
            '<div>' +
              `<div id="${id}" class="leaflet-popup-content justifyBetween">` +
              'Delete ruler' +
              '</div></div>'
          )
          .openOn(this._map)

        this._allLayers.addLayer(popup)

        let ul = document.getElementById(id)
        ul.addEventListener('click', (e) => {
          this._clear(ruler._leaflet_id)
        })
      },
      _clicked: function (e) {
        this._drawing = true
        this._clickedLatLong = e.latlng
        this._clickedPoints.push(this._clickedLatLong)
        L.circleMarker(this._clickedLatLong, this.options.circleMarker).addTo(
          this._pointLayer
        )
        if (
          this._clickCount > 0 &&
          !e.latlng.equals(this._clickedPoints[this._clickedPoints.length - 2])
        ) {
          if (this._movingLatLong) {
            L.polyline(
              [this._clickedPoints[this._clickCount - 1], this._movingLatLong],
              this.options.lineStyle
            ).addTo(this._polylineLayer)
          }
          var text
          this._totalLength += this._result.Distance
          if (this._clickCount > 1) {
            text = totalDistanceWithYd(
              Number(
                this._totalLength.toFixed(this.options.lengthUnit.decimal)
              ),
              true
            )
          } else {
            text = totalDistanceWithYd(
              Number(
                this._result.Distance.toFixed(this.options.lengthUnit.decimal)
              ),
              true
            )
          }

          L.circleMarker(this._clickedLatLong, this.options.circleMarker)
            .bindTooltip(text, {
              permanent: true,
              direction: 'left',
              className: 'moving-tooltip',
              offset: L.point(-5, -5),
            })
            .addTo(this._pointLayer)
            .openTooltip()
        }
        this._clickCount++
      },
      _moving: function (e) {
        this._drawing = true
        if (this._clickedLatLong) {
          L.DomEvent.off(this._container, 'click', this._toggleMeasure, this)
          this._movingLatLong = e.latlng
          if (this._tempLine) {
            this._map.removeLayer(this._tempLine)
            this._map.removeLayer(this._tempPoint)
          }
          var text
          this._addedLength = 0
          this._tempLine = L.featureGroup()
          this._tempPoint = L.featureGroup()
          this._tempLine.addTo(this._map)
          this._tempPoint.addTo(this._map)
          this._calculateBearingAndDistance()
          this._addedLength = this._result.Distance + this._totalLength
          L.polyline(
            [this._clickedLatLong, this._movingLatLong],
            this.options.lineStyle
          ).addTo(this._tempLine)
          if (this._clickCount > 1) {
            text =
              totalDistanceWithYd(
                Number(
                  this._addedLength.toFixed(this.options.lengthUnit.decimal)
                ),
                true
              ) +
              '<br><div class="plus-length hide">(+' +
              totalDistanceWithYd(
                Number(
                  this._result.Distance.toFixed(this.options.lengthUnit.decimal)
                ),
                true
              ) +
              ')</div>'
          } else {
            text = totalDistanceWithYd(
              Number(
                this._result.Distance.toFixed(this.options.lengthUnit.decimal)
              ),
              true
            )
          }
          L.circleMarker(this._movingLatLong, this.options.circleMarker)
            .bindTooltip(text, {
              sticky: true,
              direction: 'left',
              offset: L.point(-10, -5),
              className: 'moving-tooltip',
            })
            .addTo(this._tempPoint)
            .openTooltip()
        }
      },
      _escape: function (e) {
        if (th.obj.escClick) th.obj.escClick()

        e.stopPropagation()

        if (e.keyCode === 27) {
          this._drawing = false

          // Remove temporary layers
          if (this._tempLine) {
            this._map.removeLayer(this._tempLine)
            this._tempLine = null
          }
          if (this._tempPoint) {
            this._map.removeLayer(this._tempPoint)
            this._tempPoint = null
          }

          if (this._clickCount > 0) {
            this._closePath()
          } else {
            this._choice = true
          }

          this._toggleMeasure(true) // Ensure the tool is disabled
        }
      },

      _calculateBearingAndDistance: function () {
        var f1 = this._clickedLatLong.lat
        var l1 = this._clickedLatLong.lng
        var f2 = this._movingLatLong.lat
        var l2 = this._movingLatLong.lng
        var toRadian = Math.PI / 180
        // haversine formula
        // distance
        var R = this.options.lengthUnit.factor
          ? 6371 * this.options.lengthUnit.factor
          : 6371 // kilometres
        var deltaF = (f2 - f1) * toRadian
        var deltaL = (l2 - l1) * toRadian
        var a =
          Math.sin(deltaF / 2) * Math.sin(deltaF / 2) +
          Math.cos(f1 * toRadian) *
            Math.cos(f2 * toRadian) *
            Math.sin(deltaL / 2) *
            Math.sin(deltaL / 2)
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        var distance = R * c
        this._result = {
          Distance: distance,
        }
      },
      _closePath: function () {
        if (this._tempLine) {
          this._map.removeLayer(this._tempLine)
          this._tempLine = null
        }
        if (this._tempPoint) {
          this._map.removeLayer(this._tempPoint)
          this._tempPoint = null
        }
        if (this._clickCount <= 1) {
          this._map.removeLayer(this._pointLayer)
        }
        this._clickedLatLong = null
        this._clickedPoints = []
        this._totalLength = 0
        this._clickCount = 0
        this._drawing = false
        L.DomEvent.on(this._container, 'click', this._toggleMeasure, this)
      },
    })
    return new MapInfo()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const res = createRef(false)
    if (nextProps.startRule && !res.current) {
      res.current = true
      this.leafletElement._toggleMeasure()
    }
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
  }

  componentDidMount() {
    const { map } = this.props.leaflet
    map.addEventListener(
      'contextmenu',
      function (e) {
        return false
      },
      false
    )

    this.leafletElement.addTo(map)
  }
}

export default withLeaflet(MapInfo)
