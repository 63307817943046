import { useImportRidersStore } from '@/entities/modals/RidersImportModal/RidersImportModal'
import { useModalStore } from '@/store/modal/ModalStore'
import { getChildren } from '@/redux/children/actions'
import {
  IMeta,
  IResponse,
} from '@/features/containers/SocketConnect/SocketConnect.type'
import { useRootZustandStore } from '@/pages/FieldTrip/Attendance/V2Attendance/Attendance.store'
import { useImportSocketResponse } from '@/features/containers/SocketConnect/store'
import { sessionApi } from '@/store/session/sessionApi'

export const socketImports = (msg: IResponse<string>) => {
  const dispatch = useRootZustandStore.getState().dispatch

  const uidImport = useImportSocketResponse.getState().uidImport
  if (msg.uidImport !== uidImport) {
    return
  }

  if (msg.statusMessage === 'start_import') {
    useImportSocketResponse.getState().setProcess('start')

    if (Array.isArray((msg.meta as any)?.ignored)) {
      useImportSocketResponse.getState().serArrayData(
        (msg.meta as any).ignored.map((el) => {
          return {
            statusMessage: 'count_ignored',
            meta: {
              errors: [el],
              data: {},
            },
            status: 1,
            uidImport: '1',
          }
        })
      )
    }
    useImportRidersStore.getState().setImportRidersStore({
      total: ((msg?.meta as any)?.total || 1) - 1,
    })
  } else if (msg.statusMessage === 'end_import') {
    const sessionId = useModalStore.getState().state?.sessionId
    const pathName = window.location.pathname
    if (pathName.includes('/riders')) {
      dispatch(getChildren(sessionId))
    } else if (pathName.includes('/sessions')) {
      const [getSessions] = sessionApi.endpoints.getAllSeasons.useLazyQuery()

      getSessions()
    }

    useImportSocketResponse.getState().setProcess('end')
  } else {
    const meta: IMeta = JSON.parse(msg?.meta || '{}')
    useImportSocketResponse.getState().setData({ ...msg, meta })
  }
}
