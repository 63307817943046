import * as types from './actionTypes'
import {
  FTRErrorType,
  FTRStatus,
} from '../../../pages/FieldTrip/Requests/enums'
import _ from 'lodash'
import { FieldTripRequest } from '../../../pages/FieldTrip/Run/models'

class Error {
  /** @type {FTRErrorType} */
  type

  /** @type {string} */
  message

  /**
   *
   * @param {FTRErrorType} type
   * @param {string} message
   */
  constructor(type, message) {
    this.type = type
    this.message = message
  }
}

class State {
  /** @type {FieldTripRequest[]} */
  data

  /** @type {Error} */
  error

  /** @type {boolean} */
  successSubmit

  /**
   *
   * @param {FieldTripRequest[]} data
   * @param {Error} error
   * @param {boolean} successSubmit
   */
  constructor(data = [], error = null, successSubmit = null) {
    this.data = data
    ;(this.error = error), (this.successSubmit = successSubmit)
  }
}

class Action {
  /** @type {string} */
  type

  /** @type {any} */
  payload

  /** @type {string} */
  error
}

export default (state = new State(), action = new Action()) => {
  switch (action.type) {
    case types.GET_REQUESTS: {
      let newState = _.cloneDeep(state)
      delete newState.error
      newState.data = action.payload.data

      if (!action.payload.filters) {
        state = newState
        return { data: newState.data }
      }

      switch (action.payload.filters.status) {
        case FTRStatus.ALL: {
          state = newState
          break
        }

        case FTRStatus.STATUS_NEW: {
          newState.data = newState.data.filter(
            (x) => x.status === FTRStatus.STATUS_NEW
          )
          break
        }

        case FTRStatus.STATUS_IN_PROGRESS: {
          newState.data = newState.data.filter(
            (x) => x.status === FTRStatus.STATUS_IN_PROGRESS
          )
          break
        }

        case FTRStatus.STATUS_COMPLEATE: {
          newState.data = newState.data.filter(
            (x) => x.status === FTRStatus.STATUS_COMPLEATE
          )
          break
        }

        case FTRStatus.STATUS_REJECTED: {
          newState.data = newState.data.filter(
            (x) => x.status === FTRStatus.STATUS_REJECTED
          )
          break
        }

        default: {
          state = newState
          break
        }
      }

      if (action.payload.filters.nameId !== '') {
        let id = parseInt(action.payload.filters.nameId)

        if (id) {
          newState.data = newState.data.filter((x) => x.id === id)
        } else {
          newState.data = newState.data.filter((x) =>
            x.name
              .toLowerCase()
              .includes(action.payload.filters.nameId.toLowerCase())
          )
        }
      }

      newState.isUpdate = false

      state = newState
      return state
    }

    case types.GET_REQUEST: {
      let newState = _.cloneDeep(state)
      return newState
    }

    case types.GET_REQUESTS_BUILDER: {
      let newState = _.cloneDeep(state)
      delete newState.error
      newState.data = []

      action.payload.data.forEach((request) => {
        let isUnique = true
        if (isUnique) {
          newState.data.push(request)
        }
      })

      if (!action.payload.filters) {
        state = newState
        return { data: newState.data }
      }

      switch (action.payload.filters.status) {
        case FTRStatus.ALL: {
          state = newState
          break
        }

        case FTRStatus.STATUS_NEW: {
          newState.data = newState.data.filter(
            (x) => x.status === FTRStatus.STATUS_NEW
          )
          break
        }

        case FTRStatus.STATUS_IN_PROGRESS: {
          newState.data = newState.data.filter(
            (x) => x.status === FTRStatus.STATUS_IN_PROGRESS
          )
          break
        }

        case FTRStatus.STATUS_COMPLEATE: {
          newState.data = newState.data.filter(
            (x) => x.status === FTRStatus.STATUS_COMPLEATE
          )
          break
        }

        case FTRStatus.STATUS_REJECTED: {
          newState.data = newState.data.filter(
            (x) => x.status === FTRStatus.STATUS_REJECTED
          )
          break
        }

        default: {
          state = newState
          break
        }
      }

      if (action.payload.filters.nameId !== '') {
        let id = parseInt(action.payload.filters.nameId)

        if (id) {
          newState.data = newState.data.filter((x) => x.id === id)
        } else {
          newState.data = newState.data.filter((x) =>
            x.name
              .toLowerCase()
              .includes(action.payload.filters.nameId.toLowerCase())
          )
        }
      }

      state = newState
      return state
    }
    case types.DELETE_REQUESTS: {
      let newState = _.cloneDeep(state)
      newState.data = newState.data.filter((x) => x.id !== action.payload)
      return newState
    }

    case types.GET_APPROVALS: {
      let newState = _.cloneDeep(state)
      newState.data.forEach((x) => {
        if (x.field_trip_id === action.payload.id) {
          x.availableApprovals = action.payload.availableApprovals
        }
      })
      return newState
    }

    case types.COMBINE_TRIPS: {
      let data = []
      let newAction = action
      let newState = state
      delete newState.error
      newAction.payload.data.forEach((item) => {
        newState.data = newState.data.filter(
          (x) => x.id !== newState.data.find((y) => y.id === item.id).id
        )
        newState.data.push(item)
      })
      state = newState
      return _.cloneDeep(state)
    }

    case types.BUILD_TRIP: {
      let newState = _.cloneDeep(state)
      let answer = action.payload.data
      let newItem = newState.data.findIndex((x) => x.id === answer.id)
      newState.data.splice(newItem, 1, answer)
      return newState
    }
    case types.EDIT_TRIP: {
      let newState = _.cloneDeep(state)
      let oldTripRequest = newState.data.find(
        (x) => x.field_trip_id === action.payload.data.id
      )
      let oldRuns = oldTripRequest.fieldTrip.fieldTripRuns

      oldRuns.map((run) => {
        if (run.osrm) {
          let newRun = action.payload.data.fieldTripRuns.find(
            (x) => x.id === run.id
          )

          if (newRun) {
            newRun.fieldTripPoints.map((point) => {
              if (point.address) {
                point.address_id = point.address.id
              }
            })

            action.payload.data.fieldTripRuns.find(
              (x) => x.id === run.id
            ).osrm = run.osrm
          }
        }
      })

      newState.data.map((item) => {
        if (item.field_trip_id === action.payload.data.id) {
          item.fieldTrip = action.payload.data
          return item
        }
      })

      newState.isUpdate = true

      return newState
    }

    case types.REVERSE_POINTS: {
      let newState = _.cloneDeep(state)

      newState.data.map((item) => {
        if (item.field_trip_id === action.payload.data.id) {
          item.fieldTrip = action.payload.data
          return item
        }
      })

      return newState
    }
    case types.CREATE_POINT: {
      let newState = _.cloneDeep(state)

      newState.data
        .find((x) => x.id === action.payload.tripId)
        .fieldTrip.fieldTripRuns.map((ftRun) => {
          if (ftRun.id === action.payload.runId) {
            ftRun.fieldTripPoints = action.payload.data
            ftRun.osrm = null
          }

          return ftRun
        })

      return newState
    }
    case types.ADD_RIDERS: {
      let newState = _.cloneDeep(state)
      newState.data
        .find((x) => x.id === action.payload.tripId)
        .fieldTrip.fieldTripRuns.map((ftRun) => {
          if (ftRun.id === action.payload.runId) {
            const osrm = { ...ftRun.osrm }
            delete ftRun.fieldTripPoints

            ftRun.fieldTripPoints = [...action.payload.data]
            ftRun.osrm = osrm
          }
          return ftRun
        })
      return newState
    }

    case types.REMOVE_RIDERS: {
      let newState = _.cloneDeep(state)

      newState.data
        .find((x) => x.id === action.payload.tripId)
        .fieldTrip.fieldTripRuns.map((ftRun) => {
          if (ftRun.id === action.payload.runId) {
            const osrm = { ...ftRun.osrm }
            delete ftRun.fieldTripPoints

            ftRun.fieldTripPoints = [...action.payload.data]
            ftRun.osrm = osrm
          }

          return ftRun
        })

      return newState
    }
    case types.EDIT_POINT: {
      let newState = _.cloneDeep(state)
      newState.data
        .find((x) => x.id === action.payload.tripId)
        .fieldTrip.fieldTripRuns.map((ftRun) => {
          if (ftRun.id === action.payload.runId) {
            let reload = solveFn(ftRun.fieldTripPoints, action.payload.data)
            if (reload) {
              ftRun.osrm = null
              ftRun.dndLoad = false
            }
            ftRun.fieldTripPoints = action.payload.data
          }
          return ftRun
        })
      return newState
    }

    case types.DELETE_POINT: {
      let newState = _.cloneDeep(state)

      newState.data
        .find((x) => x.id === action.payload.tripId)
        .fieldTrip.fieldTripRuns.map((ftRun) => {
          if (ftRun.id === action.payload.runId) {
            ftRun.fieldTripPoints = action.payload.data
            ftRun.osrm = null
          }

          return ftRun
        })

      return newState
    }
    case types.EDIT_TRIP_NAME: {
      let newState = _.cloneDeep(state)

      let newItems = newState.data.filter(
        (x) => x.field_trip_id === action.payload.data.id
      )

      newItems.map((item) => {
        item.fieldTrip = action.payload.data
      })

      newItems.forEach((item) => {
        newState.data.find((x) => x.id === item.id).fieldTrip = item.fieldTrip
      })

      newState.selectedTrip = action.payload.data[0]
      return newState
    }

    case types.SUBMIT_TRIP: {
      let newState = new State(state.data)

      if (action.payload.error) {
        newState.error = new Error(
          FTRErrorType.FieldTripSubmit,
          action.payload.error
        )
        newState.successSubmit = false

        return newState
      } else {
        newState.successSubmit = true
      }

      let fieldTripRequest = newState.data.find(
        (x) => x.fieldTrip && x.fieldTrip.id === action.payload.data.id
      )

      fieldTripRequest.fieldTrip = action.payload.data

      return newState
    }

    case types.OSRM: {
      let trip = action.find[0]
      let run = action.find[1]
      let osrm = action.payload

      let newArr = _.cloneDeep(state)
      let result = new State()

      let newItem = newArr.data.find((x) => x.id === trip)
      let newRun = newItem.fieldTrip.fieldTripRuns.find((x) => x.id === run)

      newRun.osrm = osrm

      result.data = newArr.data

      return _.cloneDeep(result)
    }

    case types.GET_TRIP: {
      const newState = _.cloneDeep(state)
      newState.attendance = action.payload.data

      return newState
    }

    case types.CHECK_ALL: {
      let newState = _.cloneDeep(state)
      newState.checkAll = true

      return newState
    }

    case types.UNCHECK_ALL: {
      let newState = _.cloneDeep(state)
      newState.checkAll = false

      return newState
    }

    default: {
      return state
    }
  }
}

function solveFn(old, next) {
  return !old.every((x, i) => {
    if (
      JSON.stringify(x.address) === JSON.stringify(next[i].address) &&
      x.title === next[i].title &&
      x.use_default_rule === next[i].use_default_rule &&
      x.curbside_pickup_only === next[i].curbside_pickup_only
    ) {
      return true
    } else {
      //time

      return false
    }
  })
}
