import { createSelector } from 'reselect'

export const getState = (state) => state

export const getChildrenList = createSelector(
  getState,
  (state) => state.children.childrenList
)

export const getActiveSeason = createSelector(
  getState,
  (state) => state.sessions.activeSeason
)

export const getCampMode = createSelector(
  getState,
  (state) => state.auth.company.camp_mode
)

export const getAddChildError = createSelector(
	getState,
	(state) => state.children.addChildError
)