import React, { Fragment, useEffect, useState } from 'react'
import Input from 'antd/es/input'
import './index.less'
import _ from 'lodash'
import { Trans } from 'react-i18next'
import { NewAntInput } from '../../../../entities/ui/NewInput/NewAntInput'

export default function (props) {
  const [openRiderPlot, setOpenRiderPlot] = useState(false)
  const [childrenList, setChildrenList] = useState([])
  const [childrenListF, setChildrenListF] = useState([])
  const [plotRiderName, setPlotRiderName] = useState('')

  useEffect(() => {
    if (childrenList !== props.childrenList) {
      setChildrenList(props.childrenList)
      setChildrenListF(props.childrenList)
    }
  })

  function ridersList() {
    return (
      <div className={`ridersList`}>
        <NewAntInput
          placeholder={'Search'}
          onChange={ridersFilter}
          type="text"
          className={'search-plot-input'}
        />
        {childrenListF.map((x, index) => (
          <Fragment key={index}>{getRiderLine(x)}</Fragment>
        ))}
      </div>
    )
  }

  function selectRider(e, x, a) {
    let data = ''
    if (a) data = { plotAddress: a.address.address }
    if (!a) {
      let plotRiderAddressInfo = x.addresses.find(
        (x) => !x.is_school && x.address.address
      )
      if (!plotRiderAddressInfo) return false
      data = { plotAddress: plotRiderAddressInfo.address.address }
    }
    props.save(data)
    setPlotRiderName(x.full_name)
    setOpenRiderPlot(false)
    e.stopPropagation()
  }

  function getRiderLine(x) {
    let addresses = x.addresses.filter((y) => !y.is_school && y.address)
    addresses = _.uniqBy(addresses, (x) => x.address.address)
    if (_.isEmpty(addresses)) return false
    return (
      <div
        className={'plot-rider-name-line'}
        onClick={(e) => selectRider(e, x)}
      >
        <div>
          <span>{x.full_name}</span>
        </div>
        {addresses.map((a, index) => {
          return (
            <div key={index} onClick={(e) => selectRider(e, x, a)}>
              <div className={'plot-rider-address'}>{a.address.address}</div>
            </div>
          )
        })}
      </div>
    )
  }

  function ridersFilter(e) {
    let value = e.target.value
    let filtered = childrenList.filter((x) =>
      x.full_name.toLowerCase().includes(value.toLowerCase())
    )
    setChildrenListF(filtered)
  }

  function clear(e) {
    setPlotRiderName('')
    e.stopPropagation()
    props.save({
      plotAddress: '',
      plotRiderAddressInfo: [],
    })
  }

  function openRiderPlotFn(e) {
    if (e.target.className.includes('search-plot-input')) return false
    setOpenRiderPlot(!openRiderPlot)
  }

  return (
    <div
      onClick={!props.disabled && openRiderPlotFn}
      id={plotRiderName ? 'orangeSel' : ''}
      className={`input-rider-plot ${openRiderPlot ? 'borderBlue' : ''} ${
        props.className
      }`}
    >
      {!props.disabled && openRiderPlot && ridersList()}

      <span
        style={{ display: 'flex', alignItems: 'center', height: '100%' }}
        className="plotSpan plotSpan-active"
      >
        {plotRiderName ? (
          plotRiderName
        ) : (
          <span
            style={{ opacity: 0.35, fontFamily: "'SBM-Light', sans-serif" }}
          >
            <Trans>Plot Rider By Name </Trans>
          </span>
        )}
      </span>
      {!plotRiderName && (
        <span
          style={{ opacity: 0.35 }}
          className={`arrow-v ${openRiderPlot ? 'upArr' : ''}`}
          onClick={() => !props.disabled && setOpenRiderPlot(!openRiderPlot)}
        />
      )}
      {plotRiderName && (
        <span className={'clear-x'} onClick={(e) => clear(e)} />
      )}
    </div>
  )
}
