import React from 'react'

export const FilterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M4.521 5.67a.74.74 0 0 1 .195.502v5.456a.37.37 0 0 0 .631.265l1.522-1.745c.204-.244.316-.365.316-.607V6.173c0-.185.07-.364.195-.501l4.368-4.74A.556.556 0 0 0 11.34 0H.562a.556.556 0 0 0-.409.932L4.521 5.67z"
    />
  </svg>
)
