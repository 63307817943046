import React, { Component } from "react";
import './index.less'
import  {getIconLegend}  from './icon'
import {Trans} from 'react-i18next'

class Legend extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let padding;
    if (this.props.runPage === true) {
      padding = { paddingLeft: `400px` };
    }
    return (
      <div style={padding}  className="legendView">
        <div className="legendDiv">
          <b className="textTop"><Trans>Primary</Trans></b>
          {getIconLegend('pickup')}
          <span><Trans>Pick Up</Trans></span>
        </div>

        <div className="legendDiv">
          {getIconLegend('dropoff')}
          <span><Trans>Drop Off</Trans></span>
        </div>

        <div className="legendDiv">
          <b className="textTop"><Trans>Secondary</Trans></b>
          {getIconLegend('pickupSecond')}
          <span><Trans>Pick Up</Trans></span>
        </div>

        <div className="legendDiv">
          {getIconLegend('dropoffSecond')}
          <span><Trans>Drop Off</Trans></span>
        </div>

        <div className="legendDiv">
          {getIconLegend('stop')}
          <span><Trans>Bus Stop</Trans></span>
        </div>


        <div className="legendDiv">
          {getIconLegend('rider')}
          <span><Trans>Rider Stop</Trans></span>
        </div>

        <div className="legendDiv">
          {getIconLegend('transfer')}
          <span><Trans>Transfer Stop</Trans></span>
        </div>

        <div className="legendDiv">
          {getIconLegend('school')}
          <span><Trans>School</Trans></span>
        </div>

        <div className="legendDiv">
          {getIconLegend('depot')}
          <span><Trans>Depot</Trans></span>
        </div>
      </div>
    );
  }
}

export default Legend;
