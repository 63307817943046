import styles from './RunInfo.module.less'
import classNames from 'classnames'
import { memo } from 'react'
import { TCloneTypes } from '@/entities/modals/CloneRunModal/CloneRunModal'
import { IUseRunInfo } from '@/entities/modals/CloneRunModal/components/RunInfo/useRunInfo'
import { getViewPosition } from '@/entities/modals/CloneRunModal/components/RunInfo/helpers/getViewPosition'
import { useCloneRunStore } from '@/entities/modals/CloneRunModal/store'

export interface IProps {
  type: TCloneTypes
}
export const RunInfo = memo(
  ({ points, errosIds, transferError }: IUseRunInfo) => {
    const getRiderFromList = useCloneRunStore.getState().getRiderFromList

    const getPosition = getViewPosition()
    if (!points?.length) {
      return <></>
    }
    return (
      <article className={styles.sideRoot}>
        {points?.map((point, index) => {
          const riderFromList =
            point.type === 1 ? getRiderFromList(point?.object?.id) : undefined

          return (
            <div className={styles.pointItem} key={point.id}>
              <div
                className={classNames(
                  styles.borderLeft,
                  styles['stop-' + point.type]
                )}
              />
              <div className={styles.pointItemRightSide}>
                <div className={styles.pointTitle}>
                  <span data-testid={`point-position-${point.id}`}>
                    {getPosition(point, index)}
                  </span>
                  <span
                    data-testid={`point-name-${point.id}`}
                    className={classNames({
                      [styles.errorName]:
                        errosIds?.[riderFromList?.id] ||
                        (transferError && point.type === 5),
                    })}
                  >
                    {getPointName(point)}
                  </span>
                </div>
                {point.type === 2 && !!point?.routePointChildren.length && (
                  <div
                    data-testid={`rider-list-${point.id}`}
                    className={styles.ridersList}
                  >
                    {point?.routePointChildren.map(
                      (rider, riderIndex, riderArray) => {
                        const riderFromList = getRiderFromList(rider?.child_id)
                        return (
                          <div
                            className={classNames(styles.riderInList, {
                              [styles.borderBottom]:
                                riderIndex + 1 !== riderArray.length,
                            })}
                            key={rider?.id}
                          >
                            <span
                              data-testid={`rider-list-rider-position-${rider?.id}`}
                            >
                              {riderIndex + 1}.
                            </span>
                            <span
                              data-error={!!errosIds?.[riderFromList?.id]}
                              data-testid={`rider-list-rider-fullname-${rider?.id}`}
                              className={classNames({
                                [styles.errorName]:
                                  errosIds?.[riderFromList?.id],
                              })}
                            >
                              {riderFromList?.full_name}
                            </span>
                            <span
                              data-testid={`rider-list-rider-type-${rider?.id}`}
                              className={styles.addressType}
                            >
                              {rider?.childaddress?.type === 1 ? 'PU' : 'DO'}
                            </span>
                          </div>
                        )
                      }
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </article>
    )
  }
)

const getPointName = (point: any) => {
  return (
    point?.busStopTitle || point?.object?.name || point?.object?.full_name || ''
  )
}
