import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

import './styles.less'

const BaseModal = (props) => {
  useEffect(() => {
    const escFunction = (event) => {
      if (event.keyCode === 27) {
        props.closeFn()
      }
      if (event.keyCode === 13) {
        if (typeof props.save === 'function') {
          props.save()
        }
      }
    }

    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [props])

  const closeModal = (e) => {
    const { title } = props
    if (
      e.target.className.indexOf('modalsRan') === 0 &&
      title &&
      typeof title === 'string' &&
      title.indexOf('Riders Import') === 0
    ) {
      props.closeFn()
      return
    }

    if (
      typeof e.target.className === 'string' &&
      e.target.className.indexOf('modalsRan') > -1
    ) {
      props.closeFn()
    }
  }

  const {
    title,
    children: content,
    class: addClass,
    close,
    closeFn,
    dopClass,
    id,
  } = props

  return ReactDOM.createPortal(
    <div
      id={id && id}
      className={`modalsRan`}
      onMouseDown={(e) => {
        e.persist()
        if (e.clientX + 10 >= e.target?.clientWidth) return
        closeModal(e)
      }}
    >
      <div
        style={{ overflow: 'visible' }}
        className={`open modal ${addClass ? addClass : ' open ' + dopClass}`}
      >
        <div
          onClick={closeFn}
          className={close ? close : 'closeM' + ' ' + props.class + ' open'}
        />
        {!_.isEmpty(title) ? (
          <div id={id && id} className="header">
            <span>{title}</span>
          </div>
        ) : null}
        {content}
      </div>
    </div>,
    document.body
  )
}

export default BaseModal
