import { NewModal } from '@/entities/ui/NewModal/NewModal'
import { ModalsEnum, useModalSelector } from '@/store/modal/ModalStore'
import styles from './AlertParentEmailModal.module.less'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { useLazySendParentEmailQuery } from '@/store/routeTool/routeTool'
import { useAppSelector } from '@/redux'
import { useMemo } from 'react'

export const AlertParentEmailModal = () => {
  const { isOpen, onCloseModal, state } = useModalSelector(
    ModalsEnum.ALERT_PARENT_EMAIL
  )

  const ids = state?.arrayId || []
  return isOpen ? (
    <_AlertParentEmailModal
      ids={ids}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
    />
  ) : null
}

const _AlertParentEmailModal = ({
  isOpen,
  onCloseModal,
  ids,
}: {
  isOpen: boolean
  onCloseModal: () => void
  ids: number[]
}) => {
  const [request, response] = useLazySendParentEmailQuery()
  const companyInfo = useAppSelector((state) => state.auth)

  const childrenList = useAppSelector((state) =>
    state.children.childrenList?.filter(({ id }) => ids.includes(id))
  )

  const totalEmailsNumbers = useMemo(
    () =>
      childrenList?.reduce(
        (totalChild, child) =>
          totalChild +
          Object.keys(child).reduce((total, acc) => {
            if (
              (companyInfo?.company?.enabledEmails ?? []).includes(acc) &&
              child[acc]
            ) {
              return total + 1
            }
            return total
          }, 0),
        0
      ),
    [childrenList, companyInfo?.company?.enabledEmails]
  )
  const onSend = () => {
    request({ ids })
  }

  return (
    <NewModal width={500} open={isOpen} onClose={onCloseModal}>
      <p data-testid="confirmEmailModal" className={styles.title}>
        You are about to send an email to all selected rider’s parents (
        <b>{totalEmailsNumbers}</b> emails).
      </p>
      <div className={styles.footer}>
        <Button
          disabled={response.isFetching}
          buttonColor="gray"
          onClick={onCloseModal}
        >
          Cancel
        </Button>
        <Button
          data-testid="sendEmailBtn"
          disabled={response.isFetching || !totalEmailsNumbers}
          onClick={onSend}
        >
          Send
        </Button>
      </div>
    </NewModal>
  )
}
