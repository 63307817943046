import { Controller, FormProvider, useForm } from 'react-hook-form'
import { ModalFooter } from '@/entities/ui/NewModal/components/ModalFooter'
import { FormInput } from '@/entities/ui/NewInput/FormInput'
import { FormSelect } from '@/entities/ui/NewSelect/NewSelect'
import { Radio } from 'antd'
import {
  useGetBusDepotListQuery,
  useGetBusesQuery,
  useGetDriversQuery,
} from '@/store/resources/resources'
import styles from './AddNewRunModal.module.less'
import { filterArrByAbcInt } from '@/helpers/arrayWork'
import { useMemo } from 'react'
import { store } from '@/redux'
import { useCreateNewRunMutation } from '@/store/routeTool/routeTool'

export interface IAddNewRunForm {
  is_morning: number
  driver_id: number
  bus_depot_id: number
  session_id?: number
  mode?: string
}

export const AddNewRunModal = () => {
  const buses = useGetBusesQuery()
  const drivers = useGetDriversQuery()
  const depots = useGetBusDepotListQuery()
  const [createNewRun, createResponse] = useCreateNewRunMutation()
  const busesOptions = filterArrByAbcInt(
    (buses?.data?.data ?? []).filter((bus) => bus.is_active),
    'bus_number'
  )?.map((bus) => ({ label: bus.bus_number, value: bus.id }))

  const driversOptions = filterArrByAbcInt(
    (drivers?.data?.data ?? []).filter((driver) => driver.is_active),
    'name'
  )?.map((driver) => ({ label: driver.name, value: driver.id }))

  const depotsOptions = filterArrByAbcInt(depots?.data?.data ?? [], 'name').map(
    (depot) => ({ label: depot.name, value: depot.id })
  )

  const methods = useForm<IAddNewRunForm>({
    defaultValues: useMemo(
      () => ({
        is_morning: 1,
        driver_id: driversOptions?.[0]?.value || null,
        bus_depot_id: depotsOptions?.[0]?.value || null,
      }),
      [depotsOptions, driversOptions]
    ),
  })

  const onSubmit = (data: IAddNewRunForm) => {
    const season_id = store.getState().sessions.activeSeason.id
    const _data = {
      mode: 'tags',
      season_id,
      ...data,
    }
    createNewRun(_data)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.form}>
        <FormInput
          rules={{
            maxLength: {
              value: 64,
              message: 'Run name should contain at most 64 characters',
            },
            required: {
              value: true,
              message: 'Run name should contain at most 64 characters',
            },
          }}
          label="Run Name"
          placeholder="Name a new Run"
          name="title"
          data-testid="runNameInp"
        />
        <FormSelect
          options={driversOptions}
          showSearch={false}
          label="Driver Name"
          placeholder="Select Driver"
          name="driver_id"
          data-testid="driverSelect"
        />
        <FormInput
          label="Monitor Name"
          placeholder="Monitor Name"
          name="monitor_name"
        />
        <FormSelect
          rules={{
            required: {
              value: true,
              message: 'error',
            },
          }}
          showErrorText={false}
          options={busesOptions}
          showSearch={false}
          placeholder="Bus #"
          label="Bus #"
          name="bus_id"
          data-testid="busSelect"
        />
        <FormSelect
          options={depotsOptions}
          showSearch={false}
          showErrorText={false}
          label="Select a Bus Depot for this Run"
          placeholder="Select Bus Depot"
          name="bus_depot_id"
          data-testid="depotSelect"
          rules={{
            required: {
              value: true,
              message: 'error',
            },
          }}
        />
        <Controller
          name="is_morning"
          render={({ field }) => (
            <Radio.Group {...field}>
              <Radio data-testid="pickUpCheckbox" value={1}>
                Pick Up Run
              </Radio>
              <Radio data-testid="dropOffCheckbox" value={2}>
                Drop Off Run
              </Radio>
            </Radio.Group>
          )}
        />
        <ModalFooter
          buttonWidth={90}
          success={{
            text: 'Save',
            testId: 'saveRunBtn',
            isLoading: createResponse.isLoading,
            isSubmitForm: true,
          }}
        />
      </form>
    </FormProvider>
  )
}
