export const RiderIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M6 0a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 7.5c3.315 0 6 1.342 6 3V12H0v-1.5c0-1.658 2.685-3 6-3z"
      />
    </svg>
  )
}
