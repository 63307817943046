export const getViewPosition = () => {
  let offsetAfterTransferStop = 0
  let transfetStopCount = 1

  return (point, index) => {
    if (point.type === 4) {
      if (index !== 0) {
        offsetAfterTransferStop += 1
      }

      const result = `${index - offsetAfterTransferStop}.${transfetStopCount}.`

      transfetStopCount = transfetStopCount + 1
      return result
    }
    transfetStopCount = 1
    return `${index - offsetAfterTransferStop}.`
  }
}
