import React, { useCallback, useEffect, useState } from 'react'
import { useContextRoutesView } from './context'
import { Trans, Translation } from 'react-i18next'
import PlotRiderByName from './components/PlotRiderByName'
import Tooltip from 'antd/es/tooltip'
import targetIcon from './img/target.svg'
import RouteItem from './components/RouteItem'
import MapViewRuns from './components/Map'
import ReactDOMServer from 'react-dom/server'
import './index.less'
import _ from 'lodash'
import * as routesActions from '../../redux/routesView/actions'
import DeleteModal from './components/DeleteModal'
import PrintRouteSheets from './components/ModalPrint'
import PrintAttendanceSheets from './components/ModalPrintPdf/AttendanceSheets'
import PrintMonthlyAttendanceSheets from './components/ModalPrintPdf/MonthlyAttendance'
import classNames from 'classnames'
import styles from './RouteView.module.less'
import moment from 'moment'
import api from '../../redux/apiConfig'
import { useSelector } from 'react-redux'
import { getCompanyOptionsRequests } from '@/redux/auth/selectors'
import { getLoaderState } from '@/redux/common/selectors'
import { SelectWithCountCheckbox } from './components/SelectWithCountCheckbox/SelectWithCountCheckbox'
import { NewAntInput } from '@/entities/ui/NewInput/NewAntInput'
import { getUrlWithSearchParams } from '@/helpers/getUrlWithSearchParams'
import { BaseInput } from '@/entities/ui/Inputs/BaseInput/BaseInput'

import { NewSelect } from '@/entities/ui/NewSelect/NewSelect'
import { openModal } from '@/features/containers/ModalContainer/modalConfig'
import { useDeleteRunsMutation } from '@/store/viewRuns/viewRuns'
import { useAppSelector } from '@/redux'
import { userInfoSelector } from '@/store/common/common.selectors'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { Icon } from '@/entities/ui/Icon/Icon'

const rq = api.routesPdf
const run = api.run

function _RoutesViewMainPage(props) {
  const loaderState = useSelector(getLoaderState)
  let { routesGroups, childrenList, access, dispatch } = useContextRoutesView()

  const [actionBlock, setActionBlock] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)

  const [compareStart, setCompareStart] = useState(false)
  const [checkedList, setCheckedList] = useState([])

  const [editGroupId, setEditGroupId] = useState(undefined)
  let [editGroupInput, setEditGroupInput] = useState(undefined)

  const [printTripModal, setPrintTripModal] = useState(false)
  const [printAttendanceSheets, setPrintAttendanceSheets] = useState(false)
  const [printMonthlyAttendanceSheets, setPrintMonthlyAttendanceSheets] =
    useState(false)

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteModalText, setDeleteModalText] = useState('')

  const [plotAddress, setPlotAddress] = useState('')

  const [filterByGroup, setFilterByGroup] = useState(undefined)
  const [filterByName, setFilterByName] = useState('')
  const [filterByDriver, setFilterByDriver] = useState('')
  const [filterByBus, setFilterByBus] = useState('')
  const [routesGroupsFiltered, setRoutesGroupsFiltered] = useState([])

  const [activeRun, setActiveRun] = useState(null)
  const [fiveClosest, setFiveClosest] = useState(false)
  const companyOptions = useSelector(getCompanyOptionsRequests)

  const [isLoading, setLoading] = useState(false)

  const isLoadingStart = loaderState === 'loadingStart'

  useEffect(() => {
    setLoading(isLoadingStart)
  }, [loaderState, isLoading])

  useEffect(() => {
    if (fiveClosest) compareBtn()
  }, [fiveClosest])

  useEffect(() => {
    let count = 0
    routesGroups.forEach((gr) => (count += gr.routes.length))
    if (!count) return
    setCheckAll(checkedList.length === count)
    if (compareStart) {
      compareBtn(false)
    }
  }, [checkedList])

  useEffect(() => {
    // filter Effect
    const filtered = []

    routesGroups.forEach((gr) => {
      let routes = [...gr.routes]
      if (filterByName) {
        routes = gr.routes.filter((r) =>
          r.title.toLowerCase().includes(filterByName.toLowerCase())
        )
      }
      if (filterByBus) {
        routes = gr.routes.filter((r) =>
          r.bus_number.toLowerCase().includes(filterByBus.toLowerCase())
        )
      }
      if (filterByDriver) {
        routes = gr.routes.filter((r) =>
          r.driver_name.toLowerCase().includes(filterByDriver.toLowerCase())
        )
      }

      const isFilterByGroup = filterByGroup && gr.title !== filterByGroup

      if (!isFilterByGroup && routes.length) {
        filtered.push({ ...gr, routes })
      }
    })

    setRoutesGroupsFiltered(filtered)
  }, [
    filterByName,
    filterByDriver,
    filterByBus,
    filterByGroup,
    routesGroups,
    dispatch,
  ])

  let onChangeCheckBox = (id) => {
    let copyAr = [...checkedList]
    if (copyAr.includes(id)) {
      const position = copyAr.indexOf(id)
      copyAr.splice(position, 1)
      setCheckedList(copyAr)
    } else {
      setCheckedList([...checkedList, id])
    }
  }

  let onCheckAllChange = (e) => {
    let checkedList = []
    routesGroups.forEach((gr) => {
      gr.routes.forEach((r) => checkedList.push(r.id))
    })
    setCheckedList(e.target.checked ? checkedList : [])
  }

  let handleIcon = () => {
    setActionBlock(!actionBlock)
  }

  let editGroupName = (group) => {
    if (!access) return false
    setEditGroupId(group.id)
    setEditGroupInput(group.title)
  }

  let editGroupNameInput = (e) => {
    e.preventDefault()
    setEditGroupInput(e.target.value)
  }

  let editGroupNameSave = () => {
    const isHasSpace =
      editGroupInput.startsWith(' ') || editGroupInput.endsWith(' ')
    if (isHasSpace) editGroupInput = editGroupInput.trim()
    dispatch(routesActions.editGroup(editGroupId, editGroupInput))
    editGroupNameCancel()
  }

  let editGroupNameCancel = () => {
    setEditGroupId(undefined)
    setEditGroupInput(undefined)
  }

  let delRecordsModal = () => {
    let text = checkedList.length + ' runs'
    if (checkedList.length === 1) {
      routesGroups.forEach((gr) => {
        gr.routes.forEach((r) => {
          if (r.id === checkedList[0]) text = r.title
        })
      })
    }

    setDeleteModalOpen(true)
    setDeleteModalText(text)
  }

  let delRecordsFn = () => {
    props?.deleteRuns({
      routeIds: checkedList,
      callBack: () => {
        setCheckedList([])
        setDeleteModalOpen(false)
        setActionBlock(false)
      },
    })
    // dispatch(routesActions.removeItem(checkedList))
  }

  let printTripBtn = () => {
    if (checkedList.length === 0) return false
    setPrintTripModal(true)
  }

  let printAttendanceSheetsBtn = () => {
    if (checkedList.length === 0) return false
    setPrintAttendanceSheets(true)
  }

  let printMonthlyAttendanceSheetsBtn = () => {
    if (checkedList.length) {
      console.log(checkedList.length)
      openModal({
        modalSettings: { title: 'Monthy Attendance Report', width: 360 },
        modalProps: { runIds: checkedList },
        currentModal: 'monthlyAttendanceModal',
      })
    }
  }

  let extortToXls = () => {
    if (_.isEmpty(checkedList)) return

    let idsString = ''
    checkedList.forEach((x) => {
      if (idsString.length > 0) {
        idsString += `,${x}`
      } else {
        idsString = `${x}`
      }
    })

    let url = `/route-prints/xls?date=${moment().unix()}&id=${idsString}`

    rq.get(url).then((response) => {
      if (response.status === 200) {
        const file = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      }
    })

    setCheckedList([])
    setActionBlock(false)
  }

  let plotChangeState = (data) => {
    setPlotAddress(data.plotAddress)
  }

  let handleClose = (e) => {
    let className = e.target.className

    if (!e.target.getAttribute('class')) return

    let rFalse = [
      `arrowDown`,
      'arrowUp',
      'textAddress',
      'addressBlockVr',
      'noClose',
      'addressTitle',
    ]
    if (rFalse.find((x) => x === className)) return false

    if (!className) {
      setActionBlock(!actionBlock)
    }

    if (className.baseVal === '') {
      setActionBlock(!actionBlock)
      return
    }

    if (className && Object.keys(className).indexOf('searchInput') > -1) {
      return false
    }

    if (e.target.localName && e.target.localName !== 'svg') {
      setActionBlock(!actionBlock)
    }

    if (Object.keys(className).indexOf('actionBlock') === -1) {
      setActionBlock(false)
    }

    if (Object.keys(className).indexOf('rider') === -1) {
      //
    } else {
      if (e.target.localName && e.target.localName !== 'svg') {
        if (className !== 'actionBlock') {
          setActionBlock(false)
        } else {
          setActionBlock(!actionBlock)
        }
      }
    }

    let d = document.querySelector('.ant-menu')
    if (d) {
      //
    }
  }

  let handleClick = () => {
    setActionBlock(false)
  }

  let compareBtn = (resetList = true) => {
    resetList && setCompareStart(!compareStart)

    if (compareStart && resetList) {
      setCheckedList([])
    } else if (checkedList.length >= 2) {
      routesGroupsFiltered.forEach((gr) => {
        gr.routes.forEach((r) => {
          if (checkedList.includes(r.id)) {
            if (r.osrm) {
              return false
            } else {
              loadRunById(r.id)
            }
          }
        })
      })
    }
  }

  let plotAddressFn = useCallback(
    _.debounce((value) => {
      setPlotAddress(value)
    }, 600),
    []
  )

  let findFiveClosest = () => {
    if (plotAddress.length > 2) {
      run
        .get(
          getUrlWithSearchParams('/routes/geocode', {
            address: plotAddress,
            provider: companyOptions?.geocode_provider ?? 'google',
          })
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.lat) {
              _displayFiveClosest(response.data.lat, response.data.lon)
            }
          }
        })
    }
  }

  let loadRunById = (id) => {
    dispatch(routesActions.getRunDetails(id))
  }

  let getFilteredRoutesIds = () => {
    let ids = []
    routesGroups.forEach((gr) => {
      gr.routes.forEach((r) => ids.push(r.id))
    })
    return ids
  }
  let _displayFiveClosest = (lat, lng) => {
    let ids = getFilteredRoutesIds()

    run
      .post('/routes/five-closest', {
        lat: lat,
        lng: lng,
        ids: ids,
      })
      .then((response) => {
        if (response.status === 200) {
          let checkedList = []
          _.map(response.data, (item) => {
            checkedList.push(item.route_id)
          })

          setCheckedList(checkedList)
          setCompareStart(false)
          setFiveClosest(true)
        }
      })
  }

  let oneActive = (id) => {
    routesGroups.forEach((gr) => {
      gr.routes.forEach((x) => {
        if (x.id === id) setActiveRun(x)
      })
    })
  }
  let getPointsUpd = () => {}

  let compareOff = () => setCompareStart(false)

  let tr = (text) => {
    return ReactDOMServer.renderToStaticMarkup(
      <Translation>
        {(t, { i18n }) => {
          return t(text)
        }}
      </Translation>
    )
  }

  let groupTitleView = (group) => {
    if (group.id !== editGroupId) {
      return (
        <div key={group.id} className={styles.titleWrapper}>
          <span className={styles.title}>{group.title}</span>
          <Button
            disabled={!props?.permissions?.write_routes}
            onClick={() => editGroupName(group)}
            buttonColor="black"
            variant="text"
          >
            <Icon icon="edit" />
          </Button>
        </div>
      )
    } else {
      return (
        <div className="editBlock">
          <input
            type="text"
            value={editGroupInput}
            onChange={editGroupNameInput}
          />
          <span onClick={editGroupNameSave} className="save">
            Save
          </span>
          <span onClick={editGroupNameCancel} className="cancel">
            Cancel
          </span>
        </div>
      )
    }
  }
  const items = [
    {
      label: (
        <div>
          <Trans>Delete</Trans>
          <b style={{ marginLeft: 6 }}>{checkedList.length}</b>
        </div>
      ),
      callback: delRecordsModal,
      show: access === 1 && (checkedList?.length || 0) > 0,
      id: 1,
    },
    {
      label: <Trans>Print Route Sheet(s)</Trans>,
      callback: printTripBtn,
      show: true,
      id: 2,
    },
    {
      label: <Trans>Print Attendance Sheet(s)</Trans>,
      callback: printAttendanceSheetsBtn,
      show: true,
      id: 3,
    },
    {
      label: <Trans>Print Monthly Attendance</Trans>,
      callback: printMonthlyAttendanceSheetsBtn,
      show: companyOptions && companyOptions.is_monthly_report,
      id: 4,
    },
    {
      label: <Trans>Export Stop List(s) to xls</Trans>,
      callback: extortToXls,
      show: true,
      id: 5,
    },
  ]

  const disabled = {
    disabled: isLoading,
  }

  return (
    <div
      className="rvPage paddingPage  scrollListMenuMax transMax"
      style={{ flexDirection: 'column', height: window.innerHeight - 90 }}
      onClick={handleClose}
    >
      <div style={{ display: 'flex' }}>
        <div className={classNames(styles.filtersWrapper)}>
          <SelectWithCountCheckbox
            items={items}
            disabled={isLoading}
            count={checkedList.length}
            onCheckAll={onCheckAllChange}
          />

          <BaseInput
            value={filterByName}
            onChange={(e) => setFilterByName(e.target.value)}
            placeholder={tr('Run Name')}
            disabled={isLoading}
            className={classNames(disabled)}
          />

          <BaseInput
            onFocus={handleClick}
            value={filterByDriver}
            onChange={(e) => setFilterByDriver(e.target.value)}
            placeholder={tr('Driver Name')}
            className={classNames(disabled)}
            disabled={isLoading}
          />
          <BaseInput
            size="middle"
            onFocus={handleClick}
            value={filterByBus}
            onChange={(e) => setFilterByBus(e.target.value)}
            placeholder={tr('Bus #')}
            disabled={isLoading}
            className={classNames(disabled)}
          />
          <NewSelect
            allowClear
            showSearch={false}
            size="middle"
            placeholder={tr('Group')}
            name="Group"
            value={filterByGroup}
            onChange={(val) => !isLoading && setFilterByGroup(val)}
            disabled={isLoading}
            className={classNames(disabled, styles.select)}
            options={
              routesGroups
                ?.filter((item) => !!item.title)
                ?.map((item) => ({ label: item.title, value: item.title })) ??
              []
            }
          />

          <div
            onClick={!isLoading && compareBtn}
            className={classNames(styles.compareButton, {
              [styles.compareActive]: compareStart || checkedList.length > 1,
              disabled: isLoading,
            })}
          >
            <Trans>Compare Runs</Trans> {checkedList.length}
          </div>

          <PlotRiderByName
            childrenList={childrenList}
            save={!isLoading && plotChangeState}
            disabled={isLoading}
            className={classNames(disabled)}
          />

          <NewAntInput
            onFocus={handleClick}
            onChange={(e) => plotAddressFn(e.target.value)}
            placeholder={'Plot Address on Map'}
            disabled={isLoading}
            className={classNames(disabled)}
          />
          <Tooltip
            placement="bottomRight"
            title={tr('Find closest Runs')}
            className={classNames(disabled)}
          >
            <div
              className={styles.closesRuns}
              onClick={!isLoading && findFiveClosest}
            >
              <img src={targetIcon} />
            </div>
          </Tooltip>
        </div>
      </div>

      <div style={{ display: 'flex', height: '100%' }}>
        <div className="viewListCol">
          <div className="groupList">
            {routesGroupsFiltered.map((group) => {
              return (
                <div key={group.id}>
                  <div className={'bl_1'}>{groupTitleView(group)}</div>
                  {group.routes.map((route) => (
                    <RouteItem
                      groupTitle={group.title}
                      key={route.id}
                      route={route}
                      routes={routesGroups}
                      checkedList={checkedList}
                      childrenList={childrenList}
                      onChange={onChangeCheckBox}
                      getPoints={getPointsUpd}
                      oneActive={oneActive}
                      compareOff={compareOff}
                      access={access}
                    />
                  ))}
                </div>
              )
            })}
          </div>
        </div>

        <div className="mapCol">
          <div className="map">
            <MapViewRuns
              plotAddress={plotAddress}
              clearNow={0}
              plotRiderName={null}
              activeRoute={activeRun}
              checkedList={checkedList}
              compareStart={compareStart}
              access={access}
            />
          </div>
        </div>
      </div>

      {printTripModal && (
        <PrintRouteSheets
          checkedList={checkedList}
          closeFn={() => setPrintTripModal(false)}
        />
      )}

      {printAttendanceSheets && (
        <PrintAttendanceSheets
          checkedList={checkedList}
          closeFn={() => setPrintAttendanceSheets(false)}
        />
      )}

      {printMonthlyAttendanceSheets && (
        <PrintMonthlyAttendanceSheets
          checkedList={checkedList}
          closeFn={() => setPrintMonthlyAttendanceSheets(false)}
        />
      )}

      {deleteModalOpen && (
        <DeleteModal
          title={'Delete Run'}
          deleteObject={deleteModalText}
          closeFn={() => setDeleteModalOpen(false)}
          deleteFn={delRecordsFn}
        />
      )}
    </div>
  )
}

const RoutesViewMainPage = (props) => {
  const [deleteRuns] = useDeleteRunsMutation({
    fixedCacheKey: 'batch-delete-runs',
  })

  const userInfo = useAppSelector(userInfoSelector)

  return (
    <_RoutesViewMainPage
      permissions={userInfo?.permissions}
      deleteRuns={deleteRuns}
      {...props}
    />
  )
}

export default RoutesViewMainPage
