import React, { Component } from 'react'
import Riders from '../Riders/index'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

import { Trans } from 'react-i18next'
import { sortRidersList } from '@/components/RepeatFunctions/filter'
import { connect } from 'react-redux'
import _ from 'lodash'

class DropOff extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
      pickUp: true,
      dropOff: true,
    }
  }

  handleClick = (e, name) => {
    this.setState({
      show: !this.state.show,
    })
    if (name === 'default') {
      this.setState({
        edit: !this.state.edit,
      })
    }
  }

  handleGetStatus() {
    this.props.routePointChildren.forEach((address, i) => {
      let type = address.childaddress?.type
      if (type === 1) {
        this.setState({
          pickUp: false,
          dropOff: true,
        })
      } else {
        this.setState({
          pickUp: true,
          dropOff: false,
        })
      }
    })
  }

  findTextPU = () => {
    this.props.routePointChildren.find((item) => {
      if (item.childaddress?.type === 1) {
        return true
      }
      return false
    })
  }
  componentDidMount() {
    if (this.props?.companyOptions?.expand_riders_in_view_runs) {
      this.setState({
        show: true,
      })
    }
  }

  render() {
    let { id, routePointChildren } = this.state

    if (routePointChildren === undefined) {
      return false
    }

    routePointChildren = sortRidersList(routePointChildren)

    let pickUpChild = routePointChildren.filter(
      (x) => x.childaddress?.type === 1
    )
    let dropOffChild = routePointChildren.filter(
      (x) => x.childaddress?.type === 2
    )

    this.handleGetStatus

    return (
      <div className="blockList">
        {this.props.routePointChildren.length ? (
          <div className="SchoolAddress">
            <span className="riders">
              <Trans>Riders</Trans> {this.props.routePointChildren.length}
            </span>
            {this.state.show ? (
              <UpOutlined onClick={(e) => this.handleClick(e, 'Ediable')} />
            ) : (
              <DownOutlined onClick={(e) => this.handleClick(e, 'Ediable')} />
            )}
          </div>
        ) : null}
        {this.state.show && (
          <div className="PuckUpMain marginTop">
            <div className="pickUpBlock">
              {pickUpChild.length > 0 && (
                <div className="pickUp">
                  <Trans>Pick Up Riders</Trans>{' '}
                </div>
              )}

              {this.props.routePointChildren.map((address, i) => {
                let type = address.childaddress?.type
                if (address.childaddress?.type !== 1) {
                  return false
                }

                let child = this.props.object.find(
                  (child) => child.id === address.childaddress.child_id
                )
                return (
                  <Riders
                    type={type}
                    id={id}
                    phone={this.props.phone}
                    child={_.cloneDeep(child)}
                    address={address}
                    delChildFromStop={this.props.delChildFromStop}
                  />
                )
              })}
            </div>

            <div className="dropOffBlock">
              {dropOffChild.length > 0 && (
                <div className="dropOff">
                  <Trans>Drop Off Riders</Trans>{' '}
                </div>
              )}

              {this.props.routePointChildren.map((address, i) => {
                let type = address.childaddress?.type
                if (address.childaddress.type !== 2) {
                  return false
                }

                let child = this.props.object.find(
                  (child) => child.id === address.childaddress.child_id
                )
                return (
                  <Riders
                    key={i}
                    type={type}
                    id={id}
                    phone={this.props.phone}
                    child={_.cloneDeep(child)}
                    address={address}
                    delChildFromStop={this.props.delChildFromStop}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    companyOptions: state.auth.companyOptions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}
export default connect(mapStateToProps, mapDispatchToProps)(DropOff)
