import React, { Component } from 'react'
import './index.less'

class RadialProgress extends Component {
  render() {
    return (
      <div
        className="radial-progress"
        data-progress={this.props.percentage ? this.props.percentage : 0}
      >
        <div className="circle">
          <div className="mask full">
            <div className="fill" style={{ background: this.props.color }} />
          </div>
          <div className="mask half">
            <div className="fill" style={{ background: this.props.color }} />
            <div
              className="fill fix"
              style={{ background: this.props.color }}
            />
          </div>
        </div>
        <div className="inset">
          <div className="percentage">
            <div className="text">
              <span style={{ color: this.props.color }}>
                <svg
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                >
                  <path d="M7.5 0a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5zm0 9.375c4.144 0 7.5 1.678 7.5 3.75V15H0v-1.875c0-2.072 3.356-3.75 7.5-3.75z" />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RadialProgress
