import { Popover, Spin } from 'antd'
import classnames from 'classnames'
import { BaseInput } from '@/entities/ui/Inputs/BaseInput/BaseInput'
import { TemplateSettings } from '@/entities/modals/ParentEmailModal/components/TemplateSettings/TemplateSettings'
import { Editor } from '@tinymce/tinymce-react'
import { getInit } from '@/entities/modals/ParentEmailModal/ParentEmailModal.helpers'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { IEmailTemplate } from '@/store/routeTool/RouteToolTemplateApi/RouteToolTemplateApi.types'
import { useEffect, useMemo, useState } from 'react'
import { useCreateEmailTemplateMutation } from '@/store/routeTool/RouteToolTemplateApi/RouteToolTemplateApi'
import {
  useNewVariantParentEmailModalConfig,
  useParentState,
} from '@/entities/modals/ParentEmailModal/components/variants/NewVariant/useNewVariantParentEmailModalConfig'
import styles from './NewVariant.module.less'
import { useAppSelector } from '@/redux'

export const NewVariant = ({ onCloseModal, arrayId }) => {
  const {
    isLoading,
    onSend,
    onSave,
    onInit,
    handleSetTemplate,
    currentTemplate,
    initLoading,
    isSave,
    isSend,
    getEditorContent,
    isShowSaveButton,
  } = useNewVariantParentEmailModalConfig(arrayId)

  const companyInfo = useAppSelector((state) => state.auth)

  const childrenList = useAppSelector((state) =>
    state.children.childrenList?.filter(({ id }) => arrayId.includes(id))
  )

  const totalEmailsNumbers = useMemo(
    () =>
      childrenList?.reduce(
        (totalChild, child) =>
          totalChild +
          Object.keys(child).reduce((total, acc) => {
            if (
              (companyInfo?.company?.enabledEmails ?? []).includes(acc) &&
              child[acc]
            ) {
              return total + 1
            }
            return total
          }, 0),
        0
      ),
    [childrenList, companyInfo?.company?.enabledEmails]
  )

  const [customSubject, setCustomSubject] = useState('')
  const [isLoadingTiny, setIsLoadingTiny] = useState(true)
  const placeholder = companyInfo.company.camp_mode
    ? companyInfo.company.display_name + ' weekly camp email'
    : "`Rider's name` school bus schedule"
  const [isOpenPopup, setIsOpenPopup] = useState(false)

  return (
    <>
      {(isLoadingTiny || initLoading) && <Spin className={styles.spin} />}

      <div
        className={classnames(styles.editorWrapper, {
          [styles.loading]: isLoadingTiny || initLoading,
        })}
      >
        <BaseInput
          style={{ marginBottom: 8 }}
          label={'Input custom email subject or leave default one.'}
          value={customSubject}
          onChange={(e) => setCustomSubject(e.target.value)}
          placeholder={placeholder}
        />
        <TemplateSettings
          isOpenPopup={isOpenPopup}
          setIsOpenPopup={setIsOpenPopup}
          selectedTemplate={currentTemplate}
          handleSetTemplate={handleSetTemplate}
        />
        <Editor
          onLoadContent={(props) => {
            if (props.load) {
              setIsLoadingTiny(false)
            }
          }}
          onClick={() => setIsOpenPopup(false)}
          apiKey="8vc4whabhap8hkv3fkr2nnyk258fpx0s4jxnulstwe49emgf"
          onInit={(evt, editor) => {
            onInit(editor)
          }}
          initialValue={currentTemplate?.text ?? ''}
          init={getInit()}
        />
      </div>

      <p className={styles.title}>
        You are about to send an email to all selected rider’s parents (
        <b>{totalEmailsNumbers}</b> emails).
      </p>
      <div className={styles.footer}>
        <Button buttonColor="gray" onClick={onCloseModal} disabled={isLoading}>
          Cancel
        </Button>
        {isShowSaveButton && (
          <Button disabled={isSend} isLoading={isSave} onClick={onSave}>
            Save
          </Button>
        )}
        <SaveAs
          currentTemplate={currentTemplate}
          isSend={isSend}
          getEditorContent={getEditorContent}
        />
        <Button
          onClick={() => onSend(customSubject)}
          disabled={isSave || !totalEmailsNumbers}
          isLoading={isSend}
        >
          Send
        </Button>
      </div>
    </>
  )
}

const SaveAs = ({
  getEditorContent,
  currentTemplate,
  isSend,
}: {
  getEditorContent: () => string
  currentTemplate: IEmailTemplate
  isSend: boolean
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const [value, setValue] = useState('')
  useEffect(() => {
    currentTemplate?.title && setValue(currentTemplate.title)
  }, [currentTemplate?.title])

  const [onCreate, createResponse] = useCreateEmailTemplateMutation()

  const handleSaveAs = async () => {
    const res = await onCreate({
      title: value,
      text: getEditorContent?.() ?? '',
    }).unwrap()

    if (res?.data?.id) {
      useParentState.getState().setStore({
        currentTemplate: {
          text: res?.data?.text ?? '',
          id: res?.data?.id ?? 0,
          title: res?.data?.title,
        },
      })

      setIsOpen(false)
    }
  }

  return (
    <Popover
      open={isOpen}
      onOpenChange={setIsOpen}
      trigger="click"
      placement="top"
      content={
        <div>
          <BaseInput
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Enter template name"
            label="Template name"
          />
          <div className={styles.saveAsFooter}>
            <Button
              isLoading={createResponse?.isLoading}
              disabled={!value?.trim() || isSend}
              onClick={handleSaveAs}
            >
              Save
            </Button>

            <Button buttonColor="gray" variant="text">
              Cancel
            </Button>
          </div>
        </div>
      }
    >
      <Button disabled={isSend} buttonColor="green">
        Save as
      </Button>
    </Popover>
  )
}
