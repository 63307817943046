import React, { Component } from "react";
import { connect } from "react-redux";

import Row from "antd/es/row";
import Select from "../../../../../../components/customSelect/index";
import SelectInput from "antd/es/select";
import SelectAnt from "antd/es/select";
import { Trans } from "react-i18next";
import Input from "antd/es/input";
import Col from "antd/es/col";
import _ from "lodash";
import * as routesActions from "../../../../../../redux/routesView/actions";
import "./index.less";
import { SuffixIcon } from "../../../../../../img/iconsComponents/SuffixIcon";

let Option = SelectInput.Option;

let tmp = [];

class RouteItemEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props,
      inputValue: props.route.groupName,
      bus_id: props.route.bus_id,
      driver_id: props.route.driver_id,
      speed_multiplier: props.route.speed_multiplier,
      open: false,
      save: false,
      text: "",
      dropDownOpen: false,
      titleLengthError: false,
      titleEmpty: false,
      groupNameEmpty: false
    };

    this.selectRef = React.createRef();
    this.editBlock = React.createRef();
  }

  componentDidMount() {
    this.editBlock.current.addEventListener("mousedown", e => {
      let target = e.target;

      if (target.className !== "ant-input1 wrapInput") {
        this.setState({
          dropDownOpen: false
        });
      }
    });
  }

  handleChange = (type, e) => {
    this.setState({
      [type]: e.target.value
    });
  };

  changeActiveDriverBus = (type, value) => {
    this.setState({
      [type]: value
    });
  };

  save = () => {
    let {
      text,
      title,
      routeItemId,
      monitor_name,
      group_id,
      bus_id,
      driver_id,
      speed_multiplier,
      inputValue,
      route,
      titleLengthError,
      titleEmpty,
      groupNameEmpty
    } = this.state;
    const isHasSpace = inputValue.startsWith(" ") || inputValue.endsWith(" ");
    if (isHasSpace) inputValue = inputValue.trim();

    let editObj = {
      groupName: inputValue,
      company_id: route.company_id,
      season_id: route.season_id,
      title: title,
      monitor_name: monitor_name,
      bus_id: bus_id,
      driver_id: driver_id,
      speed_multiplier: speed_multiplier,
      id: routeItemId
    };
    if (titleLengthError || titleEmpty || groupNameEmpty) return;
    this.props.saveEdit(editObj);
  };

  saveEditGroup = () => {
    let { inputValue, route, groupNameEmpty } = this.state;
    const isHasSpace = inputValue.startsWith(" ") || inputValue.endsWith(" ");
    if (isHasSpace) inputValue = inputValue.trim();

    let data = {
      id: route.id,
      groupName: inputValue,
      company_id: route.company_id,
      season_id: route.season_id
    };
    if (groupNameEmpty || !inputValue) return;
    this.props.dispatch(routesActions.editItem(data));

    setTimeout(() => {
      this.props.dispatch(routesActions.getRoutes(route.season_id));
    }, 1500);

    // let findIndex = this.props.routes.findIndex(x => {
    //
    //   if(x.title === inputValue ||
    //     x.title === '' && (inputValue === "no group" || inputValue === ""))  return true
    // })
    //
    // if(findIndex > -1) {
    //   console.log(`edit`)
    //   this.props.dispatch(routesActions.editItem(data))
    //
    // }else{
    //   console.log(`new`)
    //   this.props.dispatch(routesActions.addGroup(data , route))
    // }
  };

  handleChangeTitle = e => {
    const value = e.target.value;
    this.setState({
      titleLengthError: value.length > 64,
      titleEmpty: value === "",
      title: value
    });
  };

  handleClick = val => {
    this.setState({
      inputValue: val,
      inputValueWrite: val,
      text: val,
      title: val
    });
  };

  changeInputGroupName = e => {
    const { value } = e.target;

    this.setState({
      inputValue: value,
      groupNameEmpty: value === "" || /^\s+$/.test(value)
    });
  };

  clickInput = e => {
    this.setState({
      dropDownOpen: !this.state.dropDownOpen
    });
  };

  selectGroupOpt = value => {
    this.setState({
      inputValue: value
      // dropDownOpen : !this.state.dropDownOpen
    });
  };

  render() {
    let {
      route,
      routes,
      buses,
      drivers,
      inputValue,
      dropDownOpen,
      titleLengthError,
      titleEmpty,
      groupNameEmpty
    } = this.state;

    tmp = _.cloneDeep(routes);

    return (
      <div ref={this.editBlock} className="info edit">
        <Row>
          <Col span={24}>
            <input
              className={
                titleLengthError || titleEmpty
                  ? "titleInput errorBorder"
                  : "titleInput"
              }
              type="text"
              defaultValue={route.title}
              onChange={this.handleChangeTitle}
              placeholder={"Run name"}
            />
          </Col>
          <span className={"titleErrorSpan"}>
            {titleLengthError
              ? "Run name should contain at most 64 characters"
              : titleEmpty
              ? "Run name can not be empty"
              : ""}
          </span>
        </Row>

        <Row>
          <Col span={24}>
            <span>
              <Trans>
                {route.is_morning === 1 ? "Pick Up Run" : "Drop Off Run"}
              </Trans>{" "}
            </span>
            <span className="body">{route.riderscount}</span>
            <span className="point">{route.stopscount}</span>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col span={12}>
            <span className="inf">
              <Trans>Driver</Trans>{" "}
            </span>
            <Select
              placeholder={"No select"}
              className={`selectEdit`}
              getPopupContainer={trigger => trigger.parentNode}
              onChange={e => this.changeActiveDriverBus("driver_id", e)}
              value={this.state.driver_id ? this.state.driver_id : null}
            >
              <option className={"hide"} key={`d0`} value={null}>
                {"No select"}
              </option>
              {drivers.map(driver => {
                return (
                  <option key={`d` + driver.id} value={driver.id}>
                    {driver.name}
                  </option>
                );
              })}
            </Select>
          </Col>

          <Col span={12}>
            <span className="inf">
              <Trans>Bus #</Trans>
            </span>
            <Select
              style={{ width: "97%" }}
              placeholder={"No select"}
              getPopupContainer={trigger => trigger.parentNode}
              onChange={e => this.changeActiveDriverBus("bus_id", e)}
              value={this.state.bus_id ? this.state.bus_id : null}
            >
              <Option className={"hide"} key={`b0`} value={null}>
                {"No select"}
              </Option>
              {buses.map(bus => {
                if (!bus.bus_number) return;
                return (
                  <Option key={`b` + bus.id} value={bus.id}>
                    {bus.bus_number}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <span className="inf">
              <Trans>Monitor</Trans>
            </span>
            <input
              value={
                this.state.monitor_name && this.state.monitor_name.length >= 0
                  ? this.state.monitor_name
                  : route.monitor_name
              }
              style={{ width: "97%" }}
              onChange={e => this.handleChange("monitor_name", e)}
              type="text"
            />
          </Col>
          <Col span={12} />
        </Row>
        {this.state.adminFn && (
          <Row>
            <Col span={12}>
              <span className="inf">
                <Trans>Speed Multiplier</Trans>
              </span>
              <input
                value={
                  this.state.speed_multiplier
                    ? this.state.speed_multiplier
                    : route.speed_multiplier
                }
                onChange={e => this.handleChange("speed_multiplier", e)}
                type="text"
              />
            </Col>
            <Col span={12} />
          </Row>
        )}
        <hr />

        <Row>
          <Col span={24}>
            <span className="inf">
              <Trans>Group</Trans>
            </span>

            <Row>
              <Col span={12}>
                <div className={"inputsSelectWrap"}>
                  <SelectAnt
                    open={dropDownOpen}
                    className={"wrapSelect"}
                    ref={this.selectRef}
                    onChange={this.selectGroupOpt}
                  >
                    {routes.map(r => {
                      if (r.title === "") return;
                      return (
                        <Option key={r.id} value={r.title}>
                          {r.title}{" "}
                        </Option>
                      );
                    })}
                  </SelectAnt>

                  <div className={"suffixWrap"}>
                    <Input
                      className={
                        groupNameEmpty ? "wrapInput errorBorder" : "wrapInput"
                      }
                      value={inputValue}
                      placeholder={"Select or create group"}
                      onChange={this.changeInputGroupName}
                      onClick={this.clickInput}
                    />
                    <SuffixIcon
                      className={`suffixIconInner ${
                        !dropDownOpen ? "DownRotateSuffix" : "TopRotateSuffix"
                      }`}
                      onClick={() =>
                        this.setState({ dropDownOpen: !dropDownOpen })
                      }
                    />
                    {groupNameEmpty && (
                      <span className={"titleErrorSpan"}>
                        Group name can not be empty
                      </span>
                    )}
                  </div>
                </div>
              </Col>

              <Col span={12}>
                <span
                  className="save noclick saveEditGr"
                  onClick={this.saveEditGroup}
                >
                  <Trans>Save</Trans>
                </span>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="buttonGroup">
          <span onClick={this.save} className="save noclick">
            <Trans>Save</Trans>
          </span>
          <span onClick={() => this.props.close()} className="cancel noclick">
            <Trans>Cancel</Trans>
          </span>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteItemEdit);
