import { NewModal } from '@/entities/ui/NewModal/NewModal'
import { ModalsEnum, useModalSelector } from '@/store/modal/ModalStore'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from '../../../../../img/logo.svg'
import styles from './PrintModal.module.less'
import { FormInput } from '@/entities/ui/NewInput/FormInput'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Input, Spin, Table } from 'antd'
import React from 'react'
import { Button } from '@/entities/ui/NewButton/NewButton'
import {
  useLazyGetPrintPdfQuery,
  useLazySavePrintDriverQuery,
} from '@/store/fieldTrip/FieldTripApi'
import { usePrintModalConfig } from './usePrintModalConfig'
import { columnsDest, columnsSource } from './PrintModal.constants'
import { PrintDriverForm } from './PrintModal.types'

export const PrintModal = ({ run }: { run: any }) => {
  const { isOpen, onCloseModal } = useModalSelector(ModalsEnum.PRINT_MODAL)
  return isOpen && run?.id ? (
    <_PrintModal isOpen={isOpen} run={run} onCloseModal={onCloseModal} />
  ) : null
}

const _PrintModal = ({
  isOpen,
  onCloseModal,
  run,
}: {
  run: any
  isOpen: boolean
  onCloseModal: () => void
}) => {
  const tripId = run.id
  const config = usePrintModalConfig(run)
  const { isLoading, resetReport, defaultValuesForm } = config

  return (
    <NewModal
      className={styles.root}
      title="Print Driver Sheet"
      open={isOpen}
      width={800}
      onClose={onCloseModal}
    >
      <div className={styles.topLine}>
        <div>
          <img src={logo} alt="logo" />
        </div>
        <p className={styles.title}>Driver Sheet</p>
      </div>
      {isLoading ? (
        <div className={styles.spinWrapper}>
          <Spin />
        </div>
      ) : (
        <PrintModalForm
          defaultValuesForm={defaultValuesForm}
          resetReport={resetReport}
          tripId={tripId}
          onCloseModal={onCloseModal}
          {...config}
        />
      )}
    </NewModal>
  )
}

const PrintModalForm = ({
  onCloseModal,
  tripId,
  resetReport,
  defaultValuesForm,
  sources,
  destinations,
}: {
  onCloseModal: () => void
  tripId: number
  resetReport: (tripId: number) => void
  defaultValuesForm: PrintDriverForm
} & ReturnType<typeof usePrintModalConfig>) => {
  const methods = useForm<PrintDriverForm>({ defaultValues: defaultValuesForm })

  const [onSaveRequest, savePrintResponse] = useLazySavePrintDriverQuery()
  const [onPrintPdfRequest, printPdfResponse] = useLazyGetPrintPdfQuery()

  const onSave = (data: PrintDriverForm) => {
    onSaveRequest({ data, tripId })
  }

  const onPrintPdf = () => {
    onPrintPdfRequest(tripId)
  }

  const isDisable = savePrintResponse.isFetching || printPdfResponse.isFetching

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSave)}>
        <div className={styles.topLineForm}>
          <label htmlFor="trip-name">Trip Name :</label>
          <FormInput name="tripName" id="trip-name" />
          <label htmlFor="trip-description">Trip Description :</label>
          <FormInput name="tripDescription" id="trip-description" />
        </div>
        <div className={styles.secondLineForm}>
          <div className={styles.secondLineItem}>
            <label htmlFor="driver">Driver :</label>
            <FormInput name="driverName" id="driver" />
          </div>
          <div className={styles.secondLineItem}>
            <label htmlFor="bus">Bus :</label>
            <FormInput name="busNumber" id="bus" />
          </div>
        </div>
        <Table
          columns={columnsSource}
          pagination={false}
          dataSource={sources}
        />
        <Table
          columns={columnsDest}
          pagination={false}
          dataSource={destinations}
        />
        <div className={styles.thirdLineForm}>
          <div className={styles.thirdLineItem}>
            <label htmlFor="starting_mileage">Starting Mileage :</label>
            <FormInput name="startingMileage" id="starting_mileage" />
          </div>
          <div className={styles.thirdLineItem}>
            <label htmlFor="ending_mileage">Ending Mileage :</label>
            <FormInput name="endingMileage" id="ending_mileage" />
          </div>
          <div className={styles.thirdLineItem}>
            <label htmlFor="first_total">Total :</label>
            <FormInput name="totalMileage" id="first_total" />
          </div>
          <div className={styles.thirdLineItem}>
            <label htmlFor="start_time">Start Time :</label>
            <FormInput name="startTime" id="start_time" />
          </div>
          <div className={styles.thirdLineItem}>
            <label htmlFor="end_time">End Time :</label>
            <FormInput name="endTime" id="end_time" />
          </div>
          <div className={styles.thirdLineItem}>
            <label htmlFor="second_total">Total :</label>
            <FormInput name="totalTime" id="second_total" />
          </div>
          <div className={styles.thirdLineItem}>
            <label htmlFor="charter_start_time">Charter Start Time :</label>
            <FormInput name="charterStartTime" id="charter_start_time" />
          </div>
          <div className={styles.thirdLineItem}>
            <label htmlFor="contract_time">Contract Time :</label>
            <FormInput name="contractTime" id="contract_time" />
          </div>
        </div>

        <p className={styles.alert}>
          While away from home base, you are not only responsible for the
          students' safety, but also the bus and its equipment. The bus must be
          under your supervision at ALL times.
        </p>
        <div className={styles.tranCoordinator}>
          <label htmlFor="trans_coordinator">
            Transportation Coordinator :
          </label>
          <FormInput name="transportationCoordinator" id="trans_coordinator" />
        </div>
        <div className={styles.textAreaWrapper}>
          <label htmlFor="trans_coordinator">Driver Comments :</label>
          <Controller
            name="driverComments"
            render={({ field }) => (
              <Input.TextArea {...field} rows={4} id="trans_coordinator" />
            )}
          />
        </div>
        <div className={styles.footer}>
          <Button
            onClick={() => resetReport(tripId)}
            disabled={isDisable}
            type="button"
            buttonColor="ocean"
          >
            Reset
          </Button>
          <Button
            disabled={isDisable}
            type="button"
            onClick={onCloseModal}
            buttonColor="gray"
          >
            Cancel
          </Button>
          <Button disabled={isDisable} type="submit">
            Save
          </Button>
          <Button
            onClick={onPrintPdf}
            disabled={isDisable}
            type="button"
            buttonColor="blue"
          >
            Print
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}
