import React, { Component } from 'react'
import { connect } from 'react-redux'
import BaseModal from '../../../../components/BaseModal'
import './index.less'
import { browserHistory } from 'react-router'

class Modal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showList: [],
    }
  }

  AddStops = (e) => {
    browserHistory.push({
      pathname: '/routes',
      state: { id: e.target.id },
    })
  }

  CloseModal = () => {
    this.props.closeFn('tyrn_error', '')
  }

  render() {
    let closeFn = this.props.closeFn

    return (
      <div className="modal">
        <BaseModal
          title={'Turn by turn directions for trip Tzahov'}
          closeFn={() => closeFn('tyrn_error', '')}
          class={'ModalError'}
          close={'closeBtn'}
        >
          <div className="errorModal">
            <span>The Trip is empty. Please add Stops to the Trip!</span>
            <div className="buttonGroup">
              <button className="cancel" onClick={this.CloseModal}>
                Cancel
              </button>
              <button
                className="addStops"
                id={this.props.id}
                onClick={this.AddStops}
              >
                Add Stops
              </button>
            </div>
          </div>
        </BaseModal>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
