import * as types from "./actionTypes";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case types.DIST_GET_ITEMS: {
      let newArr = [...state];
      newArr = action.payload;
      return newArr;
    }
    case types.DIST_ADD_ITEM: {
      let newArr = [...state];
      newArr.push(action.payload);
      newArr = newArr.sort(dist => dist.id);
      return newArr;
    }
    case types.DIST_UPDATE: {
      let newArr = [...state];
      let index = newArr.findIndex(dist => dist.id === action.payload.id);
      newArr.splice(index, 1, action.payload);
      return newArr;
    }
    case types.DIST_DELETE: {
      let newArr = [...state];
      newArr = newArr.filter(dist => dist.id !== action.payload);
      return newArr;
    }

    default:
      return state;
  }
};
