import { forwardRef, Ref } from 'react'
import { Input as Inpuut, InputRef } from 'antd'
import classnames from 'classnames'
import styles from './BaseInput.module.less'
import { BaseInputProps } from '../Inputs.types'
import { NewPasswordInput } from '@/entities/ui/NewInput/NewAntInput'

export const BaseInput = forwardRef(
  (
    {
      error,
      value,
      formattedValue,
      showErrorText = true,
      ...props
    }: BaseInputProps,
    ref: Ref<InputRef>
  ) => {
    const Component = props.type === 'password' ? NewPasswordInput : Inpuut
    return (
      <article className={classnames(styles.root, props.wrapperClassName)}>
        {props.label && (
          <label
            data-error={!!error}
            className={classnames(styles.label, props.labelClassName, {
              [styles.errorMessage]: !!error || typeof error === 'string',
            })}
          >
            {props.label}
          </label>
        )}
        <Component
          status={error || typeof error === 'string' ? 'error' : undefined}
          {...props}
          value={formattedValue ? formattedValue(value as string) : value}
          className={classnames(props.className)}
          ref={ref}
        />
        {error && showErrorText && (
          <p className={classnames(styles.errorMessage, styles.error)}>
            {error}
          </p>
        )}
      </article>
    )
  }
)
