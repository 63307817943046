import React, { useState } from 'react'
import '../customSelect/index.less'
import { Select } from 'antd'
import { SuffixIcon } from '../../img/iconsComponents/SuffixIcon'
import { CloseOutlined } from '@ant-design/icons'

function CustomInput({
  open,
  children,
  onChange,
  value,
  name,
  handleSelected,
  allowClear,
  maxTagTextLength,
  ...props
}) {
  const [openState, setOpenState] = useState(open)

  const handleOnchange = (val, opt, arr) => {
    setOpenState(false)
    onChange(val, opt, arr)
  }

  const clearFilters = () => {
    onChange(name, name)
  }

  const handleAllow = (allowClear, val) => {
    if (allowClear) {
      if (typeof val === 'number' || typeof val === 'string') {
        return true
      }
    }
    return false
  }

  return (
    <Select
      allowClear={handleAllow(allowClear, value)}
      onChange={(val, opt, arr) => handleOnchange(val, opt, arr)}
      notFoundContent={null}
      onSelect={handleSelected}
      getPopupContainer={(trigger) => trigger.parentNode}
      onDropdownVisibleChange={(visible) => setOpenState(visible)}
      dropdownMatchSelectWidth={false}
      maxTagTextLength={maxTagTextLength}
      clearIcon={
        <CloseOutlined onClick={clearFilters} className="clearIcons" />
      }
      suffixIcon={
        <div style={{ height: '100%', position: 'relative' }}>
          <SuffixIcon
            style={{
              transform: openState ? 'rotate(180deg)' : 'rotate(0deg)',
              top: '2px',
              position: 'absolute',
              right: '-6px',
            }}
          />
        </div>
      }
      value={value}
      dropdownRender={(menu) => {
        if (
          menu.props.options &&
          menu.props.options.length &&
          menu.props.options[0].value === 0
        ) {
          return <option className="hide" />
        }
        return <span>{menu}</span>
      }}
      {...props}
    >
      {children}
    </Select>
  )
}

export default CustomInput
