import { create } from 'zustand'

interface IStore {
  isRule: boolean
  setStore: (params: Partial<IStore>) => void
}
export const useRulerStore = create<IStore>((set) => ({
  isRule: false,
  setStore: (params) => set({ ...params }),
}))
