import * as types from './actionTypes'
import _ from 'lodash'

const initialState = {
  buses: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.BUS_GET_ITEMS: {
      let newArr = { ...state }
      newArr.buses = action.payload.sort((bus) => bus.bus_number)
      return _.cloneDeep(newArr)
    }
    case types.BUS_ADD: {
      let newArr = { ...state }
      newArr.buses = [...newArr.buses]
      newArr.buses.push(action.payload)
      newArr.buses = newArr.buses.sort((bus) => bus.bus_number)
      return newArr
    }
    case types.BUS_UPDATE: {
      let newArr = { ...state }
      newArr.buses = [...newArr.buses]
      let index = newArr.buses.findIndex((bus) => bus.id === action.payload.id)
      newArr.buses.splice(index, 1, action.payload)
      return newArr
    }
    case types.BUS_DELETE: {
      let newArr = { ...state }
      newArr.buses = [...newArr.buses]
      newArr.buses = newArr.buses.filter((bus) => bus.id !== action.payload)
      return newArr
    }

    default:
      return state
  }
}
