import { IRequestOpenedItem } from '@/store/routeTool/routeTool.types'
import { useLazyPostAutoAcceptQuery } from '@/store/routeTool/routeTool'

const titles = {
  pickupAddress: 'Pick Up Address',
  dropoffAddress: 'Drop Off Address',
}

export const useAutoAccept = (item: IRequestOpenedItem) => {
  const isPickUpUse = item.isUseAm
  const isDropOffUse = item.isUsePm

  const isShowSchoolAm =
    !item.hasSchoolAm &&
    item.changes.some(
      (change) => change.type === 'create' && !!change.newFields?.pickupAddress
    )

  const isShowSchoolPm =
    !item.hasSchoolPm &&
    item.changes.some(
      (change) => change.type === 'create' && !!change.newFields?.dropoffAddress
    )

  const changes: {
    title: string
    type: 'update' | 'delete'
    value: string
    entityId: number
  }[] = []
  if (isPickUpUse || isDropOffUse) {
    item.changes.forEach((change) => {
      if (
        isPickUpUse &&
        change.oldFields?.pickupAddress &&
        change.type !== 'delete'
      ) {
        changes.push({
          title: titles.pickupAddress,
          type: 'update',
          entityId: change.entityId,
          value: 'force',
        })
      }
      if (
        isDropOffUse &&
        change.oldFields?.dropoffAddress &&
        change.type !== 'delete'
      ) {
        changes.push({
          title: titles.dropoffAddress,
          type: 'update',
          entityId: change.entityId,
          value: 'force',
        })
      }

      if (
        isPickUpUse &&
        change.oldFields?.pickupAddress &&
        change.type === 'delete'
      ) {
        changes.push({
          title: titles.pickupAddress,
          type: 'delete',
          entityId: change.entityId,
          value: 'force',
        })
      }

      if (
        isDropOffUse &&
        change.oldFields?.dropoffAddress &&
        change.type === 'delete'
      ) {
        changes.push({
          title: titles.dropoffAddress,
          type: 'delete',
          entityId: change.entityId,
          value: 'force',
        })
      }
    })
  }

  const [request, response] = useLazyPostAutoAcceptQuery()

  const onSubmit = ({ changes, ...rest }: any) => {
    const actions = changes.map((item) => ({
      id: item.entityId,
      action: item.value,
    }))

    request({ id: item.id, ...rest, actions })
  }

  return {
    changes,
    onSubmit,
    isFetching: response.isFetching,
    isShowSchoolAm,
    isShowSchoolPm,
  }
}

export const required = {
  required: {
    value: true,
    message: 'error',
  },
}
