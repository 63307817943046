import _ from 'lodash'
import L from 'leaflet'

export class FilterItem {
  e
  type

  constructor(e, type) {
    this.e = e
    this.type = type
  }
}

export function getChangeInput(e, type) {
  let newArr = this.state.filters

  newArr[type] = e
  this.setState({
    filters: newArr,
  })
}

export function getChangeInputArray(filters) {
  filters.forEach((filter) => {
    let newArr = this.state.filters
    newArr[filter.type] = filter.e

    this.setState({
      filters: newArr,
    })
  })
}

export function sortRidersList(list) {
  if (!list) {
    return
  } else {
    return list.sort((a, b) => {
      if (a.withProblems || b.withProblems) {
        return 1
      }

      let nameA = (
        (a.childaddress || a.childAddress)?.child?.full_name ?? ''
      ).toLowerCase()
      let nameB = (
        (b.childaddress || b.childAddress)?.child?.full_name ?? ''
      ).toLowerCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    })
  }
}

export function getChangeInputArrayRetrun(old, filters) {
  let newArr = { ...old }
  filters.forEach((filter) => {
    newArr[filter.type] = filter.e
  })
  return newArr
}

export function getFilterArray(item, action, prop) {
  let filters = this.state.filters //список всех активных фильтров
  if (action === 'clean') {
    filters['name'] = ''
    filters['address'] = ''
    filters[prop] = []
  } else {
    const position = filters[prop].indexOf(item)
    if (~position) {
      filters[prop].splice(position, 1)
    } else {
      filters[prop].push(item)
    }
  }
  this.setState({
    filters: filters,
  })
}

function parseZipCode(singleLineAddress) {
  singleLineAddress = singleLineAddress.trim()
  var postalCode = singleLineAddress.match(
      /([0-9]{5})|([a-z][0-9][a-z] ?[0-9][a-z][0-9])/gi
    ),
    indexOfPostalCode = -1
  if (postalCode) {
    postalCode = postalCode.pop() // pick match closest to end
    indexOfPostalCode = singleLineAddress.lastIndexOf(postalCode)
    if (indexOfPostalCode == 0 && singleLineAddress.length > 10) {
      // postal code is probably part of street address
      postalCode = null
      indexOfPostalCode = -1
    }
  }
  return postalCode
}

export function renderChildrenListBuildRun(address, status) {
  let childrenList = this.state.childrenList

  function createLeafletShapes(zonesList, ids) {
    let arr = []
    ids.forEach((inId) => {
      let item = (zonesList || []).find((x) => x.id === inId)

      if (
        item.data &&
        item.data.type &&
        item.data.type === 'FeatureCollection'
      ) {
        item.data.features.forEach((shape) => {
          if (shape.properties && shape.properties.subType === 'Circle') {
            arr.push(
              L.circle(
                [shape.geometry.coordinates[1], shape.geometry.coordinates[0]],
                { radius: shape.properties.radius }
              )
            )
          }

          if (shape.properties && shape.properties.subType === 'Radius') {
            arr.push(
              L.circle(
                [shape.geometry.coordinates[1], shape.geometry.coordinates[0]],
                { radius: shape.properties.radius }
              )
            )
          }

          if (shape.geometry.type === 'Polygon') {
            arr.push(L.geoJSON(shape))
          }
        })
      }
    })
    return arr
  }

  function isMarkerInsidePolygon(coord, poly) {
    let polyPoints = poly.getLatLngs()[0]
    let x = coord.lat,
      y = coord.lng
    let inside = false
    for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
      let xi = polyPoints[i].lat,
        yi = polyPoints[i].lng
      let xj = polyPoints[j].lat,
        yj = polyPoints[j].lng

      let intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
      if (intersect) inside = !inside
    }
    return inside
  }

  let {
    addressesPrimary,
    riderName,
    addressType,
    zip,
    transCat,
    schoolId,
    grade,
    zonesActive,
  } = this.state.filters

  if (!childrenList || childrenList.length === 0) {
    return []
  }

  if (status) {
    childrenList = _.flatten(childrenList.filter((i) => i.status))
  }

  if (addressType !== null) {
    childrenList = childrenList.filter((item) => {
      item.addressesView = item.addresses.filter((x) => {
        if (x.type === addressType && !x.is_school) {
          return true
        }
      })
      if (item.addresses) return item
    })
  } else {
    childrenList = childrenList.filter((item) => {
      item.addressesView = item.addresses
      return true
    })
  }

  if (zip) {
    childrenList = childrenList.map((item) => {
      item.addressesView = item.addressesView.filter((x) => {
        if (x.is_use || x.is_school || !x.address) return false
        let singleLineAddress = x.address.address
        let possibleZip = parseZipCode(singleLineAddress)
        if (zip.includes(possibleZip)) return x
      })
      return item
    })
  }

  if (transCat) {
    childrenList = childrenList.filter((item) => {
      if (transCat.includes(item.transportation_category)) return item
    })
  }

  if (schoolId) {
    childrenList = childrenList.map((item) => {
      item.addressesView = item.addressesView.filter((x) => {
        if (x.is_use || x.is_school) return false
        if (schoolId.includes(x?.school?.id)) return x
      })
      return item
    })
  }

  if (grade) {
    childrenList = childrenList.filter((item) => {
      if (grade.includes(item.classs)) return true
    })
  }

  if (addressesPrimary) {
    childrenList = childrenList.map((child) => {
      child.addressesView = child.addressesView.filter(
        (address) => address.is_primary && !address.is_use
      )
      return child
    })
  }

  if (riderName) {
    if (riderName.match(/[$-+/:-?\\{-~!"^_`\[\]]/)) {
      return []
    }
    childrenList = childrenList.filter((item) => {
      if (
        //if item.full_name exist
        (item.full_name &&
          item.full_name.toLowerCase().search(riderName.toLowerCase()) > -1) ||
        item.cid.toString().includes(riderName)
      ) {
        return true
      }
    })
  }

  if (
    zonesActive &&
    zonesActive.in &&
    zonesActive.ex &&
    this.props &&
    this.props.zonesList
  ) {
    let zonesList = this.props.zonesList
    let zoneLayersIn = createLeafletShapes(zonesList, zonesActive.in)
    let zoneLayersEx = createLeafletShapes(zonesList, zonesActive.ex)

    childrenList = childrenList.map((child) => {
      child.addressesView = child.addressesView.filter((x) => {
        let coord = {}

        if (!x.is_school && x.address && x.address.lat && x.address.lon) {
          coord = {
            lat: x.address.lat,
            lng: x.address.lon,
          }

          let find = false

          zoneLayersIn.forEach((zoneLayer, i, arr) => {
            if (!zoneLayer.eachLayer) {
              if (
                zoneLayer.getLatLng().distanceTo(coord) < zoneLayer._mRadius
              ) {
                find = true
              }
            } else {
              zoneLayer.eachLayer((y) => {
                let r = isMarkerInsidePolygon(coord, y)
                if (r) find = true
              })
            }
          })

          if (_.isEmpty(zoneLayersIn)) find = true

          zoneLayersEx.forEach((zoneLayer) => {
            if (!zoneLayer.eachLayer) {
              if (
                zoneLayer.getLatLng().distanceTo(coord) < zoneLayer._mRadius
              ) {
                find = false
              }
            } else {
              zoneLayer.eachLayer((y) => {
                let r = isMarkerInsidePolygon(coord, y)
                if (r) find = false
              })
            }
          })

          if (find) return x
        }
      })
      return child
    })
  }

  return childrenList
}

export function renderChildrenList(address, status) {
  let childrenList = this.state && this.state.childrenList
  let filters = this.state.filters
  if (!childrenList || childrenList.length === 0) {
    return []
  }

  function createLeafletShapes(zonesList, ids) {
    let arr = []
    ids.forEach((inId) => {
      let item = (zonesList || []).find((x) => x.id === inId)

      if (
        item.data &&
        item.data.type &&
        item.data.type === 'FeatureCollection'
      ) {
        item.data.features.forEach((shape) => {
          if (shape.properties && shape.properties.subType === 'Circle') {
            arr.push(
              L.circle(
                [shape.geometry.coordinates[1], shape.geometry.coordinates[0]],
                { radius: shape.properties.radius }
              )
            )
          }

          if (shape.properties && shape.properties.subType === 'Radius') {
            arr.push(
              L.circle(
                [shape.geometry.coordinates[1], shape.geometry.coordinates[0]],
                { radius: shape.properties.radius }
              )
            )
          }

          if (shape.geometry.type === 'Polygon') {
            arr.push(L.geoJSON(shape))
          }
        })
      }
    })

    return arr
  }

  if (address && filters.address !== '') {
    childrenList = childrenList.filter((child) => {
      //фильтр на неиспользованные
      if (!child.addresses) return false
      if (!child.status) return false
      if (!child.addresses.length) return false
      for (let i = 0; i < child.addresses.length; i++) {
        if (!child.addresses[i].is_use) {
          return true
        }
      }
      return false
    })
  }

  if (status) {
    childrenList = _.flatten(childrenList.filter((i) => i.status))
  }

  if (filters.district instanceof Array && filters.district.length > 0) {
    childrenList = childrenList.filter((el) =>
      filters.district.some((a) => a === el.district_name)
    )

    childrenList = _.flatten(childrenList)
  }

  if (filters.grade instanceof Array && filters.grade.length > 0) {
    childrenList = childrenList.filter((el) =>
      filters.grade.some((a) => a === el.classs)
    )
    childrenList = _.flatten(childrenList)
  }

  if (filters.name !== '') {
    if (filters.name.match(/[$-+/:-?\\{-~!"^_`\[\]]/)) return []
    childrenList = childrenList.filter((item) => {
      if (!item.full_name) return false
      if (
        item.full_name.toLowerCase().search(filters.name.toLowerCase()) > -1 ||
        item.cid.toString().includes(filters.name)
      ) {
        return true
      }
    })
  }

  if (filters.address !== '') {
    if (filters.address.match(/[$-+/:-?\\{-~!"^_`\[\]]/)) return []
    childrenList = childrenList.filter((item) => {
      return item.addresses.some((address) => {
        if (!address || !address.address || !address.address.address) {
          return false
        }

        return (
          address.address.address
            .toLowerCase()
            .search(filters.address.toLowerCase()) > -1
        )
      })
    })
  }

  if (filters.routes.length > 0) {
    childrenList = childrenList.filter((child) => {
      let find = child.addresses.some((x) => {
        if (x.route && x.route.length) {
          return x.route.some((y) => filters.routes.includes(y.title))
        }
      })
      if (find) return child
    })
    childrenList = _.flatten(childrenList)
  }

  if (filters.trans instanceof Array && filters.trans.length > 0) {
    childrenList = childrenList.filter((i) =>
      filters.trans.some((e) => e === i.transportation_category)
    )

    childrenList = _.flatten(childrenList)
  }

  if (typeof filters.trans === 'string' && filters.trans !== '') {
    childrenList = childrenList.filter((item) => {
      if (item.transportation_category === filters.trans) return item
    })
  }

  if (typeof filters.grade === 'string' && filters.grade.length > 0) {
    childrenList = childrenList.filter((item) => {
      if (
        item.classs &&
        item.classs.toLowerCase().search(filters.grade.toLowerCase()) > -1
      ) {
        return true
      }
    })
  }

  if (filters.pickUpDropOff !== 'All' && filters.pickUpDropOff !== '') {
    childrenList = childrenList.filter((item) => {
      item.addressesView = item.addresses.filter((x) => {
        if (x.type === filters.pickUpDropOff && !x.is_school) {
          return true
        }
      })
      if (item.addresses) return item
    })
  } else {
    childrenList = childrenList.filter((item) => {
      item.addressesView = item.addresses
      return true
    })
  }

  if (!filters.addresses) {
    childrenList = childrenList.map((child) => {
      child.addressesView = child.addressesView.filter(
        (address) => address.is_primary && !address.is_use
      )
      return child
    })
  }

  if (filters.zones && filters.zones.in) {
    let zonesList = this.props.zonesList
    let zoneLayersIn = createLeafletShapes(zonesList, filters.zones.in)
    let zoneLayersEx = createLeafletShapes(zonesList, filters.zones.ex)

    childrenList = childrenList.map((child) => {
      child.addressesView = child.addressesView.filter((x) => {
        let coord = {}
        if (x.address && x.address.lat && x.address.lon) {
          coord = {
            lat: x.address.lat,
            lng: x.address.lon,
          }

          let find = false

          zoneLayersIn.forEach((zoneLayer, i, arr) => {
            if (!zoneLayer.eachLayer) {
              if (
                zoneLayer.getLatLng().distanceTo(coord) < zoneLayer._mRadius
              ) {
                find = true
              }
            } else {
              zoneLayer.eachLayer((y) => {
                if (y.getBounds) {
                  let bounds = y.getBounds()
                  if (bounds.contains(coord)) {
                    find = true
                  }
                }
              })
            }
          })

          if (_.isEmpty(zoneLayersIn)) find = true

          zoneLayersEx.forEach((zoneLayer) => {
            if (!zoneLayer.eachLayer) {
              if (
                zoneLayer.getLatLng().distanceTo(coord) < zoneLayer._mRadius
              ) {
                find = false
              }
            } else {
              zoneLayer.eachLayer((y) => {
                if (y.getBounds) {
                  let bounds = y.getBounds()
                  if (bounds.contains(coord)) {
                    find = false
                  }
                }
              })
            }
          })

          if (find) return x
        }
      })
      return child
    })
  }

  if (typeof filters.zip === 'string' && filters.zip) {
    childrenList = childrenList.map((item) => {
      item.addressesView = item.addressesView.filter((x) => {
        if (x.is_use || x.is_school || !x.address) return false
        let singleLineAddress = x.address.address
        let possibleZip = parseZipCode(singleLineAddress)
        if (possibleZip && filters.zip === possibleZip) {
          return x
        }
      })
      return item
    })
  }

  if (typeof filters.schools === 'string' && filters.schools !== '') {
    childrenList = childrenList.map((item) => {
      item.addressesView = item.addressesView.filter((x) => {
        if (x.is_use || x.is_school) return false
        if (x.school.name === filters.schools) return x
      })
      return item
    })
  }

  if (_.isArray(filters.schools) && filters.schools.length > 0) {
    childrenList = childrenList.map((item) => {
      let find = item.addresses.findIndex((x) => {
        if (x.is_school) return false

        let check = filters.schools.findIndex((s) => {
          if (x.school && x.school.name && x.school.name === s) return true
        })
        if (check >= 0) return true
      })
      if (find >= 0) return item
    })
    childrenList = _.compact(childrenList)
  }

  return childrenList
}

export function getSchoolFromAddresses(child) {
  return child.addresses.map((x) => {
    if (x.is_use || x.is_school) return false
    if (x.school) return x.school.name
  })
}
export function getSchoolFromAddresses2(child) {
  return child.addresses.map((x) => {
    if (x.school) return x.school.name
  })
}

export function getZipFromAddresses(child) {
  return child.addresses.map((x) => {
    if (x.is_use || x.is_school || !x.address || !x.address.address)
      return false

    let singleLineAddress = x.address.address
    let possibleZip = parseZipCode(singleLineAddress)
    return possibleZip
  })
}
