import { combineReducers } from 'redux'
import sessions from './sessions/reducers'
import schools from './schools/reducers'
import children from './children/reducers'
import routesView from './routesView/reducers'
import attendance from './attendance/reducers'
import auth from './auth/reducers'
import socket from './socket/reducers'
import fieldTrip from './fieldTrip/reducers'
import fieldTripRequests from './fieldTrip/requests/reducers'
import buses from './auth/buses/reducers'
import busResources from './bus/reducers'
import depots from './depot/reducers'
import drivers from './driver/reducers'
import dashboard from './dashboard/reducers'
import consoles from './console/reducers'
import userSettings from './userSettings/reducers'
import districts from './districts/reducers'
import settings from './settings/reducers'
import gps from './gps/reducers'
import rtRequests from './requests/reducers'
import headerLoader from './common/reducers'
import { authApi } from '@/store/auth/AuthApi'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import { sessionApi } from '@/store/session/sessionApi'
import { lcSettingsApi } from '@/store/lc/settings/lcSettingsApi'
import { fieldTripApi } from '@/store/fieldTrip/FieldTripApi'
import { routeToolApi } from '@/store/routeTool/routeTool'
import { resourcesApi } from '@/store/resources/resources'
import thunk from 'redux-thunk'

import { createRequestMiddleware, requestsReducer } from 'redux-requests'
import delayMiddleware from '@/redux/common/components/middleWare'
import { commonApi } from '@/store/common/common'
import { viewRunsApi } from '@/store/viewRuns/viewRuns'
import { routeToolTemplateApi } from '@/store/routeTool/RouteToolTemplateApi/RouteToolTemplateApi'

export const rootReducer = (state, action) => {
  if (action.type === 'auth.UNAUTHENTICATED') {
    state = undefined
  }
  return appReducer(state, action)
}

const appReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [lcSettingsApi.reducerPath]: lcSettingsApi.reducer,
  [sessionApi.reducerPath]: sessionApi.reducer,
  [fieldTripApi.reducerPath]: fieldTripApi.reducer,
  [routeToolApi.reducerPath]: routeToolApi.reducer,
  [resourcesApi.reducerPath]: resourcesApi.reducer,
  [commonApi.reducerPath]: commonApi.reducer,
  [viewRunsApi.reducerPath]: viewRunsApi.reducer,
  [routeToolTemplateApi.reducerPath]: routeToolTemplateApi.reducer,
  sessions,
  schools,
  children,
  routesView,
  attendance,
  fieldTrip,
  fieldTripRequests,
  buses,
  auth,
  userSettings,
  busResources,
  depots,
  dashboard,
  drivers,
  consoles,
  districts,
  settings,
  gps,
  requestsReducer,
  socket,
  rtRequests,
  headerLoader,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      immutableCheck: false,
      serializableCheck: false,
    })
      .concat([thunk, delayMiddleware, createRequestMiddleware()])
      .concat(authApi.middleware)
      .concat(lcSettingsApi.middleware)
      .concat(routeToolApi.middleware)
      .concat(fieldTripApi.middleware)
      .concat(sessionApi.middleware)
      .concat(resourcesApi.middleware)
      .concat(commonApi.middleware)
      .concat(viewRunsApi.middleware)
      .concat(routeToolTemplateApi.middleware),
})

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type RootState = ReturnType<typeof rootReducer>

export const useAppDispatch: () => AppDispatch = useDispatch
export type AppDispatch = typeof store.dispatch
