import React, { Component } from 'react'
import { connect } from 'react-redux'

import Input from 'antd/es/input'
import CurrencyInput from 'react-currency-masked-input'

import debounce from 'lodash.debounce'

import * as fieldTripRequestsActions from '../../../../../redux/fieldTrip/requests/actions'

class TripCost extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cost: props.data.fieldTrip.cost,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      cost: nextProps.data.fieldTrip.cost,
    })
  }

  applyCost = debounce(({ name, value }) => {
    let request = { cost: this.state.cost }

    this.props.dispatch(
      fieldTripRequestsActions.editTrip(this.props.data.fieldTrip.id, request)
    )
  }, 1000)

  handleChange = (event) => {
    let request = {
      name: 'cost',
      value: parseFloat(event.target.value),
    }

    let isValid = event.currentTarget.validity.valid

    this.setState(
      {
        cost: request.value,
      },
      () => {
        if (isValid) {
          this.applyCost(request)
        }
      }
    )
  }

  render() {
    return (
      <div style={{ padding: '0 10px' }} className="trip-cost">
        <div className="row">
          <div className="row-heading">
            <h4>Field Trip Costs</h4>
          </div>

          <div className="input-wrapper">
            {this.props.isReadonly && (
              <div>
                <label className="label views">Estimated Cost</label>
                <span>
                  $ {this.state.cost ? this.state.cost : 'not indicated'}
                </span>
              </div>
            )}

            {!this.props.isReadonly && (
              <div className="costInput">
                <label className="label writes">Estimated Cost</label>
                <span className="symbol writes">$</span>
                <CurrencyInput
                  className="input cost"
                  onInput={this.handleChange}
                  value={this.state.cost}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(TripCost)
