import React, { useEffect, useMemo, useState } from 'react'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import { mapKey } from '../../redux/configLocal'
import { useMap } from '../MapDefault/MapContext'
import { useSelector } from 'react-redux'
import { BasemapLayer } from 'react-esri-leaflet/v2'
import { GOOGLE } from '../../helpers/constants'

export default function (props) {
  const mapLayer = useSelector((state) =>
    state.auth.companyOptions
      ? state.auth.companyOptions.base_map_layer
      : GOOGLE
  )
  const [stateMapType, setStateMapType] = useState('roadmap')

  useEffect(() => {
    if (props.maptype) {
      setStateMapType(props.maptype)
    }
  }, [props.maptype])

  const { mapType } = useMap()

  const currentMapType = useMemo(() => {
    if (props.maptype) {
      return stateMapType
    } else {
      return mapType
    }
  }, [props.maptype, stateMapType, mapType])

  const googleRoadmap = currentMapType === 'roadmap' && mapLayer === 'google'
  const esriRoadMap = currentMapType === 'roadmap' && mapLayer === 'esri'

  return (
    <div>
      {esriRoadMap && <BasemapLayer name={'Streets'} />}
      {googleRoadmap && <ReactLeafletGoogleLayer {...roadMapProps} />}
      {currentMapType === 'traffic' && (
        <ReactLeafletGoogleLayer {...trafficProps} />
      )}
      {currentMapType === 'satellite' && (
        <ReactLeafletGoogleLayer {...setelliteProps} />
      )}
    </div>
  )
}

const roadMapProps = {
  googleMapsLoaderConf: { KEY: mapKey, LIBRARIES: ['geometry'] },
  type: 'roadmap',
}

const trafficProps = {
  googleMapsLoaderConf: { KEY: mapKey, LIBRARIES: ['geometry'] },
  googleMapsAddLayers: [{ name: 'TrafficLayer' }],
  type: 'roadmap',
}

const setelliteProps = {
  googleMapsLoaderConf: { KEY: mapKey, LIBRARIES: ['geometry'] },
  type: 'satellite',
}
