import { Path, withLeaflet } from 'react-leaflet'
import { Polyline } from 'leaflet'
import L from 'leaflet'
import './leaflet.textpath'
import './polyline.encoded'
import 'leaflet.polyline.snakeanim/L.Polyline.SnakeAnim.js'

import hotline from 'leaflet-hotline'
hotline(L)

function coordsFilter(coords, props) {
  return coords.map((latlng, i) => {
    let gradient = 0
    if (props.late) {
      if (!props.late[i]) {
        gradient = 0
      } else {
        gradient = props.late[i] * 1
      }
    }
    if (props && props.nextLate && props.nextLate) {
      gradient = 1
    }

    return [latlng.lat, latlng.lng, gradient]
  })
}

class PolylineTextGradient extends Path {
  createLeafletElement(props) {
    let l = Polyline.fromEncoded(props.geometry)
    let latLngs = l.getLatLngs()
    let coords = coordsFilter(latLngs, props)

    l = L.hotline(coords.slice(0, -1), {
      palette: {
        0.0: `#7ed321`,
        1.0: `#d0021b`,
      },
      weight: 13,
      outlineWidth: 0,
    })

    return l
  }

  updateLeafletElement(fromProps, toProps) {
    if (toProps.geometry !== fromProps.geometry) {
      this.leafletElement.setLatLngs(
        L.Polyline.fromEncoded(toProps.geometry).getLatLngs()
      )
      let latLngs = this.leafletElement.getLatLngs()
      let coords = coordsFilter(latLngs, toProps)
      this.leafletElement.setLatLngs(coords)
    }

    this.setStyleIfChanged(fromProps, toProps)
  }
}

export default withLeaflet(PolylineTextGradient)

export const AnimatedPolyline = ({ start, end, map, isLate }) => {
  try {
    if (
      map &&
      start &&
      start[0] &&
      start[1] &&
      end &&
      end[0] &&
      end[1] &&
      start[0] !== end[0] &&
      start[1] !== end[1]
    ) {
      const route = L.polyline([start, end], {
        color: isLate ? '#d0021b' : '#7ed321',
        weight: 13,
        opacity: 0.8,
        snakingSpeed: 50,
      })
      route.addTo(map.contextValue.map).snakeIn()

      setTimeout(() => {
        route.removeFrom(map.contextValue.map)
      }, 3000)
    }
  } catch (err) {}

  return null
}
