import React from 'react'

export const CalendarIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      {...props}
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M6.362 9.912a.408.408 0 0 0-.408-.407H4.531a.408.408 0 0 0-.408.407v1.423c0 .225.183.408.408.408h1.423a.408.408 0 0 0 .408-.408V9.912zM9.918 9.912a.407.407 0 0 0-.407-.407H8.088a.408.408 0 0 0-.408.407v1.423c0 .225.183.408.408.408H9.51a.408.408 0 0 0 .407-.408V9.912zM13.475 9.912a.408.408 0 0 0-.408-.407h-1.423a.408.408 0 0 0-.408.407v1.423c0 .225.183.408.408.408h1.423a.408.408 0 0 0 .408-.408V9.912zM6.362 13.469a.407.407 0 0 0-.408-.408H4.531a.407.407 0 0 0-.408.408v1.422c0 .226.183.408.408.408h1.423a.408.408 0 0 0 .408-.408V13.47zM9.918 13.469a.407.407 0 0 0-.407-.408H8.088a.407.407 0 0 0-.408.408v1.422c0 .226.183.408.408.408H9.51a.408.408 0 0 0 .407-.408V13.47zM13.475 13.469a.407.407 0 0 0-.407-.408h-1.424a.407.407 0 0 0-.408.408v1.422c0 .226.183.408.408.408h1.424a.408.408 0 0 0 .407-.408V13.47z" />
        <path d="M16.02 2v2.174c0 .982-.797 1.774-1.78 1.774h-1.122c-.982 0-1.79-.792-1.79-1.774V1.993H6.27v2.18c0 .983-.807 1.775-1.79 1.775H3.358a1.775 1.775 0 0 1-1.78-1.774V2A1.618 1.618 0 0 0 .016 3.61v12.736c0 .89.721 1.62 1.61 1.62h14.348a1.62 1.62 0 0 0 1.61-1.62V3.61c0-.873-.705-1.584-1.564-1.61zm-.522 13.553a.696.696 0 0 1-.696.697H2.766a.696.696 0 0 1-.697-.697V8.974c0-.384.312-.696.697-.696h12.036c.385 0 .696.312.696.696v6.58z" />
        <path d="M3.354 4.783h1.11a.61.61 0 0 0 .61-.61V.61a.61.61 0 0 0-.61-.61h-1.11a.61.61 0 0 0-.61.61v3.563c0 .337.273.61.61.61zM13.104 4.783h1.11a.61.61 0 0 0 .61-.61V.61a.61.61 0 0 0-.61-.61h-1.11a.61.61 0 0 0-.61.61v3.563c0 .337.273.61.61.61z" />
      </g>
    </svg>
  )
}
