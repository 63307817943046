// @ts-check

import React, { Component } from "react";
import { connect } from "react-redux";

import BaseModal from "../../../../../components/BaseModal";
import "./index.less";

class PropsModel {
  /** @type {string} */
  title;

  /** @type {string} */
  content;

  /** @type {Function} */
  closeFn;
}

class ModalInfo extends Component {
  /**
   * @param {Readonly<PropsModel>} props
   */
  constructor(props) {
    super(props);

    this.state = {
      title: props.title,
      content: props.content
    };
  }

  /**
   *
   * @param {Readonly<PropsModel>} nextProps
   */
  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  handleSave = () => {};

  render() {
    let { title, content } = this.state;
    return (
      <BaseModal
        class="ft-modal-info"
        title={title}
        closeFn={this.props.closeFn}
        save={this.props.closeFn}
      >
        <div
          className="line"
          style={{
            fontFamily: "SBM-Lite",
            fontSize: "16px",
            color: "#000000"
          }}
        >
          {<div dangerouslySetInnerHTML={{ __html: content }}></div>}
        </div>

        {this.props.children}

        <div className="center">
          <div className="buttons">
            <button className="save" onClick={this.props.closeFn}>
              OK
            </button>
          </div>
        </div>
      </BaseModal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalInfo);
