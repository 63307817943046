import { IRtRequestsAndRegistration } from '@/features/containers/SocketConnect/SocketConnect.type'

export const parentPortalTypes: IRtRequestsAndRegistration['eventType'][] = [
  'inReviewPortalRequest',
  'rejectedPortalRequest',
  'acceptedPortalRequest',
  'createdPortalRequest',
  'changeStatusRegistrationRequest',
]

export const rtRequestsActionTypes: IRtRequestsAndRegistration['eventType'][] =
  [
    'inReviewPortalRequest',
    'rejectedPortalRequest',
    'acceptedPortalRequest',
    'createdPortalRequest',
  ]
