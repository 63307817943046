import PolylineText from '@/components/MapDefault/src/polyline-text/PolylineTextGps'
import { directionsPathOptionsWith2Colors } from '@/components/MapDefault/mapSpecialFns'
import React, { FC, useEffect, useState } from 'react'
import { getOsrm } from '@/pages/Gps/NewGpsDetails/helpers/getOsrm'
import { useGpsDetailsStore } from '@/pages/Gps/NewGpsDetails/store'
import * as Sentry from '@sentry/react'

const options = {
  repeat: true,
  offset: 3,
  attributes: {
    fill: 'white',
    'font-weight': 'normal',
    'font-size': '12',
  },
}

export const RunPolyline: FC = () => {
  const run = useGpsDetailsStore.getState().gpsInfo

  const [osrm, setOsrm] = useState(null)

  useEffect(() => {
    const hasPoints = run?.points?.length > 1
    hasPoints && getOsrm(run).then(setOsrm).catch(Sentry.captureException)
  }, [run])

  if (!osrm?.geometry) return null

  return (
    <PolylineText
      arrowOptions={options}
      pathOptions={directionsPathOptionsWith2Colors(true)}
      geometry={osrm.geometry}
    />
  )
}
