import { Radio } from 'antd'
import { FC, useState } from 'react'
import styles from './ForceEditRiderAddress.module.less'
import { useForceUpdateAddressesStore } from '@/pages/Riders/components/ModalEdit/forceUpdateAddressActions'
import { closeModal } from '@/features/containers/ModalContainer/modalConfig'
import { ModalFooter } from '@/entities/ui/NewModal/components/ModalFooter'
import { useEditRiderModalStore } from '@/pages/Riders/components/ModalEdit/store'

interface IProps {
  onSave: ({
    isCheckUseAddress,
    forceParams,
    newRider,
  }: {
    isCheckUseAddress?: boolean
    forceParams?: ForceParams
    newRider?: any
  }) => void
  rider: any
}

export interface ForceParams {
  force: boolean
  forceChildAddressIds: number[]
}

export const ForceEditRiderAddress: FC<IProps> = ({ onSave, rider }) => {
  const [value, setValue] = useState('2')
  const isLoading = useEditRiderModalStore((state) => state.isLoading)
  const handleSave = () => {
    const editData = useForceUpdateAddressesStore.getState().editData
    const initialData = useForceUpdateAddressesStore.getState().initialAddresses

    switch (value) {
      case '2': {
        const addresses = rider?.addresses.map((address) => {
          const editAddress = editData?.find(
            (item) => item.addressId === address.id
          )
          if (editAddress?.type === 'school') {
            return {
              ...address,
              childSchool: {
                ...address.childSchool,
                school_id: editAddress.oldValue,
              },
            }
          } else if (editAddress?.type === 'address') {
            return {
              ...address,
              address:
                typeof address?.address === 'string'
                  ? editAddress.oldValue
                  : { ...address.address, address: editAddress.oldValue },
            }
          } else if (editAddress?.type === 'addressType') {
            return { ...address, type: editAddress.oldValue }
          } else if (editAddress?.type === 'delete') {
            const oldAddress = initialData.find(
              (el) => el.id === editAddress?.addressId
            )

            return oldAddress
          }
          return address
        })
        const deletedData = editData.filter((el) => el.type === 'delete')
        if (deletedData.length > 0) {
          deletedData.forEach((item) => {
            const init = initialData?.find((el) => el.id === item?.addressId)
            if (init) {
              addresses.push(init)
            }
          })
        }
        onSave({
          forceParams: { force: true, forceChildAddressIds: [] },
          isCheckUseAddress: false,
          newRider: { ...rider, addresses },
        })
        // closeModal()
        break
      }

      case '3': {
        onSave({
          forceParams: {
            force: true,
            forceChildAddressIds: editData.map((item) => item.addressId),
          },
        })
        break
      }

      default: {
        break
      }
    }
  }

  return (
    <section>
      <p>
        You have changed address or school for a rider on a run. <br />
        Please choose one of the following options:
      </p>
      <Radio.Group
        className={styles.form}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        <Radio data-testid="saveWithoutAddressChanges" value="2">
          Save without address changes
        </Radio>
        <Radio data-testid="saveAndRemoveFromRun" value="3">
          Save and remove rider from run
        </Radio>
      </Radio.Group>
      <ModalFooter
        buttonWidth={72}
        success={{
          action: handleSave,
          text: 'Ok',
          isLoading: isLoading,
          testId: 'confirmAndSaveRiderInRun',
        }}
        cancel={{
          action: closeModal,
          text: 'Cancel',
        }}
      />
    </section>
  )
}
