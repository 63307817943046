import SocketIOClient from 'socket.io-client'
import { socketIOClientIp } from '@/redux/configLocal'
import { useEffect } from 'react'
import { useAppSelector } from '@/redux'
import { parentPortalTypes } from '@/features/containers/SocketConnect/constants'
import { rtRequestsAndRegistrations } from '@/features/containers/SocketConnect/helpers/rtRequestsAndRegistrations'
import { socketImports } from '@/features/containers/SocketConnect/helpers/socketImports'
import { useImportSocketResponse } from '@/features/containers/SocketConnect/store'

import { optimizeRouteMessage } from '@/features/SocketConnect/helpers/optimizeRoute'

const socket = SocketIOClient(socketIOClientIp)

export const SocketConnect = () => {
  const socket_token = useAppSelector((state) => state.auth.socket_token)
  const onMessage = (msg) => {
    const uidImport = useImportSocketResponse.getState().uidImport

    if (parentPortalTypes.includes(msg?.eventType)) {
      return rtRequestsAndRegistrations(msg)
    } else if (msg.uidImport && msg.uidImport === uidImport) {
      socketImports(msg)
    } else if (typeof msg?.routeId === 'number') {
      optimizeRouteMessage(msg)
    }
  }

  useEffect(() => {
    if (!socket_token || !socket) {
      return
    }

    const joinRoom = () => {
      socket.emit('room', 'import_' + socket_token)
      socket.emit('room', 'optimizeRoute')
      socket.emit('room', socket_token)
      socket.on('message', onMessage)
    }

    const handleConnect = () => {
      joinRoom()
    }

    if (!socket.hasListeners('connect')) {
      socket.on('connect', handleConnect)
    }

    if (socket.connected) {
      joinRoom()
    }

    socket.on('disconnect', () => {
      socket.off('message', onMessage)
    })

    return () => {
      socket.off('message', onMessage)
      socket.off('connect', handleConnect)
      socket.off('disconnect')
      socket.disconnect()
    }
  }, [socket_token])

  return null
}
