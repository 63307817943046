import React, { Component } from 'react'
import { connect } from 'react-redux'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import styles from './RouteItem.module.less'
import * as routesActions from '../../../../redux/routesView/actions'
import Child from './components/ChildPoint'
import School from './components/SchoolPoint'
import Stop from './components/StopPoint'
import Transfer from './components/TransferPoint'
import BusDepot from './components/BusDepot'
import EditRoutePoint from './components/EditRoutePoint'
import ModalTyrn from '../ModalTyrn'
import ModalError from '../ModalError'
import ModalPrint from '../ModalPrint'
import ModalEmail from '../ModalEmail'
import _ from 'lodash'
import { Trans } from 'react-i18next'
import {
  arrivalTimeUtsToLocal,
  averageSpeed,
  getTimeLastUTC,
  totalDist,
  totalTimeWithWT2,
} from '../../../../components/RepeatFunctions/timeWork'
import Success from '../../../../components/Success'

import ModalMapZoom from '../MapZoom'
import ScheduleRun from './components/ScheduleRun'
import i18n from '../../../../i18n'
import { NewCheckbox } from '../../../../entities/ui/NewCheckbox/NewCheckbox'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { useRouterStore } from '@/shared/hooks/routeHooks/useRouter'
import { Icon } from '@/entities/ui/Icon/Icon'
import classnames from 'classnames'

const relativePath = 'client'

class RouteItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editItem: false,
      showResult: false,
      zoomMap: false,
      ...this.props,
      ...this.props.route,
      route: this.props.route,
      routes: this.props.routes,
      bus_id: this.props.route.bus_id,
      detailView: false,
      runDetail: [],
      turn_by_turn: false,
      zoomMaps: false,
      tyrn_error: false,
      print_trip: false,
      email_trip: false,
      osrm: false,
      showTimes: false,
      answer: [],
      activeId: '',
      activeRouteOnMap: 0,
      schedule_run: false,
      adminFn: false,
    }
  }

  componentDidMount() {
    // this.props.dispatch(
    //   sessionsActions.currentSession(parseInt(this.state.route.season_id))
    // )
    this.setState({
      isLoading: this.props.loader === 'loadingStart',
    })

    document.addEventListener('keypress', (e) => {
      if (e.shiftKey && e.ctrlKey && e.code === `KeyK`) {
        this.setState({
          adminFn: !this.state.adminFn,
        })
      }
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.loader !== this.props.loader) {
      this.setState({
        isLoading: this.props.loader === 'loadingStart',
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loader !== this.props.loader) {
      this.setState({
        isLoading: this.props.loader === 'loadingStart',
      })
    }
    if (nextProps.activeSeason) {
      this.setState({
        title: nextProps.activeSeason.title,
      })
    }
    if (this.props.routes !== nextProps.routes) {
      let route = this.props.route
      nextProps.routes.forEach((i) => {
        if (i.id === route.routegroup_id) {
          i.routes.forEach((el) => {
            if (el.id === route.id) {
              this.setState({
                route: el,
              })
            }
            if (el.id === this.state.activeRouteOnMap && el.points) {
              // let getAnswer = sessionTimes(el)
              this.props.getPoints(el) //step 2
              this.setState(
                {
                  runDetail: el.points,
                  // answer: getAnswer
                },
                () => {
                  if (this.state.zoomMaps) {
                    this.setState({
                      detailView: false,
                    })
                  }
                }
              )
            }

            if (el.id === this.state.turnIdModal && el.osrm) {
              this.setState({
                turnData: el,
              })
            }
          })
        }
      })
    }

    let routes = _.sortBy(nextProps.routes, ['title'], ['asc'])

    this.setState({
      buses: nextProps.buses,
      routes: routes,
    })
  }

  sessions = (e) => {
    if (this.state.runDetail) {
      this.props.dispatch(routesActions.getRunDetails(this.state.route.id))
    }
    this.setState({
      showTimes: !this.state.showTimes,
      openRiderPlot: false,
    })
  }

  loadOSRMandPoints = (routeId, e, type) => {
    let { getPoints, oneActive } = this.props
    let defSettings = this.props.auth
    let { answer, route, detailView, isLoading } = this.state
    if (!isLoading) {
      this.props.compareOff()

      if (e.target.className.baseVal === '') {
        if (answer.length === 0) {
          if (!route.points) {
            this.props.dispatch(routesActions.getOSRM(routeId)) //step1
          }
          this.setState({
            activeRouteOnMap: routeId,
          })
        }
      } else {
        oneActive(routeId)
        let el = e.target.className
        if (el.indexOf('noclick') !== -1) return

        if (type !== 'mapZoom') {
          this.setState({
            detailView: !detailView,
          })
        }

        if (answer.length === 0) {
          this.props.dispatch(routesActions.getRunDetails(routeId, defSettings)) //step1
        } else {
          getPoints(route)
        }
        this.setState({
          activeRouteOnMap: routeId,
        })
      }
    }
  }

  mapZoom = (routeId) => {
    this.setState({
      zoomMapsOpen: true,
      zoomMapId: routeId,
    })
  }

  save = (text, id) => {
    this.setState({
      editItem: !this.state.editItem,
    })
  }

  saveEdit = (obj) => {
    this.props.dispatch(routesActions.editItemReload(obj))

    this.setState({
      editItem: !this.state.editItem,
    })
  }

  getDriverName = (driverId) => {
    if (
      this.props.drivers.length === 0 ||
      !driverId ||
      !(this.props.drivers || []).find((x) => x.id === driverId)
    )
      return
    return (this.props.drivers || []).find((x) => x.id === driverId).name
  }
  getAMPM = (val) => {
    val = val.replaceAll('AM', '<span class="am">AM</span>')
    val = val.replaceAll('PM', '<span class="pm">PM</span>')
    return (
      <span className="convText" dangerouslySetInnerHTML={{ __html: val }} />
    )
  }

  getBusName = (busId) => {
    if (
      this.props.buses.length === 0 ||
      !busId ||
      !(this.props.buses || []).find((x) => x.id === busId)
    )
      return
    return (this.props.buses || []).find((x) => x.id === busId).bus_number
  }

  info = (groupName) => {
    let { route } = this.state
    let driverName = this.getDriverName(route.driver_id)
    if (driverName) {
      route.driver_name = driverName
    }
    let busName = this.getBusName(route.bus_id)
    if (busName) {
      route.bus_number = busName
    }

    let arrivalTime = null
    if (route.osrm) {
      arrivalTime = getTimeLastUTC(
        route.osrm.legs[route.osrm.legs.length - 1].end_point.time
      )
      arrivalTime = arrivalTimeUtsToLocal(
        this.state.time_zone,
        arrivalTime,
        i18n.language
      )
    }

    return (
      <div
        className="info"
        onClick={(e) => this.loadOSRMandPoints(route.id, e)}
      >
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <div className="title overflowRV">{route.title}</div>
          </Col>
        </Row>

        <Row style={{ width: '100%' }}>
          <Col span={24} style={{ marginBottom: '6px' }}>
            <span className="trip_type">
              <Trans>
                {route.is_morning === 1 ? 'Pick Up Run' : 'Drop Off Run'}
              </Trans>
            </span>
            <span className="body">
              {route.riderscount ? route.riderscount : 0}
            </span>
            <span className="point">
              {route.stopscount ? route.stopscount : 0}
            </span>
          </Col>
        </Row>

        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <span className="inf">
              {' '}
              <Trans>Distance</Trans>
              <span> {route.osrm && totalDist(route.osrm.distance)} </span>
            </span>
          </Col>
          <Col span={12}>
            <span className="inf">
              <Trans>Calculated Speed</Trans>
              <span>
                {' '}
                {route.osrm &&
                  averageSpeed(route.osrm.distance, route.osrm.duration)}{' '}
              </span>
            </span>
          </Col>
        </Row>

        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <span className="inf">
              <Trans>Duration</Trans>{' '}
              <span> {route.osrm && totalTimeWithWT2(route.osrm)} </span>
            </span>
          </Col>
        </Row>
        <hr />

        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <span className="inf">
              <Trans>Driver</Trans> <span>{driverName}</span>
            </span>
          </Col>
          <Col span={12}>
            <span className="inf">
              <Trans>Arrival Time</Trans>
              <span> {arrivalTime}</span>
            </span>
          </Col>
        </Row>

        <Row style={{ width: '100%' }}>
          <Col span={12}>
            {route.monitor_name && (
              <span className="inf">
                <Trans>Monitor</Trans> <span>{route.monitor_name}</span>
              </span>
            )}
          </Col>
        </Row>

        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <span className="inf">
              <Trans>Bus #s</Trans> <span>{busName}</span>
            </span>
          </Col>
          <Col span={12}>
            {route.return_to_depot > 0 && (
              <span className="inf">
                <Trans>
                  Return to Depot <span>Yes</span>
                </Trans>
              </span>
            )}
          </Col>
        </Row>

        <hr />
        <Row style={{ width: '100%' }} className={'alignButton'}>
          {this.props.groupTitle && (
            <Col span={24}>
              <span className="inf overflowRV flexGl">
                <Trans>Group</Trans>{' '}
                <span style={{ marginLeft: '4px' }}>
                  {this.props.groupTitle}
                </span>
              </span>
            </Col>
          )}
        </Row>
        {this.props.access === 1 && (
          <div style={{ marginTop: 8 }} className={'btnBottom'}>
            <span
              onClick={() => this.setState({ editItem: !this.state.editItem })}
              className="noclick editTrip"
            >
              <Trans>Edit Run</Trans>
            </span>
          </div>
        )}
      </div>
    )
  }

  edit = () => {
    return (
      <EditRoutePoint
        groupTitle={this.props.groupTitle}
        routeItemId={this.state.id}
        route={this.state.route}
        adminFn={this.state.adminFn}
        routes={this.state.routes}
        buses={this.props.buses}
        drivers={this.props.drivers}
        close={this.closeEditModal}
        save={this.save}
        saveEdit={this.saveEdit}
      />
    )
  }
  getOsrm = (route) => {
    if (route.hasOwnProperty('osrm') && typeof route.osrm === 'object') {
      return route.osrm
    } else {
      return false
    }
  }

  getPointOsrmLeg = (osrm, index) => {
    if (osrm && osrm.legs) {
      if (index === osrm.legs.length) {
        let leg = osrm.legs[index - 1]
        return leg.end_point
      } else {
        let leg = osrm.legs[index]
        if (leg) {
          let distance = leg.distance
          let duration = leg.duration
          return { ...leg.start_point, distance, duration }
        }
      }
    }
  }

  loadElements = () => {
    let points = this.state.runDetail

    let osrm = this.getOsrm(this.state.route)

    if (!points) return false

    let time_zone = this.state.time_zone

    let route = this.state.route

    return points.map((point, index) => {
      let first = point.position === 1
      switch (point.type) {
        case 1: //child
          return (
            <Child
              data-testid="childPoint"
              pointOsrmLeg={this.getPointOsrmLeg(osrm, index)}
              key={point.id}
              {...point}
              routeView={true}
              first={first}
              timeZone={time_zone}
              route={route}
            />
          )
        case 2: //BusStop
          return (
            <Stop
              data-testid="stopPoint"
              routeView={true}
              key={point.id}
              {...point}
              pointOsrmLeg={this.getPointOsrmLeg(osrm, index)}
              first={first}
              timeZone={time_zone}
              route={route}
            />
          )
        case 3: //school
          return (
            <School
              data-testid="schoolPoint"
              key={point.id}
              routeView={true}
              childrenList={this.props.childrenList}
              pointOsrmLeg={this.getPointOsrmLeg(osrm, index)}
              first={first}
              {...point}
              timeZone={time_zone}
              route={route}
            />
          )
        case 5: //transfer
          return (
            <Transfer
              data-testid="transferPoint"
              pointOsrmLeg={this.getPointOsrmLeg(osrm, index)}
              key={point.id}
              routeView={true}
              routes={this.props.routes}
              {...point}
              first={first}
              timeZone={time_zone}
              route={route}
            />
          )
        case 4: //redirectionPoint
          return null
        case 6: //child
          return (
            <BusDepot
              data-testid="depotPoint"
              routeView={true}
              pointOsrmLeg={this.getPointOsrmLeg(osrm, index)}
              key={point.id}
              {...point}
              first={first}
              timeZone={time_zone}
              route={route}
            />
          )
        default:
          return null
      }
    })
  }

  openModal = (modal, routeId) => {
    this.setState({
      [modal]: !this.state[modal],
      routeIdActive: routeId,
    })
  }

  openModal2 = (modal, routeId) => {
    this.setState({
      [modal]: !this.state[modal],
      routeIdActive: routeId,
      turnIdModal: routeId,
    })
  }

  turn = (routeId) => {
    let defSettings = this.props.auth

    this.setState({
      title: this.props.route.title,
    })

    this.props.dispatch(routesActions.getRunDetails(routeId, defSettings, true)) //step1
    this.openModal2('turn_by_turn', routeId)
  }

  closeEditModal = () => {
    this.setState({
      editItem: !this.state.editItem,
    })
  }

  onChangeCB = () => {
    //Check BOX
    let active = this.state.route.id ? 0 : this.state.route.id
    this.setState({
      activeId: active,
    })
    this.props.onChange(this.state.route.id)
  }

  handleClose = () => {
    this.setState({
      showResult: false,
    })
  }

  handleOpenModal = () => {
    this.setState({
      email_trip: false,
      showResult: true,
    })
  }

  render() {
    const { checkedList } = this.props

    const routerPush = useRouterStore.getState()?.router?.push
    const {
      route,
      editItem,
      detailView,
      tyrn_error,
      title,
      print_trip,
      email_trip,
      showResult,
      zoomMapsOpen,
      schedule_run,
      adminFn,
      turn_by_turn,
      turnData,
      routeIdActive,
      groupName,
      activeId,
      isLoading,
    } = this.state

    return (
      <div data-item-id={route.id} className="routeItem">
        <div className="inside">
          <div className="check noclick">
            <NewCheckbox
              onChange={this.onChangeCB}
              value={route.id}
              checked={checkedList.includes(route.id) || activeId === route.id}
              disabled={isLoading}
            />
          </div>

          {!editItem ? this.info(groupName) : this.edit()}

          <div className={classnames(styles.buttons)}>
            <Button
              leftIcon={<Icon icon={'runBuilder'} />}
              buttonColor="blue"
              disabled={isLoading}
              onClick={() =>
                routerPush(`/${relativePath}/RouteTool/routes/${route.id}`)
              }
              size="sm"
            >
              Modify Run
            </Button>

            <Button
              buttonColor="blue"
              disabled={isLoading}
              onClick={() => this.turn(route.id)}
              size="sm"
              leftIcon={<Icon icon="turn" />}
            >
              Turn-by-turn
            </Button>

            <Button
              buttonColor="blue"
              disabled={isLoading}
              leftIcon={<Icon icon="checkbox" />}
              onClick={() =>
                routerPush(`/${relativePath}/RouteTool/attendance/${route.id}`)
              }
              size="sm"
            >
              Attendance
            </Button>

            <Button
              buttonColor="blue"
              disabled={isLoading}
              onClick={() => this.mapZoom(route.id)}
              size="sm"
              leftIcon={<Icon icon="search" />}
            >
              Map Zoom
            </Button>

            <Button
              buttonColor="blue"
              disabled={isLoading}
              onClick={() => this.openModal('print_trip', route.id)}
              size="sm"
              leftIcon={<Icon icon="printIcon" />}
            >
              Print Run
            </Button>

            <Button
              buttonColor="blue"
              disabled={isLoading}
              onClick={() => this.openModal('email_trip', route.id)}
              size="sm"
              leftIcon={<Icon icon={'email'} />}
            >
              Email Run
            </Button>

            {adminFn && (
              <Button
                buttonColor="blue"
                className={styles.adminButton}
                disabled={isLoading}
                onClick={() => this.openModal('schedule_run', route.id)}
                size="sm"
              >
                Run's Schedule
              </Button>
            )}
          </div>
        </div>

        <div className="details">{detailView && this.loadElements()}</div>

        {tyrn_error && (
          <ModalError id={routeIdActive} closeFn={this.openModal} />
        )}

        {turn_by_turn && (
          <ModalTyrn
            title={title}
            turnData={turnData}
            closeFn={this.openModal}
          />
        )}
        {print_trip && (
          <ModalPrint
            id={routeIdActive}
            route={route}
            closeFn={this.openModal}
          />
        )}

        {email_trip && (
          <ModalEmail
            id={routeIdActive}
            title={route.title}
            closeFn={this.openModal}
            openSuccess={this.handleOpenModal}
          />
        )}
        {showResult && (
          <Success close={this.handleClose} text={'Successfully'} />
        )}

        {zoomMapsOpen && (
          <ModalMapZoom
            zoomMapId={this.state.zoomMapId}
            closeFn={this.handleCloseZoom}
          />
        )}

        {schedule_run && (
          <ScheduleRun id={route.id} closeFn={this.handleCloseSchedule} />
        )}
      </div>
    )
  }

  handleCloseZoom = () => {
    this.setState({
      zoomMapsOpen: false,
    })
  }

  handleCloseSchedule = () => {
    this.setState({
      schedule_run: false,
    })
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    activeSeason: state.sessions.activeSeason,
    routes: state.routesView.routes,
    depots: state.auth.depots,
    drivers: state.drivers.drivers,
    buses: state.buses.buses,
    loader: state.headerLoader.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteItem)
