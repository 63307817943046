import {
  averageSpeed,
  totalTimeWithWT2,
} from '@/components/RepeatFunctions/timeWork'
import _ from 'lodash'

export const totalDistanceWithYd = (distance = 0, inMi = false) => {
  let dist = '0 mi'
  const distanceInMiles = inMi ? distance : distance / 1609.34

  if (distanceInMiles < 1) {
    dist = `${Math.round(distanceInMiles * 1760)} yd`
  } else {
    dist = `${distanceInMiles.toFixed(2)} mi`
  }
  return dist
}

export function totalInfoByOsrmWithYd(osrm, points) {
  let time = '0 min'
  let dist = '0 mi'
  let speed = '0 mph'
  let countRiders,
    cP = 0
  const s = []

  if (typeof osrm === 'object' && osrm && osrm.duration) {
    time = totalTimeWithWT2(osrm)
    dist = totalDistanceWithYd(osrm.distance)
    speed = averageSpeed(osrm.distance, osrm.duration)
  }

  _.each(points, (point) => {
    _.each(point.routePointChildren, (child) => {
      s.push(child.child_id)
    })
    if (point.type !== 6) cP += 1
  })
  countRiders = _.uniq(s).length
  return {
    time: time,
    dist: dist,
    speed: speed,
    countRiders: countRiders,
    countPoints: cP,
  }
}
