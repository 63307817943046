import React, { Component } from 'react'
import { Popover } from 'antd'
import { connect } from 'react-redux'
import * as runActions from '../../../../../../redux/routesView/actions'
import { Trans } from 'react-i18next'
import { MinusCircleOutlined } from '@ant-design/icons'
import styles from './index.module.less'
import classnames from 'classnames'

class BlockMore extends Component {
  constructor(props) {
    super(props)

    this.state = {
      over: false,
      use_default_settings: false,
      curbside_pickup: false,
    }
  }

  onMouseEvent = (e) => {
    let position = { x: e.screenX, y: e.screenY }

    let ScreenY = window.innerHeight

    let diff = Math.abs(ScreenY - position.y)

    this.setState({
      position: diff < 116 ? 'topLeft' : 'bottomLeft',
    })
  }

  HandleChecked = (e, type) => {
    let point = this.props.routePointRoute
    let data = {
      id: point.point_id,
      curbsidePickup:
        type === 'curbsidePickup'
          ? e.target.checked * 1
          : point.curbside_pickup,
      defaultSettings:
        type === 'defaultSettings'
          ? e.target.checked * 1
          : point.use_default_settings,
      routeId: point.route_id,
    }

    this.props.dispatch(runActions.editPointsSett(data))
  }

  render() {
    if (!this.props.routePointRoute) return false

    const Data = (
      <div>
        <ul
          id={this.props.isLast ? 'blockLast' : undefined}
          className={styles.root}
        >
          <li
            className={classnames(styles.listItem, styles.removeFromRun)}
            onClick={this.props.removeRun}
          >
            <MinusCircleOutlined />
            <Trans>Remove from Run</Trans>
          </li>
        </ul>
      </div>
    )

    return (
      <div className={styles.moreContainer}>
        <Popover
          overlayClassName="morePopoverOnlyDel"
          content={Data}
          placement="bottomRight"
          trigger="hover"
        >
          <span data-testid="moreButton" className="More">
            <span className="round" />
            <span className="round" />
            <span className="round" />
          </span>
        </Popover>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockMore)
