import { Popover } from 'antd'
import styles from './HelpPopover.module.less'
import { QuestionCircle } from '@/assets/icons/QuestionCircle'

export const HelpPopover = () => {
  return (
    <Popover
      placement="bottomRight"
      getPopupContainer={() => document.body}
      content={
        <div className={styles.contentRoot}>
          <div>
            <span>Toll free</span>{' '}
            <span className={styles.info}>1.888.667.6883</span>
          </div>
          <a className={styles.info} href="mailto:support@schoolbusmanager.com">
            support@schoolbusmanager.com
          </a>
        </div>
      }
    >
      <div className={styles.root}>
        <QuestionCircle />
        <span>Help</span>
      </div>
    </Popover>
  )
}
