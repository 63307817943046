import React, { useEffect, useState } from 'react'
import RunStatistic from '../RunStatistic'
import RunButtons from '../RunButtons'
import DragWrap from '../DragContent/DragWrap'
import * as runActions from '../../../../../../redux/routesView/actions'
import { childInTransfer } from '../../../../../../redux/routesView/actions'
import childStopInBus from '../../../StopsAndPinsFunctions/childStopInBus'
import { useContextBuilder } from '../../context'
import { useSelector } from 'react-redux'
import { getLoaderState } from '../../../../../../redux/common/selectors'

function ActiveRunsBlock(props) {
  let {
    routes,
    dispatch,
    activeRunsIds,
    setOpenModal,
    activeRun,
    setActiveRun,
  } = useContextBuilder()

  const loaderState = useSelector(getLoaderState)
  const [isLoading, setLoading] = useState(false)
  const isLoadingStart = loaderState === 'loadingStart'

  useEffect(() => {
    setLoading(isLoadingStart)
  }, [loaderState, isLoading])

  function childInStop(dragData) {
    if (!dragData) return
    if (dragData.combine && dragData.combine.draggableId) {
      let route_id = activeRun.id
      let destId = dragData.combine.draggableId
      let departId = dragData.draggableId

      let destPoint = activeRun.points.find((x) => x.id === parseInt(destId))
      let departPoint = activeRun.points.find(
        (x) => x.id === parseInt(departId)
      )

      // rule STOP Point into TRANSFER POINT
      if (destPoint.type === 5 && departPoint.type === 2) {
        departPoint.routePointChildren.forEach((x) => {
          let copyF = destPoint.routePointTransferChild.findIndex(
            (f) => f.child_address_id === x.child_address_id
          )
          if (copyF > -1) return false
          // link_id

          let data = {
            routeId: route_id,
            pointId: destPoint.id,

            childAddressId: x.child_address_id,
          }

          dispatch(childInTransfer(data))
        })
      }

      // rules   stop point + child point
      if (destPoint.type === 2 && departPoint.type === 1) {
        dispatch(childStopInBus(parseInt(departId), route_id, parseInt(destId)))
      }

      if (destPoint.type === 5 && departPoint.type === 1) {
        if (destPoint.position < departPoint.position) {
          setOpenModal(`modalInfoError`)
          return false
        }
        console.log(
          'departPoint.routePointChildren[0]',
          departPoint.routePointChildren[0]
        )
        let data = {
          routeId: route_id,
          pointId: destPoint.id,
          childAddressId:
            activeRun.is_morning === 1
              ? departPoint.routePointChildren[0].child_address_id
              : departPoint.routePointChildren[0]?.childaddress?.link_id,

          deletePoint_: departPoint.id,
        }
        dispatch(childInTransfer(data))

        dispatch(
          runActions.runReload(
            destPoint.routePointRoutes.find(
              (x) => x.route_id !== destPoint.routeId
            ).route_id
          )
        )
      }
    }
  }

  function setNewPositions(points) {
    let type4 = 0
    let prev = false
    let prevCount = 0
    return points.map((x, i) => {
      x.position = i + 1
      x.routePointRoute.position = i + 1
      x.routePointRoute.view_position = i - type4

      if (x.type === 4) {
        x.routePointRoute.view_position =
          i - type4 - 1 + (prevCount ? (prevCount + 1) / 10 : 0.1)
        prevCount += 1
        prev = true
        type4 += 1
      } else {
        prev = false
        prevCount = 0
      }

      return x
    })
  }

  let onDragEnd = (dragData) => {
    if (isLoading) return
    if (!dragData.destination) {
      childInStop(dragData)
      return false
    }

    if (dragData.source.index === dragData.destination.index) return false

    let routeId = activeRun.id
    let run = activeRun

    let idElement = dragData.draggableId
    let newPosition = dragData.destination.index
    let inStop = false
    let child_address_id = false

    /* ПРАВИЛО сначала забираем ребенка потом можем добавлять его в транс остановку */
    let points = [...run.points]

    let sourceI = dragData.source.index
    let destI = dragData.destination.index

    points.splice(sourceI, 1)
    points.splice(destI, 0, run.points[dragData.source.index])

    points = setNewPositions(points)

    let findT5 = points.findIndex((p) => p.type === 5)

    if (findT5 < -1) {
      let ridersInRun = []
      let error = false

      points.forEach((p) => {
        p.routePointTransferChild.forEach((tr) => {
          if (tr.childAddress.type === 1) {
            let find = ridersInRun.findIndex(
              (x) => x === tr.childAddress.child.id
            )
            if (find > -1) {
              ridersInRun.splice(find, 1)
            } else {
              error = true
            }
          }
        })
        p.routePointChildren.forEach((a) => ridersInRun.push(a.child_id))
      })
      if (error) {
        setOpenModal(`modalInfoError`)
        return false
      }
    }
    /* КОНЕЦ ПРАВИЛА */

    let data = {
      route_id: routeId,
      point_id: idElement,
      position: newPosition + 1,
      bus_stop: inStop,
    }

    dispatch(runActions.changePosition(data))

    let localData = { ...activeRun, points: [...points], changePosition: true }
    setActiveRun(localData)
  }

  if (!activeRun) return <div />

  return (
    <div className="overflow">
      <div className="activeInfo activeInfoRT">
        <RunStatistic />

        <RunButtons route={activeRun} activeRunsIds={activeRunsIds} />
      </div>

      <div className="overflow">
        <DragWrap
          onDragEnd={onDragEnd}
          removeStopFromRun={props.removeStopFromRun}
          removeChildFromStop={props.removeChildFromStop}
        />
      </div>
    </div>
  )
}

export default ActiveRunsBlock
