import moment from 'moment'
import 'moment-timezone'
import * as Leaflet from 'leaflet'

export const getTimestampWithOffset = (
  ts: string | number,
  timeZone: string
) => {
  if (!ts || !timeZone || !moment.tz.zone(timeZone)) return null
  const timezoneMoment = moment(+ts * 1000).tz(timeZone)
  const offset = timezoneMoment.utcOffset()
  return +ts + offset * 60
}

/**
 * Convert timestamp to readable string
 * @param timestamp in seconds
 * @param timeZone to make offset
 * @param format default 'hh:mm A'
 * @param fallback string to return if no timestamp provided, default 'Unknown'
 */
export const getTimeStringWithFormat = (
  timestamp: number | null,
  timeZone: string,
  format = 'hh:mm A',
  fallback = 'Unknown'
) =>
  timestamp
    ? moment(getTimestampWithOffset(timestamp, timeZone) * 1000)
        .utc()
        .format(format)
    : fallback

export const handleMouseOut = (e: Leaflet.LeafletMouseEvent) => {
  if (e.originalEvent.offsetY > 0) {
    e.sourceTarget.closePopup()
  }
}

export const handleMouseOver = (e: Leaflet.LeafletMouseEvent) => {
  if (e.sourceTarget.options.id === 'bus') {
    return
  }
  e.sourceTarget.openPopup()

  const el = document.querySelectorAll('.leaflet-popup')[0]

  if (el) {
    el.addEventListener('mouseleave', function () {
      e.sourceTarget.closePopup()
    })
  }
}

export const getRouteMarkerType = (type: number) => {
  switch (type) {
    case 2:
      return 'b'
    case 3:
      return 'school'
    case 4:
      return 'circle'
    case 5:
      return 'transfer'
    case 6:
      return 'dpt'
  }
}

export const getRunTotalHours = (start: number, end: number) => {
  const hourRunStarted = moment(start * 1000)
    .startOf('hour')
    .unix()
  const hourRunFinished = moment(end * 1000)
    .endOf('hour')
    .unix()

  return Math.ceil((hourRunFinished - hourRunStarted) / 3600)
}
