import {childInBus} from '../../../../redux/routesView/actions'


export default function (point_id, route_id , bus_stop_point_id ) {

  let data = {
    childPointId: point_id,
    routeId: route_id,
    busStopPointId: bus_stop_point_id
  };

  return childInBus(data)
}