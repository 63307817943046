import { create } from 'zustand'
import { browserHistory } from 'react-router'
import { useLayoutEffect, useMemo } from 'react'
import { shallow } from 'zustand/shallow'

export interface IRouterStore {
  router: {
    push?: (route: string) => void
    replace?: (route: string) => void
    params?: Record<string, string | number>
    query?: Record<string, string>
    pathName?: string
  }
  setRouter: (router: Partial<IRouterStore['router']>) => void
}

export const useRouterStore = create<IRouterStore>((set, get) => ({
  router: undefined,
  setRouter: (routerData) =>
    set({ router: { ...(get().router ?? {}), ...(routerData ?? {}) } }),
}))

export const RootRouter = (params) => {
  useRootRouter(params)
  return <>{params.children}</>
}

export const useRootRouter = ({
  location,
  params,
}: {
  location: any
  params: any
}) => {
  const setRouter = useRouterStore.getState().setRouter
  const browser = browserHistory

  useLayoutEffect(() => {
    setRouter({
      push: browser.push,
      replace: browser.replace,
      pathName: location.pathname,
      query: location.query,
      params: params,
    })
  }, [
    browser.push,
    browser.replace,
    location.pathname,
    location.query,
    params,
    setRouter,
  ])
}

export const useRouter = () => {
  const params = useRouterStore((state) => state.router, shallow)
  return useMemo(
    () => ({
      push: params?.push ?? browserHistory.push,
      replace: params?.replace ?? browserHistory.replace,
      pathName: params?.pathName ?? browserHistory?.location?.pathname,
      query: params?.query,
      params: params?.params,
    }),
    [
      params?.params,
      params?.pathName,
      params?.push,
      params?.query,
      params?.replace,
    ]
  )
}

export const getPathname = () => useRouterStore.getState().router.pathName
