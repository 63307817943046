import React, { Component } from "react";
import { connect } from "react-redux";

import Checkbox from "antd/es/checkbox";
import moment from "moment";
import { NewCheckbox } from "../../../../../entities/ui/NewCheckbox/NewCheckbox";

class TripDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  dateString = dateParam => {
    let date = new Date(dateParam * 1000);
    return moment(date).format("MM/DD/YYYY LT");
  };

  returnDate = time => {
    return moment(time * 1000).format("MM/DD/YYYY");
  };

  render() {
    let details = this.props.data;
    let depart = details.fieldTripRequestDestinations.filter(i => i.type === 1);
    let destination = details.fieldTripRequestDestinations.filter(
      i => i.type === 2
    );
    return (
      <div>
        <div className="row details-block">
          <div className="row-heading">
            <h4>Requester information:</h4>
          </div>

          <div className="info-row">
            <span className="info-row-label">Name</span>
            <span className="info-row-value">{details.requestor_name}</span>
          </div>

          <div className="info-row">
            <span className="info-row-label">Phone</span>
            <span className="info-row-value">
              {details.requestor_phone.replace(/ /g, "-")}
            </span>
          </div>

          <div className="info-row">
            <span className="info-row-label">Email</span>
            <span className="info-row-value">{details.requestor_email}</span>
          </div>
        </div>

        <div className="row details-block">
          <div className="row-heading">
            <h4>Trip Description</h4>
          </div>

          <div className="info-row">
            <span className="info-row-label">Name</span>
            <span className="info-row-value">{details.name}</span>
          </div>

          <div className="info-row">
            <span className="info-row-label">Trip ID</span>
            <span className="info-row-value">{details.id}</span>
          </div>

          {details.description && (
            <div className="info-row info-row-block">
              <span className="info-row-label height16">
                Description or Purpose
              </span>
              <span className="info-row-value">{details.description}</span>
            </div>
          )}

          {details.special_note_request && (
            <div className="info-row info-row-block">
              <span className="info-row-label height16">
                Special Requests or Notes
              </span>
              <span className="info-row-value">
                {details.special_note_request}
              </span>
            </div>
          )}
        </div>

        <div className="row details-block">
          <div className="row-heading">
            <h4>Riders</h4>
          </div>

          <div className="info-row">
            <span className="info-row-label">Riders (kids)</span>
            <span className="info-row-value">{details.count_kids}</span>
          </div>

          <div className="info-row">
            <span className="info-row-label">
              Adults (staff and chaperones)
            </span>
            <span className="info-row-value">{details.count_adults}</span>
          </div>

          <div className="info-row">
            <span className="info-row-label">
              <NewCheckbox checked={details.special_needs} />
            </span>
            <span className="info-row-value">Some with Special Needs</span>
          </div>
        </div>

        <div className="row details-block details-block-double">
          <div className="row-heading">
            <h4>Trip Request</h4>
          </div>

          {depart.map((d, i) => {
            return (
              <div key={d.id}>
                <div className="info-row">
                  <span className="info-row-label">Departure {i + 1}</span>
                  <span className="info-row-value">{d.address}</span>
                </div>

                {!!d.date && (
                  <div className="info-row space">
                    <span className="info-row-label">
                      Departure Date and Time
                    </span>
                    <span className="info-row-value">
                      {this.dateString(d.date)}
                    </span>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="row details-block details-block-double">
          {destination.map((d, i) => {
            return (
              <div key={d.id}>
                <div className="info-row">
                  <span className="info-row-label">Destination {i + 1}</span>
                  <span className="info-row-value">{d.address}</span>
                </div>

                {!!d.date && (
                  <div className="info-row space">
                    <span className="info-row-label">
                      {d.time_type === 1 ? "Departure" : "Arrival"} Date and
                      Time
                    </span>
                    <span className="info-row-value">
                      {this.dateString(d.date)}
                    </span>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {!!details.is_round_trip && (
          <div className="row details-block">
            <div className="info-row">
              <span className="info-row-label">
                <NewCheckbox className={"pointer"} checked={true} />
              </span>
              <span className="info-row-value">Return Trip</span>
            </div>

            <div className="info-row">
              <span className="info-row-label">Return Date</span>
              <span className="info-row-value">
                {this.returnDate(details.date_round_trip)}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TripDetails);
