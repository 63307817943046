import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { ActiveMenu } from '@/redux/sessions/actions'

import * as childrenActions from '../../redux/children/actions'
import * as routesActions from '../../redux/routesView/actions'

import _ from 'lodash'
import { browserHistory } from 'react-router'
import { useDebouncedCallback } from 'use-debounce'

export let Context = React.createContext()

export let useContextRoutesView = () => {
  return useContext(Context)
}

function RoutesViewContext(props) {
  let { childrenList, activeSeason, children, dispatch, userPermissions } =
    props

  const routesAllByGroup = props?.routesAllByGroup ?? []

  const [routesGroups, setRoutesGroups] = useState([])
  const activeSeasonRef = useRef()
  let access = userPermissions?.write_routes ?? 0

  useEffect(() => {
    let sessionsID = localStorage.getItem('ActiveSessionId')

    if (props.params && props.params.id) {
      sessionsID = props.params.id
      localStorage.setItem('ActiveSessionId', props.params.id)
    }

    dispatch(ActiveMenu(sessionsID))
    dispatch(childrenActions.getChildren(sessionsID))
    dispatch(routesActions.getRoutes(sessionsID))

    if (activeSeason.title) activeSeasonRef.current = activeSeason
  }, [])

  const callback = useDebouncedCallback(() => {
    if (
      activeSeasonRef.current &&
      activeSeasonRef.current.id !== activeSeason.id
    ) {
      browserHistory.push(`/client/RouteTool/routes-view/` + activeSeason.id)

      activeSeasonRef.current = activeSeason

      let sessionsID = activeSeason.id
      dispatch(ActiveMenu(sessionsID))
      dispatch(childrenActions.getChildren(sessionsID))
      dispatch(routesActions.getRoutes(sessionsID))
    }
  }, 0)

  useEffect(() => {
    callback()
  }, [activeSeason])

  useEffect(() => {
    let sortList

    let sessId = 0
    let reload = (routesAllByGroup || [])?.find((x) => {
      sessId = x.season_id
      return x.update
    })

    if (reload && sessId) {
      dispatch(routesActions.getRoutes(sessId))
      return
    }

    sortList = routesAllByGroup.map((gr) => {
      gr.routes = _.sortBy(gr.routes, ['title'], ['asc'])
      return gr
    })
    setRoutesGroups(sortList)
  }, [routesAllByGroup])

  return (
    <Context.Provider
      value={{
        routesGroups,
        childrenList,
        access,
        dispatch,
      }}
    >
      {children}
    </Context.Provider>
  )
}

function mapStateToProps(state) {
  return {
    childrenList: state.children.childrenList,
    routesAllByGroup: state.routesView.routes,
    activeSeason: state.sessions.activeSeason,
    userPermissions: state.auth.permissions,
  }
}

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutesViewContext)
