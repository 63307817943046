import { create } from 'zustand'
import { createSelector } from 'reselect'

interface ITransCatStore {
  normalizeSelected: Record<string, string>
  setSelect: (name: string) => void
  checkAll: (data: string[]) => void
  reset: () => void
}

export const useTransCatStore = create<ITransCatStore>((set, get) => ({
  normalizeSelected: {},
  reset: () => {
    set({ normalizeSelected: {} })
  },
  checkAll: (data) => {
    if (data.length === Object.keys(get().normalizeSelected).length) {
      set({
        normalizeSelected: {},
      })
    } else {
      set({
        normalizeSelected: data.reduce(
          (acc, item) => ({ ...acc, [item]: item }),
          {}
        ),
      })
    }
  },
  setSelect: (name) => {
    const normalize = get().normalizeSelected
    if (!normalize?.[name]) {
      set({ normalizeSelected: { ...normalize, [name]: name } })
    } else {
      const value = { ...get().normalizeSelected }
      delete value[name]
      set({ normalizeSelected: value })
    }
  },
}))

const selectNormalizeSelected = (state: ITransCatStore) =>
  state.normalizeSelected

export const selectedSelector = createSelector(
  [selectNormalizeSelected, (_, name: string) => name],
  (normalizeSelected, name) => normalizeSelected?.[name]
)

export const checkedLengthSelector = createSelector(
  [selectNormalizeSelected],
  (normalizeSelected) => Object.keys(normalizeSelected ?? {}).length
)

export const useZustandSelector = <T>(
  selector: (state: ITransCatStore, ...args: any[]) => T,
  ...args: any[]
): T => {
  return useTransCatStore((state) => selector(state, ...args))
}
