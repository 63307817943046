import { createSelector } from 'reselect'
import _ from 'lodash'
import { sortItemsByNumAbc } from '@/helpers/arrayWork'
import { GpsState } from '@/redux/gps/reducers'

export const getGps = (state) => state.gps as GpsState
export const getAuth = (state) => state.auth

export const getGpsRun = createSelector(getGps, (state: GpsState) => {
  return state.gpsRun
})
export const getGpsRuns = createSelector(getGps, (state: GpsState) =>
  state.gpsRuns.filter((x) => !!x.gps)
)
export const getCompanyId = createSelector(getAuth, (state) => state.company.id)

export const getRidersFromGpsRun = createSelector(getGpsRun, (state) => {
  const riders = state?.points?.flatMap((x) =>
    x.riders.map((item) => ({
      id: item.id,
      name: item.fullName,
    }))
  )

  const uniqueRiders = _.uniqBy(riders, 'id')
  return uniqueRiders.sort(sortItemsByNumAbc)
})
