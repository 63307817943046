export const QuestionCircle = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M7.988 0A7.987 7.987 0 0 0 0 7.988a7.987 7.987 0 0 0 7.988 7.988 7.987 7.987 0 0 0 7.988-7.988C15.976 3.576 12.379 0 7.988 0zm0 15.201c-3.973 0-7.214-3.24-7.214-7.213a7.214 7.214 0 0 1 14.427 0c0 3.973-3.24 7.213-7.213 7.213zm.335-3.345a.419.419 0 1 1-.838-.001.419.419 0 0 1 .838.001zm1.735-5.688c0 .732-.419 1.402-1.193 1.86-.565.336-.544.732-.544.754v1.004c0 .21-.167.376-.376.376s-.397-.166-.397-.376v-.94c-.02-.335.148-.984.899-1.443.375-.23.815-.627.815-1.212 0-.71-.585-1.297-1.297-1.297-.713 0-1.297.585-1.297 1.297 0 .21-.167.376-.376.376a.373.373 0 0 1-.376-.376c0-1.15.92-2.07 2.07-2.07s2.072.897 2.072 2.047z"
      />
    </svg>
  )
}
