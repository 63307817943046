import React, { Component } from 'react'

import Col from 'antd/es/col'
import Row from 'antd/es/row'
import { Trans } from 'react-i18next'

import './index.less'

import DropOff from '../DropOff/index'
import { totalDist, totalTime } from '@/components/RepeatFunctions/timeWork'
import anchorAndTimeFN from '../../../../../Run/components/BuildRun/components/anchorAndTime'

class StopPoint extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
      PickUp: 'show',
      DropOff: 'show',
    }
  }

  render() {
    let { id, object, address, busStopTitle, routePointChildren, child } =
      this.state
    let position = this.state.routePointRoute.view_position
    let { pointOsrmLeg, route } = this.props

    let distance = null
    let duration = null

    if (pointOsrmLeg && pointOsrmLeg.time) {
      if (
        pointOsrmLeg.distance !== undefined &&
        pointOsrmLeg.duration !== undefined
      ) {
        distance = totalDist(pointOsrmLeg.distance)
        duration = totalTime(pointOsrmLeg.duration)
      }
    }

    return (
      <div key={id} className="Point">
        <span className="backgroundLeft" />
        <div className="StopPoint">
          {busStopTitle && (
            <Row style={{ width: '100%' }} className="MarginBlock noMarginTop">
              <Col span={24} className="fullName">
                {position}. {busStopTitle}
              </Col>
            </Row>
          )}

          <Row style={{ width: '100%' }} className="marginTopEight">
            <Col span={12} className={'dist'}>
              {distance && duration && (
                <div>
                  <span>{distance}</span>
                  <span>{duration}</span>
                </div>
              )}
            </Col>
            <Col span={24} className={'waitArr'}>
              {anchorAndTimeFN.bind(this)()}
            </Col>
          </Row>

          <Row
            className="Info"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {this.props.first && (
              <Row style={{ width: '100%' }}>
                <Col span={24} className="text">
                  <Trans>The Run Starts from here</Trans>
                </Col>
              </Row>
            )}

            {address && address.address && (
              <Row style={{ width: '100%' }} className="MarginBlock">
                <Col
                  span={24}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <span className="Address noIcon">
                    <Trans>Address</Trans>
                  </span>
                  {address.lat.substr(0, 10)} {address.lon.substr(0, 10)}
                </Col>
              </Row>
            )}

            <Row style={{ width: '100%' }} className="MarginBlock">
              <Col span={24}>
                <div className="firstLine">
                  <div style={{ width: '100%' }}>
                    <DropOff
                      routePointChildren={routePointChildren}
                      id={id}
                      phone={true}
                      object={object}
                      child={child}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    )
  }
}

export default StopPoint
