import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { getRidersFromGpsRun } from '@/redux/gps/selectors'
import { getLoaderState } from '@/redux/common/selectors'
import { NewSelect } from '@/entities/ui/NewSelect/NewSelect'

type RidersSelectProps = {
  selected: string | number
  onChange: (name: string) => void
}

export const RidersSelect: FC<RidersSelectProps> = ({ selected, onChange }) => {
  const riders = useSelector(getRidersFromGpsRun)
  const isLoading = useSelector(getLoaderState) === 'loadingStart'

  const options = [
    { value: 0, label: 'Search for a Rider' },
    ...riders.map((x) => ({ value: x.name, label: x.name })),
  ]

  return (
    <NewSelect
      style={{ width: 283 }}
      showSearch
      allowClear
      placeholder="Search for a Rider"
      optionFilterProp="children"
      onChange={(e) => !isLoading && onChange(e)}
      value={selected}
      disabled={isLoading}
      options={options}
    />
  )
}
