import moment from 'moment'
export const dateFormat = 'MM/DD/YYYY'

export const getInitParams = () => {
  const sun = moment().weekday(0).unix()
  const sat = moment().weekday(6).unix()

  const range =
    moment(sun * 1000).format(dateFormat) +
    ' - ' +
    moment(sat * 1000).format(dateFormat)
  return { sun, sat, range }
}
