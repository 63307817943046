import { useImportRidersStore } from '@/entities/modals/RidersImportModal/RidersImportModal'
import { useCallback, useMemo } from 'react'
import { shallow } from 'zustand/shallow'
import { useLazyAddFileQuery } from '@/store/routeTool/routeTool'
import { useImportField } from '@/entities/modals/RidersImportModal/components/MappingStep/MappingStep.constants'

export const useMappingStepConfig = (sessionId?: number) => {
  const resp = useImportRidersStore((state) => state.importResponse, shallow)
  const { fieldKeys } = useImportField()
  const isXlsx = resp.file.name.includes('.xls')

  const [request, response] = useLazyAddFileQuery()
  const defaultValues = useMemo(() => {
    if (isXlsx) {
      return Object.keys(resp.xlsxDefault.mappingForm).reduce((acc, item) => {
        const target = resp.xlsxDefault.mappingForm
        return {
          ...acc,
          [item]: target?.[item] ?? [],
        }
      }, {})
    } else {
      const target = resp.mappingForm || {}
      const keys = Object.keys(target)
      return keys.reduce(
        (acc, key) => ({ ...acc, [key]: target?.[key] ?? [] }),
        {}
      )
    }
  }, [isXlsx, resp.mappingForm, resp?.xlsxDefault?.mappingForm])

  const options = useMemo(
    () =>
      isXlsx
        ? Object.keys(resp?.xlsxDefault?.headers)
            .map((el) => ({ label: resp.xlsxDefault.headers[el], value: el }))
            .filter((el) => el.label && el.value)
        : (resp.headers || [])
            ?.filter((el) => !!el)
            ?.map((label, index) => ({ label, value: index })),
    [isXlsx, resp.headers, resp?.xlsxDefault?.headers]
  )

  const handleSubmit = useCallback(
    (data: Record<string, number | number[]> & { forceCheckbox: boolean }) => {
      const { force_override_address, ...rest } = data

      const MappingForm = {}

      Object.keys(rest).forEach((key) => {
        if (!fieldKeys.includes(key)) {
          return
        }
        if (typeof rest[key] === 'number') {
          MappingForm[key] = rest[key]
        } else if ((rest[key] as number[])?.length > 0) {
          MappingForm[key] =
            (rest[key] as number[]).length === 1 ? rest[key][0] : rest[key]
        }
      })

      const result = {
        MappingForm,
        force_override_address: force_override_address ? 1 : 0,
      }

      request({ data: result, sessionId })
    },
    [fieldKeys, request, sessionId]
  )

  const isLoading = useMemo(() => {
    if (response.isFetching) {
      return true
    } else return response.isSuccess
  }, [response.isFetching, response.isSuccess])

  return useMemo(
    () => ({
      defaultValues,
      options,
      handleSubmit,
      isLoading,
    }),
    [defaultValues, handleSubmit, isLoading, options]
  )
}
