import { FormProvider, useForm } from 'react-hook-form'
import { FormInput } from '@/entities/ui/NewInput/FormInput'
import { ModalFooter } from '@/entities/ui/NewModal/components/ModalFooter'
import styles from './AddNewSession.module.less'
import { useAddSessionMutation } from '@/store/session/sessionApi'

export const AddNewSession = () => {
  const methods = useForm({
    defaultValues: {
      school_year: new Date().getFullYear().toString(),
    },
  })

  const [requestSession, responseSession] = useAddSessionMutation()

  const onSubmit = (data) => {
    requestSession(data)
  }

  return (
    <FormProvider {...methods}>
      <form
        data-testid={'addNewSessionModal'}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <FormInput
          rules={{
            required: {
              value: true,
              message: 'Session name field is required',
            },
          }}
          label="Session name"
          placeholder="Session name"
          data-testid="sessionNameInp"
          name="title"
          type="text"
        />
        <FormInput
          name="school_year"
          rules={{
            required: {
              value: true,
              message: 'Year of Session field is required',
            },
            validate: (v: string) => {
              if ((v ?? '').length === 4) {
                return true
              } else {
                return 'Incorrect year'
              }
            },
          }}
          maxLength={4}
          wrapperClassName={styles.yearInput}
          label="Year of Session"
          type="number"
          placeholder="Year of Session"
          data-testid="yearOfSession"
        />
        <ModalFooter
          buttonWidth={90}
          success={{
            text: 'Save',
            isLoading: responseSession.isLoading,
            testId: 'saveSessionBtn',
            isSubmitForm: true,
          }}
        />
      </form>
    </FormProvider>
  )
}
