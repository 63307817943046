import { IRegistrationItem } from '@/store/routeTool/routeTool.types'
import styles from './MainScreen.module.less'
import { Fragment } from 'react'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { InfoBlock } from '@/entities/modals/RouteToolRegistrations/RTRegistrationsOpenedModal/components/InfoBlock/InfoBlock'
import { useRTRegistrationsStore } from '@/entities/modals/RouteToolRegistrations/RTRegistrationsOpenedModal/store'

export const MainScreen = (item: IRegistrationItem) => {
  const setScreen = useRTRegistrationsStore((state) => state.setScreen)

  const firstGuardianSummary = [
    item?.payload?.firstGuardian,
    item?.payload?.firstGuardianEmail,
    item?.payload?.firstGuardianPhone,
    item?.payload?.firstGuardianWorkPhone,
  ]
    .filter((item) => !!item)
    .join(', ')

  const secondGuardianSummary = [
    item.payload?.secondGuardian,
    item.payload?.secondGuardianEmail,
    item.payload?.secondGuardianPhone,
    item.payload?.secondGuardianWorkPhone,
  ]
    .filter((item) => !!item)
    .join(', ')

  const emergencyContactSummary = [
    item.payload?.emergencyContact,
    item.payload?.emergencyContactEmail,
    item.payload?.emergencyContactPhone,
    item.payload?.emergencyContactWorkPhone,
  ]
    .filter((item) => !!item)
    .join(', ')

  const children = []
  item.payload?.children?.forEach((child) => {
    if (!children[child.cid]) {
      children[child.cid] = []
    }
    children[child.cid].push(child)
  })

  return (
    <>
      {' '}
      {(firstGuardianSummary ||
        secondGuardianSummary ||
        emergencyContactSummary) && (
        <div className={styles.firstBlockWrapper}>
          {firstGuardianSummary && (
            <InfoBlock title="First Guardian" subtitle={firstGuardianSummary} />
          )}
          {secondGuardianSummary && (
            <InfoBlock
              title="Second Guardian"
              subtitle={secondGuardianSummary}
            />
          )}
          {emergencyContactSummary && (
            <InfoBlock
              title="Emergency Contact"
              subtitle={emergencyContactSummary}
            />
          )}
        </div>
      )}
      {Object.keys(children).map((cid, index) => (
        <div className={styles.wrapper} key={index}>
          <InfoBlock title="CID" subtitle={cid} />
          {children?.[cid]?.map((child) => (
            <Fragment key={child.id}>
              {child.type === 1 && (
                <>
                  <InfoBlock title="Pick Up Address" subtitle={child.address} />
                  <InfoBlock
                    title="Pick Up School"
                    subtitle={child.schoolName}
                  />
                </>
              )}

              {child.type === 2 && (
                <>
                  <InfoBlock
                    title="Drop Off Address"
                    subtitle={child.address}
                  />
                  <InfoBlock
                    title="Drop Off School"
                    subtitle={child.schoolName}
                  />
                </>
              )}
            </Fragment>
          ))}
        </div>
      ))}
      {[1, 4].includes(item.status) && (
        <div className={styles.modalFooter}>
          <Button onClick={() => setScreen('reject')} buttonColor="red">
            Reject
          </Button>
          <Button onClick={() => setScreen('manualAccept')} buttonColor="green">
            Manual Accept
          </Button>
          <Button onClick={() => setScreen('autoAccept')} buttonColor="green">
            Auto Accept
          </Button>
        </div>
      )}
    </>
  )
}
