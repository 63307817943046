import * as types from './actionTypes'
import _ from 'lodash'

import moment from 'moment'
import { isArray } from 'react-nestable/dist/utils'
import { RUN_EDIT_POINT_FLAG_NAME } from './actionTypes'

const initialState = {
  routes: [],
  activeRuns: [],
  osrm: {},
  activeGPS: [],
  gpsActiveRun: {},
  errorOptimize: false,
  addressGeo: {},
  loading: false,
  stopInfo: [],
}

function deletePoint(id, points) {
  points = _.orderBy(points, 'position')
  let pointsNew = points.filter((item) => {
    return item.id !== id
  })
  let i = 1
  pointsNew = _.map(pointsNew, (point) => {
    point.position = i
    i++
    return point
  })

  return pointsNew
}

function addPoint(point, points) {
  const position = point.position
  points = _.map(points, (p) => {
    if (p.position >= position) {
      p.position++
    }
    return p
  })
  points.push(point)
  points = _.orderBy(points, 'position')
  return points
}

function getRouteByID(runId, state) {
  let route = {}
  state.routes.forEach((gr) => {
    gr.routes.forEach((r) => (r.id === runId ? (route = { ...r }) : ''))
  })
  return route
}

function GetTime(Min, defaults, index) {
  let startDate
  if (index === 0) {
    let str = moment()
      .subtract(1, 'days')
      .endOf('day')
      .add(Min, 'minutes')
      .add(1, 'minutes')
      .format('hh:mm A')

    return str
  } else {
    let startDate = moment(defaults, 'hh:mm A')
    return startDate.add(Min, 'minutes').format('hh:mm A')
  }
}

function routsInject(copyArray, routeA) {
  copyArray.forEach((gr) => {
    gr.routes.forEach((r) => {
      if (r.id === routeA.id) {
        r.points = routeA.points
        r.osrm = null
        r.reload = routeA.reload
      }
    })
  })
  return copyArray
}

export default (state = initialState, action) => {
  let routeA = {}

  if (action.runId) {
    routeA = getRouteByID(action.runId, { ...state })
  }

  switch (action.type) {
    case types.ROUTE_GET_ITEMS: {
      const items = action.payload.filter((i) => i.routes.length > 0)
      const routes = _.orderBy(items, 'title')

      return {
        ...state,
        routes: routes,
      }
    }

    case types.ROUTE_GET_ITEMS_GPS: {
      state.activeGPS = action.payload
      return _.cloneDeep(state)
    }

    case types.ROUTE_GROUP_ADD: {
      return {
        ...state,
        routes: state.routes.map((item) => ({
          ...item,
          update: true,
        })),
      }
    }

    case types.GEOCODER_ADDRESS: {
      const items = action.payload

      let keys = action.keys

      let newArr = { ...state }

      if (items.lat === '' && items.lon === '') {
        items.keys.push(keys)
      } else {
        items.deleteId = keys
      }

      newArr.geo = []

      newArr.geo.push(items)

      return _.cloneDeep(newArr)
    }

    case types.ROUTE_GROUP_EDIT: {
      const payload = action.payload

      return {
        ...state,
        routes: state.routes.map((item) => ({
          ...item,
          update: true,
          title: item.id === payload.id ? payload.title : item.title,
        })),
      }
    }

    case types.ROUTE_GET_DETAILS_LOADING: {
      return {
        ...state,
        loading: action.payload,
      }
    }

    case types.ROUTE_GET_DETAILS: {
      const response = action.payload
      if (isArray(state.routes) && state.routes.length === 0)
        return action.payload

      let newArr = { ...state }
      newArr.routes = newArr.routes.filter((routeGr) => {
        if (routeGr.id === response.routegroup_id) {
          return routeGr.routes.filter((i) => {
            if (i.id === response.id) {
              i['points'] = _.orderBy(response.points, 'position')
              i['osrm'] = response.osrm ? response.osrm : null
              i['time_zone'] = response.time_zone
              i.reload = null
            } else {
              return true
            }
            return false
          })
        } else {
          return true
        }
      })
      return _.cloneDeep(newArr)
    }

    case types.ROUTE_GET_DETAILS_GPS: {
      let newArr = { ...state }
      newArr.gpsActiveRun = action.payload
      return _.cloneDeep(newArr)
    }

    case types.OSRM: {
      return state
    }

    case types.ROUTE_ADD_ITEM: {
      const item = action.payload
      item.newTripFlag = true
      let newArr = { ...state }
      let count = _.findIndex(newArr.routes, (i) => i.id === item.routegroup_id)
      if (count > -1) {
        let routes = newArr.routes[count].routes
        routes.forEach((x) => (x.newTripFlag = false))

        routes.push({
          ...item,
          reloadBuilder: true,
          active: true,
        })
      } else {
        newArr.routes.push({
          id: item.routegroup_id,
          routes: [{ ...item, reloadBuilder: true, active: true }],
        })
      }
      return _.cloneDeep(newArr)
    }
    case types.ROUTE_EDIT_ITEM: {
      const editItem = action.payload
      let { id, groupName } = editItem

      let parentState = { ...state }
      let newArr = parentState.routes

      let result = newArr.find((r) => {
        return r.routes.find(
          (item) => item.id === id && item.groupName === groupName
        )
      })
      if (result) {
        newArr.forEach((r) => {
          r.routes.forEach((item, i) => {
            if (item.id === id && item.groupName === groupName) {
              r.routes[i] = { ...item, ...editItem }
              if (
                item.no_uturns !== editItem.no_uturns ||
                item.curbside_pickup !== editItem.curbside_pickup ||
                item.use_default_rules !== editItem.use_default_rules
              ) {
                r.routes[i].reload = true
              }
            }
          })
        })
      } else {
        let add = 0
        newArr.forEach((r) => {
          r.routes.forEach((item, i) => {
            if (item.id === id && item.groupName !== groupName) {
              r.routes.splice(i, 1)
            } else if (item.groupName === groupName) {
              if (!add) {
                r.routes.push(editItem)
              }
              add++
            }
          })
        })
      }

      parentState.routes = newArr
      return _.cloneDeep(parentState)
    }
    case types.ROUTE_REMOVE_ITEM: {
      const id = action.payload
      state.routes = state.routes.filter((group) => {
        group.routes = group.routes.filter((item) => {
          return item.id !== id
        })
        if (group.routes.length > 0) {
          return true
        }
      })
      return _.cloneDeep(state)
    }
    case types.ROUTE_REMOVE_ITEMS: {
      const ids = action.payload

      state.routes = state.routes.filter((group) => {
        group.routes = group.routes.filter((item) => {
          return !ids.includes(item.id)
        })
        if (group.routes.length > 0) {
          return true
        }
      })
      return _.cloneDeep(state)
    }

    case types.RUN_VALID_ADDRESS: {
      const error = action.payload.valid
      const data = action.payload.data
      let newArr = { ...state }

      if (!error && typeof error !== 'undefined') {
        newArr.ValidAddress = error
      } else {
        newArr.ValidAddress = { valid: true, data: data }
      }
      return newArr
    }

    /** **********************RUN**************************************/

    case types.ROUTE_EDIT: {
      let position = action.payload.position
      let secondRun = action.secondRun

      let routeC = routeA.points.map((p) => {
        if (p.id === action.payload.id) {
          return action.payload
        } else {
          return p
        }
      })

      let points = _.orderBy(routeC, 'position')
      routeA.points = points

      let newArr = { ...state }

      routeA.osrm = ''

      newArr.routes = routsInject(newArr.routes, routeA)
      newArr.changedPosition = action.payload.address

      // чтобы osrm перерисовался когда дело касается трансферной остановки.
      if (secondRun) {
        let routeByID = getRouteByID(secondRun, state)
        routeByID.osrm = ''
        newArr.routes = routsInject(newArr.routes, routeByID)
      }

      return _.cloneDeep(newArr)
    }

    case types.RUN_GET_ITEMS: {
      let points = _.orderBy(action.payload.points, 'position')
      let newArr = { ...action.payload }
      newArr.points = points
      return newArr
    }
    case types.RUN_DELETE_POSITION: {
      // const id = action.payload;

      let newArr = { ...state }
      routeA.osrm = ''
      routeA.reload = true

      // let pointsNew = deletePoint(id, routeA.points);
      newArr.routes = routsInject(newArr.routes, routeA)

      return _.cloneDeep(newArr)
    }

    case types.ERROR: {
      let newArr = { ...state }
      newArr.errorOptimize = true
      return _.cloneDeep(newArr)
    }

    case types.CLEAR_ERROR: {
      let newArr = { ...state }
      newArr.errorOptimize = false
      return _.cloneDeep(newArr)
    }

    case types.RUN_RELOAD: {
      let newArr = { ...state }
      newArr.routes.forEach((gr) => {
        gr.routes.forEach((r) => {
          if (r.id === routeA.id) {
            r.reload = true
            r.osrm = null
            r.optimize = action.optimize
            r.child = Math.random(1, 19999)
          }
        })
      })

      return _.cloneDeep(newArr)
    }

    case types.RUN_RELOAD2: {
      let newArr = { ...state }

      routeA.osrm = null
      routeA.reload = true

      newArr.routes = routsInject(newArr.routes, routeA)

      return _.cloneDeep(newArr)
    }

    case types.RUN_DELETE_FROM_TRANSFER: {
      const target = action.payload
      let newArr = { ...state }

      routeA.points.forEach((point, i, arr) => {
        if (point.id === target.id) {
          point.routePointTransferChild = point.routePointTransferChild.filter(
            (child) => child.child_id !== target.childId
          )
        }
      })
      routeA.osrm = null
      routeA.reload = true

      newArr.routes = routsInject(newArr.routes, routeA)

      return _.cloneDeep(newArr)
    }

    case types.RUN_EDIT_POINT_FLAG_NAME: {
      let newArr = _.cloneDeep(state)
      const activeRunId = action.payload.routeId
      const group_flag = action.payload.group_flag
      const routegroupId = action.payload.routegroupId
      const point_id = action.payload.point_id

      const routes = newArr?.routes?.map((route) => {
        if (route.id === routegroupId) {
          return {
            ...route,
            routes: route.routes.map((item) => {
              if (item.id === activeRunId) {
                return {
                  ...item,
                  points: item.points.map((point) => {
                    if (point.id === point_id) {
                      return {
                        ...point,
                        routePointRoute: {
                          ...point.routePointRoute,
                          group_flag,
                        },
                      }
                    } else {
                      return point
                    }
                  }),
                }
              } else {
                return item
              }
            }),
          }
        } else {
          return route
        }
      })
      const result = { ...newArr, routes: routes }
      return _.cloneDeep(result)
    }

    case types.RUN_DELETE_FROM_BUS: {
      const target = action.payload
      let newArr = { ...state }

      routeA.points.forEach((point, i, arr) => {
        if (point.id === target.id) {
          point.routePointChildren = point.routePointChildren.filter(
            (child) => child.child_id !== target.childId
          )

          if (!action.isSchool) {
            // point.object={}
            point.object = point.object.filter(
              (child) => child.id !== target.childId
            )
          }
        }
      })

      newArr.routes = routsInject(newArr.routes, routeA)

      return _.cloneDeep(newArr)
    }

    case types.RUN_ADD_ITEM: {
      let newArr = { ...state }
      routeA.osrm = null
      routeA.reload = true

      newArr.routes = routsInject(newArr.routes, routeA)
      return _.cloneDeep(newArr)
    }

    case types.RUN_ADD_ITEM_TRANSFER: {
      let newArr = { ...state }

      newArr.routes = routsInject(newArr.routes, routeA)
      return { ...newArr }
    }

    case types.RUN_CHILD_IN_BUS: {
      break
    }
    case types.RUN_EDIT_ANCHOR: {
      // RELOAD
      let newArr = { ...state }

      newArr.routes.forEach((gr) => {
        gr.routes.forEach((r) => {
          if (r.id === routeA.id) {
            r.reload = true
          }
        })
      })

      return _.cloneDeep(newArr)
    }

    case types.RUN_CHANGE_POSITION: {
      let newArr = { ...state }

      let settings = action.payload[1]
      let response = action.payload[0]

      let { routeId } = response

      let routeFind = getRouteByID(routeId, newArr)

      let oldP = routeFind.points.findIndex((x, i) => {
        return x.id + '' === settings.point_id
      })

      routeFind.points.splice(oldP, 1)
      routeFind.points.splice(settings.position, 0, response)
      routeFind.reload = true

      routeFind.points.forEach((x, i) => {
        x.position = i + 1
      })

      newArr.routes.forEach((gr) => {
        gr.routes.forEach((x) => {
          if (x.id === routeFind.id) {
            x.points = routeFind.points
            x.reload = true
            x.osrm = null
          }
        })
      })
      return _.cloneDeep(newArr)
    }

    case types.RUN_OSRM_ROUTE: {
      let newArr = { ...state }
      routeA.osrm = action.payload
      newArr.routes = routsInject(newArr.routes, routeA)
      return newArr
    }
    case types.ADDRESS_GEOCODE: {
      return {
        ...state,
        addressGeo: action.payload,
      }
    }
    case types.GET_CHILD_STOP_INFO: {
      return {
        ...state,
        stopInfo: [...state.stopInfo, action.payload],
      }
    }
    case types.RUN_EDIT_ITEM: {
      break
    }
    case types.RUN_REMOVE_ITEM: {
      break
    }
    case types.RUN_TYPE_IN_RUN: {
      break
    }

    default:
      return state
  }
}
