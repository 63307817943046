export const SaveIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="10"
      viewBox="0 0 14 10"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M51 329.192L52.4 327.846 56 331.308 63.6 324 65 325.346 56 334z"
        transform="translate(-51 -324)"
      />
    </svg>
  )
}
