import React, { Component } from 'react'
import { connect } from 'react-redux'
import BaseModal from '../../../../../components/BaseModal'
import moment from 'moment'

import './index.less'
import { NewSingleDatePicker } from '../../../../../entities/ui/NewSingleDatePicker/NewSingleDatePicker'
import {CalendarIcon, ClearIcon} from '../../../../../img/icons'

const dateFormat = 'MM/DD/YYYY h:mm A'

class ModalAnchor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      point: props.point,
      isNew: props.point && props.point.is_anchor ? false : true,
      form: {
        anchor_time: null,
      },
      formErrors: {
        anchor_time: false,
      },
      formEmpty: {
        anchor_time: true,
      },
    }
  }

  getPointType = (typeIndex = 0) => {
    const types = ['', 'Bus Depot', 'Bus Stop', 'School']
    return types[typeIndex]
  }

  handleChange = (type, value) => {
    const form = this.state.form
    form[type] = value

    this.setState({
      form: form,
    })

    if (this.state.formErrors.hasOwnProperty(type)) {
      if (!value || value == '') {
        let errors = this.state.formErrors
        errors[type] = true

        let empty = this.state.formEmpty
        empty[type] = true

        this.setState({
          formErrors: errors,
          formEmpty: empty,
        })
      } else {
        let errors = this.state.formErrors
        errors[type] = false

        let empty = this.state.formEmpty
        empty[type] = false

        this.setState({
          formErrors: errors,
          formEmpty: empty,
        })
      }
    }
  }

  save = () => {
    const { form, formErrors, formEmpty, isNew, point } = this.state
    let isError = false

    Object.keys(formEmpty).forEach((type) => {
      if (formEmpty[type]) {
        formErrors[type] = true
        isError = true
      }
    })

    if (isError && isNew) {
      this.setState({
        formErrors: formErrors,
      })

      return
    }

    this.props.markAsAnchorFn(point.id, form.anchor_time, isNew)
    this.props.close()
  }

  render() {
    let { close } = this.props
    let { form, formErrors, point, isNew } = this.state

    return (
      <BaseModal
        class={'ModalAncor'}
        title={isNew ? 'Mark as Anchor' : 'Unmark as Anchor'}
        className="add-point-modal"
        closeFn={close}
        save={this.save}
      >
        <div className="flexLine anchor-modal-line stop-name">
          {`${point.position}. ${point.title}`}
          <div className="stop-type">{this.getPointType(point.type)}</div>
        </div>

        {isNew ? (
          <div
            className="flexLine"
            style={{
              flexWrap: 'wrap',
              marginTop: '5px',
              marginBottom: '5px',
              position: 'relative',
            }}
          >
            <span className={formErrors.anchor_time ? 'error' : ''}>
              Anchor Date and Departure Time
            </span>
            <NewSingleDatePicker
              getCalendarContainer={(trigger) => trigger.parentNode}
              id={form['anchor_time'] ? 'dtClear' : 'DatePicker'}
              className={formErrors.anchor_time ? 'error' : ''}
              style={{ width: '100%' }}
              showTime={{
                defaultValue: moment('00:00:00', 'HH:mm'),
                use12Hours: true,
                format: 'hh:mm A',
              }}
              format={dateFormat}
              onChange={(e) =>
                this.handleChange('anchor_time', e ? e.unix(Number) : null)
              }
              suffixIcon={<CalendarIcon />}
              clearIcon={<ClearIcon />}
              divider
            />
          </div>
        ) : (
          <div
            className="line"
            style={{
              fontFamily: 'SBM-Lite',
              fontSize: '16px',
              color: '#000000',
            }}
          >
            Are you sure you want to unmark stop as Anchor?
          </div>
        )}

        <div className="center">
          <div className="buttons">
            <button className="cancel" onClick={close}>
              Cancel
            </button>
            <button className="save" onClick={this.save}>
              {isNew ? 'Save' : 'Unmark'}
            </button>
          </div>
        </div>
      </BaseModal>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAnchor)
