import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import Legend from '../../../../components/MapDefault/legend/LegendView'
import RoutesActive from './RoutesActive'
import './index.less'

import { Map, Marker, Popup } from 'react-leaflet'
import {
  fnMouseOut,
  fnMouseOver,
  getIcon,
} from '../../../../components/MapDefault/mapSpecialFns'

import DepotsPins from '../../../Run/components/MapContainer/components/DepotsPins'
import api from '../../../../redux/apiConfig'
import _ from 'lodash'
import chamomileFn from './RoutesActive/chamomile'
import L from 'leaflet'
import { BaseGoogleLayer } from '../../../../components/GoogleLayer/BaseGoogleLayer'
import { addressWithOptions } from '../../../../helpers/url'

const relativePath = 'client'
const run = api.run

class MapViewRuns extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeRoute: props.activeRoute,
      findRider: [],
      checkedList: [],
      showMap: false,
      boundsFirstTargetRun: false,
      plotAddress: '',
      plotRiderName: '',
      filterChild: [],
    }

    this.map = React.createRef()
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        showMap: true,
      })
    }, 1)
  }

  fitBoun = (bound) => {
    let LanLon = []
    if (_.isEmpty(bound)) {
      this.map.current.contextValue.map.fitBounds(this.getBounds(), {
        padding: L.point([100, 100]),
      })
    }

    bound.map((item) => {
      LanLon.push([item.lat, item.lon])
    })

    this.map.current.contextValue.map.fitBounds(LanLon, {
      padding: L.point([100, 100]),
    })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.clearNow !== this.state.clearNow) {
      this.setState(
        {
          clearNow: nextProps.clearNow,
        },
        () => {
          if (this.map.current) {
            this.map.current.contextValue.map.fitBounds(this.getBounds(), {
              padding: L.point([100, 100]),
            })
          }
        }
      )
    }

    if (nextProps.findRider !== this.state.findRider) {
      this.setState(
        {
          findRider: nextProps.findRider,
        },
        () => {
          this.plotRiderItem(nextProps.findRider)
        }
      )
    }

    if (nextProps.activeRoute !== this.state.activeRoute) {
      this.setState({
        activeRoute: nextProps.activeRoute,
        boundsFirstTargetRun: false,
      })
    }

    if (nextProps.plotAddress !== this.state.plotAddress) {
      this.setState({
        plotAddress: nextProps.plotAddress,
      })
      this.plotAddress(nextProps.plotAddress)
    }

    if (nextProps.plotRiderName !== this.state.plotRiderName) {
      this.setState({
        plotRiderName: nextProps.plotRiderName,
      })
      this.plotRider(nextProps.plotRiderName)
    }

    this.setState({
      checkedList: nextProps.checkedList,
      compareStart: nextProps.compareStart,
      depots: nextProps.depots,
      childrenList: nextProps.childrenList,
    })
  }

  plotRider = (plotRiderName) => {
    if (plotRiderName) {
      let cloneChild = this.state.childrenList.filter(
        (item) =>
          item.full_name.toLowerCase().search(plotRiderName.toLowerCase()) > -1
      )

      cloneChild.forEach((item) => {
        item.addresses = item.addresses.filter((v) => v.is_school === 0)
      })

      this.setState({
        filterChild: cloneChild,
      })

      let temp = []

      if (!_.isEmpty(cloneChild)) {
        cloneChild.forEach((item, index) => {
          if (!_.isEmpty(item.addresses)) {
            item.addresses.forEach((address, index) => {
              if (
                address &&
                address.address &&
                address.address.lat &&
                address.address.lon
              ) {
                temp[index] = [address.address.lat, address.address.lon]
              }
            })
          }
        })

        temp = _.union(temp)
        if (this.map.current && !_.isEmpty(temp)) {
          if (temp.length === 2) {
            this.setState({
              centerTarget: [temp[0][0], temp[0][1]],
            })
          } else {
            this.map.current.contextValue.map.fitBounds(temp, {
              padding: L.point([100, 100]),
            })
          }
        }
      }
    } else {
      this.setState({
        filterChild: [],
      })
    }
  }

  plotRiderItem = (items) => {
    if (!_.isEmpty(items)) {
      items.forEach((item) => {
        const encodedPlotAddress = encodeURIComponent(item.address)
        const companyOpts =
          this.props.companyOptions &&
          this.props.companyOptions.geocode_provider
        run
          .get(
            `/routes/geocode?address=${addressWithOptions(
              encodedPlotAddress,
              companyOpts
            )}`
          )
          .then((response) => {
            if (response.status === 200) {
              if (response.data.lat) {
                item.lat = response.data.lat
                item.lon = response.data.lon
              }

              items.map((x) => {
                if (x.id === item.id) {
                  return x === item
                }
              })

              this.setState(
                {
                  rand: Math.random(),
                  findRider: items,
                },
                () => {
                  this.fitBoun(items)
                }
              )
            }
          })
      })
    }
  }

  plotAddress = (address) => {
    if (address && address.length > 2) {
      const encodedPlotAddress = encodeURIComponent(address)
      const companyOpts =
        this.props.companyOptions && this.props.companyOptions.geocode_provider
      run
        .get(
          `/routes/geocode?address=${addressWithOptions(
            encodedPlotAddress,
            companyOpts
          )}`
        )
        .then((response) => {
          if (response.status === 200 && response.data.lat) {
            let marker = {
              location: [response.data.lat, response.data.lon],
              icon: getIcon('men_pickup'),
              object: address,
            }

            this.map.current.contextValue.map.setView(
              [response.data.lat, response.data.lon],
              15
            )

            this.setState({
              plotAddressMarker: marker,
            })
          }
        })
    } else {
      this.setState({
        plotAddressMarker: null,
      })
    }
  }

  getBounds = () => {
    let { bounds, boundsFirstTargetRun, depots } = this.state

    if (bounds && bounds.length > 1 && !boundsFirstTargetRun) {
      this.setState({
        boundsFirstTargetRun: true,
      })
      return bounds
    }

    if (depots && depots.length > 0 && !boundsFirstTargetRun) {
      return _.map(depots, (dep) => {
        if (!dep.address) return false
        return [dep.address.lat, dep.address.lon]
      })
    }

    if (!boundsFirstTargetRun) {
      return [
        [24.664018, -123.461071],
        [46.088543, -56.280215],
      ]
    }

    return bounds
  }

  goToBuilder = () => {
    let checkedList = this.state.checkedList
    if (!checkedList || checkedList.length !== 2) return false
    if (!this.props.access) return false

    return (
      <Link
        to={`/${relativePath}/RouteTool/routes/${checkedList[0]}/${checkedList[1]}`}
      >
        <div style={{ zIndex: 1000 }} className="transferRuns">
          <span> Switch to Transfer Mode</span>
        </div>
      </Link>
    )
  }

  boundsCb = (bounds) => {
    this.setState({
      bounds: bounds,
    })
  }

  render() {
    let {
      activeRoute,
      checkedList,
      compareStart,
      centerTarget,
      plotAddressMarker,
      filterChild,
      findRider,
    } = this.state

    chamomileFn.bind(this)() //Ромашка
    if (!this.state.showMap) return <div />

    return (
      <Map
        bounds={this.getBounds()}
        ref={this.map}
        center={centerTarget}
        wheelPxPerZoomLevel={3000}
      >
        <Legend runPage={this.props.paddingLegend} />

        <DepotsPins routesViewPage={true} />

        <RoutesActive
          activeRoute={activeRoute}
          boundsCb={this.boundsCb}
          checkedList={checkedList}
          compareStart={compareStart}
          map={this.map}
        />

        {this.goToBuilder()}

        <BaseGoogleLayer />

        {!_.isEmpty(findRider) &&
          findRider.map((item) => {
            if (item && item.lat && item.lon) {
              return (
                <Marker
                  key={item.id}
                  zIndexOffset={1000}
                  position={[item.lat, item.lon]}
                  icon={getIcon('stops', item)}
                  onMouseOver={(e) => fnMouseOver(e)}
                  onMouseOut={(e) => fnMouseOut(e)}
                >
                  <Popup
                    offset={[0, -14]}
                    direction="top"
                    onMouseOut={(e) => fnMouseOut(e)}
                  >
                    <div className="full_name">
                      {' '}
                      {item.full_name}
                      <br /> {item.address && item.address}
                    </div>
                  </Popup>
                </Marker>
              )
            }
          })}

        {plotAddressMarker && (
          <Marker
            key={'plot'}
            position={plotAddressMarker.location}
            icon={getIcon('stops', plotAddressMarker.object)}
            onMouseOver={(e) => fnMouseOver(e)}
            onMouseOut={(e) => fnMouseOut(e)}
          >
            <Popup
              offset={[0, -14]}
              direction="top"
              onMouseOut={(e) => fnMouseOut(e)}
            >
              <div className="full_name"> {plotAddressMarker.object}</div>
            </Popup>
          </Marker>
        )}

        {filterChild &&
          this.state.filterChild.map((item, i) => {
            return item.addresses.map((address) => {
              if (!address.address) {
                return false
              } else {
                return (
                  <Marker
                    key={address.id}
                    position={[address.address.lat, address.address.lon]}
                    onMouseOver={(e) => fnMouseOver(e)}
                    onMouseOut={(e) => fnMouseOut(e)}
                    icon={getIcon(
                      address.address.type === 1 ? 'men_pickup' : 'men_dropoff',
                      item.full_name
                    )}
                  >
                    <Popup
                      offset={[0, -14]}
                      direction="top"
                      onMouseOut={(e) => fnMouseOut(e)}
                    >
                      <div className="full_name"> {item.full_name}</div>
                    </Popup>
                  </Marker>
                )
              }
            })
          })}
      </Map>
    )
  }
}

function mapStateToProps(state) {
  return {
    childrenList: state.children.childrenList,
    depots: state.auth.depots,
    companyOptions: state.auth.companyOptions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapViewRuns)
