import { Checkbox as CCheckbox, CheckboxProps } from 'antd'
import React, { forwardRef, Ref } from 'react'
import './NewCheckbox.less'
import classNames from 'classnames'
import { Controller } from 'react-hook-form'
import { IRules } from '../NewInput/FormInput'
import styles from './NewCheckbox.module.less'

interface Props extends CheckboxProps {
  textClassName?: string
}
export const NewCheckbox = forwardRef(
  ({ className, ...props }: Props, ref: Ref<HTMLInputElement>) => {
    return (
      <CCheckbox
        className={classNames('newCheckbox', className)}
        {...props}
        ref={ref}
      />
    )
  }
)

interface FormCheckboxProps extends Props {
  name: string
  rules?: IRules
}
export const FormCheckbox = forwardRef(
  (
    {
      name,
      rules,
      children,
      className,
      textClassName,
      ...rest
    }: FormCheckboxProps,
    ref: Ref<HTMLInputElement>
  ) => {
    return (
      <Controller
        rules={rules}
        name={name}
        render={({ field }) => (
          <div className={className}>
            <label className={styles.root}>
              <NewCheckbox
                {...{ ...rest, ...field }}
                ref={field.ref ?? ref}
                checked={!!field.value}
              />
              <span className={textClassName}> {children}</span>
            </label>
          </div>
        )}
      />
    )
  }
)
