import { create } from 'zustand'
import { shallow } from 'zustand/shallow'
import {
  GpsRun,
  GpsRunWithDetails,
  RiderId,
  RiderStoreId,
  RiderType,
  RunRider,
  PointId,
  SocketCheckPoint,
  StopType,
  TimelinePoint,
  TimelinePoints,
} from '@/redux/gps/types'
import { Map } from 'react-leaflet'

export interface IGpsDetailsStore {
  attendanceRiders: RunRider[]
  checkPoints: { [key: PointId]: SocketCheckPoint }
  setCheckPoint: (v: SocketCheckPoint) => void
  resetState: () => void
  setAttendanceRider: (v: Partial<RunRider>) => void
  riders: { [key: RiderStoreId]: RunRider }
  setGpsInfo: (v: GpsRunWithDetails) => void
  setGpsStore: (v: Partial<IGpsDetailsStore>) => void
  gpsInfo: GpsRunWithDetails
  gpsTimestamp: GpsRun[]
  selectRider: string
  mapRef: { current: Map }
  isReload: boolean
  mqttGps: TimelinePoints
  setMqttGpsData: (v: TimelinePoint) => void
}

const initialState = {
  attendanceRiders: [],
  mapRef: { current: null },
  gpsInfo: {} as GpsRunWithDetails,
  riders: {},
  gpsTimestamp: [],
  selectRider: '',
  checkPoints: [],
  isReload: false,
  mqttGps: {},
}

export const useGpsDetailsStore = create<IGpsDetailsStore>((set, get) => ({
  ...initialState,
  setGpsStore: (params: Partial<IGpsDetailsStore>) => {
    set({ ...params })
  },
  resetState: () => set(initialState),
  setGpsInfo: (gpsInfo) => {
    return set((state) => ({
      ...state,
      gpsInfo,
      riders: gpsInfo?.points
        ?.map((point) => point.riders)
        ?.flat()
        ?.reduce((acc, rider) => ({ ...acc, [getUniqueId(rider)]: rider }), {}),
    }))
  },
  setAttendanceRider: (value) => {
    const uniqueId = getUniqueIdv2(value.id, value.childAddressId)

    set({
      riders: {
        ...get().riders,
        [uniqueId]: { ...get().riders?.[uniqueId], ...value },
      },
    })

    const points = get().gpsInfo?.points.map((p) => {
      const riders = p?.riders?.map((r) => {
        if (r.id === value.id && r.childAddressId === value.childAddressId) {
          return {
            ...r,
            attend: value.attend,
          }
        } else return r
      })
      return { ...p, riders }
    })
    set({
      gpsInfo: {
        ...get().gpsInfo,
        points,
      },
    })
  },
  setCheckPoint: (value) => {
    set({
      checkPoints: { ...get().checkPoints, [value.point_id]: value },
    })
    const points = get().gpsInfo?.points.map((p) => {
      if (p.id === value.point_id) {
        return { ...p, factTime: value.date }
      } else return p
    })
    set({
      gpsInfo: {
        ...get().gpsInfo,
        points,
      },
    })
  },
  setMqttGpsData: (value) => {
    set({ mqttGps: { ...get().mqttGps, [value.timeString]: value } })
  },
}))

export const getAttendanceRiderById = (id: number) => {
  return (
    Object.values(useGpsDetailsStore.getState().riders).filter(
      (rider) => Number(rider.id) === Number(id) && rider.attend === 1
    ) || []
  )
}

export const getRiderType = (id: RiderId, type: RiderType): number => {
  const attendanceWithAttend = getAttendanceRiderById(id)

  const getCurrentType = (type = 1) => {
    return type - 1
  }
  if (attendanceWithAttend?.length === 0) {
    return type ?? 1
  } else if (attendanceWithAttend?.length === 1) {
    return 3 + getCurrentType(type)
  } else if (attendanceWithAttend?.length > 1) {
    return 7 + getCurrentType(type)
  }
  return type ?? 1
}

export const getUniqueId = (rider: RunRider): RiderStoreId => {
  return `${rider?.id || ''}-${rider?.childAddressId || ''}` as RiderStoreId
}
export const getUniqueIdv2 = (
  childId: string | number | undefined,
  addressId: string | number | undefined
) => {
  return `${childId || ''}-${addressId || ''}`
}

export const useRunPointParams = () => {
  return useGpsDetailsStore(
    (state) => ({
      gpsInfo: state.gpsInfo,
      gpsRuns: state.gpsTimestamp,
      gpsPoints: (state.gpsInfo?.points || []).filter(
        (point) => !!point?.address && point?.stopType !== StopType.Redirection
      ),
    }),
    shallow
  )
}
