import { Header } from '@/features/containers/components/Header/Header'
import { Menu } from '@/features/containers/components/Menu/Menu'
import styles from './MainContainer.module.less'

export const MainContainer = ({
  children,
  withMenu = true,
}: {
  children: any
  withMenu?: boolean
}) => {
  return (
    <div className={styles.root}>
      <Header className={styles.header} />
      {withMenu && <Menu className={styles.menu} />}
      <section id={'CONTENT_LAYOUT'} className={styles.content}>
        {children}
      </section>
    </div>
  )
}
