import React, { Component } from 'react'
import * as runActions from '../../../../../../redux/routesView/actions'
import { connect } from 'react-redux'

import Col from 'antd/es/col'
import Row from 'antd/es/row'
import { Trans } from 'react-i18next'
import i18n from '../../../../../../i18n'
import './index.less'

import { getValid } from '../../../../../../components/validPhone/index'
import {
  getTimeLastUTC,
  totalDist,
  totalTime,
} from '../../../../../../components/RepeatFunctions/timeWork'
import anchorAndTimeFN from '../../../../../Run/components/BuildRun/components/anchorAndTime'

class Child extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
    }
  }

  render() {
    let { routePointChildren, id, object, address, withProblems } = this.state
    let { pointOsrmLeg } = this.props

    let position = this.state.routePointRoute.view_position

    let arriveTime = null
    let distance = null
    let duration = null

    if (pointOsrmLeg && pointOsrmLeg.time) {
      arriveTime = getTimeLastUTC(pointOsrmLeg.time)

      if (
        pointOsrmLeg.distance !== undefined &&
        pointOsrmLeg.duration !== undefined
      ) {
        distance = totalDist(pointOsrmLeg.distance)
        duration = totalTime(pointOsrmLeg.duration)
      }
    }

    return (
      <div
        style={withProblems ? { background: '#ffd8d7' } : {}}
        key={id}
        className="childPoint"
      >
        <span className="backgroundLeft" />

        <Row style={{ width: '100%' }} className="MarginBlock">
          <Col span={24} className="fullName">
            {typeof position === 'number' && `${position} .`}{' '}
            {withProblems ? 'Deleted rider' : object?.full_name}
          </Col>
        </Row>
        <Row style={{ width: '100%', marginLeft: 0 }} className="Info">
          {this.props.first && (
            <Row style={{ width: '100%' }} className="MarginBlock">
              <Col span={24} className="text">
                <Trans>The Run Starts from here</Trans>
              </Col>
            </Row>
          )}

          <Row style={{ width: '100%' }} className="marginTopEight">
            <Col span={12} className={'dist'}>
              {distance && duration && (
                <div>
                  <span>{distance}</span>
                  <span>{duration}</span>
                </div>
              )}
            </Col>
            <Col span={24} className={'waitArr'}>
              {anchorAndTimeFN.bind(this)()}
            </Col>
          </Row>

          {object?.phone && (
            <Row style={{ width: '100%' }} className="MarginBlock">
              <Col span={1} className="PhoneIco" />
              <Col span={23}>
                <span className="number fontLight">
                  {getValid(object?.phone, i18n.t('+1 ###-###-####'))}
                </span>
              </Col>
            </Row>
          )}

          {address && address.address && (
            <Row style={{ width: '100%' }} className="MarginBlock">
              <Col span={24} className="fontLight">
                <span className="DropOff ">
                  <Trans>
                    {routePointChildren[0] &&
                    routePointChildren[0].childaddress.type === 1
                      ? 'PU'
                      : 'DO'}
                  </Trans>
                  <span className="oval" />
                </span>
                {address.address}
              </Col>
            </Row>
          )}

          {object && object.school_name && (
            <Row style={{ width: '100%' }} className="MarginBlock">
              <Col span={1} className="SchoolIcon" />
              <Col span={23} className="fontLight">
                {object.school_name}
              </Col>
            </Row>
          )}
        </Row>
      </div>
    )
  }
}

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return { runActions, dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Child)
