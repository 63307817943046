import React, { Component } from 'react'
import { connect } from 'react-redux'
import BaseModal from '../../../../../components/BaseModal'
import api from '../../../../../redux/apiConfig'
import './index.less'
import { Row } from 'antd'
import moment from 'moment'
import { Spin } from 'antd'
import { getSearchParamsFromObject } from '../../../../../helpers/url'
import { NewWeekPicker } from '../../../../../entities/ui/NewWeekPicker/NewWeekPicker'
import { NewSingleDatePicker } from '../../../../../entities/ui/NewSingleDatePicker/NewSingleDatePicker'

let rq = api.printAttendanceV2
let rq2 = api.logXls

const dateFormat = 'MM/DD/YYYY'

moment.locale('en')

class Modal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeDate: 0,
      errorDate: false,
      open: false,
      spinning: false,
      selectWeek: moment().format(dateFormat),
      startDate: moment(new Date().getTime()).startOf('day').unix(),
      endDate: moment(new Date().getTime()).endOf('day').unix(),
    }
  }

  componentDidMount() {
    let date = moment().unix()
    let Mon = moment(date * 1000)
      .weekday(0)
      .format('MM/DD/YYYY')
    let Sun = moment(date * 1000)
      .weekday(6)
      .format(dateFormat)

    let last = moment(date * 1000)
      .weekday(6)
      .format(dateFormat)

    let Range = Mon + ' - ' + Sun

    this.setState({
      changeWeek: 'week_pk_selected',
      onChangeWeek: true,
      dateSeleced: moment(Mon).unix(),
      selectDate: Mon,
      Last: last,
      selectedDate: date,
      selectWeek: Range,
    })
  }

  handleChange = (type, value) => {
    let date = moment(value).unix()

    let Mon = moment(date * 1000)
      .weekday(0)
      .format(dateFormat)
    let Sun = moment(date * 1000)
      .weekday(6)
      .format(dateFormat)

    let last = moment(date * 1000)
      .weekday(6)
      .format(dateFormat)

    let Range = Mon + ' - ' + Sun

    this.setState({
      selectDate: value,
      selectWeek: Range,
      open: false,
      dateSeleced: moment(date * 1000)
        .weekday(0)
        .unix(),
    })
  }

  onChange = (type, e) => {
    if (type === 'activeDate') {
      this.setState({
        [type]: e.unix(),
        errorDate: false,
      })
    } else {
      this.setState({
        [type]: e.target.checked,
      })
    }
  }

  saveBtn = () => {
    const { isDashboard, filter } = this.props
    this.setState({ spinning: true })
    let { dateSeleced, startDate, endDate } = this.state

    let dateChange = moment(dateSeleced * 1000)
      .utc()
      .unix()

    let d = moment(dateChange).format('YYYY-MM-DD')
    let date = moment(dateChange * 1000).utc(`${d} ${7}:00`)
    let dd = date.unix()

    if (isDashboard) {
      const query = {
        start: startDate,
        end: endDate,
      }

      if (filter && filter !== 'all') query.type = filter

      const params = getSearchParamsFromObject(query)

      let url = `/general-logs/xls?${params}`

      rq2.get(url).then((response) => {
        if (response.status === 200) {
          const file = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const fileURL = URL.createObjectURL(file)
          window.open(fileURL)
          this.props.closeFn('printAttendanceSheets', '')
        }
      })
      this.props.closeFn('printAttendanceSheets', '')
    } else {
      let ids = []
      this.props.checkedList.forEach((x, i) => {
        ids.push(x * 1)
      })

      let url = `route/print/monthly-attendance-xls`

      rq.post(url, {
        runIds: ids,
        timestamp: dd,
      }).then((response) => {
        const file = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)

        this.props.closeFn('printAttendanceSheets', '')
      })
    }
  }

  handleChangeDate = (date, startDate) => {
    if (date) {
      let timestamp = date.unix()
      if (startDate) {
        timestamp = date.startOf('day').utc().unix()
        this.setState({ startDate: timestamp })
      } else {
        timestamp = date.endOf('day').utc().unix()
        this.setState({ endDate: timestamp })
      }
    }
  }
  disabledDate = (current) => {
    const dates = [
      moment(this.state.dateSeleced * 1000).utc(),
      moment(this.state.Last),
    ]
    if (!dates) {
      return false
    }
    const tooLate = dates[0] && current.diff(dates[0], 'month') >= 1
    const tooEarly = dates[1] && dates[1].diff(current, 'month') >= 1
    return !!tooEarly || !!tooLate
  }

  handleCallback = (start, end, type) => {
    if (type === 'Attendance') {
      let day = moment(start).weekday(0).unix()

      this.setState({
        dateSeleced: day,
        selectedDate: moment(end).weekday(0).unix(),
      })
    }
  }

  handleClose = () => {}

  render() {
    let closeFn = this.props.closeFn
    const { isDashboard } = this.props

    return (
      <BaseModal
        title={`${
          isDashboard ? 'Export system log to XLS' : 'Monthy Attendance Report'
        }`}
        closeFn={() => closeFn('printAttendanceSheets', '')}
        save={this.handleSave}
      >
        <Spin spinning={this.state.spinning} tip="Loading...">
          <div id="modalPrint" className="content">
            <div
              className="modal-body attendance-modal"
              onClick={this.handleClose}
            >
              {isDashboard ? (
                <div id="date-range-picker">
                  <Row className="dashboard-logs-datepicker-range">
                    <NewSingleDatePicker
                      suffixIcon={''}
                      clearIcon={''}
                      onChange={(date) => this.handleChangeDate(date, true)}
                      value={moment.unix(this.state.startDate)}
                      format={dateFormat}
                    />
                    <span className="picker-line">-</span>
                    <NewSingleDatePicker
                      suffixIcon={''}
                      clearIcon={''}
                      onChange={(date) => this.handleChangeDate(date, false)}
                      value={moment.unix(this.state.endDate)}
                      format={dateFormat}
                    />
                  </Row>
                </div>
              ) : (
                <div>
                  <NewWeekPicker
                    routeParamId={1}
                    value="attendance"
                    name="Attendance"
                    callback={this.handleCallback}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="center">
            <div className="buttons">
              <button
                className="cancel"
                onClick={() => closeFn('printAttendanceSheets', '')}
              >
                Cancel
              </button>
              <button onClick={this.saveBtn} className={`save`}>
                {isDashboard ? 'Export' : 'Print'}
              </button>
            </div>
          </div>
        </Spin>
      </BaseModal>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
