import React, { useLayoutEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import AllModals from './components/AllModals'
import EmptyRun from './components/EmptyRun'
import ActiveRuns from './components/ActiveRuns'
import SelectLine from './components/SelectLine'
import { browserHistory } from 'react-router'

import { useContextBuilder } from './context'
import { SuffixIcon } from '../../../../img/iconsComponents/SuffixIcon'
import { NewCheckbox } from '../../../../entities/ui/NewCheckbox/NewCheckbox'

function BuildRun(props) {
  const [menuToggle, setMenuToggle] = useState(false)
  let { emptyRunFlag, transferMode, setTransferMode, activeRun, auth } =
    useContextBuilder()

  const isRuleDispatcher =
    auth.permissions && auth.permissions?.write_routes !== 1

  const pathLength = browserHistory
    .getCurrentLocation()
    .pathname.split('/').length

  useLayoutEffect(() => {
    if (pathLength === 6) {
      setTransferMode(true)
    }
  }, [])

  function menuToggleFn() {
    setMenuToggle(!menuToggle)
    props.buildRunMenuOpenTypeCb(!menuToggle)
  }

  return (
    <div className={`buildRun ${menuToggle && 'buildRunMin minTop'}`}>
      <div
        id="RunBuilder"
        data-testid="runBuilderToggle"
        className="btnBuildRun"
        onClick={menuToggleFn}
      >
        <SuffixIcon
          className={!menuToggle ? 'LeftRotateBtn' : 'RightRotateBtn'}
        />
      </div>
      <div className="flexRow">
        <p className="title">
          <Trans>Build Run</Trans>
        </p>
        <div>
          <NewCheckbox
            disabled={!activeRun || isRuleDispatcher}
            checked={transferMode}
            onChange={() => {
              setTransferMode(!transferMode)
            }}
          >
            <span className="textTransferRB">
              <Trans>Transfer Mode</Trans>
            </span>
          </NewCheckbox>
        </div>
      </div>

      <SelectLine /* ВЫБОР ЧЕРЕЗ SELECT */
        setActiveRunCb={props.setActiveRunCb}
      />

      {emptyRunFlag && <EmptyRun />}

      {!emptyRunFlag && (
        <ActiveRuns
          transferMode={transferMode}
          deletePositionFromRun={props.deletePositionFromRun}
          removeStopFromRun={props.removeStopFromRun}
          removeChildFromStop={props.removeChildFromStop}
        />
      )}

      <AllModals />
    </div>
  )
}

export default BuildRun
