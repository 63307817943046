import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../translations/en"
import ru from "../translations/ru"
import {priorityRuLoad} from './redux/configLocalDEV'
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: en
  },
  ru: {
    translation: ru
  }
};

let lang = 'en'
const useLang = priorityRuLoad ? lang = "ru" : 'en'
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: useLang,
    fallbackLng: 'en',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function(value, format, lng) {
        if (format === 'avgSpeed') {
          if (lng === "en"){
            let mph = Math.trunc(value.dist/1609 / (value.time/60/60));
            return new Intl.NumberFormat('en-US',  {
              style: 'unit',
              unit: 'mile-per-hour'
            }).format(mph);
          }else{
            let kph = Math.trunc(value.dist/1000 / (value.time/60/60));
            return new Intl.NumberFormat(lng,  {
              style: 'unit',
              unit: 'kilometer-per-hour'
            }).format(kph);
          }

        }else if(format === 'distance'){
          if (lng === "en"){
            if (!value) value = 0;
            let mi = Math.round((value / 1609.34) * 100) / 100;
            return new Intl.NumberFormat('en-US',  {
              style: 'unit',
              unit: 'mile'
            }).format(mi);
          }else{
            if (!value) value = 0;
            let km = Math.round( (value / 1000) * 100 ) / 100;
            return new Intl.NumberFormat(lng,  {
              style: 'unit',
              unit: 'kilometer'
            }).format(km);
          }
        }else if(format === 'duration'){
          if (!value) value = 0;
          let min = Math.round(value / 60)
          return new Intl.NumberFormat(lng,  {
            style: 'unit',
            unit: 'minute'
          }).format(min);
        }

        return value;
      }
    }
  },
    (e,t)=>{
      if(i18n.language === "ru") {
        // TODO need to add another lang fonts/css fixes
      }
    }

  );
export default i18n;