export const CopyIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="14"
      viewBox="0 0 11 14"
      {...props}
    >
      <g fill="currentColor">
        <path d="M6.796 2.896c-.229 0-.415-.183-.415-.41V0H.431C.204 0 .018.184.018.41v11.37l8.94-.002V2.896h-2.16z" />
        <path d="M7.211.247L7.211 2.076 8.953 2.076zM10.568 2.076h-.782v10.112c0 .227-.186.41-.415.41l-7.872.003v.989c0 .226.185.41.414.41h8.655c.23 0 .415-.184.415-.41V2.486c0-.227-.186-.41-.415-.41z" />
      </g>
    </svg>
  )
}
