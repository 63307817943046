export const EyeIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="10"
      viewBox="0 0 17 10"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M8.342.025C5.154.025 2.264 1.769.131 4.602a.66.66 0 0 0 0 .788c2.133 2.836 5.023 4.58 8.211 4.58 3.188 0 6.079-1.744 8.212-4.577a.66.66 0 0 0 0-.788C14.42 1.769 11.53.025 8.342.025zm.229 8.474a3.511 3.511 0 0 1-3.73-3.73 3.517 3.517 0 0 1 3.272-3.273 3.511 3.511 0 0 1 3.73 3.73A3.528 3.528 0 0 1 8.572 8.5zm-.106-1.618a1.886 1.886 0 0 1-2.007-2.006A1.898 1.898 0 0 1 8.223 3.11a1.886 1.886 0 0 1 2.007 2.007A1.898 1.898 0 0 1 8.465 6.88z"
      />
    </svg>
  )
}
