interface IProps {
  progressColor?: 'blue' | 'orange'
}

export const StatusProgressIcon = ({
  progressColor = 'blue',
  ...props
}: IProps) => {
  const colors = {
    blue: '#3cb0e7',
    orange: '#ead84d',
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" {...props}>
      <rect x="5" y="13" width="30" height="8" rx="4" fill="#efefef" />
      <rect
        x="5"
        y="13"
        width="23"
        height="8"
        rx="4"
        fill={colors[progressColor]}
      />
      <text
        fill="#000"
        fontSize="7"
        fontFamily="SBM, sans-serif"
        fontWeight="700"
      >
        <tspan x="0" y="34">
          In Progress
        </tspan>
      </text>
    </svg>
  )
}
