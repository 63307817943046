import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react'

export const axiosBaseQuery =
  (
    api: AxiosInstance
  ): BaseQueryFn<
    {
      url: string
      method?: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
    },
    unknown,
    unknown
  > =>
  async ({ method, url, data, params }) => {
    try {
      const response = await api[method || 'get'](url, data, params)

      return { data: { data: response.data, meta: response?.headers } }
    } catch (axiosError) {
      console.log({ ...axiosError })
      const err = axiosError as AxiosError

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data,
          serverError: true,
        },
      }
    }
  }
