import { IFTRequestItem } from '@/store/fieldTrip/FieldTrip.types'

export const transformResponseFTRequests = (response: {
  data: IFTRequestItem[]
}) => {
  const newData = []
  const ids = []
  response.data.forEach((item) => {
    if (item?.fieldTrip?.fieldTripRequests.length > 1) {
      const dupletIds = item?.fieldTrip?.fieldTripRequests.map((el) => el.id)
      if (!ids.includes(item.id)) {
        newData.push(item)
      }

      dupletIds.forEach((el) => ids.push(el))
    } else {
      newData.push(item)
    }
    //
  })

  return { ...response, data: newData }
}
