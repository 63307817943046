import { DatePicker } from 'antd'
import moment from 'moment/moment'
import { LeftIcon, RightIcon } from '../../../img/icons'
import React, { memo, useMemo, useState } from 'react'
import { Moment } from 'moment'
import './NewWeekPicker.less'
import { getInitParams } from './NewWeekPicker.helpers'

const dateFormat = 'MM/DD/YYYY'

interface IProps {
  name: string
  routeParamId: string
  selectDate: string
  selectWeek: string
  last?: string
  weekDisabled: boolean
  value: string
  open: boolean
  callback: (...params: any) => void
  changeDate?: moment.Moment
  next?: boolean
  activityLogOpen?: boolean
  suffixIcon?: React.ReactNode
}

export const NewWeekPicker = memo((props: Partial<IProps>) => {
  const [state, setState] = useState<Partial<IProps>>({
    ...props,
    [props.name]: getInitParams().range,
    [props.value]: getInitParams().sun * 1000,
  })

  const isDisabledChange = useMemo(
    () => !!props.routeParamId,
    [props.routeParamId]
  )

  // useEffect(() => {
  //   setState(props);
  // }, []);

  const onChange = (value: Moment, date: string, type: string) => {
    if (isDisabledChange) {
      const Sat = moment(date).weekday(0).format(dateFormat)

      const Sun = moment(date).weekday(6).format(dateFormat)
      const Range = Sat + ' - ' + Sun
      setState((prev) => ({
        ...prev,
        changeWeek: 'week_pk_selected',
        onChangeWeek: true,
        selectDate: Sat,
        open: false,
        changeDate: value,
        activityLogOpen: false,
        [props.name]: Range,
        [props.value]: date,
      }))
    }
  }

  const PrevWeek = (date, type) => {
    if (isDisabledChange) {
      const { last, start, end, rangeString } = getDates(date)
      setState((prev) => ({
        ...prev,
        activityLogOpen: false,
        open: false,
        prev: true,
        changeDate: moment(date).weekday(0),
        last: last,
        [type]: rangeString,
        [props.value]: end,
      }))

      props.callback?.(start, end, props.name)
    }
  }

  const NextWeek = (date, type) => {
    if (isDisabledChange) {
      const Sat = moment(date)
        .add(1, 'weeks')
        .startOf('isoWeek')
        .subtract(1, 'd')
        .format(dateFormat)
      const Sun = moment(date)
        .add(1, 'weeks')
        .endOf('isoWeek')
        .subtract(1, 'd')
        .format(dateFormat)

      const Range = Sat + ' - ' + Sun

      setState({
        changeDate: moment(date),
        next: true,
        activityLogOpen: false,
        open: false,
        last: Sun,
        [type]: Range,
        [props.value]: Sun,
      })
      props.callback?.(Sat, Sun, props.name)
    }
  }

  const handleOpen = () => {
    if (!state.weekDisabled && props.routeParamId) {
      setState((prev) => ({ ...prev, open: !prev.open }))
    }
  }

  return (
    <DatePicker
      format="MM/DD/YYYY"
      picker="week"
      disabled={!isDisabledChange}
      value={moment(state[props.value])}
      onOpenChange={handleOpen}
      size="middle"
      clearIcon={''}
      suffixIcon={props.suffixIcon}
      dropdownClassName="datePickerDropdown"
      onChange={(value, date) => {
        onChange(value, date, state.name)
      }}
      className="weekPicker"
      inputRender={(v) => {
        return (
          <div className="weekPickerSpan">
            <span
              className="navButton"
              onClick={(e) => {
                e.stopPropagation()
                PrevWeek(state[props.value], props.name)
              }}
            >
              <LeftIcon />
            </span>
            <input
              readOnly
              {...v}
              className="previewInput"
              value={state[props.name]}
            />
            <span
              className="navButton"
              onClick={(e) => {
                e.stopPropagation()
                NextWeek(state[props.value], props.name)
              }}
            >
              <RightIcon />
            </span>
          </div>
        )
      }}
    />
  )
})

const getDates = (date: string) => {
  const start = moment(date)
    .subtract(1, 'weeks')
    .startOf('isoWeek')
    .subtract(1, 'd')
    .format(dateFormat)

  const end = moment(date)
    .subtract(1, 'weeks')
    .endOf('isoWeek')
    .subtract(1, 'd')
    .format(dateFormat)
  const last = moment(date)
    .subtract(1, 'weeks')
    .endOf('isoWeek')
    .subtract(1, 'd')
    .format(dateFormat)

  const rangeString = start + ' - ' + end

  return { start, end, last, rangeString }
}
