export const OverviewIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="12"
      viewBox="0 0 23 12"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M17.044 1.028h-2.24C14.094.392 12.753 0 11.26 0S8.426.392 7.715 1.028h-2.24C2.456 1.028 0 3.49 0 6.514S2.456 12 5.475 12c3.019 0 5.475-2.461 5.475-5.486 0-.04 0-.08-.002-.12h.623l-.001.12C11.57 9.54 14.026 12 17.045 12c3.018 0 5.474-2.461 5.474-5.486s-2.456-5.486-5.475-5.486zM5.474 9.77a3.256 3.256 0 0 1 0-6.51 3.256 3.256 0 0 1 0 6.51zm7.257-6.616H9.788a.635.635 0 0 1 0-1.27h2.943a.635.635 0 0 1 0 1.27zm4.313 6.616a3.256 3.256 0 0 1 0-6.51 3.256 3.256 0 0 1 0 6.51z"
      />
    </svg>
  )
}
