import { create } from 'zustand'
import {
  IRequestOpenedItem,
  ITableSort,
} from '@/store/routeTool/routeTool.types'
import { TRequestActionTypes } from '@/features/containers/SocketConnect/SocketConnect.type'
import {
  createNewItem,
  setInReviewStatus,
  setRejectOrAcceptStatus,
} from '@/features/containers/SocketConnect/helpers/rtRequests'

export interface IOpenedFilter {
  page: number
  pageSize: number
  fullName: string
  order: ITableSort
  orderField?: string
  status?: number
  offset: number
}

export interface IStore {
  filters: IOpenedFilter
  items: IRequestOpenedItem[]
  setFilters: (filter: Partial<IOpenedFilter>) => void
  setItems: ({
    items,
    shoMore,
  }: {
    items: IRequestOpenedItem[]
    shoMore?: boolean
  }) => void
  incrementOffset: ({ offset, id }?: { offset?: number; id: number }) => void
  replaceStatus: (
    id: number,
    actionType: TRequestActionTypes,
    userBy?: string,
    updatedAt?: number
  ) => void
}

export const filtersForRequests = {
  page: 1,
  pageSize: 50,
  fullName: '',
  offset: 0,
  status: null,
}

export const defaultFilter = {
  ...filtersForRequests,
  order: undefined,
  orderField: undefined,
}

export const useRequestOpenedStore = create<IStore>((set, get) => ({
  filters: defaultFilter,
  items: [],
  setItems: ({ items, shoMore }) => {
    if (shoMore) {
      set({ items: [...get().items, ...items] })
    } else {
      set({ items })
    }
  },
  replaceStatus: (id, actionType, userBy, updatedAt) => {
    switch (actionType) {
      case 'createdPortalRequest':
        return createNewItem()
      case 'inReviewPortalRequest':
        return setInReviewStatus({ set, get, id, userBy, updatedAt })
      case 'acceptedPortalRequest':
        return setRejectOrAcceptStatus({ set, get, id })
      case 'rejectedPortalRequest':
        return setRejectOrAcceptStatus({ set, get, id })
    }
    //
  },
  incrementOffset: ({ offset, id }) =>
    set({
      items: get().items.filter((item) => item.id !== id),
      filters: { ...get().filters, offset: offset ?? get().filters.offset + 1 },
    }),
  setFilters: (filters) => set({ filters: { ...get().filters, ...filters } }),
}))
