export const ConsolesIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="17"
      viewBox="0 0 14 17"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M7.057 15.485a.699.699 0 0 0 .216-.511.699.699 0 0 0-.216-.511.699.699 0 0 0-.512-.216.699.699 0 0 0-.51.216.699.699 0 0 0-.217.511c0 .197.072.367.216.511a.699.699 0 0 0 .511.216.699.699 0 0 0 .512-.216zm4.58-2.33V2.248a.35.35 0 0 0-.109-.256.35.35 0 0 0-.255-.108H1.818a.35.35 0 0 0-.256.108.35.35 0 0 0-.107.256v10.909a.35.35 0 0 0 .107.256.35.35 0 0 0 .256.107h9.455a.35.35 0 0 0 .255-.107.35.35 0 0 0 .108-.256zM13.09 2.248V14.61c0 .5-.178.928-.534 1.284a1.75 1.75 0 0 1-1.284.535H1.818c-.5 0-.928-.178-1.284-.535A1.75 1.75 0 0 1 0 14.61V2.247c0-.5.178-.928.534-1.284A1.75 1.75 0 0 1 1.818.429h9.455c.5 0 .928.178 1.284.534.356.356.534.784.534 1.284z"
      />
    </svg>
  )
}
