import './newSwitch.less'
import React, { memo, useEffect, useState } from 'react'
import { Switch, SwitchProps } from 'antd'
import classNames from 'classnames'

export const NewSwitch = memo(
  ({
    className,
    checked,
    onChange,
    checkedChildren,
    unCheckedChildren,
    controlled,
    ...props
  }: SwitchProps & { controlled?: boolean }) => {
    const [state, setState] = useState(!!checked)

    useEffect(() => {
      setState(!!checked)
    }, [checked])

    return (
      <Switch
        checkedChildren={checkedChildren || 'ON'}
        unCheckedChildren={unCheckedChildren || 'OFF'}
        defaultChecked={false}
        className={classNames('newSwitch', className)}
        checked={controlled ? checked : state}
        onChange={(checked, event) => {
          setState((state) => !state)
          onChange?.(checked, event)
        }}
        {...props}
      />
    )
  }
)
