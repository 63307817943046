import { ModalsEnum, useModalSelector } from '@/store/modal/ModalStore'
import { NewModal } from '@/entities/ui/NewModal/NewModal'
import {
  useGetRTRequestsMessageQuery,
  useLazyPostRejectMessageRequestQuery,
} from '@/store/routeTool/routeTool'
import styles from './RequestsClosedModal.module.less'
import { Input, Spin } from 'antd'
import { CommentBlock } from '@/entities/modals/Requests/components/CommentBlock/CommentBlock'
import { newRequestStatuses } from '@/pages/Requests/constants'
import { useMemo, useState } from 'react'
import { format } from 'date-fns'
import { ChangeTypes } from '@/entities/modals/Requests/RequestsOpenedModal/components/ChangeTypes'
import { Button } from '@/entities/ui/NewButton/NewButton'

export const RequestsClosedModal = () => {
  const { isOpen, onCloseModal, state } = useModalSelector(
    ModalsEnum.CLOSED_PARENT_REQUEST
  )

  return isOpen ? (
    <_RequestsClosedModal
      item={state?.item as any}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
    />
  ) : null
}

const _RequestsClosedModal = ({ item, isOpen, onCloseModal }) => {
  const response = useGetRTRequestsMessageQuery(String(item.id || '0'))
  const [comment, setComment] = useState('')

  const [setMessageRequest, messageResponse] =
    useLazyPostRejectMessageRequestQuery()
  const onSendMessage = () => {
    setMessageRequest({ id: item.id, message: comment }).then((res) => {
      if (res.isSuccess) {
        setComment('')
        response.refetch()
      }
    })
  }

  const title = useMemo(() => {
    return `Request change ${
      item?.date ? format(item.date * 1000, 'MM/dd/yyyy h:mm a') : ''
    }`
  }, [item?.date])

  return (
    <NewModal
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 16,
          }}
        >
          {title}
          <span className={styles.status}>
            {newRequestStatuses[item?.status]}
          </span>
        </div>
      }
      open={isOpen}
      onClose={onCloseModal}
    >
      <section className={styles.root}>
        {item.guardian && (
          <div className={styles.guardianWrapper}>
            <p className={styles.label}>Guardian</p>
            <p>{item.guardian}</p>
          </div>
        )}
        <div className={styles.riderIdWrapper}>
          <p className={styles.label}>Rider / ID</p>
          <p>
            {item.childName} / {item.riderId}
          </p>
        </div>
        <ChangeTypes changes={item?.changes || []} />
        <div className={styles.textAreaWrapper}>
          <Input.TextArea
            className={styles.messageTextArea}
            autoSize
            placeholder="Leave comment"
            onChange={(e) => setComment(e.target.value)}
            value={comment}
          />
          <Button
            onClick={onSendMessage}
            isLoading={messageResponse.isFetching}
            className={cn({ [styles.hiddenMessageButton]: !comment })}
          >
            Send
          </Button>
        </div>
        <div
          className={cn(styles.commentsWrapper, {
            [styles.loading]: response.isFetching,
          })}
        >
          {response.isFetching && (
            <div className={styles.spinWrapper}>
              <Spin className={styles.loadingSpin} />
            </div>
          )}
          {(response.data?.data?.length || 0) > 0 && (
            <div className={styles.commentsBlock}>
              {response.data?.data?.map((comment) => (
                <CommentBlock {...comment} key={comment.id} />
              ))}
            </div>
          )}
        </div>
      </section>
    </NewModal>
  )
}
