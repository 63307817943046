export const BusesIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M7.5 0a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15zm0 1.875c2.44 0 4.502 1.572 5.28 3.75H2.22c.778-2.178 2.84-3.75 5.28-3.75zM1.875 7.5c2.562 0 4.636 2.468 4.68 5.53-2.651-.454-4.68-2.752-4.68-5.53zm6.57 5.53c.044-3.062 2.118-5.53 4.68-5.53 0 2.778-2.029 5.076-4.68 5.53z"
      />
    </svg>
  )
}
