import { Popover } from 'antd'
import styles from './UserPopover.module.less'
import { Link } from 'react-router'
import { useLazyLogoutQuery } from '@/store/auth/AuthApi'
import { useAppSelector } from '@/redux'
import { memo } from 'react'
import { routeLinks } from '@/shared/routeLinks'
import { userInfoSelector } from '@/store/common/common.selectors'
import { UserIcon } from '@/assets/icons/useIcon'

export const UserPopover = memo(() => {
  const [logout, response] = useLazyLogoutQuery()
  const auth = useAppSelector((state) => state?.auth)
  const userInfo = useAppSelector(userInfoSelector)
  const isReadSettings = !!userInfo?.permissions?.read_settings

  return (
    <Popover
      placement="bottomRight"
      getPopupContainer={() => document.body}
      content={
        <div className={styles.contentRoot}>
          {isReadSettings && (
            <Link to={routeLinks.lc_profile} onlyActiveOnIndex={false}>
              Profile
            </Link>
          )}
          {isReadSettings && (
            <Link to={routeLinks.lc_settings} onlyActiveOnIndex={false}>
              Account
            </Link>
          )}
          <button disabled={response.isFetching} onClick={() => logout()}>
            Logout
          </button>
        </div>
      }
    >
      <div className={styles.root}>
        <div className={styles.avatar}>
          {auth?.company?.pathS3 ? (
            <img src={auth?.company?.pathS3} alt="avatar" />
          ) : (
            <UserIcon />
          )}
        </div>
        <span>
          {auth?.name || auth?.user?.username || auth?.user?.companyName}
        </span>
      </div>
    </Popover>
  )
})
