import { EditControl } from 'react-leaflet-draw'
import { FeatureGroup } from 'react-leaflet'
import React, { useEffect, useRef, useState } from 'react'
import { useMap } from '../MapContext'
import L from 'leaflet'
import turf from 'turf-inside'
import _ from 'lodash'
import { bindPopup } from '../helpers/popUp'
import { pointIcon } from '../icon'
import NewShapeNameModal from '../../../pages/Run/components/Filter/NewShapeNameModal'
import { create, edit } from '../../../pages/Zones/components/crud'
import { useSelector } from 'react-redux'
import { getLoaderState } from '../../../redux/common/selectors'
import { totalDistanceWithYd } from '@/helpers/totalInfoByOsrmWithYd'

export default function (props) {
  let {
    drawTool,
    setDrawTool,
    scope,
    mapRef,
    onCreatedFn,
    figureClick,
    ridersOnMap,
  } = useMap()

  const [zoneNewModalOpen, setZoneNewModalOpen] = useState(false)

  let drawControl = useRef({})

  let layerRef = useRef()

  const loaderState = useSelector(getLoaderState)
  const [isLoading, setLoading] = useState(false)
  const [currentLayer, setCurrentLayer] = useState([])
  const isLoadingStart = loaderState === 'loadingStart'

  useEffect(() => {
    setLoading(isLoadingStart)
  }, [loaderState, isLoading])

  useEffect(() => {
    if (currentLayer && !drawTool) {
      currentLayer.forEach((layer) => createdChoose(layer))
    }
  }, [isLoading, ridersOnMap.current])

  useEffect(() => {
    if (!drawTool) {
      drawControl.current._toolbars.draw._modes.circle.handler.disable()
    }

    if (drawTool && drawTool !== 'polyline') {
      onDrawType(drawTool)
    }
  }, [drawTool])

  const onDrawType = (type) => {
    let tool = type
    let shapeOptions = {}

    if (tool === 'radius' || tool === 'oval') type = `circle`
    let Draw = drawControl.current._toolbars.draw._modes[type]

    if (tool === 'radius') {
      shapeOptions = {
        fillColor: 'rgba(0, 0, 0, 0)\n' + '\n',
        color: '#000',
        radiusType: true,
      }
    }

    if (tool === 'rectangle' || tool === 'polygon' || tool === 'oval') {
      shapeOptions = {
        fillColor: '#a70000',
        color: 'rgba(255, 120, 120, 0.45)\n' + '\n',
      }
    }

    Draw.handler.setOptions({
      showArea: false,
      shapeOptions: shapeOptions,
    })

    // PAINT START HERE
    Draw.handler.enable()
  }

  const onMounted = (drawC) => {
    drawControl.current = drawC

    drawControl.current.setDrawingOptions({
      circle: {
        metric: false,
        feet: false,
      },
    })
  }

  const getStops = (layer) => {
    let { props, state } = scope.current
    if (!props.activeRuns[0]) return false

    let route = null

    state.routes.forEach((gr) => {
      gr.routes.find((x) => {
        if (x.id === props.activeRuns[0]) {
          route = x
          return true
        }
      })
    })

    if (route) {
      let STOP_SELECTED = 0
      let stopPoints = []
      let stopsAll
      stopsAll = _.compact(
        route.points && route.points.map((p) => (p.type === 2 ? p : false))
      )

      stopsAll.forEach((item) => {
        let latLng
        if (!item.address) return false
        if (item.address.lat === '' && item.address.lon === '') {
          let currentAddress = item.address.address.split(';')
          latLng = {
            lat: currentAddress[0],
            lng: currentAddress[1],
          }
        } else {
          latLng = {
            lat: item.address.lat,
            lng: item.address.lon,
          }
        }

        if (layer.contains(latLng)) {
          stopPoints && stopPoints.push({ ...latLng, id: item.id })
          STOP_SELECTED = item.id
        }
      })
      return { stopPoints: stopPoints || [], STOP_SELECTED: STOP_SELECTED }
    }
  }

  function radiusMiInfo(layer) {
    let r = Math.floor(layer._mRadius) / 1000
    r = r / 1.609
    r = r.toFixed(2)

    layer.types = 'circle'
    layer.id_flag = Math.round(1)

    let allElementsLayersGr = new L.layerGroup(layer)

    if (drawTool === 'radius') {
      let lat = layer._latlng.lat
      let lng = layer._latlng.lng

      let pointA = new L.LatLng(lat, lng)
      let pointB = new L.LatLng(lat, layer.getBounds()._northEast.lng)
      let pointList = [pointA, pointB]

      let markerIcons = new L.marker(pointA, {
        id: layer._leaflet_id,
        icon: pointIcon(),
      })
      let polylineCircle = new L.polyline(pointList, {
        color: '#000',
        weight: 3,
        opacity: 1,
        id: layer._leaflet_id,
        className: 'lineCircle',
        lineJoin: 'round',
      })

      let tooltips = layer.bindTooltip(totalDistanceWithYd(Number(r), true), {
        permanent: true,
        interactive: true,
        placement: 'right',
        direction: 'left',
        className: 'toolTipCirckle',
      })

      layer.line = polylineCircle

      allElementsLayersGr.addLayer(markerIcons)
      allElementsLayersGr.addLayer(polylineCircle)
      allElementsLayersGr.addLayer(tooltips)

      polylineCircle.addTo(mapRef.current.contextValue.map)
      tooltips.addTo(mapRef.current.contextValue.map).openTooltip({
        lat: lat,
        lng: layer.getBounds()._northEast.lng,
      })
    }
    return allElementsLayersGr
  }

  function getRiderGroups(layer) {
    let ridersAll = ridersOnMap.current
    let final = []

    ridersAll.forEach((x) => {
      let position = {
        lat: x.position[0],
        lng: x.position[1],
      }

      if (layer.contains(position)) {
        final.push(x)
      }
    })

    if (final.length > 1) {
      final = _.groupBy(final, `position`)
    }

    return final
  }

  const openModalFn = () => {
    setZoneNewModalOpen(true)
  }

  const _onCreated = (e) => {
    let type = e.layerType
    let layer = e.layer
    layerRef.current = layer
    toolsOptions()
    let { stopPoints, STOP_SELECTED } = getStops(layer)
    let pinsOnMapInGroup = getRiderGroups(layer)

    let radGr = null
    if (type === 'circle') radGr = radiusMiInfo(layer)
    bindPopup(
      e,
      layer,
      scope.current,
      stopPoints,
      drawControl,
      mapRef,
      STOP_SELECTED,
      figureClick,
      radGr,
      pinsOnMapInGroup,
      openModalFn,
      isLoading
    )

    layer.on('click', (u) => layer.closePopup())
    setDrawTool(null)
  }

  function toolsOptions() {
    L.Polygon.include({
      contains: function (latLng) {
        return turf(new L.Marker(latLng).toGeoJSON(), this.toGeoJSON())
      },
    })
    L.Rectangle.include({
      contains: function (latLng) {
        return this.getBounds().contains(latLng)
      },
    })

    L.CircleMarker.include({
      contains: function (latLng) {
        return false
      },
    })

    L.Circle.include({
      contains: function (latLng) {
        return this.getLatLng().distanceTo(latLng) < this.getRadius()
      },
    })
  }

  function createdChoose(e) {
    if (typeof onCreatedFn.current === 'function') onCreatedFn.current(e)
    else _onCreated(e)
  }

  let save = (data) => {
    let geoJson = layerRef.current.toGeoJSON()
    if (layerRef.current.types === 'circle') {
      geoJson.properties = {
        subType: 'Circle',
        radius: layerRef.current._mRadius,
      }
    }

    create(data).then((r) => {
      r.data = { type: 'FeatureCollection', features: [geoJson] }
      edit(r.id, r).then((response) => {
        props.saveNewShape(response)
      })
    })
  }

  return (
    <FeatureGroup>
      {zoneNewModalOpen && (
        <NewShapeNameModal
          close={() => setZoneNewModalOpen(false)}
          save={save}
        />
      )}
      <EditControl
        onMounted={onMounted}
        position="topright"
        onCreated={(e) => {
          createdChoose(e)
          currentLayer.push(e)
        }}
      />
    </FeatureGroup>
  )
}
