export const DropOffIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="27"
      height="27"
      viewBox="0 0 27 27"
    >
      <defs>
        <filter
          id="prefix__a"
          width="163.2%"
          height="163.2%"
          x="-31.6%"
          y="-31.6%"
          filterUnits="objectBoundingBox"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.280400815 0"
          />
        </filter>
        <circle id="prefix__b" cx="9.5" cy="9.5" r="9.5" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(4 4)">
        <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
        <use fill="#F5F5F5" xlinkHref="#prefix__b" />
        <path
          fill="#737373"
          fillRule="nonzero"
          d="M5.699 10.934h.997c.162 0 .319-.026.47-.078.153-.052.288-.139.407-.26.118-.121.213-.279.285-.474.07-.195.107-.432.107-.712 0-.257-.025-.488-.075-.695-.05-.207-.132-.383-.246-.53-.114-.148-.265-.26-.453-.34-.187-.078-.419-.117-.694-.117h-.798v3.207zM4.58 6.788h2.195c.327 0 .633.052.915.156.283.105.527.262.734.47.207.21.368.471.485.784.116.314.174.682.174 1.105 0 .37-.047.712-.142 1.026-.095.313-.239.584-.431.812-.193.228-.433.407-.72.538-.287.13-.626.196-1.015.196H4.58V6.788zm6.178 2.565c0 .214.024.421.074.623.05.202.13.384.24.545.108.162.25.291.427.389.175.097.39.146.64.146.253 0 .466-.05.642-.146.176-.098.318-.227.428-.389.109-.161.188-.343.238-.545.05-.202.075-.41.075-.623 0-.224-.025-.44-.075-.649-.05-.209-.13-.395-.238-.559-.11-.164-.252-.294-.428-.392-.176-.097-.39-.146-.641-.146-.252 0-.466.049-.641.146-.176.098-.319.228-.428.392-.11.164-.189.35-.239.56-.05.208-.074.424-.074.648zm-1.12 0c0-.38.058-.733.172-1.058.114-.326.278-.61.492-.852.213-.242.476-.432.787-.57.311-.138.661-.206 1.05-.206.395 0 .747.068 1.055.206.31.138.57.328.784.57.214.243.378.526.492.852.114.325.17.678.17 1.058 0 .37-.056.716-.17 1.036-.114.321-.278.6-.492.838-.214.237-.475.424-.784.559-.308.135-.66.203-1.054.203-.39 0-.74-.068-1.051-.203-.311-.135-.574-.322-.787-.56-.214-.237-.378-.516-.492-.837-.114-.32-.171-.666-.171-1.036z"
        />
      </g>
    </svg>
  )
}
