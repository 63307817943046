import React from 'react'

export const EditIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M8.154 2.185l2.668 2.668-6.752 6.752-2.667-2.667 6.751-6.753zm4.692-.643l-1.19-1.19a1.18 1.18 0 0 0-1.667 0l-1.14 1.14 2.668 2.667 1.33-1.33a.91.91 0 0 0 0-1.287zM.007 12.687a.304.304 0 0 0 .368.361l2.972-.72L.681 9.66l-.674 3.027z"
      />
    </svg>
  )
}
