import React from 'react'
import Modal from '../../../../../../components/BaseModal'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import './index.less'

export default function InactiveRunModal (props) {
  const closeModal = () => {
    props.close()
  }

  return (
    <Modal title={'Warning!'} closeFn={props.close} >
      <div >
        <div className='spanMessage'>
          <Row>
            <Col span={24}>
              <span>
                  Please select or create run first.
              </span>
            </Col>
          </Row>
        </div>
      </div>

      <div className='center'>
        <div className='buttons'>
          <button
            className='save'
            onClick={closeModal}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  )
}
