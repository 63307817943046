import { ModalFooter } from '@/entities/ui/NewModal/components/ModalFooter'
import { create } from 'zustand'

interface IStore {
  isLoading: boolean
  setState: (v: Partial<IStore>) => void
}

export const useForceEditRiderStore = create<IStore>((set, get) => ({
  isLoading: false,
  setState: (params) => {
    set({ ...get(), ...params })
  },
}))

export const ForceEditRidingRider = ({ action }: { action: () => void }) => {
  const isLoading = useForceEditRiderStore((state) => state.isLoading)
  return (
    <section>
      <p style={{ marginTop: 8 }}>
        You are about to turn off riding status for a rider on a run.
        <br />
        This action will remove rider from all runs. Are you sure?
      </p>
      <ModalFooter
        buttonWidth={80}
        success={{
          text: 'Ok',
          isLoading: isLoading,
          action: action,
        }}
        cancel={{
          text: 'Cancel',
        }}
      />
    </section>
  )
}
