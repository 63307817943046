import React, { Component } from 'react'
import { connect } from 'react-redux'
import PolylineText from '../../../../../../components/MapDefault/src/polyline-text/PolylineText'
import {
  arrowOptions,
  assignedPopup,
  directionsPathOptionsWith2Colors,
  fnMouseOut,
  fnMouseOver,
  getIcon,
  getRouteMarkers,
} from '../../../../../../components/MapDefault/mapSpecialFns'
import { Marker } from 'react-leaflet'
import L from 'leaflet'
import _ from 'lodash'
import ViewDeleteModal from '../../../../../../components/MapDefault/DeleteStop'
import childStopInBus from '../../../StopsAndPinsFunctions/childStopInBus'
import childStopInTransfer from '../../../StopsAndPinsFunctions/childStopInTransfer'
import * as runActions from '../../../../../../redux/routesView/actions'
import { ModalAnchor } from '../../../ModalAnchor'
import i18n from '../../../../../../i18n'
import { iconRedirection, iconRiderInStop } from './Icons'
import { getRotationPositionAndIndexOfMarker } from '@/helpers/points'
import { isConvertibleToNumber } from '../../../../../../redux/routesView/actions'
import { useRulerStore } from '@/components/MapDefault/ruler/rulerStore'

// import { Icon } from '@/assets/icons/tsx.svg'

class _RouteActive extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeRuns: this.props.activeRuns,
      routesView: this.props.routesView,
      redirectPointModal: false,
      activeRunIndex: 0,
      delProps: {},

      modalAnchorEdit: false,
      isLoading: false,
      showRidersInStop: false,
      localizedRiders: [],
      activeStopId: '',
      isGoingToDrag: false,
      isDraggingContinues: false,
    }
    this.markerRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loader !== this.props.loader) {
      this.setState({
        isLoading: this.props.loader === 'loadingStart',
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.state.isGoingToDrag) {
      return false
    } else {
      return true
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.routesView !== nextProps.routesView) {
      this.setState({
        routesView: nextProps.routesView,
        isGoingToDrag: false,
      })
    }

    if (this.state.activeRuns !== nextProps.activeRuns) {
      if (nextProps.activeRuns[0] === nextProps.activeRuns[1]) return false
      this.setState({
        activeRuns: nextProps.activeRuns,
        isGoingToDrag: false,
      })
    }

    if (nextProps.activeRun) {
      let index = (nextProps.activeRuns || []).findIndex(
        (x) => x === nextProps.activeRun
      )
      if (this.state.activeRunIndex === index) return
      this.setState({
        activeRunIndex: index,
        isGoingToDrag: false,
      })
    }
  }

  _findRunFn = (runId) => {
    let { routesView } = this.state

    let activeRunObj = null
    routesView.forEach((gr) => {
      ;(gr.routes || []).find((x) => {
        if (x.id === runId) activeRunObj = x
      })
    })
    return activeRunObj
  }

  generate = () => {
    let { activeRuns, routesView } = this.state
    if (!activeRuns[0] || !routesView || routesView.length === 0) return []

    let activeRoutes = []

    activeRuns.forEach((x, i) => {
      if (!x) return false
      activeRoutes[i] = this._findRunFn(x)
    })

    let newArr = activeRoutes.map((route) => {
      if (
        route &&
        route.points &&
        route.points.length > 1 &&
        route.osrm &&
        route.osrm.geometry
      ) {
        route.geometry = route.osrm.geometry
      }

      if (route && route.points && route.points.length > 0) {
        route.markers = getRouteMarkers(route)
        if (route.points.length === 1) {
          route.geometry = null
        }
      } else if (!route) {
      } else if (!route.points || !route.points.length) {
        route.markers = null
        route.geometry = null
      }
      return route
    })

    if (!newArr[0]) return []

    return newArr
  }

  polyLineContext = (e, type) => {
    if (e.originalEvent.button != 2) {
      return
    }

    let popup = {
      x: e.latlng.lat,
      y: e.latlng.lng,
    }
    this.props.callBackRedirection(popup)
  }

  childRemoveModal = (item) => {
    this.setState({
      viewDeleteChildModalShow: true,
      delProps: item,
    })
  }

  removeStopInRunView = (item) => {
    let { object } = item
    let delProps = {}
    if (object.type === 3) {
      delProps = {
        title: i18n.t('Delete School'),
        id: object.id,
        deleteObject: object.object.name,
        item: item,
      }
    }

    if (object.type === 2) {
      delProps = {
        title: i18n.t('Delete Stop'),
        id: object.id,
        deleteObject: object.busStopTitle,
        item: item,
      }
    }

    if (object.type === 6) {
      delProps = {
        title: i18n.t('Delete Depot'),
        id: object.id,
        deleteObject: object.object.name,
        item: item,
      }
    }
    if (object.type === 1) {
      delProps = {
        deleteObject: object.object.full_name,
        id: object.id,
        title: i18n.t('Delete Rider'),
        item: item,
      }
    }

    if (object.type === 5) {
      delProps = {
        deleteObject: object.object.full_name,
        id: object.id,
        title: 'Remove Transfer Stop',
        item: item,
      }
    }

    this.setState({
      viewDeleteModalShow: true,
      delProps: delProps,
    })
  }

  childRemoveTransfer = (item) => {
    let data = {
      id: item.data.point_id,
      child_address_id: item.data.child_address_id,
      childId: item.id,
      routeId: item.data.routeId,
      name: item.name,
    }
    this.setState(
      {
        delProps: data,
      },
      () => {
        this.setState({
          viewDeleteChildModalShow: !this.state.viewDeleteChildModalShow,
          removeTransfer: true,
        })
      }
    )
  }

  isWriteRoutes = this.props.auth.permissions?.write_routes !== 1

  childRiders = (marker, readOnly) => {
    let { isLoading } = this.state
    let riders = []
    let ridersTransfer = []

    let routesActive = this.generate()

    if (_.isEmpty(routesActive)) {
      return
    }

    if (!routesActive[0].points) return false

    let sc = routesActive[0].points.filter((item) => item.type === 3)

    if (marker.object.type * 1 === 3) {
      if (!_.isEmpty(sc)) {
        let obj = (sc || []).find((x) => x.id === marker.object.id)
        if (!obj) {
          return
        }

        if (!obj.routePointChildren) {
          return
        }

        let pickUpChild = obj.routePointChildren.filter((x) => {
          if (x.childaddress && x.childaddress.type === 1) return true
        })
        let dropOffChild = obj.routePointChildren.filter((x) => {
          if (x.childaddress && x.childaddress.type === 2) return true
        })

        pickUpChild.map((address) => {
          let child = (this.props.childrenList || []).find(
            (child) => child.id === address.childaddress.child_id
          )
          if (!child) return false
          child.address = address
          riders.push({
            name: child.full_name,
            id: child.id,
            address: child.address,
          })
        })

        dropOffChild.map((address) => {
          if (this.props.childrenList.length === 0) return false

          let child = (this.props.childrenList || []).find(
            (child) => child.id === address.childaddress.child_id
          )
          if (!child) return false
          child.address = address
          riders.push({
            name: child.full_name,
            id: child.id,
            address: child.address,
          })
        })
      }
    }

    if (marker.object.type * 1 === 5) {
      if (!marker.object) {
        return
      }

      if (_.isEmpty(marker.object.routePointTransferChild)) {
        return
      }

      let pickUpChild = marker.object.routePointTransferChild.filter(
        (x) => x.childAddress.type === 1
      )
      let dropOffChild = marker.object.routePointTransferChild.filter(
        (x) => x.childAddress.type === 2
      )

      if (pickUpChild) {
        pickUpChild.map((item) => {
          let child = item.childAddress.child
          item.routeId = marker.object.routeId

          ridersTransfer.push({
            name: child.full_name,
            id: child.id,
            address: item.address,
            data: item,
          })
        })
      }

      if (dropOffChild) {
        dropOffChild.map((item) => {
          let child = item.childAddress.child
          item.routeId = marker.object.routeId

          ridersTransfer.push({
            name: child.full_name,
            id: child.id,
            address: item.address,
            data: item,
          })
        })
      }

      if (ridersTransfer) {
        return ridersTransfer.map((item) => {
          return (
            <div className="lineBottom">
              <span className="riderIcon flex1">
                {item.name}{' '}
                {!readOnly && (
                  <div className="marginLeft flex1">
                    {' '}
                    {item.data && item.data.childAddress.type === 1 ? (
                      <span className="infoDo">{i18n.t('DO')}</span>
                    ) : (
                      <span className="infoDo">{i18n.t('PU')}</span>
                    )}{' '}
                    {!this.isWriteRoutes && (
                      <button
                        disabled={isLoading}
                        onClick={(e) => this.childRemoveTransfer(item)}
                        className="removeFromRun disabledBtn"
                        style={{ margin: 0 }}
                      >
                        &nbsp;
                      </button>
                    )}{' '}
                  </div>
                )}
              </span>
            </div>
          )
        })
      }
    }

    if (marker.object.type * 1 === 2) {
      if (!marker.object) {
        return
      }

      let pickUpChild = marker.object.routePointChildren.filter((x) => {
        if (x.childaddress && x.childaddress.type === 1) return true
      })
      let dropOffChild = marker.object.routePointChildren.filter((x) => {
        if (x.childaddress && x.childaddress.type === 2) return true
      })

      pickUpChild.map((address) => {
        let child = (marker.object.object || []).find(
          (child) => child.id === address.childaddress.child_id
        )
        if (!child) {
          child = { address }
          riders.push({
            name: child.full_name,
            id: child.id,
            address: child.address,
          })
        }

        child.address = address
        riders.push({
          name: child.full_name,
          id: child.id,
          address: child.address,
        })
      })

      dropOffChild.map((address) => {
        let child = (marker.object.object || []).find(
          (child) => child.id === address.childaddress.child_id
        )
        if (!child) {
          child = { address }
          riders.push({
            name: child.full_name,
            id: child.id,
            address: child.address,
          })
        }
        child.address = address
        riders.push({
          name: child.full_name,
          id: child.id,
          address: child.address,
        })
      })
    }

    return riders.map((item) => {
      return (
        <div className="lineBottom">
          <span className="riderIcon">
            {item.name}{' '}
            {!readOnly && !this.isWriteRoutes && (
              <button
                disabled={isLoading}
                onClick={(e) => this.childRemoveModal(item)}
                className="removeFromRun disabledBtn"
              >
                &nbsp;
              </button>
            )}
          </span>
        </div>
      )
    })
  }

  removeStopFromRun = (stopObj) => {
    let object = stopObj.item.object
    this.props.removeStopFromRun(
      stopObj.id,
      object.position,
      object.routeId,
      object
    )
    this.closeModalDel()
  }

  removeChildFromStop = (item) => {
    let routesActiveId = this.state.activeRuns[0]
    if (this.state.removeTransfer) {
      this.props.removeChildFromStop(
        this.state.delProps,
        this.state.delProps.routeId
      )
    } else {
      let data = {
        id: item.address.point_id,
        child_address_id: item.address.child_address_id,
        childId: item.address.child_id,
        type: item.address.childaddress.type,
      }

      this.props.removeChildFromStop(data, routesActiveId, item.address)
    }

    this.closeModalDel()
  }

  newActiveRun = (e, routeId, i) => {
    let { activeRunIndex, activeRuns } = this.state
    if (activeRunIndex !== i) {
      this.props.setActiveRun(activeRuns, routeId)

      this.setState({
        activeRunIndex: i,
      })
    }
  }

  closeModalDel = () => {
    this.setState({
      viewDeleteModalShow: false,
      viewDeleteChildModalShow: false,
      removeTransfer: false,
    })
  }

  dragPinStart = (e) => {
    this.setState({ isDraggingContinues: true })
    return this.state.isGoingToDrag && e.sourceTarget.setZIndexOffset(5000)
  }

  getStops = (points) => {
    let stops = points.map((x) => {
      if (x.type === 2) return x
      if (x.type === 5) return x
    })
    return _.compact(stops)
  }

  distance = (lat1, lon1, lat2, lon2, unit) => {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0
    } else {
      let radlat1 = (Math.PI * lat1) / 180
      let radlat2 = (Math.PI * lat2) / 180
      let theta = lon1 - lon2
      let radtheta = (Math.PI * theta) / 180
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
      if (dist > 1) {
        dist = 1
      }
      dist = Math.acos(dist)
      dist = (dist * 180) / Math.PI
      dist = dist * 60 * 1.1515
      if (unit == 'K') {
        dist = dist * 1.609344
      }
      if (unit == 'N') {
        dist = dist * 0.8684
      }
      return dist
    }
  }

  dragStopChild = (e, marker, run) => {
    let stops = this.getStops(run.points)
    let lanLon = {}
    let d = []

    stops.forEach((item) => {
      if (item.address.lat === '' && item.address.lon === '') {
        let currentAddress = item.address.address.split(';')
        lanLon = {
          lat: currentAddress[0],
          lng: currentAddress[1],
        }
      } else {
        lanLon = {
          lat: item.address.lat,
          lng: item.address.lon,
        }
      }

      let n = this.distance(
        lanLon.lat,
        lanLon.lng,
        e.target._latlng.lat,
        e.target._latlng.lng,
        'K'
      )

      d.push({ distance: n, id: item.id })
    })

    let min = 20000000000
    let itemId = 0

    d.forEach((item) => {
      if (min > item.distance) {
        min = item.distance
        itemId = item.id
      }
    })

    let distance = min.toFixed(1)

    if (parseFloat(distance) <= 0.2) {
      let pointDestination = (run.points || []).find((x) => x.id === itemId)
      if (pointDestination.type === 5) {
        this.props.dispatch(
          childStopInTransfer(
            pointDestination,
            marker.object.routeId,
            marker.object
          )
        )
      } else {
        let pointPosition = pointDestination.position
        this.props.dispatch(
          childStopInBus(marker.object.id, marker.object.routeId, itemId)
        )
      }
    } else {
      let newLatLng = new L.LatLng(marker.location[0], marker.location[1])
      e.target.setLatLng(newLatLng)
    }
  }

  dragPinRouter = (e, marker, run) => {
    this.setState({
      isGoingToDrag: false,
      isDraggingContinues: false,
    })
    if (marker.object.type === 1) {
      this.dragStopChild(e, marker, run)
    } else {
      this.props.moveRedirection(e, marker)
    }
  }

  anchorEdit = (marker) => {
    let time_zone = ''
    ;(this.props.routesView || []).find((x) => {
      ;(x.routes || []).find((y) => {
        if (y.id === this.props.activeRun) {
          time_zone = y.time_zone
          return
        }
      })
    })

    let object = marker.object

    let data = {
      id: object.id,
      routeId: object.routeId,
      name:
        object.busStopTitle ||
        object.name ||
        object.object.name ||
        object.object.full_name,
      type: '',
      position: object.routePointRoute.view_position - 1,
      address: object.address,
      time: object.routePointRoute.time,
      waittime: object.routeWaittime,
      timeZone: time_zone,
      osrmTime: marker.osrmTime,
      isAnchor: true,
    }
    this.setState({
      modalAnchorEdit: true,
      data: data,
    })
  }

  saveAnchor = (data) => {
    this.props.dispatch(runActions.editAnchor(data))
  }

  closeModal = () => {
    this.setState({
      modalAnchorEdit: false,
      data: {},
    })
  }

  anchorDelete = (marker) => {
    let object = marker.object
    let data = {
      id: object.id,
      position: object.position,
      routeId: object.routeId,
      isAnchor: 0,
      time: null,
      routeWaittime: object.routeWaittime,
    }
    this.props.dispatch(runActions.editAnchor(data))
  }

  render() {
    let {
      activeRunIndex,
      viewDeleteModalShow,
      viewDeleteChildModalShow,
      delProps,
      isLoading,
    } = this.state

    let routesActive = this.generate()
    if (routesActive.length === 0) return <div />

    let assignedPopupBind = assignedPopup.bind(this)

    const isRule = this.props.isRule
    return routesActive.map((r, i) => {
      if (!r) return false
      let active =
        (activeRunIndex === 1 && i === 1) || (activeRunIndex === 0 && i === 0)
      return (
        <div key={i}>
          {r.geometry && (
            <PolylineText
              arrowOptions={arrowOptions()}
              pathOptions={directionsPathOptionsWith2Colors(active)}
              active={active}
              geometry={r.geometry}
              interactive={!isRule}
              onClick={(e) => this.newActiveRun(e, r.id, i)}
              onMousedown={(e) => this.polyLineContext(e, 'poly', routesActive)}
              ref={this.polylineRef}
            />
          )}

          {r.markers &&
            r.markers.map((marker) => {
              if (marker && !marker.location) return false
              let location = marker && marker.location
              if (location) {
                if (!location[0] || !location[1]) {
                  let currentAddress =
                    marker.object &&
                    marker.object.address &&
                    marker.object.address.address &&
                    marker.object.address.address.split(';')
                  if (!currentAddress) return
                  let lat = currentAddress && currentAddress[0]
                  let lon = currentAddress && currentAddress[1]
                  location = [lat, lon]
                }
              }
              if (!marker) return
              marker.object.routeId = r.id

              let index = 1000

              index += marker.object.position * 100

              const childMarkers = (e) => {
                const activeStop = this.state.activeStopId === marker.id
                const childrenPositions =
                  marker.object.routePointChildren &&
                  marker.object.routePointChildren.map((child) => {
                    if (
                      child &&
                      child.childaddress &&
                      child.childaddress.address
                    ) {
                      if (activeStop) {
                        const lat =
                          child.childaddress.address.lat &&
                          child.childaddress.address.lat
                        const lon =
                          child.childaddress.address.lon &&
                          child.childaddress.address.lon
                        return [lat, lon]
                      }
                    }
                  })

                if (childrenPositions && activeStop) {
                  this.setState({
                    localizedRiders: childrenPositions,
                  })
                }
              }
              if (
                marker &&
                isConvertibleToNumber(location[0]) &&
                isConvertibleToNumber(location[1])
              ) {
                const { rotation, zIndex, position } =
                  getRotationPositionAndIndexOfMarker(r.markers, marker)

                if (marker) {
                  return (
                    <React.Fragment>
                      <Marker
                        key={`${marker.object?.id}_${marker.object?.position}_${Math.floor(Date.now() / 5000)}`}
                        draggable={
                          !this.isWriteRoutes &&
                          (!isLoading && !isRule
                            ? marker.object.type === 1 ||
                              marker.object.type === 4 ||
                              marker.object.type === 2 ||
                              marker.object.type === 5
                            : false)
                        }
                        ref={this.markerRef}
                        onDragStart={(e) => {
                          !this.isWriteRoutes && this.dragPinStart(e, true)
                        }}
                        onDragend={(e) => {
                          if (!isRule) {
                            !this.isWriteRoutes &&
                              this.dragPinRouter(e, marker, r)
                            this.setState({
                              isGoingToDrag: false,
                              isLoading: true,
                            })
                          }
                        }}
                        onContextMenu={this.contentClick}
                        onMouseOver={(e) => {
                          if (!isRule) {
                            fnMouseOver(e)
                            if (marker.object.type === 2) {
                              this.setState({
                                showRidersInStop: true,
                                activeStopId: marker.id,
                              })
                              childMarkers(e)
                            }
                            this.setState({
                              isGoingToDrag: true,
                              isLoading: this.props.loader === 'loadingStart',
                            })
                          }
                        }}
                        onMouseOut={(e) => {
                          if (!isRule) {
                            if (!this.state.isDraggingContinues) {
                              this.setState({
                                isGoingToDrag: false,
                              })
                            }
                            fnMouseOut(e)
                            if (marker.object.type === 2) {
                              this.setState({
                                showRidersInStop: false,
                              })
                            }
                          }
                        }}
                        zIndexOffset={zIndex}
                        position={position}
                        icon={
                          marker.object.type === 4
                            ? iconRedirection
                            : marker.icon
                        }
                        interactive={!isRule}
                        rotationAngle={rotation}
                      >
                        {assignedPopupBind(marker, false, isLoading)}
                      </Marker>
                      {this.state.localizedRiders.length &&
                        this.state.showRidersInStop &&
                        this.state.localizedRiders.map((childMarker, i) => {
                          return (
                            childMarker &&
                            childMarker.length && (
                              <Marker
                                key={i}
                                draggable={false}
                                zIndexOffset={index}
                                position={childMarker}
                                icon={iconRiderInStop}
                                interactive={!isRule}
                              />
                            )
                          )
                        })}
                    </React.Fragment>
                  )
                }
                return false
              }
            })}

          {viewDeleteModalShow && (
            <ViewDeleteModal
              closeFn={this.closeModalDel}
              title={delProps.title}
              child={true}
              deleteObject={delProps.deleteObject}
              deleteFn={() => this.removeStopFromRun(delProps)}
            />
          )}

          {viewDeleteChildModalShow && (
            <ViewDeleteModal
              closeFn={this.closeModalDel}
              title={`Remove Child`}
              child={true}
              deleteObject={delProps.name}
              deleteFn={() => this.removeChildFromStop(delProps)}
            />
          )}

          {this.state.modalAnchorEdit && (
            <ModalAnchor
              data={{ ...this.state.data }}
              saveFn={this.saveAnchor}
              closeFn={this.closeModal}
            />
          )}
        </div>
      )
    })
  }
}

function mapStateToProps(state) {
  return {
    routesView: state.routesView.routes,
    childrenList: state.children.childrenList,
    loader: state.headerLoader.loading,
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

const RouteActive = (props) => {
  const isRule = useRulerStore((state) => state.isRule)

  return <_RouteActive isRule={isRule} {...props} />
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteActive)
