import L from "leaflet";
import _ from "lodash";

export default function() {

  if(!this.map.current) return;
  let markers = []

  this.map.current.contextValue.map.eachLayer((layer) => {
    if(layer instanceof L.Marker){
      let pos = layer.getLatLng();
      if(pos) markers.push(layer)
    }
  })

  if(markers.length){
    let group = _.groupBy(markers, `_latlng` )
    for (let key in group) {
      let count = group[key].length;
      let rotation_angle = 15;
      if(count > 1) {

        if (rotation_angle * count > 160) {
          rotation_angle = Math.floor(160 / count);
        }
        let total_angle = rotation_angle * (count - 1);
        let total_width = 48 * Math.sqrt(2 - 2* Math.cos(Math.PI*total_angle/180));
        let step = Math.round(total_width/count);

        group[key].forEach((layer,i) => {
          if (!layer._popup || !layer._popup.options) return;

          let offset = layer._popup.options.offset;
          let fn =[ i*step - total_width / 2, offset[1] ] ;
          layer._popup.options.offset = fn;
         // layer.setRotationAngle(i*rotation_angle - total_angle / 2)

        });
      }else{
       let layer = group[key][0];
        //if (!layer._popup || !layer._popup.options) return;
        // layer._popup.options.offset = [0,-14];
        //layer.setRotationAngle(0);
      }
    }
  }
}