import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Map } from 'react-leaflet'
import './index.less'
import { getGpsRecordsV2, getGpsRunData } from '@/redux/gps/actions'
import { Filter } from './components/DetailsFilter'
import { getBounds } from '@/components/RepeatFunctions/mapGenerate'
import { Controls } from '@/pages/Gps/NewGps/components/Controls'
import { connectToMqtt, socketEnable } from './helpers/sockets'
import { getCompanyId } from '@/redux/gps/selectors'
import { useGpsDetailsStore } from '@/pages/Gps/NewGpsDetails/store'
import { RunPolyline } from '@/pages/Gps/NewGpsDetails/components/RunPolyline'
import { BusPosition } from '@/pages/Gps/NewGpsDetails/components/BusPosition'
import { checkIfToday } from '@/pages/Gps/NewGps/helpers'
import { TimeLine } from '@/pages/Gps/NewGpsDetails/components/TimeLine'
import { StopsPins } from '@/pages/Gps/NewGpsDetails/components/StopsPins'
import { Loader } from '@/pages/Gps/NewGpsDetails/components/Loader'
import { MqttClient } from 'mqtt'
import { Socket } from 'socket.io-client'
import { AppDispatch } from '@/redux'

export const GpsDetailPage = ({ timestamp }) => {
  const mapRef = useRef<Map | null>(null)
  const socket = useRef<Socket | null>(null)

  const [mqttClient, setMqttClient] = useState<MqttClient | null>(null)

  const actualCompanyId = useSelector(getCompanyId)
  const { gpsInfo: run } = useGpsDetailsStore.getState()
  const { consoleHash, consoleImei } = run

  const isToday = checkIfToday(timestamp)

  useEffect(() => {
    if (consoleImei && isToday) {
      socket.current = socketEnable(consoleImei, actualCompanyId)
    }
    if (consoleHash && isToday) {
      !mqttClient && setMqttClient(connectToMqtt(consoleHash))
    }
    return () => {
      socket.current && socket.current.close()
      mqttClient && mqttClient.end()
    }
  }, [actualCompanyId, consoleImei, consoleHash, isToday])

  useEffect(() => {
    mapRef.current && useGpsDetailsStore.getState().setGpsStore({ mapRef })
  }, [])

  return (
    <div id="mainGps">
      <TimeLine />

      <Filter timestamp={timestamp} />

      <Map
        bounds={getBounds(run)}
        ref={(map: Map) => (mapRef.current = map)}
        zoomControl={false}
        style={{ zIndex: 1 }}
        id="gpsDetailMap"
        key={run.id}
      >
        <RunPolyline />

        <BusPosition mqttClient={mqttClient} timestamp={timestamp} />

        <StopsPins />

        <Controls mapRef={mapRef} showTarget={false} />
      </Map>
    </div>
  )
}

export const NewGpsDetailPage: FC = (props: {
  routeParams: { day: string; id: string }
}) => {
  const timestamp = +props.routeParams.day
  const runId = +props.routeParams.id

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(getGpsRunData(runId, timestamp))
    dispatch(getGpsRecordsV2(timestamp))
  }, [dispatch, runId, timestamp])

  const isReload = useGpsDetailsStore((state) => state.isReload)

  if (isReload) return <Loader />

  return <GpsDetailPage timestamp={timestamp} />
}
