import axios from 'axios'
import * as type from './configLocal'
import { browserHistory } from 'react-router'
import * as Sentry from '@sentry/react'

const rootRoute = 'client'
let baseUrl
if (type.localUrl) {
  baseUrl = type.localUrl
}

axios.defaults.baseURL = baseUrl
axios.defaults.headers.common['Authorization'] =
  'Bearer ' + localStorage.getItem('token')

function interceptors(error) {
  // TODO https://stackoverflow.com/questions/49886315/axios-interceptors-response-undefined  Переделать когда js переедет на сервак

  if (error.response && error.response.status === 401) {
    localStorage.removeItem('token')
    browserHistory.push(`/${rootRoute}/signin`)
  } else if ([499, 502, 503].includes(error.response?.status)) {
    Sentry.captureException(error)
  }

  return Promise.reject(error)
}

axios.interceptors.response.use(null, (error) => interceptors(error))

const prepareEndpoint = (
  withContentType,
  withCharset,
  additionalUrl,
  isV2,
  responseType,
  transformRequest
) => {
  const finalBaseUrl = isV2 ? baseUrl?.replace('api-site', 'api/v2') : baseUrl
  const params = {
    baseURL: `${finalBaseUrl}${additionalUrl || ''}`,
  }

  if (withContentType) {
    params.headers = {
      'Content-Type': `application/json${withCharset ? '; charset=UTF-8' : ''}`,
    }
  }

  if (responseType) {
    params.responseType = 'arraybuffer'
  }

  if (transformRequest) {
    params.transformRequest = [
      function (data, headers) {
        delete headers.common.Authorization
        return data
      },
    ]
  }

  return axios.create(params)
}

const api = {
  session: prepareEndpoint(true, true),
  district: prepareEndpoint(true, false, '/districts'),
  schools: prepareEndpoint(true, false, '/schools'),
  run: prepareEndpoint(true, true),
  common: prepareEndpoint(true, true),
  route: prepareEndpoint(),
  emails: prepareEndpoint(),
  routes: prepareEndpoint(true, true),
  def: prepareEndpoint(true, true),
  rtEmail: prepareEndpoint(),
  gpsV2: prepareEndpoint(false, false, '', true),
  riderPdf: prepareEndpoint(false, false, '', false, true),
  routesPdf: prepareEndpoint(false, false, '', false, true),
  logXls: prepareEndpoint(false, false, '', false, true),
  dashboard: '',
  children: prepareEndpoint(true),
  trip: prepareEndpoint(true, false, '', false, false, true),
  userCompany: prepareEndpoint(),
  user: prepareEndpoint(),
  childImport: prepareEndpoint(true, false, '/children'),
  attendances: prepareEndpoint(true, false, '/attendances'),
  bus: prepareEndpoint(true),
  gps: prepareEndpoint(true),
  runV2: prepareEndpoint(false, false, '', true),
  printAttendanceV2: prepareEndpoint(false, false, '', true, true),
}

api.session.interceptors.response.use(null, (error) => interceptors(error))
api.district.interceptors.response.use(null, (error) => interceptors(error))
api.schools.interceptors.response.use(null, (error) => interceptors(error))
api.run.interceptors.response.use(null, (error) => interceptors(error))
api.common.interceptors.response.use(null, (error) => interceptors(error))
api.route.interceptors.response.use(null, (error) => interceptors(error))
api.emails.interceptors.response.use(null, (error) => interceptors(error))
api.routes.interceptors.response.use(null, (error) => interceptors(error))
api.def.interceptors.response.use(null, (error) => interceptors(error))
api.rtEmail.interceptors.response.use(null, (error) => interceptors(error))
api.riderPdf.interceptors.response.use(null, (error) => interceptors(error))
api.routesPdf.interceptors.response.use(null, (error) => interceptors(error))
api.children.interceptors.response.use(null, (error) => interceptors(error))
api.trip.interceptors.response.use(null, (error) => interceptors(error))
api.userCompany.interceptors.response.use(null, (error) => interceptors(error))
api.user.interceptors.response.use(null, (error) => interceptors(error))
api.childImport.interceptors.response.use(null, (error) => interceptors(error))
api.attendances.interceptors.response.use(null, (error) => interceptors(error))
api.bus.interceptors.response.use(null, (error) => interceptors(error))
api.gps.interceptors.response.use(null, (error) => interceptors(error))

export default api
