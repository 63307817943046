import { Popup } from 'react-leaflet'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import React, { FC } from 'react'
import {
  getUniqueId,
  useGpsDetailsStore,
} from '@/pages/Gps/NewGpsDetails/store'
import { PopupHeader } from '@/pages/Gps/NewGpsDetails/components/PopupHeader'
import { GpsRunPoint } from '@/redux/gps/types'
import { getRiderIconByAttendance } from '@/pages/Gps/NewGpsDetails/helpers/getRiderIconByAttendance'

type StopPopupProps = {
  stop: GpsRunPoint
}
export const StopPopup: FC<StopPopupProps> = ({ stop }) => {
  const riders = useGpsDetailsStore.getState().riders

  const run = useGpsDetailsStore.getState().gpsInfo

  return (
    <Popup autoClose={false} offset={[0, -23]}>
      <div className={'popupMaps'}>
        <PopupHeader run={run} stop={stop} />

        <div className={'margin8'}>
          <label className="fontWeight500 medium">{stop.name}</label>
          <Row className="margin5">
            <Col span={24}>
              <span>{stop.address}</span>
            </Col>
          </Row>
          <div className="line1px" />
          <Row className="margin5">
            <Col span={12}>
              <span>
                <span className={'white'}>
                  <span className="textGray">Riders </span>
                  <b>{stop.riders.length}</b>
                </span>
              </span>
            </Col>
            <Col span={12}>
              <span>
                <span className={'white textGray'}> Wait time</span>{' '}
                {stop.waitTime ? stop.waitTime / 60 : 0} {'m'}
              </span>
            </Col>
          </Row>
          <div className="line1px" />
        </div>

        <hr className={'line-popup'} />
        <div
          className={'margin8 scrollable-popup'}
          style={{ marginTop: '4px' }}
        >
          <div className={'column'}>
            <div>
              {stop.riders
                ?.sort((a, b) => a.fullName.localeCompare(b.fullName))
                .map((item, index, arr) => {
                  return (
                    <div key={index}>
                      <span className={'flex alignCenter'}>
                        {getRiderIconByAttendance(getUniqueId(item), riders)}
                        {item.fullName}
                      </span>

                      {index + 1 < arr.length && <div className="line1px" />}
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}
