import { FormProvider, useForm } from 'react-hook-form'
import { useImportField } from '@/entities/modals/RidersImportModal/components/MappingStep/MappingStep.constants'
import { FormSelect } from '@/entities/ui/NewSelect/NewSelect'
import styles from './MappingStep.module.less'
import React, { Fragment } from 'react'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { FormCheckbox } from '@/entities/ui/NewCheckbox/NewCheckbox'
import { useMappingStepConfig } from '@/entities/modals/RidersImportModal/components/MappingStep/MappingStep.hook'

export const MappingStep = ({ sessionId }: { sessionId?: number }) => {
  const { options, defaultValues, handleSubmit, isLoading } =
    useMappingStepConfig(sessionId)

  const methods = useForm({
    defaultValues,
  })

  const rules = (key: string) => {
    if (key === 'cid') {
      return {
        required: {
          value: true,
          message: 'error',
        },
      }
    } else {
      return undefined
    }
  }
  const { formFields } = useImportField()
  return (
    <section>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <p className={styles.paragraph}>
            <span className={styles.orangeCircle} />
            On top of each box below you see the name of the data fields in SBM.
            By clicking inside each box, you will be able to select one or more
            fields from your file to map to the SBM data field.
          </p>
          <p className={styles.paragraph}>
            <span className={styles.orangeCircle} /> Please match each field on
            your spreadsheet using the dropdown menus.
          </p>
          <p className={styles.paragraph}>
            <span className={styles.orangeCircle} /> If you select multiple
            fields from your file to be mapped to one field in SBM, the fields
            will be concatenated separated by a comma. For example, mapping FN
            and LN to Full Name will generate FN, LN in the SBM data base for
            the field "Full Name".
          </p>
          <div className={styles.scrollBlock}>
            <div className={styles.selectsWrapper}>
              {formFields.map((field, index) => (
                <Fragment key={field.key + index}>
                  {field.key !== 'empty' ? (
                    <FormSelect
                      mode="multiple"
                      rules={rules(field.key)}
                      options={options}
                      placeholder="Please select"
                      maxTagTextLength={20}
                      label={field.value}
                      name={field.key}
                      showErrorText={false}
                    />
                  ) : (
                    <div />
                  )}
                </Fragment>
              ))}
            </div>
            <FormCheckbox
              className={styles.checkbox}
              name="force_override_address"
            >
              Force override addresses
            </FormCheckbox>
          </div>
          <div className={styles.footer}>
            <Button isLoading={isLoading} type="submit" buttonColor={'orange'}>
              Import
            </Button>
          </div>
        </form>
      </FormProvider>
    </section>
  )
}
