import { Modal, ModalProps } from 'antd'
import styles from './NewModal.module.less'
import { CloseCircleOutlined } from '@ant-design/icons'
import React, { ReactNode } from 'react'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { useModalStore } from '@/store/modal/ModalStore'
interface Props extends Omit<ModalProps, 'onCancel' | 'modalRender' | 'style'> {
  onClose?: () => void
  children: ReactNode
  title?: ReactNode
  isLoading?: boolean
  onCancel?: () => void
  onSave?: () => void
  isOpen?: boolean
}
export const NewModal = ({ children, ...props }: Props) => {
  return (
    <>{props?.open ? <_NewModal {...props}>{children}</_NewModal> : <></>}</>
  )
}
const _NewModal = ({
  onClose,
  children,
  title,
  onCancel,
  onSave,
  isLoading,
  ...props
}: Props) => {
  const handleClose = onClose ?? useModalStore.getState().onCloseModal
  return (
    <Modal
      modalRender={() => (
        <ModalContent
          className={props.className}
          onClose={handleClose}
          onCancel={onCancel}
          onSave={onSave}
          isLoading={isLoading}
          title={title}
        >
          {children}
        </ModalContent>
      )}
      onCancel={handleClose}
      style={{ top: 160 }}
      {...props}
    />
  )
}

const ModalContent = ({
  children,
  title,
  onClose,
  onCancel,
  onSave,
  isLoading,
  className,
}) => {
  return (
    <section className={cn(styles.root, className)}>
      <div className={styles.closeButtonWrapper}>
        <CloseCircleOutlined onClick={onClose} className={styles.closeIcon} />
      </div>
      <div className={styles.content}>
        {title && <p className={styles.title}>{title}</p>}

        {children}

        {(onCancel || onSave) && (
          <div className={styles.footer}>
            {onCancel && (
              <Button onClick={onCancel} type="button" buttonColor="gray">
                Cancel
              </Button>
            )}
            {onSave && (
              <Button isLoading={isLoading} onClick={onSave}>
                Save
              </Button>
            )}
          </div>
        )}
      </div>
    </section>
  )
}
