export const SchoolIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="16"
      viewBox="0 0 22 16"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M16.83 7.36l.17 2.997c.025.437-.234.841-.778 1.214-.544.374-1.287.67-2.23.887-.942.219-1.963.328-3.063.328s-2.122-.11-3.065-.328c-.942-.218-1.685-.513-2.229-.887-.544-.373-.803-.777-.778-1.214l.17-2.998 5.446 1.717c.14.045.291.067.456.067.164 0 .316-.022.455-.067l5.445-1.717zm4.818-3.368c.14.05.21.148.21.294 0 .145-.07.243-.21.294L11.023 7.92a.4.4 0 0 1-.094.009.4.4 0 0 1-.095-.01L4.648 5.965c-.272.215-.496.567-.673 1.058a6.036 6.036 0 0 0-.323 1.693c.399.228.598.572.598 1.034 0 .436-.183.775-.55 1.015l.55 4.108a.28.28 0 0 1-.076.237.28.28 0 0 1-.228.104H2.125a.28.28 0 0 1-.228-.104.28.28 0 0 1-.076-.237l.55-4.108a1.146 1.146 0 0 1-.55-1.015c0-.462.206-.813.617-1.053.07-1.31.38-2.353.93-3.13L.208 4.58C.07 4.53 0 4.43 0 4.286c0-.146.07-.244.209-.294L10.834.652a.4.4 0 0 1 .095-.01.4.4 0 0 1 .094.01l10.625 3.34z"
      />
    </svg>
  )
}
