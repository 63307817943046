import { Moment } from 'moment/moment'

export enum InputChangingValues {
  start = 'start_date',
  end = 'end_date',
  weeks = 'weeks',
}
export const getNewDateWeeksAmount = (
  start: Moment | null,
  end: Moment | null,
  weeksAmount: string,
  changing: InputChangingValues
) => {
  let startDate = start
  let endDate = end
  let weeks = String(weeksAmount)

  // Only one value available cases
  if (
    (!start && !end && weeksAmount) ||
    (start && !end && !weeksAmount) ||
    (!start && end && !weeksAmount) ||
    (!start && !end && !weeksAmount)
  ) {
    return { startDate, endDate, weeks: weeks ? String(weeks) : '' }
  }

  if (changing === InputChangingValues.start) {
    if (endDate) {
      weeks = `${endDate.diff(startDate, 'week') + 1}`
    } else {
      endDate = startDate.clone().add(+weeksAmount, 'weeks').add(-3, 'day')
    }
  }
  if (changing === InputChangingValues.end) {
    if (startDate) {
      weeks = `${endDate.diff(startDate, 'week') + 1}`
    } else {
      startDate = endDate.clone().add(-weeksAmount, 'weeks').add(3, 'day')
    }
  }
  if (changing === InputChangingValues.weeks) {
    if (startDate) {
      endDate = startDate.clone().add(+weeksAmount, 'weeks').add(-3, 'day')
    } else {
      startDate = endDate.clone().add(-weeksAmount, 'weeks').add(3, 'day')
    }
  }

  return { startDate: startDate.format(), endDate: endDate.format(), weeks }
}
