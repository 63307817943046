import * as types from './actionTypes'
import api from '../apiConfig'
import axios from 'axios'
import * as commonTypes from '../common/actionTypes'
import { notification } from 'antd'
import { useEditRiderModalStore } from '@/pages/Riders/components/ModalEdit/store'
import {
  closeModal,
  onDisabledToggleModal,
} from '@/features/containers/ModalContainer/modalConfig'
import { useForceEditRiderStore } from '@/entities/modals/ForceEditRidingRider/ForceEditRidingRider'
import { getUrlWithSearchParams } from '@/helpers/getUrlWithSearchParams'

const rq = api.common
const rqs = api.common

export function getChildren(season_id) {
  return function (dispatch) {
    dispatch({ type: commonTypes.PENDING_START })
    let page = 1
    const url = `children?page=${page}&expand=addresses.address,addresses.route&season_id=${season_id}`
    rq.get(url).then((response) => {
      if (response.status === 200) {
        // if x-pagination-current-page is equal to x-pagination-page-count, then it is the last page
        if (
          response.headers['x-pagination-current-page'] ===
          response.headers['x-pagination-page-count']
        ) {
          dispatch({
            type: types.CHILDREN_GET_ITEMS,
            payload: response.data,
          })
        } else {
          // if not request all the rest pages and concat the data
          let endpoints = []
          let initialData = response.data
          for (
            let i = 2;
            i <= response.headers['x-pagination-page-count'];
            i++
          ) {
            endpoints.push(
              `children?page=${i}&expand=addresses.address,addresses.route&season_id=${season_id}`
            )
          }
          axios
            .all(endpoints.map((endpoint) => rq.get(endpoint)))
            .then((data) => {
              data.forEach((response) => {
                if (response.status === 200) {
                  initialData = initialData.concat(response.data)
                } else {
                  console.warn('error')
                }
              })

              dispatch({
                type: types.CHILDREN_GET_ITEMS,
                payload: initialData,
              })
            })
        }
        dispatch({ type: commonTypes.PENDING_END })
      } else {
        console.warn('error')
      }
    })
  }
}

export function addChildNode(childId, text) {
  return function (dispatch) {
    rq.post(
      `child-notes/`,
      JSON.stringify({
        child_id: childId,
        text: text,
      })
    ).then(function (response) {
      if (response.status === 201) {
        dispatch({
          type: types.CHILDREN_ADD_CHILDNODE,
          payload: { data: response.data },
        })
      } else {
        console.warn('error')
      }
    })
  }
}

export function editChildNode(childId, text, nodeId) {
  let body = JSON.stringify({ child_id: childId, text: text })
  return function (dispatch) {
    rq.put(`child-notes/${nodeId}`, body).then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: types.CHILDREN_EDIT_CHILDNODE,
          payload: { data: response.data, childID: childId },
        })
      } else {
        console.warn('error')
      }
    })
  }
}

export function getChildrenUse(season_id, id, type) {
  return function (dispatch) {
    rq.get(
      `children?season_id=${season_id}&expand=addresses.address,addresses.route`
    ).then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: types.CHILDREN_GET_ITEMS_USE,
          payload: { data: response.data, id: id, type: type },
        })
      } else {
        console.warn('error')
      }
    })
  }
}

export function getChildrenFt(season_id) {
  return function (dispatch) {
    rq.get(
      `children?season_id=${season_id}&fields=id,classs,full_name,transportation_category,special_instructions,school_name,phone,parent_first_name,parent_last_name`
    ).then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: types.CHILDREN_GET_ITEMS,
          payload: response.data,
        })
      } else {
        console.warn('error')
      }
    })
  }
}

export function addItem(child, season_id) {
  return function (dispatch) {
    rq.post(
      `children?expand=addresses.address,addresses.route`,
      JSON.stringify({ ...child, season_id: season_id })
    )
      .then(function (response) {
        if (response.status === 201) {
          closeModal()
          dispatch({
            type: types.CHILDREN_ADD_ITEM,
            payload: { data: response.data },
          })
        } else {
          console.warn('error')
        }
      })
      .catch((error) => {
        const response = error.response
        dispatch({
          type: types.CHILDREN_ADD_ITEM_ERROR,
          payload: { data: response.data },
        })
      })
      .finally(() => {
        const setStore = useEditRiderModalStore.getState().setStore
        onDisabledToggleModal(false)
        setStore({ isLoading: false })
      })
  }
}

export function clearAddItemError() {
  return function (dispatch) {
    dispatch({
      type: types.CHILDREN_ADD_ITEM_ERROR,
      payload: { data: null },
    })
  }
}

export function addItem_socket(response) {
  return function (dispatch) {
    dispatch({
      type: types.CHILDREN_ADD_ITEM,
      payload: { data: response },
    })
  }
}

export function addDailyChange(id, text) {
  return function (dispatch) {
    const payload = {
      id: id,
      has_daily_change: 1,
      daily_change_comment: text,
    }
    rq.put(
      `children/${id}?expand=addresses.address,addresses.route`,
      JSON.stringify(payload)
    )
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.CHILDREN_EDIT_ITEM,
            payload: response.data,
          })
        } else {
          console.warn('error ' + id)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function removeDailyChange(id) {
  return function (dispatch) {
    const payload = {
      id: id,
      has_daily_change: 0,
    }
    rq.put(
      `children/${id}?expand=addresses.address,addresses.route`,
      JSON.stringify(payload)
    )
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.CHILDREN_EDIT_ITEM,
            payload: response.data,
          })
        } else {
          console.warn('error ' + id)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function editItem(item) {
  return function (dispatch) {
    rq.put(
      `children/${item.id}?expand=addresses.address,addresses.route`,
      JSON.stringify(item)
    )
      .then(function (response) {
        if (response.status === 200) {
          closeModal()
          dispatch({
            type: types.CHILDREN_EDIT_ITEM,
            payload: response.data,
          })
        } else {
          console.warn('error ' + item.id)
        }
      })
      .catch((error) => {
        const response = error.response
        dispatch({
          type: types.CHILDREN_ADD_ITEM_ERROR,
          payload: { data: response.data },
        })
      })
      .finally(() => {
        onDisabledToggleModal(false)
        const setStore = useEditRiderModalStore.getState().setStore
        setStore({ isLoading: false })
      })
  }
}

export function editItem_socket(response) {
  return function (dispatch) {
    dispatch({
      type: types.CHILDREN_EDIT_ITEM,
      payload: response,
    })
  }
}

export function editItemAddrss(item, id) {
  return function (dispatch) {
    rq.put(
      `children/${id}?expand=addresses.address,addresses.route`,
      JSON.stringify(item)
    ).then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: types.CHILDREN_EDIT_ITEM_ADDRESS,
          payload: response.data,
        })
      } else {
        console.warn('error ' + item.id)
      }
    })
  }
}

export function uploadChildPhoto(childID, base64) {
  const body = JSON.stringify({ imageS3: base64 })
  return function (dispatch) {
    rq.put(
      `children/${childID}?expand=addresses.address,addresses.route`,
      body
    ).then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: types.UPLOAD_CHILD_PHOTO,
          payload: response.data,
        })
      } else {
        console.warn('error childID' + childID)
      }
    })
  }
}

export function delAddress(block) {
  return function (dispatch) {
    rq.delete('child-addresses/' + block.id)
      .then(function (response) {
        if (response.status === 204) {
          dispatch({
            type: types.CHILDREN_REMOVE_ADDRESS,
            payload: block,
          })
        } else {
          console.warn('status <>204 ')
        }
      })
      .catch(() => {
        console.warn('catch')
      })
  }
}

export function moveToRun(riders) {
  return function (dispatch) {
    dispatch({
      type: types.CHILDREN_MOVE_TO_RUN,
      payload: riders,
    })
  }
}

export function moveToRunV2(riders) {
  return function (dispatch) {
    dispatch({
      type: types.CHILDREN_MOVE_TO_RUN_V2,
      payload: riders,
    })
  }
}

export function toggleAddress(childId, addressId, activeRun) {
  return function (dispatch) {
    dispatch({
      type: types.CHILDREN_EDIT_ADDRESS,
      payload: { childId, addressId, activeRun },
    })
  }
}

export function removeChildrenFromTransfer(childId, runId) {
  return function (dispatch) {
    dispatch({
      type: types.CHILDREN_REMOVE_FROM_TRANSFER,
      payload: { childId, runId },
    })
  }
}

export function afterCloneAddress(addressId, childId) {
  return function (dispatch) {
    dispatch({
      type: types.CHILDREN_EDIT_ADDRESS_AFTER_CLONE,
      payload: { addressId, childId },
    })
  }
}

export function toggleAddressFromSchool(childId, route_id) {
  return function (dispatch) {
    dispatch({
      type: types.CHILDREN_EDIT_ADDRESS_FROM_SCHOOL,
      payload: { childId, route_id },
    })
  }
}

export function toggleAddressType2(payload) {
  return function (dispatch) {
    dispatch({
      type: types.CHILDREN_ADDRESS_SWITCH,
      payload: payload,
    })
  }
}

export function showAddress(childId, addressId) {
  return function (dispatch) {
    dispatch({
      type: types.CHILDREN_SHOW_ADDRESS,
      payload: { childId, addressId },
    })
  }
}

export function removeItem(id, name) {
  return async function (dispatch) {
    try {
      dispatch({ type: commonTypes.PENDING_START })
      await rq.delete('children/' + id)
      dispatch({
        type: types.CHILDREN_REMOVE_ITEM,
        payload: id,
      })

      notification.success({
        message: name
          ? `Child ${name} was successfully removed`
          : 'Child was successfully removed',
      })
    } catch (e) {
      if (e?.response?.status === 422) {
        notification.error({
          description: e.response?.data?.message || '',
          message: 'Rider can’t be deleted',
        })
      } else {
        notification.error({
          message: `Child "${name}" could not be deleted`,
        })
      }
    } finally {
      dispatch({ type: commonTypes.PENDING_END })
    }
  }
}

export function removeItem_socket(id) {
  return function (dispatch) {
    dispatch({
      type: types.CHILDREN_REMOVE_ITEM,
      payload: id,
    })
  }
}

export function editActive(id, status, params = {}) {
  useForceEditRiderStore.getState().setState({ isLoading: true })
  return function (dispatch) {
    rq.put(
      getUrlWithSearchParams('children/' + id, {
        expand: 'addresses.address,addresses.route',
      }),
      {
        status: status === 1 ? 0 : 1,
        ...(params ?? {}),
      }
    )
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.CHILDREN_EDIT_ACTIVE,
            payload: response.data,
          })
          closeModal()
        } else {
          console.warn('error ' + id)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        useForceEditRiderStore.getState().setState({ isLoading: false })
      })
  }
}

export function allInactive(status, session, data) {
  return function (dispatch) {
    rq.post(`children/all-inactive?season_id=${session}&status=${status * 1}`, {
      ids: data,
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(getChildren(session))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function clearDaily(session) {
  return function (dispatch) {
    rq.post(`../api/v2/riders/daily-change-clear/${session}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.CHILDREN_CLEAR_DAILY,
            payload: session,
          })
        } else {
          // console.warn("error " + id);
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function allInactive_socket(status) {
  return function (dispatch) {
    dispatch({
      type: types.CHILDREN_ALL_INACTIVE,
      payload: status * 1,
    })
  }
}

//= ================================

export function getNotes(id) {
  return function (dispatch) {
    rq.get('child-notes?child_id=' + id)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.CHILDREN_GET_NOTES,
            payload: {
              id: id,
              data: response.data,
            },
          })
        } else {
          console.warn('error')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function addNote(note) {
  return function (dispatch) {
    rq.post('child-notes', JSON.stringify(note))
      .then(function (response) {
        if (response.status === 201) {
          dispatch({
            type: types.CHILDREN_ADD_NOTE,
            payload: response.data,
          })
        } else {
          console.warn('error')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function editNote(noteId, req) {
  return function (dispatch) {
    rq.put('child-notes/' + noteId, JSON.stringify(req)) // внутри должен передаваться childId
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.CHILDREN_EDIT_NOTE,
            payload: response.data,
          })
        } else {
          console.warn('error ' + noteId)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function removeNote(id, childId) {
  return function (dispatch) {
    rq.delete('child-notes/' + id + '?child_id=' + childId)
      .then(function (response) {
        if (response.status === 204 || response.status === 200) {
          dispatch({
            type: types.CHILDREN_REMOVE_NOTE,
            payload: {
              id,
              childId,
            },
          })
        } else {
          console.warn('status <>204 ' + id)
        }
      })
      .catch(() => {
        console.warn('catch', id)
      })
  }
}

export function getAttendanceByRiderId(date, childId) {
  return function (dispatch) {
    rqs
      .get(`attendances?date=${date}&child_id=${childId}`)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.CHILDREN_ATTENDANCE_BY_ID,
            payload: {
              data: response.data,
              id: childId,
            },
          })
        } else {
          console.warn('status 201 ')
        }
      })
      .catch(() => {
        console.warn('catch')
      })
  }
}

export function getAttendance(date, childId) {
  return function (dispatch) {
    rqs
      .get('attendances?date=' + date)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.CHILDREN_ATTENDANCE,
            payload: {
              data: response.data,
              id: childId,
            },
          })
        } else {
          console.warn('status 201 ')
        }
      })
      .catch(() => {
        console.warn('catch')
      })
  }
}

export function getctivityLog(start, end, childId) {
  return function (dispatch) {
    rqs
      .get(
        `general-logs?date_start=${start}&date_end=${end}&type=Child&entity_id=${childId}`
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.CHILDREN_LOG,
            payload: {
              data: response.data,
              id: childId,
            },
          })
        } else {
          console.warn('status 201 ')
        }
      })
      .catch(() => {
        console.warn('catch')
      })
  }
}

export const setResetFilters = (resetFilters) => {
  return {
    type: types.CHILDREN_RESET_FILTERS,
    payload: resetFilters,
  }
}
