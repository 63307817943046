import React from 'react'
import { useContextBuilder } from './context'
import StopModalAdd from './components/StopModalAdd'
import SchoolModalAdd from './components/SchoolModalAdd'
import DepotModalAdd from './components/ModalDepoAdd'
import ModalTransferAdd from './components/ModalTransferAdd'
import ModalOptimizeRun from './components/ModalOptimizeRun'
import Schedule_run from '../../../RoutesView/components/RouteItem/components/ScheduleRun'
import ModalBase from '../../../../components/BaseModal'
import i18n from '../../../../i18n'
import { Trans } from 'react-i18next'
import ModalClone from './components/ModalClone'

export default function ({ addInRun }) {
  let {
    openModal,
    setOpenModal,
    activeRun,
    activeRunsIds,
    setIsOptimized,
    setIsDraggedPin,
  } = useContextBuilder()

  let close = () => setOpenModal('')

  let getStopPosition = (inputPosition, points) => {
    let pos = null
    if (points === 0) {
      pos = 0
    } else {
      points.map((item) => {
        const { position, routePointRoute } = item
        if (inputPosition == routePointRoute.view_position) {
          pos = position
        }
        return null
      })
    }
    return pos
  }

  if (openModal === `modalStop`) {
    return (
      <StopModalAdd
        id={'NoPadd'}
        activeRun={activeRun}
        getStopPosition={getStopPosition}
        addInRun={addInRun}
        close={close}
      />
    )
  }

  if (openModal === `modalSchool`) {
    return (
      <SchoolModalAdd
        id={'NoPadd'}
        addInRun={addInRun}
        activeRun={activeRun}
        getStopPosition={getStopPosition}
        close={close}
      />
    )
  }

  if (openModal === `modalDepot`) {
    return (
      <DepotModalAdd
        id={'NoPadd'}
        addInRun={addInRun}
        activeRun={activeRun}
        getStopPosition={getStopPosition}
        close={close}
      />
    )
  }

  if (openModal === `modalTransfer`) {
    return (
      <ModalTransferAdd
        id={'NoPadd'}
        addInRun={addInRun}
        activeRun={activeRun}
        activeRuns={activeRunsIds}
        getStopPosition={getStopPosition}
        close={close}
      />
    )
  }

  if (openModal === `modalOptimizeRun`) {
    return (
      <ModalOptimizeRun
        id={'NoPadd'}
        data={activeRun}
        closeFn={close}
        setIsOptimized={setIsOptimized}
        setIsDraggedPin={setIsDraggedPin}
      />
    )
  }

  if (openModal === `modalClone`) {
    return <ModalClone run={activeRun} closeFn={close} />
  }

  if (openModal === `schedule_run`) {
    return <Schedule_run closeFn={close} />
  }

  if (openModal === `modalInfoError`) {
    return (
      <ModalBase title={i18n.t('Transfer Stop Error')} closeFn={close}>
        <div> Rider not on the bus yet </div>

        <div className="center">
          <div className="buttons">
            <button onClick={close} className="save">
              {' '}
              OK{' '}
            </button>
          </div>
        </div>
      </ModalBase>
    )
  }

  if (openModal === `openModalErrorTransferOptimize`) {
    return (
      <ModalBase title={i18n.t('Transfer Stop Error')} closeFn={close}>
        <div>
          <Trans>
            The Selected Range can not be optimized as it contains one or more
            Transfer Stops that are not in optimized location(s) with respect to
            the transferred Riders. Try to optimize segments before and after
            the transfer stop(s), or change the location of the Transfer
            Stop(s).
          </Trans>
        </div>

        <div className="center">
          <div className="buttons">
            <button onClick={close} className="save">
              {' '}
              OK{' '}
            </button>
          </div>
        </div>
      </ModalBase>
    )
  }

  return <div />
}
