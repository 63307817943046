import * as types from './actionTypes'
import _ from 'lodash'

const initialState = {
  user: false,
  roles: [],
  profile: {},
  else1: {},
  else2: {},
  error: [],
  userRoles: [],
  passwordError: null,
  users: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.USER_PROFILE_SETTINGS: {
      let newArr = { ...state }
      newArr.profile = action.payload
      return newArr
    }
    case types.USER_LIST: {
      let newArr = { ...state }
      newArr.users = action.payload
      return newArr
    }
    case types.USER_ADD: {
      let newArr = { ...state }
      newArr.errors = []
      newArr.users.push(action.payload)
      return _.cloneDeep(newArr)
    }
    case types.USER_ADD_ERROR: {
      let newArr = { ...state }
      newArr.errors = action.payload
      return newArr
    }
    case types.USER_DEL: {
      let newArr = { ...state }
      let pos = newArr.users.findIndex(x => x.id === action.payload)
      newArr.users.splice(pos, 1)
      return _.cloneDeep(newArr)
    }
    case types.USER_ROLES: {
      let newArr = { ...state }
      newArr.roles = action.payload
      return newArr
    }
    case types.USER_ROLE: {
      let newArr = { ...state }
      newArr.thisUserRole = action.payload.role
      return _.cloneDeep(newArr)
    }
    case types.USER_ROLE_DEL: {
      let newArr = { ...state }
      let nameText = action.payload
      let roles = newArr.roles
      let index = roles.findIndex(x => x.role.nameText === nameText)
      roles.splice(index, 1)
      return _.cloneDeep(newArr)
    }
    case types.USER_ROLE_EDIT: {
      let newArr = { ...state }
      let roleFull = action.payload
      let roles = newArr.roles
      let index = roles.findIndex(x => x.role.nameText === roleFull.role.nameText)
      roles.splice(index, 1, roleFull)
      return _.cloneDeep(newArr)
    }
    case types.USER_ROLE_ADD: {
      let newArr = { ...state }
      newArr.roles.push(action.payload)
      return _.cloneDeep(newArr)
    }
    case types.SET_USER_PROFILE_SETTINGS: {
      let newArr = { ...state }
      if (action.payload.error) {
        newArr.error = action.payload.error
        return newArr
      } else {
        newArr.error = []
      }
      if (newArr.profile.id === action.payload.data.id) {
        newArr.profile = action.payload.data
      }
      newArr.users = newArr.users.map(user => {
        if (user.id === action.payload.data.id) {
          return action.payload.data
        }
        return user
      })
      return newArr
    }
    case types.SET_USER_PASSWORD: {
      let newArr = { ...state }
      console.log('PASSWORD CHANGE RESPONSE', action.payload)
      if (action.payload.data.result) {
        newArr.passwordError = null
      } else {
        newArr.passwordError = 'Incorrect password'
      }
      return newArr
    }
    default:
      return state
  }
}
