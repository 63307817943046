import { ModalsEnum, useModalSelector } from '@/store/modal/ModalStore'
import { useResendFieldTripToApproverMutation } from '@/store/fieldTrip/FieldTripApi'
import { NewModal } from '@/entities/ui/NewModal/NewModal'
import styles from './ResendToApproverModal.module.less'
import { ModalFooter } from '@/entities/ui/NewModal/components/ModalFooter'

export const ResendToApproverModal = () => {
  const { isOpen, state } = useModalSelector(ModalsEnum.RESEND_TO_APPROVER)
  const [onEdit, response] = useResendFieldTripToApproverMutation()
  return (
    <NewModal width={330} open={isOpen} title="Resend to approver">
      <p className={styles.text}>
        Approval message will be resent to the current approver
      </p>

      <ModalFooter
        buttonWidth={80}
        success={{
          action: () => onEdit(state.id),
          isLoading: response.isLoading,
          text: 'Resend',
        }}
      />
    </NewModal>
  )
}
