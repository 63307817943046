import React from 'react'
import i18n from '../../../../../../i18n'
import Select from 'antd/es/select'
import { useContextBuilder } from '../../context'
import { SuffixIcon } from '../../../../../../img/iconsComponents/SuffixIcon'

export default function ({
  activeRunId,
  loadRun,
  activeRunsIds,
  activeSelect,
  transferMode,
}) {
  let { allRoutes } = useContextBuilder()

  return (
    <Select
      data-testid="runSel"
      className={`sel_ant sel_run_builder ${activeSelect && 'borderSelect'} `}
      onChange={(id) => loadRun(id)}
      placeholder={i18n.t('Please select')}
      showSearch={true}
      dropdownClassName={transferMode ? `ddStyle2` : `ddStyle`}
      optionFilterProp="value"
      filterOption={(input, option) => {
        if (option.props.children.toLowerCase().includes(input.toLowerCase()))
          return true
      }}
      suffixIcon={
        <SuffixIcon
          style={{
            top: '2px',
            position: 'relative',
            right: '-6px',
            cursor: 'pointer',
          }}
        />
      }
      value={activeRunId || undefined}
    >
      {allRoutes.map((route) => {
        return (
          <Select.Option
            disabled={
              route.id === activeRunsIds[0] || route.id === activeRunsIds[1]
            }
            key={route.id}
            value={route.id}
          >
            {route.title}
          </Select.Option>
        )
      })}
    </Select>
  )
}
