import React, { useEffect, useState } from 'react'
import i18n from '../../../../i18n'
import { NewMultiplySelect } from '../../../../entities/ui/NewMultiplySelect/NewMultiplySelect'
import styles from './index.module.less'
export default function (props) {
  let [zonesList, setZones] = useState(props.zonesList)

  let [zonesInclude, setZonesIn] = useState([])
  let [zonesIncludeDis, setZonesInDis] = useState([])

  let [zonesExclude, setZonesEx] = useState([])
  let [zonesExcludeDis, setZonesExDis] = useState([])

  useEffect(() => {
    setZones(props.zonesList)
  }, [props.zonesList])

  useEffect(() => {
    if (props.zonesActive === '') {
      setZonesIn([])
      setZonesEx([])
      setZonesInDis([])
      setZonesExDis([])
    }
  }, [props.zonesActive])

  const includeSave = (ids) => {
    setZonesIn(ids)
    setZonesExDis(ids)
    props.changeCb({ in: ids, ex: zonesExclude })
  }

  const excludeSave = (ids) => {
    setZonesEx(ids)
    setZonesInDis(ids)
    props.changeCb({ in: zonesInclude, ex: ids })
  }
  return (
    <div className={styles.filtersThirdLine}>
      <NewMultiplySelect
        value={zonesInclude}
        options={zonesList}
        label={i18n.t('Include Zone')}
        placeholder="None"
        onChange={includeSave}
        disabledOptions={zonesIncludeDis}
        filterProp={'name'}
      />
      <NewMultiplySelect
        value={zonesExclude}
        options={zonesList}
        label={i18n.t('Exclude Zone')}
        placeholder="None"
        onChange={excludeSave}
        disabledOptions={zonesExcludeDis}
        filterProp="name"
      />
    </div>
  )
}
