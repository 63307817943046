import i18n from '../../../../i18n'
import BaseModal from '../../../../components/BaseModal'
import React, { useState } from 'react'
import _ from 'lodash'

export default function (props) {
  const [inputValue, setInputValue] = useState('')

  const [inputError, setInputError] = useState(false)

  let save = () => {
    if (_.isEmpty(inputValue)) {
      setInputError(true)
      return false
    }

    let data = { name: inputValue }
    props.save(data)

    props.close()
  }

  const changeInput = (e) => {
    const value = e.target.value
    setInputError(value.trim() === '')
    setInputValue(value)
  }

  return (
    <BaseModal
      class={'EditModalApproval'}
      title={'Enter zone name'}
      className='add-point-modal'
      closeFn={props.close}
    >
      <div>

        <div
          className={`labelLine ${inputError ? 'error' : ''}`}
        >
          <label>{i18n.t('Zone name')}</label>
        </div>
        <input
          className={inputError ? 'inputLine errorBorder' : 'inputLine '}
          type='text'
          value={inputValue}
          autoFocus
          onChange={changeInput}
        />
        {inputError && (
          <span className='errorSpan'>Zone name can't be empty</span>
        )}

        <div className='center'>
          <div className='buttons'>
            <button className='cancel' onClick={props.close}>
              Cancel
            </button>
            <button disabled={inputError} className='save' onClick={save}>
              Save
            </button>
          </div>
        </div>
      </div>
    </BaseModal>
  )
}
