import { SearchOutlined } from '@ant-design/icons'
import { Button } from '@/entities/ui/NewButton/NewButton'
import React, { memo } from 'react'
import { useLazyImportChildrenQuery } from '@/store/routeTool/routeTool'

export const FieldsForMappingButton = memo(() => {
  const [request, response] = useLazyImportChildrenQuery()

  return (
    <Button
      isLoading={response.isFetching}
      onClick={() => request()}
      buttonColor="orange"
      data-id="mapFieldsBtn"
    >
      <SearchOutlined /> Show fields for Mapping
    </Button>
  )
})
