import { Progress } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from './HeaderProgressBar.module.less'
import classNames from 'classnames'
import { useAppSelector } from '@/redux'

export const HeaderProgressBar = () => {
  const loader = useAppSelector((state) => state.headerLoader.loading)
  const [loading, setLoading] = useState(loader)
  const [percent, setPercent] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [randomValue, setRandomValue] = useState(
    Math.floor(Math.random() * (95 - 65 + 1)) + 65
  )

  const isRunBuilder =
    window.location.pathname.startsWith('/client/RouteTool/routes-view') ||
    window.location.pathname.startsWith('/client/RouteTool/routes')

  const isGpsTracker = window.location.pathname.startsWith('/client/gps/')
  const isFieldTrips = window.location.pathname.startsWith('/client/FieldTrips')
  const isZoneTools = window.location.pathname.startsWith('/client/Zones')

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (percent < randomValue && loading === 'loadingStart') {
        setPercent((prevPercent) => prevPercent + 1)
      }
    }, 80)

    return () => clearInterval(intervalId)
  }, [percent, loading, randomValue])

  useEffect(() => {
    if (loader !== loading) {
      setLoading(loader)
      setIsLoading(true)
      setRandomValue(Math.floor(Math.random() * (95 - 70 + 1)) + 70)
    }
    if (loader === 'loadingFinish') {
      setPercent(0)
      setIsLoading(false)
    }
  }, [loader])

  const isShowLoader =
    (isRunBuilder || isGpsTracker || isFieldTrips || isZoneTools) && isLoading

  return (
    <>
      {isShowLoader && (
        <div id="progressbar-wrapper" className={classNames(styles.root)}>
          <Progress
            percent={percent}
            className="loadingBar"
            strokeWidth={5}
            showInfo={false}
            strokeColor="#3cb0e7"
            strokeLinecap="square"
          />
        </div>
      )}
    </>
  )
}
