import { IStore } from '@/pages/Requests/Opened/useRequestOpenedStore'
import { getPathname } from '@/shared/hooks/routeHooks/useRouter'
import { useRootZustandStore } from '@/pages/FieldTrip/Attendance/V2Attendance/Attendance.store'
import { commonApi } from '@/store/common/common'
import { useRequestsStore } from '@/pages/FieldTrip/Requests/requestsStore'
import { routeLinks } from '@/shared/routeLinks'
import { routeToolApi } from '@/store/routeTool/routeTool'

export const decrementRegistrationCount = () => {
  const dispatch = useRootZustandStore.getState().dispatch
  dispatch(
    commonApi.util.updateQueryData('getCompanyCounters', undefined, (data) => {
      if (data?.data?.newPortalRequests) {
        data.data.newPortalRequests = data.data.newPortalRequests - 1
      }
    })
  )
}

export const incrementRegistrationCount = () => {
  const dispatch = useRootZustandStore.getState().dispatch
  dispatch(
    commonApi.util.updateQueryData('getCompanyCounters', undefined, (data) => {
      data.data.newPortalRequests = (data.data.newPortalRequests ?? 0) + 1
    })
  )
}

interface Props {
  set: (
    partial:
      | IStore
      | Partial<IStore>
      | ((state: IStore) => IStore | Partial<IStore>),
    replace?: boolean
  ) => void
  get: () => IStore
  id: number
  userBy?: string
  updatedAt?: number
}

export const createNewItem = () => {
  // 1) добавляет к каунту requests +1
  // 2) если пользователь на странице /requests и на табе opened :
  //   - показываем кнопку с рефрешем
  const pathname = getPathname()

  const activeTab = useRequestsStore.getState().tab

  //1)
  incrementRegistrationCount()

  // 2)
  if (pathname === routeLinks.requests && activeTab === 'opened') {
    useRequestsStore.getState().setShowRefresh(true)
  }
}

export const setInReviewStatus = ({
  set,
  get,
  id,
  userBy,
  updatedAt,
}: Props) => {
  //обработка кейса - статус изменился с new на inReview ( 1=>2 )
  //1) уменьшаем  счетчик в меню requests на 1
  //2) меняем статус на 2 ( in review )

  decrementRegistrationCount()
  const currentData = get().items

  set({
    items: currentData.map((item) =>
      item.id === id ? { ...item, status: 2, userBy, updatedAt } : item
    ),
  })
}

export const setRejectOrAcceptStatus = ({ set, get, id }: Props) => {
  //обработка кейса: статус изменен с in review на reject или accept ( 4-> 2|3 )
  // 1) удаляем item их общего списка
  // 2) добавляем оффсет к фильтрам
  // 3) вычитаем тотал из ответа с сервера что бы избежать бага в инфинити скрол таблице
  const dispatch = useRootZustandStore.getState().dispatch

  const filteredData = get().items.filter((item) => item.id !== id)
  const filters = get().filters
  // 1 и 2 )
  set({
    items: filteredData,
    filters: { ...filters, offset: filters.offset + 1 },
  })

  // 3)
  dispatch(
    routeToolApi.util.updateQueryData(
      'getOpenedParentRequests',
      filters,
      (data) => {
        const resultTotal =
          Number(data.meta['x-pagination-total-count']) > 0
            ? Number(data.meta['x-pagination-total-count']) - 1
            : 0
        data.meta = {
          ...data.meta,
          ['x-pagination-total-count']: resultTotal,
        }

        data.data = [...(data?.data ?? [])].filter((item) => {
          return item.id !== id
        })
      }
    )
  )
  //4

  const pathname = getPathname()

  const activeTab = useRequestsStore.getState().tab
  if (pathname === routeLinks.requests && activeTab === 'closed') {
    useRequestsStore.getState().setShowRefresh(true)
  }
}
