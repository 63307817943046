import { ITransCat } from '@/store/lc/settings/settings.types'
import { useAppSelector } from '@/redux'
import {
  normalizeTransCatNamesSelector,
  normalizeTransCatShortNamesSelector,
} from '@/store/lc/settings/lcSettingsApi'

export const useValidation = (transCat?: ITransCat, variant?: string) => {
  const transCatNames: Record<string, ITransCat> = useAppSelector(
    normalizeTransCatNamesSelector
  )
  const transCatShorNames = useAppSelector(normalizeTransCatShortNamesSelector)

  const nameValidation = {
    validate: (value) => {
      if (variant === 'create') {
        return transCatNames?.[value] ? 'Name field already is created' : true
      } else {
        if (
          transCatNames?.[value] &&
          transCatNames?.[value].id !== transCat.id
        ) {
          return 'Name field already is created'
        } else {
          return true
        }
      }
    },
  }

  const shortNameValidation = {
    maxLength: {
      value: 3,
      message: 'max 3',
    },
    validate: (value) => {
      if (variant === 'create') {
        return transCatShorNames?.[value]
          ? 'Short name already is created'
          : true
      } else {
        if (
          transCatShorNames?.[value] &&
          transCatShorNames?.[value].id !== transCat.id
        ) {
          return 'Short name already is created'
        } else {
          return true
        }
      }
    },
  }
  return { nameValidation, shortNameValidation }
}
