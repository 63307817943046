import { create } from 'zustand'

export type IStep = 'main' | 'reject' | 'manual-accept' | 'auto-accept'

interface IStore {
  step: IStep
  setStep: (step: IStep) => void
}
export const useRequestsStore = create<IStore>((set) => ({
  step: 'main',
  setStep: (step) => set({ step }),
}))
