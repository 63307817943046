import React, { Component } from "react";

import Modal from "../../../../../../../components/BaseModal";
import "./index.less";

import Row from "antd/es/row";
import Col from "antd/es/col";
import Select from "../../../../../../../components/customSelect/index";

class ModalUnassign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStopId: 0,
      existCount: 0
    };
  }

  handleAdd = () => {
    if (!this.state.selectedStopId) {
      return;
    }

    this.props.addRidersFn(this.state.selectedStopId);
    this.props.closeFn();
  };

  handleChange = value => {
    let existCount = this.state.existCount;
    let stop = this.props.stops.find(x => x.id === value);

    stop.fieldTripRiders.forEach(rider => {
      this.props.checkedRiders.forEach(checkedRider => {
        if (checkedRider.id === rider.child_id) {
          existCount++;
        }
      });
    });

    this.setState({
      selectedStopId: value,
      existCount: existCount
    });
  };

  render() {
    let selectedStop = this.props.stops.find(
      x => x.id === this.state.selectedStopId
    );
    return (
      <Modal id="copyRiders" save={this.handleAdd} closeFn={this.props.closeFn}>
        <div className=" Modal">
          <div className={"anchor-block"}>
            <Row>
              <Col span={24}>
                <h3 className={"title txt"}>
                  <b>Add Riders to a Stop</b>
                </h3>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <h3 className={"body txt"}>
                  You need to Select a Stop you want to add{" "}
                  {this.props.count > 0 ? this.props.count : 0} Rider(s) to
                </h3>
              </Col>
            </Row>

            <Row className={"margin6"}>
              <Col span={24}>
                <label>Select a Stop</label>
                <Select
                  value={this.state.selectedStopId}
                  onChange={stopId => this.handleChange(stopId)}
                  style={{ width: "100%" }}
                >
                  <option value={0}>Select stop...</option>
                  {this.props.stops.map(stop => (
                    <option key={stop.id} value={stop.id}>
                      {stop.title}
                    </option>
                  ))}
                </Select>
              </Col>
            </Row>

            {this.state.existCount && selectedStop ? (
              <Row className={"margin10"}>
                <Col span={24}>
                  <h3 className={"body txt"}>
                    {this.state.existCount} Riders are already added to{" "}
                    {selectedStop.title}. The rest{" "}
                    {this.props.checkedRiders.length - this.state.existCount}{" "}
                    will be moved to the {selectedStop.title}
                  </h3>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <div className="anchor-block-footer">
              <button className="btn btn-grey" onClick={this.props.closeFn}>
                <b>Cancel</b>
              </button>
              <button className="btn btn-orange" onClick={this.handleAdd}>
                <b>Add</b>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalUnassign;
