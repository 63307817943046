import L from 'leaflet'

const simplifiedSvg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <circle cx="7.5" cy="7.5" r="5.5" fill="#FFE23D" stroke="#7B7B7B" stroke-width="1"/>
  </svg>
`

export const iconRedirection = L.divIcon({
  className: 'leaflet-data-marker',
  html: simplifiedSvg,
  iconSize: [15, 15],
  popupAnchor: [0, 4],
})

const simplifiedSvgRider = `
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <circle cx="7.5" cy="7.5" r="5.5" fill="rgba(252,122,138,0.49)" stroke="rgb(250,80,99)" stroke-width="2"/>
  </svg>
`

export const iconRiderInStop = L.divIcon({
  className: 'leaflet-data-marker',
  html: simplifiedSvgRider,
  iconSize: [15, 15],
})
