import RoutesViewContext from './context'
import RoutesView from './index'
import React from 'react'

export default function (props) {
  return (
    <RoutesViewContext params={props.params}>
      <RoutesView
      // access={ props.userPermissions[`write_routes`] }
      />
    </RoutesViewContext>
  )
}
