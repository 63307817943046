import api from '../../../redux/apiConfig'

const common = api.common
export async function get () {
  return await common.get(`shapes`).then((response) => {
    if (response.status === 200) {
      return response.data
    }
  }).catch((error) => {
    console.log(error)
  })
}
export function del (id) {
  common.delete(`shapes/${id}`).then((response) => {
    if (response.status === 204) {
      return response.data
    }
  }).catch((error) => {
    console.log(error)
  })
}
export async function edit (id, item) {
  return await common.put(`shapes/${id}`,
    { 'name': item.name, data: item.data })
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch((error) => {
      console.log(error)
    })

}

export async function create (data) {
  return await common.post(`shapes`, data).then((response) => {
    if (response.status === 201) {
      return response.data
    }
  }).catch((error) => {
    console.log(error)
  })
}