import * as types from "./actionTypes";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ATTENDANCE_GET_ITEM: {
      return state;
    }
    case types.ATTENDANCE_EDIT_ITEM: {
      return state;
    }
    case types.ATTENDANCE_GET_CHILD_ITEM: {
      return state;
    }

    default:
      return state;
  }
};
