import { useEffect, useState } from 'react'
// leaflet
import L from 'leaflet'

// pixi-overlay
import * as PIXI from 'pixi.js'
import 'leaflet-pixi-overlay'
import _ from 'lodash'

import { useLeafletMap } from 'use-leaflet'
import { useRulerStore } from '@/components/MapDefault/ruler/rulerStore'

PIXI.settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = false
PIXI.SCALE_MODES.DEFAULT = PIXI.SCALE_MODES.NEAREST
PIXI.utils.skipHello()
const PIXILoader = PIXI.Loader.shared

let closeTooltipTimeoutId
const PixiOverlay = ({ markers }) => {
  const isRule = useRulerStore((state) => !state.isRule)

  const [openedPopupData, setOpenedPopupData] = useState(null)
  const [openedTooltipData, setOpenedTooltipData] = useState(null)

  const [openedPopup, setOpenedPopup] = useState(null)
  const [openedTooltip, setOpenedTooltip] = useState(null)

  const [pixiOverlay, setPixiOverlay] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const map = useLeafletMap()

  // load sprites
  useEffect(() => {
    // cancel loading if already loading as it may cause: Error: Cannot add resources while the loader is running.
    if (PIXILoader.loading) {
      PIXILoader.reset()
    }

    let loadingAny = false
    if (pixiOverlay && pixiOverlay.options)
      pixiOverlay.options.markers = markers
    for (let marker of markers) {
      const resolvedMarkerId = marker.iconId || marker.iconColor

      // skip if no ID or already cached
      if (
        (!marker.iconColor && !marker.iconId) ||
        PIXILoader.resources[`marker_${resolvedMarkerId}`]
      ) {
        continue
      }
      loadingAny = true

      PIXILoader.add(
        `marker_${resolvedMarkerId}`,
        marker.customIcon
          ? getEncodedIcon(marker.customIcon)
          : getDefaultIcon(marker.iconColor)
      )
    }
    if (loaded && loadingAny) {
      setLoaded(false)
    }

    if (loadingAny) {
      PIXILoader.load(() => setLoaded(true))
    } else {
      setLoaded(true)
    }
  }, [markers])

  // load pixi when map changes
  useEffect(() => {
    let pixiContainer = new PIXI.Container()
    let overlay = L.pixiOverlay(
      (utils) => {
        // redraw markers
        const scale = utils.getScale()
        let group = _.groupBy(utils.getContainer().children, (child) => {
          return child.x + '-' + child.y
        })

        for (let key in group) {
          let count = group[key].length
          let rotation_angle = 15
          if (count > 1) {
            if (rotation_angle * count > 160) {
              rotation_angle = Math.floor(160 / count)
            }
            let total_angle = rotation_angle * (count - 1)
            let total_width =
              48 * Math.sqrt(2 - 2 * Math.cos((Math.PI * total_angle) / 180))
            let step = Math.round(total_width / count)

            group[key].forEach((layer, i) => {
              layer.angle = i * rotation_angle - total_angle / 2
            })
          } else {
            let layer = group[key][0]
            layer.angle = 0
          }
        }
        utils.getContainer().children.forEach((child) => {
          child.scale.set(1 / scale)
        })

        utils.getRenderer().render(utils.getContainer())
      },
      pixiContainer,
      { resolution: 2, markers: markers }
    )
    overlay.addTo(map)
    setPixiOverlay(overlay)

    setOpenedPopupData(null)
    setOpenedTooltipData(null)

    return () => pixiContainer.removeChildren()
  }, [map])

  // draw markers first time in new container
  useEffect(() => {
    if (pixiOverlay && pixiOverlay.utils && markers && loaded) {
      const utils = pixiOverlay.utils
      let container = utils.getContainer()
      let renderer = utils.getRenderer()
      let project = utils.latLngToLayerPoint
      let projectback = utils.layerPointToLatLng
      let scale = utils.getScale()

      markers.forEach((marker) => {
        const {
          id,
          iconColor,
          iconId,
          onClick,
          position,
          popup,
          tooltip,
          popupOpen,
          markerSpriteAnchor,
          addRider,
          addStop,
          dragEnd,
        } = marker

        const resolvedIconId = iconId || iconColor

        if (
          !PIXILoader.resources[`marker_${resolvedIconId}`] ||
          !PIXILoader.resources[`marker_${resolvedIconId}`].texture
        ) {
          return
        }

        const markerTexture =
          PIXILoader.resources[`marker_${resolvedIconId}`].texture
        // const markerTexture = new PIXI.Texture.fromImage(url);

        markerTexture.anchor = { x: 0.5, y: 1 }

        const markerSprite = PIXI.Sprite.from(markerTexture)
        if (markerSpriteAnchor) {
          markerSprite.anchor.set(markerSpriteAnchor[0], markerSpriteAnchor[1])
        } else {
          markerSprite.anchor.set(0.5, 1)
        }

        const markerCoords = project(position)
        markerSprite.x = markerCoords.x
        markerSprite.y = markerCoords.y

        markerSprite.scale.set(1 / scale)

        if (popupOpen && isRule) {
          setOpenedPopupData({
            id,
            offset: [0, -35],
            position,
            content: popup,
            onClick,
          })
        }

        if ((popup || onClick || tooltip) && isRule) {
          markerSprite.interactive = true
        }

        if ((popup || onClick) && isRule) {
          markerSprite.defaultCursor = 'pointer'
          markerSprite.buttonMode = true
        }

        markerSprite.__originalPopupData = {
          id,
          offset: [0, -25],
          position,
          content: popup,
          addRider: addRider,
          addStop: addStop,
        }

        markerSprite
          .on('pointerdown', (event) => {
            onDragStart(event, markerSprite, map, container, renderer)
          })
          .on('mouseout', () => {
            closeTooltipTimeoutId = setTimeout(() => {
              setOpenedTooltipData(null)
            }, 900)
          })
          .on('pointerup', (event) => {
            onDragEnd(
              event,
              markerSprite,
              map,
              container,
              renderer,
              dragEnd,
              projectback,
              onClick
            )
          })
          .on('pointerupoutside', (event) => {
            onDragEnd(
              event,
              markerSprite,
              map,
              container,
              renderer,
              dragEnd,
              projectback,
              onClick
            )
          })
          .on('pointermove', (event) => {
            onDragMove(event, markerSprite, container, renderer, scale)
          })

        container.addChild(markerSprite)
      })
      let group = _.groupBy(container.children, (child) => {
        return child.x + '-' + child.y
      })

      for (let key in group) {
        let count = group[key].length
        let rotation_angle = 15
        if (count > 1) {
          if (rotation_angle * count > 160) {
            rotation_angle = Math.floor(160 / count)
          }
          let total_angle = rotation_angle * (count - 1)
          let total_width =
            48 * Math.sqrt(2 - 2 * Math.cos((Math.PI * total_angle) / 180))
          let step = Math.round(total_width / count)

          group[key].forEach((layer, i) => {
            layer.angle = i * rotation_angle - total_angle / 2
            let fn = [i * step - total_width / 2, -30]
            layer.on('mouseover', () => {
              if (!layer.dragging && isRule) {
                clearTimeout(closeTooltipTimeoutId)

                setOpenedTooltipData({
                  id: layer.__originalPopupData.id,
                  offset: fn,
                  position: layer.__originalPopupData.position,
                  content: layer.__originalPopupData.content,
                  addRider: layer.__originalPopupData.addRider,
                  addStop: layer.__originalPopupData.addStop,
                })
              }
            })
          })
        } else {
          let layer = group[key][0]
          layer.on('mouseover', () => {
            if (!layer.dragging && isRule) {
              clearTimeout(closeTooltipTimeoutId)

              setOpenedTooltipData({
                id: layer.__originalPopupData.id,
                offset: [0, -30],
                position: layer.__originalPopupData.position,
                content: layer.__originalPopupData.content,
                addRider: layer.__originalPopupData.addRider,
                addStop: layer.__originalPopupData.addStop,
              })
            }
          })
          layer.angle = 0
        }
      }
      renderer.render(container)
    }

    return () =>
      pixiOverlay &&
      pixiOverlay.utils &&
      pixiOverlay.utils.getContainer().removeChildren()
  }, [pixiOverlay, markers, loaded, isRule])

  // handle tooltip
  useEffect(() => {
    if (openedTooltip) {
      map.closePopup(openedTooltip)
    }

    if (
      openedTooltipData &&
      (!openedPopup ||
        !openedPopupData ||
        openedPopupData.id !== openedTooltipData.id)
    ) {
      setOpenedTooltip(
        openPopup(
          map,
          openedTooltipData,
          {},
          setOpenedPopup,
          setOpenedTooltipData
        )
      )
    }

    // prevent tooltip from closing if cursor is on tooltip
    const tooltipContainer = document.querySelector('.leaflet-popup-content')
    if (tooltipContainer) {
      tooltipContainer.addEventListener('mouseover', () => {
        clearTimeout(closeTooltipTimeoutId)
      })
      tooltipContainer.addEventListener('mouseout', () => {
        closeTooltipTimeoutId = setTimeout(() => {
          setOpenedTooltipData(null)
        }, 900)
      })
    }
  }, [openedTooltipData, openedPopupData, map])

  // handle popup
  useEffect(() => {
    // close only if different popup
    if (openedPopup) {
      map.closePopup(openedPopup)
    }

    // open only if new popup
    if (openedPopupData) {
      setOpenedPopup(
        openPopup(
          map,
          openedPopupData,
          { autoClose: false },
          setOpenedPopup,
          setOpenedTooltipData
        )
      )
    }

    // we don't want to reload when whenedPopup changes as we'd get a loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedPopupData, map])

  return null
}

function openPopup(
  map,
  data,
  extraOptions = {},
  setOpenedPopup,
  setOpenedTooltipData,
  isPopup
) {
  const html = L.DomUtil.create('div')
  html.innerHTML = data.content
  const popup = L.popup(Object.assign({ offset: data.offset }, extraOptions))
    .setLatLng(data.position)
    .setContent(html)
    .on('add', () => {
      L.DomEvent.on(html, 'click', (e) => {
        const button = e.target.parentNode
        if (e.target.className === 'addToRun') {
          if (
            e.target.parentNode.className === 'createStopInRider' &&
            !button.disabled
          ) {
            map.closePopup()
            data.addStop(1)
          }
          if (
            e.target.parentNode.className === 'full_name' &&
            !button.disabled
          ) {
            map.closePopup()
            data.addRider(1)
          }
        }
      })

      // Add mouse events to keep the popup open
      html.addEventListener('mouseenter', () => {
        clearTimeout(closeTooltipTimeoutId)
      })

      html.addEventListener('mouseleave', () => {
        closeTooltipTimeoutId = setTimeout(() => {
          console.log('close')
          setOpenedPopup(null)
          setOpenedTooltipData(null)
        }, 900)
      })
    })
    .openOn(map)

  return popup
}

function getDefaultIcon(color) {
  const svgIcon = `<svg style="-webkit-filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, .4));filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, .4));" xmlns="http://www.w3.org/2000/svg" fill="${color}" width="36" height="36" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 6.243 6.377 6.903 8 16.398 1.623-9.495 8-10.155 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.342-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>`
  return getEncodedIcon(svgIcon)
}

function getEncodedIcon(svg) {
  if (svg.base64) return svg.base64

  const decoded = unescape(encodeURIComponent(svg))
  const base64 = btoa(decoded)
  return `data:image/svg+xml;base64,${base64}`
}

function onDragStart(event, marker, map, container, renderer) {
  // store a reference to the data
  // the reason for this is because of multitouch
  // we want to track the movement of this particular touch
  if (event.data.button === 0) {
    map.closePopup()
    map.dragging.disable()
    marker.data = event.data
    marker.data.origX = marker.x
    marker.data.origY = marker.y
    marker.alpha = 0.5
    marker.dragging = true
    marker.isClicked = true
    renderer.render(container)
  }
}

function onDragEnd(
  event,
  marker,
  map,
  container,
  renderer,
  dragEnd,
  projectback,
  onClick
) {
  map.dragging.enable()

  if (marker.dragging) {
    if (!dragEnd(projectback({ x: marker.x, y: marker.y }))) {
      marker.alpha = 1
      marker.dragging = false
      marker.x = marker.data.origX
      marker.y = marker.data.origY
      marker.anchor.set(0.5, 1)
      // set the interaction data to null
      marker.data = null
      renderer.render(container)
    }
  }
  if (marker.isClicked) {
    onClick(null)
  }
}

function onDragMove(event, marker, container, renderer, scale) {
  if (marker.dragging) {
    marker.anchor.set(0.5, 0.85)
    const newPosition = marker.data.getLocalPosition(marker.parent)
    marker.x = newPosition.x
    marker.y = newPosition.y
    marker.isClicked = false
    renderer.render(container)
  }
}

export default PixiOverlay
