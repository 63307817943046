export const SettingsIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M9.268 9.268A2.409 2.409 0 0 0 10 7.5c0-.69-.244-1.28-.732-1.768A2.409 2.409 0 0 0 7.5 5c-.69 0-1.28.244-1.768.732A2.409 2.409 0 0 0 5 7.5c0 .69.244 1.28.732 1.768A2.409 2.409 0 0 0 7.5 10c.69 0 1.28-.244 1.768-.732zM15 6.436v2.168a.376.376 0 0 1-.078.224.297.297 0 0 1-.195.127l-1.807.274c-.124.351-.25.647-.38.888.227.326.575.775 1.044 1.348.065.078.098.16.098.244 0 .085-.03.16-.088.225-.176.24-.498.592-.967 1.054-.469.463-.775.694-.918.694a.477.477 0 0 1-.254-.088l-1.348-1.055c-.286.15-.582.274-.888.371-.104.886-.199 1.491-.283 1.817-.046.182-.163.273-.352.273H6.416a.369.369 0 0 1-.24-.083.29.29 0 0 1-.112-.21l-.273-1.797a6.035 6.035 0 0 1-.879-.361l-1.377 1.045a.351.351 0 0 1-.244.088.337.337 0 0 1-.244-.108c-.82-.742-1.358-1.289-1.611-1.64a.382.382 0 0 1 .01-.45c.097-.136.263-.353.497-.649.235-.296.41-.526.528-.689a4.832 4.832 0 0 1-.4-.966L.282 8.916a.308.308 0 0 1-.205-.122.37.37 0 0 1-.078-.23V6.396c0-.078.026-.153.078-.224a.294.294 0 0 1 .186-.127L2.08 5.77c.091-.299.218-.598.38-.898-.26-.371-.608-.82-1.044-1.348a.367.367 0 0 1-.098-.234c0-.065.03-.14.088-.225.17-.234.49-.584.962-1.05.472-.465.78-.698.923-.698.085 0 .17.033.254.098l1.348 1.045c.286-.15.582-.273.888-.371.104-.886.199-1.491.283-1.817C6.11.091 6.227 0 6.416 0h2.168c.091 0 .17.028.24.083a.29.29 0 0 1 .112.21l.273 1.797c.319.104.612.224.879.361l1.387-1.045a.317.317 0 0 1 .234-.088c.085 0 .166.033.244.098.84.775 1.377 1.328 1.611 1.66a.315.315 0 0 1 .069.215.376.376 0 0 1-.078.225 18.83 18.83 0 0 1-.498.649c-.235.296-.41.526-.528.689.17.325.303.644.4.957l1.788.273a.308.308 0 0 1 .205.122.37.37 0 0 1 .078.23z"
      />
    </svg>
  )
}
