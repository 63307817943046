import React, { useEffect, useState } from 'react'
import { Popup } from 'react-leaflet'
import { useMap } from '../../../../../components/MapDefault/MapContext'
import * as runActions from '../../../../../redux/routesView/actions'
import { useSelector } from 'react-redux'
import { getLoaderState } from '../../../../../redux/common/selectors'
import { findPositionAndProjection } from '@/pages/Run/helpers/findPositionAndProjection'

export default function (props) {
  const { popUp, scope } = useMap()

  const [show, setShow] = useState(false)
  const [openType, setOpenType] = useState(undefined)
  const [position, setPosition] = useState({})

  const loaderState = useSelector(getLoaderState)
  const [isLoading, setLoading] = useState(false)
  const isLoadingStart = loaderState === 'loadingStart'

  useEffect(() => {
    setLoading(isLoadingStart)
  }, [loaderState, isLoading])

  const stopContextMenu = (e) => {
    e.preventDefault()
    stopEvent(e)
  }

  useEffect(() => {
    const currentPopup = document.querySelectorAll(
      '.leaflet-popup-pointer-event'
    )

    if (currentPopup) {
      currentPopup.forEach((x) => {
        x.addEventListener('contextmenu', stopContextMenu)
      })
    }

    return () => {
      if (currentPopup) {
        currentPopup.forEach((x) => {
          x.removeEventListener('contextmenu', stopContextMenu)
        })
      }
    }
  }, [popUp, show])

  useEffect(() => {
    if (!props.redirection || props.redirection === false) return
    setShow(false)
    setPosition(props.redirection)
    setOpenType(`redirectPoint`)
  }, [props.redirection])

  useEffect(() => {
    let { activeRuns } = scope.current.props
    if (!popUp) return
    if (position && position.x === popUp.x) return
    setShow(false)
    setPosition(popUp)

    if (activeRuns.length === 0) return

    if (activeRuns[0] !== 0 && activeRuns[1] !== 0) {
      setOpenType(`transferPoint`)
    } else if (activeRuns.length && activeRuns[0] !== 0) setOpenType(`mapPoint`)
  }, [popUp])

  useEffect(() => {
    let { activeRuns } = scope.current.props
    if (activeRuns.length && position && position.x) setShow(true)
  }, [position])

  function close() {
    setShow(false)
    setOpenType(undefined)
  }

  function getPositionOnMap(runId) {
    const { routes } = scope.current.state
    for (const gr of routes) {
      for (const x of gr.routes) {
        if (x.id === runId) {
          return findPositionAndProjection(x.osrm, position)
        }
      }
    }
  }

  function addBusByLocation() {
    let { activeRun, dispatch } = scope.current.props
    if (!position.x || !position.y) {
      return
    }
    let busStop = {
      address: position?.x + ', ' + position?.y,
      isAnchor: false,
      routeId: activeRun,
    }

    dispatch(runActions.busStopInRun(busStop))
    close()
  }

  let addStopDom = (
    <button
      onClick={addBusByLocation}
      style={{ width: `100%`, display: `flex` }}
      disabled={isLoading}
      className={'disabledBtn'}
    >
      <span className="addToRunParent">Add Stop</span>

      <span className="addToRun" />
    </button>
  )

  function addTransferPoint() {
    let { activeRuns, activeRun, dispatch } = scope.current.props

    let r1p = getPositionOnMap(activeRuns[0])
    let r2p = getPositionOnMap(activeRuns[1])
    if (!position.x || !position.y) {
      return
    }
    let transfer = {
      address: `${position.x},${position.y}`,
      name: 'Transfer stop',
      routes: [
        {
          routeId: activeRuns[0],
          waittime: 0,
          position: r1p.position,
        },
        {
          routeId: activeRuns[1],
          waittime: 0,
          position: r2p.position,
        },
      ],
    }

    dispatch(runActions.transferInRun({ ...transfer }, { route_id: activeRun }))
    close()
  }

  let transferPointDom = (
    <button
      className="disabledBtn"
      style={{ width: `100%`, display: `flex` }}
      onClick={addTransferPoint}
      disabled={isLoading}
    >
      <span className="addToRunParent">Add transfer point</span>
      <span className="addToRun" />
    </button>
  )

  function addRedirectionByLocation() {
    let { activeRun, dispatch } = scope.current.props

    let { position, projection } = getPositionOnMap(activeRun)
    if (!projection?.x || !projection?.y || !position) {
      return
    }
    let rdPoint = {
      address: projection.x + ', ' + projection.y,
      type: 4,
      routeId: activeRun,
      position,
    }

    dispatch(runActions.redirectionPointInRun(rdPoint))
    close()
  }

  let redirectPointDom = (
    <button
      style={{ width: `100%`, display: `flex` }}
      onClick={addRedirectionByLocation}
      disabled={isLoading}
      className={'disabledBtn'}
    >
      <span className="addToRunParent"> Add redirection point </span>
      <span className="addToRun" />
    </button>
  )

  let openPopUpDom = () => {
    switch (openType) {
      case `mapPoint`:
        return addStopDom

      case `redirectPoint`:
        return [addStopDom, redirectPointDom]

      case `transferPoint`:
        return transferPointDom
    }
  }

  const stopEvent = (event) => {
    if (event.preventDefault !== undefined) {
      return event.preventDefault()
    }
    if (event.stopPropagation !== undefined) {
      return event.stopPropagation()
    }
  }
  if (!show) return <div />

  return (
    <Popup
      offset={[0, 10]}
      position={[position.x, position.y]}
      className={`leaflet-popup-pointer-event `}
    >
      <div className="full_name">
        <div
          style={{ width: `100%` }}
          className="leaflet-popup-content justifyBetween"
        >
          {openPopUpDom()}
        </div>
      </div>
    </Popup>
  )
}
