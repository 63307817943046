export const START_DATE = 'date'
export const END_DATE = '-date'
export const START_GUARDIAN = 'guardian'
export const END_GUARDIAN = '-guardian'
export const ASCEND_COLOR = '#000000'
export const DESCEND_COLOR = '#898989'
export const GOOGLE = 'google'
export const FIELDS = {
  EMERGENCY_CONTACT: 'emergency_contact',
  EMERGENCY_CONTACT_PHONE: 'emergency_contact_phone',
  EMERGENCY_CONTACT_WORK_PHONE: 'emergency_contact_work_phone',
  FIRST_GUARDIAN: 'first_guardian',
  FIRST_GUARDIAN_PHONE: 'first_guardian_phone',
  FIRST_GUARDIAN_WORK_PHONE: 'first_guardian_work_phone',
  SECOND_GUARDIAN: 'second_guardian',
  SECOND_GUARDIAN_PHONE: 'second_guardian_phone',
  SECOND_GUARDIAN_WORK_PHONE: 'second_guardian_work_phone',
  VENDOR_END_DATE: 'vendor_end_date',
  FIRST_GUARDIAN_EMAIL: 'first_guardian_email',
  SECOND_GUARDIAN_EMAIL: 'second_guardian_email',
  EMERGENCY_CONTACT_EMAIL: 'emergency_contact_email',
  DAILY_CHANGE_COMMENT: 'daily_change_comment',
  FULL_NAME: 'full_name',
  SITE_NAME: 'site_name',
  BIRTH_DATE: 'birth_date',
  SCHOOL_NAME: 'school_name',
  TRANSPORTATION_CATEGORY: 'transportation_category',
  PICK_UP_HOUSE_NUMBER: 'pick_up_house_number',
  PICK_UP_ADDRESS: 'pick_up_addres',
  PICK_UP_CITY: 'pick_up_city',
  DROP_OFF_HOUSE_NUMBER: 'drop_off_house_number',
  DROP_OFF_ADDRESS: 'drop_off_address',
  DROP_OFF_CITY: 'drop_off_city',
  SERVICE_START_DATE: 'service_start_date',
  SERVICE_END_DATE: 'service_end_date',
  PROGRAM_TYPE: 'program_type',
  IS_ATTENDANT: 'is_attendant',
  IS_NURSE: 'is_nurse',
  PARENT_FIRST_NAME: 'parent_first_name',
  PARENT_LAST_NAME: 'parent_last_name',
  PHONE: 'phone',
  WORK_PHONE: 'work_phone',
  ROUTE: 'route',
  VENDOR_START_DATE: 'vendor_start_date',
  PICK_UP_RATE: 'pick_up_rate',
  DROP_OFF_RATE: 'drop_off_rate',
  PICK_UP_PRICING_TIER: 'pick_up_pricing_tier',
  DROP_OFF_PRICING_TIER: 'drop_off_pricing_tier',
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday',
  ADDITIONAL_RIDER1: 'additional_rider1',
  ADDITIONAL_RIDER2: 'additional_rider2',
  CLASS: 'classs',
  EMAIL: 'email',
  GENDER: 'gender',
  WEEKS: 'weeks',
  WEEKLY_DO: 'weekly_do',
  WEEKLY_PU: 'weekly_pu',
  SCHOOL_NAME_AM: 'school_name_am',
  SCHOOL_NAME_PM: 'school_name_pm',
  PICK_UP_ZIP: 'pick_up_zip',
  DROP_OFF_ZIP: 'drop_off_zip',
  SEASON_ID: 'season_id',
  ROUTE_ID: 'route_id',
  ROUTE_ORDER: 'route_order',
  STATUS: 'status',
  HAS_DAILY_CHANGE: 'has_daily_change',
  SPECIAL_INSTRUCTIONS: 'special_instructions',
  ALERT_NOTE: 'alert_note',
  CID: 'cid',
  IMAGE_S3: 'imageS3',
}

export const LOADING_START = 'loadingStart'
