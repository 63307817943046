import React, { Component } from "react";

import "../Additional/index.less";
import { DownIconOutlined, UpIcon } from "../../../../../img/icons";

class Additional extends Component {
  constructor(props) {
    super(props);

    this.state = {
      point: props.point,
      isExpanded: {
        riders: false,
        adults: false
      }
    };
  }
  handleExpand = type => {
    const isExpanded = this.state.isExpanded;
    isExpanded[type] = !isExpanded[type];

    this.setState({
      isExpanded: isExpanded
    });
  };

  RenderAdditional() {
    let { point, isExpanded } = this.state;

    return (
      <div id="Riders">
        <span className="link" onClick={() => this.handleExpand("adults")}>
          <span className="IconRiders" />
          <span className="linkRiders">
            Additional Riders {point.fieldTripRiderAdults.length}
            {isExpanded.adults ? (
              <UpIcon style={{ fontSize: "12px" }} className="icon-right" />
            ) : (
              <DownIconOutlined
                style={{ fontSize: "12px" }}
                className="icon-right"
              />
            )}
          </span>
          <span className="IconUser" />
        </span>

        {/* this.props.removeRiderFn(point.run_id, point.id, rider.id, true) */}

        {isExpanded.adults && (
          <div className="List Top">
            {point.fieldTripRiderAdults.map(rider => {
              return (
                <div key={rider.id} className="ListUsers">
                  <span className="Name">
                    {rider.name}{" "}
                    {!this.props.isReadonly && (
                      <span
                        className="deleteIcon"
                        onClick={() =>
                          this.props.deleteRiderFn(point.id, rider.id, true)
                        }
                      >
                        <span className={"DeLine"} />
                      </span>
                    )}
                  </span>

                  <div className="Info">
                    <span className="Cellphone">{rider.phone}</span>
                    <span className="text">{rider.notes}</span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div
        className="firstLine rline Top"
        style={{ paddingLeft: "0", paddingTop: "10px", margin: 0 }}
      >
        <div className="flex" style={{ width: "100%" }}>
          {this.RenderAdditional()}
        </div>
      </div>
    );
  }
}

export default Additional;
