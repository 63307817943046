export const AnchorIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      {...props}
    >
      <path
        fill="currerntColor"
        fillRule="nonzero"
        d="M6.301 2.73a.412.412 0 0 0 .128-.301.412.412 0 0 0-.128-.302A.412.412 0 0 0 6 2a.412.412 0 0 0-.301.127.412.412 0 0 0-.128.302c0 .116.043.216.128.3A.412.412 0 0 0 6 2.858a.412.412 0 0 0 .301-.127zM12 8.643V11c0 .098-.045.165-.134.2a.366.366 0 0 1-.08.014.24.24 0 0 1-.154-.06l-.623-.623c-.531.639-1.242 1.144-2.133 1.517-.89.373-1.85.56-2.876.56a7.373 7.373 0 0 1-2.876-.56c-.89-.373-1.602-.878-2.133-1.517l-.623.623a.209.209 0 0 1-.154.06.366.366 0 0 1-.08-.013C.044 11.165 0 11.098 0 11V8.643c0-.063.02-.114.06-.154.04-.04.092-.06.154-.06h2.357c.099 0 .166.044.201.133.036.085.02.163-.047.235l-.67.67c.3.406.723.748 1.27 1.027a5.611 5.611 0 0 0 1.818.553V6.714H3.857a.412.412 0 0 1-.301-.127.412.412 0 0 1-.127-.301v-.857c0-.117.042-.217.127-.302A.412.412 0 0 1 3.857 5h1.286V3.908a1.743 1.743 0 0 1-.623-.619 1.645 1.645 0 0 1-.234-.86c0-.474.167-.878.502-1.212A1.652 1.652 0 0 1 6 .714c.473 0 .877.168 1.212.503.335.334.502.738.502 1.212 0 .312-.078.599-.234.86a1.743 1.743 0 0 1-.623.62V5h1.286c.116 0 .216.042.301.127a.412.412 0 0 1 .127.302v.857a.412.412 0 0 1-.127.301.412.412 0 0 1-.301.127H6.857v4.333a5.611 5.611 0 0 0 1.818-.553c.547-.279.97-.621 1.27-1.027l-.67-.67c-.067-.072-.083-.15-.047-.235.035-.089.102-.133.2-.133h2.358c.062 0 .114.02.154.06.04.04.06.091.06.154z"
      />
    </svg>
  )
}
