import { useRequestsStore } from '@/entities/modals/Requests/RequestsOpenedModal/store'
import { IRequestOpenedItem } from '@/store/routeTool/routeTool.types'
import { NewCheckbox } from '@/entities/ui/NewCheckbox/NewCheckbox'
import styles from './AutoAccept.module.less'
import { Button } from '@/entities/ui/NewButton/NewButton'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { useAppSelector } from '@/redux'
import { schoolsOptionsSelector } from '@/store/lc/settings/lcSettingsApi'
import { FormSelect } from '@/entities/ui/NewSelect/NewSelect'
import {
  required,
  useAutoAccept,
} from '@/entities/modals/Requests/RequestsOpenedModal/steps/AutoAccept/useAutoAccept'

export const AutoAccept = (item: IRequestOpenedItem) => {
  const onCancel = () => {
    useRequestsStore.getState().setStep('main')
  }

  const { changes, onSubmit, isFetching, isShowSchoolPm, isShowSchoolAm } =
    useAutoAccept(item)

  const methods = useForm<{
    changes: any[]
    schoolPmId?: number
    schoolAmId?: number
  }>({
    defaultValues: { changes },
  })

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'changes',
    keyName: 'keyName',
  })

  const options = useAppSelector(schoolsOptionsSelector)

  const isHaveChanges = fields.length > 0
  return (
    <section className={styles.root}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            {isHaveChanges ? (
              <div>
                <p style={{ marginBottom: 12 }}>
                  Please choose your desired action(s) from the following
                  options:
                </p>
              </div>
            ) : (
              <p className={styles.title}>
                You are about to accept the request. Changes will be applied to
                the rider record immediately!
              </p>
            )}
            {(fields ?? []).map((change, index) => {
              const Component = components[change.type]
              return (
                <Component
                  name={`changes[${index}].value`}
                  key={change.keyName}
                  title={change.title}
                />
              )
            })}
            {isShowSchoolAm && (
              <FormSelect
                rules={required}
                label={'School AM'}
                placeholder={'Select school'}
                options={options}
                name={'schoolAmId'}
                showErrorText={false}
              />
            )}
            {isShowSchoolPm && (
              <FormSelect
                placeholder={'Select school'}
                rules={required}
                style={{ marginTop: 12 }}
                label={'School PM'}
                options={options}
                name={'schoolPmId'}
                showErrorText={false}
              />
            )}
            <div className={styles.footer}>
              <Button onClick={onCancel} type="button" buttonColor="gray">
                Cancel
              </Button>
              <Button isLoading={isFetching} type="submit" buttonColor="orange">
                Apply
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </section>
  )
}

const ChangeAddresses = ({ title, name }: { title: string; name: string }) => {
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <article className={styles.addressesChangeWrapper}>
          <p> {title}</p>
          <NewCheckbox
            onChange={() => field.onChange('force')}
            checked={field.value === 'force'}
          >
            Take the rider out of the runs, change his/her address and make
            her/him as unassigned. You need to assign this rider to appropriate
            runs.
          </NewCheckbox>
          <NewCheckbox
            onChange={() => field.onChange('update_on_run')}
            checked={field.value === 'update_on_run'}
          >
            Keep the rider on the runs but change the address. This option is
            appropriate when the new address does not change the course of the
            run substantially.
          </NewCheckbox>
          <NewCheckbox
            onChange={() => field.onChange('ignore')}
            checked={field.value === 'ignore'}
          >
            Ignore this change
          </NewCheckbox>
        </article>
      )}
    />
  )
}

const DeleteAddress = ({ title, name }: { title: string; name: string }) => {
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <article className={styles.addressesChangeWrapper}>
          <p> {title}</p>
          <NewCheckbox
            onChange={() => field.onChange('force')}
            checked={field.value === 'force'}
          >
            Take the rider out of the runs and delete his/her address.
          </NewCheckbox>
          <NewCheckbox
            onChange={() => field.onChange('ignore')}
            checked={field.value === 'ignore'}
          >
            Ignore this change
          </NewCheckbox>
        </article>
      )}
    />
  )
}
const components = {
  update: ChangeAddresses,
  delete: DeleteAddress,
}
