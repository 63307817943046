import { WarningIcon } from '@/assets/icons/WarningIcon'
import { ViewRunsIcon } from '@/assets/icons/ViewRunsIcon'
import { UserIcon } from '@/assets/icons/useIcon'
import { TrashIcon } from '@/assets/icons/TrashIcon'
import { StatusProgressIcon } from '@/assets/icons/StatusProgressIcon'
import { StatusNewIcon } from '@/assets/icons/StatusNewIcon'
import { SettingsIcon } from '@/assets/icons/SettingsIcon'
import { SessionIcon } from '@/assets/icons/SessionIcon'
import { SchoolIcon } from '@/assets/icons/SchoolIcon'
import { RunBuilderIcon } from '@/assets/icons/RunBuilderIcon'
import { QuestionCircle } from '@/assets/icons/QuestionCircle'
import { PrintIcon } from '@/assets/icons/PrintIcon'
import { PickUpIcon } from '@/assets/icons/PickUpIcon'
import { OverviewIcon } from '@/assets/icons/OverviewIcon'
import { MessageIcon } from '@/assets/icons/message'
import { LogoIcon } from '@/assets/icons/logo'
import { FinishedPickUpIcon } from '@/assets/icons/FinishedPickUpIcon'
import { FinishedDropOffIcon } from '@/assets/icons/FinishedDropOffIcon'
import { EmailIcon } from '@/assets/icons/EmailIcon'
import { EditIcon } from '@/assets/icons/EditIcon'
import { DropOffIcon } from '@/assets/icons/DropOffIcon'
import { ConsolesIcon } from '@/assets/icons/ConsolesIcon'
import { ChronometerIcon } from '@/assets/icons/ChronometerIcon'
import { CalendarIcon } from '@/assets/icons/CalendarIcon'
import { BusesIcon } from '@/assets/icons/BusesIcon'
import { BusDepotsIcons } from '@/assets/icons/BusDepotsIcons'
import { BillingIcon } from '@/assets/icons/BillingIcon'
import { AttendanceIcon } from '@/assets/icons/AttendanceIcon'
import { ArrowLeftIcon } from '@/assets/icons/ArrowLeftIcon'
import { AcceptPickUpIcon } from '@/assets/icons/AcceptPickUpIcon'
import { AcceptDropOffIcon } from '@/assets/icons/AcceptDropOffIcon'
import { FilterIcon } from '@/assets/icons/FilterIcon'
import { EyeIcon } from '@/assets/icons/EyeIcon'
import { AddPlusIcon } from '@/assets/icons/AddPlusIcon'
import { WarningShapeIcon } from '@/assets/icons/WarningShapeIcon'
import { EyeHideIcon } from '@/assets/icons/EyeHideIcon'
import { CopyIcon } from '@/assets/icons/CopyIcon'
import { TimeIcon } from '@/assets/icons/TimeIcon'
import { ClearIcon } from '@/assets/icons/ClearIcon'
import { AnchorIcon } from '@/assets/icons/AnchorIcon'
import { MinusCircle } from '@/assets/icons/MinusCircle'
import { RiderIcon } from '@/assets/icons/RiderIcon'
import { PinIcon } from '@/assets/icons/PinIcon'
import { TimerIcon } from '@/assets/icons/TimerIcon'
import { DistanceIcon } from '@/assets/icons/DistanceIcon'
import { SpeedMeterIcon } from '@/assets/icons/SpeedMeterIcon'
import { TurnIcon } from '@/assets/icons/TurnIcon'
import { CheckboxIcon } from '@/assets/icons/CheckboxIcon'
import { SearchIcon } from '@/assets/icons/SearchIcon'
import { SaveIcon } from '@/assets/icons/SaveIcon'
import { NodeIndex } from '@/assets/icons/NodeIndex'
import { InfoIcon } from '@/assets/icons/infoIcon'

export const IconList = {
  //ui
  warning: <WarningIcon />,
  warningShape: <WarningShapeIcon />,
  questionCircle: <QuestionCircle />,
  printIcon: <PrintIcon />,
  message: <MessageIcon />,
  email: <EmailIcon />,
  edit: <EditIcon />,
  calendar: <CalendarIcon />,
  arrowLeft: <ArrowLeftIcon />,
  filterIcon: <FilterIcon />,
  eye: <EyeIcon />,
  eyeHide: <EyeHideIcon />,
  addPlus: <AddPlusIcon />,
  copy: <CopyIcon />,
  time: <TimeIcon />,
  clear: <ClearIcon />,
  anchor: <AnchorIcon />,
  minusCircle: <MinusCircle />,
  rider: <RiderIcon />,
  pin: <PinIcon />,
  timer: <TimerIcon />,
  distance: <DistanceIcon />,
  speedMeter: <SpeedMeterIcon />,
  turn: <TurnIcon />,
  checkbox: <CheckboxIcon />,
  search: <SearchIcon />,
  saveIcon: <SaveIcon />,
  nodeIndex: <NodeIndex />,
  infoIcon: <InfoIcon />,

  //map
  acceptPickUp: <AcceptPickUpIcon />,
  acceptDropOff: <AcceptDropOffIcon />,
  pickUp: <PickUpIcon />,
  dropOff: <DropOffIcon />,
  finishedPickUp: <FinishedPickUpIcon />,
  finishedDropOff: <FinishedDropOffIcon />,
  //menu
  viewRun: <ViewRunsIcon />,
  userIcon: <UserIcon />,
  trash: <TrashIcon />,
  statusProgress: <StatusProgressIcon />,
  statusNew: <StatusNewIcon />,
  setting: <SettingsIcon />,
  session: <SessionIcon />,
  schoolIcon: <SchoolIcon />,
  runBuilder: <RunBuilderIcon />,

  overviewIcon: <OverviewIcon />,
  logo: <LogoIcon />,
  console: <ConsolesIcon />,
  chronometer: <ChronometerIcon />,
  bus: <BusesIcon />,
  busDepot: <BusDepotsIcons />,
  billing: <BillingIcon />,
  attendance: <AttendanceIcon />,
}

export const iconKeys = Object.keys(IconList)
