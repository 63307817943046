import { totalInfoByOsrm } from '../../../BuildRun/components/totalInfoByOsrm'

function capacityCheck ({ buses, routes , activeRun, addCount }) {

  let difference
  let bus
  let run

  routes.forEach(gr=> {
    gr.routes.forEach(x=> {
      if(x.id === activeRun) run = x ;
    })
  })

  buses.buses.forEach(x=> {
    if(run && x.id === run.bus_id) bus = x
  })
  if(!run || !bus.capacity) return false

  let {countRiders} = totalInfoByOsrm(null, run.points);

  difference =  bus.capacity - (countRiders + addCount)
  return difference
}


export default capacityCheck


export function capacityInfo ({ buses, routes , activeRun, addCount }) {

  let difference
  let bus
  let run

  routes.forEach(gr=> {
    gr.routes.forEach(x=> {
      if(x.id === activeRun) run = x ;
    })
  })

  buses && run && buses.buses.forEach(x=> {
    if(x.id === run.bus_id) bus = x
  })
  if(!bus || !bus.capacity) return false

  let {countRiders} = totalInfoByOsrm(null, run.points);

  difference =  bus.capacity - (countRiders + addCount)

  return {
    difference: difference,
    busCapacity: bus.capacity}
}