import React, { Component } from 'react'
import * as runActions from '../../../../../../redux/routesView/actions'
import { connect } from 'react-redux'
import StopChildAddress from './StopChildAddress/index'
import { ModalAnchor } from '../../../ModalAnchor'

import Modal from './../Modal/DeleteStop/index'
import AllPurpose from './../Modal/AllPurpose/index'
import MoreBlock from '../BlockMore'
import {
  getTimeLastUTC,
  totalDist,
  totalTime,
} from '@/components/RepeatFunctions/timeWork'
import anchorAndTimeFN from '../anchorAndTime'
import _ from 'lodash'
import { sortRidersList } from '@/components/RepeatFunctions/filter'
import TransferRunChain from '../TransferRunChain'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import styles from './TransferPoint.module.less'
import classnames from 'classnames'
import { RightIcon } from '@/img/icons'
import { AttendanceGroup } from '@/pages/Run/components/BuildRun/components/AttendanceGroup/AttendanceGroup'

class Stop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
      edit: false,
      showModalAnchor: false,
      pickUp: false,
      dropOff: false,
      anchorItem: '',
      show: false,
      index: props.index3,
      arrive: props.Arrive,
      Wait: props.Wait,
      miles: props.miles,
      min: props.min,
      isLoading: false,
    }
    this.min = 0
    this.miles = 0
    this.time = ''
    this.obj = {}
    this.showEdit = false
  }

  componentDidMount() {
    this.obj = {
      title: 'Edit Bus Stop',

      input: [
        {
          label: 'Вus Stop Name',
          placeholder: 'Name a New Bus Stop',
        },
      ],

      Address: [
        {
          label: 'Address',
          placeholder: 'address',
        },
      ],

      minute: [
        {
          label: 'Wait Time',
          placeholder: 'In Minutes',
        },
      ],
      stops: [
        {
          label: 'Enter Stop Number',
          placeholder: 'or Stop Will Be Placed Last on the List',
        },
      ],
    }

    this.getRunName()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loader !== this.props.loader) {
      this.setState({
        isLoading: this.props.loader === 'loadingStart',
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
    })
  }

  closeModal = () => {
    this.setState({
      showModalAnchor: false,
      removeModal: false,
      editStop: false,
      removeModalTrans: false,
    })
  }

  fn = () => {
    console.log('test')
  }

  handleChange = (type, e) => {
    e.preventDefault()
    if (type === 'newPosition') {
      //this.props.changePosFn(idElement, newPosition, inStop, child_address_id)
      if (e.target.value) this.props.changePosFn(this.state.id, e.target.value)
    }
    this.setState({
      [type || '1']: e.target.value,
    })
  }

  delChildFromStop = (id, child, child_address_id) => {
    let obj = {
      id: id,
      child: child,
      child_address_id: child_address_id,
      routeId: this.state.routeId,
    }

    this.setState(
      {
        obj: obj,
        busStopTitle: child.full_name,
      },
      () => {
        this.setState({
          removeModalTrans: true,
        })
      }
    )
  }

  saveAnchor = (id, data) => {
    this.props.dispatch(runActions.editAnchor(id, data))
  }

  removeRun = (e, id) => {
    this.setState({
      removeModal: true,
    })
  }

  handleStop = () => {
    this.setState({
      editStop: true,
    })
  }

  handleAnchor = () => {
    return (
      !this.props.isRoleDispatcher &&
      this.setState({
        showModalAnchor: true,
      })
    )
  }

  handleClick = () => {
    this.setState({
      show: !this.state.show,
    })

    if (!this.state.show) {
      this.setState({
        edit: true,
      })
    }
  }

  editToggle = () => {
    let { edit } = this.state

    if (edit) {
      this.setState({
        show: false,
        edit: false,
      })
    } else {
      this.setState({
        edit: true,
      })
    }
  }

  deleteTrans = () => {
    let obj = this.state.obj

    this.props.removeChildFromTransfer(
      obj.id,
      obj.child,
      obj.child_address_id,
      obj.routeId
    )
    this.closeModal()
  }

  deletePoint = () => {
    this.props.delFn()
    this.closeModal()
  }

  getChildByType = () => {
    let { routePointTransferChild } = this.state

    let activeR = null
    this.props.routes.forEach((g) => {
      g.routes.forEach((x) => {
        if (x.id === this.state.routeId) {
          activeR = x
        }
      })
    })

    let pickUpChild = []
    let dropOffChild = []

    if (_.isEmpty(routePointTransferChild) || !activeR.points) {
      pickUpChild = null
      dropOffChild = null
      return { pickUpChild, dropOffChild }
    }

    routePointTransferChild.forEach((x) => {
      let find = (activeR.points || []).find((ch) => {
        if (
          ch?.type === 1 &&
          ch.routePointChildren[0].child_address_id === x.child_address_id
        ) {
          return true
        }
        if (ch?.type === 2) {
          return ch.routePointChildren.find(
            (y) => y.child_address_id === x.child_address_id
          )
        }
      })

      if (x.childAddress?.type === 1) {
        find ? dropOffChild.push(x) : pickUpChild.push(x)
      }
      if (x.childAddress?.type === 2) {
        find ? pickUpChild.push(x) : dropOffChild.push(x)
      }
    })

    return { pickUpChild, dropOffChild }
  }

  childList = () => {
    let { id, routePointTransferChild, object, isLoading } = this.state
    let { pickUpChild, dropOffChild } = this.getChildByType()
    if (_.isEmpty(routePointTransferChild)) {
      return
    }
    sortRidersList(pickUpChild)
    sortRidersList(dropOffChild)

    return (
      <div className="blockList">
        <div className={styles.attendanceGroupTitleWrapper}>
          {routePointTransferChild.length > 0 && (
            <div
              style={{ marginTop: 0 }}
              className="SchoolAddress"
              onClick={this.handleClick}
            >
              <span className="riders">
                Riders {routePointTransferChild.length}
              </span>
              {this.state.show ? <UpOutlined /> : <DownOutlined />}
            </div>
          )}
          <AttendanceGroup routePointRoute={this.state.routePointRoute} />
        </div>
        {this.state.show && (
          <div className="PuckUpMain marginTop">
            <div className="pickUpBlock">
              {pickUpChild.length > 0 && (
                <div className="pickUp">Pick Up Riders</div>
              )}

              {pickUpChild.map((address) => {
                return (
                  <StopChildAddress
                    isLoading={isLoading}
                    address={address}
                    delChildFromStop={this.delChildFromStop}
                    isWriteRoutes={this.props.isWriteRoutes}
                  />
                )
              })}
            </div>

            <div
              className={`dropOffBlock ${
                pickUpChild.length > 0 ? `marginTop` : ''
              }`}
            >
              {dropOffChild.length > 0 && (
                <div className="dropOff">Drop Off Riders</div>
              )}
              {dropOffChild.map((address) => {
                return (
                  <StopChildAddress
                    isLoading={isLoading}
                    address={address}
                    delChildFromStop={this.delChildFromStop}
                    isWriteRoutes={this.props.isWriteRoutes}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
  }

  getRunName = () => {
    let { routePointRoutes, routeId } = this.state
    let selectRuns

    if (!routePointRoutes) {
      return false
    }

    let run = routePointRoutes.filter((x) => x.route_id !== routeId)

    if (!_.isEmpty(run)) {
      let id = run[0].route_id

      this.state.routes.forEach((item) => {
        item.routes.forEach((x) => {
          if (x.id === id) {
            if (x) {
              selectRuns = x
            }
          }
        })
      })

      if (selectRuns) {
        this.setState({
          selectTitle: selectRuns.title,
        })
      }
    }
  }

  render() {
    let {
      id,
      address,
      busStopTitle,
      routePointChildren,
      routePointTransferChild,
      routePointRoute,
      object,
      isAnchor,
      routeId,
      edit,
      lastIndex,
    } = this.state

    let {
      school,
      routeWaittime,
      pointOsrmLeg,
      route,
      routePointRoutes,
      osrmTime,
    } = this.props

    if (!this.showEdit) {
      this.showEdit = true
    }

    let arriveTime = null
    let distance = null
    let duration = null
    if (pointOsrmLeg && pointOsrmLeg.time) {
      arriveTime = getTimeLastUTC(pointOsrmLeg.time)

      if (
        pointOsrmLeg.distance !== undefined &&
        pointOsrmLeg.duration !== undefined
      ) {
        distance = totalDist(pointOsrmLeg.distance)
        duration = totalTime(pointOsrmLeg.duration)
      }
    }

    //if(!routePointRoute) return ;
    let newPosition = routePointRoute.view_position

    let TransferTitle = this.props.busStopTitle
      ? this.props.busStopTitle
      : this.props.address.address

    if (!address) {
      return null
    }

    return (
      <div className="ListDiv">
        <div
          data-testid="transferPoint"
          key={id}
          className="busStopPoint bgTranscent Transfer"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="buttonTrigger"
            onClick={this.editToggle}
          >
            <RightIcon
              style={{ color: 'white' }}
              className={classnames(styles.arrow, {
                [styles.active]: edit,
              })}
            />{' '}
          </div>

          {!this.props.isWriteRoutes && (
            <MoreBlock
              isLast={newPosition === lastIndex}
              routePointRoute={routePointRoute}
              id={id}
              handleAnchor={this.handleAnchor}
              handleEditStop={this.handleStop}
              removeRun={this.removeRun}
              route={route}
            />
          )}

          <div className="Head">
            <div className="big">
              {newPosition}. {TransferTitle}{' '}
              {routePointTransferChild &&
                routePointTransferChild.length > 0 &&
                `(` + routePointTransferChild.length + ' Riders)'}
            </div>
            {distance && duration && (
              <div className="flex normal distance">
                <span>{distance}</span>
                <span>{duration}</span>
              </div>
            )}
          </div>

          <div className="firstLine">
            <div className="flex"></div>
          </div>
          {anchorAndTimeFN.bind(this)()}

          <TransferRunChain
            routePointRoutes={routePointRoutes}
            routeId={routeId}
          />

          <div className="firstLine">
            <div className="flex"></div>
          </div>

          {this.state.edit && address && address.address && (
            <div className="firstLine">
              <div className="flex">
                <div>
                  <span className="GrayColor">Address</span>
                  <b className="AddressLatLon">
                    {address.address
                      ? address.address
                      : address.lat + ' ' + address.lon}
                  </b>
                </div>
              </div>
            </div>
          )}

          <div className="firstLine">
            <div style={{ width: '100%' }}>{this.childList()}</div>
          </div>
        </div>

        {this.state.removeModalTrans && (
          <Modal
            closeFn={this.closeModal}
            title={'Remove Rider'}
            deleteBtn={'Remove'}
            child={'Remove'}
            deleteObject={this.state.busStopTitle}
            deleteFn={() => this.deleteTrans(this.state.obj)}
          />
        )}

        {this.state.removeModal && (
          <Modal
            closeFn={this.closeModal}
            title={'Delete Stop'}
            deleteBtn={'Delete Stop'}
            deleteObject={this.state.busStopTitle}
            deleteFn={() =>
              this.deletePoint(
                id,
                this.state.position,
                object.id,
                this.state.address.id,
                this.state?.type ?? 1
              )
            }
          />
        )}

        {this.state.editStop && (
          <AllPurpose
            id={id}
            title={'Edit Stop Point'}
            positon={newPosition}
            waittime={routeWaittime}
            routeId={routeId}
            stopName={busStopTitle && busStopTitle}
            busStopTitle={busStopTitle && busStopTitle}
            fullAdress={address.address}
            Address={this.obj.Address}
            input={this.obj.input}
            school={school}
            select={this.obj.select}
            minute={this.obj.minute}
            stops={this.obj.stops}
            closeFn={this.closeModal}
          />
        )}
        {this.state.showModalAnchor && (
          <ModalAnchor
            data={{
              id: id,
              routeId: routePointRoute['route_id'],
              name: busStopTitle,
              type: 'Transfer Stop',
              position: newPosition,
              address: address,
              time: routePointRoute.time,
              waittime: routePointRoute.waittime,
              isAncor: 0,
              timeZone: this.props.timeZone,
              osrmTime,
            }}
            saveFn={this.saveAnchor}
            closeFn={this.closeModal}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    miles: state.routesView.Miles,
    Wait: state.routesView.Wait,
    Arrive: state.routesView.Arrive,
    routes: state.routesView.routes,
    loader: state.headerLoader.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stop)
