import React from 'react'
import _ from 'lodash'
function adjustBrightness(hex, steps) {
  // Steps should be between -255 and 255. Negative = darker, positive = lighter
  steps = Math.max(-255, Math.min(255, steps))

  // Normalize into a six character long hex string
  hex = hex.replace('#', '')
  if (hex.length === 3) {
    hex =
      hex.substr(0, 1) +
      hex.substr(0, 1) +
      hex.substr(1, 1) +
      hex.substr(1, 1) +
      hex.substr(2, 1) +
      hex.substr(2, 1)
  }

  // Split into three parts: R, G and B
  const color_parts = [hex.substr(0, 2), hex.substr(2, 2), hex.substr(4, 2)]
  let ret = '#'

  _.each(color_parts, (color) => {
    color = parseInt(color, 16) // Convert to decimal
    color = Math.max(0, Math.min(255, color + steps)) // Adjust color
    ret += _.pad(color.toString(16), 2, '0')
  })
  return ret
}
function filling(color, fontSize, offsetY, text, iconed) {
  if (iconed) {
    return (
      <path
        fill={color}
        d="M12 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 7.5c3.315 0 6 1.342 6 3V17H6v-1.5c0-1.658 2.685-3 6-3z"
      />
    )
  } else {
    return (
      <text
        fill={color}
        fontFamily="Helvetica"
        fontSize={fontSize}
        transform="translate(1 1)"
      >
        <tspan textAnchor="middle" x="11" y={offsetY}>
          {text}
        </tspan>
      </text>
    )
  }
}

export default function mapicon(
  textT = '33',
  colorstart,
  colorend,
  color,
  iconed = false,
  strokeColor = null
) {
  const text = (textT ?? '33').toString()

  if (!strokeColor) {
    strokeColor = adjustBrightness(colorend, -30)
  }
  const gradId = colorstart.replace('#', '') + colorend.replace('#', '')
  let offsetY = 17,
    fontSize = 14
  if (text.length === 2) {
    offsetY = 16
    fontSize = 12
  }
  if (text.length === 3) {
    offsetY = 15
    fontSize = 8
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="43"
      viewBox="0 0 24 43"
    >
      <defs>
        <linearGradient id={gradId} x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor={colorstart} />
          <stop offset="100%" stopColor={colorend} />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill={'url(#' + gradId + ')'}
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20.219 18.187c1.875-3 2.094-4.782 2.094-7.187 0-6.075-5.188-11-11.188-11C5.05 0 0 4.925 0 11c0 2.405.188 4.187 2.063 7.187.835 1.338 9.062 10.063 9.062 23.438 0-13.375 8.258-22.1 9.094-23.438z"
          transform="translate(1 1)"
        />
        {filling(color, fontSize, offsetY, text, iconed)}
      </g>
    </svg>
  )
}
