export const EyeHideIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      viewBox="0 0 18 12"
      {...props}
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M14.549 3.542a12.888 12.888 0 0 1 1.943 2.079.634.634 0 0 1 0 .757c-2.048 2.72-4.823 4.393-7.883 4.393a8.543 8.543 0 0 1-3.655-.83l1.748-1.167a3.36 3.36 0 0 0 2.126.585 3.387 3.387 0 0 0 3.142-3.142c.02-.314-.003-.62-.065-.912zm-5.94-2.318c1.32 0 2.587.311 3.765.884l-1.767 1.179a3.36 3.36 0 0 0-2.218-.651 3.376 3.376 0 0 0-3.052 4.165L2.752 8.524a12.831 12.831 0 0 1-2.027-2.15.634.634 0 0 1 0-.756c2.048-2.72 4.823-4.394 7.884-4.394zm.117 6.582a1.833 1.833 0 0 1-.512-.04l2.182-1.453a1.822 1.822 0 0 1-1.67 1.493zm.419-3.544L6.805 5.82l.01-.09a1.824 1.824 0 0 1 2.33-1.47zM16.467.1a.6.6 0 1 1 .666 1L.933 11.9a.6.6 0 1 1-.666-1L16.467.1z" />
      </g>
    </svg>
  )
}
