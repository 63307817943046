import mqtt from 'mqtt'
import { io } from 'socket.io-client'

import { socketIOClientIp } from '@/redux/configLocal'
import { useGpsDetailsStore } from '../store'
import {
  MQTT_PASSWORD,
  MQTT_PORT,
  MQTT_URL,
  MQTT_USERNAME,
} from '@/shared/constants'
import {
  CompanyId,
  RunRider,
  SocketAttendChild,
  SocketCheckPoint,
} from '@/redux/gps/types'

export const connectToMqtt = (consoleHash: string) => {
  const mqttClient = mqtt.connect(MQTT_URL, {
    port: +MQTT_PORT,
    username: MQTT_USERNAME,
    password: MQTT_PASSWORD,
  })

  mqttClient.on('connect', () => {
    mqttClient.subscribe(consoleHash)
    mqttClient.subscribe(consoleHash + '_front')
  })

  return mqttClient
}

export const socketEnable = (consoleImei: string, companyId: CompanyId) => {
  const gpsRun = useGpsDetailsStore.getState().gpsInfo
  const socket = io(socketIOClientIp)
  if (!socket) {
    return
  }

  socket.on('connect', () => {
    socket.emit('room', consoleImei)
    socket.emit('room', `company_${companyId}`)
  })

  socket.on('message', (msg) => {
    //проехал новую остановку

    if (msg.event === 'check-point') {
      if (gpsRun.id * 1 === msg.data.route_id) {
        useGpsDetailsStore
          .getState()
          .setCheckPoint(msg.data as SocketCheckPoint)
        console.log(
          '%c%s',
          `color: red;'`,
          `SOCKET - Проехал остановку !!!`,
          msg.data
        )
      }
    }
    //отметка ребенка
    if (msg.event === 'add-attendance') {
      console.log(
        '%c%s',
        `color: red;'`,
        `SOCKET - Новый ребенок !!!`,
        msg.data
      )

      useGpsDetailsStore
        .getState()
        .setAttendanceRider(mapSocketAttendChildToRunRider(msg.data))
    }
  })
  return socket
}

const mapSocketAttendChildToRunRider = (
  child: SocketAttendChild
): Partial<RunRider> => ({
  attend: child.attend,
  id: child.child_id,
  childAddressId: child.child_address_id,
})
