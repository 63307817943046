export const ROUTE_GET_ITEMS = 'route.GET_ITEMS'
export const ROUTE_GET_ITEMS_GPS = 'route.GET_ITEMS_GPS'
export const ROUTE_ADD_ITEM = 'route.ADD_ITEM'
export const ROUTE_GROUP_EDIT = 'route.ROUTE_GROUP_EDIT'
export const ROUTE_GROUP_ADD = 'route.ROUTE_GROUP_ADD'
export const ROUTE_EDIT = 'route.ROUTE_EDIT'
export const ROUTE_EDIT_ITEM = 'route.EDIT_ITEM'
export const GEOCODER_ADDRESS = 'route.GEOCODER_ADDRESS'
export const ROUTE_REMOVE_ITEM = 'route.REMOVE_ITEM'
export const ROUTE_REMOVE_ITEMS = 'route.REMOVE_ITEMS'
export const ROUTE_GET_DETAILS = 'route.ROUTE_GET_DETAILS'
export const ROUTE_GET_DETAILS_LOADING = 'route.ROUTE_GET_DETAILS_LOADING'
export const ROUTE_GET_DETAILS_GPS = 'route.ROUTE_GET_DETAILS_GPS'
export const OSRM = 'route.OSRM'

export const ADDRESS_GEOCODE = '.route.ADDRESS_GEOCODE'

export const RUN_VALID_ADDRESS = 'run.RUN_VALID_ADDRESS'
export const RUN_GET_ITEMS = 'run.GET_ITEMS'
export const RUN_ADD_ITEM = 'run.ADD_ITEM' // add item (busStop Child school transfer depot )
export const RUN_ADD_ITEM_TRANSFER = 'run.ADD_ITEM_TRANSFER'
export const RUN_EDIT_ITEM = 'run.EDIT_ITEM' // edit position

export const RUN_REMOVE_ITEM = 'run.REMOVE_ITEM' //
export const RUN_DELETE_POSITION = 'run.DELETE_POSITION' //
export const ADD_TRANSFER_CHILD = 'run.ADD_TRANSFER_CHILD' //
export const RUN_RELOAD = 'run.RUN_RELOAD' //
export const ERROR = 'run.ERROR' //
export const CLEAR_ERROR = 'run.CLEAR_ERROR' //
export const RUN_RELOAD2 = 'run.RUN_RELOAD2' //
export const RUN_DELETE_FROM_BUS = 'run.DELETE_FROM_BUS' //
export const RUN_EDIT_POINT_FLAG_NAME = 'run.RUN_EDIT_POINT_FLAG_NAME' //
export const RUN_DELETE_FROM_TRANSFER = 'run.RUN_DELETE_FROM_TRANSFER' //
export const RUN_TYPE_IN_RUN = 'run.TYPE_IN_RUN'
export const RUN_CHILD_IN_BUS = 'run.CHILD_IN_BUS'
export const RUN_CHANGE_POSITION = 'run.CHANGE_POSITION'
export const RUN_OSRM_ROUTE = 'run.OSRM_ROUTE'
export const RUN_EDIT_ANCHOR = 'run.EDIT_ANCHOR'
export const SUM_DURATION = 'run.SUM_DURATION'
export const GET_CHILD_STOP_INFO = 'run.GET_CHILD_STOP_INFO'
