import ReactDOM from 'react-dom'
import './reset.less'
import './index.less'
import './i18n'
import React from 'react'
import './styles/colors.less'

import { unregister } from './registerServiceWorker'
import { App } from './App'

import classnames from 'classnames'
;(window as any).cn = classnames

if ((import.meta as any).hot) {
  ;(import.meta as any).hot.accept()
}

ReactDOM.render(<App />, document.getElementById('root'))
unregister()
