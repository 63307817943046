export function getValid(value, pattern) {
  if (!value) {
    return
  }
  value = value.replace('+1', '').split(' ').join('')
  var i = 0,
    v = value.toString()

  if (v.length === 10) {
    return pattern?.replace(/#/g, (_) => v[i++])
  }
  return v
}
