export const UserIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="82"
      height="82"
      viewBox="0 0 82 82"
      {...props}
    >
      <defs>
        <circle id="a" cx="41" cy="41" r="41" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#A0A0A0" xlinkHref="#a" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M42 17c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15zm-.5 38C57.799 55 71 66.038 71 79.667V92H12V79.667C12 66.038 25.201 55 41.5 55z"
          mask="url(#b)"
        />
      </g>
    </svg>
  )
}
