export const GET_OPENED_REQUESTS = 'routeTool.GET_OPENED_REQUESTS'
export const RESET_OPENED_REQUESTS = 'routeTool.RESET_OPENED_REQUESTS'
export const GET_CLOSED_REQUESTS = 'routeTool.GET_CLOSED_REQUESTS'
export const RESET_CLOSED_REQUESTS = 'routeTool.RESET_CLOSED_REQUESTS'
export const GET_REQUEST_MESSAGES = 'routeTool.GET_REQUEST_MESSAGES'
export const POST_REQUEST_MESSAGE = 'routeTool.POST_REQUEST_MESSAGE'
export const POST_REJECT_REQUEST = 'routeTool.POST_REJECT_REQUEST'
export const POST_MANUAL_ACCEPT_REQUEST = 'routeTool.POST_MANUAL_ACCEPT_REQUEST'
export const POST_AUTO_ACCEPT_REQUEST = 'routeTool.POST_AUTO_ACCEPT_REQUEST'
export const REMOVE_REQUEST = 'routeTool.REMOVE_REQUEST'
