import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from 'react'
import './index.less'
import { EsriGoogleMapLayer } from '@/pages/Gps/NewGps/components/EsriGoogleMapLayer'
import { Map } from 'react-leaflet'
import { LatLngTuple } from 'leaflet'
import { areBoundsEqual } from '@/pages/Gps/NewGps/helpers'

type ControlsProps = {
  mapRef: MutableRefObject<Map>
  showTarget?: boolean
  bounds?: LatLngTuple[]
}

export const Controls: FC<ControlsProps> = ({
  mapRef,
  bounds,
  showTarget = true,
}) => {
  const [boundsState, setBoundsState] = useState([])
  const [mapType, setMapType] = useState<'roadmap' | 'satellite'>('roadmap')

  const centerMapToBounds = useCallback(() => {
    if (bounds && mapRef?.current) {
      mapRef.current.contextValue.map.fitBounds(bounds)
      const currentZoom = mapRef.current.contextValue.map.getZoom()
      if ((bounds.length === 1 && currentZoom > 14) || areBoundsEqual(bounds)) {
        mapRef.current.contextValue.map.setZoom(14)
      }
    }
  }, [mapRef, bounds])

  useEffect(() => {
    const currentZoom = mapRef?.current?.contextValue.map.getZoom()
    if (
      JSON.stringify(boundsState) !== JSON.stringify(bounds) ||
      currentZoom > 14
    ) {
      setBoundsState(bounds)
    }
  }, [bounds, boundsState, mapRef])

  useEffect(() => {
    boundsState?.length && centerMapToBounds()
  }, [boundsState, centerMapToBounds])

  const switchMapType = () => {
    setMapType(mapType === 'roadmap' ? 'satellite' : 'roadmap')
  }

  const zoomIn = () => {
    mapRef.current.contextValue.map.zoomIn()
  }

  const zoomOut = () => {
    mapRef.current.contextValue.map.zoomOut()
  }

  return (
    <div id={'mapControlButton'}>
      <div className={'white'} data-testid="zoomIn">
        <div className="zoomIn" onClick={zoomIn} />
      </div>

      <div className={'white'} data-testid="zoomOut">
        <div className="zoomOut" onClick={zoomOut} />
      </div>

      <div className={'white'}>
        <div
          className={mapType === 'roadmap' ? 'earth' : 'road'}
          onClick={switchMapType}
        />
      </div>
      {showTarget && (
        <div className={'white'} data-testid="centerMap">
          <div className="target" onClick={centerMapToBounds} />
        </div>
      )}
      <EsriGoogleMapLayer mapType={mapType} />
    </div>
  )
}
