import * as types from './actionTypes'
import _ from 'lodash'
const initialState = {
  data: [],
  isLoaded: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.DASHBOARD_ADD_ITEM: {
      return { ...state }
    }

    case types.DASHBOARD_ADD_ACTIVITY: {
      let newArr = action.clear ? [] : [...state.data]
      newArr.push(...action.payload)
      return {
        ...state,
        data: _.cloneDeep(newArr),
        isLoaded: true
      }
    }

    default:
      return state
  }
}
