import { Controller, FormProvider, useForm } from 'react-hook-form'
import { NewSwitch } from '@/entities/ui/NewSwitch/NewSwitch'
import styles from './AddAndEditRole.module.less'
import { FormInput } from '@/entities/ui/NewInput/FormInput'
import { ModalFooter } from '@/entities/ui/NewModal/components/ModalFooter'
import {
  useCreateRoleMutation,
  useUpdateRoleMutation,
} from '@/store/lc/settings/lcSettingsApi'
import {
  IRole,
  IRoleRequestPermissions,
} from '@/store/lc/settings/settings.types'
import { useMemo } from 'react'

const baseRules = [
  {
    allow: 1,
    name: 'read_settings',
    description: 'View Account Settings',
  },
  {
    allow: 1,
    name: 'write_settings',
    description: 'Edit Account Settings',
  },
  {
    allow: 1,
    name: 'read_billing',
    description: 'View Billing Settings',
  },
  {
    allow: 1,
    name: 'write_billing',
    description: 'Edit Billing Settings',
  },
  {
    allow: 1,
    name: 'read_sessions',
    description: 'View Sessions',
  },
  {
    allow: 1,
    name: 'write_sessions',
    description: 'Edit Sessions',
  },
  {
    allow: 1,
    name: 'read_children',
    description: 'View Children',
  },
  {
    allow: 1,
    name: 'write_children',
    description: 'Edit Children',
  },
  {
    allow: 1,
    name: 'read_schools',
    description: 'View Schools',
  },
  {
    allow: 1,
    name: 'write_schools',
    description: 'Edit Schools',
  },
  {
    allow: 1,
    name: 'read_routes',
    description: 'View Routes',
  },
  {
    allow: 1,
    name: 'write_routes',
    description: 'Edit Routes',
  },
  {
    allow: 1,
    name: 'read_attendance',
    description: 'View Attendance',
  },
  {
    allow: 1,
    name: 'write_attendance',
    description: 'Edit Attendance',
  },
  {
    allow: 1,
    name: 'read_zone_tool',
    description: 'View Zone Tool',
  },
  {
    allow: 1,
    name: 'write_zone_tool',
    description: 'Edit Zone Tool',
  },
  {
    allow: 1,
    name: 'read_field_trip',
    description: 'View Field Trip',
  },
  {
    allow: 1,
    name: 'write_field_trip',
    description: 'Edit Field Trip',
  },
  {
    allow: 1,
    name: 'read_gps_track',
    description: 'View GPS Track',
  },
  {
    allow: 1,
    name: 'read_resources',
    description: 'View Resources',
  },
  {
    allow: 1,
    name: 'write_resources',
    description: 'Edit Resources',
  },
  {
    allow: 1,
    name: 'read_parent_requests',
    description: 'View parent requests',
  },
  {
    allow: 1,
    name: 'write_parent_requests',
    description: 'Edit parent requests',
  },
  {
    allow: 1,
    name: 'messaging_parents',
    description: 'Message parents',
  },
]

const initValues = baseRules.reduce(
  (acc, item) => ({ ...acc, [item.name]: true }),
  {}
)

interface Props {
  variant: 'edit' | 'create'
  role?: IRole
}

export const AddAndEditRole = ({ variant, role }: Props) => {
  const defaultValues = useMemo(() => {
    if (role) {
      const permissions = Object.keys(role.permissions).reduce(
        (acc, key) => ({ ...acc, [key]: !!role.permissions[key] }),
        {}
      )

      return {
        ...permissions,
        name: role?.role?.is_general
          ? role.role?.nameText
          : role.role?.data?.name,
      }
    } else {
      return { ...initValues, name: '' }
    }
  }, [role])
  const methods = useForm<IRoleRequestPermissions & { name: string }>({
    defaultValues,
  })
  const [update, updateResponse] = useUpdateRoleMutation()
  const [create, updateCreate] = useCreateRoleMutation()
  const onSubmit = (data: IRoleRequestPermissions & { name: string }) => {
    const { name, ...permissions } = data

    if (variant === 'edit') {
      update({ name: role.role.nameText, new_name: name, permissions })
    } else if (variant === 'create') {
      create(name).then((res: any) => {
        if (res?.data?.data?.role.nameText) {
          update({ name: res.data.data.role.nameText, permissions })
        }
      })
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormInput
          wrapperClassName={styles.roleName}
          rules={{
            required: { value: true, message: 'This field is required' },
          }}
          label="Role Name"
          name="name"
        />
        <div className={styles.itemsWrapper}>
          {baseRules.map((role) => (
            <Controller
              key={role.name}
              name={role.name}
              render={({ field }) => (
                <div className={styles.item}>
                  <span className={styles.description}>{role.description}</span>
                  <NewSwitch
                    controlled
                    onChange={field.onChange}
                    checked={field.value}
                  />
                </div>
              )}
            />
          ))}
        </div>
        <ModalFooter
          success={{
            isSubmitForm: true,
            text: variant === 'edit' ? 'Edit Role' : 'Create Role',
            isLoading: updateResponse.isLoading || updateCreate.isLoading,
          }}
        />
      </form>
    </FormProvider>
  )
}
