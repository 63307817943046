import * as types from "./actionTypes";

const initialState = {
  drivers: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.DRIVER_GET_ITEMS: {
      let newArr = { ...state };
      newArr.drivers = action.payload;
      return newArr;
    }
    case types.DRIVER_ADD: {
      let newArr = { ...state };
      newArr.drivers = [...newArr.drivers];
      newArr.drivers = newArr.drivers.filter(
        driver => driver.id !== action.payload.id
      );
      newArr.drivers.push(action.payload);
      newArr.drivers = newArr.drivers.sort(driver => driver.id);
      return newArr;
    }
    case types.DRIVER_UPDATE: {
      let newArr = { ...state };
      newArr.drivers = [...newArr.drivers];
      let index = newArr.drivers.findIndex(
        driver => driver.id === action.payload.id
      );
      newArr.drivers.splice(index, 1, action.payload);

      return newArr;
    }
    case types.DRIVER_DELETE: {
      let newArr = { ...state };
      newArr.drivers = [...newArr.drivers];
      newArr.drivers = newArr.drivers.filter(
        driver => driver.id !== action.payload
      );
      return newArr;
    }
    default:
      return state;
  }
};
