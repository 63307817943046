import { NewSelect } from '@/entities/ui/NewSelect/NewSelect'
import { useChangeFlagGroupMutation } from '@/store/routeTool/routeTool'
import { memo } from 'react'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { Icon } from '@/entities/ui/Icon/Icon'
import styles from './AttendanceGroup.module.less'
import { useContextBuilder } from '@/pages/Run/components/BuildRun/context'
import _ from 'lodash'
import { useAppDispatch } from '@/redux'
import { RUN_EDIT_POINT_FLAG_NAME } from '@/redux/routesView/actionTypes'
import { useRouter } from '@/shared/hooks/routeHooks/useRouter'
import classnames from 'classnames'
import { Popover } from 'antd'

interface IProps {
  routePointRoute: any
  className?: string
}

export const AttendanceGroup = (props: IProps) => {
  const router = useRouter()
  const { activeRun } = useContextBuilder()
  const currentPoint = activeRun?.points?.find(
    (point) => point.id === props?.routePointRoute.point_id
  )
  //TODO filter for only school
  if (
    !router.pathName.includes('/client/RouteTool/routes') ||
    currentPoint?.type !== 3
  ) {
    return null
  }

  return <_AttendanceGroup {...props} />
}

export const _AttendanceGroup = memo(
  ({ routePointRoute, className }: IProps) => {
    const [changeFlagName, flagNameResponse] = useChangeFlagGroupMutation()
    const { activeRun, setActiveRun } = useContextBuilder()
    const value = routePointRoute?.group_flag

    const dispatch = useAppDispatch()

    const onChangeGroupFlag = async (flagName) => {
      const res = await changeFlagName({
        flagName,
        ids: [routePointRoute.id],
      }).then((e) => e)

      if (!res.error) {
        const cloneActiveRun = _.cloneDeep(activeRun)
        const newActiveRun = {
          ...cloneActiveRun,
          points: cloneActiveRun.points.map((point) => {
            if (point.id === routePointRoute.point_id) {
              return {
                ...point,
                routePointRoute: {
                  ...point.routePointRoute,
                  group_flag: flagName,
                },
              }
            } else {
              return point
            }
          }),
        }

        dispatch({
          type: RUN_EDIT_POINT_FLAG_NAME,
          payload: {
            routeId: activeRun.id,
            group_flag: flagName,
            routegroupId: activeRun.routegroup_id,
            point_id: routePointRoute.point_id,
          },
        })

        setActiveRun(newActiveRun)
      }
    }

    return (
      <div className={classnames(styles.root, className)}>
        <p className={styles.label}>
          Grouping Site Stops
          <Popover
            align={{ offset: [-20, 0] }}
            content={
              <div style={{ width: 200 }}>
                Grouping Site Stops are used in the driver app to define nearby
                stops as one single location for picking up riders. Consecutive
                stops with the same group name will be treated within driver app
                as a single pickup/dropoff location and will have unified
                attendance taking screen
              </div>
            }
          >
            <div className={styles.question}>
              <span>?</span>
            </div>
          </Popover>
          :
        </p>

        <NewSelect
          className={styles.select}
          value={value}
          disabled={flagNameResponse.isLoading}
          onChange={(flagName) => onChangeGroupFlag(flagName)}
          options={options}
          showSearch={false}
          size="small"
        />
        {value && (
          <Button
            onClick={() => onChangeGroupFlag(null)}
            isLoading={flagNameResponse.isLoading}
            buttonColor="black"
            size="sm"
            variant="text"
          >
            <Icon height={10} width={10} icon="clear" />
          </Button>
        )}
      </div>
    )
  }
)

const options = [
  { label: 'G1', value: 'G1' },
  { label: 'G2', value: 'G2' },
  { label: 'G3', value: 'G3' },
  { label: 'G4', value: 'G4' },
  { label: 'G5', value: 'G5' },
  { label: 'G6', value: 'G6' },
  { label: 'G7', value: 'G7' },
  { label: 'G8', value: 'G8' },
]
