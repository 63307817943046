import React, { FC, useState } from 'react'
import { NewTabs } from '@/entities/ui/NewTabs/NewTabs'
import { TabsProps } from 'antd/lib/tabs'
import { useAppSelector } from '@/redux'
import {
  IFieldTrip,
  IFieldTripPoint,
  IFieldTripRun,
} from '@/store/fieldTrip/FieldTrip.types'
import { useGetOSRMQuery } from '@/store/common/common'
import {
  getStartEndWithTimezone,
  getTimeLastWithTimezone,
  getWT,
  totalDist,
  totalTime,
} from '@/components/RepeatFunctions/timeWork'
import styles from './TurnByTurnModal.module.less'
import { Collapse, Spin } from 'antd'
import textInstrustionv5 from 'osrm-text-instructions'
import { Icon } from '@/entities/ui/Icon/Icon'
import { navImages } from '@/entities/modals/TurnByTurnModal/constants'
import classNames from 'classnames'
import { getAuthRequest } from '@/redux/auth/selectors'

const textInstrustion = textInstrustionv5('v5')

interface IProps {
  routeId: number
}

export const TurnByTurnModal: FC<IProps> = (props) => {
  const route: IFieldTrip = useAppSelector((state) =>
    state?.fieldTrip.find((item) => item.id === props.routeId)
  )
  const [activeTab, setActiveTab] = useState(
    String(route?.fieldTripRuns?.[0]?.id)
  )

  const tabs: TabsProps['items'] = route?.fieldTripRuns?.map((ftRun) => ({
    label: ftRun.name,
    key: String(ftRun.id),
    children: <Tab ftRun={ftRun} />,
  }))

  return (
    <section>
      <NewTabs
        defaultActiveKey={activeTab}
        onChange={(e) => setActiveTab(e)}
        items={tabs}
      />
    </section>
  )
}

const Tab = ({ ftRun }: { ftRun: IFieldTripRun }) => {
  const {
    company: { osrm_host: host },
  } = useAppSelector(getAuthRequest)
  const pointsForOsrm = [...(ftRun?.fieldTripPoints ?? [])]
    ?.sort((a, b) => {
      if (a.position > b.position) {
        return 1
      }
      if (a.position < b.position) {
        return -1
      }
      return 0
    })
    .filter((point) => !!point?.address && !!point?.address?.lat)
    .map((point) => ({
      lat: point.address.lat,
      lon: point.address.lon,
      waittime: point.waittime,
      is_anchor: point.is_anchor,
      time: point.time,
    }))

  const { data, isFetching } = useGetOSRMQuery({
    points: pointsForOsrm,
    steps: 'true',
    ...(host && { host }),
  })

  const durationText = totalTime(data?.data?.duration)
  const distanceText = totalDist(data?.data?.distance)

  return (
    <section style={{ overflow: 'hidden' }}>
      <article className={styles.titleBlock}>
        <div className={styles.titleItem}>
          <span className={styles.title}>Total time</span>
          <span className={styles.value}>
            {isFetching ? (
              <div className={styles.textSkeleton} />
            ) : (
              durationText ?? ''
            )}
          </span>
        </div>
        <div className={styles.titleItem}>
          <span className={styles.title}>Total dist</span>
          <span className={styles.value}>
            {isFetching ? (
              <div className={styles.textSkeleton} />
            ) : (
              distanceText ?? ''
            )}
          </span>
        </div>
      </article>
      {isFetching ? (
        // {!isFetching && !isSuccess ? (
        <div className={styles.spinWrapper}>
          {' '}
          <Spin />
        </div>
      ) : (
        <Collapse bordered={false} defaultActiveKey={['0']}>
          {ftRun.fieldTripPoints
            ?.sort((a) => a.position)
            .map((point, i, arr) =>
              arr.length === i + 1 ? (
                <div
                  className={classNames({
                    [styles.error]: !data?.data.legs?.[i - 1],
                  })}
                  style={{ padding: 12 }}
                >
                  <Header
                    point={point}
                    isLast
                    leg={data?.data.legs?.[i - 1]}
                    index={i}
                    tz={ftRun.tz}
                  />
                </div>
              ) : (
                <Collapse.Panel
                  showArrow={false}
                  key={i}
                  data-error={!data?.data.legs?.[i]}
                  disabled={!data?.data.legs?.[i]}
                  style={{ background: 'red!important' }}
                  header={
                    <Header
                      point={point}
                      leg={data?.data.legs?.[i]}
                      index={i}
                      tz={ftRun.tz}
                    />
                  }
                >
                  {!data?.data.legs?.[i]?.steps && 'error'}
                  {data?.data.legs?.[i]?.steps.map((step, i) => {
                    const imgType = maneuverIcon(
                      step.maneuver.type ?? '',
                      step.maneuver.modifier ?? ''
                    )
                    return (
                      <div className={styles.step} key={i}>
                        <div className={styles.imageWrapper}>
                          <img alt={imgType} src={navImages[imgType]} />
                        </div>
                        <div style={{ flexGrow: 1 }}>
                          <p className={styles.stepTitle}>
                            {textInstrustion.compile('en', step)}
                          </p>
                          <p className={styles.stepSubtitle}>
                            {totalDist(step?.distance)}{' '}
                            <div className={styles.lingLine} />
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </Collapse.Panel>
              )
            )}
        </Collapse>
      )}
    </section>
  )
}

const Header = ({
  point,
  index,
  leg,
  isLast,
  tz,
}: {
  point: IFieldTripPoint
  index: number
  leg: any
  isLast?: boolean
  tz?: string
}) => {
  return (
    <div className={classNames(styles.header)}>
      <div className={styles.headerItem}>
        <span className={styles.headerWait}>Wait</span>
        <span className={styles.waittime}>{getWT(point.waittime)}</span>
      </div>
      <div className={styles.headerItem}>
        <span className={styles.headerTitle}>
          ({index}) {point.title} {point?.address?.address ?? ''}
        </span>
        {!isLast && <Icon className={styles.arrow} icon={'arrowLeft'} />}
        <span className={styles.legInfo}>
          {isLast ? (
            leg?.end_point?.time ? (
              getTimeLastWithTimezone(leg?.end_point?.time)
            ) : (
              '-'
            )
          ) : (
            <>
              {leg ? (
                <>
                  {getStartEndWithTimezone(
                    leg?.start_point?.time,
                    leg?.end_point?.time,
                    tz
                  )}{' '}
                  {totalTime(leg?.duration)} ({totalDist(leg?.distance)})
                </>
              ) : (
                '-'
              )}
            </>
          )}
        </span>
      </div>
    </div>
  )
}

const maneuverIcon = (type, mod) => {
  if (type === 'turn' || type === 'roundabout turn') {
    if (mod === 'uturn') return 'uturn-left'
    if (mod === 'straight') return 'straight'
    return 'turn-' + mod.replace(' ', '-')
  } else if (type === 'rotary' || type === 'roundabout') {
    return 'roundabout-left'
  } else if (type === 'fork') {
    return 'fork-' + mod.replace('slight ', '')
  } else if (type === 'off ramp' || type === 'on ramp') {
    if (mod === 'straight') {
      return mod
    } else {
      mod = mod.replace('slight ', '')
      return 'ramp-' + mod.replace('sharp ', '')
    }
  } else if (type === 'merge') {
    return type
  } else if (type === 'continue' || type === 'end of road') {
    if (mod === 'uturn') {
      return 'uturn-left'
    } else if (mod === 'straight') {
      return mod
    } else {
      mod = mod.replace('slight ', '')
      return 'turn-' + mod.replace('sharp ', '')
    }
  } else if (type === 'arrive') {
    if (mod === 'continue') {
      return 'straight'
    } else if (mod !== 'default' && mod !== '') {
      mod = mod.replace('slight ', '')
      return 'arrive-' + mod.replace('sharp ', '')
    }
  }
  return 'straight'
}
