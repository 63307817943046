import { Spin } from 'antd'
import React from 'react'

export const Loader = () => (
  <div
    style={{
      height: '100vh',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin />
      <p>...Loading</p>
    </div>
  </div>
)
