export const EmailIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="11"
      viewBox="0 0 14 11"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M14 3.547V9.75c0 .344-.122.638-.367.883s-.54.367-.883.367H1.25c-.344 0-.638-.122-.883-.367A1.204 1.204 0 0 1 0 9.75V3.547c.23.255.492.482.79.68 1.884 1.28 3.179 2.18 3.882 2.695.297.219.538.39.723.512.184.122.43.247.738.375.307.127.594.191.86.191h.015c.265 0 .552-.064.86-.191.306-.128.553-.253.737-.375.185-.123.426-.293.723-.512.886-.64 2.182-1.54 3.89-2.695a4.31 4.31 0 0 0 .782-.68zm0-2.297c0 .411-.128.805-.383 1.18a3.714 3.714 0 0 1-.953.96L9.008 5.93c-.052.036-.163.116-.332.238-.17.122-.31.221-.422.297-.112.075-.247.16-.406.254a2.367 2.367 0 0 1-.45.21c-.14.048-.27.071-.39.071h-.016c-.12 0-.25-.023-.39-.07a2.367 2.367 0 0 1-.45-.211 8.104 8.104 0 0 1-.406-.254 16.349 16.349 0 0 1-.422-.297c-.17-.122-.28-.202-.332-.238-.474-.334-1.156-.809-2.047-1.426-.89-.617-1.424-.988-1.601-1.113a3.879 3.879 0 0 1-.914-.903C.143 2.105 0 1.75 0 1.422 0 1.016.108.677.324.406.54.136.85 0 1.25 0h11.5c.339 0 .632.122.879.367s.371.54.371.883z"
      />
    </svg>
  )
}
