export const TimerIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="12"
      viewBox="0 0 10 12"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M5 10.857c-2.148 0-3.889-1.79-3.889-4 0-2.209 1.741-4 3.889-4 2.148 0 3.889 1.791 3.889 4 0 2.21-1.741 4-3.889 4zm3.906-7.206l.788-.811c-.25-.291-.5-.554-.783-.806l-.789.823A4.84 4.84 0 0 0 5 1.714c-2.761 0-5 2.303-5 5.143S2.239 12 5 12c2.778 0 5-2.303 5-5.143a5.214 5.214 0 0 0-1.094-3.206zM4.444 7.43h1.112V4H4.444v3.429zM6.667 0H3.333v1.143h3.334V0z"
      />
    </svg>
  )
}
