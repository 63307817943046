import * as types from './actionTypes'
import api from '../apiConfig'

const common = api.common

export function addUser(profile) {
  return function (dispatch) {
    common
      .post('users?expand=role', profile)
      .then((response) => {
        console.log(response)
        if (response.status === 201) {
          dispatch({
            type: types.USER_ADD,
            payload: response.data,
          })
        }
      })
      .catch((reason) => {
        if (!reason) return false
        console.log(reason)
        dispatch({
          type: types.USER_ADD_ERROR,
          payload: reason.response.data,
        })
      })
  }
}

export function delUser(id) {
  return function (dispatch) {
    common.delete(`users/${id}`).then((response) => {
      if (response.status === 204) {
        dispatch({
          type: types.USER_DEL,
          payload: id,
        })
      } else {
        console.warn('error')
      }
    })
  }
}

export function getProfileSettings() {
  return function (dispatch) {
    common.get('users/info').then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: types.USER_PROFILE_SETTINGS,
          payload: response.data,
        })
      } else {
        console.warn('error')
      }
    })
  }
}

export function getUsers() {
  return function (dispatch) {
    common.get('users?expand=userRelation,role').then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: types.USER_LIST,
          payload: response.data,
        })
      } else {
        console.warn('error')
      }
    })
  }
}

export function getRoles() {
  return function (dispatch) {
    common.get('roles').then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: types.USER_ROLES,
          payload: response.data,
        })
      } else {
        console.warn('error')
      }
    })
  }
}

export function getRole(user_id) {
  return function (dispatch) {
    common.get('users/get-role?user_id=' + user_id).then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: types.USER_ROLE,
          payload: {
            user_id: user_id,
            role: response.data,
          },
        })
      } else {
        console.warn('error')
      }
    })
  }
}

export function setUserRelation(model) {
  return function (dispatch) {
    let profile = {
      id: model.user_id,
      userRelation: model,
    }

    common
      .put('users/' + model.user_id, profile)
      .then(function (response) {
        if (response.status === 200) {
          console.log('USERS PUT RESPONSE', response.data)
          dispatch({
            type: types.SET_USER_PROFILE_SETTINGS,
            payload: {
              data: response.data,
              error: null,
            },
          })
        }
      })
      .catch(function (error) {
        if (error.response.status === 422) {
          dispatch({
            type: types.SET_USER_PROFILE_SETTINGS,
            payload: {
              data: null,
              error: error.response.data,
            },
          })
        }
      })
  }
}

export function setProfileSettings(profile) {
  let id = profile.id ? profile.id : profile.user_id
  return function (dispatch) {
    common
      .put('users/' + id, profile)
      .then(function (response) {
        if (response.status === 200) {
          console.log('USERS PUT RESPONSE', response.data)
          dispatch({
            type: types.SET_USER_PROFILE_SETTINGS,
            payload: {
              data: response.data,
              error: null,
            },
          })
        }
      })
      .catch(function (error) {
        if (error.response.status === 422) {
          dispatch({
            type: types.SET_USER_PROFILE_SETTINGS,
            payload: {
              data: null,
              error: error.response.data,
            },
          })
        }
      })
  }
}

export function setPassword(data) {
  return function (dispatch) {
    common.post('users/change-password', data).then(function (response) {
      if (response.status === 200) {
        console.log('CHANGE PASSWORD RESPONSE', response.data)
        dispatch({
          type: types.SET_USER_PASSWORD,
          payload: {
            data: response.data,
          },
        })
      }
    })
  }
}

export function setRole(profile) {
  return function (dispatch) {
    common
      .put('user/set-role', profile)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.SET_USER_PROFILE_SETTINGS,
            payload: {
              data: response.data,
              error: null,
            },
          })
        }
      })
      .catch(function (response) {
        if (response.status === 422) {
          dispatch({
            type: types.SET_USER_PROFILE_SETTINGS,
            payload: {
              data: null,
              error: response.data,
            },
          })
        }
      })
  }
}
