import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { FormInput } from '@/entities/ui/NewInput/FormInput'
import { FormSelect } from '@/entities/ui/NewSelect/NewSelect'
import { useAddNewSessionConfig } from '@/pages/Sessions/components/modals/AddNewSessionModal/useAddNewSessionConfig'
import { CalendarIcon } from '@/img/icons'
import React from 'react'
import styles from './AddNewSessionModal.module.less'
import { NewRadio } from '@/entities/ui/NewRadio/NewRadio'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { FormType } from '@/pages/Sessions/components/modals/AddNewSessionModal/AddNewSessionModal.types'
import {
  defaultValues,
  rules,
} from '@/pages/Sessions/components/modals/AddNewSessionModal/AddNewSessionModal.constants'
import { NewSingleDatePicker } from '@/entities/ui/NewSingleDatePicker/NewSingleDatePicker'
import { InputChangingValues } from '@/pages/Sessions/components/modals/AddNewSessionModal/helpers'
import { closeModal } from '@/features/containers/ModalContainer/modalConfig'

export const AddNewSessionModal = () => {
  const methods = useForm<FormType>({ defaultValues })
  const startDate = useWatch({
    control: methods.control,
    name: InputChangingValues.start,
  })
  const endDate = useWatch({
    control: methods.control,
    name: InputChangingValues.end,
  })
  const weeks = useWatch({
    control: methods.control,
    name: InputChangingValues.weeks,
  })

  const {
    seasonOptions,
    onSubmit,
    formType,
    setFormType,
    isLoading,
    onChange,
  } = useAddNewSessionConfig(methods)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className={styles.form}>
          <div className={styles.radioWrapper}>
            <NewRadio
              onChange={() => setFormType(0)}
              checked={formType === 0}
            />
            <NewRadio
              onChange={() => setFormType(1)}
              checked={formType === 1}
            />
          </div>
          <div className={styles.mainFormWrapper}>
            <FormInput
              rules={rules(0)}
              error={formType === 1 ? '' : undefined}
              placeholder="Example 2020-Base"
              disabled={formType === 1}
              showErrorText={false}
              label="Name New Base Session"
              formattedValue={(value) => (formType === 0 ? value : '')}
              name="title"
              data-testid="newSessionName"
            />
            <span className={styles.or}>or</span>
            <FormSelect
              rules={rules(1)}
              label="Create a Base Session from the existing Session"
              name="id"
              showErrorText={false}
              placeholder="Select..."
              sideEffect={() => formType === 0 && setFormType(1)}
              options={seasonOptions}
              data-testid="baseSessionSelector"
            />

            {formType === 1 && (
              <FormInput
                placeholder="Example 2020-Base"
                label="Name Base Session"
                showErrorText={false}
                name="title"
                rules={rules(1)}
              />
            )}
            <div className={cn(styles.lasLine)}>
              <FormInput
                placeholder="Weeks"
                label="Number of Weeks"
                type="number"
                showErrorText={false}
                name="weeks"
                data-testid="weeksInp"
                onChange={(v) =>
                  onChange(
                    startDate,
                    endDate,
                    v.target.value,
                    InputChangingValues.weeks
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                }}
              />

              <Controller
                name="start_date"
                rules={{
                  validate: {
                    message: (v) => (!v ? 'This field is required' : true),
                  },
                }}
                render={({ field, fieldState }) => (
                  <div>
                    <div
                      className={cn(styles.datePickerLabel, {
                        [styles.error]: !!fieldState?.error?.message,
                      })}
                    >
                      <label>Start Date</label>
                    </div>
                    <NewSingleDatePicker
                      placement="topLeft"
                      placeholder="From Mon"
                      clearIcon={false}
                      data-testid="sessionStartDate"
                      disabledDate={(current) =>
                        current.day() !== 1 || current.diff(endDate) > 0
                      }
                      status={fieldState?.error?.message ? 'error' : undefined}
                      picker="week"
                      defaultValue={field.value}
                      value={field.value}
                      format="MM/DD/YYYY"
                      onChange={(v) =>
                        onChange(
                          v,
                          endDate,
                          weeks as string,
                          InputChangingValues.start
                        )
                      }
                      suffixIcon={<CalendarIcon />}
                    />
                  </div>
                )}
              />
              <Controller
                name="end_date"
                rules={{
                  validate: {
                    message: (v) => (!v ? 'This field is required' : true),
                  },
                }}
                render={({ field, fieldState }) => (
                  <div>
                    <div
                      className={cn(styles.datePickerLabel, {
                        [styles.error]: !!fieldState?.error?.message,
                      })}
                    >
                      <label>End Date</label>
                    </div>
                    <NewSingleDatePicker
                      clearIcon={false}
                      placement="topLeft"
                      placeholder="Till Fri"
                      data-testid="sessionEndDate"
                      status={fieldState?.error?.message ? 'error' : undefined}
                      disabledDate={(current) =>
                        current.day() !== 5 || current.diff(startDate) < 0
                      }
                      picker="week"
                      format="MM/DD/YYYY"
                      suffixIcon={<CalendarIcon />}
                      defaultValue={field.value}
                      value={field.value}
                      onChange={(v) =>
                        onChange(
                          startDate,
                          v,
                          weeks as string,
                          InputChangingValues.end
                        )
                      }
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            data-testid="cancelCampSessionBtn"
            onClick={closeModal}
            type="button"
            buttonColor="gray"
          >
            Cancel
          </Button>
          <Button
            data-testid="saveCampSessionBtn"
            isLoading={isLoading}
            type="submit"
          >
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}
