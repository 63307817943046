import React from 'react'
import Modal from "../../../../../../components/BaseModal";
import Row from 'antd/es/row'
import Col from 'antd/es/col'

export default function (props){

  function save(){
    props.save()
  }

  return (
    <Modal
      title={'Warning!'}
      saveBtn={"Save"}
      closeBtn={"Cancel"}
      close={"closeM DeleteStop "}
      closeFn={props.close}
      class={"ModalClose open"}
    >
      <div className="profile">
        <div>
          <Row>
            <Col span={24}>
              <span className="Text">
                This operation causes the number of riders to exceed the bus capacity by {props.difference}. Do you want to continue?
              </span>
            </Col>
          </Row>
        </div>
      </div>
      <div className="center">
          <div className="buttons">
            <button
              className="cancel"
              onClick={props.close}
            >
              Cancel
            </button>
            <button
              className="save"
              style={{ whitespace: "pre" }}
              onClick={save}
            >
              Continue
            </button>
          </div>
      </div>
    </Modal>
  )
}