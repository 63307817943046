import { IRequestOpenedItem } from '@/store/routeTool/routeTool.types'
import styles from '@/entities/modals/Requests/RequestsOpenedModal/steps/index.module.less'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { useRequestsStore } from '@/entities/modals/Requests/RequestsOpenedModal/store'
import { useLazyPostManualAcceptQuery } from '@/store/routeTool/routeTool'

export const ManualAcceptStep = (item: IRequestOpenedItem) => {
  const onCancel = () => {
    useRequestsStore.getState().setStep('main')
  }
  const [request, response] = useLazyPostManualAcceptQuery()
  const onSubmit = () => {
    request(item.id)
  }

  return (
    <section>
      <p className={styles.title}>
        You are going to accept the guardian's request.
      </p>

      <p className={styles.title}>
        Note that system <b>will not</b> modify rider record, and{' '}
        <b>you will have to manually apply the requested changes!</b>
      </p>
      <div className={styles.footer}>
        <Button onClick={onCancel} type="button" buttonColor="gray">
          Cancel
        </Button>
        <Button
          isLoading={response.isFetching}
          onClick={onSubmit}
          type="button"
          buttonColor="orange"
        >
          Send
        </Button>
      </div>
    </section>
  )
}
