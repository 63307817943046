import { IChange } from '@/store/routeTool/routeTool.types'
import styles from '@/entities/modals/Requests/RequestsOpenedModal/RequestsOpenedModal.module.less'
import { modalConstants, requestFields } from '@/pages/Requests/constants'
import { Fragment } from 'react'

export const ChangeTypes = ({ changes }: { changes: IChange[] }) => {
  return (
    <>
      {changes?.map((change, index) => {
        if (change.type === 'update') {
          return <Update key={index} {...change} />
        } else if (change.type === 'create') {
          return <CreateComponent {...change} />
        } else if (change.type === 'delete') {
          return <DeleteComponent {...change} />
        }
      })}
    </>
  )
}

export const DeleteComponent = (change: IChange) => {
  return (
    <article>
      <p className={styles.modalContentTypeTitle}>
        {modalConstants?.[change.type]}
      </p>
      {Object.keys(change.oldFields || {}).map((oldField) => (
        <Fragment key={oldField}>
          <p className={styles.label}>{requestFields?.[oldField]}</p>
          <p>{change.newFields?.[oldField] || ' - '}</p>
        </Fragment>
      ))}
    </article>
  )
}
export const CreateComponent = (change: IChange) => {
  return (
    <article>
      <p className={styles.modalContentTypeTitle}>
        {modalConstants?.[change.type]}
      </p>
      {Object.keys(change.newFields || {}).map((newField) => (
        <Fragment key={newField}>
          <p className={styles.label}>{requestFields?.[newField]}</p>
          <p>{change.newFields?.[newField] || ' - '}</p>
        </Fragment>
      ))}
    </article>
  )
}

export const Update = (change: IChange) => {
  return (
    <article>
      <p className={styles.modalContentTypeTitle}>
        {modalConstants?.[change.type]}
      </p>
      {Object.keys(change.oldFields || {}).map((oldField) => (
        <Fragment key={oldField}>
          <p className={styles.label}>{requestFields?.[oldField]}</p>
          <p>{change.oldFields?.[oldField] || ' - '}</p>
        </Fragment>
      ))}
      <p className={styles.into}>Into</p>
      {Object.keys(change.newFields || {}).map((newField) => (
        <Fragment key={newField}>
          <p className={styles.label}>{requestFields?.[newField]}</p>
          <p>{change.newFields?.[newField] || ' - '}</p>
        </Fragment>
      ))}
    </article>
  )
}
