import React, { FC } from 'react'
import { Marker } from 'react-leaflet'
import { browserHistory } from 'react-router'
import { GpsPopup } from '@/pages/Gps/NewGps/components/Popup'
import { GpsRun, RunStatus } from '@/redux/gps/types'
import { makeLIcon } from '@/pages/Gps/NewGps/helpers'
import {
  getRotationPositionAndIndexOfMarker,
  MarkerPoint,
} from '@/helpers/points'
import 'leaflet-rotatedmarker'
import {
  handleMouseOut,
  handleMouseOver,
} from '@/pages/Gps/NewGpsDetails/helpers/common'

type GpsPinsProps = {
  gpsRuns: GpsRun[]
  isToday: boolean
}

export const GpsPins: FC<GpsPinsProps> = ({ gpsRuns, isToday }) => {
  const handleClick = (run: GpsRun) => {
    browserHistory.push({
      pathname: `/client/gps/${run.id}/${run.actualStartTimestamp}`,
    })
  }

  const runsMarkers = gpsRuns.map(
    (run) =>
      ({
        location: [+run.gps.latitude, +run.gps.longitude],
        id: run.id,
        object: { position: run.id },
      }) as MarkerPoint
  )

  return (
    <div>
      {gpsRuns.map((run) => {
        let status = RunStatus.Inactive
        if (!isToday || run.isConnect) {
          status = run.isLate ? RunStatus.Late : RunStatus.OnTime
        }
        if (run.status === RunStatus.AboutToStart) {
          status = RunStatus.AboutToStart
        }

        const { rotation, zIndex, position } =
          getRotationPositionAndIndexOfMarker(runsMarkers, {
            location: [+run.gps.latitude, +run.gps.longitude],
            id: run.id,
            object: { position: run.id },
          } as MarkerPoint)

        return (
          <Marker
            data-testid={`gps-pin-${run.id}`}
            key={`${run.id}_${rotation}`}
            icon={makeLIcon(status)}
            onClick={() => handleClick(run)}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            zIndexOffset={zIndex}
            rotationAngle={rotation}
            position={position}
          >
            <GpsPopup
              run={run}
              status={status}
              onClick={() => handleClick(run)}
            />
          </Marker>
        )
      })}
    </div>
  )
}
