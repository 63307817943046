import * as types from './actionTypes'
import { sortItemsByNumAbc } from '../../helpers/arrayWork'

const initialState = {
  consoles: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CONSOLE_GET_ITEMS: {
      return action.payload
    }
    case types.CONSOLE_ADD: {
      const item = action.payload
      const newState = [...state, { ...item }]
      return newState.sort(sortItemsByNumAbc)
    }
    case types.CONSOLE_UPDATE: {
      let newArr = [...state]
      newArr = newArr.filter(console => console.id !== action.payload.id)
      newArr.push(action.payload)
      return newArr.sort(sortItemsByNumAbc)
    }
    case types.CONSOLE_DELETE: {
      let newArr = [...state]
      newArr = newArr.filter(console => console.id !== action.payload)
      return newArr
    }

    case types.CONSOLE_DRIVERNAME: {
      let newArr = [...state]
      let data = action.payload
      let temp = []
      temp = newArr
      let tmp = {}
      temp.map(item => {
        if (item.driver_id) {
          tmp = data.filter(x => x.id === item.driver_id)
          if (tmp) {
            item.drivers = tmp
          }
        }
      })
      return newArr
    }

    default:
      return state
  }
}
