import { Dispatch, SetStateAction, useState } from 'react'
import { notification, Popover } from 'antd'
import styles from './TemplateSettings.module.less'
import { BaseInput } from '@/entities/ui/Inputs/BaseInput/BaseInput'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { Icon } from '@/entities/ui/Icon/Icon'
import {
  useCreateEmailTemplateMutation,
  useDeleteEmailTemplateMutation,
  useGetEmailTemplatesQuery,
  useUpdateEmailTemplateMutation,
} from '@/store/routeTool/RouteToolTemplateApi/RouteToolTemplateApi'
import { IEmailTemplate } from '@/store/routeTool/RouteToolTemplateApi/RouteToolTemplateApi.types'
import { ErrorMessage } from '@/entities/ui/Typography/ErrorMessage/ErrorMessage'

export const TemplateSettings = ({
  handleSetTemplate,
  selectedTemplate,
  isOpenPopup,
  setIsOpenPopup,
}: {
  selectedTemplate: IEmailTemplate
  isOpenPopup: boolean
  handleSetTemplate: (v: IEmailTemplate) => void
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>
}) => {
  const [editId, setEditId] = useState<number | null>(null)
  const [editValue, setEditValue] = useState('')

  const templates = useGetEmailTemplatesQuery()
  const [onDelete, deleteResponse] = useDeleteEmailTemplateMutation()
  const [onUpdate, updateResponse] = useUpdateEmailTemplateMutation()
  const [onCreate, createResponse] = useCreateEmailTemplateMutation()

  const options =
    templates.data?.data?.map((template) => ({
      label: template?.title,
      value: template?.id,
    })) ?? []

  const handleOnEdit = (id: number, label) => {
    if (id === editId) {
      setEditId(null)
      setEditValue('')
    } else {
      setEditId(id)
      setEditValue(label)
    }
  }

  const handleSave = async (id: number) => {
    if (!editValue?.trim()) {
      notification.error({ message: 'Title cannot be empty' })
    }
    await onUpdate({ id, title: editValue }).unwrap()
    setEditId(null)
    setEditValue('')
  }

  const [createValue, setCreateValue] = useState('')

  const handleCreate = () => {
    onCreate({ title: createValue })
    setCreateValue('')
  }

  return (
    <Popover
      onOpenChange={setIsOpenPopup}
      trigger="click"
      placement="bottomLeft"
      open={isOpenPopup}
      content={
        <article className={styles.contentRoot}>
          <div className={styles.itemWrapper}>
            {options.map((item) => {
              const isEdit = editId === item.value
              return (
                <div className={styles.item} key={item.value}>
                  {!isEdit ? (
                    <p
                      onClick={() => {
                        setIsOpenPopup(false)
                        handleSetTemplate(
                          templates?.data?.data?.find(
                            (el) => el.id === item.value
                          )
                        )
                      }}
                      className={styles.label}
                    >
                      {item.label}
                    </p>
                  ) : (
                    <BaseInput
                      value={editValue}
                      disabled={
                        updateResponse?.originalArgs?.id === item.value &&
                        updateResponse?.isLoading
                      }
                      onChange={(e) => setEditValue(e.target.value)}
                      size={'small'}
                    />
                  )}
                  <div className={styles.buttonsBlock}>
                    {isEdit && (
                      <div className={styles.editButtons}>
                        <Button
                          onClick={() => handleSave(item.value)}
                          variant="text"
                          disabled={!editValue?.trim()}
                          isLoading={
                            updateResponse?.originalArgs?.id === item.value &&
                            updateResponse?.isLoading
                          }
                          className={styles.saveButton}
                        >
                          Save
                        </Button>
                        <Button
                          onClick={() => handleOnEdit(item.value, item.label)}
                          buttonColor="black"
                          variant="text"
                        >
                          <Icon icon={'clear'} />
                        </Button>
                      </div>
                    )}
                    {!isEdit && (
                      <Button
                        onClick={() => handleOnEdit(item.value, item.label)}
                        buttonColor="black"
                        variant="text"
                      >
                        <Icon icon={'edit'} />
                      </Button>
                    )}
                    {options?.length > 1 && (
                      <Button
                        onClick={() => onDelete({ id: item.value })}
                        className={styles.deleteButton}
                        isLoading={
                          deleteResponse?.originalArgs?.id === item.value &&
                          deleteResponse?.isLoading
                        }
                        buttonColor="red"
                        variant="text"
                      >
                        <Icon icon={'trash'} />{' '}
                      </Button>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          <div className={styles.footer}>
            <BaseInput
              size={'small'}
              value={createValue}
              onChange={(e) => setCreateValue(e.target.value)}
              placeholder="Enter template name"
            />
            <Button
              buttonColor="black"
              leftIcon={<Icon icon={'addPlus'} />}
              variant="text"
              onClick={handleCreate}
              isLoading={createResponse.isLoading}
              disabled={!createValue.trim()}
            >
              Add Template
            </Button>
          </div>
        </article>
      }
    >
      {templates.status === 'rejected' ? (
        <ErrorMessage style={{ marginBottom: 6 }}>
          An error occurred while loading templates
        </ErrorMessage>
      ) : (
        <div style={{ marginTop: 6, width: 'fit-content' }}>
          <label className={styles.labelTemplate}>Current template</label>
          <div className={styles.root}>
            <span className={styles.labelPreview}>
              {selectedTemplate?.title ??
                templates.data?.data?.[0]?.title ??
                'Templates'}
            </span>
            <Icon
              width="11"
              height="6"
              viewBox="0 0 11 6"
              data-open={isOpenPopup}
              className={styles.iconArrow}
              icon="arrowLeft"
            />
          </div>
        </div>
      )}
    </Popover>
  )
}
