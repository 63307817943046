import { FormProvider, useForm } from 'react-hook-form'
import { ModalFooter } from '@/entities/ui/NewModal/components/ModalFooter'
import { FormInput } from '@/entities/ui/NewInput/FormInput'
import {
  ITransCat,
  IUpdateTransCatParams,
} from '@/store/lc/settings/settings.types'
import styles from './CreateAndEditTransCatModal.module.less'
import { useCallback, useMemo } from 'react'
import {
  useLazyCreateTransCatQuery,
  useLazyUpdateTransCatQuery,
} from '@/store/lc/settings/lcSettingsApi'
import { useValidation } from '@/entities/modals/CreateAndEditTransCatModal/useValidation'
import { ErrorMessage } from '@/entities/ui/Typography/ErrorMessage/ErrorMessage'

interface Props {
  variant: 'create' | 'edit'
  transCat?: ITransCat
}

export const CreateAndEditTransCatModal = ({ variant, transCat }: Props) => {
  const methods = useForm({
    defaultValues: useMemo(
      () => ({
        name: transCat?.name ?? '',
        short_name: transCat?.short_name ?? '',
      }),
      [transCat?.name, transCat?.short_name]
    ),
  })

  const [onUpdate, updateResponse] = useLazyUpdateTransCatQuery()
  const [onCreate, createResponse] = useLazyCreateTransCatQuery()
  const onSubmit = useCallback(
    (data: Pick<IUpdateTransCatParams, 'name' | 'short_name'>) => {
      if (variant === 'edit') {
        onUpdate({
          name: data.name,
          short_name: data.short_name,
          id: transCat?.id,
          company_id: transCat?.company_id,
          description: transCat?.decription ?? transCat?.description ?? null,
        })
      } else if (variant === 'create') {
        onCreate({
          name: data.name,
          short_name: data.short_name,
        })
      }
    },
    [
      onCreate,
      onUpdate,
      transCat?.company_id,
      transCat?.decription,
      transCat?.description,
      transCat?.id,
      variant,
    ]
  )
  const { nameValidation, shortNameValidation } = useValidation(
    transCat,
    variant
  )
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className={styles.root}>
          <FormInput
            showErrorText={false}
            rules={nameValidation}
            name="name"
            placeholder="Category"
            label="Category"
          />
          <FormInput
            showErrorText={false}
            rules={shortNameValidation}
            name="short_name"
            placeholder="up to 3 Symb..."
            label="Pin Abbreviation"
          />
        </div>
        <div>
          {Object.values(methods.formState.errors).map((el, index) => (
            <ErrorMessage key={index}>{el?.message}</ErrorMessage>
          ))}
        </div>
        <ModalFooter
          success={{
            text: variant === 'create' ? 'Create' : 'Update',
            isSubmitForm: true,
            isLoading: updateResponse.isFetching || createResponse.isFetching,
          }}
        />
      </form>
    </FormProvider>
  )
}
