import React, { Component } from 'react'
import './index.less'
import { getValid } from '../../../../../../components/validPhone/index'

import Row from 'antd/es/row'
import Col from 'antd/es/col'
import { Icon } from '@/entities/ui/Icon/Icon'
import styles from './index.module.less'
import { useRouterStore } from '@/shared/hooks/routeHooks/useRouter'
class Riders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
    }
  }

  getPhone = (child) => {
    if (this.props.phone) {
      if (child.first_guardian_phone) {
        return child.first_guardian_phone
      }

      if (child.second_guardian_phone) {
        return child.second_guardian_phone
      }

      if (child.emergency_contact_phone) {
        return child.emergency_contact_phone
      }
    }
  }

  render() {
    let { type } = this.props
    let WeekName
    // let times = JSON.parse(this.state.child.session_times);
    // if (Object.keys(times)[0] !== "0") {
    //   WeekName = Object.keys(times)[0];
    // }
    const isProblemChild = !this.state.child
    const isViewRun = useRouterStore
      .getState()
      .router.pathName?.includes('RouteTool/routes-view')
    return (
      <div data-type={type}>
        <div style={{ width: '100%' }}>
          <div>
            <div>
              <div
                key={this.props.address.id}
                style={isProblemChild ? { background: '#ffd8d7' } : {}}
                className="riderInfo pickUpList PUP"
              >
                {isProblemChild ? (
                  <div className="marginBttom" style={{ display: 'flex' }}>
                    <div style={{ width: '80%' }}>
                      <span className="SchoolNames">Deleted rider</span>
                    </div>
                  </div>
                ) : (
                  this.state.child.full_name && (
                    <div className="marginBttom" style={{ display: 'flex' }}>
                      <div style={{ width: '80%' }}>
                        <span className="SchoolNames">
                          {!isProblemChild
                            ? this.state.child.full_name
                            : 'Deleted rider'}
                        </span>
                      </div>
                    </div>
                  )
                )}

                {!isProblemChild && this.getPhone(this.state.child) && (
                  <Row style={{ width: '100%' }} className="MarginBlock flex">
                    <Col
                      span={1}
                      className="PhoneIco"
                      style={{ left: '10px' }}
                    />
                    <Col span={23}>
                      <span className="number">
                        {getValid(
                          this.getPhone(this.state.child),
                          '+1 ###-###-####'
                        )}
                      </span>
                    </Col>
                  </Row>
                )}

                {!isProblemChild &&
                  this.props.address?.childaddress?.address?.address &&
                  isViewRun && (
                    <Row style={{ width: '100%' }} className="MarginBlock flex">
                      <Col span={1} style={{ left: '10px' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            width: '100%',
                          }}
                        >
                          <Icon
                            className={styles.pinIcon}
                            icon={'pin'}
                            width={10}
                            height={20}
                          />
                        </div>
                      </Col>
                      <Col span={23}>
                        <span style={{ paddingLeft: 12 }}>
                          {this.props.address?.childaddress?.address?.address}
                        </span>
                      </Col>
                    </Row>
                  )}

                {!isProblemChild && this.state.child.school_name && (
                  <div className="marginBttom" style={{ marginTop: '10px' }}>
                    <span className="Address">
                      {' '}
                      {this.state.child.school_name}
                    </span>
                    {this.state.child.classs && (
                      <span className="normal" style={{ marginLeft: '28px' }}>
                        {this.state.child.classs.replace('th', '')}th{' '}
                        <span className="gray">Grade</span>
                      </span>
                    )}
                  </div>
                )}

                <div
                  className="calendars marginBttom"
                  style={{ marginLeft: '25px' }}
                >
                  {/*<div
                    className="sessions"
                    style={{ display: "flex", width: "100%",flexDirection:'column'}}
                  >
                    <SessionTimes
                      item={this.state.child.session_times}
                      type="getTimesForRunPage"
                    />
                  </div>
                  <div className="marginBttom">{WeekName}</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Riders
