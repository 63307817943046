import BuildRunContext from './context'
import BuildRun from './BuildRun'
import React from 'react'
import BuildRunModals from './BuildRunModals'

export default function (props) {
  return (
    <BuildRunContext
      activeRunsStart={props.activeRunsIds}
      setActiveRunCb={props.setActiveRunCb}
      activeRunId={props.activeRunId}
    >
      <BuildRun
        activeRunId={props.activeRunId}
        showAnyModalFn={props.showAnyModalFn}
        activeRunsIds={props.activeRunsIds}
        setActiveRunCb={props.setActiveRunCb}
        addInRun={props.addInRun}
        removeChildFromStop={props.removeChildFromStop}
        removeStopFromRun={props.removeStopFromRun}
        activeRunTypeCb={props.activeRunTypeCb}
        buildRunMenuOpenTypeCb={props.buildRunMenuOpenTypeCb}
      />
      <BuildRunModals addInRun={props.addInRun} />
    </BuildRunContext>
  )
}
