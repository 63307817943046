import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "../../../../../../../components/BaseModal";
import * as routeAction from "../../../../../../../redux/routesView/actions";
import i18n from "../../../../../../../i18n"
import Row from "antd/es/row";
import Col from "antd/es/col";
import Input from "antd/es/input";
import SelectDefault from "antd/es/select";

import "./index.less";
import * as runActions from '../../../../../../../redux/routesView/actions'
import {addressWithOptions} from "../../../../../../../helpers/url";

class AllPurpose extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      name: "",
      typing: false,
      TypeInput: false,
      ValidError: false,
      addressEmpty: false,
      stopTitleEmpty: false,
      typingTimeout: 0,
      waittime: this.props.waittime,
      position: this.props.positon,
      busStopTitle: this.props.busStopTitle,
      address: this.props.fullAdress
    };
  }

  handleSaveWithGeo = (onModalClose) => {
    let { id, position, waittime, busStopTitle, address } = this.state;
    let { dispatch } = this.props
    const encodedPlotAddress = encodeURIComponent(address)
    const companyOpts = this.props.companyOptions && this.props.companyOptions.geocode_provider
    if (address === '') {
      this.setState({
        addressEmpty: true
      })
      return
    }
    if (busStopTitle === '') {
      this.setState({
        stopTitleEmpty: true
      })
      return
    }
    dispatch(
      runActions.getAddressGeo(addressWithOptions(encodedPlotAddress, companyOpts), () => {
        dispatch(routeAction.editPoints(
          id,
          position,
          this.props.routeId,
          waittime,
          busStopTitle,
          address
        ))
        onModalClose()
      }, this.props.companyOptions)
    );
  };

  handleSave = () => {
    let { id, position, waittime, busStopTitle, address } = this.state;
    this.props.dispatch(
      routeAction.editPoints(
        id,
        position,
        this.props.routeId,
        waittime,
        busStopTitle,
        address
      )
    );
    this.props.closeFn();
  };

  handleMinute = e => {
    let val = e.target.value;
    this.setState({ waittime: val * 60 });
  };

  handleStop = (e, type) => {
    e.preventDefault();
    let { dispatch } = this.props;
    dispatch(runActions.setGeoAddressResponse())
    let {stopTitleEmpty, addressEmpty} = this.state
    if (stopTitleEmpty) {
      this.setState({
        stopTitleEmpty: false
      })
    }
    if (addressEmpty) {
      this.setState({
        addressEmpty: false
      })
    }
    let val = e.target.value;
    this.setState({
      [type]: val
    });
  };

  render() {
    let { input, title, closeFn, Address , depot ,school , children} = this.props;
    let { busStopTitle, address, stopTitleEmpty, addressEmpty } = this.state;

    return (
      <Modal
        saveBtn={i18n.t("Save")}
        title={title}
        closeFn={closeFn}
        save={() => {
          Address && title ? this.handleSaveWithGeo(() => closeFn()) : this.handleSave()
        }}
        class={"ModalPurpose open"}
      >
        <div className="profile">
          <div>
            <Row>
              {input && (
                <Col span={24}>
                  <div>
                    <label>{input[0].label}</label>
                    <Input
                      placeholder={input[0].placeholder}
                      className={stopTitleEmpty ? 'error' : ''}
                      value={busStopTitle && busStopTitle}
                      onChange={e => this.handleStop(e, `busStopTitle`)}
                    />
                  </div>
                </Col>
              )}

              {Address && (
                <Col span={24}>
                  <div>
                    <label>{Address[0].label}</label>
                    <Input
                      placeholder={Address[0].placeholder}
                      className={addressEmpty || (this.props.addressGeo && this.props.addressGeo.errorMessage) ? 'error' : ''}
                      value={address}
                      onChange={e => this.handleStop(e, `address`)}
                    />
                  </div>
                </Col>
              )}

              {this.props.select && (
                <Col span={24}>
                  <label>{this.props.select[0].label}</label>

                  {children && (
                    <div>{children.full_name}</div>
                  )}
                  {school && school.name && (
                    <SelectDefault
                      disabled
                      style={{ width: "100%" }}
                      defaultValue={0}
                    >
                      <option selected value={0}>{school.name}</option>
                    </SelectDefault>
                  )}

                  {depot && depot.name && (
                    <SelectDefault
                      disabled
                      style={{ width: "100%" }}
                      defaultValue={0}
                    >
                      <option selected value={0}>{depot.name}</option>
                    </SelectDefault>
                  )}

                </Col>
              )}

              <Col span={24}>
                <Row>
                  {this.props.minute && (
                    <Col span={12}>
                      <div>
                        <label>{this.props.minute[0].label}</label>
                        <Input
                          placeholder={this.props.minute[0].placeholder}
                          defaultValue={
                            this.state.waittime / 60
                              ? this.state.waittime / 60
                              : 0
                          }
                          onChange={e => this.handleMinute(e)}
                        />
                      </div>
                    </Col>
                  )}

                  {this.props.stops && (
                    <Col span={12}>
                      <div>
                        <label>{this.props.stops[0].label}</label>
                        <Input
                          placeholder={this.props.stops[0].placeholder}
                          defaultValue={
                            this.state.position ? this.state.position : 0
                          }
                          onChange={e => this.handleStop(e, `position`)}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </div>

        <div className="center" style={{ width: "100%" }}>
          <div className="buttons">
            <button className="cancel" onClick={closeFn}>
              {i18n.t("Cancel")}
            </button>
            <button
              className="save"
              onClick={() => {
                Address && title ? this.handleSaveWithGeo(() => closeFn()) : this.handleSave()
              }}
              style={{ whitespace: "pre" }}
            >
              {i18n.t("Save")}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    addressGeo: state.routesView.addressGeo,
    companyOptions: state.auth.companyOptions,
  };
}

const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllPurpose);
