export const getInit = () => ({
  setup: (editor) => {
    editor.ui.registry.addMenuButton('keys', {
      text: `Insert `,
      fetch: (callback) => {
        const items = getItems(editor)
        callback(items)
      },
    })
  },
  height: 500,
  menubar: false,
  plugins: [
    'advlist',
    'autolink',
    'lists',
    'link',
    'image',
    'charmap',
    'preview',
    'anchor',
    'searchreplace',
    'visualblocks',
    'code',
    'fullscreen',
    'insertdatetime',
    'media',
    'table',
    'code',
    'help',
    'wordcount',
  ],
  skin: 'material-classic',
  toolbar:
    'undo redo | blocks | ' +
    'keys | bold italic forecolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | help',
  content_style:
    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
})

const getItems = (editor) => {
  const keys = {
    RIDER_NAME: '{Rider Name}',
    SCHOOL_NAME: '{School Name}',
    PICKUP_STOP_NAME: '{Pickup Stop Name}',
    PICKUP_ADDRESS: '{Pickup Address}',
    PICKUP_TIME: '{Pickup Time}',
    DROPOFF_STOP_NAME: '{Dropoff Stop Name}',
    DROPOFF_ADDRESS: '{Dropoff Address}',
    DROPOFF_TIME: '{Dropoff Time}',
    BUS_AM: '{BUS_AM}',
    BUS_PM: '{BUS_PM}',
    RUN_NAME_AM: '{Run Name AM}',
    RUN_NAME_PM: '{Run Name PM}',
    AM_DRIVER: '{AM_DRIVER}',
    PM_DRIVER: '{PM_DRIVER}',
    AM_MONITOR: '{AM_MONITOR}',
    PM_MONITOR: '{PM_MONITOR}',
    SESSION_NAME: '{SESSION_NAME}',
    SESSION_START_DATE: '{SESSION_START_DATE}',
    SESSION_END_DATE: '{SESSION_END_DATE}',
    COMPANY_NAME: '{Company Name}',
    COMPANY_EMAIL: '{Company Email}',
    COMPANY_PHONE: '{Company Phone}',
    QR_CODE: '{QR code}',
    SCHOOL_NAME_AM: '{School Name AM}',
    SCHOOL_NAME_PM: '{School Name PM}',
    GUARDIAN_FULL_NAME: '{Guardian Full Name}',
    GOOGLE_WALLET_PASS: '{GOOGLE_WALLET_PASS}',
    APPLE_WALLET_PASS: '{APPLE_WALLET_PASS}',
  }

  const items = []
  for (const key in keys) {
    items.push({
      type: `menuitem`,
      text: key,
      onAction: () => editor.insertContent(`&nbsp;${keys[key]}&nbsp;`),
    })
  }
  return items
}
