import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { ViewFilterIcon } from '@/img/icons'

export const ViewFilterLive = () => {
  const timeRef = useRef<HTMLSpanElement>(null)
  const interval = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (timeRef.current) {
      interval.current = setInterval(() => {
        const showDots = timeRef.current?.textContent?.includes(':')
        const secVisible = !showDots ? ' : ' : '   '
        timeRef.current.textContent = moment().format(
          'hh' + secVisible + 'mm A'
        )
      }, 1000)
    }
    return () => {
      interval.current && clearInterval(interval.current)
    }
  }, [])

  return (
    <div
      style={{ height: 32, padding: '0 10px' }}
      className="inpGps timeFilter"
    >
      <span ref={timeRef} style={{ whiteSpace: `pre` }} />
      <span className={'sep'} />
      <ViewFilterIcon />
    </div>
  )
}
