import React, { Component } from 'react'
import Modal from '../../Modal/DeleteStop/index'
import i18n from '../../../../../../../i18n'
import { connect } from 'react-redux'

class StopChildAddress extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
      removeModal: false,
      isLoading: false,
    }
  }

  componentDidMount() {
    this.setState({
      isLoading: this.props.loader === 'loadingStart',
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loader !== this.props.loader) {
      this.setState({
        isLoading: this.props.loader === 'loadingStart',
      })
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ ...nextProps })
  }

  deletePointFromStopModal = () => {
    this.setState({
      removeModal: !this.state.removeModal,
    })
  }

  delFn = () => {
    this.props.delChildFromStop(
      this.state.id,
      this.state.child.id,
      this.state.address.child_address_id
    )

    this.deletePointFromStopModal()
  }

  render() {
    const {
      address,
      readOnly,
      transferMode,
      isWriteRoutes,
      transferModalOpen,
    } = this.props

    let schoolName = this.state.address.childaddress.school.name
    const { isLoading } = this.state
    const isProblemChild = !this.state.child
    const type = this.props.type ?? 'PU'
    return (
      <div>
        <div style={{ width: '100%' }}>
          <div>
            <div>
              <div
                key={address.id}
                style={isProblemChild ? { background: '#ffd8d7' } : {}}
                className="riderInfo pickUpList PUP"
              >
                <div className="marginBttom" style={{ display: 'flex' }}>
                  <div style={{ width: '100%' }}>
                    <span className="SchoolNames">
                      {!isProblemChild
                        ? this.state.child.full_name
                        : 'Deleted rider'}
                    </span>
                  </div>
                  <div>
                    {!readOnly && !isWriteRoutes && (
                      <button
                        disabled={isLoading}
                        className="disabledBtn removeSpan"
                        onClick={this.deletePointFromStopModal}
                      >
                        <span> </span>
                      </button>
                    )}
                  </div>
                </div>

                <div className="marginBttom">
                  {transferMode && (
                    <span className="Address toTransferModal">
                      {' '}
                      {schoolName}
                      {!isWriteRoutes && (
                        <button
                          disabled={isLoading}
                          onClick={transferModalOpen}
                          className="disabledBtn toTransferBtn"
                          title="Add riders to transfer stop"
                        >
                          T
                        </button>
                      )}
                    </span>
                  )}

                  {isProblemChild
                    ? ''
                    : this.state.child.classs && (
                        <div className="schools" style={{ borderTop: 'none' }}>
                          <span className="normal">
                            <span className="gray">{i18n.t('Grade')}: </span>
                            {this.state.child.classs}
                          </span>
                        </div>
                      )}
                </div>

                <div
                  className="calendars marginBttom"
                  style={{ marginLeft: '25px' }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        {this.state.removeModal && (
          <Modal
            closeFn={this.deletePointFromStopModal}
            title={i18n.t('Remove Rider')}
            deleteBtn={i18n.t('Remove Rider')}
            child={true}
            deleteObject={
              isProblemChild ? 'Deleted rider' : this.state.child.full_name
            }
            deleteFn={() => this.delFn()}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loader: state.headerLoader.loading,
  }
}

export default connect(mapStateToProps)(StopChildAddress)
