import { NewModal } from '@/entities/ui/NewModal/NewModal'
import { ModalsEnum, useModalSelector } from '@/store/modal/ModalStore'
import styles from './DeleteBusDepotModal.module.less'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { useDeleteBusDepotMutation } from '@/store/resources/resources'
import { IBusDepot } from '@/store/resources/resources.types'

export const DeleteBusDepotModal = () => {
  const { isOpen, onCloseModal, state } = useModalSelector(
    ModalsEnum.DELETE_BUS_DEPOT
  )

  return isOpen ? (
    <_DeleteBusDepotModal
      state={state as IBusDepot}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
    />
  ) : null
}

const _DeleteBusDepotModal = ({
  isOpen,
  onCloseModal,
  state,
}: {
  isOpen: boolean
  onCloseModal: () => void
  state: IBusDepot
}) => {
  const [request, response] = useDeleteBusDepotMutation()
  const onDelete = () => {
    request(state.id)
  }
  return (
    <NewModal
      title="Delete depot"
      width={420}
      open={isOpen}
      onClose={onCloseModal}
      data-testid="deleteDepotModal"
    >
      <p className={styles.text}>Do you want to delete this depot?</p>
      <div className={styles.footer}>
        <Button onClick={onCloseModal} buttonColor="gray">
          Cancel
        </Button>
        <Button
          data-testid="deleteDepotBtn"
          onClick={onDelete}
          isLoading={response.isLoading}
        >
          Delete
        </Button>
      </div>
    </NewModal>
  )
}
