import React, { Component } from "react";
import { connect } from "react-redux";
import BaseModal from "../../../../../components/BaseModal";
import api from "../../../../../redux/apiConfig";
import "./index.less";
import DatePicker from "antd/es/date-picker";
import moment from "moment";
import { Spin } from "antd";
import { NewWeekPicker } from "../../../../../entities/ui/NewWeekPicker/NewWeekPicker";

let rq = api.routesPdf;
const { WeekPicker } = DatePicker;
const dateFormat = "MM/DD/YYYY";

moment.locale("en");

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeDate: 0,
      errorDate: false,
      open: false,
      spinning: false,
      selectWeek: moment().format(dateFormat)
    };
  }

  componentDidMount() {
    let date = moment().unix();
    let Mon = moment(date * 1000)
      .weekday(0)
      .format("MM/DD/YYYY");
    let Sun = moment(date * 1000)
      .weekday(6)
      .format(dateFormat);

    let last = moment(date * 1000)
      .weekday(6)
      .format(dateFormat);

    let Range = Mon + " - " + Sun;

    this.setState({
      changeWeek: "week_pk_selected",
      onChangeWeek: true,
      dateSeleced: moment(Mon).unix(),
      selectDate: Mon,
      Last: last,
      selectedDate: date,
      selectWeek: Range
    });
  }

  handleChange = (type, value) => {
    let date = moment(value).unix();

    let Mon = moment(date * 1000)
      .weekday(0)
      .format(dateFormat);
    let Sun = moment(date * 1000)
      .weekday(6)
      .format(dateFormat);

    let last = moment(date * 1000)
      .weekday(6)
      .format(dateFormat);

    let Range = Mon + " - " + Sun;

    this.setState({
      selectDate: value,
      selectWeek: Range,
      open: false,
      dateSeleced: moment(date * 1000)
        .weekday(0)
        .unix()
    });
  };

  onChange = (type, e) => {
    if (type === "activeDate") {
      this.setState({
        [type]: e.unix(),
        errorDate: false
      });
    } else {
      this.setState({
        [type]: e.target.checked
      });
    }
  };

  saveBtn = () => {
    this.setState({ spinning: true });
    let { dateSeleced } = this.state;

    let dateChange = moment(dateSeleced * 1000)
      .utc()
      .unix();

    let d = moment(dateChange).format("YYYY-MM-DD");
    let date = moment(dateChange * 1000).utc(`${d} ${7}:00`);
    let dd = date.unix();

    let string = "";
    this.props.checkedList.forEach((x, i) => {
      string += x;
      if (i !== this.props.checkedList.length - 1) {
        string += ",";
      }
    });

    let url = `/attendances/print?date=${dd}&route_id=${string}`;

    rq.get(url).then(response => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.props.closeFn("printAttendanceSheets", "");
    });
  };

  handleCallback = (start, end, type) => {
    if (type === "Attendance") {
      let day = moment(start)
        .weekday(0)
        .unix();

      this.setState({
        dateSeleced: day
      });
    }
  };

  handleClose = () => {};

  render() {
    let closeFn = this.props.closeFn;

    return (
      <BaseModal
        title={"Attendance Sheets"}
        closeFn={() => closeFn("printAttendanceSheets", "")}
        save={this.handleSave}
      >
        <Spin spinning={this.state.spinning} tip="Loading...">
          <div id="modalPrint" className="content">
            <div
              className="modal-body attendance-modal"
              onClick={this.handleClose}
            >
              <NewWeekPicker
                routeParamId={1}
                value="attendance"
                name="Attendance"
                callback={this.handleCallback}
              />
            </div>
          </div>

          <div className="center">
            <div className="buttons">
              <button
                className="cancel"
                onClick={() => closeFn("printAttendanceSheets", "")}
              >
                Cancel
              </button>
              <button onClick={this.saveBtn} className={`save`}>
                Print
              </button>
            </div>
          </div>
        </Spin>
      </BaseModal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
