import * as types from './actionTypes'

export const initState = {
  openedRequests: [],
  closedRequests: [],
  openedRequestsHeaders: {},
  closedRequestsHeaders: {},
  requestsMessages: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case types.RESET_OPENED_REQUESTS: {
      return {
        ...state,
        openedRequests: []
      }
    }
    case types.RESET_CLOSED_REQUESTS: {
      return {
        ...state,
        closedRequests: []
      }
    }
    case types.GET_OPENED_REQUESTS: {
      return {
        ...state,
        openedRequests: [...state.openedRequests, ...action.payload.data],
        openedRequestsHeaders: action.payload.headers
      }
    }
    case types.GET_CLOSED_REQUESTS: {
      return {
        ...state,
        closedRequests: [...state.closedRequests, ...action.payload.data],
        closedRequestsHeaders: action.payload.headers
      }
    }
    case types.GET_REQUEST_MESSAGES: {
      return {
        ...state,
        requestsMessages: action.payload
      }
    }
    case types.POST_REQUEST_MESSAGE: {
      return {
        ...state
      }
    }
    case types.POST_REJECT_REQUEST: {
      return {
        ...state
      }
    }
    case types.POST_AUTO_ACCEPT_REQUEST: {
      return {
        ...state
      }
    }
    case types.POST_MANUAL_ACCEPT_REQUEST: {
      return {
        ...state
      }
    }
    case types.REMOVE_REQUEST: {
      const requestItemId = action.payload;
      const updatedRequests = state.openedRequests.filter(item => item.id !== requestItemId);
      return {
        ...state,
        openedRequests: updatedRequests,
      }
    }
    default:
      return state
  }
}
