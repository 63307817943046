import { NewModal } from '@/entities/ui/NewModal/NewModal'
import { ModalFooter } from '@/entities/ui/NewModal/components/ModalFooter'
import styles from './EditFieldTripModal.module.less'
import { ModalsEnum, useModalSelector } from '@/store/modal/ModalStore'
import { useEditFieldTripMutation } from '@/store/fieldTrip/FieldTripApi'

export const EditFieldTripModal = () => {
  const { isOpen, state } = useModalSelector(ModalsEnum.EDIT_FIELD_TRIP_MODAL)
  const [onEdit, response] = useEditFieldTripMutation()
  return (
    <NewModal width={330} open={isOpen} title="Edit the Field Trip">
      <p className={styles.text}>
        After pressing "Edit" the Field Trip will be moved to the{' '}
        <b>request list</b>, you will need to <b>resend</b> it for approval?
      </p>
      <ModalFooter
        buttonWidth={80}
        success={{
          action: () => onEdit(state.id),
          isLoading: response.isLoading,
          text: 'Edit',
        }}
      />
    </NewModal>
  )
}
