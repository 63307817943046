import React, { Component } from "react";
import { connect } from "react-redux";

import BaseModal from "../../../../../components/BaseModal";
import "./index.less";

class ModalInfo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BaseModal
        class="ftModalAllRuns"
        title={this.props.title}
        closeFn={this.props.closeFn}
        save={this.props.closeFn}
      >
        {this.props.children}

        <div className="center">
          <div className="buttons">
            <button className="save" onClick={this.props.closeFn}>
              OK
            </button>
          </div>
        </div>
      </BaseModal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalInfo);
