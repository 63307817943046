import { IconList } from '@/entities/ui/Icon/IconList.constatnts'
import { cloneElement, HTMLProps, isValidElement } from 'react'

interface Props extends Omit<HTMLProps<HTMLDivElement>, 'size'> {
  icon: keyof typeof IconList
  viewBox?: string
}

export const Icon = ({ icon, ...props }: Props) => {
  const element = IconList[icon]

  return isValidElement(Element) ? null : cloneElement(element, props)
}
