import * as types from './actionTypes'
import { GpsRun, GpsRunWithDetails } from '@/redux/gps/types'

export type GpsState = {
  gpsRuns: GpsRun[]
  gpsRun: GpsRunWithDetails | null
  isLoading: boolean
}

const initialState: GpsState = {
  gpsRuns: [],
  gpsRun: null,
  isLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GPS_SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }
    case types.GPS_GET_RECORDS: {
      return { ...state, gpsRuns: action.payload }
    }
    case types.GPS_RECORDS_CLEAN: {
      return { ...state, gpsRuns: [] }
    }
    case types.GPS_GET_RECORD_BY_ID: {
      const gpsRuns = [...state.gpsRuns]
      const index = gpsRuns.findIndex((x) => x.id === action.payload.id)
      if (index !== -1) gpsRuns.splice(index, 1)
      gpsRuns.push(action.payload)
      return { ...state, gpsRuns }
    }
    case types.GPS_GET_RUN: {
      return {
        ...state,
        gpsRun: action.payload,
      }
    }

    default:
      return state
  }
}
