import { create } from 'zustand'
import {
  IIgnoreMessage,
  IImportStore,
  IMeta,
  IResponse,
} from '@/features/containers/SocketConnect/SocketConnect.type'

export const useImportSocketResponse = create<IImportStore>((set, get) => ({
  data: [],
  normalizeData: {},
  reset: () =>
    set({
      data: [],
      normalizeData: {},
      process: 'initial',
      isStarted: false,
      uidImport: '',
    }),
  process: 'initial',
  uidImport: '',
  isStarted: false,
  setProcess: (process) => set({ process }),
  setData: (meta: IResponse<IMeta | IIgnoreMessage>) => {
    const uniqueId = meta?.uniqueId
    if (!get().normalizeData?.[uniqueId]) {
      set({
        data: [...get().data, meta],
        normalizeData: {
          ...get().normalizeData,
          ...(uniqueId ? { [uniqueId]: meta } : {}),
        },
      })
    }
  },
  serArrayData: (arr: IResponse<IMeta | IIgnoreMessage>[]) =>
    set({ data: [...get().data, ...arr] }),
  setStore: (params: Partial<IImportStore>) => set({ ...get(), ...params }),
}))

interface IStore {
  optimize: {
    isLoading: boolean
  }
  setOptimize: (optimize: Partial<IStore['optimize']>) => void
}
export const useSocketConnectStore = create<IStore>((set, get) => ({
  optimize: {
    isLoading: false,
  },
  setOptimize: (optimize) =>
    set({ optimize: { ...get().optimize, ...optimize } }),
}))
