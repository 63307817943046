import { Radio, RadioProps } from 'antd'
import classnames from 'classnames'
import styles from './NewRadio.module.less'
import { Controller } from 'react-hook-form'
import { IRules } from '@/entities/ui/NewInput/FormInput'
import { forwardRef } from 'react'

export const NewRadio = ({ className, ...props }: RadioProps) => {
  return (
    <div style={{ position: 'relative' }}>
      <Radio className={classnames(styles.root, className)} {...props} />
    </div>
  )
}

interface IFormRadio extends RadioProps {
  name?: string
  rules?: IRules
}
export const FormRadio = forwardRef(
  ({ name, rules, ...rest }: IFormRadio, ref) => {
    return (
      <Controller
        rules={rules}
        name={name}
        render={({ field }) => (
          <NewRadio ref={ref ?? field.ref} {...rest} {...field} />
        )}
      />
    )
  }
)
