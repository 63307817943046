import React, { Fragment, useEffect, useState } from 'react'
import Col from 'antd/es/col'
import Row from 'antd/es/row'
import { Trans } from 'react-i18next'
import SelectBlock from './SelectBlock'
import { useContextBuilder } from '../../context'
import history from '../../../../../../helpers/history'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { Icon } from '@/entities/ui/Icon/Icon'
import { openModal } from '@/features/containers/ModalContainer/modalConfig'

export default function ({ setActiveRunCb }) {
  const [adminFn, setAdminFn] = useState(false)
  let {
    setOpenModal,
    activeRunsIds,
    transferMode,
    setActiveRunsIds,
    setEmptyRunFlag,
    selectActiveIndex,
    setSelectActiveIndex,
    emptyRunFlag,
    routesView,
    auth,
    activeRun,
  } = useContextBuilder()

  useEffect(() => {
    document.addEventListener('keypress', (e) => {
      if (e.shiftKey && e.ctrlKey && e.code === `KeyK`) setAdminFn(!adminFn)
    })
  }, [])

  useEffect(() => {
    if (emptyRunFlag) {
      setSelectActiveIndex(0)
      setActiveRunsIds([0, 0])
    }
  }, [emptyRunFlag])

  const isWriteRoutes = auth?.permissions?.write_routes !== 1

  let loadRun = (routeId, selectIndex) => {
    let copyIds = [...activeRunsIds]
    //получаю с сервера все WP и сортирую по position

    if (!routeId) return false

    if (selectIndex === 1) {
      history.push(`/client/RouteTool/routes/${copyIds[0]}/${routeId}`)
    } else if (selectIndex === 0 && transferMode) {
      history.push(`/client/RouteTool/routes/${routeId}/${copyIds[1]}`)
    } else if (selectIndex === 0 && !transferMode) {
      history.push(`/client/RouteTool/routes/${routeId}`)
    }

    if (selectIndex === 1) {
      copyIds[1] = routeId
    } else {
      copyIds[0] = routeId
      copyIds[1] = 0
    }

    if (selectIndex === selectActiveIndex) {
      setActiveRunCb(copyIds, routeId)
    }
    setActiveRunsIds(copyIds)
    setEmptyRunFlag(false)
  }

  let selectActiveFn = (index, activeRunId) => {
    if (activeRunsIds[1]) {
      setSelectActiveIndex(index)
      setActiveRunCb(activeRunsIds, activeRunId)
    }
  }

  return (
    <Row>
      <Col span={19}>
        {activeRunsIds.map((activeRunId, index) => {
          if (!activeRunId && index && !transferMode)
            return <Fragment key={index} />
          //if (!this.state.routesGroupWithRoutes) return false; //TODO

          if (transferMode) {
            let activeSelect = index === selectActiveIndex
            return (
              <Row key={index}>
                <Col span={2}>
                  <input
                    type="checkbox"
                    className={`checkboxTransfer ${
                      activeSelect ? ' checked' : ''
                    }`}
                    name="radioButton"
                    checked={activeSelect}
                    onClick={() => selectActiveFn(index, activeRunId)}
                    style={{ marginTop: '8px', marginLeft: ' 6px' }}
                  />
                  <label htmlFor="radioButton"></label>
                </Col>

                <Col span={22} style={{ padding: `0 10px 0px 0px` }}>
                  <SelectBlock
                    activeRunId={activeRunId}
                    activeRunsIds={activeRunsIds}
                    activeSelect={activeSelect}
                    transferMode={true}
                    loadRun={(routeId) => loadRun(routeId, index)}
                  />
                </Col>
              </Row>
            )
          } else {
            return (
              <Row key={index}>
                <Col span={24} style={{ padding: `0 10px` }}>
                  <SelectBlock
                    activeRunId={activeRunId}
                    activeRunsIds={activeRunsIds}
                    index={index}
                    activeSelect={transferMode && index === selectActiveIndex}
                    transferMode={false}
                    loadRun={(routeId) => loadRun(routeId, index)}
                  />
                </Col>
              </Row>
            )
          }
        })}
      </Col>

      <Col span={5}>
        <Button
          variant="text"
          buttonColor="titleDark"
          style={{ marginTop: 4 }}
          disabled={!activeRunsIds[0] || isWriteRoutes || routesView.loading}
          onClick={() =>
            openModal({
              currentModal: 'cloneRunModal',
              modalProps: {
                runId: activeRun?.id,
                runName: activeRun?.title,
                time_zone: activeRun?.time_zone,
              },
              modalSettings: {
                width: 600,
              },
            })
          }
          rightIcon={<Icon icon={'copy'} />}
        >
          Clone Run
        </Button>
        <div
          className={`cloneRunBtn ${adminFn ? 'artefactOn' : `artefact`}`}
          onClick={() => setOpenModal('schedule_run')}
        >
          <Trans>Run's Schedule</Trans>
        </div>
      </Col>
    </Row>
  )
}
