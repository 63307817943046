import React from "react";
import "./loding.less";
import logo from "../../img/logo.svg";

const Loading = ({ isLoading, error }) => {

  if (isLoading) {
    return (
      <div className="loading_logo">
        <img src={logo}  alt={''}/>
      </div>
    );
  } else if (error) {
    console.log('error', error)
    return (
      <div className="loading_logo">
        <img src={logo}  alt={''}/>
        <div>Sorry, there was a problem loading the page.</div>
      </div>
    );
  } else {
    return null;
  }
};

export default Loading;
