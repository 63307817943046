import React from 'react'
import { Trans } from 'react-i18next'
import { openModal } from '@/features/containers/ModalContainer/modalConfig'

function EmptyRun() {
  return (
    <div className="empty">
      <div className="img" />
      <div className="emptyRunText">
        <Trans>There is No Run Chosen!</Trans>
        <br />
        <Trans>Please Select a Run</Trans>
        <br />
        <Trans>or</Trans>
      </div>
      <div className="emptyRunButton">
        <div
          data-testid="addNewRun"
          className="btnNewRan"
          onClick={() =>
            openModal({
              currentModal: 'addNewRunModal',
              modalSettings: { title: 'Add a New Run' },
            })
          }
        >
          <span>
            <Trans>+ Add New Run</Trans>
          </span>
        </div>
      </div>
    </div>
  )
}

export default EmptyRun
