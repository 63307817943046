import { Popup } from 'react-leaflet'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import React, { FC } from 'react'
import './index.less'
import { GpsRun, RunStatus } from '@/redux/gps/types'
import { getTimeStringWithFormat } from '@/pages/Gps/NewGpsDetails/helpers/common'

type PopupProps = {
  run: GpsRun
  status: RunStatus
  onClick: () => void
}

export const GpsPopup: FC<PopupProps> = ({ run, status, onClick }) => {
  const deviationTimeMin = Math.abs(Math.round(run.deviationTime / 60))

  const runState = {
    [RunStatus.Inactive]: [`INACTIVE!`, 'headerGrey'],
    [RunStatus.OnTime]: [`on time`, 'headerBase'],
    [RunStatus.Late]: [deviationTimeMin + ` min behind`, 'headerRed'],
  }

  return (
    <Popup>
      <div>
        <div className={'popupMaps'} onClick={onClick}>
          <div className={`headerPopUp ${runState[status][1]}`}>
            <span className="medium">{runState[status][0]}</span>
          </div>
          <div className={'margin8'}>
            <span className="runNameGps" />
            <label className="medium fontWeight500">Start Time</label>
            <Row>
              <Col span={12}>
                <span>
                  <span className={'white'}>Scheduled</span>
                  <br />
                  <span className="black">
                    {getTimeStringWithFormat(run.startTimestamp, run.timezone)}
                  </span>
                </span>
              </Col>
              <Col span={12}>
                <span>
                  <span className={'white'}>Actual</span>
                  <br />
                  <span className="black">
                    {getTimeStringWithFormat(
                      run.actualStartTimestamp,
                      run.timezone
                    )}
                  </span>
                </span>
              </Col>
            </Row>
          </div>
          <div className={'margin8'}>
            <label className="medium fontWeight500">End Time</label>
            <Row>
              <Col span={12}>
                <span>
                  <span className={'white'}> Scheduled</span>
                  <br />
                  <span className="black">
                    {getTimeStringWithFormat(run.endTimestamp, run.timezone)}
                  </span>
                </span>
              </Col>
              <Col span={12}>
                <span>
                  <span className={'white'}> Actual</span>
                  <br />
                  <span className="black">
                    {getTimeStringWithFormat(
                      run.actualEndTimestamp,
                      run.timezone
                    )}
                  </span>
                </span>
              </Col>
            </Row>
          </div>

          <hr className={'line-popup'} />
          <div className={'margin8'}>
            <hr className="hr_gray" />
            <div className={'column'}>
              <div>
                <label>
                  <span className={'labelWhite'}>Run</span>{' '}
                  <span className="colorBlack black">{run.title}</span>
                </label>
              </div>

              {run.driverName && (
                <div data-testid="driverName">
                  <label>
                    <span className={'labelWhite'}>Driver</span>{' '}
                    <span className="colorBlack black">{run.driverName}</span>
                  </label>
                </div>
              )}
              {run.busName && (
                <div data-testid="busName">
                  <label>
                    <span className={'labelWhite'}>Bus</span>{' '}
                    <span className="colorBlack black">#{run.busName}</span>
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}
