import { getTimestampWithOffset } from '@/pages/Gps/NewGpsDetails/helpers/common'
import { GpsRunPoint } from '@/redux/gps/types'

const colors = ['#d32121', `#7ed321`]

export const getColorBarStyle = (
  stop: GpsRunPoint,
  nextStop: GpsRunPoint | undefined,
  minuteHeight: number,
  start: number,
  end: number,
  timeZone: string
) => {
  const factTime = getTimestampWithOffset(stop.factTime, timeZone)
  const scheduled = getTimestampWithOffset(stop.scheduledTime, timeZone)
  const scheduledNextPoint = nextStop
    ? getTimestampWithOffset(nextStop.scheduledTime, timeZone)
    : null
  const factTimeNextPoint = nextStop?.factTime
    ? getTimestampWithOffset(nextStop.factTime, timeZone)
    : factTime + scheduledNextPoint - scheduled

  const height = nextStop
    ? getBarHeightOfLegByTimes(
        factTime,
        end && factTimeNextPoint > end ? end : factTimeNextPoint,
        minuteHeight
      )
    : 0
  const marginBottom = factTime
    ? getBarHeightOfLegByTimes(start, factTime, minuteHeight)
    : 0
  const colorsStart = factTime - scheduled <= 0 ? 1 : 0
  const colorsEnd = factTimeNextPoint - scheduledNextPoint <= 0 ? 1 : 0
  const background = `linear-gradient(to top, ${colors[colorsStart]}, ${colors[colorsEnd]}  )`

  return { height, marginBottom, background }
}

const getBarHeightOfLegByTimes = (start: number, end: number, minuteHeight) => {
  if (!start || !end) return 0
  return Math.floor((end - start) / 60) * minuteHeight
}
