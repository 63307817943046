import { NewModal } from '@/entities/ui/NewModal/NewModal'
import { ModalFooter } from '@/entities/ui/NewModal/components/ModalFooter'
import { ModalsEnum, useModalSelector } from '@/store/modal/ModalStore'
import { useDeleteFTRequestMutation } from '@/store/fieldTrip/FieldTripApi'

export const DeleteFTRequestModal = () => {
  const [onDelete, deleteResponse] = useDeleteFTRequestMutation()

  const { isOpen, state } = useModalSelector(ModalsEnum.DELETE_FT_REQUEST_MODAL)
  return (
    <NewModal title="Delete Field Trip Request" open={isOpen}>
      <p>
        Are you sure you want to delete Field Trip Request New Test Request?
      </p>
      <ModalFooter
        success={{
          text: 'Delete',
          action: () => state?.id && onDelete(state?.id),
          isLoading: deleteResponse?.isLoading,
        }}
      />
    </NewModal>
  )
}
