import React, { useState, useContext, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import * as runActions from '../../../../redux/routesView/actions'
import _ from 'lodash'
import socketIOClient from 'socket.io-client'
import { socketIOClientIp } from '../../../../redux/configLocal'
import { browserHistory } from 'react-router'

const socket = socketIOClient(socketIOClientIp)

export let Context = React.createContext()

function BuildRunContext(props) {
  let {
    children,
    routesView,
    routes,
    schools,
    dispatch,
    activeSeason,
    setActiveRunCb,
    activeRunId,
    activeRunsStart,
    auth,
    buses,
    changedPosition,
  } = props

  const [openModal, setOpenModal] = useState('')
  const [activeRunsIds, setActiveRunsIds] = useState([])
  const [emptyRunFlag, setEmptyRunFlag] = useState(true)
  const [isOptimized, setIsOptimized] = useState(false)
  const [isDraggedPin, setIsDraggedPin] = useState(false)
  const [selectActiveIndex, setSelectActiveIndex] = useState(0)
  const activeSeasonRef = useRef()

  const [selectData0, setSelectData0] = useState({})
  const [selectData1, setSelectData1] = useState({})

  const [transferMode, setTransferMode] = useState(false)
  const [allRoutes, setAllRoutes] = useState([])
  const [activeRun, setActiveRun] = useState(null) // объект целого рана который используетя в данный момент

  const [newTripId, setNewTripId] = useState(null)
  const [sockerStarted, setSockerStarted] = useState(false)
  const [runDetailsDispatchId, setRunDetailsDispatch] = useState(null)

  const routesProps = useRef()

  // TODO обработать ID рана которорый невалидный

  useEffect(() => {
    // START LOAD
    // type 0   / empty
    // type 1   / empty / empty -> transfer
    // type 2   / ID  -> base
    // type 3   / ID / ID   -> transfer

    // Страница уже загружена
    // type 4 Выбрал ID
    // type 5  ID уже загружен / 2ой надо загрудить -> transfer
    // type 6 создаю новый ID ( жду загрузки рана )

    setActiveRunsIds(activeRunsStart)
    setEmptyRunFlag(activeRunsStart[0] === 0 && activeRunsStart[1] === 0)

    activeSeasonRef.current = activeSeason
  }, [])

  useEffect(() => {
    if (activeRunsIds && activeRunsIds.length && !activeRunId) {
      setActiveRunCb(activeRunsIds, activeRunsIds[0])
    }
  }, [activeRunsIds])

  useEffect(() => {
    if (
      !sockerStarted &&
      auth &&
      auth.socket_token &&
      activeRunsIds.length > 0 &&
      allRoutes.length > 0
    ) {
      socket.emit('room', auth.socket_token)
      socket.on('message', (msg) => {
        if (!msg.url) return false
        let decode_url = msg.url.replace(`api/v2/`, '').split('/')

        switch (decode_url[2]) {
          case 'transfer':
            let runId1 = msg.post.routes[0].routeId
            let runId2 = msg.post.routes[1].routeId
            dispatch(runActions.getRunDetails(runId1, auth))
            dispatch(runActions.getRunDetails(runId2, auth))
        }
      })
      setSockerStarted(true)
    }
  }, [sockerStarted, auth, activeRunsIds, allRoutes])

  useEffect(() => {
    if (activeRunId < 1) return
    let index = activeRunsIds.findIndex((x) => x === activeRunId)
    if (index > -1 && selectActiveIndex !== index) setSelectActiveIndex(index)
  }, [activeRunId, selectActiveIndex])

  useEffect(() => {
    if (!routes || !routes.length) return
    if (_.isEqual(routesProps.current, routes)) return

    let all = routes.map((x) => x.routes)
    let _allRoutes = _.sortBy(_.concat(...all), 'title')
    setAllRoutes(_allRoutes)

    routesProps.current = _.cloneDeep(routes)
  }, [routes])

  useEffect(() => {
    setIsDraggedPin(!!changedPosition)
  }, [changedPosition])

  const defSettings = auth

  useEffect(() => {
    if (runDetailsDispatchId) {
      dispatch(runActions.getRunDetails(runDetailsDispatchId, defSettings))
    }
  }, [runDetailsDispatchId])

  useEffect(() => {
    if (!allRoutes.length) return

    let newRun = (allRoutes || []).find((x) => x.newTripFlag)

    if (newRun && newTripId !== newRun.id) {
      setNewTripId(newRun.id)
      browserHistory.push(`/client/RouteTool/routes/${newRun.id}`)
      setEmptyRunFlag(false)
      setActiveRunsIds([newRun.id, 0])
      setSelectActiveIndex(0)
      setTransferMode(false)
    }

    activeRunsIds.forEach((runId, index, arr) => {
      if (!runId) return false
      allRoutes.forEach((route) => {
        if (runId === route.id) {
          if (
            !route.osrm &&
            route.points &&
            route.points.length > 1 &&
            (isDraggedPin || !isOptimized)
          ) {
            dispatch(runActions.getRunDetails(runId, defSettings))

            const routeBRunId = activeRunsIds.filter(
              (currentId) => currentId !== runId
            )[0]
            if (routeBRunId) {
              dispatch(runActions.getRunDetails(routeBRunId, defSettings))
            }
          } else if (
            !route.hasOwnProperty('points') &&
            allRoutes.length &&
            activeRunsIds.length &&
            activeRunId
          ) {
            setRunDetailsDispatch(runId)
          } else if (route.reload) {
            dispatch(runActions.getRunDetails(runId, defSettings))
          } else {
            if (!index) {
              setSelectData0(route)
            } else {
              setSelectData1(route)
            }
            if (activeRunId === route.id) {
              setActiveRun(route)
              setActiveRunCb(activeRunsIds, route.id)
            }
          }
        }
      })
    })
  }, [allRoutes, activeRunsIds, activeRunId, selectActiveIndex])

  useEffect(() => {
    if (!transferMode && activeRunsIds[0]) {
      browserHistory.push(`/client/RouteTool/routes/${activeRunsIds[0]}`)
      setActiveRun(selectData0)
      setSelectData1({})
      setActiveRunsIds([activeRunsIds[0], 0])
      setSelectActiveIndex(0)
    }
  }, [transferMode])

  useEffect(() => {
    let id = 0
    if (selectActiveIndex === 0 && selectData0.id) {
      setActiveRun(selectData0)
      id = selectData0.id
    }
    if (selectActiveIndex === 1 && selectData1.id) {
      id = selectData1.id
    }
    setActiveRunCb(activeRunsIds, id)
  }, [selectActiveIndex])

  useEffect(() => {
    if (activeSeasonRef.current !== activeSeason) {
      activeSeasonRef.current = activeSeason
      browserHistory.push(`/client/RouteTool/routes`)
      setSelectActiveIndex(0)
      setAllRoutes([])
      setActiveRun(null)
      setTransferMode(false)
      setEmptyRunFlag(true)
    }
  }, [activeSeason])
  return (
    <Context.Provider
      value={{
        routes,
        routesView,
        schools,
        dispatch,
        auth,
        openModal,
        setOpenModal,
        emptyRunFlag,
        activeRunsIds,
        setActiveRunsIds,
        transferMode,
        setTransferMode,
        setEmptyRunFlag,
        allRoutes,
        activeRun,
        setActiveRun,
        selectActiveIndex,
        setSelectActiveIndex,
        buses,
        setIsOptimized,
        setIsDraggedPin,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export let useContextBuilder = () => {
  return useContext(Context)
}

function mapStateToProps(state) {
  return {
    routesView: state.routesView,
    routes: state.routesView.routes,
    schools: state.schools,
    auth: state.auth,
    buses: state.buses,
    activeSeason: state.sessions.activeSeason,
    changedPosition: state.routesView.changedPosition,
  }
}

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildRunContext)
