import * as turf from '@turf/turf'
import { LatLngTuple } from 'leaflet'

export const getDistance = (
  lon1: number,
  lat1: number,
  lon2: number,
  lat2: number
) => {
  const from = turf.point([lon1, lat1])
  const to = turf.point([lon2, lat2])
  return Math.floor(turf.distance(from, to, { units: 'meters' }))
}

const ANGLE = 15
const SAME_STOP_DISTANCE = 3

export const getRotationPositionAndIndexOfMarker = (
  markers: MarkerPoint[],
  marker: MarkerPoint
) => {
  const sameStops = markers.filter(
    (m) =>
      getDistance(
        m.location[0],
        m.location[1],
        marker.location[0],
        marker.location[1]
      ) < SAME_STOP_DISTANCE
  )
  const stopPosition = sameStops.findIndex((i) => i.id === marker.id)
  const minPosition = Math.min(...sameStops.map((s) => s.object.position))
  const zIndex = (minPosition * 100 + stopPosition) * 10

  const rotation =
    sameStops.length < 12
      ? ANGLE * ((stopPosition - (sameStops.length - 1) / 2) % 6)
      : Math.floor(120 / sameStops.length) *
        (stopPosition - (sameStops.length - 1) / 2)

  const position = sameStops[0].location

  return { rotation, zIndex, position }
}

export type MarkerPoint = {
  location: LatLngTuple
  id: number | string
  object: { position: number }
}
