import React  from "react";
import "./index.less";
import { useMap } from '../../../../components/MapDefault/MapContext'

export default function (props)  {
  const { mapType, setMapType,zoomOut, zoomIn} = useMap();


  const switchMapType =()=>{
    let typeSwitch = mapType === "roadmap" ? "satellite" : "roadmap";
    setMapType(typeSwitch)
  }

  return (
    <div id={"mapControlButtom"}>
      <div className={"white"}>
        <div className="zoomIn" onClick={zoomIn} />
      </div>

      <div className={"white"}>
        <div className="zoomOut" onClick={zoomOut} />
      </div>

      <div className={"white"}>
        <div
          className={mapType === "roadmap" ? "earth" : "road"}
          onClick={switchMapType}
        />
      </div>
    </div>
  );
}

