import React, { useContext, useState } from 'react'
import shapeWork from './shapeWork'
import { useRulerStore } from '@/components/MapDefault/ruler/rulerStore'

export let Context = React.createContext()

const filtersAllDefault = {
  riderName: '',

  zip: null,
  transCat: null,
  zonesActive: null,
  grade: null,
  schoolId: null,
  addressType: null, // pick up 1  drop off 2 | null === All

  pinsShow: true,
  addressesPrimary: false, //address.is_primary
}

function FilterContext({ children, map }) {
  let [filtersActive, setFiltersActive] = useState(false)

  let [filtersAllObj, setFiltersAllObj] = useState(filtersAllDefault)

  let [modalOpen, setModalOpen] = useState(0)
  const isRule = useRulerStore((state) => state.isRule)
  let updateFilter = (filters) => {
    setFiltersAllObj((prevState) => {
      return { ...prevState, ...filters }
    })
  }

  let resetAllFilters = () => {
    setFiltersAllObj(filtersAllDefault)
  }

  let resetFilter = (key) => {
    setFiltersAllObj((prevState) => {
      return { ...prevState, [key]: filtersAllDefault[key] }
    })
  }

  return (
    <Context.Provider
      value={{
        filtersAllObj,
        updateFilter,
        filtersActive,
        setFiltersActive,
        resetAllFilters,
        resetFilter,
        modalOpen,
        setModalOpen,
        isRule,
      }}
    >
      {children}

      {shapeWork(filtersAllObj.zonesActive, filtersActive, map, modalOpen)}
    </Context.Provider>
  )
}

export let useContextFilter = () => {
  return useContext(Context)
}

export default FilterContext
