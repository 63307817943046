import * as types from './actionTypes'

export function loaderStart () {
  return function (dispatch) {
    dispatch({ type: types.PENDING_START })
  }
}
export function loaderFinish () {
  return function (dispatch) {
    dispatch({ type: types.PENDING_END })
  }
}
