import { RiderStoreId, RiderType, RunRider } from '@/redux/gps/types'
import { IconList } from '@/entities/ui/Icon/IconList.constatnts'

export const getRiderIconByAttendance = (
  riderId: RiderStoreId,
  riders: { [key: RiderStoreId]: RunRider }
) => {
  const rider = riders?.[riderId]
  const attendedStopsAmount = Object.values(riders).filter(
    (r) => Number(r.id) === Number(rider.id) && r.attend === 1
  ).length

  if (attendedStopsAmount === 0) {
    return rider.type === RiderType.PickUp ? IconList.pickUp : IconList.dropOff
  }
  if (attendedStopsAmount === 1) {
    return rider.type === RiderType.PickUp
      ? IconList.acceptPickUp
      : IconList.acceptDropOff
  }
  return rider.type === RiderType.PickUp
    ? IconList.finishedPickUp
    : IconList.finishedDropOff
}
