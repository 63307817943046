export const GET_REQUESTS = "fieldTrip.GET_REQUESTS";
export const GET_STATUS_LOCK = "fieldTrip.GET_STATUS_LOCK";
export const GET_REQUESTS_BUILDER = "fieldTrip.GET_REQUESTS_BUILDER";
export const GET_REQUEST = "fieldTrip.GET_REQUEST";
export const DELETE_REQUESTS = "fieldTrip.DELETE_REQUESTS";
export const DELETE_FIELD_TRIPS = "fieldTrip.DELETE_FIELD_TRIPS";
export const COMBINE_TRIPS = "fieldTrip.COMBINE_TRIPS";
export const BUILD_TRIP = "fieldTrip.BUILD_TRIP";
export const EDIT_TRIP = "fieldTrip.EDIT_TRIP";
export const EDIT_FIELDTRIP_POINTS = "fieldTrip.EDIT_FIELDTRIP_POINTS";
export const EDIT_TRIP_NAME = "fieldTrip.EDIT_TRIP_NAME";
export const DELETE_TRIP = "fieldTrip.DELETE_TRIP";
export const EDIT_TRIP_ACTIVE = "fieldTrip.EDIT_TRIP_ACTIVE";
export const SUBMIT_TRIP = "fieldTrip.SUBMIT_TRIP";
export const CREATE_POINT = "fieldTrip.CREATE_POINT";
export const EDIT_POINT = "fieldTrip.EDIT_POINT";
export const DELETE_POINT = "fieldTrip.DELETE_POINT";
export const REVERSE_POINTS = "fieldTrip.REVERSE_POINTS";
export const ADD_RIDERS = "fieldTrip.ADD_RIDERS";
export const REMOVE_RIDERS = "fieldTrip.REMOVE_RIDERS";
export const GET_APPROVALS = "fieldTrip.GET_APPROVALS";
export const OSRM = "fieldTrip.OSRM";
export const GET_TRIP = "fieldTrip.GET_TRIP";
export const CHECK_ALL = "fieldTrip.CHECK_ALL";
export const UNCHECK_ALL = "fieldTrip.UNCHECK_ALL";
