import { ROOT_DIR } from '@/shared/constants'
import { Link } from 'react-router'
import styles from './Header.module.less'
import classnames from 'classnames'
import { LogoIcon } from '@/assets/icons/logo'
import { UserPopover } from '@/features/containers/components/Header/components/UserPopover/UserPopover'
import { HelpPopover } from '@/features/containers/components/Header/components/HelpPopover/HelpPopover'
import { HeaderProgressBar } from '@/features/containers/components/Header/components/HeaderProgressBar/HeaderProgressBar'
import { useMenuLinks } from '@/features/containers/components/Header/useHeader'
import { useRouter } from '@/shared/hooks/routeHooks/useRouter'
import { memo } from 'react'

export const Header = memo(({ className }: { className?: string }) => {
  const options = useMenuLinks()
  const router = useRouter()

  const onClickLogo = () => router.push(ROOT_DIR + '/dashboard')
  return (
    <header className={classnames(styles.root, className)}>
      <div className={styles.logoWrapper} onClick={onClickLogo}>
        <LogoIcon className={styles.logo} />
      </div>
      <ul className={styles.menuList}>
        {options.map(({ text, to, ...rest }) => (
          <li key={to}>
            <Link
              activeClassName={styles.activeLink}
              onlyActiveOnIndex={false}
              to={to}
            >
              {!!rest.count && (
                <div className={styles.countBadge}>{rest.count}</div>
              )}
              {text}
            </Link>
          </li>
        ))}
      </ul>
      <UserPopover />
      <HelpPopover />

      <HeaderProgressBar />
    </header>
  )
})
