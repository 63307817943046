import { create } from 'zustand'

interface IStore {
  isLoading: boolean
  setStore: (params: Partial<IStore>) => void
}

export const useEditRiderModalStore = create<IStore>((set) => ({
  isLoading: false,
  setStore: ({ isLoading }) => {
    set({ isLoading: isLoading })
  },
}))
