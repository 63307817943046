// @ts-check

export class BaseEntity {
  /** @type {number} */
  id

  /** @type {boolean} */
  isDeleted
}

/**
 * @enum {number}
 */
export const PeriodType = {
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
}

/**
 * @enum {number}
 */
export const AddressType = {
  PickUp: 1,
  DropOff: 2,
}

export class Address extends BaseEntity {
  /** @type {number} */
  company_id

  /** @type {string} */
  address

  /** @type {string} */
  lat

  /** @type {string} */
  lon
}

export class RecurrenceValue extends BaseEntity {
  /** @type {number} */
  recurrence_id

  /** @type {string} */
  value

  /** @type {string} */
  start_time

  /** @type {string} */
  end_time
}

export class Recurrence extends BaseEntity {
  /** @type {number} */
  calendar_id

  /** @type {number} */
  period_skip

  /** @type {PeriodType} */
  period_type

  /** @type {RecurrenceValue[]} */
  recurrenceValues
}

export class Calendar extends BaseEntity {
  /** @type {string} */
  start_date

  /** @type {string} */
  end_date

  /** @type {string} */
  start_time

  /** @type {string} */
  end_time

  /** @type {boolean} */
  is_full_day

  /** @type {boolean} */
  is_recurring

  /** @type {Recurrence} */
  recurrence
}

export class RiderAddress extends BaseEntity {
  /** @type {number} */
  address_id

  /** @type {number} */
  address_link_id

  /** @type {number} */
  calendar_id

  /** @type {number} */
  child_id

  /** @type {Address} */
  address

  /** @type {Calendar} */
  calendar

  /** @type {boolean} */
  is_primary

  /** @type {boolean} */
  is_school

  /** @type {boolean} */
  is_use

  /** @type {object} */
  route

  /** @type {AddressType} */
  type

  /**
   * @type {number}
   * @description milliseconds
   */
  waittime
}

/**
 * @enum {number} RiderStatus
 */
export const RiderStatus = {
  Inactive: 0,
  Riding: 1,
}

export class Rider extends BaseEntity {
  /** @type {number} */
  cid

  /** @type {number} */
  company_id

  /** @type {any} */
  classs

  /** @type {number} */
  driving_distance

  /** @type {string} */
  additional_rider1

  /** @type {string} */
  additional_rider2

  /** @type {RiderAddress[]} */
  addresses

  /** @type {string} */
  alert_note

  /** @type {string} */
  birth_date

  /** @type {string} */
  pick_up_addres

  /** @type {string} */
  pick_up_city

  /** @type {string} */
  pick_up_house_number

  /** @type {string} */
  pick_up_pricing_tier

  /** @type {string} */
  pick_up_rate

  /** @type {string} */
  pick_up_zip

  /** @type {string} */
  drop_off_address

  /** @type {string} */
  drop_off_city

  /** @type {string} */
  drop_off_house_number

  /** @type {string} */
  drop_off_pricing_tier

  /** @type {string} */
  drop_off_rate

  /** @type {string} */
  drop_off_zip

  /** @type {string} */
  dropoff_lat

  /** @type {string} */
  dropoff_lon

  /** @type {string} */
  email

  /** @type {string} */
  emergency_contact

  /** @type {string} */
  emergency_contact_phone

  /** @type {string} */
  emergency_contact_work_phone

  /** @type {string} */
  first_guardian

  /** @type {string} */
  first_guardian_phone

  /** @type {string} */
  first_guardian_work_phone

  /** @type {string} */
  second_guardian

  /** @type {string} */
  second_guardian_phone

  /** @type {string} */
  second_guardian_work_phone

  /** @type {string} */
  sunday

  /** @type {string} */
  monday

  /** @type {string} */
  tuesday

  /** @type {string} */
  wednesday

  /** @type {string} */
  thursday

  /** @type {string} */
  friday

  /** @type {string} */
  saturday

  /** @type {string} */
  full_name

  /** @type {string} */
  gender

  /** @type {string} */
  is_attendant

  /** @type {string} */
  is_nurse

  /** @type {string} */
  lat

  /** @type {string} */
  lon

  /** @type {string} */
  nameS3

  /** @type {string} */
  parent_first_name

  /** @type {string} */
  parent_last_name

  /** @type {string} */
  pathS3

  /** @type {string} */
  phone

  /** @type {string} */
  program_type

  /** @type {any} */
  rider_type

  /** @type {any} */
  route

  /** @type {number} */
  route_id

  /** @type {any} */
  route_order

  /** @type {string} */
  school_name

  /** @type {number} */
  season_id

  /** @type {string} */
  service_start_date

  /** @type {string} */
  service_end_date

  /** @type {string} */
  session_times

  /** @type {string} */
  site_name

  /** @type {string} */
  special_instructions

  /** @type {any} */
  split

  /** @type {RiderStatus} */
  status

  /** @type {any} */
  stop_time

  /** @type {string} */
  transportation_category

  /** @type {any} */
  vendor_start_date

  /** @type {string} */
  work_phone

  /** @type {ChildNote[]} */
  notes
}

export class School extends BaseEntity {
  /** @type {number} */
  company_id

  /** @type {number} */
  calendar_id

  /** @type {number} */
  district_id

  /** @type {number} */
  address_id

  /** @type {number} */
  address_link_id

  /** @type {string} */
  name

  /** @type {string} */
  phone

  /** @type {string} */
  districtName

  /** @type {string} */
  code

  /** @type {string} */
  link_code

  /** @type {string} */
  linkToken

  /** @type {number} */
  stop_time

  /** @type {Address} */
  address
}

export class Season extends BaseEntity {
  /** @type {number} */
  company_id

  /** @type {number} */
  date

  /** @type {number} */
  childrenCount

  /** @type {File[]} */
  files

  /** @type {File} */
  latestFile

  /** @type {string} */
  school_year

  /** @type {string} */
  title

  /** @type {number} */
  tripsCount

  /** @type {number} */
  status

  /** @type {any} */
  modified
}

export class File extends BaseEntity {
  /** @type {number} */
  company_id

  /** @type {number} */
  season_id

  /** @type {number} */
  date
}

export class ChildNote extends BaseEntity {
  /** @type {number} */
  author_id

  /** @type {number} */
  child_id

  /** @type {number} */
  company_id

  /** @type {string} */
  text

  /** @type {boolean} */
  include_in_driver_sheet

  /** @type {number} Timestamp */
  created_at

  /** @type {number} Timestamp */
  updated_at
}

/**
 *
 * @enum {number} EditParam
 */
export const EditParam = {
  EditInfo: 0,
  AddAddressPrimary: 1,
  EditAddressPrimary: 2,
  AddAddressAdditional: 3,
  EditAddressAdditional: 4,
  AddNote: 5,
  EditNote: 6,
  serviceDetail: 7,
  camperBlock: 8,
}

export class EditParams {
  /** @type {boolean} */
  addAddressPrimary = false

  /** @type {boolean} */
  editAddressPrimary = false

  /** @type {boolean} */
  addAddressAdditional = false

  /** @type {boolean} */
  editAddressAdditional = false

  /** @type {boolean} */
  addNote = false

  /** @type {boolean} */
  editNote = false

  serviceDetail = false

  camperBlock = false

  /**
   *
   * @param {EditParam} param
   */
  constructor(param) {
    switch (param) {
      case EditParam.EditInfo:
        this.EditInfo = true
        break
      case EditParam.AddAddressPrimary:
        this.addAddressPrimary = true
        break
      case EditParam.EditAddressPrimary:
        this.editAddressPrimary = true
        break
      case EditParam.AddAddressAdditional:
        this.addAddressAdditional = true
        break
      case EditParam.EditAddressAdditional:
        this.editAddressAdditional = true
        break
      case EditParam.AddNote:
        this.addNote = true
        break
      case EditParam.EditNote:
        this.editNote = true
        break
      case EditParam.serviceDetail:
        this.serviceDetail = true
        break
      case EditParam.camperBlock:
        this.camperBlock = true
        break
      default:
        break
    }
  }
}
