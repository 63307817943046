import { NewSwitch } from '@/entities/ui/NewSwitch/NewSwitch'
import React from 'react'
import { create } from 'zustand'
import { useAppSelector } from '@/redux'
import {
  sessionsSelector,
  useEditNewYearSessionMutation,
} from '@/store/session/sessionApi'
import { userInfoSelectorPermissions } from '@/store/common/common.selectors'

interface IStore {
  isLoading: null | number
  setIsLoading: (isLoading: null | number) => void
}
export const useNextYearStore = create<IStore>((set) => ({
  isLoading: null,
  setIsLoading: (isLoading) => set({ isLoading }),
}))

export const NewYearSessionCell = ({ id }: { id: number }) => {
  const permissions = useAppSelector(userInfoSelectorPermissions)

  const [request] = useEditNewYearSessionMutation()
  const handleSetNewYearSession = (id, value) => {
    request({ id, value })
  }
  const isLoading = useNextYearStore((state) => state.isLoading)
  const season = useAppSelector(sessionsSelector)
  const hasNextYearFeature = useAppSelector(
    (state) => state?.auth?.companyOptions?.has_next_year_feature
  )

  const sessionsById = season.reduce(
    (acc, session) => ({ ...acc, [session.id]: session }),
    {}
  )

  return hasNextYearFeature ? (
    <div style={{ width: 120, marginRight: 40 }}>
      <p style={{ margin: 0 }}>New Year Session</p>
      <NewSwitch
        controlled
        disabled={
          sessionsById?.[id]?.deleting === 1 ||
          sessionsById?.[id]?.status === 0 ||
          !permissions?.write_sessions
        }
        loading={!!isLoading && id === isLoading}
        onChange={(value) => handleSetNewYearSession(id, value)}
        checked={!!sessionsById?.[id]?.is_next_year}
      />
    </div>
  ) : null
}
