import { useGpsDetailsStore } from '@/pages/Gps/NewGpsDetails/store'
import { LeftIcon } from '@/img/icons'
import React, { FC, useState } from 'react'
import styles from './DetailsFilter.module.less'
import { browserHistory } from 'react-router'
import { RidersSelect } from '@/pages/Gps/NewGpsDetails/components/RidersSelect'
import { RunsSelect } from '@/pages/Gps/NewGpsDetails/components/RunsSelect'
import { Layer } from 'leaflet'
import moment from 'moment/moment'
import { NewButton } from '@/entities/ui/NewButton/NewButton'

type FilterProps = {
  timestamp: number
}

export const Filter: FC<FilterProps> = ({ timestamp }) => {
  const { gpsInfo: run, mapRef } = useGpsDetailsStore()

  const [selected, setSelected] = useState(0)

  const onChangeName = (e) => {
    useGpsDetailsStore.getState().setGpsStore({ selectRider: e })

    mapRef.current.contextValue.map.eachLayer(
      (layer: Layer & { options: { alt?: string } }) => {
        const alt: string[] = JSON.parse(layer.options?.alt || '[]')

        layer.closePopup()
        if (alt.includes(e)) {
          if (!layer.getPopup()?.isOpen()) {
            layer.openPopup()
            layer
              .getPopup()
              ?.getElement()
              .addEventListener('mouseleave', () => {
                layer.closePopup()
              })
          } else {
            layer.closePopup()
          }
        }
      }
    )

    setSelected(e)
  }

  const handleBack = () => {
    browserHistory.push(`/client/gps/d/${timestamp}`)
  }

  return (
    <>
      <div className={styles.filterBlock}>
        <div className={styles.selectsBlock}>
          <span className={styles.time}>
            {moment(timestamp * 1000).format('MM-DD-YYYY')}
          </span>
          <RunsSelect />
          <RidersSelect selected={selected} onChange={onChangeName} />
        </div>

        <div className={styles.infoBlock}>
          <span>
            <label className={styles.block}>Driver Name</label>
            <br />
            <span className={styles.info}>{run.driverName}</span>
          </span>
          <span>
            <label className={styles.block}>Bus Number</label>
            <br />
            <span className={styles.info}>{run.busNumber}</span>
          </span>
          <span>
            <label className={styles.block}>Run name</label>
            <br />
            <span className={styles.info}>{run?.runName || ' - '}</span>
          </span>
        </div>
      </div>

      <NewButton
        data-testid={'backToAllRoutesButton'}
        onClick={handleBack}
        className={styles.buttonBack}
      >
        <LeftIcon /> <span>Back to All Routes</span>
      </NewButton>
    </>
  )
}
