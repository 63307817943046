import { create } from 'zustand'
import { IRegistrationItem } from '@/store/routeTool/routeTool.types'
import {
  editCurrentInReviewItem,
  editCurrentNewItem,
  decrementRegistrationCount,
  afterCreateNewRegistration,
} from '@/pages/FieldTrip/Registrations/tabs/Opened/helpers'

export interface IRegistrationsOpenedStore {
  filter: {
    tab: string
    page: number
    pageSize: number
    offset: number
    cid: string
    status: number | null
  }
  items: IRegistrationItem[]
  setItems: (items: IRegistrationItem[], more?: boolean) => void
  setFilter: (filter: Partial<IRegistrationsOpenedStore['filter']>) => void
  resetFilter: () => void
  replaceStatus: (
    id: number,
    status: number,
    userBy?: string,
    updatedAt?: number
  ) => void
}

export const initialRegOpenedFilter = {
  tab: 'opened',
  page: 1,
  pageSize: 50,
  cid: '',
  status: null,
  offset: 0,
}
export const useRegistrationsOpenedStore = create<IRegistrationsOpenedStore>(
  (set, get) => ({
    filter: initialRegOpenedFilter,
    items: [],
    replaceStatus: (id, status, userBy, updatedAt) => {
      //если был созда новый registration item
      if (status === 1) {
        afterCreateNewRegistration({ set, get })
      } else if (get().items.length === 0) {
        // фоновый эффект , в случае когда пользователь не находится
        // и не находился на стринице и не получал список items
        // просто изменяем каунт для меню если статус поменялся на 4 ( in review)
        decrementRegistrationCount()
      } else {
        const currentStatus = get().items.find((item) => item.id === id)?.status
        switch (currentStatus) {
          case 1:
            return editCurrentNewItem({
              status,
              set,
              get,
              id,
              userBy,
              updatedAt,
            })
          case 2:
            return
          case 3:
            return
          case 4:
            return editCurrentInReviewItem({
              status,
              set,
              get,
              id,
            })
          default:
            return
        }
      }
    },
    setItems: (items, more) =>
      more ? set({ items: [...get().items, ...items] }) : set({ items }),
    setFilter: (filter) => set({ filter: { ...get().filter, ...filter } }),
    resetFilter: () => set({ filter: initialRegOpenedFilter }),
  })
)
