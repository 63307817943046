import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getGpsRecordsV2 } from '@/redux/gps/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Map } from 'react-leaflet'
import './index.less'
import { Legend } from './components/Legend'
import { GpsPins } from './components/GpsPins'
import moment from 'moment'
import { Controls } from '@/pages/Gps/NewGps/components/Controls'
import { getGpsRuns } from '@/redux/gps/selectors'
import { GpsFilter } from '@/pages/Gps/NewGps/components/GpsFilter'
import { checkIfToday, filterRuns } from '@/pages/Gps/NewGps/helpers'
import { RunId } from '@/redux/gps/types'
import { AppDispatch } from '@/redux'
import { LatLngTuple } from 'leaflet'

export type Filter = {
  runId: RunId | null
  driver: string
  timeWindow: number
}

const defaultEdges = [
  [24.664018, -123.461071],
  [46.088543, -56.280215],
] as LatLngTuple[]

export const GpsPage = (props) => {
  const dispatch = useDispatch<AppDispatch>()

  const gpsRuns = useSelector(getGpsRuns)

  const runsIdsString = gpsRuns
    .map((r) => r.id)
    .sort((a, b) => a - b)
    .join(',')

  const [filter, setFilter] = useState<Filter>({
    runId: null,
    driver: '',
    timeWindow: 5,
  })
  const [bounds, setBounds] = useState<LatLngTuple[]>(defaultEdges)
  const [isBrowserTabHidden, setIsBrowserTabHidden] = useState(false)

  const mapRef = useRef<Map | null>(null)
  const interval = useRef<NodeJS.Timeout | null>(null)

  const timeJump = Number(
    props.params?.time ?? Math.floor(Date.now() / (60 * 1000)) * 60
  )

  const isToday = checkIfToday(timeJump)

  const loadGpsRuns = useCallback(() => {
    clearInterval(interval.current)
    if (isToday) {
      dispatch(getGpsRecordsV2(moment().utc(false).unix()))
      interval.current = setInterval(() => {
        dispatch(getGpsRecordsV2(moment().utc(false).unix()))
      }, 5000)
    } else {
      dispatch(getGpsRecordsV2(timeJump))
    }
  }, [dispatch, timeJump, isToday])

  useEffect(() => {
    if (isBrowserTabHidden) {
      clearInterval(interval.current)
    } else {
      loadGpsRuns()
    }
    return () => {
      clearInterval(interval.current)
    }
  }, [isBrowserTabHidden, loadGpsRuns])

  useEffect(() => {
    const runs = filterRuns(gpsRuns, filter, isToday)
    if (runs.length) {
      const filteredBounds = new Set(
        runs.map((x) => JSON.stringify([+x.gps.latitude, +x.gps.longitude]))
      )
      setBounds(Array.from(filteredBounds).map((o) => JSON.parse(o)))
    } else {
      setBounds(defaultEdges)
    }
  }, [runsIdsString, filter, isToday])

  document.addEventListener('visibilitychange', function () {
    setIsBrowserTabHidden(document.hidden)
  })

  return (
    <div id="mainGps">
      <GpsFilter filter={filter} setFilter={setFilter} timeJump={timeJump} />

      <Map
        ref={(map) => (mapRef.current = map)}
        style={{ zIndex: 1 }}
        zoomControl={false}
        doubleClickZoom={false}
        id="gpsMap"
        wheelPxPerZoomLevel={3000}
      >
        <GpsPins
          gpsRuns={filterRuns(gpsRuns, filter, isToday)}
          isToday={isToday}
        />

        <Controls mapRef={mapRef} bounds={bounds} />

        <Legend />
      </Map>
    </div>
  )
}
