import arriveLeft from '@/assets/img/arrive-left.png'
import arriveRight from '@/assets/img/arrive-right.png'
import arriveStraight from '@/assets/img/arrive-straight.png'
import forkLeft from '@/assets/img/fork-left.png'
import forkRight from '@/assets/img/fork-right.png'
import keepLeft from '@/assets/img/keep-left.png'
import keepRight from '@/assets/img/keep-right.png'
import merge from '@/assets/img/merge.png'
import rampLeft from '@/assets/img/ramp-left.png'
import rampRight from '@/assets/img/ramp-right.png'
import roundaboutLeft from '@/assets/img/roundabout-left.png'
import roundaboutReght from '@/assets/img/roundabout-right.png'
import straight from '@/assets/img/straight.png'
import turnLeft from '@/assets/img/turn-left.png'
import turnRight from '@/assets/img/turn-right.png'
import turnSharpLeft from '@/assets/img/turn-sharp-left.png'
import turnSharpRight from '@/assets/img/turn-sharp-right.png'
import turnSlightLeft from '@/assets/img/turn-slight-left.png'
import turnSlightRight from '@/assets/img/turn-slight-right.png'
import uturnLeft from '@/assets/img/uturn-left.png'
import uturnRight from '@/assets/img/uturn-right.png'

export const navImages = {
  'arrive-left': arriveLeft,
  'arrive-right': arriveRight,
  'arrive-straight': arriveStraight,
  'fork-left': forkLeft,
  'fork-right': forkRight,
  'keep-left': keepLeft,
  'keep-right': keepRight,
  merge,
  'ramp-left': rampLeft,
  'ramp-right': rampRight,
  'roundabout-left': roundaboutLeft,
  'roundabout-right': roundaboutReght,
  straight,
  'turn-left': turnLeft,
  'turn-right': turnRight,
  'turn-sharp-left': turnSharpLeft,
  'turn-sharp-right': turnSharpRight,
  'turn-slight-left': turnSlightLeft,
  'turn-slight-right': turnSlightRight,
  'uturn-left': uturnLeft,
  'uturn-right': uturnRight,
}
