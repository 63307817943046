import moment from 'moment/moment'
import { getRunTotalHours } from '@/pages/Gps/NewGpsDetails/helpers/common'

class Hour {
  ts: number
  h: string
  ampm: string
  start: { text: string; min: number }
  end: { text: string; min: number }
  empty: boolean
  colors: { type: number; px: number }[]

  constructor(ts: number, empty?: boolean) {
    this.ts = ts
    this.h = moment(ts).utc().format('hh')
    this.ampm = moment(ts).utc().format('A')
    this.start = null
    this.end = null
    this.empty = empty
    this.colors = [
      { type: 0, px: 0 },
      { type: 0, px: 0 },
    ]
  }

  setColor(data) {
    this.colors = data
  }
}

export function genHours(start: number, end: number) {
  if (start > end) return []

  const hourCount = getRunTotalHours(start, end)

  const startH = moment(start * 1000)
    .startOf('hour')
    .unix()

  let hours = [
    new Hour(startH * 1000 - 3600000, true),
    new Hour(startH * 1000, true),
  ]

  const startMin = +moment(start * 1000).format('m')
  const stopMin = +moment(end * 1000).format('m')

  hours[1].start = {
    text: moment(start * 1000)
      .utc()
      .format('hh:mm A'),
    min: startMin * 2.5,
  }

  for (let h = 1; h < hourCount; h++) {
    hours.push(new Hour(startH * 1000 + h * 3600000))
  }

  hours.at(-1).end = {
    text: moment(end * 1000)
      .utc()
      .format('hh:mm A'),
    min: stopMin * 2.5,
  }

  hours.push(new Hour(startH * 1000 + hourCount * 3600000, true))

  hours = baseColor(hours, start, end)
  hours = completeColor(hours)

  return hours
}

function baseColor(hours: Hour[], startTime: number, endTime: number) {
  hours.forEach((x, i, a) => {
    const last = i === a.length - 1

    //первый элемент
    if (!last && x.ts / 1000 < startTime && startTime < a[i + 1].ts / 1000) {
      const startMin = +moment(startTime * 1000)
        .utc()
        .format('m')
      const data = [
        { type: 0, px: 1.6 * startMin },
        { type: 1, px: 1.6 * startMin },
      ]
      x.setColor(data)
    }

    //последний
    else if (
      endTime &&
      !last &&
      x.ts / 1000 < endTime &&
      endTime < a[i + 1].ts / 1000
    ) {
      const startMin = +moment(endTime * 1000)
        .utc()
        .format('m')
      const data = [
        { type: 1, px: 1.6 * startMin },
        { type: 0, px: 1.6 * startMin },
      ]
      x.setColor(data)
    }

    //средний
    else if (!x.empty) {
      const data = [
        { type: 1, px: 0 },
        { type: 1, px: 0 },
      ]
      x.setColor(data)
    }
  })

  return hours
}

function completeColor(hours: Hour[]) {
  const now = moment().utc(false).unix() * 1000
  const nowMin = +moment().utc(false).format('mm') * 1.6

  hours.forEach((x) => {
    if (!x.empty) {
      if (x.ts < now && x.ts + 3600000 < now) {
        const c = [...x.colors]
        c[0].type = 2
        c[1].type = 2
      } else if (x.ts < now && x.ts + 3600000 > now) {
        const c = [...x.colors]
        c[0].type = 2
        c[0].px = nowMin
        c[1].px = nowMin
      }
    }
  })

  return hours
}
