import React, { Component } from 'react'
import * as runActions from '../../../../../../redux/routesView/actions'
import { connect } from 'react-redux'

import { Col, Row } from 'antd'
import { Trans } from 'react-i18next'
import './index.less'
import anchorAndTimeFN from '../../../../../Run/components/BuildRun/components/anchorAndTime'

class BusDepot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
    }
  }

  render() {
    let { id, object, address } = this.state

    let position = this.state.routePointRoute.view_position

    return (
      <div className="BusDeport" key={id}>
        <span className="backgroundLeft" />

        {object && (
          <Row className="MarginBlock noMarginTop">
            <Col span={24} className="fullName">
              {position}. {object && object.name}
            </Col>
          </Row>
        )}

        <Row
          className="Info"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Row className="MarginBlock">
            <Col span={24} className="text">
              {anchorAndTimeFN.bind(this)()}
            </Col>
          </Row>
          <Row className="MarginBlock">
            {address && (
              <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                <span className="Address">
                  <Trans>Address</Trans>
                </span>
                {address.address}
              </Col>
            )}
          </Row>
        </Row>
      </div>
    )
  }
}

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return { runActions, dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(BusDepot)
