import React, { Component } from 'react'
import { connect } from 'react-redux'

import MapDefault from '../../../../components/MapDefault'
import MapButtons from './components/MapButtons'
import SchoolsPins from './components/SchoolsPins'
import DepotsPins from './components/DepotsPins'
import RoutesActive from './components/RoutesActive'
import ChildrenPins from './components/ChildrenPins'
import LegendView from '../../../../components/MapDefault/legend/LegendView.js'
import * as settingsActions from '../../../../redux/settings/actions'
import { MapProvider } from '../../../../components/MapDefault/MapContext'
import MapPopUps from './components/MapPopUps'
import { Context } from '../Filter/FilterContext'

class Map extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeRuns: props.activeRuns,
      activeRun: props.activeRun,
      routes: [],
      bounds: [],
      startOval: false,
      targetNewRun: 0,
      targetBtn: 0,
    }
    this.map
  }

  componentDidMount() {
    this.props.dispatch(settingsActions.getTransportationCategories())
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.filters !== nextProps.filters) {
      this.setState({
        filters: nextProps.filters,
      })
    }

    if (
      nextProps.routes !== this.state.routes ||
      nextProps.activeRuns !== this.state.activeRuns
    ) {
      let activeRun = this.activeRunFind(
        nextProps.activeRuns[0],
        nextProps.routes
      )

      if (activeRun) {
        let bounds = this.getBounds(activeRun.points)

        this.setState({
          bounds: bounds,
          activeRuns: nextProps.activeRuns,
          time_zone: activeRun.time_zone,
        })
      }

      this.setState({
        routes: nextProps.routes,
      })
    }

    if (nextProps.activeRun !== this.state.activeRun) {
      if (
        typeof nextProps.activeRun === 'number' &&
        typeof this.state.activeRun === 'number'
      ) {
        this.setState({ targetNewRun: this.state.targetNewRun + 1 })
      }
    }

    this.setState({
      activeRun: nextProps.activeRun,
      depots: nextProps.depots,
    })
  }

  activeRunFind = (runId, routesList) => {
    let result = null
    if (!routesList) return null
    routesList.forEach((gr) => {
      gr.routes.forEach((route) => {
        if (runId === route.id) {
          if (route.points && route.points.length) {
            result = route
          }
        }
      })
    })
    return result
  }

  getBounds = (points) => {
    return points
      .filter((point) => !!point?.address?.lat && !!point?.address?.lon)
      .map((point) => [point.address.lat, point.address.lon])
  }

  handleTarget = () => {
    this.setState({ targetBtn: this.state.targetBtn + 1 })
  }

  startRuleActive = () => {
    this.setState({
      startRule: true,
    })
  }

  callBackRedirection = (position) => {
    this.setState(
      {
        redirection: position,
      },
      () => {
        this.setState({
          redirection: false,
        })
      }
    )
  }

  render() {
    let {
      activeRuns,
      activeRun,
      bounds,
      targetNewRun,
      redirection,
      targetBtn,
      time_zone,
    } = this.state

    const isWriteRoutes = this.props?.userPermissions?.write_routes !== 1
    return (
      <MapProvider>
        <MapDefault
          scope={this}
          startRuleActive={this.startRuleActive}
          bounds={bounds}
          targetNewRun={targetNewRun}
          targetBtn={targetBtn}
          getMapRef={this.props.getMapRef}
          saveNewShape={this.props.saveNewShape}
        >
          <div className="childrenProps">
            <div className="mapElements elementPosition">
              <MapButtons
                target={this.handleTarget}
                activeRun={activeRun}
                isWriteRoutes={isWriteRoutes}
              />
            </div>

            <Context.Consumer>
              {(ct) => {
                return (
                  <ChildrenPins
                    addRiderInRun={this.props.addRiderInRun}
                    makeStopInRun={this.props.makeStopInRun}
                    hoveredAddress={null}
                    filters={ct.filtersAllObj}
                    makeAnyStops={this.props.makeAnyStops}
                    zonesList={this.props.zonesList}
                    activeRuns={activeRuns}
                    activeRun={activeRun}
                    transCat={this.props.transCat}
                    map={this.map}
                    ctx={ct}
                  />
                )
              }}
            </Context.Consumer>

            <DepotsPins activeRuns={activeRuns} />

            <SchoolsPins />

            <RoutesActive
              deleteFromList={this.props.deleteFromList}
              removeStopFromRun={this.props.removeStopFromRun}
              removeChildFromStop={this.props.removeChildFromStop}
              removeChildFromTrans={this.props.removeChildFromTrans}
              callBackRedirection={this.callBackRedirection}
              activeRuns={activeRuns}
              activeRun={activeRun}
              randKey={Math.random()}
              moveRedirection={this.props.moveRedirection}
              setActiveRun={this.props.setActiveRun}
              time_zone={time_zone}
            />

            {!isWriteRoutes && (
              <MapPopUps redirection={redirection} activeRuns={activeRuns} />
            )}

            <LegendView runPage={this.props.paddingLegend} />
          </div>
        </MapDefault>
      </MapProvider>
    )
  }
}

function mapStateToProps(state) {
  return {
    routes: state.routesView.routes,
    transCat: state.settings.data,
    childrenList: state.children.childrenList,
    depots: state.auth.depots,
    userPermissions: state.auth.permissions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Map)
