import { ROOT_DIR } from '@/shared/constants'

const _routeLinks = {
  signin: '/signin',
  dashboard: '/dashboard',
  routeTool: '/RouteTool',
  fieldTrips: '/FieldTrips',
  gps: '/gps',
  resources: '/Resources',
  zones: '/Zones',
  lc_profile: '/lc/profile',
  lc_settings: '/lc/settings',
  sessions: '/RouteTool/sessions',
  riders: '/RouteTool/riders',
  routeView: '/RouteTool/routes-view',
  runBuilder: '/RouteTool/routes',
  attendance: '/RouteTool/attendance',
  requests: '/RouteTool/requests',
  registrations: '/RouteTool/parentRegistrations',

  ftRequests: '/FieldTrips/requests',
  ftRoutesView: '/FieldTrips/routes-view',
  ftAttendance: '/FieldTrips/attendance',
  ftRun: '/FieldTrips/run',

  resBusDepots: '/Resources/depots',
  resBuses: '/Resources/buses',
  resDrivers: '/Resources/drivers',
  resSs: '/Resources/ss',
  resConsole: '/Resources/console',

  lcProfile: '/lc/profile',
  lcOverview: '/lc/overview',
  lcSettings: '/lc/settings',
  lcBilling: '/lc/billing/plans',
}

export const routeLinks = Object.keys(_routeLinks).reduce(
  (acc, key) => ({ ...acc, [key]: ROOT_DIR + _routeLinks[key] }),
  {}
) as Record<keyof typeof _routeLinks, string>
