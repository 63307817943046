import { useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  useLazySendEmailQuery,
  useLazySendParentEmailQuery,
  useLazyUpdateEmailQuery,
} from '@/store/routeTool/routeTool'
import { useAppDispatch } from '@/redux'
import { EMAIL_TEMPLATE } from '@/redux/auth/actionTypes'

export const useOldVariantParentEmailModalConfig = (arrayId) => {
  const editorRef = useRef(null)
  const initialValue = useSelector(
    (state: any) => state.auth.company.email_template
  )

  const dispatch = useAppDispatch()

  const [onSendRequest, sendResponse] = useLazySendEmailQuery()
  const [onUpdateRequest, updateEmailResponse] = useLazyUpdateEmailQuery()
  const [onSendParentRequest, sendParentResponse] =
    useLazySendParentEmailQuery()
  const onSend = (customSubject: string) => {
    const template = editorRef.current.getContent()
    const ids = arrayId
    const requestData = {
      ids,
      template,
      customSubject,
    }
    onSendRequest(requestData)
  }

  const onSave = () => {
    onUpdateRequest({ email_template: editorRef.current.getContent() }).then(
      (response) => {
        if (response.status === 'fulfilled') {
          dispatch({
            type: EMAIL_TEMPLATE,
            payload: { email_template: editorRef.current.getContent() },
          })
          onSendParentRequest({ ids: arrayId })
        }
      }
    )
  }

  const onInit = (_, editor) => (editorRef.current = editor)

  const isLoading =
    sendResponse.isFetching ||
    updateEmailResponse.isFetching ||
    sendParentResponse.isFetching

  return { initialValue, isLoading, onSend, onSave, onInit }
}
