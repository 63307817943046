import { IRtRequestsAndRegistration } from '@/features/containers/SocketConnect/SocketConnect.type'
import { useRequestOpenedStore } from '@/pages/Requests/Opened/useRequestOpenedStore'
import { useRegistrationsOpenedStore } from '@/pages/FieldTrip/Registrations/tabs/Opened/store'
import { rtRequestsActionTypes } from '@/features/containers/SocketConnect/constants'

export const rtRequestsAndRegistrations = (
  message: IRtRequestsAndRegistration
) => {
  if (message?.eventType === 'changeStatusRegistrationRequest') {
    useRegistrationsOpenedStore
      .getState()
      .replaceStatus(
        message.id,
        message.status,
        message?.userBy,
        message?.updatedAt
      )
  } else if (rtRequestsActionTypes.includes(message.eventType)) {
    useRequestOpenedStore
      .getState()
      .replaceStatus(
        message.id,
        message.eventType,
        message?.userBy,
        message?.updatedAt
      )
  }
}
