import React, { Component } from 'react'
import { Popover } from 'antd'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import * as runActions from '../../../../../../redux/routesView/actions'
import { NewCheckbox } from '../../../../../../entities/ui/NewCheckbox/NewCheckbox'
import styles from './index.module.less'
import { AnchorIcon, EditIcon } from '../../../../../../img/icons'
import { MinusCircleOutlined } from '@ant-design/icons'
import classnames from 'classnames'

class BlockMore extends Component {
  constructor(props) {
    super(props)

    this.state = {
      over: false,
      use_default_settings: false,
      curbside_pickup: false,
      curbsideChecked: false,
      isLoading: false,
    }
  }

  onMouseEvent = (e) => {
    let position = { x: e.screenX, y: e.screenY }

    let ScreenY = window.innerHeight

    let diff = Math.abs(ScreenY - position.y)

    this.setState({
      position: diff < 116 ? 'topLeft' : 'bottomLeft',
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loader !== this.props.loader) {
      this.setState({
        isLoading: this.props.loader === 'loadingStart',
      })
    }
    const curbsideChecked = Boolean(this.props.routePointRoute.curbside_pickup)
    if (this.state.curbsideChecked !== curbsideChecked) {
      this.setState({ curbsideChecked })
    }
  }

  onMouseLeave() {
    console.log(`onMouseLeave`)
  }

  mouseOver() {
    this.setState({ over: true })
  }

  HandleChecked = (e, type) => {
    let point = this.props.routePointRoute
    let data = {
      id: point.point_id,
      curbsidePickup:
        type === 'curbsidePickup'
          ? e.target.checked * 1
          : point.curbside_pickup * 1,
      defaultSettings:
        type === 'defaultSettings'
          ? e.target.checked * 1
          : point.use_default_settings * 1,
      routeId: point.route_id,
    }
    this.props.dispatch(runActions.editPointsSett(data))
  }

  getDefCurbside = () => {
    let { use_default_settings } = this.props.routePointRoute
    let { use_default_rules, curbsidePickup } = this.props.route

    if (use_default_settings) return curbsidePickup
    if (use_default_rules) return this.props.company.curbside_pickup

    return this.props.routePointRoute.curbside_pickup
  }

  render() {
    if (!this.props.routePointRoute) return false
    let { use_default_settings, curbside_pickup } = this.props.routePointRoute
    let permissionToTransfer =
      [1, 2, 3].includes(this.props?.type) && !!this.props?.showTransfer

    let curbside = this.getDefCurbside()
    let { isLoading } = this.state

    const vvv = this.props.route.points.find(
      (item) => item.id === this.props.routePointRoute?.point_id
    )

    const DOM = (
      <ul
        id={this.props.isLast ? 'blockLast' : ''}
        className={styles.root}
        onMouseLeave={this.onMouseLeave}
      >
        <li
          data-testid="menuEditStop"
          className={classnames(styles.listItem)}
          onClick={this.props.handleEditStop}
        >
          <EditIcon /> <Trans>Edit Stop</Trans>
        </li>
        <li
          data-testid="menuAnchorStop"
          className={classnames(styles.listItem)}
          onClick={this.props.handleAnchor}
        >
          <AnchorIcon /> <Trans>Mark Stop as Anchor</Trans>
        </li>
        <li
          data-testid="menuRemove"
          className={classnames(styles.listItem, styles.removeFromRun, {
            [styles.disabled]: isLoading,
          })}
          onClick={!isLoading ? this.props.removeRun : undefined}
        >
          <MinusCircleOutlined />
          <Trans>Remove from Run</Trans>
        </li>
        {permissionToTransfer && (
          <li
            className={classnames(styles.listItem, {
              [styles.disabled]: isLoading,
            })}
            onClick={!isLoading ? this.props.stopRidersToTransfer : undefined}
          >
            <div className={styles.tIcon}>T</div>
            <Trans>
              Add rider{this.props.type === 2 ? 's' : ''} to transfer stop
            </Trans>
          </li>
        )}
        <li className={styles.routingListItem}>
          <NewCheckbox
            checked={vvv.defaultSettings}
            disabled={isLoading}
            onChange={(e) => this.HandleChecked(e, 'defaultSettings')}
            name="use_default_settings"
            className="checked"
            type="checkbox"
          >
            <Trans>Use Default Routing Rules</Trans>
          </NewCheckbox>

          <NewCheckbox
            className={styles.curbsidePickup}
            checked={vvv.curbsidePickup}
            disabled={use_default_settings || isLoading}
            name="curbside_pickup"
            onChange={(e) => {
              this.HandleChecked(e, 'curbsidePickup')
            }}
            type="checkbox"
          >
            <Trans>Curbside Pickup Only</Trans>
          </NewCheckbox>
        </li>
      </ul>
    )

    return (
      <div className={styles.moreContainer}>
        <Popover content={DOM} placement="bottomRight" trigger="hover">
          <span data-testid="moreButton" className="More">
            <span className="round" />
            <span className="round" />
            <span className="round" />
          </span>
        </Popover>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    company: state.auth.company,
    loader: state.headerLoader.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockMore)
