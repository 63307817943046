import React, { Component } from 'react'
import * as runActions from '../../../../../../redux/routesView/actions'
import { connect } from 'react-redux'
import i18n from '../../../../../../i18n'

import Modal from './../Modal/DeleteStop/index'
import MoreBlock from '../BlockMore/onlyDelete'
import {
  getTimeLastUTC,
  totalDist,
  totalTime,
} from '../../../../../../components/RepeatFunctions/timeWork'
import anchorAndTimeFN from '../anchorAndTime'

class Child extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
      dropTargetPosition: 'BOTTOM',
      edit: false,
      showModalAnchor: false,
      Edit: false,
      editStop: false,
      anchorItem: '',
      index: props.index3,
      arrive: props.Arrive,
      Wait: props.Wait,
      miles: props.miles,
      min: props.min,
      AllPurpose: [],
      curbside_pickup: 0,
      use_default_settings: 0,
    }
    this.min = 0
    this.miles = 0
    this.time = ''
  }

  componentDidMount() {
    this.obj = {
      title: i18n.t('Edit Bus Depot Stop'),
      select: [
        {
          label: i18n.t('School'),
          name: 'school',
        },
      ],
      minute: [
        {
          label: i18n.t('Wait Time'),
          placeholder: i18n.t('In Minutes'),
        },
      ],
      stops: [
        {
          label: i18n.t('Enter Stop Number'),
          placeholder: i18n.t('or Stop Will Be Placed Last on the List'),
        },
      ],
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      ...nextProps,
    })
  }

  fn = () => {
    console.log('test')
  }

  closeModal = () => {
    this.setState({
      showModalAnchor: false,
      removeModal: false,
      Edit: false,
      editStop: false,
    })
  }

  handleEditStop = () => {
    this.setState({
      editStop: true,
    })
  }

  editToggle = () => {
    this.setState({
      edit: !this.state.edit,
    })
  }

  handleChange = (type, e) => {
    e.preventDefault()
    if (type === 'newPosition') {
      if (e.target.value) this.props.changePosFn(this.state.id, e.target.value)
    }
    this.setState({
      [type]: e.target.value,
    })
  }

  removeRun = (e, id) => {
    this.setState({
      removeModal: true,
    })
  }

  handleStop = () => {
    this.setState({
      editStop: true,
    })
  }

  Edit = (e, id) => {
    this.setState({
      Edit: true,
    })
  }

  handleAnchor = () => {
    return (
      !this.props.isRoleDispatcher &&
      this.setState({
        showModalAnchor: true,
      })
    )
  }

  deletePoint = () => {
    this.props.delFn()
    this.closeModal()
  }

  removeStopInRun = (idStop) => {
    let activeRun = this.state.routesActive[this.state.activeRunIndex]
    let activePoint = activeRun.points.find((x) => x.id === idStop)
    this.props.dispatch(
      runActions.delPosition(idStop, activePoint.position, activeRun.id)
    )

    this.setState({
      viewDeleteModalShow: false,
    })

    let object = this.state.delProps
    if (object.item && object.item.object.type === 1) {
      let obj = object.item.object
      this.props.deleteFromList(
        obj.address.id,
        obj.object.id,
        obj.routePointChildren[0].child_address_id
      )
    }
  }

  render() {
    let { address, object, id, routePointRoute, lastIndex } = this.state
    let children = object
    let { pointOsrmLeg, isWriteRoutes } = this.props
    let newPosition = this.props.routePointRoute.view_position

    if (!this.showEdit) {
      this.showEdit = true
    }

    let arriveTime = null
    let distance = null
    let duration = null
    if (pointOsrmLeg && pointOsrmLeg.time) {
      arriveTime = getTimeLastUTC(pointOsrmLeg.time)
      if (
        pointOsrmLeg.distance !== undefined &&
        pointOsrmLeg.duration !== undefined
      ) {
        distance = totalDist(pointOsrmLeg.distance)
        duration = totalTime(pointOsrmLeg.duration)
      }
    }
    return (
      <div className="ListDiv">
        <div
          key={id}
          className={
            address && (!address.lat || !address.lon) && this.state.edit
              ? 'childPoint error White'
              : 'childPoint bgTranscent White'
          }
        >
          {!isWriteRoutes && (
            <MoreBlock
              isLast={newPosition === lastIndex}
              routePointRoute={routePointRoute}
              id={id}
              handleAnchor={this.handleAnchor}
              handleEditStop={this.handleStop}
              removeRun={this.removeRun}
            />
          )}

          <div className="Head">
            <div className="big">
              {newPosition}. {children.full_name}
            </div>
            {distance && duration && (
              <div className="normal distance">
                <span>{distance}</span>
                <span>{duration}</span>
              </div>
            )}
          </div>

          {anchorAndTimeFN.bind(this)()}
        </div>
        {this.state.removeModal && (
          <Modal
            closeFn={this.closeModal}
            title={i18n.t('Delete Redirection Point')}
            deleteBtn={i18n.t('Delete')}
            deleteObject={this.state.object.full_name}
            deleteFn={() => this.deletePoint()}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    miles: state.routesView.Miles,
    Wait: state.routesView.Wait,
    Arrive: state.routesView.Arrive,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { runActions, dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Child)
