import { Trans } from 'react-i18next'
import { Popover } from 'antd'
import React from 'react'
import { ModalsEnum, useModalStore } from '@/store/modal/ModalStore'

export const OptimizeRun = ({ contentText, isLoading, isDisabled, runId }) => {
  const handleOpenModal = () => {
    useModalStore.getState().onOpenModal({
      currentModal: ModalsEnum.OPTIMIZE_RUN_MODAL,
      state: { id: runId },
    })
    //
  }

  return (
    <Popover
      placement="bottomLeft"
      align={{ offset: [0, -10] }}
      content={contentText}
      id={contentText ? 'popoverOptimaze' : 'popoverOptimazeEmpty'}
      trigger="hover"
    >
      <button
        data-testid="optimizeBtn"
        className={isLoading ? 'runBtn disabledAtPending' : 'runBtn'}
        disabled={isDisabled}
        onClick={handleOpenModal}
      >
        <Trans>Optimize Run</Trans>
      </button>
    </Popover>
  )
}
