import { ReactNode, Suspense } from 'react'
import Loading from './components/RepeatFunctions/loading'

export const WithSuspense = ({ children }: { children: ReactNode }) => {
  return (
    <Suspense fallback={<Loading isLoading={true} error={false} />}>
      {children}
    </Suspense>
  )
}
