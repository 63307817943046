import {
  ICommentMessage,
  IRequestOpenedItem,
} from '@/store/routeTool/routeTool.types'
import { useState } from 'react'
import styles from '@/entities/modals/Requests/RequestsOpenedModal/RequestsOpenedModal.module.less'
import { ChangeTypes } from '@/entities/modals/Requests/RequestsOpenedModal/components/ChangeTypes'
import { Input, Spin } from 'antd'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { useRequestsStore } from '@/entities/modals/Requests/RequestsOpenedModal/store'
import { useLazyPostRejectMessageRequestQuery } from '@/store/routeTool/routeTool'
import { CommentBlock } from '@/entities/modals/Requests/components/CommentBlock/CommentBlock'

export const MainStep = (
  item: IRequestOpenedItem & {
    isFetching: boolean
    comments: ICommentMessage[]
    refetch: () => void
  }
) => {
  const [comment, setComment] = useState('')
  const [setMessageRequest, messageResponse] =
    useLazyPostRejectMessageRequestQuery()
  const onSendMessage = () => {
    setMessageRequest({ id: item.id, message: comment }).then((res) => {
      if (res.isSuccess) {
        setComment('')
        item.refetch()
      }
    })
  }

  return (
    <section className={styles.content}>
      {item.guardian && (
        <div className={styles.guardianWrapper}>
          <p className={styles.label}>Guardian</p>
          <p>{item.guardian}</p>
        </div>
      )}

      <div className={styles.riderIdWrapper}>
        <p className={styles.label}>Rider / ID</p>
        <p>
          {item.childName} / {item.riderId}
        </p>
      </div>
      <ChangeTypes changes={item?.changes || []} />
      <Navigate />
      <div className={styles.textAreaWrapper}>
        <Input.TextArea
          className={styles.messageTextArea}
          autoSize
          placeholder="Leave comment"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
        />
        <Button
          onClick={onSendMessage}
          isLoading={messageResponse.isFetching}
          className={cn({ [styles.hiddenMessageButton]: !comment })}
        >
          Send
        </Button>
      </div>
      <div
        className={cn(styles.commentsWrapper, {
          [styles.loading]: item.isFetching || messageResponse.isFetching,
        })}
      >
        {(item.isFetching || messageResponse.isFetching) && (
          <div className={styles.spinWrapper}>
            <Spin className={styles.loadingSpin} />
          </div>
        )}
        {(item?.comments?.length || 0) > 0 && (
          <div className={styles.commentsBlock}>
            {item.comments?.map((comment) => (
              <CommentBlock {...comment} key={comment.id} />
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

const Navigate = () => {
  const setStep = useRequestsStore.getState().setStep

  return (
    <div className={styles.buttons}>
      <Button onClick={() => setStep('reject')} buttonColor="red">
        Reject
      </Button>
      <Button onClick={() => setStep('manual-accept')} buttonColor="green">
        Manual Accept
      </Button>
      <Button onClick={() => setStep('auto-accept')} buttonColor="green">
        Auto Accept
      </Button>
    </div>
  )
}
