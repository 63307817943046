import * as types from './actionTypes'
import _ from 'lodash'

const initialState = {
  childrenList: [],
  isLoaded: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHILDREN_GET_ITEMS: {
      let arr = []
      arr = [...action.payload]
      return {
        ...state,
        childrenList: arr,
        isLoaded: true,
      }
    }

    case types.CHILDREN_ADD_CHILDNODE: {
      return {
        ...state,
        childrenList: action.payload,
      }
    }

    case types.CHILDREN_EDIT_CHILDNODE: {
      let newArr = [...state.childrenList]

      newArr.childrenList.filter((x) => {
        if (x.id === action.childID) {
          console.log(x)
        }
      })
      return {
        ...state,
        childrenList: newArr,
      }
    }

    case types.CHILDREN_ADD_ITEM: {
      const item = action.payload.data
      return {
        ...state,
        addChildError: 'close',
        childrenList: [...state.childrenList, { ...item, status: 1 }],
      }
    }

    case types.CHILDREN_ADD_ITEM_ERROR: {
      const item = action.payload.data
      return {
        ...state,
        addChildError: item,
      }
    }

    case types.CHILDREN_EDIT_ITEM: {
      const closedItem = action.payload
      let newArr = [...state.childrenList]

      newArr.forEach((item, i) => {
        if (item.id === closedItem.id) {
          newArr[i] = { ...newArr[i], ...closedItem }
        }
      })
      return {
        ...state,
        addChildError: 'close',
        childrenList: _.cloneDeep(newArr),
      }
    }

    case types.CHILDREN_CLEAR_DAILY: {
      const season_id = action.payload
      let newArr = [...state.childrenList]

      newArr = newArr.map((item, i) => {
        item.has_daily_change = 0
        item.daily_change_comment = ''
        return item
      })

      return {
        ...state,
        childrenList: _.cloneDeep(newArr),
      }
    }
    case types.CHILDREN_GET_ITEMS_USE: {
      let children = action.payload.data
      let id = action.payload.id
      let type = action.payload.type

      children.map((item) => {
        if (item.id === id) {
          return item.addresses.map((item) => {
            if (item.is_school === 0 && item.type === type) {
              return (item.is_use = 1)
            }
          })
        }
      })

      return {
        ...state,
        childrenList: _.cloneDeep(children),
      }
    }

    case types.CHILDREN_EDIT_ITEM_ADDRESS: {
      const Item = action.payload
      let newArr = [...state.childrenList]
      return {
        ...state,
        childrenList: _.cloneDeep(Item),
      }
    }

    case types.UPLOAD_CHILD_PHOTO: {
      const closedItem = action.payload
      let newArr = [...state.childrenList]

      newArr.find((x) => x.id === closedItem.id).pathS3 = action.payload.pathS3
      return {
        ...state,
        childrenList: newArr,
      }
    }
    case types.CHILDREN_EDIT_ACTIVE: {
      const item = action.payload
      let newArr = _.cloneDeep(state.childrenList)
      newArr.forEach((val) => {
        if (val.id === item.id) {
          val.status = item.status
          val.is_use = item.is_use
          val.addresses = item?.addresses ?? val.addresses
          if (typeof val?.is_use === 'number') {
            val.is_use = item.is_use
          }
          if (Array.isArray(item?.addresses)) {
            val.addresses = item?.addresses
          }
        }
      })
      return {
        ...state,
        childrenList: newArr,
      }
    }

    case types.CHILDREN_SHOW_ADDRESS: {
      const target = action.payload
      let newArr = [...state.childrenList]
      let index = newArr.findIndex((i) => i.id === target.childId)
      let index2 = newArr[index].addresses.findIndex((i) => {
        if (i.address.id === target.addressId || i.id === target.addressId) {
          return true
        }
      })
      if (!newArr[index].addresses[index2].hasOwnProperty('viewOnMap')) {
        newArr[index].addresses[index2].viewOnMap = false
      } else {
        newArr[index].addresses[index2].viewOnMap =
          !newArr[index].addresses[index2].viewOnMap
      }
      return {
        ...state,
        childrenList: _.cloneDeep(newArr),
      }
    }

    case types.CHILDREN_MOVE_TO_RUN: {
      // x.child_address_id, x.childId, data.route_id
      const riders = action.payload
      let newArr = [...state.childrenList]
      for (let r = 0; r < riders.children.length; r++) {
        let x = riders.children[r]
        // eslint-disable-next-line no-labels
        for (let i = 0; i < newArr.length; i++) {
          newArr[i].addresses.forEach((a) => {
            if (a.id === x.childAddressId || a.id === x.child_address_id) {
              a.is_use = 1
              a.route = [{ id: riders.routeId || riders.route_id }]
            }
          })
        }
      }
      return {
        ...state,
        childrenList: _.cloneDeep(newArr),
      }
    }

    case types.CHILDREN_MOVE_TO_RUN_V2: {
      const riders = action.payload
      let newArr = [...state.childrenList]
      for (let r = 0; r < riders.childrenPoints.length; r++) {
        let x = riders.childrenPoints[r]
        // eslint-disable-next-line no-labels
        for (let i = 0; i < newArr.length; i++) {
          newArr[i].addresses.forEach((a) => {
            if (a.id === x.childAddressId || a.id === x.child_address_id) {
              a.is_use = 1
              a.route = [{ id: riders.routeId || riders.route_id }]
            }
          })
        }
      }
      return {
        ...state,
        childrenList: _.cloneDeep(newArr),
      }
    }

    case types.CHILDREN_REMOVE_FROM_TRANSFER: {
      const childId = action.payload.childId
      const runId = action.payload.runId
      let newArr = [...state.childrenList]
      let index = newArr.findIndex((i) => i.id === childId)
      newArr[index].addresses.forEach((address) => {
        if (address.is_school && !address.is_use) return false
        if (
          address.route &&
          address.route.length > 0 &&
          address.route[0].id === runId &&
          address.is_use &&
          !address.is_school
        ) {
          address.is_use = 0
          address.route = null
        }
      })
      return {
        ...state,
        childrenList: _.cloneDeep(newArr),
      }
    }

    case types.CHILDREN_EDIT_ADDRESS: {
      const target = action.payload
      const route_id = target.activeRun
      let newArr = [...state.childrenList]
      let index = newArr.findIndex((i) => i.id === target.childId)
      let index2 = newArr[index].addresses.findIndex((i) => {
        if (i.address) {
          if (i.address.id === target.addressId || i.id === target.addressId) {
            return true
          }
        }
      })

      let r = false
      newArr[index].addresses.forEach((address) => {
        if (address.is_school && !address.is_use) return false

        if (
          address.route &&
          address.route.length > 0 &&
          address.route[0].id === route_id &&
          address.is_use &&
          address.id === target.addressId &&
          !address.is_school
        ) {
          address.is_use = 0
          address.route = null
          r = true
        }
      })
      if (r) {
        return {
          ...state,
          childrenList: _.cloneDeep(newArr),
        }
      }

      let addressTarget = newArr[index].addresses[index2]
      if (addressTarget) {
        addressTarget.is_use = !addressTarget.is_use * 1
        newArr[index].addresses[index2].route = [{ id: route_id }]

        return {
          ...state,
          childrenList: _.cloneDeep(newArr),
        }
      }
    }

    case types.CHILDREN_EDIT_ADDRESS_AFTER_CLONE: {
      const target = action.payload
      let newArr = [...state.childrenList]
      let index = newArr.find((i) => i.id === target.childId)

      let index2 = index.addresses.find((i) => i.id === target.addressId)

      index2.is_use = 1

      return {
        ...state,
        childrenList: _.cloneDeep(newArr),
      }
    }

    case types.CHILDREN_EDIT_ADDRESS_FROM_SCHOOL: {
      const { childId, route_id } = action.payload

      let newArr = [...state.childrenList]
      let index = newArr.findIndex((i) => i.id === childId)

      newArr[index].addresses.forEach((x) => {
        if (x.route) {
          if (x.route[0].id === route_id) {
            x.route = []
            x.is_use = 0
          }
        }
      })

      return {
        ...state,
        childrenList: _.cloneDeep(newArr),
      }
    }

    case types.CHILDREN_REMOVE_ITEM: {
      const id = action.payload
      return {
        ...state,
        childrenList: state.childrenList.filter((item) => item.id !== id),
      }
    }
    case types.CHILDREN_REMOVE_BY_IDS: {
      const ids = action.payload
      return {
        ...state,
        childrenList: state.childrenList.filter(
          (item) => !ids.includes(item.id)
        ),
      }
    }

    case types.CHILDREN_REMOVE_ADDRESS: {
      const block = action.payload

      let newArr = [...state.childrenList]
      newArr.forEach((item) => {
        if (item.id === block.child_id) {
          let newAddresses = item.addresses.filter((i) => {
            return i.id !== block.id
          })
          item.addresses = newAddresses
        }
      })
      return {
        ...state,
        childrenList: newArr,
      }
    }

    case types.CHILDREN_GET_NOTES: {
      let { id, data } = action.payload
      let newArr = [...state.childrenList]
      newArr.forEach((val, i) => {
        if (val.id === id) {
          val.notes = data
        }
      })
      return {
        ...state,
        childrenList: _.cloneDeep(newArr),
      }
    }

    case types.CHILDREN_EDIT_NOTE: {
      let note = action.payload
      let newArr = [...state.childrenList]
      newArr.forEach((val, i) => {
        if (val.id === note.child_id) {
          val.notes.forEach((v, i, a) => {
            if (v.id === note.id) {
              a[i] = note
            }
          })
        }
      })
      return {
        ...state,
        childrenList: newArr,
      }
    }

    case types.CHILDREN_ADDRESS_SWITCH: {
      return { ...state }
    }

    case types.CHILDREN_ADD_NOTE: {
      let data = action.payload
      let newArr = [...state.childrenList]
      newArr.forEach((val, i) => {
        if (val.id === data.child_id) {
          if (val.notes) {
            val.notes.splice(0, 0, data)
          }
        }
      })
      return {
        ...state,
        childrenList: _.cloneDeep(newArr),
      }
    }
    case types.CHILDREN_LOG: {
      let id = action.payload.id
      let data = action.payload.data
      let newArr = [...state.childrenList]

      data = data.filter((item) => {
        if (item) {
          return item
        }
      })

      newArr.map((item) => {
        if (item.id === id) {
          return (item.logs = data)
        }
      })
      return {
        ...state,
        childrenList: _.cloneDeep(newArr),
      }
    }

    case types.CHILDREN_ATTENDANCE: {
      let id = action.payload.id
      let data = action.payload.data
      let newArr = [...state.childrenList]

      data = data.filter((item) => {
        if (item.child_address) {
          return item
        }
      })

      newArr.map((item) => {
        if (item.id === id) {
          return (item.attendance = data)
        }
      })
      return {
        ...state,
        childrenList: _.cloneDeep(newArr),
      }
    }

    case types.CHILDREN_ATTENDANCE_BY_ID: {
      let id = action.payload.id
      let data = action.payload.data
      let newArr = [...state.childrenList]

      data = data.filter((item) => {
        if (item.child_address) {
          return item
        }
      })

      newArr.map((item) => {
        if (item.id === id) {
          return (item.attendance = data)
        }
      })
      return {
        ...state,
        childrenList: _.cloneDeep(newArr),
      }
    }

    case types.CHILDREN_REMOVE_NOTE: {
      let { id, childId } = action.payload
      let newArr = [...state.childrenList]
      newArr.forEach((val, i) => {
        if (val.id === childId) {
          let notes = val.notes.filter((item) => {
            return item.id !== id
          })
          val.notes = notes
        }
      })
      return {
        ...state,
        childrenList: _.cloneDeep(newArr),
      }
    }

    case types.CHILDREN_RESET_FILTERS: {
      return {
        ...state,
        resetFilters: action.payload,
      }
    }

    default:
      return state
  }
}
