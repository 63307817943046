import 'react-contexify/dist/ReactContexify.min.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map, Marker } from 'react-leaflet'

import PolylineText from '../../../../../components/MapDefault/src/polyline-text/PolylineText'
import * as fieldTripActions from '../../../../../redux/fieldTrip/requests/actions'
import {
  directionsPathOptions,
  getIcon,
  getRouteMarkersFT,
} from './mapSpecialFns'
import _ from 'lodash'
import {
  contextMenuOnDepot,
  fnMouseOut,
  fnMouseOver,
} from '../../../../../components/MapDefault/mapSpecialFns'
import { assignedPopup } from '../MapFTBuilder/mapSpecialFns'
import { mapKey } from '../../../../../redux/configLocal'
import chamomileFn from '../../../../../components/MapDefault/chamomileFn'
import { BaseGoogleLayer } from '../../../../../components/GoogleLayer/BaseGoogleLayer'
import { getRotationPositionAndIndexOfMarker } from '@/helpers/points'

class MapRunBuilder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      osrm: {},
      bounds: [],
      depots: [],
      showMap: false,
      markers: [],
      ftrs: this.props.ftrs,
      fieldTrip: props.fieldTrip,
      activeFT: [],
    }
    this.map = React.createRef()
  }

  componentWillMount() {
    if (!this.props.ftrs) return
    this.props.ftrs.forEach((r) => {
      if (r.fieldTripPoints.length > 1) {
        r.fieldTripPoints.sort((a, b) => {
          if (a.position > b.position) {
            return 1
          }
          if (a.position < b.position) {
            return -1
          }
          return 0
        })
        this.props.dispatch(
          fieldTripActions.getOSRM(r.fieldTripPoints, this.props.tripID, r.id)
        )
      }
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        showMap: true,
      })
    }, 1)
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.fieldTrip !== nextProps.fieldTrip) {
      let activeFT = nextProps.fieldTrip.data.find(
        (x) => x.id === this.props.tripID
      )

      this.setState({
        fieldTrip: nextProps.fieldTrip.data,
        activeFT: activeFT,
      })
    }

    if (this.state.ftrs !== nextProps.ftrs && !!nextProps.ftrs) {
      if (this.state.ftrs) {
        let copy = 0
        this.state.ftrs.forEach((x, i) => {
          if (!nextProps.ftrs[i]) {
            return
          }

          if (x.id === nextProps.ftrs[i].id) {
            copy = 1
          }
        })
        if (copy) return false
      }

      nextProps.ftrs.forEach((r) => {
        if (r.fieldTripPoints.length > 1) {
          this.props.dispatch(
            fieldTripActions.getOSRM(r.fieldTripPoints, nextProps.tripID, r.id)
          )
        }
      })

      this.setState({ ftrs: nextProps.ftrs })
    }
  }

  key = mapKey

  arrowOptions = {
    repeat: true,
    offset: 3,
    attributes: {
      fill: 'white',
      'font-weight': 'normal',
      'font-size': '12',
    },
  }

  getBounds = () => {
    let bounds = []
    if (Array.isArray(this.state.ftrs)) {
      this.state.ftrs.forEach((x) => {
        if (x.markers) {
          x.markers.forEach((y) => {
            !!y?.location && bounds.push(y.location)
          })
        }
      })
    }

    if (bounds.length > 1) return bounds

    bounds = _.map(this.props.depots, (depot) => {
      return [depot.address.lat, depot.address.lon]
    })
    if (_.isEmpty(bounds)) {
      return [
        [24.664018, -123.461071],
        [46.088543, -56.280215],
      ]
    }
    return bounds
  }

  gen = () => {
    let activeFT = this.state.activeFT
    if (activeFT && activeFT.fieldTrip && activeFT.fieldTrip.fieldTripRuns) {
      activeFT.fieldTrip.fieldTripRuns.forEach((x) => {
        let markers = getRouteMarkersFT(x)
        x.markers = markers
      })

      if (this.state.ftrs !== activeFT.fieldTrip.fieldTripRuns) {
        this.setState({
          ftrs: activeFT.fieldTrip.fieldTripRuns,
        })
      }
      return activeFT
    }
  }

  childRidersAdditions = (marker) => {
    if (marker.object.fieldTripRiderAdults.length > 0) {
      let data = marker.object.fieldTripRiderAdults.map((rider) => {
        if (rider) {
          return (
            <div
              className="firstLine rline fullWidth top5"
              style={{ paddingLeft: '0' }}
            >
              <div className="flex">
                <div className="List Top" style={{ width: '100%' }}>
                  <div className="sepBottom">
                    <span className="riderIcon">
                      <span className="Name">{rider.name}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      })

      return (
        <div className="top5">
          <span>Additional {marker.object.fieldTripRiderAdults.length}</span>
          {data}
        </div>
      )
    }
  }

  childRiders = (marker) => {
    if (marker.object.fieldTripRiders.length > 0) {
      let data = marker.object.fieldTripRiders.map((rider) => {
        if (rider.child) {
          return (
            <div
              className="firstLine rline fullWidth top5"
              style={{ paddingLeft: '0' }}
            >
              <div className="flex">
                <div className="List Top" style={{ width: '100%' }}>
                  <div className="sepBottom">
                    <span className="riderIcon">
                      <span className="Name">{rider.child.full_name}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      })

      return (
        <div className="top5">
          <span>Riders {marker.object.fieldTripRiders.length}</span>
          {data}
        </div>
      )
    }
  }

  render() {
    let { depots } = this.props
    let generatedList = this.gen()
    let firstRun
    chamomileFn.bind(this)()
    if (generatedList) {
      firstRun = generatedList.fieldTrip.fieldTripRuns[0]
    }
    let markers = []
    let assignedPopupBind = assignedPopup.bind(this)

    if (!this.state.showMap) return <div />
    return (
      <div>
        <Map bounds={this.getBounds()} ref={this.map}>
          {generatedList &&
            generatedList.fieldTrip &&
            generatedList.fieldTrip.fieldTripRuns.map((x, i) => {
              if (x.osrm && x.osrm !== 'ERROR') {
                return (
                  <div key={i}>
                    <PolylineText
                      arrowOptions={this.arrowOptions}
                      pathOptions={directionsPathOptions(i)}
                      geometry={x.osrm.geometry}
                      name={x.name}
                    />
                  </div>
                )
              }
            })}

          {generatedList &&
            generatedList.fieldTrip &&
            generatedList.fieldTrip.fieldTripRuns.map((x, i) => {
              if (this.props.activeRun && x.id !== this.props.activeRun.id) {
                return
              }

              if (x.markers) {
                let arrayMarker = _.orderBy(
                  x.markers.filter((m) => !!m?.object),
                  function (e) {
                    return e?.object?.position
                  },
                  ['desc']
                )

                return arrayMarker.map((marker, _, arr) => {
                  marker.object.readOnly = true
                  marker.sequence = marker.object.position
                  marker.object.view = 'mapFtView'
                  const { rotation, zIndex, position } =
                    getRotationPositionAndIndexOfMarker(arr, marker)
                  return (
                    <Marker
                      key={marker.id}
                      icon={getIcon(marker.object.type, marker.sequence)}
                      onMouseOver={(e) => fnMouseOver(e)}
                      onMouseOut={(e) => fnMouseOut(e)}
                      zIndexOffset={zIndex}
                      position={position}
                      rotationAngle={rotation}
                    >
                      {assignedPopupBind(marker)}
                    </Marker>
                  )
                })
              }
            })}

          {depots &&
            depots.map((depot) => {
              return (
                <Marker
                  key={depot.id}
                  onContextMenu={contextMenuOnDepot}
                  position={[depot.address.lat, depot.address.lon]}
                  icon={getIcon('busdepot', '')}
                  onMouseOver={(e) => fnMouseOver(e)}
                  onMouseOut={(e) => fnMouseOut(e)}
                />
              )
            })}

          <BaseGoogleLayer />
        </Map>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    depots: state.auth.depots,
    fieldTrip: state.fieldTripRequests,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { fieldTripActions, dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapRunBuilder)
