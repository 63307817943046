export const DistanceIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M2.7 3.666a.965.965 0 1 1-.001-1.93.965.965 0 0 1 .001 1.93zM2.7 0C1.2 0 0 1.2 0 2.7c0 2.022 2.7 5.016 2.7 5.016S5.4 4.722 5.4 2.7C5.4 1.2 4.2 0 2.7 0zm6.6 3.666a.966.966 0 1 1 0-1.932.966.966 0 0 1 0 1.932zM9.3 0C7.8 0 6.6 1.2 6.6 2.7c0 2.022 2.7 5.016 2.7 5.016S12 4.722 12 2.7C12 1.2 10.8 0 9.3 0zm0 8.4c-.762 0-1.44.48-1.692 1.2H4.392a1.802 1.802 0 0 0-3.396 1.2A1.81 1.81 0 0 0 3.3 11.896c.51-.18.9-.588 1.092-1.098h3.222A1.795 1.795 0 0 0 9.9 11.897 1.8 1.8 0 0 0 11.01 9.6 1.814 1.814 0 0 0 9.3 8.4zm0 2.7a.9.9 0 1 1 0-1.8.9.9 0 0 1 0 1.8z"
      />
    </svg>
  )
}
