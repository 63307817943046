import moment from 'moment'
import React from 'react'
import _ from 'lodash'
import { getIcon } from '@/components/MapDefault/mapSpecialFns'
import { getRouteMarker } from '@/components/RepeatFunctions/mapGenerate'
import { useGpsDetailsStore } from '@/pages/Gps/NewGpsDetails/store'
import {
  GpsRunPoint,
  GpsRunWithDetails,
  StopType,
  TimelinePoint,
} from '@/redux/gps/types'
import { BaseIconOptions, Icon } from 'leaflet'

function renderBlockBase(min: TimeLineMinute) {
  return (
    <div className="info-block-line" key={min.time}>
      {blockInfo(min)}
    </div>
  )
}

function blockInfo(min: TimeLineMinute) {
  const { time, bearing, speed, timeZone } = min
  return (
    <div className="firstLine" key={min.time}>
      <div className="line-time">
        <span className="h">
          {moment(time * 1000)
            .tz(timeZone)
            .format('hh:mm')}{' '}
        </span>
        <span className="m">
          {moment(time * 1000)
            .tz(timeZone)
            .format('A')}
        </span>
      </div>
      <div className="line-speed">
        <span className="h"> {speed} </span>
        <span className="m">mph</span>
      </div>
      <div className="line-road">{bearing}</div>
    </div>
  )
}

function renderBlockPoint(min: TimeLineMinute, gpsRun: GpsRunWithDetails) {
  const realPoint = gpsRun?.points?.find((x) => x.id === min.point.id)

  const io = getIcon(
    getRouteMarker(realPoint.stopType),
    realPoint.position
  ) as Icon<BaseIconOptions>
  const offset = (realPoint.scheduledTime - realPoint.factTime) / 60

  return (
    <div className="info-block-line pointBlock" key={min.time}>
      {blockInfo(min)}
      <div className="secondLine">
        <div
          className="pointViewSVG"
          style={{ backgroundImage: `url(${io.options.iconUrl})` }}
        />
        <span className={`greenMin ${offset < 0 ? 'redMin' : ''}`}>
          {` ${Math.floor(Math.abs(offset))} `}
        </span>
        <span className="secondLine-status">
          {offset > 0 ? 'min earlier' : 'min late'}
        </span>
      </div>
    </div>
  )
}

function isPointStop(minutes: TimeLineMinute[], gpsRun: GpsRunWithDetails) {
  const factPoints = gpsRun?.points?.filter((p) => !!p.factTime)
  if (_.isEmpty(factPoints)) return false
  minutes.forEach((m) => {
    factPoints.forEach((x) => {
      const dateCheck = moment(x.factTime * 1000)
        .utc()
        .startOf('minute')
        .unix()
      m.time = moment(m.time * 1000)
        .utc()
        .startOf('minute')
        .unix()

      if (m.time === dateCheck && x.stopType !== StopType.Redirection) {
        m.point = x
      }
    })
  })
}

function msMeels(meters: number) {
  return `${Math.round((meters * 3600) / 1609.34)}`
}

function gpsHistoryInfo(gpsRun: GpsRunWithDetails, min: TimeLineMinute) {
  const mqttData = useGpsDetailsStore.getState().mqttGps

  const utcKey = moment(min.time * 1000)
    .utc()
    .format('HH:mm')

  let h: TimelinePoint
  if (gpsRun.timeline && gpsRun.timeline[utcKey]) {
    h = gpsRun.timeline[utcKey]
  } else if (mqttData[utcKey]) {
    h = mqttData[utcKey]
  }

  min.speed = h?.speed ? msMeels(+h.speed) : '---'
  min.bearing = h?.bearing ? bearingTable(+h.bearing) : ''
}

function bearingTable(bearing: number) {
  const dataList = [
    { key: `North`, deg: 0 },
    { key: `East`, deg: 90 },
    { key: `South`, deg: 180 },
    { key: `West`, deg: 270 },
    { key: `North`, deg: 360 },
  ]

  let way = ''
  dataList.forEach((x) => {
    if (x.deg - 45 < bearing && bearing < x.deg + 45) {
      way = x.key
    }
  })

  return way
}

export const getMinutesOnTimeLine = (
  mousePos: number,
  barHeight: number,
  runTotalMinutes: number
) => {
  const gpsRun = useGpsDetailsStore.getState().gpsInfo

  const ratio = barHeight / runTotalMinutes

  const minutes: TimeLineMinute[] = [0, 1, 2, 3, 4, 5].map((x) => {
    const time = +gpsRun?.startTime + x * 60 + Math.floor(mousePos / ratio) * 60

    return {
      time: time,
      point: null,
      speed: null,
      bearing: null,
      timeZone: gpsRun.timeZone,
    }
  })

  isPointStop(minutes, gpsRun) // ищем пройденные стопы и добавляем к выбранной минуте
  return minutes
    .filter((min) => !!min.time)
    .reverse()
    .map((min) => {
      gpsHistoryInfo(gpsRun, min)
      return min.point ? renderBlockPoint(min, gpsRun) : renderBlockBase(min)
    })
}

export type TimeLineMinute = {
  time: number
  point: GpsRunPoint
  speed: string
  bearing: string
  timeZone: string
}
