import { create } from 'zustand'

export interface ISessionStore {
  editNameId: number | null
  openedFileIds: number[]
  setStore: (params: Partial<Omit<ISessionStore, 'setStore'>>) => void
  setOpenedFileIds: (id: number) => void
  filter: {
    selected: number[]
    name: string
    sort: 'school_year' | 'date' | null
  }
}

export const useSessionsStore = create<ISessionStore>((set, get) => ({
  editNameId: null,
  openedFileIds: [],
  filter: {
    selected: [],
    sort: null,
    name: '',
  },
  setStore: (params) => set({ ...get(), ...params }),
  setOpenedFileIds: (id) => {
    const isHave = get().openedFileIds.includes(id)
    const openedFileIds = get().openedFileIds
    if (isHave) {
      set({ openedFileIds: openedFileIds.filter((item) => item !== id) })
    } else {
      set({ openedFileIds: [...openedFileIds, id] })
    }
  },
}))

export const resetSessionZStore = () => {
  useSessionsStore.getState().setStore({
    editNameId: null,
    openedFileIds: [],
    filter: {
      selected: [],
      sort: null,
      name: '',
    },
  })
}
