import React, { Component } from "react";
import { connect } from "react-redux";

import Input from "antd/es/input";

import DatePicker from "antd/es/date-picker";
import BaseModal from "../../../../../components/BaseModal";
import moment from "moment";

import "./index.less";

class ModalDeleteStop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      point: props.point
    };
  }

  delete = () => {
    const { point } = this.state;

    this.props.deleteStopFn(point.id);
    this.props.close();
  };

  renderText = () => {
    return (
      <div>
        Are you sure you want to{" "}
        {this.state.point.type === 3 ? "delete" : "remove"}{" "}
        {this.state.point.type === 3 ? (
          <b>{this.state.point.position + " ."}</b>
        ) : null}{" "}
        <b>{this.state.point.title}</b>{" "}
        {this.state.point.fieldTripRiderAdults.length > 0 && "and remove"}{" "}
        {this.state.point.fieldTripRiderAdults.length > 0 ? (
          <b>{this.state.point.fieldTripRiderAdults.length + " Riders"}</b>
        ) : null}{" "}
        from Run?{" "}
      </div>
    );
  };

  title = () => {
    if (this.state.point.type === 1) {
      return "Remove Bus Depot from Run";
    }
    if (this.state.point.type === 2) {
      return "Delete Stop";
    }

    if (this.state.point.type === 3) {
      return "Remove School from Run";
    }
  };

  render() {
    let { close } = this.props;
    let { point } = this.state;
    let ridersCount =
      point.fieldTripRiders.length + point.fieldTripRiderAdults.length;

    return (
      <BaseModal
        class={"deleteModal"}
        title={this.title()}
        className="add-point-modal"
        closeFn={close}
      >
        <div>
          <div className="line deleteModal">{this.renderText()}</div>

          <div className="center">
            <div className="buttons">
              <button className="cancel" onClick={close}>
                Cancel
              </button>
              <button className="save" onClick={this.delete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </BaseModal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalDeleteStop);
