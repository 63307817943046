import React from 'react'
import {
  getTimeLastUTC,
  arrivalTimeUtsToLocal,
} from '../../../../../components/RepeatFunctions/timeWork'
import { Trans } from 'react-i18next'
import i18n from '../../../../../i18n'

export default function () {
  let {
    isAnchor,
    routeWaittime,
    pointOsrmLeg,
    routeView,
    timeZone,
    routeTime,
    newPosition,
  } = this.props
  let value = 0

  let type = this.props.type

  if (this.props.type === 1) value = 28

  let departTime = null

  let arriveTime = null

  if (pointOsrmLeg && pointOsrmLeg.time) {
    departTime = getTimeLastUTC(pointOsrmLeg.time)
  }

  if (pointOsrmLeg && pointOsrmLeg.arrival_time) {
    arriveTime = getTimeLastUTC(pointOsrmLeg.arrival_time)
  }

  departTime = departTime
    ? arrivalTimeUtsToLocal(timeZone, departTime, i18n.language)
    : null
  arriveTime = arriveTime
    ? arrivalTimeUtsToLocal(timeZone, arriveTime, i18n.language)
    : null

  const anchorTimeToLocal = routeTime
    ? arrivalTimeUtsToLocal(timeZone, routeTime, i18n.language)
    : null

  let routeWaittimeStr = routeWaittime / 60 ? routeWaittime / 60 : 0

  routeWaittimeStr = routeWaittimeStr.toFixed()

  let isMorning = this.props.route.is_morning

  const lastArrivePoint = this.props.lastIndex === this.props.newPosition

  const arriveInfo = arriveTime !== departTime && (
    <span onClick={lastArrivePoint ? this.handleAnchor : undefined}>
      <Trans>Arrive</Trans>{' '}
      <span className="minutes">{arriveTime || anchorTimeToLocal}</span>{' '}
    </span>
  )

  const departInfo = arriveTime !== departTime && (
    <span onClick={this.handleAnchor}>
      <b
        onClick={this.openModalAnchor}
        className={isAnchor > 0 ? 'arrive' : 'arriveNo noPadding'}
      />
      <Trans>{departTime && arriveTime ? 'Depart' : 'Arrive'}</Trans>{' '}
      <span className="minutes">{departTime || anchorTimeToLocal}</span>{' '}
    </span>
  )

  const similarTimeInfo = (
    <span onClick={this.handleAnchor}>
      <b
        onClick={this.openModalAnchor}
        className={isAnchor > 0 ? 'arrive' : 'arriveNo noPadding'}
      />
      <Trans>Arrive/Depart:</Trans>{' '}
      <span className="minutes">{arriveTime || anchorTimeToLocal}</span>{' '}
    </span>
  )

  return (
    <div
      className={` ${
        routeView ? 'firstLine flex routeViewPoint' : 'firstLine'
      }`}
    >
      {type === 6 && newPosition === 1 && (
        <div className="flex Full">
          <span style={{ marginLeft: value + 'px' }} className="BusDep">
            <Trans>The Run Starts from here</Trans>
          </span>
        </div>
      )}
      <div className="flex right" style={{ width: '100%' }}>
        <div className="normal">
          <div
            className="arrive"
            style={{ marginLeft: 'auto', width: 'fit-content' }}
          >
            <div style={{ width: 'fit-content' }}>
              {newPosition === 0 && !isAnchor && !departTime && !arriveTime ? (
                <span className="gray">
                  <Trans>{newPosition === 1 ? 'Depart' : 'Arrive'}</Trans>{' '}
                  <span className="minutes">
                    {isMorning === 1 ? '07:00 AM' : '12:00 PM'}
                  </span>
                </span>
              ) : (
                <span className="gray">
                  {arriveTime && arriveInfo}
                  {departTime && departInfo}
                  {departInfo === arriveInfo && similarTimeInfo}
                </span>
              )}
              {newPosition !== 0 && (
                <span>
                  <span className="gray">
                    <Trans>Wait</Trans>{' '}
                  </span>
                  <span className="minutes">
                    {routeWaittimeStr} <Trans>min</Trans>
                  </span>
                </span>
              )}

              {newPosition === 0 && routeView && (
                <span>
                  <span className="gray">
                    <Trans>Wait</Trans>{' '}
                  </span>
                  <span className="minutes">
                    {routeWaittimeStr} <Trans>min</Trans>
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
