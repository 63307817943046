import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { axiosBaseQuery } from '@/helpers/axiosBaseQuery'
import api from '@/redux/apiConfig'
import { getUrlWithSearchParams } from '@/helpers/getUrlWithSearchParams'
import axios from 'axios'
import { notification } from 'antd'
import { closeModal } from '@/features/containers/ModalContainer/modalConfig'
import * as commonTypes from '@/redux/common/actionTypes'
import * as types from '@/redux/routesView/actionTypes'

export const viewRunsApi = createApi({
  reducerPath: 'viewRunsApi',
  baseQuery: axiosBaseQuery(api.common),
  endpoints: (build) => ({
    printMonthlyAttendance: build.query<
      { data: string },
      { runIds: number[]; timestamp: number }
    >({
      keepUnusedDataFor: 0,
      queryFn: async ({ runIds, timestamp }) => {
        const response = await axios.post(
          getUrlWithSearchParams(
            '../api/v2/route/print/monthly-attendance-xls'
          ),
          { runIds, timestamp },
          { responseType: 'arraybuffer' }
        )
        return { data: { data: response.data } }
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const response = await queryFulfilled
          const file = new Blob([response.data.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const fileURL = URL.createObjectURL(file)
          window.open(fileURL)
          closeModal()
        } catch (e) {
          notification.error({ message: 'Server error' })
        }
      },
    }),
    deleteRuns: build.mutation<any, { routeIds: number; callBack: () => void }>(
      {
        query: ({ routeIds }) => ({
          url: '../api/v2/route/delete',
          method: 'delete',
          data: { data: { routeIds } },
        }),
        onQueryStarted: async (
          { routeIds, callBack },
          { queryFulfilled, dispatch }
        ) => {
          dispatch({ type: commonTypes.PENDING_START })
          try {
            await queryFulfilled
            dispatch({
              type: types.ROUTE_REMOVE_ITEMS,
              payload: routeIds,
            })
            callBack?.()
          } catch (e) {
            notification.error({ message: 'Server error' })
          } finally {
            dispatch({ type: commonTypes.PENDING_END })
          }
        },
      }
    ),
  }),
})

export const { useLazyPrintMonthlyAttendanceQuery, useDeleteRunsMutation } =
  viewRunsApi
