import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from './../../../../../components/customSelect/index'
import BaseModal from '../../../../../components/BaseModal'
import InputMask from 'react-input-mask'
import { NewAntInput } from '../../../../../entities/ui/NewInput/NewAntInput'

class ModalAddRider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        point_id: 0,
        rider_name: null,
        phone: null,
        note: null,
      },
      formErrors: {
        point_id: false,
        rider_name: false,
        phone: false,
      },
      formEmpty: {
        point_id: true,
        rider_name: true,
        phone: true,
      },
    }
  }

  addRiderInRun = () => {
    const { form, formErrors, formEmpty } = this.state
    let isError = false

    Object.keys(formEmpty).forEach((type) => {
      if (formEmpty[type]) {
        formErrors[type] = true
        isError = true
      }
    })

    if (isError) {
      this.setState({
        formErrors: formErrors,
      })

      return
    }

    this.props.addInRun(form.point_id, form.rider_name, form.phone, form.note)
    this.props.close()
  }

  handleChange = (type, value) => {
    const form = this.state.form
    form[type] = value

    this.setState({
      form: form,
    })

    if (this.state.formErrors.hasOwnProperty(type)) {
      if (!value || value == '') {
        let errors = this.state.formErrors
        errors[type] = true

        let empty = this.state.formEmpty
        empty[type] = true

        this.setState({
          formErrors: errors,
          formEmpty: empty,
        })
      } else {
        let errors = this.state.formErrors
        errors[type] = false

        let empty = this.state.formEmpty
        empty[type] = false

        this.setState({
          formErrors: errors,
          formEmpty: empty,
        })
      }
    }
  }

  render() {
    let { close } = this.props
    let { formErrors } = this.state

    return (
      <BaseModal
        title="Additional Rider"
        save={this.addRiderInRun}
        closeFn={close}
      >
        <div className="line fontLight" style={{ flexWrap: 'wrap' }}>
          <span className={formErrors.rider_name ? 'error' : ''}>
            Rider Name
          </span>
          <NewAntInput
            className={formErrors.rider_name ? 'error' : ''}
            onChange={(e) => this.handleChange('rider_name', e.target.value)}
            placeholder="John Connor"
          />
        </div>

        <div className="line fontLight" style={{ flexWrap: 'wrap' }}>
          <span className={formErrors.phone ? 'error' : ''}>Phone</span>
          <InputMask
            onChange={(e) => this.handleChange('phone', e.target.value)}
            mask="+1 999 999 9999"
            maskChar="-"
            value={this.state.form.phone}
          />
        </div>

        <div className="line fontLight" style={{ flexWrap: 'wrap' }}>
          <span>Note</span>
          <NewAntInput
            autoFocus={true}
            onChange={(e) => this.handleChange('note', e.target.value)}
            placeholder="Tape note text here..."
          />
        </div>

        <div className="line flexSelectLine">
          <span className={formErrors.point_id ? 'error' : ''}>
            Select a Stop
          </span>
          <Select
            value={this.state.form.point_id}
            className={formErrors.point_id ? 'error' : ''}
            onChange={(e) => this.handleChange('point_id', e)}
          >
            <option className={'hide'} value={0} key={0} disabled>
              Select stop
            </option>
            {this.props.stops.map((stop) => {
              if (stop.type != 1)
                return (
                  <option key={stop.id} value={stop.id}>
                    {stop.title}
                  </option>
                )
            })}
          </Select>
        </div>

        <div className="center">
          <div className="buttons">
            <button className="cancel" onClick={close}>
              Cancel
            </button>
            <button className="save" onClick={this.addRiderInRun}>
              Save
            </button>
          </div>
        </div>
      </BaseModal>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddRider)
