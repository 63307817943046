import React, { Component } from 'react'
import { connect } from 'react-redux'

import Input from 'antd/es/input'

import DatePicker from 'antd/es/date-picker'
import BaseModal from '../../../../../components/BaseModal'
import moment from 'moment'

import './index.less'
import { CreatePointRequest, StopPointType, StopPoint } from '../../models'
import { NewSingleDatePicker } from '../../../../../entities/ui/NewSingleDatePicker/NewSingleDatePicker'
import { CalendarIcon, ClearIcon } from '../../../../../img/icons'
import { NewAntInput } from '../../../../../entities/ui/NewInput/NewAntInput'

const dateFormat = 'MM/DD/YYYY h:mm A'

/**
 * @typedef {object} PropsModel
 * @prop {StopPoint} editData
 *
 * @extends {Component<PropsModel>}
 */
class ModalAddStop extends Component {
  /**
   *
   * @param {PropsModel} props
   */
  constructor(props) {
    super(props)

    this.state = {
      form: {
        title: props.editData ? props.editData.title : null,
        address:
          props.editData && props.editData.address
            ? props.editData.address.address
            : null,
        waittime: props.editData ? props.editData.waittime : null,
        position: props.editData ? props.editData.position : null,
        time: props.editData ? props.editData.osrmTime : null,
      },
      formErrors: {
        title: false,
        address: false,
      },
      formEmpty: {
        title: props.editData ? false : true,
        address: props.editData ? false : true,
      },
    }
  }

  disabledDate = (current, id) => {
    return current && current < moment().subtract(1, 'days')
  }

  createPoint = () => {
    const { form, formErrors, formEmpty } = this.state
    let isError = false

    Object.keys(formEmpty).forEach((type) => {
      if (formEmpty[type]) {
        formErrors[type] = true
        isError = true
      }
    })

    if (isError) {
      this.setState({
        formErrors: formErrors,
      })

      return
    }

    const request = new CreatePointRequest(
      form.time,
      form.waittime,
      form.address,
      StopPointType.Stop,
      form.position,
      form.title
    )

    this.props.createPointFn(request)
    this.props.close()
  }

  saveStop = () => {
    const { form, formErrors, formEmpty } = this.state
    let isError = false

    Object.keys(formEmpty).forEach((type) => {
      if (formEmpty[type]) {
        formErrors[type] = true
        isError = true
      }
    })

    if (isError) {
      this.setState({
        formErrors: formErrors,
      })

      return
    }

    /** @type {StopPoint} */
    const point = {
      id: this.props.editData.id,
      type: StopPointType.Stop,
      position: form.position,
      address: form.address,
      address_id: this.props.editData.address_id,
      address_link_id: this.props.editData.address_link_id,
      waittime: form.waittime,
      time: form.time,
      title: form.title,
      curbside_pickup_only: this.props.editData.curbside_pickup_only,
      use_default_rule: this.props.editData.use_default_rule,
      fieldTripRiders: this.props.editData.fieldTripRiders,
      fieldTripRidersAdults: this.props.editData.fieldTripRiderAdults,
      isDeleted: this.props.editData.isDeleted,
    }

    this.props.editPointFn(
      point,
      form.address !== this.props.editData.address?.address
    )
    this.props.close()
  }

  handleChange = (type, value) => {
    const form = this.state.form

    if (type === 'waittime') {
      value = value * 60
    }

    form[type] = value

    this.setState({
      form: form,
    })

    if (this.state.formErrors.hasOwnProperty(type)) {
      if (!value || value === '') {
        let errors = this.state.formErrors
        errors[type] = true

        let empty = this.state.formEmpty
        empty[type] = true

        this.setState({
          formErrors: errors,
          formEmpty: empty,
        })
      } else {
        let errors = this.state.formErrors
        errors[type] = false

        let empty = this.state.formEmpty
        empty[type] = false

        this.setState({
          formErrors: errors,
          formEmpty: empty,
        })
      }
    }
  }

  render() {
    let { close } = this.props
    let { form, formErrors } = this.state

    return (
      <BaseModal
        title={this.props.editData ? 'Edit a Bus Stop' : 'Add a New Bus Stop'}
        save={this.props.editData ? this.saveStop : this.createPoint}
        class="add-point-modal"
        closeFn={close}
      >
        <div className="line" style={{ flexWrap: 'wrap' }}>
          <span className={formErrors.name ? 'error' : ''}>Bus Stop Name</span>
          <NewAntInput
            className={formErrors.name ? 'error' : ''}
            value={form.title}
            onChange={(e) => this.handleChange('title', e.target.value)}
            placeholder="Name a New Bus Stop"
          />
        </div>

        <div className="line" style={{ flexWrap: 'wrap' }}>
          <span className={formErrors.address ? 'error' : ''}>Address</span>
          <NewAntInput
            autoFocus={true}
            className={formErrors.address ? 'error' : ''}
            value={form.address}
            onChange={(e) => this.handleChange('address', e.target.value)}
            placeholder="Ex: 5th Ave & E 8 Street, New York, 10011"
          />
        </div>

        <div className="line flex">
          <div
            className="third"
            style={{ paddingRight: '10px', position: 'relative' }}
          >
            <span className={formErrors.time ? 'error' : ''}>
              Date and Time
            </span>

            <span className={form['time'] ? 'timeNotEmpty' : 'timeEmpty'}>
              <NewSingleDatePicker
                getCalendarContainer={(trigger) => trigger.parentNode}
                id="DatePicker"
                disabledDate={this.disabledDate}
                className={formErrors.time ? 'error' : ''}
                value={form.time ? moment(form.time * 1000) : null}
                showTime={{
                  defaultValue: moment('00:00:00', 'HH:mm'),
                  use12Hours: true,
                  format: 'hh:mm A',
                }}
                format={dateFormat}
                onChange={(e) =>
                  this.handleChange('time', e ? e.unix(Number) : null)
                }
                suffixIcon={<CalendarIcon />}
                clearIcon={<ClearIcon />}
                divider
              />
            </span>
          </div>

          <div className="third" style={{ paddingRight: '10px' }}>
            <span>Wait Time for This Stop</span>
            <NewAntInput
              onChange={(e) => this.handleChange('waittime', e.target.value)}
              value={form.waittime / 60}
              placeholder="In Minutes"
            />
          </div>

          <div className="third">
            <span>Enter Stop Number</span>
            <NewAntInput
              onChange={(e) => this.handleChange('position', e.target.value)}
              value={form.position}
              placeholder="Last on the List"
            />
          </div>
        </div>

        <div className="center">
          <div className="buttons">
            <button className="cancel" onClick={close}>
              Cancel
            </button>
            <button
              className="save"
              onClick={this.props.editData ? this.saveStop : this.createPoint}
            >
              Save
            </button>
          </div>
        </div>
      </BaseModal>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddStop)
