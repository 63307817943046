import { AdditionalToggle } from '@/entities/ui/AdditionalToggle/AdditionalToggle'
import React, { useCallback, useEffect } from 'react'
import styles from './CloneRunModal.module.less'
import { ErrorAlert } from '@/entities/modals/CloneRunModal/components/ErrorAlert/ErrorAlert'
import { options } from '@/entities/modals/CloneRunModal/constants'
import { Settings } from '@/entities/modals/CloneRunModal/components/Settings/Settings'
import { RunInfo } from '@/entities/modals/CloneRunModal/components/RunInfo/RunInfo'
import { FormProvider, useForm } from 'react-hook-form'
import { ModalFooter } from '@/entities/ui/NewModal/components/ModalFooter'
import moment from 'moment/moment'
import { momentTzHelper } from '@/helpers/momentTz'
import { ICloneRunParams } from '@/store/routeTool/routeTool.types'
import { useRunInfo } from '@/entities/modals/CloneRunModal/components/RunInfo/useRunInfo'
import { useCloneRunMutation } from '@/store/routeTool/routeTool'
import { useAppSelector } from '@/redux'
import { useSelector } from 'react-redux'
import { getChildrenList } from '@/redux/children/selectors'
import { useCloneRunStore } from '@/entities/modals/CloneRunModal/store'

export type TCloneTypes = 'reverse' | 'mirror' | 'repeat' | 'base'

const reverseTypes = {
  reverse: 1,
  mirror: 2,
  repeat: 0,
}

export const CloneRunModal = ({
  runId,
  runName,
  time_zone,
}: {
  runId: number
  runName?: string
  time_zone?: string
}) => {
  const [onClone, cloneResponse] = useCloneRunMutation()
  const methods = useForm({
    defaultValues: {
      anchor: 0,
      name: '',
    },
  })
  //
  const childrenList = useSelector(getChildrenList)

  const currentRun = useAppSelector((state) =>
    state?.routesView?.routes?.[0]?.routes?.find((route) => route.id === runId)
  )

  const getRiderFromList = useCallback(
    (child_id): any => {
      return childrenList.find((rider) => rider.id === child_id)
    },
    [childrenList]
  )

  useEffect(() => {
    const setCloneRunStore = useCloneRunStore.getState().setStore

    if (currentRun || childrenList) {
      setCloneRunStore({
        getRiderFromList,
        currentRun,
        currentRunType: currentRun?.is_morning === 1 ? 'PU' : 'DO',
      })
    }
  }, [currentRun, childrenList, getRiderFromList])

  //
  const onSubmit = (data) => {
    const { anchor, name } = data
    let _anchor = anchor
    const time = moment().unix()
    if (_anchor) {
      _anchor = anchor.utc(true).unix()
    }
    let localUTCOffset = (momentTzHelper()(time * 1000, time_zone) as any)
      ._offset
    if (localUTCOffset < 0) {
      localUTCOffset = Math.abs(localUTCOffset)
      _anchor = _anchor + localUTCOffset * 60
      _anchor = moment(_anchor * 1000)
        .utc(false)
        .format('HH:mm')
    }

    const cloneVariant = useCloneRunStore.getState().cloneVariant
    const result: ICloneRunParams = {
      id: runId,
      name,
      reverse: reverseTypes[cloneVariant],
      anchor: _anchor,
    }
    onClone(result)
  }

  const cloneVariant = useCloneRunStore((state) => state.cloneVariant)

  const runInfoBaseProps = useRunInfo({
    type: 'base',
  })

  const runInfoProps = useRunInfo({
    type: cloneVariant,
  })
  useEffect(() => {
    return () => {
      useCloneRunStore.getState().setStore({ cloneVariant: 'reverse' })
    }
  }, [])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <header className={styles.header}>
          <p className={styles.headerTitle}>Clone Run</p>
          <AdditionalToggle width={95} options={options} />
        </header>
        <Settings runName={runName} />
        <ErrorAlert />
        <div className={styles.infoWrapper}>
          <RunInfo {...runInfoBaseProps} />
          <RunInfo {...runInfoProps} />
        </div>
        <ModalFooter
          success={{
            text: 'Clone',
            isLoading: cloneResponse.isLoading,
            isSubmitForm: true,
          }}
        />
      </form>
    </FormProvider>
  )
}
