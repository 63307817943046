export const PrintIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M3 11h7V9H3v2zm0-5h7V3H8.75a.723.723 0 0 1-.531-.219A.723.723 0 0 1 8 2.25V1H3v5zm8.852.852A.48.48 0 0 0 12 6.5a.48.48 0 0 0-.148-.352A.48.48 0 0 0 11.5 6a.48.48 0 0 0-.352.148A.48.48 0 0 0 11 6.5c0 .135.05.253.148.352A.48.48 0 0 0 11.5 7a.48.48 0 0 0 .352-.148zM13 6.5v3.25a.24.24 0 0 1-.074.176.24.24 0 0 1-.176.074H11v1.25a.723.723 0 0 1-.219.531.723.723 0 0 1-.531.219h-7.5a.723.723 0 0 1-.531-.219A.723.723 0 0 1 2 11.25V10H.25a.24.24 0 0 1-.176-.074A.24.24 0 0 1 0 9.75V6.5c0-.411.147-.764.441-1.059A1.444 1.444 0 0 1 1.5 5H2V.75c0-.208.073-.385.219-.531A.723.723 0 0 1 2.75 0H8c.208 0 .437.052.688.156.25.104.447.23.593.375L10.47 1.72c.146.146.27.343.375.593.104.25.156.48.156.688v2h.5c.411 0 .764.147 1.059.441.294.295.441.648.441 1.059z"
      />
    </svg>
  )
}
