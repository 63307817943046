import React, { useEffect } from 'react'
import './index.less'
import moment from 'moment'
import axios from 'axios'
import message from 'antd/es/message'
import Upload from 'antd/es/upload'
import _ from 'lodash'
import api from '../../../../redux/apiConfig'
import { useMap } from '../../../../components/MapDefault/MapContext'
import { useSelector } from 'react-redux'
import { getCompanyOptionsRequests } from '../../../../redux/auth/selectors'
import { addressWithOptions } from '../../../../helpers/url'

const run = api.run

export default function ({
  activeID,
  addZone,
  loadZones,
  plotCb,
  userPermissions,
  isLoading,
}) {
  const companyOptions = useSelector(getCompanyOptionsRequests)
  const { setDrawTool, selectedToolType, setSelectedTool, drawTool } = useMap()

  useEffect(() => {
    if (!drawTool) setSelectedTool(null)
  }, [drawTool])

  function activeEl(type) {
    if (activeID || (!activeID && type === 'polyline')) {
      setSelectedTool(type)
      setDrawTool(type)
    }
  }

  const writeZoneTool =
    userPermissions && userPermissions['write_zone_tool'] === 1

  const upLoad = {
    multiple: false,
    showUploadList: false,
    action: (file) => {
      const formData = new FormData()
      const date = moment().format('MM/DD/YYYY')

      formData.append('file', file)
      formData.append('name', `file-${date}`)

      axios
        .post('shapes/upload', formData)
        .then((response) => loadZones(response.data))
        .catch(() => {
          message.error('upload failed.')
        })
    },
  }

  function _plotAddress(address) {
    const encodedPlotAddress = encodeURIComponent(address)
    if (address.length > 4) {
      run
        .get(
          `/routes/geocode?address=${addressWithOptions(
            encodedPlotAddress,
            companyOptions && companyOptions.geocode_provider
          )}`
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.lat) {
              plotCb(response.data)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    if (!address) {
      plotCb()
    }
  }

  const plotAddressDeb = _.debounce(_plotAddress, 600)

  let blockButton = !activeID

  return (
    <div className={`shapeBtnsLine ${isLoading ? 'disabled-zone' : ''}`}>
      <input
        type="text"
        className={'plotAddresss zone-tool'}
        onChange={(e) => plotAddressDeb(e.target.value)}
        placeholder="Plot Address on Map"
        disabled={isLoading}
      />

      <div
        className={`ruler ${
          selectedToolType === 'polyline' ? 'active' : ''
        } zone-tool`}
        onClick={() => !isLoading && activeEl('polyline')}
      />

      <div
        className={`zone-tool rectangle  ${
          blockButton ? ' blockButton ' : ''
        }  ${selectedToolType === 'rectangle' ? 'active' : ''}`}
        onClick={() => !isLoading && activeEl('rectangle')}
      />

      <div
        className={`zone-tool free-form ${blockButton ? 'blockButton' : ''} ${
          selectedToolType === 'polygon' ? 'active' : ''
        }`}
        onClick={() => !isLoading && activeEl('polygon')}
      />

      <div
        className={`zone-tool oval ${blockButton ? 'blockButton' : ''}  ${
          selectedToolType === 'oval' ? 'active' : ''
        }`}
        onClick={() => !isLoading && activeEl('oval')}
      />

      <div
        className={`zone-tool radius ${blockButton ? 'blockButton' : ''} ${
          selectedToolType === 'radius' ? 'active' : ''
        }`}
        onClick={() => !isLoading && activeEl('radius')}
      />

      {writeZoneTool && (
        <Upload {...upLoad} style={{ display: `flex` }} disabled={isLoading}>
          <div className="zone-tool loadZone">Load Zones</div>
        </Upload>
      )}

      {writeZoneTool && (
        <div className="zone-tool btnNewZone" onClick={addZone}>
          + Zone
        </div>
      )}
    </div>
  )
}
