import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { axiosBaseQuery } from '@/helpers/axiosBaseQuery'
import api from '@/redux/apiConfig'
import { notification } from 'antd'
import { IEmailTemplate } from '@/store/routeTool/RouteToolTemplateApi/RouteToolTemplateApi.types'
import { useParentState } from '@/entities/modals/ParentEmailModal/components/variants/NewVariant/useNewVariantParentEmailModalConfig'
import { store } from '@/redux'

export const routeToolTemplateApi = createApi({
  reducerPath: 'routeToolTemplateApi',
  baseQuery: axiosBaseQuery(api.common),
  tagTypes: ['templates-list'],
  endpoints: (build) => ({
    getEmailTemplates: build.query<{ data: IEmailTemplate[] }, void>({
      keepUnusedDataFor: 0,
      providesTags: ['templates-list'],
      query: () => ({
        url: '../api/v2/company/email-text-template/index',
      }),
      transformResponse: (response: { data: IEmailTemplate[] }, meta) => {
        return {
          ...response,
          data: response?.data.sort((a, b) => a.title.localeCompare(b.title)),
        }
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const response = await queryFulfilled

          const firstTemplate = response?.data?.data?.[0]
          if (firstTemplate) {
            const setState = useParentState.getState().setStore
            setState({ currentTemplate: firstTemplate })
          }
        } catch (e) {
          //
        }
      },
    }),
    createEmailTemplate: build.mutation<
      { data: IEmailTemplate },
      { title: string; text?: string }
    >({
      query: (params) => ({
        url: '../api/v2/company/email-text-template/create',
        method: 'post',
        data: params,
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled
          dispatch(
            routeToolTemplateApi.util.updateQueryData(
              'getEmailTemplates',
              undefined,
              (state) => {
                state.data = [...state.data, response.data?.data]
              }
            )
          )
          notification.success({
            message: 'Template has been created successfully',
          })
        } catch (e) {
          notification.error({
            message: 'An error occurred, the template could not be created',
          })
        }
      },
    }),
    updateEmailTemplate: build.mutation<
      { data: IEmailTemplate },
      { id: number; title: string; text?: string }
    >({
      query: ({ id, ...rest }) => ({
        url: `../api/v2/company/email-text-template/update/${id}`,
        method: 'put',
        data: rest,
      }),
      onQueryStarted: async ({ id }, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled

          const state = useParentState.getState()
          if (state.currentTemplate.id === id) {
            state.setStore({
              currentTemplate: {
                id: response?.data?.data?.id,
                text: response?.data?.data?.text,
                title: response?.data?.data?.title,
              },
            })
          }
          dispatch(
            routeToolTemplateApi.util.updateQueryData(
              'getEmailTemplates',
              undefined,
              (state) => {
                state.data = [...state.data].map((item) =>
                  id === item.id
                    ? { ...item, ...(response?.data?.data ?? {}) }
                    : item
                )
              }
            )
          )
          notification.success({
            message: 'Template has been update successfully',
          })
        } catch (e) {
          notification.error({
            message: 'An error occurred, the template could not be updated',
          })
        }
      },
    }),

    deleteEmailTemplate: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `../api/v2/company/email-text-template/delete/${id}`,
        method: 'delete',
      }),
      onQueryStarted: async ({ id }, { queryFulfilled, dispatch }) => {
        try {
          const templates =
            routeToolTemplateApi.endpoints.getEmailTemplates.select()(
              store.getState()
            )?.data?.data

          const state = useParentState.getState()

          if (state.currentTemplate.id === id) {
            const newCurrentTemplate = templates?.find((item) => item.id !== id)
            console.log(newCurrentTemplate)
            newCurrentTemplate &&
              state.setStore({ currentTemplate: newCurrentTemplate })
          }
          await queryFulfilled

          dispatch(
            routeToolTemplateApi.util.updateQueryData(
              'getEmailTemplates',
              undefined,
              (state) => {
                state.data = [...state.data].filter((item) => item.id !== id)
              }
            )
          )
          notification.success({
            message: 'Template has been delete successfully',
          })
        } catch (e) {
          notification.error({
            message: 'An error occurred, the template could not be delete',
          })
        }
      },
    }),
  }),
})

export const {
  useGetEmailTemplatesQuery,
  useDeleteEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
  useCreateEmailTemplateMutation,
} = routeToolTemplateApi
