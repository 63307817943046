import { DatePicker } from 'antd'
import { ModalFooter } from '@/entities/ui/NewModal/components/ModalFooter'
import styles from './MonthlyAttendanceModal.module.less'
import { useState } from 'react'
import moment from 'moment'
import { useLazyPrintMonthlyAttendanceQuery } from '@/store/viewRuns/viewRuns'
import classNames from 'classnames'

interface IProps {
  runIds?: number[]
}

export const MonthlyAttendanceModal = ({ runIds }: IProps) => {
  const [date, setDate] = useState(moment())
  const [request, response] = useLazyPrintMonthlyAttendanceQuery()

  const handlePrint = () => {
    request({ timestamp: date.set({ day: 5 }).unix(), runIds })
  }

  return (
    <div>
      <div className={classNames(styles.root)}>
        <DatePicker
          popupClassName="montly-attendance-report"
          value={date}
          onChange={(e) => {
            setDate(e)
          }}
          picker="month"
        />
      </div>

      <ModalFooter
        success={{
          action: handlePrint,
          text: 'Print',
          isLoading: response.isFetching,
        }}
      />
    </div>
  )
}
