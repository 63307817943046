import React from 'react'

export const TimeIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        {...props}
        fill="currentColor"
        fillRule="nonzero"
        d="M9 5.036v4.5c0 .093-.03.17-.09.23a.313.313 0 0 1-.231.091H5.464a.313.313 0 0 1-.23-.09.313.313 0 0 1-.091-.231v-.643c0-.094.03-.17.09-.231.06-.06.138-.09.231-.09h2.25V5.035c0-.094.03-.171.09-.231.061-.06.138-.09.232-.09h.643c.093 0 .17.03.23.09s.091.137.091.23zm3.445 6.278a5.341 5.341 0 0 0 .734-2.743c0-.99-.245-1.905-.734-2.742a5.447 5.447 0 0 0-1.989-1.989 5.341 5.341 0 0 0-2.742-.733c-.99 0-1.905.245-2.742.733a5.447 5.447 0 0 0-1.989 1.99A5.341 5.341 0 0 0 2.25 8.57c0 .992.244 1.906.733 2.743a5.447 5.447 0 0 0 1.99 1.988 5.341 5.341 0 0 0 2.741.734c.991 0 1.905-.245 2.742-.734a5.447 5.447 0 0 0 1.99-1.988zm1.949-6.61a7.52 7.52 0 0 1 1.035 3.867c0 1.4-.345 2.69-1.035 3.873a7.68 7.68 0 0 1-2.808 2.807 7.546 7.546 0 0 1-3.872 1.035c-1.4 0-2.69-.345-3.872-1.035a7.68 7.68 0 0 1-2.807-2.807A7.546 7.546 0 0 1 0 8.57c0-1.4.345-2.69 1.035-3.872a7.68 7.68 0 0 1 2.807-2.807A7.546 7.546 0 0 1 7.714.857c1.4 0 2.69.345 3.872 1.035a7.668 7.668 0 0 1 2.808 2.812z"
      />
    </svg>
  )
}
