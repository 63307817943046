import { commonApi } from '@/store/common/common'
import { useRootZustandStore } from '@/pages/FieldTrip/Attendance/V2Attendance/Attendance.store'
import { IRegistrationsOpenedStore } from '@/pages/FieldTrip/Registrations/tabs/Opened/store'
import { routeToolApi } from '@/store/routeTool/routeTool'
import { getPathname } from '@/shared/hooks/routeHooks/useRouter'
import { useRegistrationsStore } from '@/pages/FieldTrip/Registrations/registrationsStore'
import { routeLinks } from '@/shared/routeLinks'

export const decrementRegistrationCount = () => {
  const dispatch = useRootZustandStore.getState().dispatch
  dispatch(
    commonApi.util.updateQueryData('getCompanyCounters', undefined, (data) => {
      if (data?.data?.newParentPortalRegistrationRequests) {
        data.data.newParentPortalRegistrationRequests =
          data.data.newParentPortalRegistrationRequests - 1
      }
    })
  )
}

export const incrementRegistrationCount = () => {
  const dispatch = useRootZustandStore.getState().dispatch
  dispatch(
    commonApi.util.updateQueryData('getCompanyCounters', undefined, (data) => {
      data.data.newParentPortalRegistrationRequests =
        (data.data.newParentPortalRegistrationRequests ?? 0) + 1
    })
  )
}
export interface Props {
  set: (
    partial:
      | IRegistrationsOpenedStore
      | Partial<IRegistrationsOpenedStore>
      | ((state: IRegistrationsOpenedStore) => IRegistrationsOpenedStore),
    replace?: boolean
  ) => void
  get: () => IRegistrationsOpenedStore
  id: number
  status: number
  userBy?: string
  updatedAt?: number
}

export const editCurrentNewItem = ({
  set,
  get,
  id,
  status,
  userBy,
  updatedAt,
}: Props) => {
  const dispatch = useRootZustandStore.getState().dispatch
  const filter = get().filter

  decrementRegistrationCount()

  //если статус reject или accept, нужно:
  // 1) удалить этот интем из общего списка
  // 2) добавить offset +1
  // 3) уменьшить тотал из запроса что бы не забагался onNext в инфинитискроле
  // 4)

  if ([2, 3].includes(status)) {
    const filteredData = get().items.filter((item) => item.id !== id)
    // 3 пункт
    dispatch(
      routeToolApi.util.updateQueryData(
        'getRegistrationsOpened',
        filter,
        (data) => {
          const resultTotal =
            Number(data.meta['x-pagination-total-count']) > 0
              ? Number(data.meta['x-pagination-total-count']) - 1
              : 0
          data.meta = {
            ...data.meta,
            ['x-pagination-total-count']: resultTotal,
          }

          data.data = [...(data?.data ?? [])].filter((item) => {
            return item.id !== id
          })
          //
        }
      )
    )
    // 1 и 2 пункт
    set({
      items: filteredData,
      filter: { ...filter, offset: filter.offset + 1 },
    })

    //если статус меняется на inReview
    // 1) меняем статус в текущем списке без удаления
  } else if (status === 4) {
    set({
      items: get().items.map((item) =>
        item.id === id
          ? { ...item, status: 4, updatedBy: userBy, updatedAt: updatedAt }
          : item
      ),
    })
    //
  }
}

export const editCurrentInReviewItem = ({ set, get, id }: Props) => {
  const dispatch = useRootZustandStore.getState().dispatch
  const filter = get().filter

  //обработка кейса если был изменен статус на reject или accept
  // 1) удалить этот интем из общего списка
  // 2) добавить offset +1
  // 3) уменьшить тотал из запроса что бы не забагался onNext в инфинитискроле
  // 4) показать кнопку с рефрешем если пользователь находится на табе closed и появился новый итем

  const filteredData = get().items.filter((item) => item.id !== id)
  // 3 пункт
  dispatch(
    routeToolApi.util.updateQueryData(
      'getRegistrationsOpened',
      filter,
      (data) => {
        const resultTotal =
          Number(data.meta['x-pagination-total-count']) > 0
            ? Number(data.meta['x-pagination-total-count']) - 1
            : 0
        data.meta = {
          ...data.meta,
          ['x-pagination-total-count']: resultTotal,
        }

        data.data = [...(data?.data ?? [])].filter((item) => {
          return item.id !== id
        })
        //
      }
    )
  )
  // 1 и 2 пункт
  set({
    items: filteredData,
    filter: { ...filter, offset: filter.offset + 1 },
  })

  //4
  const pathname = getPathname()
  const activeTab = useRegistrationsStore.getState().tab

  console.log(pathname, activeTab, id)
  if (pathname === routeLinks.registrations && activeTab === 'closed') {
    useRegistrationsStore.getState().setShowRefresh(true)
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const afterCreateNewRegistration = (_: Pick<Props, 'set' | 'get'>) => {
  // 1) увеличение счетчика в меню на +1
  // 2) если бользователь находится на странице parentRegistrations и на табе opened:
  //   - показываем кнопку , нажав на которую мы обновим список в таблице

  //1
  incrementRegistrationCount()

  const pathname = getPathname()
  const activeTab = useRegistrationsStore.getState().tab

  if (pathname === routeLinks.registrations && activeTab === 'opened') {
    useRegistrationsStore.getState().setShowRefresh(true)
  }

  //2
}
