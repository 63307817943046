import { ICommentMessage } from '@/store/routeTool/routeTool.types'
import styles from './CommentBlock.module.less'
import { format } from 'date-fns'

export const CommentBlock = ({
  author,
  createdAt,
  text,
  className,
}: ICommentMessage) => {
  return (
    <article className={cn(styles.commentBlock, className)}>
      <div className={styles.topLine}>
        <p className={styles.commentAuthor}>{author}</p>
        <p className={styles.commentTime}>
          {format(createdAt * 1000, 'h:mm a')}
        </p>
      </div>
      <span className={styles.commentText}>{text}</span>
    </article>
  )
}
