export function addressChard(address) {
  let { period_skip, period_type, period_value } = address

  if (period_type === 1) {
    if (period_skip > 1) {
      return 'Every ' + period_skip + ' Days'
    }
    return 'Every Day'
  }
  if (period_type === 2) {
    let temp = 'Every Week'
    if (period_skip > 1) {
      temp = 'Every ' + period_skip + ' Weeks'
    }
    const week_days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
    let period_values = period_value.split(',').map((value) => {
      return week_days[value] ? week_days[value] : ''
    })
    temp += ' on ' + period_values.join(', ')
    return temp
  }
  //TODO: Make things running for monthly case
  return 'Every Month'
}

export function addressChard2(address) {
  if (
    !address.calendar ||
    !address.calendar[0] ||
    !address.calendar[0].recurrence
  ) {
    //console.error("ERROR ADDRESS CALENDAR");
    return
  }

  let { period_skip, period_type, recurrenceValues } =
    address.calendar[0].recurrence

  if (period_type === 1 && period_skip) {
    if (period_skip > 1) {
      return 'Every ' + period_skip + ' Days'
    }
    return 'Every Day'
  }
  if (period_type === 2) {
    let temp = 'Every Week'
    if (period_skip > 1) {
      temp = 'Every ' + period_skip + ' Weeks'
    }
    const week_days = ['', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

    let sortList = recurrenceValues.sort((x, a) => {
      return x.value * 1 - a.value * 1
    })
    let period_values = sortList.map((x) => {
      return week_days[x.value]
    })
    temp += ' on ' + period_values.join(', ')
    return temp
  }
  if (period_type === 3) {
    let temp = 'Every Month'
    if (period_skip > 1) {
      temp = 'Every ' + period_skip + ' Month'
    }

    //когда дни не указаны
    if (
      recurrenceValues &&
      Array.isArray(recurrenceValues) &&
      !recurrenceValues.length
    )
      return temp + ' (not set Days)'

    recurrenceValues = recurrenceValues.sort((a, b) => {
      if (parseInt(a.value) > parseInt(b.value)) return 1
      if (parseInt(a.value) < parseInt(b.value)) return -1
    })

    let period_values = recurrenceValues.map((value) => {
      return ordinal_suffix_of(value.value)
    })
    temp += ' on the ' + period_values.join(', ') + ' Day(s)'
    return temp
  }
  //TODO: Make things running for monthly case
  return 'Every Month'
}

function ordinal_suffix_of(i) {
  let j = i % 10
  let k = i % 100
  if (j === 1 && k !== 11) {
    return i + 'st'
  }
  if (j === 2 && k !== 12) {
    return i + 'nd'
  }
  if (j === 3 && k !== 13) {
    return i + 'rd'
  }
  return i + 'th'
}
