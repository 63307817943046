import { ModalsEnum, useModalSelector } from '@/store/modal/ModalStore'
import { NewModal } from '@/entities/ui/NewModal/NewModal'
import moment from 'moment/moment'
import { IRegistrationItem } from '@/store/routeTool/routeTool.types'
import { useEffect, useMemo } from 'react'
import { MainScreen } from '@/entities/modals/RouteToolRegistrations/RTRegistrationsOpenedModal/screens/MainScreen/MainScreen'
import { RejectScreen } from '@/entities/modals/RouteToolRegistrations/RTRegistrationsOpenedModal/screens/RejectScreen/RejectScreen'
import { useRTRegistrationsStore } from '@/entities/modals/RouteToolRegistrations/RTRegistrationsOpenedModal/store'
import { ManualAcceptScreen } from '@/entities/modals/RouteToolRegistrations/RTRegistrationsOpenedModal/screens/ManualAcceptScreen/ManualAcceptScreen'
import { AutoAcceptScreen } from '@/entities/modals/RouteToolRegistrations/RTRegistrationsOpenedModal/screens/AutoAcceptScreen/AutoAcceptScreen'
import { useLazyRegistrationOpenQuery } from '@/store/routeTool/routeTool'
import styles from './RTRegistrationsOpenedModal.module.less'
import { statuses } from '@/pages/FieldTrip/Registrations/constants'
import classnames from 'classnames'

export const RTRegistrationsOpenedModal = () => {
  const { isOpen, onCloseModal, state } = useModalSelector(
    ModalsEnum.RT_REGISTRATIONS_OPENED_MODAL
  )
  return (
    <>
      {isOpen ? (
        <_RTRegistrationsOpenedModal
          record={state.item}
          isOpen={isOpen}
          onCloseModal={onCloseModal}
        />
      ) : (
        <></>
      )}
    </>
  )
}

const _RTRegistrationsOpenedModal = ({ record, isOpen, onCloseModal }) => {
  const screen = useRTRegistrationsStore((state) => state.screen)
  const [registationOpen] = useLazyRegistrationOpenQuery()

  const title = useMemo(() => {
    if (screen === 'main') {
      return (
        <div className={styles.title}>
          <p>
            Parent registration request{' '}
            {moment(record?.createdAt * 1000).format('MM/DD/YYYY hh:mm A')}
          </p>
          {[2, 3].includes(record.status) && (
            <p
              className={classnames(
                styles.subtitle,
                styles[statuses?.[record?.status]]
              )}
            >
              {statuses?.[record?.status] ?? ''}
            </p>
          )}
        </div>
      )
    } else if (screen === 'reject') {
      return 'Reject'
    } else if (screen === 'autoAccept') {
      return 'Accept'
    } else if (screen === 'manualAccept') {
      return 'Conditionally accepted'
    }
  }, [record?.createdAt, record.status, screen])

  useEffect(() => {
    if (record.status === 1 && record?.id) {
      registationOpen(record.id)
    }
  }, [registationOpen, record.id, record.status])

  useEffect(() => {
    return () => {
      useRTRegistrationsStore.getState().setScreen('main')
    }
  }, [])

  return (
    <NewModal width={560} title={title} open={isOpen} onClose={onCloseModal}>
      <GuardianBlock {...record} />
    </NewModal>
  )
}

const GuardianBlock = (item: IRegistrationItem) => {
  const screen = useRTRegistrationsStore((state) => state.screen)

  return (
    <section>
      {screen === 'main' && <MainScreen {...item} />}
      {screen === 'reject' && <RejectScreen {...item} />}
      {screen === 'manualAccept' && <ManualAcceptScreen {...item} />}
      {screen === 'autoAccept' && <AutoAcceptScreen {...item} />}
    </section>
  )
}
