import React from 'react'
import { useSelector } from 'react-redux'
import { getGpsRuns } from '@/redux/gps/selectors'
import { getLoaderState } from '@/redux/common/selectors'
import { useGpsDetailsStore } from '@/pages/Gps/NewGpsDetails/store'
import { browserHistory } from 'react-router'
import { NewSelect } from '@/entities/ui/NewSelect/NewSelect'

export const RunsSelect = () => {
  const runs = useSelector(getGpsRuns)

  const run = useGpsDetailsStore.getState().gpsInfo
  const isLoading = useSelector(getLoaderState) === 'loadingStart'

  const onChange = (id) => {
    if (isLoading || id === run.id) {
      return
    }
    const runTimestamp = runs.find((r) => r.id === id)?.actualStartTimestamp
    runTimestamp &&
      browserHistory.push({ pathname: `/client/gps/${id}/${runTimestamp}` })
  }

  const options = [...runs.map((r) => ({ value: r.id, label: r.title }))]

  return (
    <NewSelect
      style={{ width: 283 }}
      showSearch
      placeholder="Showing all runs"
      optionFilterProp="children"
      onChange={onChange}
      value={run.runName}
      disabled={false}
      options={options}
    />
  )
}
