import React, { PureComponent } from 'react'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Checkbox from 'antd/es/checkbox'

import { getValid } from '../../../../../../../components/validPhone/index'
import { NewCheckbox } from '../../../../../../../entities/ui/NewCheckbox/NewCheckbox'

class RiderElement extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isChecked: props.isChecked,
    }
  }

  handleCheck = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    })

    this.props.checkUserFn(this.props.RiderElement.id)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.isChecked !== nextProps.isChecked) {
      this.setState({
        isChecked: nextProps.isChecked,
      })
    }
  }

  render() {
    return (
      <div
        key={this.props.RiderElement.id}
        style={{ borderBottom: '1px solid #979797', paddingBottom: '9px' }}
      >
        <Row>
          <Col
            span={1}
            style={{ paddingTop: this.props.rowIndex > 0 ? '17px' : '0px' }}
          >
            <NewCheckbox
              checked={this.state.isChecked}
              className={'UnassignedCheckbox checkbox checkbox-orange'}
              onChange={this.handleCheck}
            />
          </Col>
          <Col
            span={23}
            style={{ paddingTop: this.props.rowIndex > 0 ? '17px' : '0px' }}
          >
            <span className="Title displayInline">
              {this.props.RiderElement.full_name}
              <span className="specialN">
                {this.props.RiderElement.special_instructions}
              </span>
            </span>
          </Col>
        </Row>

        {this.props.RiderElement.classs && (
          <Row>
            <Col span={24}>
              <span className="SubTitle" style={{ marginLeft: '26px' }}>
                <b>{this.props.RiderElement.classs.replace('th', '')}th</b>
                <span className="Gray">Grade</span>
              </span>
            </Col>
          </Row>
        )}

        {this.props.RiderElement.school_name && (
          <Row style={{ width: '100%', paddingTop: '2px' }}>
            <Col span={3}>
              <span className="School" />
            </Col>
            <Col span={21}>
              <span className="SchoolName">
                {!this.props.RiderElement.school_name
                  ? 'empty'
                  : this.props.RiderElement.school_name}
              </span>
              <span className="Date" />
            </Col>
          </Row>
        )}

        <Row style={{ width: '100%', paddingTop: '2px' }}>
          <Col span={3}>
            {this.props.RiderElement.phone && <span className="phone" />}
          </Col>
          <Col span={21} className="flexColumn">
            {this.props.RiderElement.phone && (
              <span className="phoneNumber">
                {getValid(this.props.RiderElement.phone, '+1 ### ###-##-##')}
              </span>
            )}
            {this.props.RiderElement.parent_first_name && (
              <span className="smallText">
                {this.props.RiderElement.parent_first_name +
                  ' ' +
                  this.props.RiderElement.parent_last_name}
              </span>
            )}
          </Col>
        </Row>
      </div>
    )
  }
}

export default RiderElement
