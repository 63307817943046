import styles from './DeleteSessionsModal.module.less'
import { FC } from 'react'
import { ModalFooter } from '@/entities/ui/NewModal/components/ModalFooter'
import {
  sessionsSelector,
  useDeleteSessionMutation,
} from '@/store/session/sessionApi'
import { useSessionsStore } from '@/pages/Sessions/store/store'
import { useAppSelector } from '@/redux'

interface IProps {
  ids: number[]
}
export const DeleteSessionsModal: FC<IProps> = ({ ids }) => {
  const seasons = useAppSelector(sessionsSelector)

  const [deleteSession, deleteSessionsResponse] = useDeleteSessionMutation({
    fixedCacheKey: 'delete-session-group',
  })
  const selected = useSessionsStore((state) => state.filter.selected)

  const sessionName =
    seasons.find((session) => session.id === ids[0])?.title ?? ''

  const onSuccess = () => {
    deleteSession(selected)
  }

  return (
    <div>
      <p className={styles.title}>
        Are you sure you want to remove{' '}
        <b>{ids.length === 1 ? sessionName : ids.length + ' sessions'}</b>
      </p>
      <ModalFooter
        success={{
          action: onSuccess,
          text: 'Delete',
          isLoading: deleteSessionsResponse.isLoading,
        }}
      />
    </div>
  )
}
