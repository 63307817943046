import React, { memo } from 'react'
import { useRunPointParams } from '@/pages/Gps/NewGpsDetails/store'
import { Marker } from 'react-leaflet'
import { getIcon } from '@/components/MapDefault/mapSpecialFns'
import {
  handleMouseOut,
  handleMouseOver,
  getRouteMarkerType,
} from '../../helpers/common'
import { StopPopup } from '@/pages/Gps/NewGpsDetails/components/StopPopup'
import { Icon } from 'leaflet'

export const StopsPins = memo(() => {
  const { gpsPoints } = useRunPointParams()

  return (
    <>
      {gpsPoints?.map((point, index) => (
        <Marker
          key={point.id}
          alt={JSON.stringify([
            ...new Set(point.riders.map((rider) => rider.fullName)),
          ])}
          position={[+point.geo.lat, +point.geo.lon]}
          icon={
            getIcon(
              getRouteMarkerType(point.stopType),
              Number(point.position)
            ) as Icon
          }
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          riseOnHover={true}
          index={index}
        >
          <StopPopup stop={point} />
        </Marker>
      ))}
    </>
  )
})
