import Input from 'antd/es/input'
import Select from '@/components/customSelect'
import { Trans } from 'react-i18next'
import React, { FC, useState } from 'react'
import { useAppSelector } from '@/redux'
import { ISeason } from '@/pages/Sessions/components/modals/AddNewSessionModal/useAddNewSessionConfig'
import { Brand } from '@/types/types'
import { selectSessionById } from '@/redux/sessions/selectors'
import styles from './AddSubSessionModal.module.less'
import { ModalsEnum, useModalSelector } from '@/store/modal/ModalStore'
import { formatDate } from '@/helpers/dates'
import { formatTitleFromWeeks } from '@/pages/Sessions/components/modals/AddSubSessionModal/formatTitleFromWeeks'
import { NewModal } from '@/entities/ui/NewModal/NewModal'
import { useAddSubSessionMutation } from '@/store/session/sessionApi'
import { ModalFooter } from '@/entities/ui/NewModal/components/ModalFooter'

export type SessionId = Brand<number, 'session_id'>
export type SessionWeekId = Brand<number, 'session_week_id'>

type IProps = {
  id: SessionId
  onClose: () => void
}

// export const AddSubSessionModal = () => {
//   const { onCloseModal, isOpen, state } = useModalSelector(
//     ModalsEnum.ADD_SUB_SESSION
//   )
//
//   return isOpen && !!state?.sessionId ? (
//     <_AddSubSessionModal id={state.sessionId} onClose={onCloseModal} />
//   ) : null
// }

export const AddSubSessionModal: FC<IProps> = ({ id }) => {
  const season: ISeason = useAppSelector(selectSessionById(id))

  const [createSubSession, { isLoading }] = useAddSubSessionMutation()

  const [title, setTitle] = useState('')
  const [errors, setErrors] = useState({ title: false, weeks: false })
  const [seasonWeekIds, setSeasonWeekIds] = useState<SessionWeekId[]>([])
  const onWeekSelect = (selectedWeeks: SessionWeekId[]) => {
    setTitle(formatTitleFromWeeks(selectedWeeks, season.seasonWeeks))
    setSeasonWeekIds(selectedWeeks)
    setErrors({ title: false, weeks: false })
  }

  const onTitleChange = (e) => {
    setTitle(e.target.value)
    setErrors({ ...errors, title: false })
  }

  const addSession = () => {
    if (!title || !seasonWeekIds.length) {
      setErrors({ title: !title, weeks: !seasonWeekIds.length })
      return false
    }
    createSubSession({ title, seasonWeekIds })
  }

  return (
    <div className={styles.newSessionModalSub}>
      <div className={styles.contentBaseSession}>
        <div className={styles.labelLine}>
          <label>Base Session</label>
        </div>

        <Input
          placeholder={'Base Session'}
          data-testid="baseSessionNameInp"
          readOnly={true}
          value={season?.title ?? ''}
        />

        <div>
          <label
            className={cn(styles.labelLine, {
              error: errors.weeks,
            })}
          >
            List of Session Weeks
          </label>
        </div>
        {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
        {/*@ts-expect-error*/}
        <Select
          data-testid="weekSelector"
          placeholder="Select Week"
          name="week_selector"
          onChange={onWeekSelect}
          style={{ width: 250, borderColor: errors.weeks ? 'red' : 'black' }}
          mode={'multiple'}
          status={errors.weeks && 'error'}
        >
          {season?.seasonWeeks?.map((w, i) => (
            <option value={w.id} key={w.id}>
              <Trans>{`#${++i} (${formatDate(w.start_date)} - ${formatDate(
                w.end_date
              )})`}</Trans>
            </option>
          ))}
        </Select>

        <div>
          <label
            className={cn(styles.labelLine, {
              error: errors.title,
            })}
          >
            Name New Sub-Session
          </label>
        </div>

        <Input
          data-testid="subSeasonName"
          placeholder={'Sample Session'}
          className={cn({ error: errors.title })}
          value={title}
          onChange={onTitleChange}
        />
      </div>
      <ModalFooter
        buttonWidth={80}
        success={{
          action: addSession,
          isLoading: isLoading,
          testId: 'saveSubSession',
          text: 'Save',
        }}
      />
    </div>
  )
}
