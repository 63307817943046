import React, { lazy, useEffect } from 'react'
import 'antd/dist/antd.variable.min.css'
import { ConfigProvider } from 'antd'
import i18n from './i18n'
import ru from 'antd/es/locale/ru_RU'
import en from 'antd/es/locale/en_US'
import { Provider, useDispatch } from 'react-redux'
import { getRoutes } from './getRoutes'
import { store } from './redux'
import { DSN_SENTRY } from './redux/configLocal'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { browserHistory, createRoutes, match } from 'react-router'
import { useRootZustandStore } from './pages/FieldTrip/Attendance/V2Attendance/Attendance.store'
import { clarity } from 'react-microsoft-clarity'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
lazy(() => import('react-leaflet'))

if (DSN_SENTRY.load) {
  Sentry.init({
    dsn: DSN_SENTRY.dsn,
    integrations: [
      new BrowserTracing({
        tracingOrigins: DSN_SENTRY.tracingOrigins,
        routingInstrumentation: Sentry.reactRouterV3Instrumentation(
          browserHistory as any,
          createRoutes(getRoutes()),
          match
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  })
}
ConfigProvider.config({ theme: {} })
export const App = () => {
  useEffect(() => {
    clarity.init('l9cwcdr54u')
  }, [])

  return (
    <ConfigProvider locale={i18n.language === 'ru' ? ru : en}>
      <Provider store={store}>
        <ZustandRoot />
        {getRoutes()}
      </Provider>
    </ConfigProvider>
  )
}

const ZustandRoot = () => {
  const setDispatch = useRootZustandStore((state) => state.setDispatch)
  const dispatch = useDispatch()

  useEffect(() => {
    setDispatch(dispatch)
  }, [dispatch])

  return null
}
