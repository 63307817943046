import React, { PureComponent } from 'react'
import Col from 'antd/es/col'
import Row from 'antd/es/row'
import { addressChard2 } from '@/components/RepeatFunctions/addressFrequency'
import { showAddress } from '@/redux/children/actions'
import { connect } from 'react-redux'
import Popover from 'antd/es/popover'
import ErrorModal from './ErrorModal'
import ErrorModalCapacity from './ErrorModalCapacity'
import capacityCheck from './capacityCheck'

class RiderAddress extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hovered: false,
      visiblePopup: false,
      showWarning: false,
      isLoading: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loader !== this.props.loader) {
      this.setState({
        isLoading: this.props.loader === 'loadingStart',
      })
    }
  }

  toggle = (e) => {
    if (e.target.className === 'NoEye') {
      e.target.className = 'Eye'
      this.props.dispatch(
        showAddress(this.props.address.child_id, this.props.address.id)
      )
      return false
    }

    if (e.target.className === 'Eye') {
      e.target.className = 'NoEye'
      this.props.dispatch(
        showAddress(this.props.address.child_id, this.props.address.id)
      )
      return false
    }
  }

  addressModal = () => {
    this.props.addressModal(this.props.address)
  }

  getTypeRun = () => {
    if (!this.props.activeRun) return

    let route
    this.props.routes.forEach((r) => {
      r.routes.forEach((x) => {
        if (x.id === this.props.activeRun) route = x
      })
    })

    return route.is_morning
  }

  riderInRun = (checkCapacity = true) => {
    if (!this.props.activeRun) return false

    let { buses, routes, activeRun } = this.props

    let conf = {
      buses: buses,
      routes: routes,
      activeRun: activeRun,
      addCount: 1,
    }

    let difference = capacityCheck(conf)

    if (!checkCapacity) {
      this.setState({
        showWarningCapacity: false,
      })
    }

    if (checkCapacity && difference < 0) {
      this.setState({
        showWarningCapacity: true,
        showWarningCapacityDifference: difference * -1,
      })
      return
    }

    if (this.getTypeRun() !== this.props.address.type) {
      this.setState({
        showWarning: this.props.address.type,
      })
      return
    }
    this.addRiderInRun()
  }

  addRiderInRun = () => {
    let address = this.props.address
    let schoolId = this.props.address.childSchool.school_id
    let childId = this.props.child.id
    this.props.addRiderInRun(address.id, schoolId, childId)
  }

  timerClick = (e, type, error) => {
    if (!this.props.activeRun || !error) return false

    if (type) {
      this.timer = setTimeout(this.addressModal, 500)
    } else {
      clearTimeout(this.timer)
    }
  }

  popText = (bool) => {
    if (bool) {
      return <span>School doesn't have an address</span>
    }
  }

  handleHidden = () => {
    this.setState({
      visiblePopup: false,
    })
  }

  handleVisibleChange = () => {
    let address = this.props.address
    let error = address.childSchool

    this.setState({
      visiblePopup: !error,
    })
  }

  render() {
    let address = this.props.address
    let child = this.props.child
    if (address.is_use || !address.address || address.is_school) {
      return null
    }
    let {
      showWarning,
      showWarningCapacity,
      showWarningCapacityDifference,
      isLoading,
    } = this.state

    let error = address.childSchool
    const isWriteRoutes = this.props?.userPermissions?.write_routes !== 1
    return (
      <div className="sbm-light" style={{ position: 'relative' }}>
        <Row
          key={child.id}
          className={
            this.props.active_address_hover_id === this.props.address.id
              ? 'Margin7 hovered_unassigned_address'
              : 'Margin7'
          }
        >
          <Col span={4}>
            <span
              onClick={(e) => this.toggle(e)}
              className={`${error ? 'Eye' : 'Error'}`}
            >
              <b>{address.type * 1 === 1 ? 'PU' : 'DO'}</b>
            </span>
          </Col>
          <Col span={20}>
            {address.address && (
              <div className="Address">
                <span className="AddressBtn">
                  <b className="IconCyrcle" style={{ marginTop: '8px' }} />

                  <span
                    onMouseDown={(e) => this.timerClick(e, 1, error)}
                    onMouseUp={(e) => this.timerClick(e, 0, error)}
                    onMouseLeave={(e) => this.timerClick(e, 0, error)}
                    className={`${error ? 'addressAccept' : 'addressName'}`}
                  >
                    <Popover
                      overlayClassName={'unassignPopup'}
                      content={this.popText(!error && true)}
                      placement="top"
                      title={null}
                      onOpenChange={this.handleVisibleChange}
                      open={this.state.visiblePopup}
                      trigger="hover"
                      align={{ offset: [20, 5] }}
                    >
                      {/*TODO удалил отображение кнопки если нет lat lon*/}
                      <div onMouseLeave={this.handleHidden}>
                        {address.address && address.address.address}
                        {!isWriteRoutes && (
                          // address?.address?.lat &&
                          // address?.address?.lon &&
                          <button
                            data-testid="addRiderLeftSideBtn"
                            disabled={isLoading}
                            style={{ display: `${error ? '' : 'none'}` }}
                            onClick={this.riderInRun}
                            className="Add addRdrBtn"
                          />
                        )}
                      </div>
                    </Popover>
                  </span>
                </span>
                <span className="AddressName">{addressChard2(address)}</span>
              </div>
            )}
          </Col>
        </Row>
        {showWarning && (
          <ErrorModal
            close={() => {
              this.setState({ showWarning: false })
            }}
            save={this.addRiderInRun}
            addressType={showWarning}
          />
        )}
        {showWarningCapacity && (
          <ErrorModalCapacity
            close={() => {
              this.setState({ showWarningCapacity: false })
            }}
            save={() => this.riderInRun(false)}
            difference={showWarningCapacityDifference}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    routes: state.routesView.routes,
    buses: state.buses,
    loader: state.headerLoader.loading,
    userPermissions: state.auth.permissions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(RiderAddress)
