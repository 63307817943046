import React, { Component } from 'react'
import BaseModal from '../../../../components/BaseModal'
import osrmTextInstructions from 'osrm-text-instructions'
import Collapse from 'antd/es/collapse'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import './index.less'
import { Trans } from 'react-i18next'
import i18n from '../../../../i18n'
import { cloneDeep } from 'lodash'
const Panel = Collapse.Panel
const osrmI = osrmTextInstructions('v5')

class TurnModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showList: [],
      turnData: this.props.turnData,
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.turnData !== nextProps.turnData) {
      let osrmCompact = this.getOsrmCompact(nextProps.turnData)

      this.setState({
        turnData: osrmCompact,
        osrm: osrmCompact.osrm,
        title: nextProps.turnData.title,
      })
    }
  }

  getManIconText = (step) => {
    let maneuver = ''

    let type = step.maneuver.type
    let mod = step.maneuver.modifier

    if (type === 'turn' || type === 'roundabout turn') {
      if (mod === 'uturn') {
        maneuver = 'uturn-left'
      } else if (mod === 'straight') {
        maneuver = 'straight'
      } else {
        if (mod === 'sharp left') {
          maneuver = 'turn-sharp-left'
        } else if (mod === 'sharp right') {
          maneuver = 'turn-sharp-right'
        } else if (mod === 'slight left') {
          maneuver = 'turn-slight-left'
        } else if (mod === 'slight right') {
          maneuver = 'turn-slight-right'
        } else {
          maneuver = 'turn-' + mod
        }
      }
    } else if (type === 'rotary' || type === 'roundabout') {
      maneuver = 'roundabout-left'
    } else if (type === 'fork') {
      mod = mod.replace('slight ', '')
      maneuver = 'fork-' + mod
    } else if (type === 'off ramp' || type === 'on ramp') {
      if (mod === 'straight') {
        maneuver = 'straight'
      } else {
        mod = mod.replace('slight ', '')
        mod = mod.replace('sharp ', '')
        maneuver = 'ramp-' + mod
      }
    } else if (type === 'merge') {
      maneuver = 'merge'
    } else if (type === 'continue' || type === 'end of road') {
      if (mod === 'uturn') {
        maneuver = 'uturn-left'
      } else if (mod === 'straight') {
        maneuver = 'straight'
      } else {
        mod = mod.replace('slight ', '')
        mod = mod.replace('sharp ', '')
        maneuver = 'turn-' + mod
      }
    } else if (type === 'arrive') {
      if (mod === 'continue') {
        maneuver = 'straight'
      } else if (mod !== 'default' && mod !== '' && mod !== undefined) {
        mod = mod.replace('slight ', '')
        mod = mod.replace('sharp ', '')
        maneuver = 'arrive-' + mod
      }
    }

    return maneuver ? maneuver : `straight`
  }

  getOsrmCompact = (turnData) => {
    const newTurnData = cloneDeep(turnData)
    console.log('Turn Data', newTurnData)
    let osrm = newTurnData.osrm
    osrm.legs_f = []
    osrm.legs.forEach((leg) => {
      leg.pointId = `${leg.start_point.lat}-${leg.start_point.lon}`
    })

    const filteredPoints = newTurnData.points.filter(
      (point) => point.type !== 4
    )

    osrm.finalPoints = []
    newTurnData.points = filteredPoints.map((newPoint) => {
      newPoint.pointId = `${newPoint.address?.lat.trim() ?? ''}-${newPoint.address?.lon.trim() ?? ''}`
      const leg = osrm.legs.find((leg) => leg.pointId === newPoint.pointId)
      if (
        leg &&
        osrm.finalPoints.at(-1)?.pointId !== newPoint.pointId &&
        osrm.finalPoints.at(-2)?.pointId !== newPoint.pointId
      ) {
        osrm.finalPoints.push({
          ...newPoint,
          distance: leg.distance,
          duration: leg.duration,
          steps: leg.steps,
          id: leg.pointId,
        })
      } else {
        osrm.finalPoints.push({
          ...newPoint,
          distance: 0,
          duration: 0,
          steps: [],
          id: newPoint.pointId,
        })
      }
      return newPoint
    })

    return newTurnData
  }

  Header = (i, leg) => {
    const wrongAddress =
      !leg.address.address || !leg.address.lat || !leg.address.lon
    let turnPoint = this.state.turnData.points[i]
    let pointName

    switch (turnPoint && turnPoint.type) {
      case 1:
        pointName = turnPoint.object.full_name
        break
      case 2:
        pointName = turnPoint.busStopTitle
        break
      case 3:
        pointName = turnPoint.object.name
        break
      case 4:
        pointName = 'Redirection Point'
        break
      case 5:
        pointName = turnPoint.busStopTitle
        break
      case 6:
        pointName = turnPoint.object.name
        break
    }

    let waitTime =
      turnPoint.routeWaittime * 1 === 1 ? 1 : (turnPoint.routeWaittime * 1) / 60

    return (
      <div className="headers">
        <Row>
          <Col span={2}>
            <div className="key fontLight size12">
              <Trans>Wait</Trans>
            </div>
            <div style={{ marginTop: '2px' }}>
              {waitTime} <Trans>min</Trans>
            </div>
          </Col>
          <Col span={22} className={'blockPaddingLeft'}>
            <div className="name">
              <span className="count">
                {' '}
                ({i}) {pointName}
              </span>
            </div>
            {wrongAddress ? (
              <p style={{ color: '#ed5652', marginTop: 10 }}>Invalid address</p>
            ) : (
              <div className="smallText">
                {i18n.t('durationFormat', { minutes: leg.duration })} (
                {i18n.t('distanceFormat', { meters: leg.distance })})
              </div>
            )}
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    let { turnData, osrm, title } = this.state
    let closeFn = this.props.closeFn

    if (!turnData || !osrm) return <div></div>
    return (
      <div>
        <BaseModal
          title={i18n.t(`Turn by turn directions for trip`) + ' ' + title + ``}
          closeFn={() => closeFn('turn_by_turn', '')}
          class={'ModalTyrn'}
          close={'closeM'}
          save={() => {}}
        >
          <div className="content TyrnByTyrn">
            <Row className={'tyrnHeaderText'}>
              <Col span={4} className={'lineHeight'}>
                <div className="key mediumHeader">
                  <Trans>Total Distance</Trans>
                </div>
                <div>{i18n.t('distanceFormat', { meters: osrm.distance })}</div>
              </Col>
              <Col span={20} className="paddingLeft60 lineHeight">
                <div className="key mediumHeader">
                  <Trans>Total Duration</Trans>
                </div>
                <div>
                  {i18n.t('durationFormat', { minutes: osrm.duration })}
                </div>
              </Col>
            </Row>

            <Collapse bordered={false}>
              {osrm.finalPoints &&
                osrm.finalPoints.length &&
                osrm.finalPoints.map((leg, i) => {
                  console.log('Leg', leg, leg.address.address)
                  const wrongAddress =
                    !leg.address.address || !leg.address.lat || !leg.address.lon
                  return (
                    <Panel
                      header={this.Header(i, leg)}
                      key={i}
                      collapsible={wrongAddress ? 'disabled' : ''}
                      showArrow={
                        i !== osrm.finalPoints.length - 1 && !wrongAddress
                      }
                      className={wrongAddress && 'collapse-no-address'}
                    >
                      {i !== osrm.finalPoints.length - 1 && (
                        <div
                          className="navigation"
                          style={{
                            overflowX: 'hidden',
                          }}
                        >
                          {leg &&
                            leg.steps &&
                            leg.steps.length &&
                            leg.steps.map((step, i) => {
                              step.instructions = osrmI.compile(
                                i18n.language,
                                step
                              )
                              let textOsrm = ''

                              if (step.instructions) {
                                let instructions =
                                  step.instructions && step.instructions.default
                                    ? step.instructions.name
                                    : step.instructions
                                textOsrm = instructions.replace(
                                  /(<([^>]+)>)/gi,
                                  ''
                                )
                                textOsrm = textOsrm
                                  .replace(/<\/font>/, '</b>')
                                  .trim()
                              }
                              let maneuverIcon = this.getManIconText(step)

                              return (
                                <div key={i} className="navline">
                                  <div className={`maneuver ${maneuverIcon}`} />
                                  <div
                                    className={
                                      textOsrm.length > 73
                                        ? 'largeTextOsrm inf'
                                        : 'inf'
                                    }
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: step.instructions,
                                      }}
                                    />
                                    <div className="dist">
                                      {i18n.t('distanceFormat', {
                                        meters: step.distance,
                                      })}
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                        </div>
                      )}
                    </Panel>
                  )
                })}
            </Collapse>
          </div>
        </BaseModal>
      </div>
    )
  }
}

export default TurnModal
