// export const localUrl = "https://demo.app.schoolbusmanager.com/api-site";
export const localUrl = 'https://dev.schoolbusmanager.com/api-site'

export const localUrlGeo = 'https://staging.map2.schoolbusmanager.com/api'

export const mapKey = 'AIzaSyDv2AHH4nkUMZXAjoHbpbT43CtvRAdoNa4'
export const socketIOClientIp =
  'https://latestio.testwp.schoolbusmanager.com/'

let checkDEV = false
if (window.location.href.includes('https://dev.schoolbusmanager.com/')) {
  // checkDEV = true;
}
export const priorityRuLoad = checkDEV

export const DSN_SENTRY = { load: false, dsn: ``, tracingOrigins: null }
