import React, { FC, memo } from 'react'
import { genHours } from '@/pages/Gps/NewGpsDetails/helpers/hoursGeneration'
import { useGetTimelineData } from '@/pages/Gps/NewGpsDetails/hooks/useGetTimelineData'

type HoursProps = {
  startTime: number
  endTime: number
  showEnd?: boolean
}
export const Hours: FC<HoursProps> = memo(({ startTime, endTime, showEnd }) => {
  const { minuteHeight } = useGetTimelineData(startTime, endTime)

  const hours = genHours(startTime, endTime)

  return (
    <div className="time-line-hours-block">
      {hours.map((info, index) => (
        <div
          key={index}
          className="time-block-hour"
          style={{
            zIndex: info.start || info.end ? 2 : 1,
            height: minuteHeight * 60,
            paddingBottom: index === 0 ? 10 : 0,
          }}
        >
          <div className="time-block-hour-view">
            <div className="hour-view-style">
              <span className="hour-view-style-time">{info.h}</span>
              <span className="hour-view-style-format">{info.ampm}</span>
            </div>
          </div>
          {!!info?.start?.text && (
            <div className={'start-block-view'}>
              <span id={'start'} className="start-block-time">
                {info.start.text} {'Start'}
              </span>
            </div>
          )}
          {!!info?.end?.text && showEnd && (
            <div className={'end-block-view '}>
              <span id={'end'} className="start-block-time">
                {info.end.text} {'End'}
              </span>
            </div>
          )}
        </div>
      ))}
    </div>
  )
})
