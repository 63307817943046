import { RootRouter, useRootRouter } from '@/shared/hooks/routeHooks/useRouter'
import { ReactNode, useEffect } from 'react'
import { browserHistory } from 'react-router'
import './index.less'

export const _RootLayout = (params) => {
  useRootRouter({ location: params.location, params: params.params })
  return (
    <RootRouter {...params}>
      <RootLayout {...params}>{params.children}</RootLayout>
    </RootRouter>
  )
}
export const RootLayout = ({
  params,
  children,
}: {
  location: any
  params: any
  children?: ReactNode
}) => {
  const lsflow = () => {
    const { pathname } = (browserHistory as any).getCurrentLocation()
    localStorage.setItem('redirectUrl', pathname?.replace('/client/signin', ''))
  }

  useEffect(() => {
    const rootRoute = 'client'
    const token = localStorage.getItem('token')
    const { pathname, search } = (browserHistory as any).getCurrentLocation()
    const currentLocation = pathname
    const fieldTripsRequestPage = currentLocation.startsWith(
      '/client/fieldRequest'
    )

    if (!token && !fieldTripsRequestPage) {
      if (
        currentLocation &&
        currentLocation !== '/' &&
        currentLocation !== `/${rootRoute}/signin` &&
        !currentLocation.includes('forgot')
      ) {
        lsflow()
      }

      if (params.hash && params.id) {
        browserHistory.push(`/${rootRoute}/signin/${params.id}/${params.hash}`)
      } else {
        let way = pathname.includes('forgot') ? 'forgot' : 'signin'
        if (search) way = way + search

        browserHistory.push(`/${rootRoute}/${way}`)
      }
    } else {
      if (
        currentLocation &&
        currentLocation !== '/' &&
        currentLocation !== `/${rootRoute}/signin` &&
        !currentLocation.includes('forgot')
      ) {
        browserHistory.push(currentLocation)
      }
    }
  }, [])
  return <>{children}</>
}
