import styles from './ModalFooter.module.less'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { useModalStore } from '@/store/modal/ModalStore'
import { closeModal } from '@/features/containers/ModalContainer/modalConfig'

export interface IModalFooter {
  buttonWidth?: number
  cancel?: {
    action?: () => void
    text?: string
    isLoading?: boolean
  }
  success?: {
    action?: () => void
    text?: string
    isLoading?: boolean
    testId?: string
    isSubmitForm?: boolean
    disabled?: boolean
  }
}
export const ModalFooter = ({ success, cancel, buttonWidth }: IModalFooter) => {
  const onCloseModal = useModalStore.getState().onCloseModal
  return (
    <div className={styles.footer}>
      <Button
        style={{ width: buttonWidth }}
        onClick={() => {
          if (cancel?.action) {
            cancel?.action()
          } else {
            onCloseModal()
            closeModal()
          }
        }}
        buttonColor="gray"
        type="button"
      >
        {cancel?.text ?? 'Cancel'}
      </Button>
      <Button
        style={{ width: buttonWidth }}
        data-testid={success?.testId ?? ''}
        onClick={success?.action}
        isLoading={success?.isLoading}
        disabled={success?.disabled}
        type={success?.isSubmitForm ? 'submit' : 'button'}
      >
        {success?.text ?? 'Delete'}
      </Button>
    </div>
  )
}
