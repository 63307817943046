import React, { FC, memo } from 'react'
import { ActiveInfo } from '@/pages/Gps/NewGpsDetails/components/ColorLine/ActiveInfo'
import { useGetTimelineData } from '@/pages/Gps/NewGpsDetails/hooks/useGetTimelineData'
import { useGpsDetailsStore } from '@/pages/Gps/NewGpsDetails/store'
import { StopType } from '@/redux/gps/types'
import { getColorBarStyle } from '@/pages/Gps/NewGpsDetails/helpers/getColorBarStyle'

type ColorLineProps = {
  start: number
  end: number
}

export const ColorLine: FC<ColorLineProps> = memo(({ start, end }) => {
  const { minuteHeight, runTotalMinutes } = useGetTimelineData(start, end)

  const run = useGpsDetailsStore.getState().gpsInfo

  const startTimeMinutes = (start / 60) % 60
  const bottomBlockHeight = minuteHeight * (60 + startTimeMinutes)

  const activeBlockHeight = ((end - start) / 60) * minuteHeight

  const stops = run.points?.filter((p) => p.stopType !== StopType.Redirection)

  const legsColorBars = stops.map((stop, i, stops) => (
    <div
      key={stop.id}
      style={getColorBarStyle(
        stop,
        stops[i + 1],
        minuteHeight,
        start,
        end,
        run.timeZone
      )}
      className="pointToPointWay"
    />
  ))

  return (
    <div className="time-line-full-way-block">
      <div
        id="block0"
        style={{ height: bottomBlockHeight, backgroundColor: '#c9c9c9' }}
        className={`time-block-hour-way`}
      />
      <div
        id="block1"
        style={{ height: activeBlockHeight, backgroundColor: '#3cb0e7' }}
        className={`time-block-hour-way`}
      >
        {legsColorBars}
        <ActiveInfo
          barHeight={activeBlockHeight}
          runTotalMinutes={runTotalMinutes}
        />
      </div>
    </div>
  )
})
