import React, { Component } from "react";
import { connect } from "react-redux";
import FilterLine from "../FilterLine";

class MapElements extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="mapElements mapElementsFt">
        <FilterLine
          // getChangeInput={this.props.getChangeInput}
          getChangeInputArray={this.props.getChangeInputArray}
          activeTripId={this.props.activeTripId}
          // getChange={this.props.getChange}
        />
        <div className="Zones">
          <div
            className={this.props.maptype === "roadmap" ? "earth" : "road"}
            onClick={this.props.switchMap}
          />
          <div className="zoomOut" onClick={this.props.zoomOut} />
          <div className="zoomIn" onClick={this.props.zoomIn} />
          {/*<div className="target" onClick={this.props.targetRun} />*/}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapElements);
