import { create } from 'zustand'
import { SortOrder } from 'antd/es/table/interface'

export interface IStore {
  filter: {
    status: number | null
    search: string
    sortKey: string
    filterKey: string
    filterValue: string | number
    order: SortOrder
    selectionRows: number[]
  }
  resetDataTableCount: number
  incrementCount: () => void
  extendedRowsKeys: number[]
  setExtenderRowsKeys: (id: number) => void
  setFilter: (filter: Partial<IStore['filter']>) => void
  setSelectionRows: (id: number) => void
}

export const defaultFilter = {
  status: null,
  search: null,
  sortKey: '',
  order: null,
  filterKey: '',
  filterValue: '',
  selectionRows: [],
}

export const useFtRequestsStore = create<IStore>((set, get) => ({
  tableFilter: {},
  resetDataTableCount: 1,
  incrementCount: () =>
    set({ resetDataTableCount: get().resetDataTableCount + 1 }),
  filter: defaultFilter,
  extendedRowsKeys: [],
  setExtenderRowsKeys: (id) => {
    const extendedRowsKeys = get().extendedRowsKeys
    if (extendedRowsKeys.includes(id)) {
      set({ extendedRowsKeys: extendedRowsKeys.filter((el) => el !== id) })
    } else {
      set({ extendedRowsKeys: [...extendedRowsKeys, id] })
    }
  },
  setFilter: (filter: Partial<IStore['filter']>) =>
    set({ filter: { ...get().filter, ...filter } }),
  setSelectionRows: (id) => {
    const rows = get().filter.selectionRows
    if (rows.includes(id)) {
      set({
        filter: {
          ...get().filter,
          selectionRows: rows.filter((el) => el !== id),
        },
      })
    } else {
      set({ filter: { ...get().filter, selectionRows: [...rows, id] } })
    }
  },
}))
