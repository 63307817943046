import { Button } from '@/entities/ui/NewButton/NewButton'
import { Popover } from 'antd'
import React from 'react'
import { NewCheckbox } from '@/entities/ui/NewCheckbox/NewCheckbox'
import { Trans } from 'react-i18next'
import styles from './RoutingRules.module.less'
import { useAppDispatch, useAppSelector } from '@/redux'
import { userInfoSelector } from '@/store/common/common.selectors'
import { useContextBuilder } from '@/pages/Run/components/BuildRun/context'
import * as runActions from '@/redux/routesView/actions'
import classNames from 'classnames'
import { getLoaderState } from '@/redux/common/selectors'

interface Props {
  disable: boolean
}

export const RoutingRules = ({ disable }: Props) => {
  return (
    <Popover
      trigger="click"
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      placement="bottomLeft"
      align={{ offset: [0, -10] }}
      content={<Content />}
    >
      <Button
        className={classNames({ [styles.loading]: disable })}
        size="sm"
        buttonColor="red"
        data-testid="runSettingsBtn"
      >
        Routing Rules
      </Button>
    </Popover>
  )
}

const Content = () => {
  const userInfo = useAppSelector(userInfoSelector)
  const loaderState = useAppSelector(getLoaderState)
  const { activeRun, setActiveRun } = useContextBuilder()
  const noUturnsGlobal = userInfo?.company.no_uturns
  const curbsidePickupGlobal = userInfo?.company.curbside_pickup
  const use_default_rules = activeRun?.use_default_rules
  const isLoading = loaderState === 'loadingStart'

  const dispatch = useAppDispatch()

  const handleChange = (
    value: boolean,
    type: 'use_default_rules' | 'no_uturns' | 'curbside_pickup'
  ) => {
    dispatch(
      runActions.editItem({
        id: activeRun.id,
        [type]: Number(value),
      })
    )
    setActiveRun({ ...activeRun, [type]: Number(value) })
  }

  return (
    <div className={styles.contentRoot}>
      <NewCheckbox
        type="checkbox"
        disabled={isLoading}
        checked={!!use_default_rules}
        onChange={(e) => handleChange(e.target.checked, 'use_default_rules')}
      >
        <Trans>Use Default Settings</Trans>
      </NewCheckbox>
      <NewCheckbox
        type="checkbox"
        style={{ marginLeft: 24 }}
        disabled={!!use_default_rules || isLoading}
        onChange={(e) => handleChange(e.target.checked, 'no_uturns')}
        checked={use_default_rules ? noUturnsGlobal : activeRun.no_uturns}
      >
        <Trans>Avoid U-Turns</Trans>
      </NewCheckbox>

      <NewCheckbox
        style={{ marginLeft: 24 }}
        type="checkbox"
        disabled={!!use_default_rules || isLoading}
        name="curbside_pickup"
        onChange={(e) => handleChange(e.target.checked, 'curbside_pickup')}
        checked={
          use_default_rules ? curbsidePickupGlobal : activeRun.curbside_pickup
        }
      >
        <Trans>Curbside Pickup Only</Trans>
      </NewCheckbox>
    </div>
  )
}
