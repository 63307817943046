import { Input as Inpuut, InputProps as IInputProps, InputRef } from 'antd'
import { forwardRef, Ref } from 'react'
import './NewInput.less'

export const NewAntInput = forwardRef(
  (props: IInputProps, ref: Ref<InputRef>) => {
    return <Inpuut {...props} ref={ref} />
  }
)

export const NewPasswordInput = forwardRef(
  (props: IInputProps, ref: Ref<InputRef>) => {
    return <Inpuut.Password {...props} ref={ref} />
  }
)

export interface InputProps extends IInputProps {
  label?: string
  wrapperClassName?: string
  labelClassName?: string
  error?: string
  formattedValue?: (v: string) => string
  showErrorText?: boolean
}
