import * as types from './../actionTypes'
import { debounce } from 'lodash'

const delayMiddleware = (store) => (next) => (action) => {
  if (action.type === types.PENDING_START) {
    // если получен экшен PENDING_START, просто передаем его дальше в цепочку middleware и редьюсер
    return next(action)
  }

  if (action.type === types.PENDING_END) {
    // если получен экшен PENDING_END, выполняем задержку и вызываем экшен PENDING_FINISH
    const dispatchFinishAction = () => {
      store.dispatch({ type: types.PENDING_FINISH })
    }
    const debouncedAction = debounce(dispatchFinishAction, 3000)
    debouncedAction()
    return next(action)
  }

  // для остальных экшенов, просто передаем их дальше в цепочку middleware и редьюсер
  return next(action)
}

export default delayMiddleware
