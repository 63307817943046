import React, { Component } from 'react'
import * as runActions from '../../../../../../redux/routesView/actions'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { ModalAnchor } from '../../../ModalAnchor'
import Modal from './../Modal/DeleteStop/index'
import i18n from '../../../../../../i18n'

import StopChildAddress from '../StopPoint/StopChildAddress'
import AllPurpose from './../Modal/AllPurpose/index'
import MoreBlock from '../BlockMore'
import styles from './index.module.less'
import _ from 'lodash'

import {
  getTimeLastUTC,
  totalDist,
  totalTime,
} from '../../../../../../components/RepeatFunctions/timeWork'
import { sortRidersList } from '../../../../../../components/RepeatFunctions/filter'
import anchorAndTimeFN from '../anchorAndTime'
import { DownOutlined, RightOutlined, UpOutlined } from '@ant-design/icons'
import classnames from 'classnames'
import ModalAddToTransfer from '@/pages/Run/components/BuildRun/components/StopPoint/ModalAddToTransfer'
import { AttendanceGroup } from '@/pages/Run/components/BuildRun/components/AttendanceGroup/AttendanceGroup'

const relativePath = 'client'

class School extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
      edit: false,
      over: false,
      showModalAnchor: false,
      removeModal: false,
      editShow: false,
      index: props.index3,
      arrive: props.Arrive,
      Wait: props.Wait,
      miles: props.miles,
      min: props.min,
      curbside_pickup: 0,
      use_default_settings: 0,
      ridersNamesToTransfer: '',
    }
    this.min = 0
    this.miles = 0
    this.wait = 0
    this.obj = {}
    this.showEdit = false
  }

  componentDidMount() {
    this.obj = {
      title: i18n.t('Edit Bus Depot Stop'),
      select: [
        {
          label: i18n.t('School'),
          name: 'school',
        },
      ],
      minute: [
        {
          label: i18n.t('Wait Time'),
          placeholder: i18n.t('In Minutes'),
        },
      ],
      stops: [
        {
          label: i18n.t('Enter Stop Number'),
          placeholder: i18n.t('or Stop Will Be Placed Last on the List'),
        },
      ],
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      ...nextProps,
    })
  }

  closeModal = () => {
    this.setState({
      showModalAnchor: false,
      removeModal: false,
      editStop: false,
    })
  }

  fn = () => {}

  handleChange = (type, e) => {
    e.preventDefault()
    if (type === 'newPosition') {
      //this.props.changePosFn(idElement, newPosition, inStop, child_address_id)
      if (e.target.value) this.props.changePosFn(this.state.id, e.target.value)
    }
    this.setState({
      [type]: e.target.value,
    })
  }

  saveAnchor = (id, data) => {
    this.props.dispatch(runActions.editAnchor(id, data))
  }

  handleAnchor = () => {
    return (
      !this.props.isRoleDispatcher &&
      this.setState({
        showModalAnchor: true,
      })
    )
  }

  editToggle = () => {
    this.setState({
      edit: !this.state.edit,
    })
  }

  removeRun = () => {
    this.setState({
      removeModal: true,
    })
  }

  handleStop = () => {
    this.setState({
      editStop: true,
    })
  }

  deletePoint = () => {
    this.props.delFn()
    this.closeModal()
  }

  handleClick = (e, name) => {
    this.setState({
      show: !this.state.show,
      edit: true,
    })
    if (name === 'default') {
      this.setState({
        edit: !this.state.edit,
        show: !this.state.show,
      })
    }
  }

  delChildFromStop = (id, child, address) => {
    this.props.removeChildFromStop(id, child, address, this.state.routeId)
  }

  getChildrenList = () => {
    let { routePointChildren } = this.state
    let pickUpChild = []
    let dropOffChild = []

    if (!routePointChildren) return false

    routePointChildren.forEach((x) => {
      if (x.childaddress && x.childaddress.type === 1) pickUpChild.push(x)
      if (x.childaddress && x.childaddress.type === 2) dropOffChild.push(x)
    })

    pickUpChild = sortRidersList(pickUpChild)
    dropOffChild = sortRidersList(dropOffChild)
    return { pickUpChild, dropOffChild }
  }

  transferModalOpen = (address) => {
    this.setState({
      modalToTransfer: true,
      point: {
        stopId: this.props.id,
        child_address_id: address.child_address_id,
      },
    })
  }

  stopRidersToTransfer = () => {
    const transferridersNames = this.getChildrenList()
      .pickUpChild?.map((item) => item.childaddress?.child?.full_name)
      ?.filter((item) => !!item)
      .join(', ')

    this.setState({
      modalToTransfer: true,
      ridersNamesToTransfer:
        typeof transferridersNames === 'string' && transferridersNames
          ? transferridersNames
          : '',
      point: { stopId: this.props.id, child_address_id: null },
    })
  }

  childList = () => {
    let { id, routePointChildren, object } = this.state

    const { dropOffChild, pickUpChild } = this.getChildrenList()
    return (
      <div>
        <div className={styles.attendanceGroupTitleWrapper}>
          {routePointChildren && !!routePointChildren.length && (
            <div style={{ marginTop: 0 }} className="SchoolAddress">
              <span className="riders">
                {i18n.t('Riders')} {routePointChildren.length}
              </span>
              {this.state.show ? (
                <UpOutlined
                  onClick={(e) => this.handleClick(e, 'Ediable')}
                  style={{ marginLeft: '2px' }}
                />
              ) : (
                <DownOutlined
                  onClick={(e) => this.handleClick(e, 'Ediable')}
                  style={{ marginLeft: '2px' }}
                />
              )}
            </div>
          )}
          <AttendanceGroup routePointRoute={this.state.routePointRoute} />
        </div>

        {this.state.show && (
          <div className="PuckUpMain marginTop">
            <div className="pickUpBlock">
              {pickUpChild.length > 0 && (
                <div className="pickUp">{i18n.t('Pick Up Riders')} </div>
              )}

              {pickUpChild.map((address) => {
                let child = (this.props.childrenList || []).find(
                  (child) => child.id === address.childaddress.child_id
                )
                return (
                  <StopChildAddress
                    id={id}
                    child={child}
                    address={address}
                    delChildFromStop={() =>
                      this.delChildFromStop(id, child, address)
                    }
                    isRoleDispatcher={this.props.isRoleDispatcher}
                    transferMode={true}
                    transferModalOpen={() => this.transferModalOpen(address)}
                  />
                )
              })}
            </div>
            <div
              className={`dropOffBlock ${
                pickUpChild.length > 0 ? `marginTop` : ''
              }`}
            >
              {dropOffChild.length > 0 && (
                <div className="dropOff">{i18n.t('Drop Off Riders')} </div>
              )}
              {dropOffChild.map((address) => {
                let child = (this.props.childrenList || []).find(
                  (child) => child.id === address.childaddress.child_id
                )
                return (
                  <StopChildAddress
                    id={id}
                    child={child}
                    address={address}
                    delChildFromStop={() =>
                      this.delChildFromStop(id, child, address)
                    }
                    isWriteRoutes={this.props.isWriteRoutes}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
  }

  modalToTransferClose = () => {
    this.setState({
      modalToTransfer: false,
      point: null,
      ridersNamesToTransfer: '',
    })
  }
  render() {
    let { id, address, object, routePointRoute, lastIndex, routeId } =
      this.state

    let school = object
    let { routeWaittime, pointOsrmLeg, route, osrmTime } = this.props
    let newPosition = routePointRoute.view_position

    if (!this.showEdit) {
      this.showEdit = true
    }
    let arriveTime = null
    let distance = null
    let duration = null
    if (pointOsrmLeg && pointOsrmLeg.time) {
      arriveTime = getTimeLastUTC(pointOsrmLeg.time)

      if (
        pointOsrmLeg.distance !== undefined &&
        pointOsrmLeg.duration !== undefined
      ) {
        distance = totalDist(pointOsrmLeg.distance)
        duration = totalTime(pointOsrmLeg.duration)
      }
    }

    const schoolAddress = school?.address
      ? school.address
      : { lat: null, lon: null }

    const { lat, lon } = schoolAddress

    let hasAddress =
      (school?.address && school?.address?.address) || lat || lon
        ? 'bgTranscent'
        : 'bgNoAddress'
    const { pickUpChild } = this.getChildrenList()
    let showTransfer = pickUpChild?.length > 0

    return (
      <div className="ListDiv">
        <div
          data-testid="schoolPoint"
          key={id}
          className={`schoolStop ${hasAddress} Orange`}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="buttonTrigger"
            onClick={(e) => this.editToggle(e)}
          >
            <RightOutlined
              style={{ color: 'white' }}
              className={classnames(styles.arrow, {
                [styles.active]: this.state.edit,
              })}
            />
          </div>

          {!this.props.isWriteRoutes && (
            <MoreBlock
              isLast={newPosition === lastIndex}
              routePointRoute={routePointRoute}
              id={id}
              type={3}
              stopRidersToTransfer={this.stopRidersToTransfer}
              handleAnchor={this.handleAnchor}
              handleEditStop={this.handleStop}
              removeRun={this.removeRun}
              route={route}
              showTransfer={showTransfer}
            />
          )}

          <div className="Head">
            <div className="big">
              {newPosition}. {school && school.name}
            </div>
            {distance &&
              duration &&
              (school?.address?.address || lat || lon) && (
                <div className="normal distance">
                  <span>{distance}</span>
                  <span>{duration}</span>
                </div>
              )}
          </div>
          {anchorAndTimeFN.bind(this)()}
          {!_.isEmpty(address) && this.state.edit && address.address && (
            <div className="firstLine">
              <div className="flex">
                <div>
                  <span className="GrayColor">{i18n.t('Address')}</span>
                  <b className="AddressLatLon">
                    {address.address
                      ? address.address
                      : address.lat + ' ' + address.lon}
                  </b>
                </div>
              </div>
            </div>
          )}
          {(_.isEmpty(address) ||
            !school?.address?.address ||
            !lat ||
            !lon) && (
            <div className="firstLine">
              <div className="flex">
                <Link to={`/${relativePath}/Resources/ss`}>
                  <div>
                    <span className="InvalidAddressText paddingLeft15">
                      {i18n.t('Invalid Address')}
                    </span>
                    <span className="editPencil" />
                  </div>
                </Link>
              </div>
            </div>
          )}

          <div className="firstLine">
            <div style={{ width: '100%' }}>{this.childList()}</div>
          </div>
        </div>
        {this.state.removeModal && (
          <Modal
            closeFn={this.closeModal}
            title={i18n.t('Delete School')}
            deleteBtn={i18n.t('Delete School')}
            deleteObject={this.state.object.name}
            deleteFn={this.deletePoint}
          />
        )}
        {this.state.editStop &&
          school?.address.address &&
          school?.address.lat &&
          school?.address.lon && (
            <AllPurpose
              id={id}
              positon={newPosition}
              waittime={routeWaittime}
              routeId={routeId}
              schoolName={school.name}
              select={this.obj.select}
              minute={this.obj.minute}
              school={school}
              stops={this.obj.stops}
              title={i18n.t('Edit School Stop')}
              closeFn={this.closeModal}
            />
          )}
        {this.state.modalToTransfer && (
          <ModalAddToTransfer
            closeFn={this.modalToTransferClose}
            point={this.state.point}
            ridersNamesToTransfer={this.state.ridersNamesToTransfer}
          />
        )}
        {this.state.showModalAnchor && (
          <ModalAnchor
            data={{
              id: id,
              routeId: routePointRoute['route_id'],
              name: school.name,
              type: i18n.t('School Stop'),
              position: newPosition,
              address: school?.address,
              time: routePointRoute.time,
              waittime: routePointRoute.waittime,
              isAnchor: routePointRoute.is_anchor,
              routeTime: routePointRoute.routeTime,
              timeZone: this.props.timeZone,
              osrmTime,
            }}
            saveFn={this.saveAnchor}
            closeFn={this.closeModal}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    miles: state.routesView.Miles,
    Wait: state.routesView.Wait,
    Arrive: state.routesView.Arrive,
    childrenList: state.children.childrenList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(School)
