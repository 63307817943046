export const AddPlusIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      {...props}
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M7 .06C3.17.06.048 3.17.048 7c0 3.83 3.111 6.94 6.952 6.94 3.83 0 6.94-3.11 6.94-6.94C13.94 3.17 10.83.06 7 .06zm0 12.91A5.98 5.98 0 0 1 1.03 7 5.98 5.98 0 0 1 7 1.03 5.98 5.98 0 0 1 12.97 7 5.98 5.98 0 0 1 7 12.97z" />
        <path d="M10.195 6.45H7.49V3.733A.485.485 0 0 0 7 3.243a.485.485 0 0 0-.49.49v2.705H3.804a.485.485 0 0 0-.49.49c0 .275.215.49.49.49H6.51v2.705c0 .275.216.49.491.49s.49-.215.49-.49V7.42h2.705c.275 0 .49-.216.49-.49a.475.475 0 0 0-.49-.48z" />
      </g>
    </svg>
  )
}
