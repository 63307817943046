import { SocketConnect } from '@/features/containers/SocketConnect/SocketConnect'
import { ModalContainer } from '@/features/containers/ModalContainer/ModalContainer'
import FreshChat from 'react-freshchat/build/react-freshchat'
import React, { memo, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux'
import { useGetUserInfoQuery } from '@/store/common/common'
import {
  useGetBusDepotListQuery,
  useGetBusesQuery,
  useGetDriversQuery,
} from '@/store/resources/resources'
import {
  isHaveUserInfoSelector,
  userInfoSelector,
} from '@/store/common/common.selectors'
import {
  sessionsSelector,
  useGetAllSeasonsQuery,
} from '@/store/session/sessionApi'
import { Spin } from 'antd'
import { useGetSchoolsQuery } from '@/store/lc/settings/lcSettingsApi'
import { useLazyGetFtRequestsQuery } from '@/store/fieldTrip/FieldTripApi'
import { useRouter } from '@/shared/hooks/routeHooks/useRouter'
import { routeLinks } from '@/shared/routeLinks'
import { currentSession } from '@/redux/sessions/actions'
import { clarity } from 'react-microsoft-clarity'

// import './index.less'

export const Containers = memo(() => {
  useGetUserInfoQuery()
  useGetBusesQuery()
  useGetBusDepotListQuery()
  useGetAllSeasonsQuery()
  useGetDriversQuery()
  useGetSchoolsQuery()
  const [getFTRequests] = useLazyGetFtRequestsQuery()
  const dispatch = useAppDispatch()

  const userInfo = useAppSelector(userInfoSelector)

  useEffect(() => {
    if (userInfo?.permissions?.read_field_trip) {
      getFTRequests()
    }
  }, [dispatch, getFTRequests, userInfo?.permissions?.read_field_trip])

  useEffect(() => {
    if (clarity.hasStarted()) {
      if (userInfo?.user_id && userInfo?.company?.name) {
        clarity.setTag('COMPANY_NAME', userInfo?.company?.name)
        clarity.setTag('COMPANY_USER', String(userInfo?.user_id))
      }
    }
  }, [userInfo?.company?.name, userInfo?.user_id])

  return (
    <>
      <SocketConnect />
      <ModalContainer />

      {userInfo?.company?.freshchat_token && (
        <div>
          <FreshChat
            token={userInfo?.company?.freshchat_token}
            email={userInfo?.email}
            first_name={userInfo?.username}
            last_name={userInfo?.last_name}
          />
        </div>
      )}
    </>
  )
})

export const AuthorizedLayout = memo((props: any) => {
  const isHaveUserInfo = useAppSelector(isHaveUserInfoSelector)
  const activeSession = useAppSelector((state) => state.sessions.activeSeason)
  const router = useRouter()

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      router?.push(routeLinks.signin)
    }
  }, [router?.push])

  useEffect(() => {
    if (isHaveUserInfo === 'error') {
      localStorage.clear()
      router?.push(routeLinks.signin)
    }
  }, [isHaveUserInfo])
  const seasons = useAppSelector(sessionsSelector)

  const seasonsIds = useMemo(
    () =>
      seasons
        .filter((item) => !item.isDeleted && !item.deleting)
        .map((item) => item?.id),
    [seasons]
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    let lsSession: string | number = localStorage.getItem('ActiveSessionId')
    if (lsSession && !seasonsIds.includes(Number(lsSession))) {
      lsSession = seasonsIds[0]
    }

    if (!activeSession?.id) {
      dispatch(currentSession(Number(lsSession) ?? seasonsIds[0]))
    }
  }, [activeSession?.id, dispatch, seasonsIds])

  return (
    <>
      <Containers />
      {isHaveUserInfo === 'success' && !!activeSession?.id ? (
        props.children
      ) : (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin />
        </div>
      )}
    </>
  )
})
