import _ from "lodash";
import * as L from "leaflet";
import ReactDOMServer from "react-dom/server";
import mapIcon from "../../../../../components/RepeatFunctions/mapicon";

export function getRouteMarkersFT(route) {
  let i = 1,
    c = 0;
  if (route && !_.isEmpty(route)) {
    return route.fieldTripPoints.map(point => {
      let type = "";
      if (point.type * 1 === 1) {
        type = "dpt";
      } else if (point.type * 1 === 3) {
        type = "school";
      } else if (point.type * 1 === 2) {
        type = "b";
      }
      let id = "routemarker-" + c;
      if (point.address) {
        let marker = {
          id: id,
          location: [point.address.lat, point.address.lon],
          icon: getIcon(type, i),
          object: point,
          sequence: i
        };
        i++;
        c++;
        return marker;
      }
    });
  } else {
    return [];
  }
}

export function directionsPathOptions(i) {
  let colors = [
    `65, 117, 5`,
    `255, 159, 0`,
    `255, 39, 25`,
    `199, 77, 225`,
    `156, 156, 156`,
    `60, 176, 231`
  ];

  if (i > 5) {
    colors[i] = `60, 176, 231`;
  }

  return {
    weight: 7,
    opacity: 0.8,
    color: `rgba( ${colors[i]} , 0.6)`
  };
}

export function getIcon(type, text) {
  if (type * 1 === 1) {
    type = "dpt";
  } else if (type * 1 === 3) {
    type = "school";
  } else if (type * 1 === 2) {
    type = "ft";
  }
  let size = [24, 43];
  let anchor = [12, 43];
  let BASE_URL = "https://demo.app.schoolbusmanager.com/";
  let url = "";
  if (type) {
    switch (type) {
      case "b":
        url = encodedIcon(text, "#00aaaa", "#005555", "#333");
        break;
      case "busdepot":
        size = [32, 37];
        anchor = [16, 37];
        url = BASE_URL + "img/busstop.png";
        break;
      case "school":
        url = encodedIcon(text, "#f57a20", "#f57a20", "#333");
        break;
      case "ft":
        url = encodedIcon(text, "#3cb0e7", "#3cb0e7", "#333");
        break;

      case "dpt":
        url = encodedIcon(text, "#1672A3", "#1672A3", "#000");
        break;
      default:
        url =
          BASE_URL +
          "route/mapiconsvg?text=P&bgstart=%231075a9&bgend=%231075a9&color=%23fff";
        break;
    }
  } else {
    url = encodedIcon(text, "#85d03d", "#69b921", "#333");
  }

  return new L.Icon({
    iconUrl: url,
    iconSize: size,
    iconAnchor: anchor,
    tooltipAnchor: [12, -25]
  });
}

export function encodedIcon(
  text,
  startcolor,
  endcolor,
  textcolor,
  iconed,
  strokeColor
) {
  return (
    "data:image/svg+xml;base64," +
    btoa(
      ReactDOMServer.renderToString(
        mapIcon(text, startcolor, endcolor, textcolor, iconed, strokeColor)
      )
    )
  );
}
