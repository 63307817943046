export const USER_PROFILE_SETTINGS = "user.USER_PROFILE_SETTINGS";
export const USER_ADD = "user.USER_ADD";
export const USER_ADD_ERROR = "user.USER_ADD_ERROR";
export const USER_DEL = "user.USER_DEL";
export const USER_ROLES = "user.USER_ROLES";
export const USER_LIST = "user.USER_LIST";

export const USER_ROLE = "user.USER_ROLE";
export const USER_ROLE_ADD = "user.USER_ROLE_ADD";
export const USER_ROLE_DEL = "user.USER_ROLE_DEL";
export const USER_ROLE_EDIT = "user.USER_ROLE_EDIT";

export const SET_USER_PROFILE_SETTINGS = "user.SET_USER_PROFILE_SETTINGS";
export const SET_USER_PASSWORD = "user.SET_USER_PASSWORD";
