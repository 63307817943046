import React, { Component } from 'react'
import '../../../../components/BaseModal/styles.less'
import './index.less'
import { Map, Marker } from 'react-leaflet'
import Modal from '../../../../components/BaseModal'
import PolylineText from '../../../../components/MapDefault/src/polyline-text/PolylineText'

import {
  assignedPopup,
  directionsPathOptionsAnyColors,
  getRouteMarkers,
  getRouteBounds,
  fnMouseOver,
  fnMouseOut,
  redirectionIcon
} from '../../../../components/MapDefault/mapSpecialFns'
import { connect } from 'react-redux'
import Legend from '../../../../components/MapDefault/legend/LegendView'
import DepotsPins from '../../../Run/components/MapContainer/components/DepotsPins'
import * as routesActions from '../../../../redux/routesView/actions'
import _ from 'lodash'
import { BaseGoogleLayer } from '../../../../components/GoogleLayer/BaseGoogleLayer'

class MapZoom extends Component {

  arrowOptions = {
    repeat: true,
    offset: 3,
    attributes: {
      fill: 'white',
      'font-weight': 'normal',
      'font-size': '12'
    }
  }

  constructor (props) {
    super(props)
    this.state = {
      run: {},
      zoomMapId: props.zoomMapId,
      routesView: this.props.routesView,
      loadFirst: false,
      isLoading: false
    }
  }

  componentDidMount () {
    this.getRunsActive()
  }

  componentWillReceiveProps (nextProps) {
    if (this.state.routesView !== nextProps.routesView) {
      this.setState({
        routesView: nextProps.routesView
      }, () => {
        this.getRunsActive()
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loader !== this.props.loader) {
      this.setState({
        isLoading: this.props.loader === 'loadingStart'
      });
    }
  }

  childRiders = (marker, readOnly) => {
    let riders = []
    let ridersTransfer = []

    let activeRoute = this.getRunById(marker.object.routeId)

    if (!activeRoute) {
      return <div></div>

    }

    let sc = null
    if (activeRoute.points) {
      sc = activeRoute.points.filter(item => item.type === 3)
    }

    if (marker.object.type * 1 === 3) {
      if (!_.isEmpty(sc)) {
        let obj = sc.find(x => x.id === marker.object.id)
        if (!obj) {
          return
        }

        if (!obj.routePointChildren) {
          return
        }

        let pickUpChild = obj.routePointChildren.filter(x => {
          if (x.childaddress && x.childaddress.type === 1) return true
        })
        let dropOffChild = obj.routePointChildren.filter(x => {
          if (x.childaddress && x.childaddress.type === 2) return true
        })

        pickUpChild.map(address => {
          let child = this.props.childrenList.find(
            child => child.id === address.childaddress.child_id
          )
          if (!child) return false
          child.address = address
          riders.push({
            name: child.full_name,
            id: child.id,
            address: child.address
          })
        })

        dropOffChild.map(address => {
          if (this.props.childrenList.length === 0) return false

          let child = this.props.childrenList.find(
            child => child.id === address.childaddress.child_id
          )
          if (!child) return false
          child.address = address
          riders.push({
            name: child.full_name,
            id: child.id,
            address: child.address
          })
        })
      }
    }

    if (marker.object.type * 1 === 5) {
      if (!marker.object) {
        return
      }

      if (_.isEmpty(marker.object.routePointTransferChild)) {
        return
      }

      let pickUpChild = marker.object.routePointTransferChild.filter(
        x => x.childAddress.type === 1
      )
      let dropOffChild = marker.object.routePointTransferChild.filter(
        x => x.childAddress.type === 2
      )

      if (pickUpChild) {
        pickUpChild.map(item => {
          let child = item.childAddress.child
          item.routeId = marker.object.routeId

          ridersTransfer.push({
            name: child.full_name,
            id: child.id,
            address: item.address,
            data: item
          })
        })
      }

      if (dropOffChild) {
        dropOffChild.map(item => {
          let child = item.childAddress.child
          item.routeId = marker.object.routeId

          ridersTransfer.push({
            name: child.full_name,
            id: child.id,
            address: item.address,
            data: item
          })
        })
      }

      if (ridersTransfer) {
        return ridersTransfer.map(item => {
          return (
            <div className="lineBottom">
              <span className="riderIcon flex1">
                {item.name}{' '}
                {!readOnly && (
                  <div className="marginLeft flex1">
                    {' '}
                    {item.data && item.data.childAddress.type === 1 ? (
                      <span class="infoDo">DO</span>
                    ) : (
                      <span class="infoDo">PU</span>
                    )}{' '}
                    <button
                      onClick={e => this.childRemoveTransfer(item)}
                      className="removeFromRun"
                    >
                      &nbsp;
                    </button>{' '}
                  </div>
                )}
              </span>
            </div>
          )
        })
      }
    }

    if (marker.object.type * 1 === 2) {
      if (!marker.object) {
        return
      }

      let pickUpChild = marker.object.routePointChildren.filter(x => {
        if (x.childaddress && x.childaddress.type === 1) return true
      })
      let dropOffChild = marker.object.routePointChildren.filter(x => {
        if (x.childaddress && x.childaddress.type === 2) return true
      })

      pickUpChild.map(address => {
        let child = marker.object.object.find(
          child => child.id === address.childaddress.child_id
        )
        child.address = address
        riders.push({
          name: child.full_name,
          id: child.id,
          address: child.address
        })
      })

      dropOffChild.map(address => {
        let child = marker.object.object.find(
          child => child.id === address.childaddress.child_id
        )
        child.address = address
        riders.push({
          name: child.full_name,
          id: child.id,
          address: child.address
        })
      })
    }

    return riders.map(item => {
      return (
        <div className="lineBottom">
          <span className="riderIcon">
            {item.name}{' '}
            {!readOnly && (
              <span
                onClick={e => this.childRemoveModal(item)}
                className="removeFromRun"
              >
                &nbsp;
              </span>
            )}
          </span>
        </div>
      )
    })
  }

  getRunById = (id) => {
    let run = null

    this.state.routesView.forEach(gr => {
      gr.routes.forEach(x => {
        if (id === x.id) {
          run = x
        }
      })
    })

    return run

  }

  getRunsActive = () => {
    let zoomMapId = this.props.zoomMapId
    let run = this.getRunById(zoomMapId)

    if (!run.osrm) {
      let defSettings = {
        curbside_pickup: 0,
        no_uturns: 0
      }

      if (!this.state.loadFirst) {
        this.props.dispatch(routesActions.getRunDetails(zoomMapId, defSettings))
        this.setState({
          loadFirst: true
        })
      }
    } else {
      run.markers = getRouteMarkers(run)
      run.bounds = getRouteBounds(run)

      this.setState({
        run: run
      })
    }
  }

  rend = () => {
    let run = this.state.run
    let { isLoading } = this.state
    let assignedPopupBind = assignedPopup.bind(this)
    if (run) {
      return (
        <div>
          <div key={run.id}>
            {run.osrm &&
            <PolylineText
              arrowOptions={this.arrowOptions}
              pathOptions={directionsPathOptionsAnyColors(0)}
              geometry={run.osrm.geometry}
              // onMouseOver={(e)=>this.onMouseEvent(e,run)}
              // onMouseOut={(e)=>this.onMouseEvent(e,run,true)}
            />
            }

            {run.markers &&
            run.markers.map(marker => {
              if (marker) {

                let index = 1000

                index += marker.object.position * 100

                return (
                  <Marker
                    draggable={false}
                    key={marker.id}
                    onMouseOver={e => fnMouseOver(e)}
                    onMouseOut={e => fnMouseOut(e)}
                    zIndexOffset={index}
                    position={marker.location}
                    icon={marker.object.type === 4
                      ? redirectionIcon()
                      : marker.icon}
                  >
                    {assignedPopupBind(marker, true, isLoading)}
                  </Marker>
                )
              }
            })}
          </div>
        </div>
      )

    } else {
      return {}
    }
  }

  getBounds = () => {
    let bounds = []

    let run = this.state.run
    if (run.points) {
      return run.points.map(point => {
        return [point.address.lat, point.address.lon]
      })
    }

    if (_.isEmpty(bounds)) {
      return [[24.664018, -123.461071], [46.088543, -56.280215]]
    }
  }

  render () {
    let runrend = this.rend()
    return (
      <Modal
        class={'ModalClose open mapZoom'}
        save={this.handleSave}
        closeFn={this.props.closeFn}
      >
        <div className={'mapCol'}>
          <div className="map">
            <div>
              <Map
                bounds={this.getBounds(runrend)}
                ref={this.map}

              >
                {runrend}
                <BaseGoogleLayer/>
                <DepotsPins routesViewPage={true}/>
                <Legend/>
              </Map>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

function mapStateToProps (state) {
  return {
    childrenList: state.children.childrenList,
    depots: state.auth.depots,
    routesView: state.routesView.routes,
    loader: state.headerLoader.loading
  }
}

const mapDispatchToProps = dispatch => {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapZoom)

