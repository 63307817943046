import React, { Component } from 'react'
import { connect } from 'react-redux'

import Input from 'antd/es/input'
import Select from './../../../../../components/customSelect/index'

import DatePicker from 'antd/es/date-picker'
import BaseModal from '../../../../../components/BaseModal'
import moment from 'moment'

import './index.less'
import { CreatePointRequest, StopPoint, StopPointType } from '../../models'

import { School } from '../../../../Riders/models'
import { NewSingleDatePicker } from '../../../../../entities/ui/NewSingleDatePicker/NewSingleDatePicker'
import { CalendarIcon, ClearIcon } from '../../../../../img/icons'
import { NewAntInput } from '../../../../../entities/ui/NewInput/NewAntInput'

const dateFormat = 'MM/DD/YYYY h:mm A'

/**
 * @typedef {object} PropsModel
 * @prop {School[]} schools
 * @prop {StopPoint} editData
 *
 * @extends {Component<PropsModel>}
 */
class ModalAddSchool extends Component {
  /**
   *
   * @param {PropsModel} props
   */
  constructor(props) {
    super(props)
    this.state = {
      form: {
        school_id: props.editData
          ? props.schools.find((x) => x.name === props.editData.title).id
          : 0,
        waittime: props.editData ? props.editData.waittime : null,
        position: props.editData ? props.editData.position : null,
        time: props.editData ? props.editData.osrmTime : null,
        anchor_time: props.editData ? props.editData.anchor_time : null,
      },
      formErrors: {
        school_id: false,
      },
      formEmpty: {
        school_id: props.editData ? false : true,
      },
    }
  }

  disabledDate = (current, id) => {
    return current && current < moment().subtract(1, 'days')
  }

  createPoint = () => {
    const { form, formErrors, formEmpty } = this.state
    let isError = false

    Object.keys(formEmpty).forEach((type) => {
      if (formEmpty[type]) {
        formErrors[type] = true
        isError = true
      }
    })

    if (isError) {
      this.setState({
        formErrors: formErrors,
      })

      return
    }

    const school = this.props.schools.find((x) => x.id === form.school_id)
    if (!school) {
      return
    }

    const request = new CreatePointRequest(
      form.time,
      form.waittime,
      school.address.address,
      StopPointType.School,
      form.position,
      school.name
    )

    this.props.createPointFn(request)
    this.props.close()
  }

  saveStop = () => {
    const { form, formErrors, formEmpty } = this.state
    let isError = false

    Object.keys(formEmpty).forEach((type) => {
      if (formEmpty[type]) {
        formErrors[type] = true
        isError = true
      }
    })

    if (isError) {
      this.setState({
        formErrors: formErrors,
      })

      return
    }

    const school = this.props.schools.find((x) => x.id === form.school_id)
    if (!school) {
      return
    }

    /** @type {StopPoint} */
    const point = {
      id: this.props.editData.id,
      type: StopPointType.School,
      position: form.position,
      address: school.address,
      address_id: this.props.editData.address_id,
      address_link_id: this.props.editData.address_link_id,
      waittime: form.waittime,
      time: form.time,
      name: school.name,
      curbside_pickup_only: this.props.editData.curbside_pickup_only,
      use_default_rule: this.props.editData.use_default_rule,
      fieldTripRiders: this.props.editData.fieldTripRiders,
      fieldTripRiderAdults: this.props.editData.fieldTripRiderAdults,
      isDeleted: this.props.editData.isDeleted,
    }

    this.props.editPointFn(
      point,
      school.address.address === this.props.editData.address.address
        ? false
        : true
    )
    this.props.close()
  }

  handleChange = (type, value) => {
    const form = this.state.form

    if (type === 'waittime') {
      value = !isNaN(+value) ? value * 60 : form.waittime
    }

    if (type === 'school_id') {
      form['waittime'] =
        (this.props.schools.find((x) => x.id === value)?.stop_time ?? 0) * 60
    }

    form[type] = value

    this.setState({
      form: form,
    })

    if (this.state.formErrors.hasOwnProperty(type)) {
      if (!value || value === '') {
        let errors = this.state.formErrors
        errors[type] = true

        let empty = this.state.formEmpty
        empty[type] = true

        this.setState({
          formErrors: errors,
          formEmpty: empty,
        })
      } else {
        let errors = this.state.formErrors
        errors[type] = false

        let empty = this.state.formEmpty
        empty[type] = false

        this.setState({
          formErrors: errors,
          formEmpty: empty,
        })
      }
    }
  }

  render() {
    let { close } = this.props
    let { form, formErrors } = this.state

    return (
      <BaseModal
        class="add-point-modal"
        title={this.props.editData ? 'Edit School' : 'Add School'}
        save={this.props.editData ? this.saveStop : this.createPoint}
        closeFn={close}
      >
        <div className="line flexSelectLine">
          <span className={formErrors.school_id ? 'error' : ''}>
            School Name
          </span>
          <Select
            placeholder={'Select school'}
            value={form.school_id}
            className={formErrors.school_id ? 'error' : ''}
            onChange={(e) => this.handleChange('school_id', e)}
          >
            <option className={'hide'} value={0} key={0} disabled>
              Select school
            </option>
            {this.props.schools.map((school) => {
              return (
                <option key={school.id} value={school.id}>
                  {school.name}
                </option>
              )
            })}
          </Select>
        </div>

        <div className="line flex">
          <div
            className="third"
            style={{ paddingRight: '10px', position: 'relative' }}
          >
            <span className={formErrors.time ? 'error' : ''}>
              Date and Time
            </span>
            <span className={form['time'] ? 'timeNotEmpty' : 'timeEmpty'}>
              <NewSingleDatePicker
                getCalendarContainer={(trigger) => trigger.parentNode}
                id="DatePicker"
                disabledDate={this.disabledDate}
                className={formErrors.time ? 'error' : ''}
                value={form.time ? moment(form.time * 1000) : null}
                format={dateFormat}
                showTime={{
                  defaultValue: moment('00:00:00', 'HH:mm'),
                  use12Hours: true,
                  format: 'hh:mm A',
                }}
                onChange={(e) =>
                  this.handleChange('time', e ? e.unix(Number) : null)
                }
                suffixIcon={<CalendarIcon />}
                clearIcon={<ClearIcon />}
                divider
              />
            </span>
          </div>

          <div className="third" style={{ paddingRight: '10px' }}>
            <span>Wait Time for This Stop</span>
            <NewAntInput
              autoFocus={true}
              onChange={(e) => this.handleChange('waittime', e.target.value)}
              value={form.waittime / 60}
              placeholder="In Minutes"
            />
          </div>

          <div className="third">
            <span>Enter Stop Number</span>
            <NewAntInput
              onChange={(e) => this.handleChange('position', e.target.value)}
              value={form.position}
              placeholder="Last on the List"
            />
          </div>
        </div>

        <div className="center">
          <div className="buttons">
            <button className="cancel" onClick={close}>
              Cancel
            </button>
            <button
              className="save"
              onClick={this.props.editData ? this.saveStop : this.createPoint}
            >
              Save
            </button>
          </div>
        </div>
      </BaseModal>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddSchool)
