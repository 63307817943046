import React, { Component } from 'react'

import { NewCheckbox } from '../../entities/ui/NewCheckbox/NewCheckbox'
import styles from './index.module.less'

class Rules extends Component {
  constructor(props) {
    super(props)
    this.state = {
      use_default_rules: this.props.route.use_default_rules,
      no_uturns: this.props.route.no_uturns,
      curbside_pickup: this.props.route.curbside_pickup,

      curbside_pickup_company: this.props.company.curbside_pickup,
      no_uturns_company: this.props.company.no_uturns,
    }
  }

  onChecked = (e) => {
    let value = e.target.checked ? 1 : 0

    this.setState({
      use_default_rules: value,
    })

    this.props.fn(this.props.route.id, 'use_default_rules', value)
  }

  HandleChenge = (e) => {
    let value = e.target.checked ? 1 : 0

    this.setState({
      [e.target.name]: value,
    })

    this.props.fn(this.props.route.id, e.target.name, value)
  }
  render() {
    let {
      curbside_pickup_company,
      no_uturns_company,
      curbside_pickup,
      no_uturns,
      use_default_rules,
    } = this.state
    return (
      <div style={this.props.style} id="ftRules" className={styles.root}>
        <NewCheckbox
          type="checkbox"
          checked={use_default_rules}
          onChange={(e) => this.onChecked(e)}
        >
          Use Default Settings
        </NewCheckbox>
        <NewCheckbox
          type="checkbox"
          disabled={use_default_rules}
          onChange={(e) => this.HandleChenge(e)}
          name="no_uturns"
          style={{ marginLeft: 20 }}
          checked={use_default_rules ? no_uturns_company : no_uturns}
        >
          Avoid U-Turns
        </NewCheckbox>
        <NewCheckbox
          type="checkbox"
          disabled={use_default_rules}
          style={{ marginLeft: 20 }}
          name="curbside_pickup"
          onChange={(e) => this.HandleChenge(e)}
          checked={
            use_default_rules ? curbside_pickup_company : curbside_pickup
          }
        >
          Curbside Pickup Only
        </NewCheckbox>
      </div>
    )
  }
}

export default Rules
