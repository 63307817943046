import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from 'antd/es/button'
import Select from '../../../../../components/customSelect/index'
import Tabs from 'antd/es/tabs'
import Popover from 'antd/es/popover'

import BaseModal from '../../../../../components/BaseModal'

import * as fieldTripRequestsActions from '../../../../../redux/fieldTrip/requests/actions'
import TabFieldTripRun from './../TabFieldTripRun/index'
import ModalInfo from '../ModalInfo'
import { FTRErrorType } from '../../../Requests/enums'
import TabTripDetails from '../TabTripDetails'
import TabTripCost from '../TabTripCost'
import TabTripApproval from '../TabTripApproval'
import MapAllRuns from '../MapAllRuns'
import ModalAllRuns from '../ModalAllRuns'

import _ from 'lodash'
import Input from 'antd/es/input'

import { Bus, Driver, FieldTripRequest, StopPointType } from '../../models'
import { LeftArrowIcon, RightArrowIcon } from '../../../../../img/icons'
import { browserHistory } from 'react-router'
import { NewTabs } from '../../../../../entities/ui/NewTabs/NewTabs'
import { MainTab } from './MainTab/MainTab'
import { PrintModal } from '@/pages/FieldTrip/Run/components/PrintModal/PrintModal'
import { ModalsEnum, useModalStore } from '@/store/modal/ModalStore'

const TabPane = Tabs.TabPane

class PropsModel {
  /** @type {{ data: FieldTripRequest[] }} */
  fieldTripRequests

  /** @type {Bus[]} */
  buses

  /** @type {Driver[]} */
  drivers

  /** @type {number} */
  transitionId
}

/**
 * @typedef {PropsModel} PropsType
 * @extends {Component<PropsType>}
 */
class BuildFieldTrip extends Component {
  /**
   *
   * @param {Readonly<PropsType>} props
   */
  constructor(props) {
    super(props)
    this.state = {
      fieldTripRequests: props.fieldTripRequests.data,
      buses: props.buses,
      drivers: props.drivers,
      menuToggle: false,
      selectedTrip: null,
      activeRun: null,
      transitionId: props.transitionId,
      transferFlag: false,
      modalOverview: false,
      modalDelete: false,
      isEditingName: false,
      modalInfo: {
        title: '',
        content: '',
        display: false,
      },
      verifiedRuns: [],
    }
  }

  componentDidMount() {}

  /**
   *
   * @param {Readonly<PropsType>} nextProps
   */
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.fieldTripRequests.error &&
      nextProps.fieldTripRequests.error.type == FTRErrorType.FieldTripSubmit
    ) {
      this.displayModalInfo('Error', nextProps.fieldTripRequests.error.message)
      return
    }

    if (nextProps.fieldTripRequests.successSubmit) {
      this.displayModalInfo(
        'Field Trip has been submitted for Approval',
        'Emails with the trip details have been sent to the Approvers'
      )
    }

    if (this.state.fieldTripRequests !== nextProps.fieldTripRequests.data) {
      if (!this.state.transferFlag && this.props.transitionId) {
        this.setState(
          {
            fieldTripRequests: nextProps.fieldTripRequests.data,
          },
          () => {
            let find = this.state.fieldTripRequests.find(
              (i) => i.id === this.props.transitionId * 1
            )

            if (find && !find.fieldTrip) {
              this.props.dispatch(
                this.props.fieldTripRequestsActions.getRequestsBuilder()
              )
            }
            if (
              find &&
              find.fieldTrip &&
              (find.status === 2 || find.status === 3 || find.status === 4)
            ) {
              this.selectTrip(this.props.transitionId * 1)
              this.setState({
                transferFlag: true,
              })
            } else if (find && find.fieldTrip) {
              browserHistory.push('/client/FieldTrips/run/')
              this.setState({
                transferFlag: true,
                transitionId: null,
              })
            }
          }
        )
      }

      if (this.state.activeRun) {
        const trip = nextProps.fieldTripRequests.data.find(
          (x) => x.field_trip_id === this.state.activeRun.trip_id
        )

        this.setState({
          selectedTrip: trip,
        })
      }
    }
    this.setState({
      fieldTripRequests: nextProps.fieldTripRequests.data,
      buses: nextProps.buses,
      drivers: nextProps.drivers,
    })
  }

  selectTrip = (tripID) => {
    const trip = this.props.fieldTripRequests.data.find((x) => x.id == tripID)

    if (!trip) {
      return
    }

    browserHistory.push(`/client/FieldTrips/run/${tripID}`)

    let activeRun = null

    if (!trip.fieldTrip) {
      return
    }

    if (trip.fieldTrip.fieldTripRuns.length) {
      activeRun = trip.fieldTrip.fieldTripRuns[0]
    }

    this.props.setActiveTrip(tripID)

    this.setState(
      {
        selectedTrip: trip,
      },
      () => {
        if (activeRun) {
          this.setActiveRun(trip.fieldTrip.fieldTripRuns.indexOf(activeRun))
        }
      }
    )
  }

  removeRider = (runId, pointId, riderId, isAdult) => {
    let { selectedTrip } = this.state

    if (!selectedTrip || !selectedTrip.fieldTrip) {
      return
    }

    const request = {
      fieldTripRuns: this.removeAddresses(selectedTrip.fieldTrip.fieldTripRuns),
    }

    const point = request.fieldTripRuns
      .find((x) => x.id == runId)
      .fieldTripPoints.find((x) => x.id == pointId)

    if (isAdult) {
      request.fieldTripRuns
        .find((x) => x.id == runId)
        .fieldTripPoints.find((x) => x.id == pointId).fieldTripRiderAdults =
        point.fieldTripRiderAdults.filter((x) => x.id != riderId)
    } else {
      request.fieldTripRuns
        .find((x) => x.id == runId)
        .fieldTripPoints.find((x) => x.id == pointId).fieldTripRiders =
        point.fieldTripRiders.filter((x) => x.id != riderId)
    }

    this.props.dispatch(
      fieldTripRequestsActions.editTrip(selectedTrip.fieldTrip.id, request)
    )
  }

  setActiveRun = (index) => {
    if (this.state.selectedTrip) {
      const run = this.state.selectedTrip.fieldTrip.fieldTripRuns[index]
      this.props.setActiveRun(run.id)
      this.props.setActiveRunPoints(run.fieldTripPoints)

      this.setState({
        activeRun: run,
      })
    }
  }

  saveTripName = (id, name) => {
    let { selectedTrip } = this.state

    if (selectedTrip.fieldTrip.status == 2) {
      return
    }

    if (name == '') {
      return
    }

    this.setState({
      isEditingName: !this.state.isEditingName,
    })

    this.props.dispatch(
      fieldTripRequestsActions.editTripName(selectedTrip.fieldTrip.id, name)
    )
  }

  changeBusInRun = (runID, busID) => {
    let { selectedTrip } = this.state

    if (selectedTrip.fieldTrip.status == 2) {
      return
    }

    if (busID == 0) {
      busID = null
    }

    if (runID == 0) {
      return
    }

    const runs = this.removeAddresses(selectedTrip.fieldTrip.fieldTripRuns)
    runs.find((x) => x.id == runID).bus_id = busID

    const request = { fieldTripRuns: runs }
    this.props.dispatch(
      fieldTripRequestsActions.editTrip(selectedTrip.fieldTrip.id, request)
    )
  }

  changeDriverInRun = (runID, driverID) => {
    let { selectedTrip } = this.state

    if (selectedTrip.fieldTrip.status == 2) {
      return
    }

    if (driverID == 0) {
      driverID = null
    }

    if (runID == 0) {
      return
    }

    const runs = this.removeAddresses(selectedTrip.fieldTrip.fieldTripRuns)
    runs.find((x) => x.id == runID).driver_id = driverID

    const request = { fieldTripRuns: runs }
    this.props.dispatch(
      fieldTripRequestsActions.editTrip(selectedTrip.fieldTrip.id, request)
    )
  }

  copyRun = (runID) => {
    let { selectedTrip } = this.state

    if (selectedTrip.fieldTrip.status == 2) {
      return
    }

    const runs = this.removeAddresses(selectedTrip.fieldTrip.fieldTripRuns)
    const copyRun = runs.find((x) => x.id === runID)

    if (!copyRun) {
      return
    }

    const newRun = new Object()

    Object.keys(copyRun).forEach((key) => {
      if (key !== 'id' && key !== 'fieldTripPoints') {
        newRun[key] = copyRun[key]
      }
    })

    newRun.name = newRun.name + ' - copy'

    newRun.bus_id = null
    newRun.driver_id = null

    newRun.bus = null
    newRun.driver = null

    newRun.fieldTripPoints = []
    copyRun.fieldTripPoints.forEach((point) => {
      const newPoint = new Object()

      newPoint.fieldTripRiders = []
      newPoint.fieldTripRiderAdults = []

      Object.keys(point).forEach((key) => {
        if (
          key !== 'id' &&
          key !== 'fieldTripRiders' &&
          key !== 'fieldTripRiderAdults' &&
          key !== 'address'
        ) {
          newPoint[key] = point[key]
        }
      })

      newRun.fieldTripPoints.push(newPoint)
    })

    copyRun.fieldTripPoints.map((point) => {
      delete point.address
      return point
    })

    runs.push(newRun)

    const request = { fieldTripRuns: runs }
    request.fieldTripRuns.find((x) => x.id == copyRun.id).fieldTripPoints =
      copyRun.fieldTripPoints
    this.props.dispatch(
      fieldTripRequestsActions.editTrip(
        this.state.selectedTrip.fieldTrip.id,
        request
      )
    )
  }

  removeAddresses = (fieldTripRuns) => {
    fieldTripRuns.map((run) => {
      run.fieldTripPoints.map((point) => {
        delete point.address
        return point
      })

      return run
    })

    return fieldTripRuns
  }

  onEdit = (targetKey, action) => {
    this[action](targetKey)
  }

  submitTrip = () => {
    let rezult = this.checkFTErrors()
    if (!rezult) return

    this.props.submitTripFn(this.state.selectedTrip.fieldTrip.id)
  }

  verifyTrip = () => {
    let rezult = this.checkFTErrors()
    if (!rezult) return

    this.displayModalInfo(
      'Verify',
      'Your field trip is verified and ready for approval'
    )
  }

  checkFTErrors = () => {
    let { selectedTrip } = this.state

    if (!selectedTrip.fieldTrip.fieldTripRuns.length) {
      this.displayModalInfo(
        'Missing trip information',
        'Please fix following errors'
      )

      return
    }

    let errors = []

    selectedTrip.fieldTrip.fieldTripRuns.forEach((run) => {
      let stops = 0
      let ridersCount = 0
      let timeError = true

      run.fieldTripPoints.forEach((point) => {
        ridersCount += point.fieldTripRiders.length
        ridersCount += point.fieldTripRiderAdults.length
        if (point.type != StopPointType.Depot) {
          stops++
        }

        if (point.time) {
          timeError = false
        }
      })

      if (!run.bus_id && !run.driver_id) {
        errors.push(`<b>${run.name}:</b> Missing Driver/Bus.<br/>`)
      } else if (!run.bus_id && run.driver_id) {
        errors.push(`Set Bus for the run <b>${run.name}</b><br/>`)
      } else if (run.bus_id && !run.driver_id) {
        errors.push(`Set Driver for the run <b>${run.name}</b><br/>`)
      }

      if (!ridersCount) {
        errors.push(`<b>${run.name}</b>: No riders assigned.`)
      }

      if (!stops) {
        errors.push(
          `Add at least 2 Stops (exept Depot) for the run <b>${run.name}</b><br/>`
        )
      }

      if (timeError) {
        errors.push(
          `<b>${run.name}</b>: At least one stop must have date/time associated with it. <br/>`
        )
      }
    })

    let runs = [...selectedTrip.fieldTrip.fieldTripRuns]

    runs.forEach((leftRun, index) => {
      if (!this.state.verifiedRuns.includes(leftRun)) {
        const rightRun = runs[index + 1]
        if (this.compareRunsByAddress(leftRun, rightRun)) {
          if (this.compareRunsByBusDriver(leftRun, rightRun)) {
            errors.push(
              `<b>${leftRun.name}</b>, <b>${rightRun.name}</b> should be asssigned to differrent buses and drivers<br/>`
            )
          }
        }

        this.state.verifiedRuns.push(leftRun)
      }
    })

    errors = Array.from(new Set(errors))

    if (errors.length) {
      let errorText = ``

      errors.forEach((error) => {
        errorText += `<div style="margin-bottom: 15px;"><div class="error-point" style="width: 8px; height: 8px; background-color: #40b2e8; border-radius: 4px; display: inline-block; margin-right: 10px; position: relative; top: -2px;"></div>${error}</div>`
      })

      this.displayModalInfo('Requestor can not be informed', errorText)
      this.state.verifiedRuns = []

      return
    }

    this.state.verifiedRuns = []
    return true
  }

  compareRunsByBusDriver = (leftRun, rightRun) => {
    if (!leftRun || !rightRun) return
    if (leftRun.id == rightRun.id) return

    if (
      leftRun.bus_id == rightRun.bus_id &&
      leftRun.driver_id == rightRun.driver_id
    ) {
      return true
    }

    return false
  }

  compareRunsByAddress = (leftRun, rightRun) => {
    if (!leftRun || !rightRun) return
    if (leftRun.id == rightRun.id) return

    let result = true
    let leftPoints = [...leftRun.fieldTripPoints]
    let rightPoints = [...rightRun.fieldTripPoints]

    if (leftPoints.length != rightPoints.length) {
      result = false
      return result
    }

    leftPoints.forEach((leftPoint, index) => {
      let rightPoint = rightPoints[index]
      if (leftPoint.type != rightPoint.type) {
        result = false
        return
      }

      if (leftPoint.address && rightPoint) {
        if (
          rightPoint.address &&
          rightPoint.address.id == leftPoint.address.id
        ) {
          result = true
        } else {
          result = false
        }
      }
    })

    return result
  }

  reversePoints = (runId) => {
    let { selectedTrip } = this.state

    if (!selectedTrip || !selectedTrip.fieldTrip) {
      return
    }

    const request = this.copyRunLocal(runId)

    const points = _.orderBy(
      request.fieldTripRuns.find((x) => x.copied).fieldTripPoints,
      ['position'],
      ['desc']
    ).map((point, index) => {
      point.position = index + 1
      return point
    })

    request.fieldTripRuns.find((x) => x.copied).fieldTripPoints = points
    delete request.fieldTripRuns.find((x) => x.copied).copied

    this.props.dispatch(
      fieldTripRequestsActions.reversePoints(selectedTrip.fieldTrip.id, request)
    )
  }

  copyRunLocal = (runID) => {
    let { selectedTrip } = this.state

    if (selectedTrip.fieldTrip.status == 2) {
      return
    }

    const runs = this.removeAddresses(selectedTrip.fieldTrip.fieldTripRuns)
    const copyRun = runs.find((x) => x.id === runID)

    if (!copyRun) {
      return
    }

    const newRun = new Object()
    newRun['copied'] = true

    Object.keys(copyRun).forEach((key) => {
      if (key !== 'id' && key !== 'fieldTripPoints') {
        newRun[key] = copyRun[key]
      }
    })

    newRun.name = newRun.name + ' - reverse'

    newRun.bus_id = null
    newRun.driver_id = null

    newRun.bus = null
    newRun.driver = null

    newRun.fieldTripPoints = []
    copyRun.fieldTripPoints.forEach((point) => {
      const newPoint = new Object()

      newPoint.fieldTripRiders = []
      newPoint.fieldTripRiderAdults = []

      Object.keys(point).forEach((key) => {
        if (
          key !== 'id' &&
          key !== 'fieldTripRiders' &&
          key !== 'fieldTripRiderAdults' &&
          key !== 'address'
        ) {
          newPoint[key] = point[key]
        }

        if (key == 'fieldTripRiders') {
          point.fieldTripRiders.forEach((rider) => {
            const newRider = {
              child_id: rider.child_id,
              run_id: rider.run_id,
            }

            newPoint['fieldTripRiders'].push(newRider)
          })
        }

        if (key == 'fieldTripRiderAdults') {
          point.fieldTripRiderAdults.forEach((rider) => {
            const newRider = {
              name: rider.name,
              phone: rider.phone,
              notes: rider.note,
              point_id: rider.point_id,
            }

            newPoint.fieldTripRiderAdults.push(newRider)
          })
        }
      })

      newRun.fieldTripPoints.push(newPoint)
    })

    copyRun.fieldTripPoints.map((point) => {
      delete point.address
      return point
    })

    runs.push(newRun)

    const request = { fieldTripRuns: runs }
    request.fieldTripRuns.find((x) => x.id == copyRun.id).fieldTripPoints =
      copyRun.fieldTripPoints

    return request
  }

  editRules = (id, Name, value) => {
    let { selectedTrip } = this.state

    if (selectedTrip.fieldTrip.status == 2) {
      return
    }

    const runs = this.removeAddresses(selectedTrip.fieldTrip.fieldTripRuns)

    runs.find((x) => x.id == id)[Name] = value

    const request = { fieldTripRuns: runs }

    this.props.dispatch(
      fieldTripRequestsActions.editTrip(selectedTrip.fieldTrip.id, request)
    )
  }

  saveRunName = (id, name) => {
    let { selectedTrip } = this.state

    if (selectedTrip.fieldTrip.status == 2) {
      return
    }

    const runs = this.removeAddresses(selectedTrip.fieldTrip.fieldTripRuns)
    runs.find((x) => x.id == id).name = name

    const request = { fieldTripRuns: runs }
    this.props.dispatch(
      fieldTripRequestsActions.editTrip(selectedTrip.fieldTrip.id, request)
    )
  }

  displayModalInfo = (title, content) => {
    this.setState({
      modalInfo: {
        title: title,
        content: content,
        display: true,
      },
    })
  }

  hideModalInfo = () => {
    this.setState({
      modalInfo: {
        title: '',
        content: '',
        display: false,
      },
    })
  }

  add = () => {
    let { selectedTrip } = this.state

    if (selectedTrip.fieldTrip.status == 2) {
      return
    }

    this.props.addRunFn(selectedTrip.id)
  }

  remove = (targetKey = null, runID = null) => {
    let { selectedTrip } = this.state

    if (selectedTrip.fieldTrip.status == 2) {
      return
    }

    this.setState({
      modalDelete: true,
      runID: runID,
      selectedTripId: selectedTrip.id,
    })
  }

  isTripReadonly = () => {
    let { selectedTrip } = this.state

    if (!selectedTrip || !selectedTrip.fieldTrip) {
      return true
    }

    switch (selectedTrip.fieldTrip.status) {
      case 1:
        return false
      case 2:
        return true
      case 3:
        if (selectedTrip.fieldTrip.lock) return true
        else return false
      case 4:
        if (selectedTrip.fieldTrip.lock) return true
        else return false
      default:
        return true
    }
  }

  getButtonState = () => {
    let { selectedTrip } = this.state
    let result = false

    if (!selectedTrip || !selectedTrip.fieldTrip) {
      return true
    }

    if (selectedTrip.fieldTrip.status == 2) {
      return true
    }

    if (
      !selectedTrip.fieldTrip.fieldTripRuns ||
      !selectedTrip.fieldTrip.fieldTripRuns.length
    ) {
      return true
    }

    selectedTrip.fieldTrip.fieldTripRuns.forEach((run) => {
      if (!run.bus_id) {
        result = true
      }

      if (!run.driver_id) {
        result = true
      }

      if (!run.fieldTripPoints || run.fieldTripPoints.length < 2) {
        result = true
      }
    })

    return result
  }

  selectActive = () => {
    let { fieldTripRequests, transitionId, selectedTrip } = this.state

    let selectValue = 0

    let status = fieldTripRequests.filter(
      (x) => x.active && x.field_trip_id && x.fieldTrip !== null
    )

    let unics = _.uniqBy(status, 'field_trip_id')
    unics = unics.map((x) => {
      if (
        x.fieldTrip.status === 1 ||
        x.fieldTrip.status === 2 ||
        (x.fieldTrip.status === 3 && !x.fieldTrip.lock)
      )
        return x
    })
    unics = _.compact(unics)

    if (transitionId) {
      selectValue = parseInt(transitionId)
    } else if (selectedTrip) {
      selectValue = selectedTrip.id
    } else {
      selectValue = 0
    }

    if (unics) {
      return (
        <Select
          className="select"
          value={selectValue}
          onChange={(e) => this.selectTrip(parseInt(e))}
          placeholder={'Select trip'}
        >
          <option className={'hide'} value={0} disabled>
            Select trip
          </option>
          {unics.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                <span>
                  {' '}
                  {item.fieldTrip.name ? item.fieldTrip.name + ', ' : ''}Trip ID{' '}
                  <i>{item.fieldTrip.id} </i>
                </span>
              </option>
            )
          })}
        </Select>
      )
    }
  }

  handleScroll = (event) => {
    let scrollTop = event
  }

  editTripName = () => {
    if (this.state.isReadonly) {
      return
    }

    this.setState({
      isEditingName: true,
    })
  }

  CancelEditTripName = () => {
    this.setState({
      isEditingName: !this.state.isEditingName,
    })
  }

  handleModal = () => {
    this.displayModalInfo('maps', null)
  }

  mapAllRunsTG = () => {
    this.setState({
      mapAllRuns: !this.state.mapAllRuns,
    })
  }

  handleTripNameChange = (name) => {
    this.setState({
      newTripName: name,
    })
  }

  closeModal = () => {
    this.setState({
      modalDelete: false,
    })
  }

  deleteRun = () => {
    this.props.removeRunFn(this.state.selectedTripId, null, this.state.runID)
    this.closeModal()
  }

  printRun = () => {
    useModalStore
      .getState()
      .onOpenModal({ currentModal: ModalsEnum.PRINT_MODAL })
  }

  cont = (name) => {
    if (name.length > 18) {
      return (
        <div className="contentText" style={{ margin: -8 }}>
          {name} really long Name of a Run
        </div>
      )
    }
  }

  render() {
    let { fieldTripRequests, selectedTrip, activeRun, menuToggle, modalInfo } =
      this.state
    let trip = selectedTrip
    let lock = true
    if (this.state.selectedTrip) {
      trip = this.state.fieldTripRequests.find(
        (x) => x.id == this.state.selectedTrip.id
      )
      if (
        trip.fieldTrip.status === 1 ||
        (trip.fieldTrip.status === 3 && !trip.fieldTrip.lock)
      ) {
        lock = false
      }
    }

    let runs
    if (this.state.selectedTrip) {
      runs = this.state.selectedTrip.fieldTrip.fieldTripRuns
    }

    const text = (
      <span className="runText">Run 2 really long Name of a Run</span>
    )

    return (
      <div
        className={`buildRunFT buildRun   ${menuToggle ? 'buildRunMin' : ''} `}
      >
        <div
          className="btnBuildRun"
          onClick={() => this.setState({ menuToggle: !menuToggle })}
        >
          {!menuToggle ? <RightArrowIcon /> : <LeftArrowIcon />}
        </div>

        <div className="flexRow">
          <p className="title">Build Field Trip</p>

          {selectedTrip && (
            <span onClick={this.mapAllRunsTG} className={'overviewIcon'}>
              <span>Overview</span>
            </span>
          )}
        </div>

        <div className={trip ? 'row top-buttons' : 'row hidden top-buttons'}>
          {trip && !lock && (
            <div className="button-wrapper">
              <Button className="btn btn-green" onClick={this.submitTrip}>
                Submit for Approval
              </Button>
            </div>
          )}

          {trip && !lock && (
            <div className="button-wrapper">
              <Button className="btn btn-blue" onClick={this.verifyTrip}>
                Verify
              </Button>
            </div>
          )}
        </div>

        <div className="row select-trip">
          <div className="input-wrapper">{this.selectActive()}</div>
        </div>

        {this.state.selectedTrip && (
          <div className="row select-trip">
            <div className="input-wrapper">
              <div className="head-line">
                {this.state.isEditingName && (
                  <label className="LabelText">Trip Name</label>
                )}
                {!this.state.isEditingName && (
                  <div className="run-name">
                    <span className={'ftText'}>
                      {this.state.selectedTrip.fieldTrip.name}
                    </span>
                    {!this.state.isReadonly && (
                      <span onClick={(e) => this.editTripName()}>
                        {trip.fieldTrip.status !== 2 && (
                          <span className={'iconPencil'} />
                        )}
                      </span>
                    )}
                  </div>
                )}

                {this.state.isEditingName && (
                  <div
                    className="run-name"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <span style={{ width: '100%' }}>
                      <Input
                        onChange={(e) =>
                          this.handleTripNameChange(e.target.value)
                        }
                        defaultValue={this.state.selectedTrip.fieldTrip.name}
                      />
                    </span>
                    <div
                      style={{
                        textAlign: 'left',
                        width: '100%',
                        marginTop: '5px',
                      }}
                    >
                      <span
                        style={{ width: '96px', display: 'inline-block' }}
                        onClick={(e) =>
                          this.saveTripName(
                            this.state.selectedTrip.id,
                            this.state.newTripName
                          )
                        }
                      >
                        <span className="Save">Save</span>
                      </span>

                      <span
                        style={{ width: '130px', display: 'inline-block' }}
                        onClick={(e) => this.CancelEditTripName()}
                      >
                        <span className="Cancel">Cancel</span>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!selectedTrip && (
          <div className="empty">
            <div className="imgDefault" />
            <div className="emptyRunText">
              There is No Trip Chosen!
              <br /> Please Select a Trip{' '}
            </div>
          </div>
        )}

        <div
          id="tableList"
          className={trip ? 'row trip-tabs' : 'row hidden trip-tabs'}
        >
          <MainTab>
            {(tabKey) => {
              switch (tabKey) {
                case 1:
                  return (
                    <div className=" trip-runs ">
                      {trip && (
                        <div className="trip-runs-inner">
                          <NewTabs
                            className="tripRunTabs"
                            hideAdd={this.isTripReadonly()}
                            popupClassName={'popupAdd'}
                            addIcon={
                              <>
                                {!this.isTripReadonly() && (
                                  <button
                                    type="button"
                                    className="addRunButton"
                                    // onClick={(e) => {
                                    //   e.preventDefault()
                                    //   this.add(e)
                                    // }}
                                  >
                                    + Run
                                  </button>
                                )}
                              </>
                            }
                            defaultActiveKey="0"
                            type={
                              trip.fieldTrip.status !== 2
                                ? 'editable-card'
                                : 'card'
                            }
                            onEdit={this.onEdit}
                            onTabClick={(key, event) => this.setActiveRun(key)}
                          >
                            {trip.fieldTrip.fieldTripRuns.map((item, index) => {
                              let functions = {
                                toggleModal: this.props.toggleModalFn,
                                toggleModalEdit: this.props.toggleModalEditFn,
                                toggleModalAnchor:
                                  this.props.toggleModalAnchorFn,
                                toggleModalDeletePoint:
                                  this.props.toggleModalDeletePointFn,
                                saveRunName: this.saveRunName,
                                editRules: this.editRules,
                                copyRun: this.copyRun,
                                printRun: this.printRun,
                                deleteRun: this.remove,
                                changeBus: this.changeBusInRun,
                                changeDriver: this.changeDriverInRun,
                                deletePoint: this.props.deletePointFn,
                                reversePoints: this.reversePoints,
                                removeRider: this.removeRider,
                                deleteRider: this.props.deleteRiderFn,
                              }

                              return (
                                <TabPane
                                  closable={false}
                                  tab={
                                    <Popover
                                      style={{
                                        zIndex: 2,
                                      }}
                                      align={{ offset: [0, 10] }}
                                      content={this.cont(item.name)}
                                      title={null}
                                    >
                                      {item.name}
                                    </Popover>
                                  }
                                  key={index}
                                >
                                  <TabFieldTripRun
                                    key={item.id}
                                    childrenList={this.props.childrenList}
                                    run={item}
                                    buses={this.state.buses}
                                    drivers={this.state.drivers}
                                    functions={functions}
                                    isReadonly={this.isTripReadonly()}
                                    handleSetTitle={this.props.handleSetTitle}
                                    osrm={this.props.osrm}
                                    selectedTrip={this.state.selectedTrip.id}
                                  />
                                </TabPane>
                              )
                            })}
                          </NewTabs>
                        </div>
                      )}
                    </div>
                  )
                case 2:
                  return <TabTripDetails data={selectedTrip} />
                case 3:
                  return (
                    <TabTripCost
                      data={selectedTrip}
                      isReadonly={this.isTripReadonly()}
                    />
                  )
                case 4:
                  return <TabTripApproval data={selectedTrip} />
              }
            }}
          </MainTab>
        </div>

        {modalInfo.display && (
          <ModalInfo
            title={this.state.modalInfo.title}
            content={this.state.modalInfo.content}
            closeFn={this.hideModalInfo}
          />
        )}
        <PrintModal run={this.state.activeRun} />

        {this.state.mapAllRuns && (
          <ModalAllRuns title="Overview" closeFn={this.mapAllRunsTG}>
            <div className="content">
              <MapAllRuns
                title={'NAME'}
                ftrs={runs}
                activeRun={this.state.activeRun}
                overview={true}
                tripID={this.state.selectedTrip.id}
              />
            </div>
          </ModalAllRuns>
        )}

        {this.state.modalDelete && (
          <BaseModal
            title="Delete run"
            class="deleteRun"
            closeFn={this.closeModal}
          >
            <div className="line flex">
              <span className="textModal">
                Are you sure you want to delete this run
              </span>
            </div>

            <div className="center">
              <div className="buttons">
                <button className="cancel" onClick={this.closeModal}>
                  Cancel
                </button>
                <button className="save" onClick={this.deleteRun}>
                  Delete
                </button>
              </div>
            </div>
          </BaseModal>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    fieldTripRequests: state.fieldTripRequests,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { fieldTripRequestsActions, dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildFieldTrip)
