import { StatusNewIcon } from '@/assets/icons/StatusNewIcon'
import { StatusProgressIcon } from '@/assets/icons/StatusProgressIcon'

export enum ftRequestsEnum {
  ALL = 0,
  STATUS_NEW = 1,
  STATUS_IN_PROGRESS = 2,
  STATUS_COMPLEATE = 3,
  STATUS_REJECTED = 4,
}

export const ftRequestStatusOptions = [
  // { label: 'All', value: ftRequestsEnum.ALL },
  { label: 'New', value: ftRequestsEnum.STATUS_NEW },
  { label: 'In progress', value: ftRequestsEnum.STATUS_IN_PROGRESS },
]

export const ftStatusIcons = {
  1: <StatusNewIcon />,
  2: <StatusProgressIcon />,
}
