import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import './index.less'
import { FilterToolIcon } from '../../../../img/icons'
import _ from 'lodash'
import SelectAntd from 'antd/es/select'
import {
  getSchoolFromAddresses,
  getZipFromAddresses,
} from '../../../../components/RepeatFunctions/filter'
import styles from './index.module.less'
import Select from '../../../../components/customSelect/index'
import Button from 'antd/es/button'
import FilterZoneLine from './filterZoneLine'
import i18n from '../../../../i18n'
import { useContextFilter } from './FilterContext'
import { GetIcon } from '../../../../img/iconsComponents/GetIcon'
import { NewMultiplySelect } from '../../../../entities/ui/NewMultiplySelect/NewMultiplySelect'
import classnames from 'classnames'
import { RightOutlined } from '@ant-design/icons'

const Option = SelectAntd.Option

export function FilterFn(props) {
  let [menuToggle, setMenuToggle] = useState(false) // открыть основыне настройки
  let [activeFiltersCount, setActiveFiltersCount] = useState(0)

  let [schoolsAr, setSchoolsAr] = useState([])
  let [schoolsSelect, setSchoolsSelect] = useState([])

  let [zipAr, setZipAr] = useState([])
  let [zipSelect, setZipSelect] = useState([])

  let [gradeAr, setGradeAr] = useState([])
  let [gradeSelect, setGradeSelect] = useState([])

  let [transCatAr, setTransCatAr] = useState([])
  let [transCatSelect, setTransCatSelect] = useState([])

  let [addressType, setAddressType] = useState(null) // PICK UP | DROP OFF

  let [addressesPrimary, setAddressesPrimary] = useState(false)
  let [pinsShow, setPinsShow] = useState(true)

  let [filtersActive, setFiltersActive] = useState(false)

  let { updateFilter, resetAllFilters, resetFilter } = useContextFilter()

  let [zonesActive, setZonesActive] = useState('')

  let loadWithRunId = useRef(undefined)

  let [activeFilters, setActiveFilters] = useState({})

  let [parentClick] = useState({})
  let parentNode = useRef()

  let [zonesList, setZonesList] = useState(props.zonesList)

  useEffect(() => {
    setZonesList(props.zonesList)
  }, [props.zonesList])

  useEffect(() => {
    if (!props.activeRunId) return
    loadWithRunId.current = true
    let isMorning = getIsMorningByRunId(props.activeRunId)
    setAddressType(isMorning)
  }, [props.activeRunId, props.routes])

  // use 1 times after load page with run id
  useEffect(() => {
    if (loadWithRunId.current) {
      applyFilters()
      loadWithRunId.current = false
    }
  }, [addressType])

  useEffect(() => sortOut(props.childrenList), [props.childrenList])

  const getIsMorningByRunId = (id) => {
    let is_morning = null
    if (props.routes && props.routes.length) {
      props.routes.forEach((gr) => {
        gr.routes.forEach((r) => {
          if (r.id === id) is_morning = r.is_morning
        })
      })
    }
    return is_morning
  }

  const schoolFilterList = (childrenList) => {
    let schools = []
    childrenList.forEach((x) => {
      schools = _.concat(schools, getSchoolFromAddresses(x))
    })
    schools = _.compact(schools)
    schools = _.uniq(schools)

    let schoolsSelect = []
    schools.forEach((x) => {
      props.schoolsProps.forEach((i) => {
        if (i.name === x) {
          schoolsSelect.push(i)
        }
      })
    })

    setSchoolsAr(schoolsSelect)
  }

  const sortOut = (list) => {
    let transAr = []
    let gradeAr = []
    let zipAr = []

    schoolFilterList(list)

    list.forEach((child) => {
      // if child have no active address we add in filter his params
      if (
        child.addressesView &&
        Array.isArray(child.addressesView) &&
        child.addressesView.length > 0
      ) {
        let f = (child.addressesView || []).find(
          (x) => x.address && !x.is_use && !x.is_school
        )
        if (!f) return
      }

      transAr.push(child.transportation_category)
      gradeAr.push(child.classs)
      let zipFromAddresses = getZipFromAddresses(child)
      zipAr = _.concat(zipAr, zipFromAddresses)
    })

    transAr = _.compact(_.uniq(transAr))
    gradeAr = _.compact(_.uniq(gradeAr))
    zipAr = _.compact(_.uniq(zipAr))
    zipAr.sort()

    setZipAr(zipAr)
    setGradeAr(gradeAr)
    setTransCatAr(transAr)
  }

  let applyFilters = () => {
    let filters = {}

    filters.transCat = transCatSelect.length ? transCatSelect : null
    filters.schoolId = schoolsSelect.length ? schoolsSelect : null
    filters.zip = zipSelect.length ? zipSelect : null
    filters.grade = gradeSelect.length ? gradeSelect : null

    if (addressType) filters.addressType = addressType
    if (zonesActive) filters.zonesActive = zonesActive

    let count = 0
    for (let key in filters) {
      if (filters[key]) count += 1
    }

    setActiveFiltersCount(count)
    setMenuToggle(false)

    setActiveFilters(filters)

    if (count) {
      setFiltersActive(true)
      updateFilter(filters)
    } else {
      updateFilter(filters)
      setFiltersActive(false)
    }
  }

  let filtersFooter = () => {
    return (
      <div className="filters-footer">
        <div className="filters-button">
          <Button
            className="btn btn-grey"
            type="danger"
            onClick={clearFiltersAll}
          >
            {i18n.t('Reset')}
          </Button>
        </div>

        <div className="filters-button btn-apply">
          <Button
            className="btn btn-orange "
            type="primary"
            onClick={applyFilters}
          >
            {i18n.t('Apply')}
          </Button>
        </div>
      </div>
    )
  }

  let openFilterBody = () => {
    return (
      <div
        id="filterLineBlock"
        className={`filters-block-dropdown ${menuToggle ? 'visible' : ''}`}
      >
        <div className="filters">
          <div className={styles.filtersFirstLine}>
            <NewMultiplySelect
              label={i18n.t('Transportation Category')}
              value={transCatSelect}
              onChange={setTransCatSelect}
              options={transCatAr}
              placeholder={'All'}
            />
            <div>
              <label className={styles.label}>
                {i18n.t('Pick Up / Drop Off')}
              </label>

              <Select
                placeholder={i18n.t('All')}
                value={addressType}
                style={{ width: '100%' }}
                onChange={(e) => setAddressType(e)}
              >
                <Option className="hide" value={null}>
                  {i18n.t('All')}
                </Option>
                <Option value={1}>{i18n.t('Pick Up')}</Option>
                <Option value={2}>{i18n.t('Drop Off')}</Option>
              </Select>
            </div>
            <div className="filters-block-1x">
              <NewMultiplySelect
                label={i18n.t('ZIP')}
                value={zipSelect}
                onChange={setZipSelect}
                options={zipAr}
                placeholder={'All'}
              />
            </div>
          </div>
          <div className={classnames(styles.filtersSecondLine)}>
            <NewMultiplySelect
              value={schoolsSelect}
              options={schoolsAr}
              onChange={setSchoolsSelect}
              placeholder="All"
              label={i18n.t('School Name')}
              filterProp="name"
            />
            <NewMultiplySelect
              value={gradeSelect}
              options={gradeAr}
              onChange={setGradeSelect}
              label={i18n.t('Grade')}
              placeholder={'All'}
            />
          </div>

          <FilterZoneLine
            changeCb={(e) => setZonesActive(e)}
            zonesActive={zonesActive}
            parentNode={parentNode}
            parentClick={parentClick}
            zonesList={zonesList}
          />

          {filtersFooter()}
        </div>
      </div>
    )
  }

  let resetFilters = () => {
    setZipSelect([])
    setGradeSelect([])
    setTransCatSelect([])
    setSchoolsSelect([])

    setAddressType(null)
    setZonesActive('')
  }

  let clearFiltersAll = () => {
    setMenuToggle(false)
    setFiltersActive(false)
    setActiveFilters({})
    resetAllFilters()
    setActiveFiltersCount(0)

    resetFilters()
  }

  let changeDropdownState = () => {
    setMenuToggle(!menuToggle)
  }

  let removeFilter = (key) => {
    if (key === 'schoolId') setSchoolsSelect([])
    if (key === 'grade') setGradeSelect([])
    if (key === 'transCat') setTransCatSelect([])
    if (key === 'addressType') setAddressType(null)
    if (key === 'zip') setZipSelect([])
    if (key === 'zonesActive') setZonesActive('')

    setActiveFilters((prevState) => {
      return { ...prevState, [key]: null }
    })

    let count = activeFiltersCount - 1
    setActiveFiltersCount(count)

    if (!count) {
      setFiltersActive(false)
    }

    resetFilter(key)
  }

  let iconBnt = () => {
    return (
      <div className={styles.filterButton} onClick={changeDropdownState}>
        <FilterToolIcon />
        <span>{i18n.t('Filters')}</span>
        <RightOutlined
          className={classnames(styles.filterArrow, {
            [styles.openFilter]: menuToggle,
          })}
          onClick={changeDropdownState}
        />
      </div>
    )
  }

  // activated filters
  let activeAddressType = () => {
    return (
      <div
        style={{ margin: 0 }}
        className={`active-filterMainPage-item doo  
        ${activeFilters.addressType ? 'visible' : 'hidden'}`}
      >
        <span data-testid="activeFilterAddress" className="active-filter-text">
          {activeFilters.addressType === 1 && i18n.t('Pick Up')}
          {activeFilters.addressType === 2 && i18n.t('Drop Off')}
        </span>

        <span className={styles.activeIcon}>
          <GetIcon onClick={() => removeFilter('addressType')} />
        </span>
      </div>
    )
  }

  let activeTransCat = () => {
    return (
      <div
        style={{ margin: 0 }}
        className={`active-filterMainPage-item doo  
        ${activeFilters.transCat ? 'visible' : 'hidden'}`}
      >
        <span className="active-filter-text visible">
          {i18n.t('Transportation Category')}
        </span>

        <span className={styles.activeIcon}>
          <GetIcon onClick={() => removeFilter('transCat')} />
        </span>
      </div>
    )
  }

  let activeGrade = () => {
    return (
      <div
        style={{ margin: 0 }}
        className={`active-filterMainPage-item  
        ${activeFilters.grade ? 'visible' : 'hidden'}`}
      >
        <span className="active-filter-text">{i18n.t('Grade')}</span>

        <span className={styles.activeIcon}>
          <GetIcon onClick={() => removeFilter('grade')} />
        </span>
      </div>
    )
  }

  let activeSchool = () => {
    return (
      <div
        style={{ margin: 0 }}
        className={`active-filterMainPage-item  
        ${activeFilters.schoolId ? 'visible' : 'hidden'}`}
      >
        <span className="active-filter-text">{i18n.t('School Name')}</span>
        <span className={styles.activeIcon}>
          <GetIcon onClick={() => removeFilter('schoolId')} />
        </span>
      </div>
    )
  }

  let activeZip = () => {
    return (
      <div
        style={{ margin: 0 }}
        className={` active-filterMainPage-item noMargin
      ${activeFilters.zip ? 'visible' : 'hidden'} `}
      >
        <span className="active-filter-text">{i18n.t('Zip')}</span>

        <span className={styles.activeIcon}>
          <GetIcon onClick={() => removeFilter('zip')} />
        </span>
      </div>
    )
  }

  let activeZones = () => {
    return (
      <div
        style={{ margin: 0 }}
        className={` active-filterMainPage-item
            ${activeFilters.zonesActive ? 'visible' : 'hidden'} `}
      >
        <span className="active-filter-text">{i18n.t('Zones')}</span>

        <span className={styles.activeIcon}>
          <GetIcon onClick={() => removeFilter('zonesActive')} />
        </span>
      </div>
    )
  }
  // END activated filters

  const addressesPrimaryChange = (val) => {
    setAddressesPrimary(val)
    updateFilter({ addressesPrimary: val })
  }
  const showPinsChange = (val) => {
    setPinsShow(val)
    updateFilter({ pinsShow: val })
  }

  let secondLine = () => {
    return (
      <div className={'buttonAlign'}>
        {filtersActive && (
          <div className="clear-active-filters">
            <span onClick={clearFiltersAll}>{i18n.t('Clear Filter')}</span>
          </div>
        )}

        <div className={'addresses-switch filters-active'}>
          <div className="switch-label">{i18n.t('Addresses')}</div>

          <div className="switcher">
            <div
              onClick={() => addressesPrimaryChange(false)}
              className={`switch-button ${!addressesPrimary ? 'active' : ''}`}
            >
              {i18n.t('All')}
            </div>
            <div
              className={`switch-button ${addressesPrimary ? 'active' : ''}`}
              onClick={() => addressesPrimaryChange(true)}
            >
              {i18n.t('Primary')}
            </div>
          </div>
        </div>

        <div className="pins-switch">
          <div className="switch-label">{i18n.t('Pins')}</div>

          <div className="switcher">
            <div
              className={`switch-button ${pinsShow ? 'active' : ''}`}
              onClick={() => showPinsChange(true)}
            >
              {i18n.t('Show')}
            </div>
            <div
              className={`switch-button ${!pinsShow ? 'active' : ''}`}
              onClick={() => showPinsChange(false)}
            >
              {i18n.t('Hide')}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="mapElements filterClass" ref={parentNode}>
      <div id="route-Filter" className={!filtersActive ? 'noflex' : 'flexAll'}>
        <div className="filters-block">
          <div className="active-filters visible">
            <div className="filter-name">
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 10,
                  alignItems: 'center',
                }}
              >
                {iconBnt()}

                {activeTransCat()}
                {activeAddressType()}
                {activeZip()}
                {activeSchool()}
                {activeGrade()}
                {activeZones()}
                {secondLine()}
              </div>
            </div>
          </div>
        </div>

        {openFilterBody()}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    childrenList: state.children.childrenList,
    routes: state.routesView.routes,
    schoolsProps: state.schools,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterFn)
