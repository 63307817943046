import { NewModal } from '@/entities/ui/NewModal/NewModal'
import { ModalsEnum, useModalSelector } from '@/store/modal/ModalStore'
import styles from './DeleteDriverModal.module.less'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { useDeleteDriverMutation } from '@/store/resources/resources'
import { IDriver } from '@/store/resources/resources.types'

export const DeleteDriverModal = () => {
  const { isOpen, onCloseModal, state } = useModalSelector(
    ModalsEnum.DELETE_DRIVER_MODAL
  )

  return isOpen ? (
    <_DeleteDriverModal
      state={state?.driver as IDriver}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
    />
  ) : null
}

const _DeleteDriverModal = ({
  isOpen,
  onCloseModal,
  state,
}: {
  isOpen: boolean
  onCloseModal: () => void
  state: IDriver
}) => {
  const [request, response] = useDeleteDriverMutation()
  const onDelete = () => {
    request(state.id)
  }

  return (
    <NewModal
      title="Delete driver"
      width={420}
      open={isOpen}
      onClose={onCloseModal}
      data-testid="modalDeleteBus"
    >
      <p className={styles.text}>Do you want to delete this driver?</p>
      <div className={styles.footer}>
        <Button onClick={onCloseModal} buttonColor="gray">
          Cancel
        </Button>
        <Button
          data-testid="deleteBtn"
          onClick={onDelete}
          isLoading={response.isLoading}
        >
          Delete
        </Button>
      </div>
    </NewModal>
  )
}
