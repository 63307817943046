import { FC } from 'react'
import styles from './SessionTokenModal.module.less'
interface IProps {
  token: string
}

export const SessionTokenModal: FC<IProps> = ({ token }) => {
  return (
    <div>
      <p className={styles.title}>Token:</p>
      <p>{token}</p>
    </div>
  )
}
