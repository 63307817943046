import React from 'react'

export function getIconLegend(type) {
  switch (type) {
    case 'pickup':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="29"
          viewBox="0 0 17 29"
        >
          <defs>
            <linearGradient id="prefix__a" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor="#FB7266" />
              <stop offset="100%" stopColor="#F44940" />
            </linearGradient>
          </defs>
          <g fill="none">
            <path
              fill="url(#prefix__a)"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="M13.48 12.125c1.25-2 1.395-3.188 1.395-4.792C14.875 3.283 11.417 0 7.417 0 3.367 0 0 3.283 0 7.333c0 1.604.125 2.792 1.375 4.792.557.892 6.042 6.708 6.042 15.625 0-8.917 5.505-14.733 6.062-15.625z"
              transform="translate(1 1)"
            />
            <path
              fill="#FFF"
              d="M7.333 2.667c1.105 0 2 .895 2 2 0 1.104-.895 2-2 2-1.104 0-2-.896-2-2 0-1.105.896-2 2-2zm0 5c2.21 0 4 .895 4 2v1h-8v-1c0-1.105 1.79-2 4-2z"
              transform="translate(1 1)"
            />
          </g>
        </svg>
      )
    case 'dropoff':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="29"
          viewBox="0 0 17 29"
        >
          <defs>
            <linearGradient id="prefix__a" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor="#FB7266" />
              <stop offset="100%" stopColor="#F44940" />
            </linearGradient>
          </defs>
          <g fill="none">
            <path
              fill="url(#prefix__a)"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="M13.48 12.125c1.25-2 1.395-3.188 1.395-4.792C14.875 3.283 11.417 0 7.417 0 3.367 0 0 3.283 0 7.333c0 1.604.125 2.792 1.375 4.792.557.892 6.042 6.708 6.042 15.625 0-8.917 5.505-14.733 6.062-15.625z"
              transform="translate(1 1)"
            />
            <path
              fill="#FFE23D"
              d="M7.333 2.667c1.105 0 2 .895 2 2 0 1.104-.895 2-2 2-1.104 0-2-.896-2-2 0-1.105.896-2 2-2zm0 5c2.21 0 4 .895 4 2v1h-8v-1c0-1.105 1.79-2 4-2z"
              transform="translate(1 1)"
            />
          </g>
        </svg>
      )
    case 'pickupSecond':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="29"
          viewBox="0 0 16 29"
        >
          <defs>
            <linearGradient id="prefix__a" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor="#FB7266" />
              <stop offset="100%" stopColor="#F44940" />
            </linearGradient>
          </defs>
          <g fill="none">
            <path
              fill="url(#prefix__a)"
              stroke="#DC433B"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="M13.48 12.125c1.25-2 1.395-3.188 1.395-4.792C14.875 3.283 11.417 0 7.417 0 3.367 0 0 3.283 0 7.333c0 1.604.125 2.792 1.375 4.792.557.892 6.042 6.708 6.042 15.625 0-8.917 5.505-14.733 6.062-15.625z"
              transform="translate(.833 1)"
            />
            <path
              fill="#FFF"
              d="M7.333 2.667c1.105 0 2 .895 2 2 0 1.104-.895 2-2 2-1.104 0-2-.896-2-2 0-1.105.896-2 2-2zm0 5c2.21 0 4 .895 4 2v1h-8v-1c0-1.105 1.79-2 4-2z"
              transform="translate(.833 1)"
            />
          </g>
        </svg>
      )
    case 'dropoffSecond':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="29"
          viewBox="0 0 16 29"
        >
          <defs>
            <linearGradient id="prefix__a" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor="#FB7266" />
              <stop offset="100%" stopColor="#F44940" />
            </linearGradient>
          </defs>
          <g fill="none">
            <path
              fill="url(#prefix__a)"
              stroke="#DC433B"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth=".5"
              d="M13.48 12.125c1.25-2 1.395-3.188 1.395-4.792C14.875 3.283 11.417 0 7.417 0 3.367 0 0 3.283 0 7.333c0 1.604.125 2.792 1.375 4.792.557.892 6.042 6.708 6.042 15.625 0-8.917 5.505-14.733 6.062-15.625z"
              transform="translate(.833 1)"
            />
            <path
              fill="#FFE23D"
              d="M7.333 2.667c1.105 0 2 .895 2 2 0 1.104-.895 2-2 2-1.104 0-2-.896-2-2 0-1.105.896-2 2-2zm0 5c2.21 0 4 .895 4 2v1h-8v-1c0-1.105 1.79-2 4-2z"
              transform="translate(.833 1)"
            />
          </g>
        </svg>
      )
    case 'stop':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="29"
          viewBox="0 0 16 29"
        >
          <defs>
            <linearGradient id="prefix__a" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor="#0DA4A4" />
              <stop offset="100%" stopColor="#11626F" />
            </linearGradient>
          </defs>
          <path
            fill="#11626F"
            fillRule="evenodd"
            stroke="#286D90"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="M13.48 12.125c1.25-2 1.395-3.188 1.395-4.792C14.875 3.283 11.417 0 7.417 0 3.367 0 0 3.283 0 7.333c0 1.604.125 2.792 1.375 4.792.557.892 6.042 6.708 6.042 15.625 0-8.917 5.505-14.733 6.062-15.625z"
            transform="translate(.667 1)"
          />
        </svg>
      )
    case 'rider':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="29"
          viewBox="0 0 16 29"
        >
          <path
            fill="#78C13B"
            fillRule="evenodd"
            stroke="#5D8766"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="M13.98 13.125c1.25-2 1.395-3.188 1.395-4.792C15.375 4.283 11.917 1 7.917 1 3.867 1 .5 4.283.5 8.333c0 1.604.125 2.792 1.375 4.792.557.892 6.042 6.708 6.042 15.625 0-8.917 5.505-14.733 6.062-15.625z"
          />
        </svg>
      )
    case 'transfer':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="29"
          viewBox="0 0 16 29"
        >
          <defs>
            <linearGradient
              id="prefix__a58"
              x1="50%"
              x2="50%"
              y1="111.332%"
              y2="-5.58%"
            >
              <stop offset="0%" stopColor="#3023AE" />
              <stop offset="100%" stopColor="#C86DD7" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <path
              fill="url(#prefix__a58)"
              stroke="#402BB2"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M20.219 18.187c1.875-3 2.094-4.782 2.094-7.187 0-6.075-5.188-11-11.188-11C5.05 0 0 4.925 0 11c0 2.405.188 4.187 2.063 7.187.835 1.338 9.062 10.063 9.062 23.438 0-13.375 8.258-22.1 9.094-23.438z"
              transform="scale(0.65)"
            />
          </g>
        </svg>
      )

    case 'school':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="29"
          viewBox="0 0 24 43"
        >
          <path
            fill="#F6BF4F"
            fillRule="evenodd"
            stroke="#CDA042"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.219 19.187c1.875-3 2.094-4.782 2.094-7.187 0-6.075-5.188-11-11.188-11C6.05 1 1 5.925 1 12c0 2.405.188 4.187 2.063 7.187.835 1.338 9.062 10.063 9.062 23.438 0-13.375 8.258-22.1 9.094-23.438z"
          />
        </svg>
      )
    case 'depot':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="29"
          viewBox="0 0 17 29"
        >
          <path
            fill="#1672A3"
            fillRule="evenodd"
            stroke="#1F5A86"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".5"
            d="M14.48 13.125c1.25-2 1.395-3.188 1.395-4.792C15.875 4.283 12.417 1 8.417 1 4.367 1 1 4.283 1 8.333c0 1.604.125 2.792 1.375 4.792.557.892 6.042 6.708 6.042 15.625 0-8.917 5.505-14.733 6.062-15.625z"
          />
        </svg>
      )
    default:
      return null
  }
}
