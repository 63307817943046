import 'antd/dist/antd.css'
import { DatePicker } from 'antd'
import classNames from 'classnames'
import './NewRangePicker.less'
import { RangePickerProps as BaseRangePickerProps } from 'antd/lib/date-picker/generatePicker'
import { Moment } from 'moment/moment'
import { forwardRef } from 'react'

const { RangePicker } = DatePicker

export const NewRangePicker = forwardRef(
  ({ className, ...props }: BaseRangePickerProps<Moment>, ref: any) => {
    return (
      <RangePicker
        className={classNames('newRangePicker', className)}
        clearIcon={false}
        {...props}
        ref={ref}
        popupClassName="rangePickerDropdown"
      />
    )
  }
)
