import * as types from "./actionTypes";

const initialState = {
  buses: [],
  drivers: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BUSES: {
      state.buses = action.payload;
      return state;
    }

    case types.GET_DRIVERS: {
      state.drivers = action.payload;
      return state;
    }

    default: {
      return state;
    }
  }
};
