import { NewModal } from '@/entities/ui/NewModal/NewModal'
import { ModalsEnum, useModalSelector } from '@/store/modal/ModalStore'
import { useAppSelector } from '@/redux'
import { isMultipleEmailInfoSelector } from '@/store/common/common.selectors'
import { NewVariant } from '@/entities/modals/ParentEmailModal/components/variants/NewVariant/NewVariant'
import { OldVariant } from '@/entities/modals/ParentEmailModal/components/variants/OldVariant/OldVariant'

export const ParentEmailModal = () => {
  const { onCloseModal, isOpen, state } = useModalSelector(
    ModalsEnum.PARENT_EMAIL
  )

  return isOpen ? (
    <_ParentEmailModal
      onCloseModal={onCloseModal}
      isOpen={isOpen}
      arrayId={state?.arrayId}
    />
  ) : null
}

const _ParentEmailModal = (props) => {
  const { isOpen, onCloseModal } = props

  const isMultipleEmail = useAppSelector(isMultipleEmailInfoSelector)

  return (
    <NewModal
      width={600}
      title="Email parents"
      open={isOpen}
      onClose={onCloseModal}
    >
      {isMultipleEmail ? <NewVariant {...props} /> : <OldVariant {...props} />}
    </NewModal>
  )
}
