import { useMemo } from 'react'
import {
  useErrorsList,
  useRepeatErrorsList,
} from '@/entities/modals/CloneRunModal/components/ErrorAlert/ErrorAlert'
import { IProps } from '@/entities/modals/CloneRunModal/components/RunInfo/RunInfo'
import { getPoints } from '@/entities/modals/CloneRunModal/components/RunInfo/helpers/getPoints'
import { TCloneTypes } from '@/entities/modals/CloneRunModal/CloneRunModal'
import { useCloneRunStore } from '@/entities/modals/CloneRunModal/store'

export const useRunInfo = ({ type }: IProps) => {
  const cloneVariant = useCloneRunStore((state) => state.cloneVariant)
  const errorsRepeatList = useRepeatErrorsList()
  const errorsList = useErrorsList()

  const erorsListsVariants: Record<TCloneTypes, any> = useMemo(
    () => ({
      base: errorsList,
      mirror: errorsList,
      repeat: errorsRepeatList,
      reverse: errorsList,
    }),
    [errorsList, errorsRepeatList]
  )

  const errosIds = useMemo(
    () =>
      erorsListsVariants[cloneVariant].reduce(
        (acc, el) => ({ ...acc, [el?.rider?.id]: el?.rider?.id }),
        {}
      ),
    [erorsListsVariants, cloneVariant]
  )

  const points = useMemo(() => getPoints(type, errosIds), [errosIds, type])

  return { points, errosIds, transferError: type === 'base' }
}

export type IUseRunInfo = ReturnType<typeof useRunInfo>
