import * as L from 'leaflet'

export function pointIcon(){

  let svg = "<svg width=\"10\"  height=\"10\">\n" +
    "  <rect  width=\"10\" height=\"10\"\n" +
    "        style=\"fill:white;stroke:white;stroke-width:5;fill-opacity:1;stroke-opacity:0.9\" />\n" +
    "</svg>"


  let divIcons = L.divIcon({
    className: "leaflet-data-marker-start",
    html: L.Util.template(svg, {}),
    iconSize: [10, 10]
  });


  return divIcons
}
