import { Popover } from 'antd'
import styles from './SelectWithCountCheckbox.module.less'
import { NewCheckbox } from '@/entities/ui/NewCheckbox/NewCheckbox'
import { Fragment, useRef, useState } from 'react'
import { SuffixIcon } from '@/img/iconsComponents/SuffixIcon'
import classnames from 'classnames'
import { IProps } from '@/pages/RoutesView/components/SelectWithCountCheckbox/SelectWithCountCheckbox.types'

export const SelectWithCountCheckbox = ({
  onCheckAll,
  count = 0,
  disabled,
  contentWidth,
  items = [],
  ...rest
}: IProps) => {
  const [open, setOpen] = useState(false)
  const popoverRef = useRef(null)
  const childrenRef = useRef(null)

  return (
    <Popover
      trigger="click"
      onOpenChange={setOpen}
      placement="bottomLeft"
      open={open}
      align={{ offset: [0, -10] }}
      content={
        <div
          ref={popoverRef}
          style={{ width: contentWidth ?? 180 }}
          onClick={(e) => {
            if (disabled) {
              e.stopPropagation()
              e.preventDefault()
            }
          }}
          className={styles.content}
        >
          {items.map(({ show = true, callback, label, id }, index) => (
            <Fragment key={id ?? index}>
              {show ? (
                <span
                  onClick={() => {
                    callback?.()
                    setOpen(false)
                  }}
                  className={styles.button}
                >
                  {label}
                </span>
              ) : null}
            </Fragment>
          ))}
        </div>
      }
    >
      <div
        ref={childrenRef}
        onClick={() => {
          !disabled && setOpen((prev) => !prev)
        }}
        className={classnames(
          { [styles.previewOpen]: open },
          { [styles.disabled]: disabled },
          styles.preview
        )}
      >
        <NewCheckbox
          disabled={disabled}
          onChange={onCheckAll}
          onClick={(e) => e.stopPropagation()}
          {...rest}
        />
        <span>{count}</span>
        <SuffixIcon
          className={classnames(styles.icon, { [styles.open]: open })}
        />
      </div>
    </Popover>
  )
}
