import { childInTransfer } from '@/redux/routesView/actions'

export default function (pointDestination, route_id, departPoint) {
  let data = {
    routeId: route_id,
    pointId: pointDestination.id,
    childAddressId: departPoint.routePointChildren[0].child_address_id,
    deletePoint_: departPoint.id,
  }

  return childInTransfer(data)
}
