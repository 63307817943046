import React from 'react'
import Modal from '../../../../components/BaseModal'

import Row from 'antd/es/row'
import Col from 'antd/es/col'

import './index.less'
import { useDeleteRunsMutation } from '@/store/viewRuns/viewRuns'

const ModalPointDelete = (props) => {
  const { title, deleteObject, closeFn, deleteFn, child } = props
  const [, deleteRunsResponse] = useDeleteRunsMutation({
    fixedCacheKey: 'batch-delete-runs',
  })

  return (
    <Modal
      title={title}
      saveBtn={'Save'}
      closeBtn={'Cancel'}
      close={'closeM DeleteStop '}
      closeFn={closeFn}
      class={'ModalClose open'}
    >
      <div className="profile">
        <div>
          <Row>
            <Col span={24}>
              <span className="Text">
                Are you sure you want to remove <b>{deleteObject}</b>
              </span>
            </Col>
          </Row>
        </div>
      </div>

      <div className="center">
        <div className="buttons">
          <button className="cancel" onClick={closeFn}>
            Cancel
          </button>
          <button
            onClick={deleteFn}
            className="save"
            disabled={deleteRunsResponse.isLoading}
            style={{ whitespace: 'pre' }}
          >
            {child ? 'Remove' : 'Delete'}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalPointDelete
