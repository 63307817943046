export const RunBuilderIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M3.055 11.841a.515.515 0 0 0 .16-.377.515.515 0 0 0-.16-.376.515.515 0 0 0-.376-.16.515.515 0 0 0-.377.16.515.515 0 0 0-.16.376c0 .145.054.27.16.377a.515.515 0 0 0 .377.159c.145 0 .27-.053.376-.159zm5.55-3.892l-5.709 5.708c-.206.207-.457.31-.753.31-.29 0-.544-.103-.762-.31l-.887-.904A.995.995 0 0 1 .176 12c0-.296.106-.55.318-.762l5.7-5.7c.218.547.537 1.03.959 1.452.42.422.905.741 1.452.959zm5.307-3.642c0 .218-.064.514-.193.888a3.68 3.68 0 0 1-1.377 1.82 3.654 3.654 0 0 1-2.163.7c-1.033 0-1.916-.368-2.65-1.101-.734-.734-1.1-1.617-1.1-2.65 0-1.032.366-1.915 1.1-2.649.734-.734 1.617-1.1 2.65-1.1.323 0 .662.045 1.017.137.354.092.654.222.9.39.089.061.133.14.133.234 0 .095-.044.173-.134.234L9.643 2.625V4.5l1.615.896.662-.406c.413-.254.79-.48 1.134-.678.343-.198.54-.297.59-.297.084 0 .15.027.197.083a.312.312 0 0 1 .07.21z"
      />
    </svg>
  )
}
