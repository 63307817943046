import * as types from './actionTypes'
import { sortItemsByNumAbc } from '../../helpers/arrayWork'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SCHOOLS_GET_ITEMS: {
      return action.payload.filter(x => {
        if (!x.name) return false
        x.type = 1
        x.title = x.name
        return true
      })
    }

    case types.SCHOOLS_ADD_ITEM: {
      const item = action.payload
      const newState = [...state, { ...item }]
      return newState.sort(sortItemsByNumAbc)
    }
    case types.DISTRICT_ADD_ITEM: {
      const item = action.payload
      return [...state, { ...item }]
    }

    case types.SCHOOLS_BY_CODE: {
      const school = action.payload
      return { school, ...state }
    }

    case types.SCHOOLS_EDIT_ITEM: {
      const elem = action.payload
      let newArr = [...state]
      newArr.forEach((item, i) => {
        if (item.id === elem.id) {
          newArr[i] = { ...elem }
        }
      })
      return newArr.sort(sortItemsByNumAbc)
    }

    case types.SCHOOLS_REMOVE_ITEM: {
      const id = action.payload
      return state.filter(item => {
        return item.id !== id
      })
    }

    default:
      return state
  }
}
