import { Alert } from 'antd'
import { useImportRidersStore } from '@/entities/modals/RidersImportModal/RidersImportModal'
import styles from './CloseAlert.module.less'
export const CloseAlert = () => {
  const visible = useImportRidersStore((state) => state.tryClose)
  const onClose = () =>
    useImportRidersStore.getState().setImportRidersStore({ tryClose: false })

  return visible ? (
    <div className={styles.root}>
      <Alert
        type="error"
        afterClose={onClose}
        closable
        message="If you want to cancel import press stop"
      />
    </div>
  ) : (
    <></>
  )
}
