import { useRootZustandStore } from '@/pages/FieldTrip/Attendance/V2Attendance/Attendance.store'
import { getRunDetails } from '@/redux/routesView/actions'
import { notification } from 'antd'
import { useModalStore } from '@/store/modal/ModalStore'
import { IOptimizeSocketResponse } from '@/features/containers/SocketConnect/SocketConnect.type'
import { useSocketConnectStore } from '@/features/containers/SocketConnect/store'

export const optimizeRouteMessage = (message: IOptimizeSocketResponse) => {
  const dispatch = useRootZustandStore.getState().dispatch
  const pathname = window.location?.pathname

  if (
    typeof message?.routeId === 'number' &&
    pathname.includes(String(message?.routeId))
  ) {
    if (message.status === 'start') {
      useSocketConnectStore.getState().setOptimize({ isLoading: true })
    } else if (message.status === 'end') {
      dispatch(getRunDetails(message.routeId))
      useModalStore.getState().onCloseModal()
      useSocketConnectStore.getState().setOptimize({ isLoading: false })
    } else if (message.status === 'error') {
      notification.error({ message: 'Server error' })
      useSocketConnectStore.getState().setOptimize({ isLoading: false })
    }
  }
}
