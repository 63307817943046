import { ModalFooter } from '@/entities/ui/NewModal/components/ModalFooter'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import styles from './CreateUserModal.module.less'
import { FormInput } from '@/entities/ui/NewInput/FormInput'
import { NewRadio } from '@/entities/ui/NewRadio/NewRadio'
import {
  useGetRolesQuery,
  useLazyCreateUserQuery,
} from '@/store/lc/settings/lcSettingsApi'
import { Radio } from 'antd'
import { isValidEmail } from '@/shared/validations/isValidEmail'
import { ICreateUserForm } from '@/entities/modals/CreateUserModal/CreateUserModal.types'
import { FC, Fragment } from 'react'

interface Props {}
export const CreateUserModal: FC<Props> = () => {
  const methods = useForm<ICreateUserForm>({
    defaultValues: { roleName: '0_Office Manager' },
  })
  const roles = useGetRolesQuery()
  const [onCreate, createResponse] = useLazyCreateUserQuery()

  const onSubmit = (data: ICreateUserForm) => {
    onCreate({ ...data, setError: methods.setError })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <section className={styles.root}>
          <div className={styles.inputsWrapper}>
            <FormInput
              rules={{ required: true }}
              name="username"
              label="Username"
            />
            <FormInput name="first_name" label="First Name" />
            <FormInput name="last_name" label="Last Name" />
            <FormInput
              rules={{
                required: true,
                validate: {
                  message: (value) =>
                    !isValidEmail(value) ? 'Invalid email' : true,
                },
              }}
              name="email"
              label="Email"
            />
            <FormInput
              rules={{ required: true }}
              type="password"
              name="password"
              label="Password"
            />
          </div>
          <div>
            <p className={styles.title}>Assigned role for this user</p>
            <Controller
              name="roleName"
              render={({ field }) => (
                <Radio.Group {...field}>
                  {roles.data?.data?.map(({ role }) => (
                    <Fragment key={role.name}>
                      <NewRadio
                        value={role.name}
                        disabled={role.name === '0_Admin'}
                      >
                        {role.is_general ? role?.nameText : role?.data?.name}
                        <span className={styles.text}>{role.description}</span>
                      </NewRadio>
                    </Fragment>
                  ))}
                </Radio.Group>
              )}
            />
          </div>
        </section>
        <ModalFooter
          success={{
            isSubmitForm: true,
            text: 'Create',
            isLoading: createResponse.isFetching,
          }}
        />
      </form>
    </FormProvider>
  )
}
