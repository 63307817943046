import { DatePicker, DatePickerProps } from 'antd'
import { LeftArrowIcon, RightArrowIcon } from '@/img/icons'
import './NewSingleDatePicker.less'
import React, { FC } from 'react'
import classNames from 'classnames'

type Props = {
  onClickLeft?: () => void
  onClickRight?: () => void
  divider?: boolean
} & DatePickerProps

export const NewSingleDatePicker: FC<Props> = ({
  value,
  onClickLeft,
  onClickRight,
  className,
  placeholder = 'Select date',
  divider = false,
  clearIcon = false,
  allowClear,
  ...props
}) => {
  return (
    <DatePicker
      value={value}
      data-testid="singleDatePicker"
      allowClear={allowClear || !!clearIcon}
      placeholder={placeholder}
      className={classNames('newDatePicker', className)}
      popupClassName="datePickerDropdown"
      inputRender={(props) => (
        <div id="new-single-datepicker">
          {onClickLeft && value && (
            <div
              className="icon"
              onClick={(e) => {
                if (!value) {
                  return
                }
                e.preventDefault()
                e.stopPropagation()
                onClickLeft?.()
              }}
            >
              <LeftArrowIcon width={12} height={12} />
            </div>
          )}
          <input {...props} />
          {onClickRight && value && (
            <div
              className="icon"
              onClick={(e) => {
                if (!value) {
                  return
                }
                e.preventDefault()
                e.stopPropagation()
                onClickRight?.()
              }}
            >
              <RightArrowIcon width={12} height={12} />
            </div>
          )}
          {divider && <span className={'lines'} />}
        </div>
      )}
      clearIcon={clearIcon}
      {...props}
    />
  )
}
