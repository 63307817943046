import * as types from './actionTypes'

const initialState = {
  user: false,
  isAuth: false,
  errors: false,
  company: false,
  role: false,
  session_id: null,
  socket_token: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SIGN_IN: {
      return { ...action.payload }
    }
    case types.UNAUTHENTICATED: {
      return initialState
    }
    case types.COMPANY_EDIT: {
      return {
        ...state,
        ...action.payload,
        company: { ...state.company, ...action.payload },
      }
    }
    case types.EMAIL_TEMPLATE: {
      let clone = { ...state }
      clone.company = { ...clone.company, ...action.payload }
      return clone
    }

    case types.COMPANY_WITH_COMPANY: {
      state.companyUser = { ...action.payload }

      return { ...state, ...action.payload }
    }
    case types.COMPANY_WITH_DEPOTS: {
      return { ...state, ...action.payload }
    }
    case types.PAYMENT: {
      return { ...state, payment: action.payload }
    }
    case types.PAYMENT_SAVE: {
      let payment = { data: [action.payload] }
      return { ...state, payment: payment }
    }

    default:
      return state
  }
}
