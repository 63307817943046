import * as types from './actionTypes'

import api from '../apiConfig'
const rq = api.schools
const runV2 = api.runV2

export function getSchools() {
  return function (dispatch) {
    runV2.get('/school/index').then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: types.SCHOOLS_GET_ITEMS,
          payload: response.data,
        })
      } else {
        console.warn('error')
      }
    })
  }
}

export function getSchoolForRequest(schoolCode) {
  return function (dispatch) {
    runV2.get(`school/view?linkCode=${schoolCode}`).then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: types.SCHOOLS_BY_CODE,
          payload: response.data,
        })
      } else {
        console.warn('error')
      }
    })
  }
}

export function addItem(school) {
  return function (dispatch) {
    runV2.post('/school/create', school).then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: types.SCHOOLS_ADD_ITEM,
          payload: response.data,
        })
      } else {
        console.warn('error')
      }
    })
  }
}

export function editItem(id, data) {
  return function (dispatch) {
    runV2.put(`/school/update/${id}`, data).then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: types.SCHOOLS_EDIT_ITEM,
          payload: response.data,
        })
      } else {
        console.warn('error ' + id)
      }
    })
  }
}

export function addItemSocket(response) {
  return function (dispatch) {
    dispatch({
      type: types.SCHOOLS_ADD_ITEM,
      payload: response,
    })
  }
}

export function editItemSocket(response) {
  return function (dispatch) {
    dispatch({
      type: types.SCHOOLS_EDIT_ITEM,
      payload: response,
    })
  }
}

export function removeItem(id) {
  return function (dispatch) {
    rq.delete('/' + id)
      .then(function (response) {
        if (response.status === 204) {
          dispatch({
            type: types.SCHOOLS_REMOVE_ITEM,
            payload: id,
          })
        } else {
          console.warn('status <>204 ' + id)
        }
      })
      .catch(() => {
        console.warn('catch', id)
      })
  }
}
export function removeItemSocket(id) {
  return function (dispatch) {
    dispatch({
      type: types.SCHOOLS_REMOVE_ITEM,
      payload: id,
    })
  }
}
