import { notification } from 'antd'

export const catchNotification = (e: any) => {
  if (typeof e === 'string') {
    notification.error({ message: e })
  } else if (e?.error?.serverError) {
    notification.error({ message: 'Server error' })
  } else {
    notification.error({ message: 'Unknown error' })
  }
}
