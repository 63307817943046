import { create } from 'zustand'
import _ from 'lodash'

export interface EditDataItem {
  type: 'school' | 'address' | 'addressType' | 'delete'
  newValue: number | string
  oldValue: number | string
  addressId: number
}

interface IStore {
  editData: EditDataItem[]
  initialAddresses: any[]
  resetEditData: () => void
  onChangeEditData: (
    type: EditDataItem['type'],
    value: string | number,
    addressId: number
  ) => void
}

export const useForceUpdateAddressesStore = create<IStore>((set, get) => ({
  editData: [],
  initialAddresses: [],
  setInitialAddresses: (addresses) => {
    set({ initialAddresses: _.cloneDeep(addresses) })
  },
  resetEditData: () => {
    set({ editData: [], initialAddresses: [] })
  },
  onChangeEditData: (type, value, addressId) => {
    const data = get().editData
    const initialAddresses = get().initialAddresses

    const currentOldValue = initialAddresses.find(
      (address) => address.id === addressId
    )
    //если был изменен ( школа,адрес,тип адреса )
    if (['school', 'address', 'addressType'].includes(type)) {
      const getOldValue = () => {
        switch (type) {
          case 'address': {
            return typeof currentOldValue.address?.address === 'string'
              ? currentOldValue.address?.address
              : currentOldValue.address?.address?.address
          }
          case 'addressType': {
            console.log('currentOldValue', currentOldValue)
            return currentOldValue?.type
          }
          case 'school': {
            return currentOldValue?.childSchool?.school_id
          }
          default:
            return
        }
      }

      const oldValue = getOldValue()

      set(() => ({
        editData: [
          ...data.filter((item) => {
            if (item.addressId !== addressId) {
              return true
            }
            return (
              item.type !== type &&
              oldValue !== item?.oldValue &&
              item.addressId === addressId
            )
          }),
          //если новое значение и старое ( изначальное совпадают,
          //то удаляем это значение из массива с изменениями
          ...(value === oldValue
            ? []
            : [
                {
                  type,
                  newValue: value,
                  addressId,
                  oldValue,
                },
              ]),
        ],
      }))
    } else if (type === 'delete') {
      set(() => ({
        editData: [
          ...data,
          {
            type,
            newValue: value,
            addressId,
            oldValue: currentOldValue,
          },
        ],
      }))
    }
  },
}))

export const editRiderWithUseAddress = (
  type: EditDataItem['type'],
  value,
  address
) => {
  const onChange = useForceUpdateAddressesStore.getState().onChangeEditData

  onChange(type, value, address?.id)
}
