import { SVGProps } from 'react'

export const ViewRunsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="15"
      viewBox="0 0 16 11"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M9.3 6.48v-.034l-.201-2.678a.283.283 0 0 0-.092-.188.271.271 0 0 0-.193-.08H7.257a.271.271 0 0 0-.192.08.283.283 0 0 0-.092.188l-.201 2.678v.034a.192.192 0 0 0 .067.167c.05.045.109.067.176.067h2.042a.256.256 0 0 0 .176-.067c.05-.044.072-.1.067-.167zm6.353 3.909c0 .407-.128.611-.385.611H9.375a.244.244 0 0 0 .184-.08.236.236 0 0 0 .067-.188L9.46 8.59a.283.283 0 0 0-.092-.188.271.271 0 0 0-.193-.08H6.897a.271.271 0 0 0-.192.08.283.283 0 0 0-.092.188l-.168 2.143a.236.236 0 0 0 .067.188c.05.053.112.08.184.08H.804c-.257 0-.385-.204-.385-.611 0-.301.072-.625.217-.971L4.126.679a.686.686 0 0 1 .218-.276.51.51 0 0 1 .318-.117H7.5a.271.271 0 0 0-.193.08.283.283 0 0 0-.092.188L7.09 2.16a.235.235 0 0 0 .067.192.25.25 0 0 0 .184.076h1.39a.25.25 0 0 0 .184-.076.235.235 0 0 0 .067-.192L8.856.554a.283.283 0 0 0-.092-.189.271.271 0 0 0-.193-.08h2.838a.51.51 0 0 1 .318.118c.1.078.173.17.218.276l3.49 8.739c.145.346.218.67.218.971z"
      />
    </svg>
  )
}
