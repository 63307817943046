import { PrintDriverForm } from './PrintModal.types'

export const columnsSource = [
  {
    title: '',
    dataIndex: 'number',
    key: 'number',
    width: '10%',
  },
  {
    title: 'Pickup Location(s)',
    dataIndex: 'address',
    key: 'address',
    width: '50%',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: '20%',
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    width: '20%',
  },
]
export const columnsDest = [
  {
    title: '',
    dataIndex: 'number',
    key: 'number',
    width: '10%',
  },
  {
    title: 'Destination(s)',
    dataIndex: 'address',
    key: 'address',
    width: '50%',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: '20%',
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    width: '20%',
  },
]

export const defaultValues: PrintDriverForm = {
  tripName: '',
  tripDescription: '',
  driverName: '',
  busNumber: '',
  startingMileage: '',
  endingMileage: '',
  totalMileage: '',
  startTime: '',
  endTime: '',
  totalTime: '',
  charterStartTime: '',
  contractTime: '',
  transportationCoordinator: '',
  driverComments: '',
}

export const dateFormat = 'MM/DD/YYYY'
export const timeFormat = 'h:mm A'
