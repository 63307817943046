import { createSelector } from 'reselect'
import { commonApi } from '@/store/common/common'

export const selectUserInfoResult = commonApi.endpoints.getUserInfo.select()

export const userInfoSelector = createSelector(
  selectUserInfoResult,
  (userInfoResult) => userInfoResult?.data?.data
)

export const userInfoSelectorPermissions = createSelector(
  selectUserInfoResult,
  (userInfoResult) => userInfoResult?.data?.data?.permissions
)
export const isMultipleEmailInfoSelector = createSelector(
  selectUserInfoResult,
  (userInfoResult) =>
    !!userInfoResult?.data?.data?.companyOptions?.is_multiple_email_template
)

export const isHaveUserInfoSelector = createSelector(
  selectUserInfoResult,
  (userInfoResult) => {
    if (userInfoResult.isSuccess) {
      return 'success'
    } else if (userInfoResult.isLoading) {
      return 'loading'
    } else if (userInfoResult.isError) {
      return 'error'
    }
  }
)
