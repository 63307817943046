export const AttendanceIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.444 0C.65 0 0 .65 0 1.444v10.112C0 12.35.65 13 1.444 13h10.112C12.35 13 13 12.35 13 11.556V1.444C13 .65 12.35 0 11.556 0H1.444zm0 6.639l1.012-.972 2.6 2.5 5.488-5.278 1.012.972-6.5 6.25L1.444 6.64z"
      />
    </svg>
  )
}
