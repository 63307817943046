import { useModalStore } from '@/store/modal/ModalStore'
import { IDriver } from '@/store/resources/resources.types'

export const ruleName = (variant: 'edit' | 'create', data: IDriver[]) => {
  return {
    required: true,
    validate: {
      valid: (value: string) => nameValidator(value, variant, data || []),
    },
  }
}
export const nameValidator = (
  value: string,
  variant: 'edit' | 'create',
  data: IDriver[]
) => {
  const driversWithCurrentName = data
    .filter((el) => el.name.toLowerCase() === value.toLowerCase())
    .map((el) => el.name)
  const currentDrive = useModalStore.getState().state?.driver
  const isNewName = currentDrive?.name?.toLowerCase() !== value.toLowerCase()

  const validate = () => {
    if (isNewName && [...driversWithCurrentName].length >= 1) {
      return true
    } else if (!isNewName && [...driversWithCurrentName, value].length > 2) {
      return true
    }
  }
  if (variant === 'create') {
    return driversWithCurrentName.length === 0
  } else if (variant === 'edit') {
    return !validate()
  }
}

export const rulePassword = (clearErrors) => {
  return {
    validate: {
      valid: (value, formValues) => {
        if (value !== formValues.confirm) {
          return false
        } else {
          clearErrors('confirm')
        }
      },
    },
  }
}
export const ruleConfirm = (clearErrors) => {
  return {
    validate: {
      valid: (value, formValues) => {
        if (value !== formValues.password) {
          return false
        } else {
          clearErrors('password')
        }
      },
    },
  }
}
