import {
  ModalsEnum,
  useModalSelector,
  useModalStore,
} from '@/store/modal/ModalStore'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  useAddNewDriverMutation,
  useGetBusDepotListQuery,
  useGetDriversQuery,
  useUpdateDriverMutation,
} from '@/store/resources/resources'
import { NewModal } from '@/entities/ui/NewModal/NewModal'
import styles from './AddDriverModal.module.less'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { FormInput } from '@/entities/ui/NewInput/FormInput'
import { CalendarIcon, ClearIcon } from '@/img/icons'
import { NewSingleDatePicker } from '@/entities/ui/NewSingleDatePicker/NewSingleDatePicker'
import React, { useEffect, useRef, useState } from 'react'
import { FormSelect } from '@/entities/ui/NewSelect/NewSelect'
import { NewMaskInput } from '@/entities/ui/NewInput/NewMaskInput'
import { IAddDriverForm } from '@/entities/modals/AddDriverModal/AddDriverModal.types'
import moment, { Moment } from 'moment'
import { IDriver } from '@/store/resources/resources.types'
import { Popover } from 'antd'
import {
  ruleConfirm,
  ruleName,
  rulePassword,
} from '@/entities/modals/AddDriverModal/AddDriverModal.helper'

export const AddDriverModal = () => {
  const { isOpen, onCloseModal, state } = useModalSelector(
    ModalsEnum.ADD_DRIVER_MODAL
  )
  const driver = state?.driver

  return isOpen ? (
    <_AddDriverModal
      isOpen={isOpen}
      defaultValues={{
        birthday: driver?.birthday
          ? moment(driver?.birthday * 1000)
          : undefined,
        driver_license: driver?.driver_license ?? '',
        name: driver?.name ?? '',
        confirm: driver?.confirm ?? '',
        email: driver?.email ?? '',
        password: driver?.password ?? '',
        phone: driver?.phone ?? '',
        username: driver?.username ?? '',
        vehicle_capacity_permit_level:
          driver?.vehicle_capacity_permit_level ?? '',
        vehicle_type_permit_level: driver?.vehicle_type_permit_level ?? '',
        bus_depot_id: state?.driver?.bus_depot_id ?? '',
        is_active: state?.driver?.is_active ?? 1,
      }}
      driver={driver}
      variant={driver ? 'edit' : 'create'}
      onCloseModal={onCloseModal}
    />
  ) : null
}

const _AddDriverModal = ({
  isOpen,
  onCloseModal,
  defaultValues,
  variant,
  driver,
}: {
  isOpen: boolean
  onCloseModal: () => void
  defaultValues: IAddDriverForm
  variant?: 'edit' | 'create'
  driver?: IDriver
}) => {
  const methods = useForm<IAddDriverForm>({
    defaultValues,
    mode: 'onChange',
  })

  const [request, response] = useAddNewDriverMutation()
  const [update, updateResponse] = useUpdateDriverMutation()
  const drivers = useGetDriversQuery()

  const depotsList = useGetBusDepotListQuery()
  const depotOptions = depotsList?.data?.data.map((depot) => ({
    label: depot.name,
    value: depot.id,
  }))

  const onSubmit = (formData: IAddDriverForm) => {
    const requestData = {
      ...formData,
      ...(formData.birthday
        ? { birthday: (formData.birthday as Moment).unix() }
        : {}),
      is_active: 1,
    }
    if (variant === 'create') {
      if (!(requestData.password ?? '')?.trim()) {
        delete requestData.password
      }
      request(requestData)
    } else {
      const currentDriver = useModalStore.getState().state.driver
      const updateData = {
        ...(defaultValues || {}),
        ...currentDriver,
        ...formData,
      }
      if (!(updateData.password ?? '')?.trim()) {
        delete updateData.password
      }
      if (!updateData.confirm) {
        delete updateData.confirm
      }
      update(updateData)
    }
  }

  const [openReset, setOpenReset] = useState(false)

  useEffect(() => {
    const submit = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        methods.handleSubmit(onSubmit)()
      }
    }

    document.addEventListener('keydown', submit)
    return () => {
      document.removeEventListener('keydown', submit)
    }
  }, [])

  return (
    <NewModal
      title={
        variant === 'create' ? 'Add Driver' : `Driver ID ${driver?.id || ' - '}`
      }
      width={600}
      open={isOpen}
      onClose={onCloseModal}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.root}>
          <FormInput
            rules={ruleName(variant, drivers?.data?.data || [])}
            label="Name"
            name="name"
            data-testid="driverNameInp"
          />
          <FormInput
            label="Username"
            name="username"
            data-testid="usernameInp"
          />
          {variant === 'create' ? (
            <div className={styles.doubleForm}>
              <FormInput
                rules={rulePassword(methods.clearErrors)}
                label="Password"
                name="password"
                type="password"
                data-testid="passwordInp"
              />
              <FormInput
                rules={ruleConfirm(methods.clearErrors)}
                label="Confirm Password"
                data-testid="confirmInp"
                name="confirm"
                type="password"
              />
            </div>
          ) : (
            <Popover
              onOpenChange={setOpenReset}
              open={openReset}
              trigger={'click'}
              placement={'bottomLeft'}
              content={<ResetPassword close={() => setOpenReset(false)} />}
            >
              <Button
                type={'button'}
                buttonColor="blue"
                style={{ width: 'fit-content' }}
              >
                Reset Password
              </Button>
            </Popover>
          )}
          <div className={styles.doubleForm}>
            <Controller
              name="birthday"
              render={({ field, fieldState }) => (
                <div>
                  <label
                    className={cn({
                      [styles.errorMessage]: fieldState.error?.type,
                    })}
                    htmlFor="birthday"
                  >
                    Date of Birth
                  </label>
                  <NewSingleDatePicker
                    status={fieldState.error?.type ? 'error' : undefined}
                    id={'birthday'}
                    data-testid="birthDayInp"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    getCalendarContainer={(trigger) => trigger.parentNode}
                    name="birthday"
                    defaultValue={field.value ? moment(field.value, 'L') : null}
                    {...field}
                    format={'L'}
                    className={styles.dateInput}
                    suffixIcon={<CalendarIcon />}
                    clearIcon={<ClearIcon />}
                    divider
                  />
                </div>
              )}
            />
          </div>
          <div className={styles.doubleForm}>
            <FormInput label="Email" name="email" />
            <Controller
              name="phone"
              render={({ field }) => (
                <div className={styles.phoneWrapper}>
                  <label htmlFor="phoneInput">Phone </label>
                  <NewMaskInput
                    className="inputMask"
                    autocomplete="off"
                    mask=" +1 999 999 9999"
                    maskChar=" "
                    id={'phoneInput'}
                    {...field}
                  />
                </div>
              )}
            />
          </div>
          <FormSelect
            label="Depot"
            data-testid="selectDepot"
            name="bus_depot_id"
            showSearch={false}
            options={depotOptions}
          />
          <FormInput
            label="Driver License"
            data-testid="driverLicInp"
            name="driver_license"
          />
          <div className={styles.doubleForm}>
            <FormInput
              label="Vehicle Type Permit Level"
              name="vehicle_type_permit_level"
            />
            <FormInput
              label="Vehicle Capacity Permit Level"
              name="vehicle_capacity_permit_level"
              type="number"
            />
          </div>
          <div className={styles.footer}>
            <Button onClick={onCloseModal} buttonColor="gray">
              Cancel
            </Button>
            <Button
              data-testid="saveBtn"
              isLoading={response.isLoading || updateResponse.isLoading}
            >
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </NewModal>
  )
}

const ResetPassword = ({ close }) => {
  const methods = useForm()
  const [update, responseUpdate] = useUpdateDriverMutation()
  const onSubmit = (data) => {
    const currentDriver = useModalStore.getState().state.driver
    update({ ...currentDriver, ...data })
  }
  const formRef = useRef<HTMLFormElement | null>(null)
  return (
    <FormProvider {...methods}>
      <form ref={formRef} onSubmit={methods.handleSubmit(onSubmit)}>
        <FormInput
          rules={{
            required: true,
            validate: {
              valid: (value, formValues) => {
                if (value !== formValues.confirm || !(value ?? '')?.trim()) {
                  return false
                } else {
                  methods.clearErrors('confirm')
                }
              },
            },
          }}
          label="Password"
          name="password"
          type="password"
        />
        <FormInput
          rules={{
            required: true,
            validate: {
              valid: (value, formValues) => {
                if (value !== formValues.password || !(value ?? '')?.trim()) {
                  return false
                } else {
                  methods.clearErrors('password')
                }
              },
            },
          }}
          label="Confirm Password"
          name="confirm"
          type="password"
        />
        <div className={styles.resetPasswordFooterWrapper}>
          <Button
            buttonColor="gray"
            onClick={close}
            className={styles.resetPasswordFooter}
            type="button"
          >
            Cancel
          </Button>
          <Button
            className={styles.resetPasswordFooter}
            onClick={() => {
              methods.handleSubmit(onSubmit)()
            }}
            isLoading={responseUpdate.isLoading}
            type="button"
          >
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}
