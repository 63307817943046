import _ from 'lodash'

function sortingObjByProp (arr, propKey) {
  if (_.isEmpty(arr)) return []
  if (!arr[0].hasOwnProperty(propKey)) return arr
  return arr.sort((a, b) => {
    a = a[propKey].trim()
    b = b[propKey].trim()

    return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
  })
}

function sortByType (array) {
  let abc = []
  let numberAbc = []
  let numbers = []
  let objects = []

  let reAZ = new RegExp(/[0-9]/)

  array.forEach((i) => {
    if (typeof i === 'object') {
      objects.push(i)
    } else
    if (Number.isInteger(i * 1)) {
      numbers.push(i)
    } else if (reAZ.test(i[0])) {
      numberAbc.push(i)
    } else if (typeof i === 'string') {
      abc.push(i)
    } else {
      console.warn(i, 'not type check')
    }
  })
  return [abc, numberAbc, numbers, objects]
}

export function filterArrByAbcInt (array = [], propKey) {
  let collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
  let [abc, numberAbc, numbers, objects] = sortByType(array)

  let abc_sort = abc.sort(collator.compare)
  let numberAbc_sort = numberAbc.sort(collator.compare)
  let numbers_sort = numbers.sort(collator.compare)

  let objects_sort = sortingObjByProp(objects, propKey)

  return numbers_sort.concat(numberAbc_sort, abc_sort, objects_sort)
}

export const sortItemsByNumAbc = (a, b) => {
  const aName = a.name ? a.name.toLowerCase() : a.title.toLowerCase()
  const bName = b.name ? b.name.toLowerCase() : b.title.toLowerCase()

  const aStartsWithNumber = /^\d/.test(aName)
  const bStartsWithNumber = /^\d/.test(bName)

  if (aStartsWithNumber && bStartsWithNumber) {
    return aName.localeCompare(bName, undefined, { numeric: true })
  }

  if (aStartsWithNumber) {
    return -1
  }

  if (bStartsWithNumber) {
    return 1
  }

  return aName.localeCompare(bName)
}
