import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModalParent from '../../../../../../components/BaseModal'
import { transferInRun } from '../../../../../../redux/routesView/actions'
import TimePicker from 'antd/es/time-picker'
import moment from 'moment'
import i18n from '../../../../../../i18n'
import { ClearIcon, TimeIcon } from '../../../../../../img/icons'
import { NewSingleDatePicker } from '../../../../../../entities/ui/NewSingleDatePicker/NewSingleDatePicker'

class ModalDepotAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formErrors: {
        address: false,
      },
      formEmpty: {
        address: true,
      },
      routeWaittime: 0,
      routeWaittime2: 0,
      anchor_time: '',
      anchor_time2: '',
    }
  }

  componentDidMount() {
    let name1, name2, points1, points2
    this.props.routes.forEach((gr) => {
      gr.routes.forEach((x) => {
        if (x.id === this.props.activeRuns[0]) {
          name1 = x.title
          points1 = x.points
        }
        if (x.id === this.props.activeRuns[1]) {
          name2 = x.title
          points2 = x.points
        }
      })
    })

    this.setState({
      name1: name1,
      name2: name2,
      points1: points1,
      points2: points2,
    })
  }

  addTransferPoint = () => {
    let errors = this.state.formErrors
    if (this.state.formEmpty.address) {
      errors.address = true

      this.setState({
        formErrors: errors,
      })
    }

    if (this.state.formErrors.address) return

    let {
      routeWaittime,
      routeWaittime2,
      position,
      position2,
      address,
      title,
      points1,
      points2,
    } = this.state

    let newPosition = this.props.getStopPosition(position, points1)
    let newPosition2 = this.props.getStopPosition(position2, points2)

    let transfer = {
      address: address,
      name: title,
      routes: [
        {
          routeId: this.props.activeRuns[0],
          waittime: parseInt(routeWaittime) * 60,
          position: +newPosition,
          time: this.state.anchor_time,
          isAnchor: true,
        },
        {
          routeId: this.props.activeRuns[1],
          waittime: parseInt(routeWaittime2) * 60,
          position: +newPosition2,
          time: this.state.anchor_time2,
          isAnchor: true,
        },
      ],
    }

    this.props.dispatch(
      transferInRun({ ...transfer }, { route_id: this.props.activeRuns[0] })
    )
    this.props.close()
  }

  handleChange = (type, e) => {
    e.preventDefault()

    this.setState({
      [type]: e.target.value,
    })

    let errors = this.state.formErrors
    let empty = this.state.formEmpty

    if (!e.target.value || e.target.value === '') {
      errors[type] = true
      empty[type] = true
    } else {
      errors[type] = false
      empty[type] = false
    }

    this.setState({
      formErrors: errors,
      formEmpty: empty,
    })
  }

  handleChangeAnchor = (type, value) => {
    if (type === 'anchor_time') {
      this.setState({
        anchor_time: value,
      })
    }
    if (type === 'anchor_time2') {
      this.setState({
        anchor_time2: value,
      })
    }
  }

  render() {
    let {
      routeWaittime,
      routeWaittime2,
      position,
      position2,
      address,
      name1,
      name2,
      title,
    } = this.state
    const { close } = this.props
    return (
      <ModalParent
        id="transfer"
        title={i18n.t('Create Transfer Stop')}
        save={this.addTransferPoint}
        closeFn={close}
      >
        <div className="line flex">
          <div style={{ width: '100%' }}>
            <span>{i18n.t('Transfer Stop Name')}</span>
            <input
              value={title}
              onChange={(e) => this.handleChange('title', e)}
              type="text"
              placeholder={i18n.t('Transfer Stop Name')}
            />
          </div>
        </div>

        <div className="line flex">
          <div style={{ width: '100%' }}>
            <span
              className={
                this.state.formErrors.address
                  ? 'line-header error'
                  : 'line-header'
              }
            >
              {i18n.t('Address')}
            </span>
            <input
              autoFocus={true}
              value={address}
              onChange={(e) => this.handleChange('address', e)}
              type="text"
              className={this.state.formErrors.address ? 'error' : ''}
              placeholder={i18n.t('Address')}
            />
          </div>
        </div>

        <span>
          <h1>{name1}</h1>
        </span>

        <div className="line flex">
          <div className="half">
            <span>{i18n.t('Wait Time')}</span>
            <input
              value={routeWaittime}
              onChange={(e) => this.handleChange('routeWaittime', e)}
              type="text"
              placeholder={i18n.t('In Minutes')}
            />
          </div>
          <div className="half" style={{ paddingRight: '5px' }}>
            <span>{i18n.t('Enter Stop Number')}</span>
            <input
              value={position}
              onChange={(e) => this.handleChange('position', e)}
              type="text"
              placeholder={i18n.t('Last on the List')}
            />
          </div>

          <div className="half" style={{ paddingLeft: '5px' }}>
            <span>Anchor Time for Transfer Stop</span>
            <div
              id={'runBuildModal'}
              style={{ width: '100%', position: 'relative' }}
            >
              <NewSingleDatePicker
                style={{ width: '100%' }}
                use12Hours
                id={'runBuildModal'}
                className={'ant-calendar-picker'}
                format="h:mm A"
                disabledHours={() => this.state.disabledTime}
                defaultValue={
                  this.state.anchor_time &&
                  moment(this.state.anchor_time, 'h:mm A')
                }
                allowClear={false}
                onChange={(e) =>
                  this.handleChangeAnchor(
                    'anchor_time',
                    e ? e.format('HH:mm') : null
                  )
                }
                picker="time"
                divider
                clearIcon={<ClearIcon />}
                suffixIcon={<TimeIcon />}
              />
            </div>
          </div>
        </div>

        <span>
          <h1>{name2}</h1>
        </span>
        <div className="line flex">
          <div className="half">
            <span>{i18n.t('Wait Time')}</span>
            <input
              value={routeWaittime2}
              onChange={(e) => this.handleChange('routeWaittime2', e)}
              type="text"
              placeholder={i18n.t('In Minutes')}
            />
          </div>
          <div className="half" style={{ paddingRight: '5px' }}>
            <span>{i18n.t('Enter Stop Number')}</span>
            <input
              value={position2}
              onChange={(e) => this.handleChange('position2', e)}
              type="text"
              placeholder={i18n.t('Last on the List')}
            />
          </div>
          <div className="half" style={{ paddingLeft: '5px' }}>
            <span>{i18n.t('Anchor Time for Transfer Stop')}</span>
            <div
              id={'runBuildModal'}
              style={{ width: '100%', position: 'relative' }}
            >
              <NewSingleDatePicker
                style={{ width: '100%' }}
                use12Hours
                id={'runBuildModal'}
                className={'ant-calendar-picker'}
                format="h:mm A"
                disabledHours={() => this.state.disabledTime}
                defaultValue={
                  this.state.anchor_time2 &&
                  moment(this.state.anchor_time2, 'h:mm A')
                }
                allowClear={false}
                onChange={(e) =>
                  this.handleChangeAnchor(
                    'anchor_time2',
                    e ? e.format('HH:mm') : null
                  )
                }
                suffixIcon={<TimeIcon />}
                clearIcon={<ClearIcon />}
                picker="time"
                divider
              />
            </div>
          </div>
        </div>

        <div className="center">
          <div className="buttons">
            <button className="cancel" onClick={() => close('modalStop')}>
              {i18n.t('Cancel')}
            </button>
            <button onClick={this.addTransferPoint} className="save">
              {i18n.t('Save')}
            </button>
          </div>
        </div>
      </ModalParent>
    )
  }
}

function mapStateToProps(state) {
  return {
    routes: state.routesView.routes,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDepotAdd)
