import { decode } from '@googlemaps/polyline-codec'
import * as turf from '@turf/turf'
import { TripRoute } from '@/types/types'

export const findPositionAndProjection = (
  osrm: TripRoute,
  point: { x: number; y: number }
) => {
  if (!osrm || !point?.x || !point?.y) return null

  let minDistance = Number.MAX_SAFE_INTEGER
  let index = 0
  let projection = null

  for (let i = 0; i < osrm.legs.length; i++) {
    const leg = osrm.legs[i]
    for (const step of leg.steps) {
      const decodedPoints = decode(step.geometry, 5).map((p) => [p[1], p[0]])

      if (decodedPoints.length > 1) {
        const distance = turf.pointToLineDistance(
          turf.point([point.y, point.x]),
          turf.lineString(decodedPoints),
          { units: 'meters' }
        )
        if (distance < minDistance) {
          minDistance = distance
          index = i
          const snapped = turf.nearestPointOnLine(
            turf.lineString(decodedPoints),
            turf.point([point.y, point.x]),
            { units: 'meters' }
          )
          projection = {
            x: snapped.geometry.coordinates[1],
            y: snapped.geometry.coordinates[0],
          }
        }
      }
    }
  }

  return { position: index + 2, projection }
}
