import { createApi } from '@reduxjs/toolkit/dist/query/react'
import axios from 'axios'
import { axiosBaseQuery } from '@/helpers/axiosBaseQuery'
import { getUrlWithSearchParams } from '@/helpers/getUrlWithSearchParams'
import { configureScope } from '@sentry/browser'
import { UNAUTHENTICATED } from '@/redux/auth/actionTypes'
import { notification } from 'antd'
import { browserHistory } from 'react-router'
import { routeLinks } from '@/shared/routeLinks'
import { useRouterStore } from '@/shared/hooks/routeHooks/useRouter'

export interface ILoginResponse {
  company_id: number
  created: number
  id: number
  id_user: number
  token: string
}

export const authApi = createApi({
  reducerPath: 'testApi',
  baseQuery: axiosBaseQuery(axios),
  endpoints: (build) => ({
    postLogin: build.query<
      { data: ILoginResponse },
      {
        login: string
        password: string
        errorFn: () => void
      }
    >({
      query: ({ login, password }) => {
        return {
          method: 'post',
          url: getUrlWithSearchParams('user/login'),
          data: `username=${login}&password=${password}`,
        }
      },
      onQueryStarted: async ({ errorFn }, { queryFulfilled }) => {
        localStorage.removeItem('token')
        const router = useRouterStore.getState().router
        delete axios.defaults.headers.common['Authorization']

        try {
          const response = await queryFulfilled
          localStorage.setItem('token', response.data.data.token)

          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + response.data.data.token

          router.push(routeLinks.routeTool)
        } catch (e) {
          await errorFn()
          notification.error({ message: 'Incorrect username or password.' })
        }
      },
    }),

    setNewPassword: build.query<any, { token: string; password: string }>({
      query: ({ token, password }) => ({
        url: getUrlWithSearchParams('/users/set-password', { token, password }),
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled
          notification.success({ message: 'Password successfully changed' })

          browserHistory.push('/client/signin')
        } catch (e) {
          notification.error({ message: 'An error occurred, please try again' })
        }
      },
    }),
    onForgotPassword: build.query<boolean, string>({
      query: (email) => {
        return {
          url: getUrlWithSearchParams('/users/reset-password', { email }),
          method: 'get',
        }
      },
      keepUnusedDataFor: 0,
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled
          if (data) {
            notification.success({
              message:
                'A link to reset your password has been sent to your email',
            })
          }
        } catch (e) {
          notification.error({
            message: 'An error occurred while sending the password reset link',
          })
        }
      },
    }),
    onBackdoorSignIn: build.query<
      { data: { token: string } },
      { id: string; hash: string }
    >({
      keepUnusedDataFor: 0,
      query: (params) => {
        return {
          url: getUrlWithSearchParams('/users/backdoor', params),
          method: 'get',
        }
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled
          if (!data) {
            notification.error({ message: 'Failed to log in' })
            browserHistory.push(routeLinks.signin)
          } else {
            localStorage.setItem('token', data.data.token)
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + data.data.token

            browserHistory.replace('/client/RouteTool')
          }
        } catch (e) {
          notification.error({ message: 'Failed to log in' })
          browserHistory.push(routeLinks.signin)
        }
      },
    }),
    logout: build.query<{ data: any }, void>({
      keepUnusedDataFor: 0,
      query: () => ({
        method: 'post',
        url: '/users/logout',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled
          dispatch({
            type: UNAUTHENTICATED,
            payload: response.data.data,
          })
        } finally {
          configureScope((scope) => scope.setUser(null))
          localStorage.clear()
          browserHistory.push(routeLinks.signin)
        }
      },
    }),
  }),
})

export const {
  useLazyLogoutQuery,
  useLazySetNewPasswordQuery,
  useLazyPostLoginQuery,

  useLazyOnForgotPasswordQuery,
  useLazyOnBackdoorSignInQuery,
} = authApi
