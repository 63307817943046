import moment from 'moment/moment'
import 'moment-timezone'
import { getTimestampWithOffset } from '@/pages/Gps/NewGpsDetails/helpers/common'

export const calculateEndTime = (
  end: number | null,
  scheduled: number,
  timeZone: string
) => {
  if (end) return getTimestampWithOffset(end, timeZone)

  const isDayAfter = moment(Date.now()).isAfter(moment(scheduled * 1000), 'day')

  if (isDayAfter) {
    const midnight = moment(scheduled * 1000)
      .utc()
      .endOf('day')
      .unix()
    const offset = moment(midnight * 1000)
      .tz(timeZone)
      .utcOffset()
    return getTimestampWithOffset(midnight - offset * 60, timeZone)
  } else {
    const differenceFromNow = moment().diff(moment(scheduled * 1000))
    return getTimestampWithOffset(
      differenceFromNow < 0 ? scheduled : moment().utc().unix() + 3600,
      timeZone
    )
  }
}
