import * as types from './actionTypes'
import api from '../apiConfig'

const common = api.common
const rqv2 = api.gpsV2

export function getTransportationCategories() {
  return function (dispatch) {
    common.get('transportation-categories').then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: types.TRANCPORTATION_CATEGORIES,
          payload: response.data,
        })
      } else {
        console.warn('error')
      }
    })
  }
}
