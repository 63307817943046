import { IRegistrationItem } from '@/store/routeTool/routeTool.types'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Input } from 'antd'
import styles from '@/entities/modals/RouteToolRegistrations/RTRegistrationsOpenedModal/screens/RejectScreen/RejectScreen.module.less'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { useLazyManualAcceptRegistrationRequestQuery } from '@/store/routeTool/routeTool'
import { useRTRegistrationsStore } from '@/entities/modals/RouteToolRegistrations/RTRegistrationsOpenedModal/store'

export const ManualAcceptScreen = (item: IRegistrationItem) => {
  const methods = useForm()
  const [onReject, response] = useLazyManualAcceptRegistrationRequestQuery()
  const onSubmit = ({ message }: { message: string }) => {
    onReject({ id: item.id, message })
  }
  const setScreen = useRTRegistrationsStore.getState().setScreen

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <p>
          You are going to manually update the rider's record. Please provide
          message to the guardian:
        </p>
        <Controller
          name={'message'}
          rules={{
            required: {
              value: true,
              message: 'error',
            },
          }}
          render={({ field, fieldState }) => (
            <Input.TextArea
              status={fieldState?.error?.message ? 'error' : undefined}
              {...field}
              placeholder="Message"
            />
          )}
        />
        <div className={styles.footer}>
          <Button
            onClick={() => setScreen('main')}
            buttonColor="gray"
            type="button"
          >
            Cancel
          </Button>
          <Button isLoading={response.isFetching} type="submit">
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}
