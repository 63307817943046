export const StatusNewIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F87A03"
          fillRule="nonzero"
          d="M23.807 12.88l-1.76-2.793a1.001 1.001 0 0 1-.132-.745l.699-3.228a1 1 0 0 0-.672-1.164l-3.145-1.008a1 1 0 0 1-.58-.486L16.68.534a1 1 0 0 0-1.263-.46l-3.058 1.25a1.002 1.002 0 0 1-.756 0L8.545.073a1 1 0 0 0-1.263.46L5.743 3.456a1 1 0 0 1-.58.486L2.02 4.95a1 1 0 0 0-.672 1.164l.699 3.228a1 1 0 0 1-.131.745L.154 12.88a1 1 0 0 0 .234 1.323l2.61 2.023a1 1 0 0 1 .378.655l.447 3.272a1 1 0 0 0 1.03.864l3.3-.128c.26-.01.516.083.71.259l2.446 2.219a1 1 0 0 0 1.344 0l2.445-2.22a.999.999 0 0 1 .711-.258l3.3.128a1 1 0 0 0 1.03-.864l.447-3.272a1 1 0 0 1 .378-.655l2.61-2.023a1 1 0 0 0 .233-1.323z"
        />
        <text
          fill="#FFF"
          fontFamily="HelveticaNeue-Medium, Helvetica Neue"
          fontSize="7"
          fontWeight="400"
        >
          <tspan x="5" y="13.99">
            New
          </tspan>
        </text>
      </g>
    </svg>
  )
}
