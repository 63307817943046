import moment from 'moment-with-locales-es6'

import i18n from '../../i18n'
import momentLib from 'moment'
import _, { toNumber } from 'lodash'
import { momentTzHelper } from '../../helpers/momentTz'

export function getTime(start, end) {
  let startT = moment(start * 1000)
    .locale(i18n.language)
    .format('LT')
  let endT = moment(end * 1000)
    .locale(i18n.language)
    .format('LT')
  return startT + ' - ' + endT
}

export function getStartEndWithTimezone(start, end, tz) {
  if (!tz || !start || !end) {
    return '--:-- - --:--'
  }
  let startT = momentLib(start * 1000)
    .tz(tz)
    .format('HH:mm')
  let endT = momentLib(end * 1000)
    .tz(tz)
    .format('HH:mm')
  return startT + ' - ' + endT
}

export function getTimeLastWithTimezone(time, tz) {
  if (!tz || !time) {
    return '--:--'
  }
  return momentLib(time * 1000)
    .tz(tz)
    .format('HH:mm')
}

export function arrivalTimeUtsToLocal(timeZone, arriveTime, lng) {
  let time = momentLib().unix() // время сейчас no UTC
  let localUTCOffset = momentTzHelper()(time * 1000, timeZone)._offset
  moment.locale(lng)
  return moment(arriveTime, 'LT').add(localUTCOffset, 'm').format('LT')
}

export function getTimeLast(time) {
  return moment(time * 1000)
    .locale(i18n.language)
    .format('LT')
}

export function getTimeLastUTC(time) {
  // let sec = moment(time * 1000).second();
  // let tt = time;
  // if(sec >= 30) tt +=  60;

  return moment(time * 1000)
    .locale(i18n.language)
    .utc()
    .format('LT')
}

export function getTimeLastLocal(time) {
  return moment(time * 1000)
    .locale(i18n.language)
    .local()
    .format('LT')
}

export function getWT(wt) {
  if (!wt) return `0 ` + i18n.t('min')
  return `${wt / 60} ` + i18n.t('min')
}

export function totalDist(dist) {
  return i18n.t('distanceFormat', { meters: dist })
}

export function totalDist2(osrm) {
  let dist = osrm.distance
  osrm.legs.forEach()
  return i18n.t('distanceFormat', { meters: dist })
}

export function totalTimeWithWT(osrm, points) {
  let duration = osrm.duration
  let waitTimeAll = 0
  points.forEach((l) => {
    waitTimeAll += l.waittime
  })
  let time = Math.ceil((duration + waitTimeAll) / 60)

  if (time < 0) return '0 ' + i18n.t('min')
  if (time > 0 && time < 60) {
    return `${time} ` + i18n.t('min')
  } else {
    let h = Math.trunc(time / 60)
    let min = time - h * 60
    return `${h}` + i18n.t('h') + ` ${min}` + i18n.t('min')
  }
}

export function totalTimeWithWTRT(osrm, points) {
  let duration = osrm.duration
  let waitTimeAll = 0
  points.forEach((l) => {
    waitTimeAll += l.routeWaittime
  })
  let time = Math.ceil((duration + waitTimeAll) / 60)

  if (time < 0) return `0 ` + i18n.t('min')
  if (time > 0 && time < 60) {
    return `${time} ` + i18n.t('min')
  } else {
    let h = Math.trunc(time / 60)
    let min = time - h * 60
    return `${h}` + i18n.t('h') + ` ${min}` + i18n.t('min')
  }
}

export function totalTimeWithWT2(osrm) {
  let lastPoint = _.last(osrm.legs)
  let f = osrm.legs[0].start_point.time
  let l = lastPoint.end_point.time + toNumber(lastPoint.end_point.waittime)

  let duration = l - f

  if (!duration) return '0 ' + i18n.t('min')
  let time = Math.trunc(duration / 60)
  if (time < 0 || time === 0) return '0 ' + i18n.t('min')
  if (time > 0 && time < 60) {
    return `${time} ` + i18n.t('min')
  } else {
    let h = Math.trunc(time / 60)
    let min = time - h * 60
    return `${h}` + i18n.t('h') + ` ${min}` + i18n.t('min')
  }
}

export function totalTime(duration) {
  if (!duration) return '0 ' + i18n.t('min')
  let time = Math.trunc(duration / 60)

  if (time < 0 || time === 0) return `0 ` + i18n.t('min')

  if (time > 0 && time < 60) {
    return `${time} ` + i18n.t('min')
  } else {
    let h = Math.trunc(time / 60)
    let min = time - h * 60
    return `${h}` + i18n.t('h') + `${min}` + i18n.t('min')
  }
}

export function averageSpeed(dist, time) {
  return i18n.t('avgSpeedFormat', { disttime: { dist: dist, time: time } })
}

//
// export function GetTimePoint(Min, defaults, index) {
//   console.log('GetTimePoint FN')
//   if (index === 0) {
//     return moment()
//       .subtract(1, "days")
//       .endOf("day")
//       .add(defaults ? defaults : Min, "minutes")
//       .format("hh:mm A");
//   } else {
//     let startDate = moment(defaults, "hh:mm A");
//     return startDate.add(Min, "minutes").format("hh:mm A");
//   }
// }
