export const BusDepotsIcons = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="19"
      viewBox="0 0 12 19"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M8.148 11.429c-1.185 1.786-1.88 3.88-1.88 6.41h-1c0-2.53-.693-4.625-1.874-6.412-.578-.875-1.957-2.404-1.934-2.368C.696 7.837.5 7.055.5 5.714.5 2.843 2.88.5 5.768.5c2.87 0 5.295 2.357 5.295 5.214 0 1.32-.206 2.116-.974 3.345.022-.034-1.36 1.495-1.941 2.37zm-1.88 6.41c0 .667-1 .667-1 0 0-1.317.175-2.529.5-3.648.325 1.12.5 2.331.5 3.648z"
      />
    </svg>
  )
}
