import {
  ModalsEnum,
  useModalSelector,
  useModalStore,
} from '@/store/modal/ModalStore'
import { NewModal } from '@/entities/ui/NewModal/NewModal'
import { FormProvider, useForm } from 'react-hook-form'
import React, { useMemo } from 'react'
import { FormInput } from '@/entities/ui/NewInput/FormInput'
import styles from './OptimizeRunModal.module.less'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { useLazyOptimizeRunQuery } from '@/store/routeTool/routeTool'
import { useAppSelector } from '@/redux'
import { IOptimizeRunModalForm } from '@/entities/modals/OptimizeRunModal/OptimizeRunModal.types'
import { useSocketConnectStore } from '@/features/containers/SocketConnect/store'

export const OptimizeRunModal = () => {
  const { isOpen, onCloseModal, state } = useModalSelector(
    ModalsEnum.OPTIMIZE_RUN_MODAL
  )

  return isOpen ? (
    <_OptimizeRunModal
      routeId={state?.id}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
    />
  ) : null
}

export const _OptimizeRunModal = ({
  isOpen,
  onCloseModal,
  routeId,
}: {
  isOpen: boolean
  onCloseModal: () => void
  routeId?: number
}) => {
  const points = useAppSelector((state) => {
    return (
      (
        (state?.routesView?.routes || [])?.find((item) =>
          item.routes.some((el) => el?.id === routeId)
        )?.routes ?? []
      ).find((el) => el?.id === routeId)?.points ?? []
    )
  })

  const methods = useForm<IOptimizeRunModalForm>({
    defaultValues: useMemo(
      () => ({
        from: 0,
        to: (points?.length || 1) - 1,
      }),
      [points?.length]
    ),
  })

  const [request] = useLazyOptimizeRunQuery()

  const pointsIds = points?.reduce(
    (acc, point) => ({ ...acc, [point.position - 1]: point.id }),
    {}
  )

  const onSubmit = ({ to, from }: IOptimizeRunModalForm) => {
    const data = {
      routeId,
      pointIds: Array.from({ length: to - from + 1 }, (_, index) => {
        return pointsIds[index + Number(from)]
      }),
    }
    useSocketConnectStore.getState().setOptimize({ isLoading: true })

    request(data)
  }

  const onClose = () => {
    useModalStore.getState().onCloseModal()
  }

  const isProcessing = useSocketConnectStore(
    (state) => state.optimize.isLoading
  )

  return (
    <NewModal
      title="Optimize Run"
      width={420}
      open={isOpen}
      onClose={onCloseModal}
      data-testid="modalDeleteBus"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <p className={styles.title}>
            This process may remove redirection points
          </p>
          <p className={styles.subtitle}>
            Select Range of Stops for Optimization
          </p>
          <div className={styles.inputsWrapper}>
            <FormInput
              rules={{
                validate: (value, formValues) => {
                  if (value > formValues.to) {
                    return '"From" more than "To"'
                  } else if (
                    Math.abs(Number(value) - Number(formValues.to)) < 3
                  ) {
                    return 'There must be more than 2 points'
                  } else if (value < 0) {
                    return 'The number must not be less than 0'
                  }
                },
              }}
              label="From"
              name="from"
              type="number"
            />
            <FormInput
              rules={{
                validate: (value, formValues) => {
                  const max = points?.length - 1
                  if (value < formValues.from) {
                    return '"To" cannot be less than "From"'
                  } else if (value < 0) {
                    return 'The number must not be less than 0'
                  } else if (
                    Math.abs(Number(value) - Number(formValues.from)) < 2
                  ) {
                    return 'There must be more than 2 points'
                  } else if (value > max) {
                    return max >= 0 ? `Maximum ${max}` : 'Incorrect position'
                  }
                },
              }}
              label="To"
              name="to"
              type="number"
            />
          </div>
          <div className={styles.footer}>
            <Button onClick={onClose} type="button" buttonColor="gray">
              Cancel
            </Button>
            <Button isLoading={isProcessing} type="submit">
              Optimize
            </Button>
          </div>
        </form>
      </FormProvider>
    </NewModal>
  )
}
