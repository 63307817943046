import { contextMenu } from 'react-contexify'
import * as L from 'leaflet'
import { Popup } from 'react-leaflet'
import React from 'react'
import _ from 'lodash'
import ReactDOMServer from 'react-dom/server'
import mapIcon from '../RepeatFunctions/mapicon'
import moment from 'moment'
import { momentTzHelper } from '@/helpers/momentTz'
import styles from './mapSpecialFns.module.less'
export function contextMenuOnDepot(e, mainState) {
  e.originalEvent.preventDefault()

  mainState.setState({
    mapPointModal: false,
  })

  contextMenu.show({
    id: 'depotMenu',
    event: e.originalEvent,
    props: {
      foo: 'bar',
    },
  })
}

let renderedAddresses = []
let timerIn, timerOut

export function redirectionIcon() {
  let svg =
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" height="15" viewBox="0 0 15 15">\n' +
    '    <defs>\n' +
    '        <circle id="b" cx="360.5" cy="526.5" r="5.5"/>\n' +
    '        <filterMainPage id="a" width="145.5%" height="145.5%" x="-22.7%" y="-13.6%" filterUnits="objectBoundingBox">\n' +
    '            <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter1"/>\n' +
    '            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>\n' +
    '            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5"/>\n' +
    '            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>\n' +
    '            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.105632649 0"/>\n' +
    '        </filterMainPage>\n' +
    '    </defs>\n' +
    '    <g fill="none" fill-rule="evenodd" transform="translate(-353 -520)">\n' +
    '        <use fill="#000" filterMainPage="url(#a)" xlink:href="#b"/>\n' +
    '        <use fill="#FFE23D" stroke="#7B7B7B" xlink:href="#b"/>\n' +
    '    </g>\n' +
    '</svg>\n'

  let iconRedirection = L.divIcon({
    className: 'leaflet-data-marker',
    html: L.Util.template(svg, {}),
    iconSize: [15, 15],
    popupAnchor: [0, 0],
    tooltipAnchor: [0, 0],
  })

  return iconRedirection
}

export function getPosition(address) {
  return [address.address.lat, address.address.lon]

  if (renderedAddresses[address.id]) {
    return renderedAddresses[address.id]
  } else {
    let id = address.id
    let lat = address.address.lat * 1 + (Math.random() - 0.5) * 0.00015
    let lon = address.address.lon * 1 + (Math.random() - 0.5) * 0.00015
    renderedAddresses[id] = [lat, lon]
    return [lat, lon]
  }
}

function hiddenPopup(popup) {
  let div = document.querySelectorAll('.leaflet-popup')

  if (!_.isEmpty(div)) {
    for (let i = 0; i < div.length; i++) {
      div[i].addEventListener('mouseleave', function (event) {
        popup.closePopup()
      })
    }
  }
}
let flag = false
function fnBindMouse(type) {
  if (type === 'mouseover') {
    flag = false
  } else {
    flag = true
  }
}

export function fnMouseOver(e) {
  flag = false
  e.originalEvent.preventDefault()
  clearTimeout(timerOut)
  timerIn = setTimeout(function () {
    e.sourceTarget.openPopup()

    let dom = document.querySelector('.leaflet-popup-content-wrapper')
    if (dom) {
      dom.addEventListener('mouseover', fnBindMouse('mouseover'))
      dom.addEventListener('mouseout', fnBindMouse('mouseout'))
    }

    if (flag) {
      hiddenPopup(e.sourceTarget)
    }
  }, 100)
}
export function fnMouseOut(e) {
  flag = false

  e.originalEvent.preventDefault()

  clearTimeout(timerIn)
  timerOut = setTimeout(function () {
    if (flag || e.originalEvent.offsetY > 0) {
      e.sourceTarget.closePopup()
    }
  }, 300)
}

export function directionsPathOptionsAnyColors(i) {
  let color = [1, 2, 3].map(() => Math.floor(Math.random() * (255 - 0)))

  let colors = [
    `#36a9f4`,
    `#ff00ff`,
    `#fd9800`,
    `#008000`,
    `#0000ff`,
    `#9400d3`,
    `#ff4500`,
    `#ee82ee`,
    `#71d0c5`,
    `#fdc006`,
  ]
  if (i < 10) {
    color = colors[i]
  } else {
    color = `rgba(${color[0]},${color[1]},${color[2]})`
  }

  return {
    weight: 7,
    opacity: 0.8,
    color: color,
  }
}

export function directionsPathOptions() {
  //  let colors = [1, 2, 3].map(() => Math.floor(Math.random() * (255 - 0)));

  let color1 = `rgba(54, 169, 244, 1)`
  //let color2 = `rgba(5, 252, 43, 1)`;

  return {
    weight: 7,
    opacity: 0.8,
    color: color1,
  }
}

export function directionsPathOptionsWith2Colors(active) {
  let color = `rgba(156, 156, 156, 0.8)`

  if (active) {
    color = `rgba(54, 169, 244, 1)`
  }

  return {
    weight: 7,
    opacity: 0.8,
    color: color,
  }
}

export function arrowOptions() {
  return {
    repeat: true,
    offset: 3,
    attributes: {
      fill: 'white',
      'font-weight': 'normal',
      'font-size': '12',
    },
  }
}

export function getIconDiv(type, text) {
  let BASE_URL = 'https://demo.app.schoolbusmanager.com/'
  let url = ''
  if (type) {
    switch (type) {
      case 1:
        url = encodedIcon('', '#fb7266', '#f44940', '#ffe23d', false, null)
        break
      case 2:
        url = encodedIcon('', '#fb7266', '#f44940', '#ffe23d', true, null)
        break
      case 3:
        url = encodedIcon('', '#fb7266', '#f44940', '#fff', true, null)
        break
      case 4:
        url = encodedIcon('', '#fb7266', '#f44940', '#fff', true, null)
        break
      case 'men_dropoff_hovered':
        url = encodedIcon('', '#fb7266', '#f44940', '#ffe23d', true, null)
        break
      case 5:
        url =
          BASE_URL + 'route/mapiconsvg?text=' + text + '&hat=1&hatColor=#70b8ff'
        break
      case 6:
        url =
          BASE_URL + 'route/mapiconsvg?text=' + text + '&hat=1&hatColor=yellow'
        break
      case 7:
        url = encodedIcon(text, '#00aaaa', '#005555', '#333')
        break
      case 8:
        url = encodedIcon(text, '#00aaaa', '#005555', '#333')
        break
      case 9:
        url = encodedIcon('D', '#1075a9', '#1075a9', '#fff')
        break
      case 10:
        url = BASE_URL + 'img/busstop.png'
        break
      case 11:
        url = encodedIcon(text, '#f57a20', '#f57a20', '#333')
        break
      case 12:
        url = encodedIcon(text, '#3023ae', '#c86dd7', '#333')
        break
      case 13:
        url = encodedIcon(text, '#1672A3', '#1672A3', '#000')
        break
      default:
        url =
          BASE_URL +
          'route/mapiconsvg?text=P&bgstart=%231075a9&bgend=%231075a9&color=%23fff'
        break
    }
  } else {
    url = encodedIcon(text, '#85d03d', '#69b921', '#333')
  }
  return url
}
export function getIconBusTop(type) {
  let icon = {
    mapIconUrl:
      '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="43" viewBox="0 0 24 43">\n' +
      '    <g fill="none" fill-rule="evenodd">\n' +
      '        <path fill="#1672A3" stroke="#1F5A86" stroke-linecap="round" stroke-linejoin="round" d="M20.219 18.187c1.875-3 2.094-4.782 2.094-7.187 0-6.075-5.188-11-11.188-11C5.05 0 0 4.925 0 11c0 2.405.188 4.187 2.063 7.187.835 1.338 9.062 10.063 9.062 23.438 0-13.375 8.258-22.1 9.094-23.438z" transform="translate(1 1)"/>\n' +
      '        <path fill="#FFF" fill-rule="nonzero" d="M14.75 13.737h-7.5v-2.632h7.5v2.632zm-.938 3.158c-.517 0-.937-.354-.937-.79 0-.436.42-.79.938-.79.517 0 .937.354.937.79 0 .436-.42.79-.938.79zm-5.624 0c-.518 0-.938-.354-.938-.79 0-.436.42-.79.938-.79.517 0 .937.354.937.79 0 .436-.42.79-.938.79zM6 16.368c0 .464.244.88.625 1.169v.937c0 .29.28.526.625.526h.625c.345 0 .625-.236.625-.526v-.527h5v.527c0 .29.28.526.625.526h.625c.345 0 .625-.236.625-.526v-.937c.381-.29.625-.705.625-1.169v-5.263C16 9.263 13.763 9 11 9s-5 .263-5 2.105v5.263zM4.226 8.61l6.603-3.888 6.961 3.896c.127.071.264.105.4.105.281 0 .555-.146.704-.405.221-.383.085-.87-.304-1.088l-7.371-4.126c-.254-.142-.566-.138-.816.01L3.395 7.238c-.384.226-.51.716-.28 1.095.23.378.727.501 1.11.275z" transform="translate(1 1)"/>\n' +
      '    </g>\n' +
      '</svg>\n',
    mapIconColor: '',
    mapIconColorInnerCircle: '',
    pinInnerCircleRadius: 0,
  }

  let iconBusTop = L.divIcon({
    className: 'leaflet-data-marker',
    html: L.Util.template(icon.mapIconUrl, {}),
    iconSize: [24, 43],
    iconAnchor: [12, 43],
    popupAnchor: [0, -20],
  })

  if (type) {
    return iconBusTop
  }
}

export function getIconBusStop() {
  let icon = {
    mapIconUrl:
      '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="43" viewBox="0 0 24 43">\n' +
      '    <g fill="none" fill-rule="evenodd">\n' +
      '        <path fill="#F6BF4F" stroke="#CDA042" stroke-linecap="round" stroke-linejoin="round" d="M20.219 18.187c1.875-3 2.094-4.782 2.094-7.187 0-6.075-5.188-11-11.188-11C5.05 0 0 4.925 0 11c0 2.405.188 4.187 2.063 7.187.835 1.338 9.062 10.063 9.062 23.438 0-13.375 8.258-22.1 9.094-23.438z" transform="translate(1 1)"/>\n' +
      '        <path fill="#000" fill-rule="nonzero" d="M15.32 11.917l.124 2.194c.019.32-.17.616-.569.889-.398.273-.942.49-1.632.65-.69.159-1.437.239-2.243.239-.806 0-1.553-.08-2.243-.24-.69-.16-1.234-.376-1.632-.649-.398-.273-.588-.57-.57-.889l.126-2.194 3.986 1.257c.102.032.213.048.333.048.12 0 .231-.016.333-.048l3.986-1.257zm3.527-2.466c.102.037.153.11.153.216s-.05.178-.153.215l-7.778 2.444c-.018.005-.041.007-.069.007-.028 0-.05-.002-.07-.007l-4.527-1.43c-.2.157-.364.415-.493.774-.13.359-.209.772-.236 1.24.291.166.437.419.437.757 0 .32-.134.567-.403.743l.403 3.007c.01.064-.01.122-.055.173-.042.051-.098.077-.167.077H4.556c-.07 0-.125-.026-.167-.077-.046-.05-.065-.109-.056-.173l.403-3.007c-.268-.176-.403-.424-.403-.743 0-.338.15-.595.452-.771.05-.959.277-1.722.68-2.292l-2.312-.722C3.05 9.845 3 9.773 3 9.667c0-.107.05-.179.153-.216l7.778-2.444C10.949 7.002 10.972 7 11 7c.028 0 .05.002.07.007l7.777 2.444z" transform="translate(1 1)"/>\n' +
      '    </g>\n' +
      '</svg>',
    mapIconColor: '',
    mapIconColorInnerCircle: '',
    pinInnerCircleRadius: 0,
  }

  return L.divIcon({
    className: 'leaflet-data-marker',
    html: L.Util.template(icon.mapIconUrl, {}),
    iconSize: [24, 43],
    iconAnchor: [12, 43],
    tooltipAnchor: [12, -25],
    popupAnchor: [0, -20],
  })
}

export function getIcon(type, text, is_primary, pixi) {
  const BASE_URL = 'https://demo.app.schoolbusmanager.com/'
  let size = [24, 43]
  let anchor = [12, 43]
  let url = ''

  const setCommonAttributes = () => {
    size = [24, 43]
    anchor = [12, 43]
  }

  const buildIconUrl = (params) => {
    return (
      BASE_URL + 'route/mapiconsvg?' + new URLSearchParams(params).toString()
    )
  }
  if (type) {
    switch (type) {
      case 'stops':
      case 'stopsTest':
      case 'men_pickup_abr':
      case 'men_dropoff_abr':
      case 'men_pickup':
      case 'men_dropoff':
      case 'men_pickup_hovered':
      case 'men_dropoff_hovered':
        url = encodedIcon(
          createAbbreviation(text),
          '#fb7266',
          '#f44940',
          type === 'men_pickup_abr'
            ? '#fff'
            : type === 'men_dropoff_hovered'
              ? '#ffe23d'
              : '#fff',
          type.includes('pickup') && is_primary ? '#000' : null
        )
        setCommonAttributes()
        break

      case 'y':
      case 'r':
        url = buildIconUrl({
          text,
          hat: 1,
          hatColor: type === 'y' ? '#70b8ff' : 'yellow',
        })
        break

      case 'b':
      case 'l':
        url = encodedIcon(text, '#00aaaa', '#005555', '#333')
        setCommonAttributes()
        break

      case 'w':
        url = encodedIcon('D', '#1075a9', '#1075a9', '#fff')
        break

      case 'busdepot':
        size = [32, 37]
        anchor = [16, 37]
        url = BASE_URL + 'img/busstop.png'
        break

      case 'school':
        url = encodedIcon(text, '#f6bf4f', '#f6bf4f', '#333', '', '#cda042')
        anchor = [12, 40]
        break

      case 'school2':
        url = encodedIcon(text, '#f57a20', '#f57a20', '#333')
        anchor = [12, 40]
        break

      case 'transfer':
        url = encodedIcon(text, '#c86dd7', '#3023ae', '#000', '', '#402bb2')
        break

      case 'riders':
        url = encodedIcon(text, '#fb7266', '#f44940', '#', '', '#')
        break

      case 'ft':
        url = encodedIcon(text, '#3cb0e7', '#3cb0e7', '#333')
        break

      case 'circle':
        size = [14, 14]
        anchor = [7, 7]
        url = circle()
        break

      case 'dpt':
        url = encodedIcon(text, '#1672A3', '#1672A3', '#000')
        break

      case 'hover':
        url = encodedIcon(text, '#ae0500', '#c15f5f', '#000')
        break

      default:
        url = buildIconUrl({
          text: 'P',
          bgstart: '#1075a9',
          bgend: '#1075a9',
          color: '#fff',
        })
        break
    }
  } else {
    url = encodedIcon(text, '#85d03d', '#69b921', '#333')
  }

  return pixi
    ? url
    : new L.Icon({
        iconUrl: url,
        iconSize: size,
        iconAnchor: anchor,
        tooltipAnchor: [12, -25],
        popupAnchor: [0, -20],
      })
}

export function assignedPopup(marker, readOnly, isLoading) {
  const isWriteRoutes = this.isWriteRoutes

  let title = marker.sequence + '. '
  let anchor = ''

  if (marker.object.type * 1 === 1) {
    title += marker.object.object && marker.object.object.full_name
  } else if (marker.object.type * 1 === 2 || marker.object.type * 1 === 5) {
    title += marker.object.busStopTitle ? marker.object.busStopTitle : 'Stop'
  } else if (marker.object.type * 1 === 3 || marker.object.type * 1 === 6) {
    title += marker.object.object && marker.object.object.name
  } else if (marker.object.type * 1 === 4) {
    title += marker.object.object && marker.object.object.name
  } else {
    return ''
  }

  if (marker.object.isAnchor && !isWriteRoutes) {
    anchor = (
      <div className="anchor">
        <div className="anchor_icon" />
        {marker.object.routeTime ? (
          <div className="anchor_time">
            {getLocalTime(
              marker.osrmTime ?? marker.object.routeTime,
              this.props.time_zone
            )}
          </div>
        ) : (
          ' - '
        )}
        <div onClick={() => this.anchorEdit(marker)} className="anchor_edit" />
        <div
          onClick={() => this.anchorDelete(marker)}
          className="anchor_delete"
        />
      </div>
    )
  }

  if (marker.object.type === 4 && !readOnly && !isWriteRoutes) {
    let object = {
      id: marker.object.id,
      item: {
        object: marker.object,
      },
    }

    return (
      <Popup offset={[0, 0]} direction="top">
        <div>
          <div className=" full_name redirection">
            {marker.object.routePointRoute.view_position} Delete redirection
            point
            <button
              disabled={isLoading}
              onClick={() => this.removeStopFromRun(object)}
              className="removeFromRun disabledBtn"
            >
              &nbsp;
            </button>
          </div>
        </div>
      </Popup>
    )
  }

  if (marker.object.type === 4 && (readOnly || isWriteRoutes)) {
    return <div />
  }
  const isProblem = !!marker.object.withProblems

  return (
    <Popup offset={[0, -14]} direction="top">
      <div
        style={
          isProblem ? { background: '#ffd8d7', margin: -10, padding: 12 } : {}
        }
      >
        <div className="full_name">
          <span> {isProblem ? 'Deleted rider' : title || ''}</span>
          {!readOnly && !isWriteRoutes && (
            <button
              disabled={isLoading}
              onClick={() => this.removeStopInRunView(marker)}
              className="removeFromRun disabledBtn"
            >
              &nbsp;
            </button>
          )}
        </div>
        {anchor}
        <div className="assigned_address">{marker.object.address.address}</div>

        {this && (
          <div className={styles.popupRoot}>
            {this.childRiders(marker, readOnly)}
          </div>
        )}
      </div>
    </Popup>
  )
}

function getLocalTime(time, tz) {
  let timeN = moment().unix() //время сейчас no UTC

  let localUTCOffset = momentTzHelper()(timeN * 1000, tz)._offset
  let getUnix = moment(time, 'hh:mm').utc(true).add(localUTCOffset, 'm').unix()

  const result = moment(getUnix * 1000)
    .utc(false)
    .format('hh:mm A')

  return result === 'Invalid date' ? ' - ' : result
}
export function assignedHover(route) {}
export function assignedOut(route) {}
export function assignedOutMouse(route) {}
export function unassignedHover(route) {}
export function unassignedOut(route) {}

export function getRouteMarkers(route) {
  let i = 0,
    c = 0
  if (route && !_.isEmpty(route) && route.points) {
    // TODO  исключение поинтов без lat lon
    return route.points
      .filter((point) => !!point?.address?.lon && !!point?.address?.lat)
      .map((point) => {
        let type = ''
        if (point.type * 1 === 2) {
          type = 'b'
        } else if (point.type * 1 === 3) {
          type = 'school'
        } else if (point.type * 1 === 4) {
          type = 'circle'
        } else if (point.type * 1 === 6) {
          type = 'dpt'
        } else if (point.type * 1 === 5) {
          type = 'transfer'
        }
        let id = 'routemarker-' + c
        if (point.address) {
          const osrmTime = route.osrm?.legs
            ?.find((l) => l.start_point?.id === point.id)
            ?.start_point?.date_time?.split(' ')[1]
            ?.slice(0, 5)

          let marker = {
            id: id,
            location: [point.address.lat, point.address.lon],
            icon: getIcon(type, i),
            object: point,
            sequence: i,
            osrmTime,
          }
          if (point.type * 1 !== 4) {
            i++
          }
          c++
          return marker
        }
      })
  } else {
    return []
  }
}

export function circle() {
  return (
    'data:image/svg+xml;base64,' +
    'PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCI+CiAgICA8ZGVmcz4KICAgICAgICA8Y2lyY2xlIGlkPSJiIiBjeD0iMzUxIiBjeT0iMjE1IiByPSI0Ii8+CiAgICAgICAgPGZpbHRlciBpZD0iYSIgd2lkdGg9IjIwMCUiIGhlaWdodD0iMjAwJSIgeD0iLTUwJSIgeT0iLTM3LjUlIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giPgogICAgICAgICAgICA8ZmVNb3JwaG9sb2d5IGluPSJTb3VyY2VBbHBoYSIgb3BlcmF0b3I9ImRpbGF0ZSIgcmFkaXVzPSIuNSIgcmVzdWx0PSJzaGFkb3dTcHJlYWRPdXRlcjEiLz4KICAgICAgICAgICAgPGZlT2Zmc2V0IGR5PSIxIiBpbj0ic2hhZG93U3ByZWFkT3V0ZXIxIiByZXN1bHQ9InNoYWRvd09mZnNldE91dGVyMSIvPgogICAgICAgICAgICA8ZmVHYXVzc2lhbkJsdXIgaW49InNoYWRvd09mZnNldE91dGVyMSIgcmVzdWx0PSJzaGFkb3dCbHVyT3V0ZXIxIiBzdGREZXZpYXRpb249IjEiLz4KICAgICAgICAgICAgPGZlQ29tcG9zaXRlIGluPSJzaGFkb3dCbHVyT3V0ZXIxIiBpbjI9IlNvdXJjZUFscGhhIiBvcGVyYXRvcj0ib3V0IiByZXN1bHQ9InNoYWRvd0JsdXJPdXRlcjEiLz4KICAgICAgICAgICAgPGZlQ29sb3JNYXRyaXggaW49InNoYWRvd0JsdXJPdXRlcjEiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4xNDgwMDA0MzcgMCIvPgogICAgICAgIDwvZmlsdGVyPgogICAgPC9kZWZzPgogICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzQ0IC0yMDkpIj4KICAgICAgICA8dXNlIGZpbGw9IiMwMDAiIGZpbHRlcj0idXJsKCNhKSIgeGxpbms6aHJlZj0iI2IiLz4KICAgICAgICA8dXNlIGZpbGw9IiNGRkYiIHN0cm9rZT0iIzg4OCIgeGxpbms6aHJlZj0iI2IiLz4KICAgIDwvZz4KPC9zdmc+Cg=='
  )
}

export function encodedIcon(
  text,
  startcolor,
  endcolor,
  textcolor,
  iconed,
  strokeColor
) {
  return (
    'data:image/svg+xml;base64,' +
    btoa(
      unescape(
        encodeURIComponent(
          ReactDOMServer.renderToString(
            mapIcon(text, startcolor, endcolor, textcolor, iconed, strokeColor)
          )
        )
      )
    )
  )
}

export function getRouteBounds(route) {
  let points = []
  if (route && !_.isEmpty(route)) {
    points = _.map(route.points, (point) => {
      if (point.address) {
        return [point.address.lat, point.address.lon]
      }
    })
  }
  return points
}

export const createAbbreviation = (str = '') => {
  const words = str.split(' ')

  let abbreviation = ''
  for (let i = 0; i < words.length; i++) {
    if (!isNaN(words[i][0])) {
      continue
    }
    abbreviation += words[i][0]
    if (abbreviation.length === 3) {
      break
    }
  }

  return abbreviation.toUpperCase()
}
