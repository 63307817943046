import { useState } from 'react'
import styles from './MainTab.module.less'
import classnames from 'classnames'
export const MainTab = ({ children, className, id, ...rest }) => {
  const [tab, setTab] = useState(1)

  const handleClick = (v: number) => {
    setTab(v)
  }
  return (
    <section className={className} id={id} {...rest}>
      <div className={styles.root}>
        <div
          className={classnames(styles.tab, { [styles.active]: tab === 1 })}
          onClick={() => handleClick(1)}
        >
          Trip
        </div>
        <div
          className={classnames(styles.tab, { [styles.active]: tab === 2 })}
          onClick={() => handleClick(2)}
        >
          Request
        </div>
        <div
          className={classnames(styles.tab, { [styles.active]: tab === 3 })}
          onClick={() => handleClick(3)}
        >
          Cost
        </div>
        <div
          className={classnames(styles.tab, { [styles.active]: tab === 4 })}
          onClick={() => handleClick(4)}
        >
          Approval
        </div>
      </div>
      {children?.(tab)}
    </section>
  )
}
