import styles from './InfoBlock.module.less'
export const InfoBlock = ({
  title,
  subtitle,
}: {
  title: string
  subtitle: string
}) => {
  return (
    <article>
      <p className={styles.title}>{title}</p>
      <p className={styles.subtitle}>{subtitle}</p>
    </article>
  )
}
