import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Marker, Popup } from 'react-leaflet'
import {
  contextMenuOnDepot,
  fnMouseOut,
  fnMouseOver,
  getIconBusTop,
} from '../../../../../../components/MapDefault/mapSpecialFns'
import * as runActions from '../../../../../../redux/routesView/actions'
import { useRulerStore } from '@/components/MapDefault/ruler/rulerStore'

class Empty extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleDepoClick = (e, depot) => {
    if (this.props.routesViewPage) return false
    if (!this.props.activeRuns[0]) {
      return false
    }

    let depots = {
      name: depot.name,
      address: depot.address.address,
      depotId: depot.id,
      waittime: 0,
      //position: "1",
      //time: moment().format("HH:mm")
    }

    this.props.dispatch(
      runActions.depotInRun({
        ...depots,
        routeId: this.props.activeRuns[0],
      })
    )
  }

  render() {
    let { depots, activeRuns } = this.props
    if (!depots) return <div />

    return depots.map((depot) => {
      if (!depot || !depot.address) return false //TODO BACKEND
      return (
        <Marker
          key={depot.id}
          zIndexOffset={500}
          onContextMenu={(e) => contextMenuOnDepot(e, this)}
          onClick={(e) => {
            if (!this.props.isRule) {
              this.handleDepoClick(e, depot)
            }
          }}
          interactive={!this.props.isRule}
          position={[depot.address.lat, depot.address.lon]}
          icon={getIconBusTop('busdepot')}
          onMouseOver={(e) => fnMouseOver(e)}
          onMouseOut={(e) => fnMouseOut(e)}
        >
          <Popup
            offset={[0, -12]}
            direction="top"
            onMouseOut={(e) => fnMouseOut(e)}
          >
            <div className="full_name"> {depot.name}</div>
          </Popup>
        </Marker>
      )
    })
  }
}

function mapStateToProps(state) {
  return {
    depots: state.auth.depots,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

const DepotPin = (props) => {
  const isRule = useRulerStore((state) => state.isRule)

  return <Empty isRule={isRule} {...props} />
}

export default connect(mapStateToProps, mapDispatchToProps)(DepotPin)
