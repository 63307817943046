import React from 'react'

export const ClearIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M.313 11.687c.209.209.47.313.757.313.287 0 .547-.104.756-.313L6 7.513l4.174 4.174c.209.209.47.313.756.313a1.07 1.07 0 0 0 .757-1.826L7.513 6l4.174-4.174A1.07 1.07 0 0 0 10.174.313L6 4.487 1.826.313A1.07 1.07 0 0 0 .313 1.826L4.487 6 .313 10.174a1.07 1.07 0 0 0 0 1.513z"
      />
    </svg>
  )
}
