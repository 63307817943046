import { Brand } from '@/types/types'
import { SessionId } from '@/pages/Sessions/components/modals/AddSubSessionModal/AddSubSessionModal'

export type RunId = Brand<number, 'run_id'>
export type PointId = Brand<number, 'point_id'>
export type RiderId = Brand<number, 'rider_id'>
/**
 * Combination of id-childAddressId makes unique id for different stops
 */
export type RiderStoreId = Brand<string, 'rider_store_id'>
export type ChildAddressId = Brand<number, 'child_address_id'>
export type CompanyId = Brand<number, 'company_id'>

export type GpsRun = {
  id: RunId
  title: string
  busName: string
  timezone: string
  driverName: string
  isLate: boolean
  status: RunStatus
  gps: {
    latitude: string
    longitude: string
  }
  startTimestamp: number
  endTimestamp: number | null
  actualStartTimestamp: number
  actualEndTimestamp: number | null
  isConnect: boolean
  deviationTime: number
  deviceUin: string
  historyMode: boolean
}

export type GpsRunWithDetails = {
  busNumber: string
  consoleHash: string
  consoleImei: string
  driverName: string
  endTime: string
  id: RunId
  points: GpsRunPoint[]
  runName: string
  startTime: string
  timeZone: string
  timeline: TimelinePoints
}

export type GpsRunPoint = {
  address: string
  math?: any
  factTime: number
  geo: {
    lat: string
    lon: string
  }
  id: PointId
  name: string
  pointOptions: {
    avoidUturns: boolean
    curbsidePickup: boolean
  }
  position: string
  riders: RunRider[]
  scheduledTime: number
  status: PointStatus
  stopType: StopType
  waitTime: number
}

export type RunRider = {
  attend: 0 | 1
  childAddressId: ChildAddressId
  fullName: string
  id: RiderId
  type: RiderType
}

export enum StopType {
  Child = 1,
  BusStop,
  School,
  Redirection,
  Transfer,
  BusDepot,
}

export const stopTypeMapper = (type: number): StopType => {
  switch (type) {
    case 1:
      return StopType.Child
    case 2:
      return StopType.BusStop
    case 3:
      return StopType.School
    case 4:
      return StopType.Redirection
    case 5:
      return StopType.Transfer
    default:
      return StopType.BusDepot
  }
}

export enum RiderType {
  PickUp = 1,
  DropOff,
  Transfer,
}

export enum RiderStatus {
  PUAbsent = 1,
  DOAbsent = 2,
  PUOnBus = 3,
  DOOnBus = 4,
  PUOffBus = 7,
  DOOffBus = 8,
}

export enum RunStatus {
  Late = 1,
  OnTime,
  Inactive,
  AboutToStart,
}

export enum PointStatus {
  Visited = 1,
  Skipped,
  Unvisited = 3,
}

/**
 * An object with utc timestamp keys in format "10:24" with speed, bearing data for Color Line Active Info component
 */
export type TimelinePoints = {
  [timeString: string]: TimelinePoint
}
export type TimelinePoint = {
  bearing: string
  speed: string
  timeString: string
}

export type SocketCheckPoint = {
  date: number
  id: number
  is_skip: boolean | null
  point_id: PointId
  route_id: RunId
  route_session_id: SessionId
}

export type SocketAttendChild = {
  attend: 0 | 1
  child_address_id: ChildAddressId
  child_id: RiderId
  company_id: CompanyId
  date: number
  isDeleted: boolean | null
  lat: number
  lon: number
  reverse: null
  route_id: RunId
}
