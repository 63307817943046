export const statusClosedOptions = [
  { label: 'Approved', value: 2 },
  { label: 'Rejected', value: 3 },
]

export const statusOpenedOptions = [
  { label: 'New', value: 1 },
  { label: 'In Review', value: 4 },
]
export const statuses = {
  1: 'New',
  2: 'Approved',
  3: 'Rejected',
  4: 'In Review',
}
