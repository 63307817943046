import { create } from 'zustand'

export type TCloneTypes = 'reverse' | 'mirror' | 'repeat' | 'base'

export interface ICloneRunStore {
  getRiderFromList: (child_id: number) => any
  currentRun: any | null
  setStore: (params: Partial<ICloneRunStore>) => void
  currentRunType: 'DO' | 'PU' | null
  cloneVariant: TCloneTypes
}

export const useCloneRunStore = create<ICloneRunStore>((set, get) => ({
  getRiderFromList: () => null,
  currentRunType: null,
  currentRun: null,
  cloneVariant: 'reverse',
  setStore: (params) => set({ ...get(), ...params }),
}))
