import { GpsRun, RunStatus } from '@/redux/gps/types'
import { Filter } from '@/pages/Gps/NewGps/index'
import L, { LatLngTuple } from 'leaflet'

export const isRunInTimeWindow = (run: GpsRun, timeWindowId: number) => {
  if (timeWindowId < 0 || timeWindowId > 5) return false
  const now = Math.floor(Date.now() / 1000)
  const offset = timeWindowId < 5 ? timeWindowId * 15 * 60 : 12 * 60 * 60
  return (
    run?.actualEndTimestamp > now - offset ||
    run?.actualStartTimestamp > now - offset
  )
}

export const checkIfToday = (timestamp: number) => {
  if (!timestamp || Number.isNaN(timestamp)) return true

  const date1 = new Date(timestamp > 1e10 ? timestamp : timestamp * 1000)
  const date2 = new Date()

  return (
    date1.getUTCFullYear() === date2.getUTCFullYear() &&
    date1.getUTCMonth() === date2.getUTCMonth() &&
    date1.getUTCDate() === date2.getUTCDate()
  )
}

export const filterRuns = (
  runs: GpsRun[],
  filter: Filter,
  isToday: boolean
) => {
  if (isToday) {
    return runs.filter(
      (r) =>
        ((filter.runId && r.id === filter.runId) ||
          (filter.driver && r.driverName === filter.driver) ||
          (!filter.driver && !filter.runId)) &&
        isRunInTimeWindow(r, filter.timeWindow)
    )
  } else if (!!filter.runId || !!filter.driver) {
    return runs.filter(
      (r) =>
        (filter.runId && r.id === filter.runId) ||
        (filter.driver && r.driverName === filter.driver)
    )
  }
  return runs
}

export const areBoundsEqual = (bounds: LatLngTuple[]) =>
  bounds.length >= 2 &&
  Array.from(new Set(bounds.map((b) => JSON.stringify(b)))).length !==
    bounds.length

export const makeLIcon = (status: RunStatus) => {
  let url: string

  switch (status) {
    case RunStatus.Late:
      url = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="43" viewBox="0 0 24 43">
            <g fill="none" fill-rule="evenodd">
                <path fill="#D0021B" stroke="#AD0419" stroke-linecap="round" stroke-linejoin="round" d="M20.219 18.187c1.875-3 2.094-4.782 2.094-7.187 0-6.075-5.188-11-11.188-11C5.05 0 0 4.925 0 11c0 2.405.188 4.187 2.063 7.187.835 1.338 9.062 10.063 9.062 23.438 0-13.375 8.258-22.1 9.094-23.438z" transform="translate(1 1)"/>
                <path fill="#FFF" fill-rule="nonzero" d="M15.833 10.865H6.548V7.607h9.285v3.258zm-1.16 3.91c-.641 0-1.161-.438-1.161-.978s.52-.977 1.16-.977c.642 0 1.161.437 1.161.977s-.52.977-1.16.977zm-6.965 0c-.64 0-1.16-.438-1.16-.978s.52-.977 1.16-.977c.641 0 1.161.437 1.161.977s-.52.977-1.16.977zM5 14.122c0 .573.302 1.088.774 1.446v1.16c0 .36.346.652.774.652h.773c.428 0 .774-.292.774-.652v-.651h6.19v.651c0 .36.347.652.775.652h.773c.428 0 .774-.292.774-.652v-1.16c.472-.358.774-.873.774-1.446V7.607c0-2.281-2.77-2.607-6.19-2.607C7.77 5 5 5.326 5 7.607v6.516z" transform="translate(1 1)"/>
            </g>
        </svg>`
      break
    case RunStatus.Inactive:
      url = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="43" viewBox="0 0 24 43">
            <g fill="none" fill-rule="evenodd">
                <path fill="#B1B1B1" stroke="#878787" stroke-linecap="round" stroke-linejoin="round" d="M20.219 18.187c1.875-3 2.094-4.782 2.094-7.187 0-6.075-5.188-11-11.188-11C5.05 0 0 4.925 0 11c0 2.405.188 4.187 2.063 7.187.835 1.338 9.062 10.063 9.062 23.438 0-13.375 8.258-22.1 9.094-23.438z" transform="translate(1 1)"/>
                <path fill="#FFF" fill-rule="nonzero" d="M15.833 10.865H6.548V7.607h9.285v3.258zm-1.16 3.91c-.641 0-1.161-.438-1.161-.978s.52-.977 1.16-.977c.642 0 1.161.437 1.161.977s-.52.977-1.16.977zm-6.965 0c-.64 0-1.16-.438-1.16-.978s.52-.977 1.16-.977c.641 0 1.161.437 1.161.977s-.52.977-1.16.977zM5 14.122c0 .573.302 1.088.774 1.446v1.16c0 .36.346.652.774.652h.773c.428 0 .774-.292.774-.652v-.651h6.19v.651c0 .36.347.652.775.652h.773c.428 0 .774-.292.774-.652v-1.16c.472-.358.774-.873.774-1.446V7.607c0-2.281-2.77-2.607-6.19-2.607C7.77 5 5 5.326 5 7.607v6.516z" transform="translate(1 1)"/>
            </g>
        </svg>`
      break
    case RunStatus.OnTime:
      url = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="43" viewBox="0 0 24 43">
            <g fill="none" fill-rule="evenodd">
                <path fill="#62AE10" stroke="#417505" stroke-linecap="round" stroke-linejoin="round" d="M20.219 18.187c1.875-3 2.094-4.782 2.094-7.187 0-6.075-5.188-11-11.188-11C5.05 0 0 4.925 0 11c0 2.405.188 4.187 2.063 7.187.835 1.338 9.062 10.063 9.062 23.438 0-13.375 8.258-22.1 9.094-23.438z" transform="translate(1 1)"/>
                <path fill="#FFF" fill-rule="nonzero" d="M15.833 10.865H6.548V7.607h9.285v3.258zm-1.16 3.91c-.641 0-1.161-.438-1.161-.978s.52-.977 1.16-.977c.642 0 1.161.437 1.161.977s-.52.977-1.16.977zm-6.965 0c-.64 0-1.16-.438-1.16-.978s.52-.977 1.16-.977c.641 0 1.161.437 1.161.977s-.52.977-1.16.977zM5 14.122c0 .573.302 1.088.774 1.446v1.16c0 .36.346.652.774.652h.773c.428 0 .774-.292.774-.652v-.651h6.19v.651c0 .36.347.652.775.652h.773c.428 0 .774-.292.774-.652v-1.16c.472-.358.774-.873.774-1.446V7.607c0-2.281-2.77-2.607-6.19-2.607C7.77 5 5 5.326 5 7.607v6.516z" transform="translate(1 1)"/>
            </g>
        </svg>`
      break
    case RunStatus.AboutToStart:
      url = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="43" viewBox="0 0 24 43">
            <g fill="none" fill-rule="evenodd">
                <path fill="#3CB0E7" stroke="#268AB9" stroke-linecap="round" stroke-linejoin="round" d="M20.219 18.187c1.875-3 2.094-4.782 2.094-7.187 0-6.075-5.188-11-11.188-11C5.05 0 0 4.925 0 11c0 2.405.188 4.187 2.063 7.187.835 1.338 9.062 10.063 9.062 23.438 0-13.375 8.258-22.1 9.094-23.438z" transform="translate(1 1)"/>
                <path fill="#FFF" fill-rule="nonzero" d="M15.833 10.865H6.548V7.607h9.285v3.258zm-1.16 3.91c-.641 0-1.161-.438-1.161-.978s.52-.977 1.16-.977c.642 0 1.161.437 1.161.977s-.52.977-1.16.977zm-6.965 0c-.64 0-1.16-.438-1.16-.978s.52-.977 1.16-.977c.641 0 1.161.437 1.161.977s-.52.977-1.16.977zM5 14.122c0 .573.302 1.088.774 1.446v1.16c0 .36.346.652.774.652h.773c.428 0 .774-.292.774-.652v-.651h6.19v.651c0 .36.347.652.775.652h.773c.428 0 .774-.292.774-.652v-1.16c.472-.358.774-.873.774-1.446V7.607c0-2.281-2.77-2.607-6.19-2.607C7.77 5 5 5.326 5 7.607v6.516z" transform="translate(1 1)"/>
            </g>
        </svg>`
      break
    default:
  }

  return L.divIcon({
    className: 'leaflet-data-marker',
    html: L.Util.template(url, {}),
    iconSize: [24, 43],
    iconAnchor: [12, 43],
    popupAnchor: [0, -51],
  })
}
