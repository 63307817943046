import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModalParent from '../../../../../../components/BaseModal'
import * as runAction from '../../../../../../redux/routesView/actions'
import Select from '../../../../../../components/customSelect/index'
import moment from 'moment'
import i18n from '../../../../../../i18n'
import { NewSingleDatePicker } from '../../../../../../entities/ui/NewSingleDatePicker/NewSingleDatePicker'
import { ClearIcon, TimeIcon } from '../../../../../../img/icons'

class SchoolModalAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      waittime: props.stop_time,
      anchor_time: '',
      formErrors: {
        school_id: false,
      },
      formEmpty: {
        school_id: true,
      },
      school_id: 0,
    }
  }

  addSchoolInRun = () => {
    if (this.state.formEmpty.school_id) {
      let errors = this.state.formErrors
      errors.school_id = true
      this.setState({
        formErrors: errors,
      })
      return
    }

    let { school_id, waittime, timeEdit, anchor_time, position } = this.state
    const points =
      this.props.activeRun &&
      this.props.activeRun.points.length &&
      this.props.activeRun.points
    let newPosition = this.props.getStopPosition(position, points)

    let school = {
      schoolId: school_id,
      position: +newPosition,
      time: anchor_time,
      isAnchor: !!anchor_time,
    }

    if (+waittime > 0 || +timeEdit > 0) {
      school.waittime = timeEdit ? +timeEdit : +waittime
    }

    this.props.addInRun('school', school)
    this.props.close()
  }

  handleChange = (type, e) => {
    if (type === 'school_id') {
      e = { target: { value: e } }
    }

    if (type === 'waittime') {
      let result = e.target.value * 60
      this.setState({
        timeEdit: result,
      })
    }

    this.setState({
      [type]: e.target.value,
    })

    if (!e.target.value || e.target.value === '') {
      let errors = this.state.formErrors
      errors[type] = true

      let empty = this.state.formEmpty
      empty[type] = true

      this.setState({
        formErrors: errors,
        formEmpty: empty,
      })
    } else {
      let errors = this.state.formErrors
      errors[type] = false

      let empty = this.state.formEmpty
      empty[type] = false

      this.setState({
        formErrors: errors,
        formEmpty: empty,
      })
    }
  }

  handleChangeAnchor = (type, value) => {
    this.setState({
      anchor_time: value,
    })
  }

  disabledDate = (current) => {
    return current && current < moment().subtract(1, 'days')
  }

  render() {
    let { close } = this.props
    let waittime = this.state.timeEdit || this.state.waittime
    waittime = !isNaN(waittime) ? waittime / 60 : ''

    return (
      <ModalParent
        title={i18n.t('Add a New School Stop')}
        closeFn={close}
        save={this.addSchoolInRun}
      >
        <div className="line flexSelectLine">
          <span className={this.state.formErrors.school_id ? 'error' : ''}>
            {i18n.t('School Name')}
          </span>
          <Select
            data-testid="schoolNameSel"
            className={this.state.formErrors.school_id ? 'error' : ''}
            onChange={(e) => this.handleChange('school_id', e)}
            value={this.state.school_id}
          >
            <option className={'hide'} value={0} key="default">
              {i18n.t('Select school')}
            </option>
            {this.props.schools.map((school) => {
              if (!school.address) return false
              return (
                <option key={school.id} value={school.id}>
                  {school.name}
                </option>
              )
            })}
          </Select>
        </div>

        <div className="line flex">
          <div className="half">
            <span>{i18n.t('Wait Time')}</span>
            <input
              data-testid="schoolWaitTimeInp"
              type="text"
              autoFocus={true}
              placeholder={i18n.t('In Minutes')}
              value={waittime}
              onChange={(e) => this.handleChange('waittime', e)}
            />
          </div>
          <div className="half">
            <span>{i18n.t('Enter Stop Number')}</span>
            <input
              data-testid="schoolStopNumInp"
              type="text"
              placeholder={i18n.t('or Stop Wll Be Placed Last on the List')}
              onChange={(e) => this.handleChange('position', e)}
            />
          </div>
        </div>

        <div className="line flex">
          <div className="half">
            <span>{i18n.t('Choose an Anchor Time if needed')}</span>
            <div style={{ position: 'relative' }}>
              <span id="runBuildModal">
                <NewSingleDatePicker
                  style={{ width: '100%' }}
                  use12Hours
                  id={'runBuildModal'}
                  className={
                    this.state.anchor_time
                      ? 'ant-calendar-picker changes'
                      : 'ant-calendar-picker'
                  }
                  format="h:mm A"
                  disabledHours={() => this.state.disabledTime}
                  defaultValue={
                    this.state.anchor_time &&
                    moment(this.state.anchor_time, 'h:mm A')
                  }
                  onChange={(e) =>
                    this.handleChangeAnchor(
                      'anchor_time',
                      e ? e.format('HH:mm') : null
                    )
                  }
                  picker="time"
                  clearIcon={<ClearIcon />}
                  suffixIcon={<TimeIcon />}
                  divider
                />
              </span>
            </div>
          </div>
        </div>

        <div className="center">
          <div className="buttons">
            <button className="cancel" onClick={close}>
              {i18n.t('Cancel')}
            </button>
            <button
              data-testid="saveSchoolBtn"
              className="save"
              onClick={this.addSchoolInRun}
            >
              {i18n.t('Save')}
            </button>
          </div>
        </div>
      </ModalParent>
    )
  }
}

function mapStateToProps(state) {
  return {
    stop_time: state.auth.stop_time,
    schools: state.schools,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { runAction, dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolModalAdd)
