import { useMouseController } from '@/pages/Gps/NewGpsDetails/hooks/useMouseController'
import { getMinutesOnTimeLine } from '@/pages/Gps/NewGpsDetails/components/ColorLine/lastMinutes'
import React, { FC } from 'react'

type ActiveInfoProps = {
  barHeight: number
  runTotalMinutes: number
}

export const ActiveInfo: FC<ActiveInfoProps> = ({
  barHeight,
  runTotalMinutes,
}) => {
  const { mousePos, top } = useMouseController()
  const style = { top: top - 240, display: mousePos ? 'initial' : 'none' } //240 height of the block to move it up
  return (
    <div style={style} className="active-line-info-block-parent">
      <div className={'active-line-info-block'}>
        <div className="block-active-child">
          {getMinutesOnTimeLine(mousePos, barHeight, runTotalMinutes)}
        </div>
      </div>
      <div className="line-active"></div>
    </div>
  )
}
