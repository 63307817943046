import React, { useEffect, useState } from 'react'
import Modal from '../../../../../../../components/BaseModal'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import { useContextBuilder } from '../../../context'
import Select from '../../../../../../../components/customSelect/index'
import {
  childInTransfer,
  childInTransferReLoad,
} from '../../../../../../../redux/routesView/actions'
import _ from 'lodash'
import api from '../../../../../../../redux/apiConfig'

const run = api.run

function AddressModal(props) {
  let { activeRun, dispatch } = useContextBuilder()
  let [selectId, setSelectId] = useState(0)
  let [stops, setStops] = useState([])
  let [errorMode, setErrorMode] = useState(false)

  let { stopId, child_address_id } = props.point

  useEffect(() => {
    let is_morning = activeRun.is_morning
    let departStop
    let transferStops = []

    departStop = activeRun.points.find((x) => x.id === stopId)

    let allTransferStops = activeRun.points.filter((x) => x.type === 5)

    if (allTransferStops.length) {
      transferStops = allTransferStops.filter((x) => {
        if (departStop.position < x.position) return x
      })
    }

    if (transferStops.length) {
      setSelectId(transferStops[0].id)
      setStops(transferStops)
    } else {
      setErrorMode(true)
    }
  }, [])

  let allRidersFromStopToTransfer = async () => {
    const results = []

    let findStop = activeRun.points.find((x) => x.id === stopId)
    let findStopT = activeRun.points.find((x) => x.id === selectId)
    if (_.isEmpty(findStop.routePointChildren)) return

    for (const x of findStop.routePointChildren) {
      let copyF = findStopT.routePointTransferChild.findIndex(
        (f) => f.child_address_id === x.child_address_id
      )
      if (copyF === -1) {
        let data = {
          route_id: activeRun.id,
          point_id: selectId,
          child_address_id: x.child_address_id,
        }

        const result = await run.post(
          `/route-points/add-transfer?expand=routePointTransferChild.childAddress.child`,
          data
        )
        results.push(result)
      }
    }
    if (!_.isEmpty(results)) dispatch(childInTransferReLoad(activeRun.id))
  }

  let oneRiderToTransfer = () => {
    let findStopT = activeRun.points.find((x) => x.id === selectId)
    let copyF = findStopT.routePointTransferChild.findIndex(
      (f) => f.child_address_id === child_address_id
    )
    if (copyF > -1) return

    let data = {
      routeId: activeRun.id,
      pointId: selectId,
      childAddressId: child_address_id,
    }
    dispatch(childInTransfer(data))
  }

  let save = () => {
    if (!selectId) return

    if (!child_address_id) {
      allRidersFromStopToTransfer()
    } else {
      oneRiderToTransfer()
    }

    props.closeFn()
  }

  return (
    <Modal id="copyRiders" closeFn={props.closeFn}>
      <div className=" Modal">
        <div className={'anchor-block'}>
          <Row>
            <Col span={24}>
              <h3 className={'title txt'}>
                <b>Add Rider(s) to a Transfer Stop</b>
              </h3>
            </Col>
          </Row>
        </div>

        {props?.ridersNamesToTransfer && (
          <p>
            Following riders that picked up on this stop{' '}
            <span style={{ fontWeight: 700 }}>
              {props?.ridersNamesToTransfer}
            </span>{' '}
            will be transfered into:
          </p>
        )}

        {errorMode && (
          <div>
            <span>You need to have a transfer stop after this stop</span>

            <div className="anchor-block-footer">
              <button className="btn btn-orange" onClick={props.closeFn}>
                <b>OK</b>
              </button>
            </div>
          </div>
        )}

        {!errorMode && (
          <React.Fragment>
            <Row className={'margin6'}>
              <Col span={24}>
                <label>Select a Stop</label>
                <Select
                  value={selectId}
                  onChange={(stopId) => setSelectId(stopId)}
                  style={{ width: '100%' }}
                >
                  <option value={0}>Select transfer stop...</option>
                  {stops.map((stop) => (
                    <option key={stop.id} value={stop.id}>
                      ({stop.routePointRoute.view_position}) -{' '}
                      {stop.busStopTitle}
                    </option>
                  ))}
                </Select>
              </Col>
            </Row>

            <div className="anchor-block-footer">
              <button className="btn btn-grey" onClick={props.closeFn}>
                <b>Cancel</b>
              </button>
              <button
                disabled={!selectId}
                className="btn btn-orange"
                onClick={save}
              >
                <b>Add</b>
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    </Modal>
  )
}

export default AddressModal
