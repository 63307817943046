export const PinIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="13"
      viewBox="0 0 9 13"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.343 6.243C7.931 5.378 8 4.865 8 4.171 8 2.42 6.372 1 4.49 1 2.584 1 1 2.42 1 4.171c0 .694.059 1.207.647 2.072C1.91 6.63 4.49 9.144 4.49 13c0-3.856 2.59-6.371 2.853-6.757z"
      />
    </svg>
  )
}
