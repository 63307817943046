import React from 'react'
import _ from 'lodash'
import { renderChildrenListBuildRun } from '../../RepeatFunctions/filter'
import L from 'leaflet'

let radiusPopup = (
  `<ul   oncontextmenu = "return false" id=\'contextMenu\'>` +
  `<li class=\'del\'>Remove Shape</li>` +
  '</ul>'
)

let figurePopup = (grPins, allPins) => {
  return `<ul   oncontextmenu = "return false" id=\'contextMenu\' class=${grPins ? 'w228' : ''} >` +
  `<li class=\'run toRunAdd\'>Add to Run ${allPins ? `(${allPins})` : ''}</li>` +
  `<li class=\'stop toStopAdd\'>Add to Stop ${allPins ? `(${allPins})` : ''}</li>` +
  `<li class=\'groupsPins toGroup\'>Make a stop - groupped pins ${grPins ? `(${grPins})` : ''}</li>` +
  `<li class=\'allPins toAllPins\'>Make a stop - all pins ${allPins ? `(${allPins})` : ''}</li>` +
  '<li class=\'del\'>Remove Shape</li>' +
  '<li class=\'fillC\'>Fill Color</li>' +
  '<li  class=\'borderShapeColor\'>Border Color</li>' +
  '<li  class=\'saveZone\'>Save as zone</li>' +
  '</ul>'
}

const getCount = (points) => {
  let allPins = 0
  let grPins = 0

  if (!Array.isArray(points)) {
    for (let key in points) {
      let length = points[key].length
      allPins += length
      if (length > 1) grPins += length
    }
  } else {
    allPins = points.length
  }
  return {
    grPins: grPins,
    allPins: allPins
  }
}

export function bindPopup (
  e, layer, scope, stopPoints = [],
  drawControl, mapRef, STOP_SELECTED,
  figureClick, radGr, pinsOnMapInGroup, openModalFn, isLoading) {
  let { grPins, allPins } = getCount(pinsOnMapInGroup)
  let popupDom = figurePopup(grPins, allPins)

  layer.bindPopup(popupDom,
    {
      className: 'contextMenu',
      closeOnEscapeKey: true,
      direction: 'bottom'
    }
  )

  layer.bringToFront()

  layer.on('mousedown touchstart', e => {
    if (e.originalEvent.button !== 2) {
      return
    }

    e.target.bringToFront()
    layer.openPopup(e && e.latlng)

    let toRunAdd = document.querySelector('#contextMenu .run')
    let toStopAdd = document.querySelector('#contextMenu .stop')
    let groupsPins = document.querySelector('#contextMenu .groupsPins')
    let allPins = document.querySelector('#contextMenu .allPins')

    let scopeNew = {
      state: {
        childrenList: scope.props.childrenList,
        filters: scope.props.filters
      }
    }

    let ridersFilter = renderChildrenListBuildRun.call(scopeNew, true, 1)
    let pointUnassigned = ridersFilterFn(ridersFilter, layer)

    if (!isLoading) {
      if (toRunAdd || toStopAdd) {
        if (stopPoints.length >= 1 && pointUnassigned.length > 0) {
          toStopAdd.classList.remove('toStopAdd')
        }

        if (pointUnassigned.length > 0) {
          toRunAdd.classList.remove('toRunAdd')
        }
      }

      if (allPins || groupsPins) {
        let keys = Object.keys(pinsOnMapInGroup)

        keys.find(x => {
          if (!isLoading) {
            allPins.classList.remove('toAllPins')
          }
          if (pinsOnMapInGroup[x].length > 1) {
            groupsPins.classList.remove('toGroup')
            return true
          }
        })
      }
    }

    let lay = e.target
    let ul = document.getElementById('contextMenu')

    ul.addEventListener('contextmenu', e => {
      return false
    })

    ul.addEventListener('click', e => {
      let target = e.target // Clicked element
      while (target && target.parentNode !== ul) {
        target = target.parentNode
        if (!target) {
          return
        }
      }
      if (target.tagName === 'LI') {
        let className = target.className

        if (className.includes('del')) {
          eventDel(drawControl, layer, radGr)
        }
        if (className.includes('bring')) {
          eventBring()
        }
        if (className.includes('back')) {
          eventBack()
        }
        if (className.includes('saveZone')) {
          layer.closePopup()
          openModalFn()
        }
        if (className.includes('borderShapeColor')) {
          eventBorderColor(lay, mapRef)
        }
        if (className.includes('fillC')) {
          eventFillC(lay, mapRef)
        }
        if (className.includes('run')) {
          if (_.isEmpty(pointUnassigned) || isLoading) return
          eventRun(lay, layer, pointUnassigned, scope)
        }
        if (className.includes('stop')) {
          if (stopPoints.length < 1 || isLoading) return
          eventStop(lay, layer, pointUnassigned, STOP_SELECTED, scope, stopPoints, mapRef)
        }
        if (className.includes('allPins')) {
          if (isLoading) return
          eventAllPins(pinsOnMapInGroup, layer, lay)
        }
        if (className.includes('groupsPins')) {
          if (isLoading) return
          eventOnlyGroupsPins(pinsOnMapInGroup, layer, lay)
        }
      }

      let colorBody = document.getElementById('btnColorBody')
      let btnColorBorder = document.getElementById('btnColorBorder')

      handleColorPicker(colorBody, layer, lay, 'fillColor')
      handleColorPicker(btnColorBorder, layer, lay, 'color')
    })

    e.originalEvent.preventDefault()
    e.originalEvent.stopPropagation()

    figureClick.current = 1
  })
}

function ridersFilterFn (ridersFilter, layer) {
  let pointUnassigned = []
  ridersFilter.forEach(unassignedChild => {
    unassignedChild.addressesView.forEach(unassignedAddress => {
      if (
        !unassignedAddress.address ||
        !unassignedAddress.address.lat ||
        unassignedAddress.viewOnMap === false ||
        unassignedAddress.is_school ||
        !unassignedAddress.childSchool ||
        (unassignedAddress.is_use * 1 !== 0)
      ) {
        return
      }

      let position = {
        lat: unassignedAddress.address.lat,
        lng: unassignedAddress.address.lon
      }

      if (layer.contains(position)) {
        pointUnassigned.push({
          addresId: unassignedAddress.id,
          shoolid: unassignedAddress.childSchool.school_id,
          childId: unassignedChild.id,
          position: position,
          addressType: unassignedAddress.type
        })
      }
    })
  })
  return pointUnassigned
}

function eventDel (drawControl, layer, radGr) {
  if (radGr) radGr.eachLayer(lay => lay.remove())
  if (layer.line) layer.line.remove()
  layer.remove()
}

function eventAllPins (pinsOnMapInGroup, layer, lay) {
  // lay.closePopup()
  // layer.remove()
  let removeShape = () => layer.remove()
  if (pinsOnMapInGroup.length === 1) {
    pinsOnMapInGroup[0].addStop(1, removeShape)
    removeShape()
  } else {
    let first = Object.keys(pinsOnMapInGroup)[0]
    if (pinsOnMapInGroup[first] && pinsOnMapInGroup[first][0]) {
      pinsOnMapInGroup[first][0].addGroupsStopsPins(pinsOnMapInGroup, removeShape)
      removeShape()
    }
  }
}

const checkIsGroupPins = arg => !Array.isArray(arg) && Object.values(arg).some(val => Array.isArray(val) && val.length > 1)
function eventOnlyGroupsPins (pinsOnMapInGroup, layer, lay) {
  if (!checkIsGroupPins(pinsOnMapInGroup)) return
  let removeShape = () => layer.remove()
  let first = Object.keys(pinsOnMapInGroup)[0]
  if (first) pinsOnMapInGroup[first][0].addGroupsPins(pinsOnMapInGroup, removeShape)
}

function eventBring () {
  // layer.bringToFront()
  // lay.closePopup()
}

function eventBack () {
  // lay.closePopup()
}

function eventBorderColor (lay, mapRef) {
  L.popup()
    .setLatLng(lay._popup._latlng)
    .setContent(
      '<div class=\'colorBlock\'> ' +
      '<p> <input id=\'btnColorBorder\' list="colorList" type=\'color\'></p>' +
      '</div>'
    )
    .openOn(mapRef.current.contextValue.map)
}

function eventFillC (lay, mapRef) {
  L.popup()
    .setLatLng(lay._popup._latlng)
    .setContent(
      '<div class=\'colorBlock\'> ' +
      '<p> <input id=\'btnColorBody\' list="colorList" type=\'color\'></p>' +
      '</div>'
    )
    .openOn(mapRef.current.contextValue.map)
}

function eventRun (lay, layer, pointUnassigned, scope) {
  let t0 = performance.now()

  let uniqPointUnassigned = _.uniqBy(pointUnassigned, `addresId`)
  let children = uniqPointUnassigned.map((item, i) => {
    return ({
      child_address_id: item.addresId,
      childId: item.childId,
      addressType: item.addressType
    })
  })

  // lay.closePopup()
  // layer.remove()
  let removeShape = () => layer.remove()
  layer.closePopup()
  // layer.remove()
  let { addRidersInRun, activeRuns } = scope.props
  addRidersInRun(`addRidersInRun`, { route_id: activeRuns[0], children: children }, removeShape)

  let t1 = performance.now()
}

function eventStop (lay, layer, pointUnassigned, STOP_SELECTED, scope, stopPoints, mapRef) {
  let { addChildrenInStop, activeRuns } = scope.props
  let uniqPointUnassigned = _.uniqBy(pointUnassigned, `addresId`)

  let children = uniqPointUnassigned.map((item, i) => {
    return ({
      child_address_id: item.addresId,
      child_id: item.childId,
      position: item.position,
      addressType: item.addressType
    })
  })

  let removeShape = () => layer.remove()

  let data = {
    children: children,
    stopPoints: stopPoints,
    route_id: activeRuns[0]
  }

  addChildrenInStop(`addChildrenInStopPoints`, data, removeShape)

  if (layer.types === 'circle') {
    // let idCircle = layer._leaflet_id
    // this.map.current.contextValue.map.eachLayer(oldLayers => {
    //   if (oldLayers instanceof L.Marker) {
    //     if (oldLayers.options.id === idCircle) {
    //       oldLayers.remove()
    //     }
    //
    //   }
    //
    //   if (oldLayers instanceof L.Polyline) {
    //     if (oldLayers.options.id === idCircle) {
    //       oldLayers.remove()
    //     }
    //
    //   }
    // })
  }
  layer.closePopup()
  // layer.remove()
}

function handleColorPicker (dom, layer, lay, type) {
  if (!dom) return
  dom.addEventListener('change', function (e) {
    let colors = e.target.value
    layer.setStyle({ [type]: colors })
    dom.value = e.target.value
    // lay.closePopup()
  })
}
