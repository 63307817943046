export const SESSIONS_ADD_ITEM = 'sessions.ADD_ITEM'
export const SESSIONS_RELOAD = 'sessions.RELOAD'

export const SESSIONS_REFRESH_IMPORT = 'sessions.REFRESH_IMPORT'
export const SESSIONS_REMOVE_ITEM = 'sessions.REMOVE_ITEM'
export const SESSIONS_EDIT_ITEM = 'sessions.EDIT_ITEM'
export const SESSIONS_EDIT_ITEMS = 'sessions.SESSIONS_EDIT_ITEMS'
export const SESSIONS_EDIT_YEAR = 'sessions.SESSIONS_EDIT_YEAR'
export const SESSIONS_GET_ALL = 'sessions.GET_ALL'
export const SESSIONS_CHANGE_ACTIVE = 'sessions.CHANGE_ACTIVE'
export const SESSIONS_CURRENT = 'sessions.CHANGE_ACTIVE-1'

export const GET_SESSIONS_CURRENT = 'sessions.GET_SESSIONS_CURRENT'

export const ACTIVE_MENU = 'sessions.ACTIVE_MENU'

export const SUBMENU_TITLE = 'sessions.SUBMENU_TITLE'

export const SESSIONS_FILE_LOG = 'sessions.FILE_LOG'
export const SESSIONS_FILE_LOG_LIST = 'sessions.FILE_LOG_LIST'
export const SESSIONS_GET_TOKEN = 'sessions.GET_TOKEN'
