import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Button from 'antd/es/button'

import Select from '../../../../../components/customSelect/index'

import { FilterItem } from '../../../../../components/RepeatFunctions/filter'
import { DropdownIcon, FilterToolIcon } from '../../../../../img/icons'
import './index.less'
import classnames from 'classnames'
import { CloseOutlined } from '@ant-design/icons'
import { NewAntInput } from '../../../../../entities/ui/NewInput/NewAntInput'

class Filter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      childrenList: props.childrenList,
      trans: [],
      grade: [],
      schools: [],
      checkBoxActive: '',
      visible: false,
      fieldTripRequests: props.fieldTripRequests,
      filters: {
        name: '',
        trans: 'All',
        schools: 'All',
        grade: 'All',
      },
      viewFilters: {
        name: '',
        trans: 'All',
        schools: 'All',
        grade: 'All',
      },
      filtersActive: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.childrenList !== nextProps.childrenList) {
      this.setState({
        childrenList: nextProps.childrenList,
      })
      this.sortOut(nextProps.childrenList)
    }

    if (this.props.fieldTripRequests !== nextProps.fieldTripRequests) {
      this.setState({
        fieldTripRequests: nextProps.fieldTripRequests,
      })
    }

    if (
      nextProps.fieldTripRequests.length > 0 &&
      nextProps.childrenList.length > 0 &&
      nextProps.activeTripId
    ) {
      let school = (nextProps.fieldTripRequests || []).find(
        (x) => x.id === nextProps.activeTripId * 1
      ).school
      if (!school) return false
      let schoolName = school && school.name && school.name

      //если по фильтру школы нет детей, то и показывать надо нефильтрованный список детей
      let result = nextProps.childrenList.filter((child) => {
        const school = (child.addresses || []).find(
          (address) =>
            address &&
            address.school &&
            address.school.name &&
            address.school.name
        )
        return (
          (school &&
            school.school &&
            school.school.name &&
            school.school.name) === schoolName
        )
      })
      if (!result.length) return false

      let filters = this.state.filters
      filters['schools'] = schoolName

      this.setState(
        {
          filters: filters,
          viewFilters: filters,
          fieldTripRequests: nextProps.fieldTripRequests,
        },
        () => {
          console.warn('FILTERS GO')
          this.applyFilters()
        }
      )
    }
  }

  checkboxActive = (type) => {
    if (
      this.state.checkBoxActive === '' ||
      this.state.checkBoxActive !== type
    ) {
      this.setState({
        checkBoxActive: type,
      })
    } else {
      this.setState({
        checkBoxActive: '',
      })
    }
  }

  sortOut = (list) => {
    //заполняю фильтры данными
    let { schools, grade, trans } = this.state

    list.forEach((child) => {
      const schoolName = (child.addresses || []).find(
        (address) =>
          address.school && address.school.name && address.school.name
      )
      schools.push(
        schoolName &&
          schoolName.school &&
          schoolName.school.name &&
          schoolName.school.name
      )
      trans.push(child.transportation_category)
      grade.push(child.classs)
    })

    schools = _.compact(_.uniq(schools))
    trans = _.compact(_.uniq(trans))
    grade = _.compact(_.uniq(grade))

    this.setState({
      schools: schools,
      grade: grade,
      trans: trans,
    })
  }

  handleChange = (e, type) => {
    let filters = { ...this.state.filters }
    filters[type] = e

    this.setState({
      viewFilters: filters,
    })
  }

  applyFilters = () => {
    let filterArr = []
    let activate = false

    Object.keys(this.state.viewFilters).forEach((filter) => {
      filterArr.push(new FilterItem(this.state.viewFilters[filter], filter))
      if (this.state.viewFilters[filter].length) {
        if (filter === 'name') {
          activate = true
        } else {
          if (this.state.viewFilters[filter] !== 'All') {
            activate = true
          }
        }
      }
    })

    if (activate) {
      this.setState({
        filtersActive: true,
        visible: false,
        filters: this.state.viewFilters,
      })
    } else {
      this.setState({
        filtersActive: false,
        visible: false,
      })
    }
    console.warn('START', 'applyFilters')
    this.setState(
      {
        filters: this.state.viewFilters,
      },
      () => {
        this.props.getChangeInputArray(filterArr)
      }
    )
  }

  removeFilter = (type) => {
    let filters = this.state.filters
    if (type === 'name') {
      filters[type] = ''
    } else {
      filters[type] = 'All'
    }

    this.setState({
      filters: filters,
      viewFilters: filters,
    })

    let showFilter = false

    Object.keys(filters).forEach((i) => {
      if (filters[i].length) {
        if (i === 'name') {
          showFilter = true
        } else {
          if (filters[i] !== 'All') {
            showFilter = true
          }
        }
      }
    })

    this.setState(
      {
        filtersActive: showFilter,
      },
      () => {
        this.applyFilters()
      }
    )
  }

  resetFilters = () => {
    let filters = { ...this.state.filters }

    filters.name = ''
    filters.schools = 'All'
    filters.trans = 'All'
    filters.grade = 'All'

    this.setState(
      {
        filters: filters,
        viewFilters: filters,
        filtersActive: false,
        visible: false,
      },
      () => {
        this.applyFilters()
      }
    )
  }

  renderFilters = () => {
    return (
      <div
        className={
          this.state.visible
            ? 'filters-block-dropdown visible'
            : 'filters-block-dropdown'
        }
      >
        <div className="filters">
          <div className="filters-line">
            <div className="filters-block-2x">
              <div className="filter-label">Name / ID</div>

              <div className="filter-input">
                <NewAntInput
                  defaultValue={this.state.filters.name}
                  value={this.state.viewFilters.name}
                  onChange={(e) => this.handleChange(e.target.value, 'name')}
                />
              </div>
            </div>

            <div className="filters-block-1x">
              <div className="filter-label">Transportation Category</div>
              <div className="filter-input">
                <Select
                  placeholder={'All'}
                  value={this.state.viewFilters.trans}
                  style={{ width: '100%' }}
                  onChange={(e) => this.handleChange(e, 'trans')}
                  suffixIcon={<DropdownIcon />}
                >
                  <option className={'hide'} key={3} value="All">
                    All
                  </option>
                  {this.state.trans.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    )
                  })}
                </Select>
              </div>
            </div>
          </div>

          <div className="filters-line">
            <div className="filters-block-2x">
              <div className="filter-label">School Name</div>

              <div className="filter-input">
                <Select
                  placeholder={'All'}
                  defaultValue={this.state.filters.schools}
                  value={this.state.viewFilters.schools}
                  style={{ width: '100%' }}
                  onChange={(e) => this.handleChange(e, 'schools')}
                  suffixIcon={<DropdownIcon />}
                >
                  <option className={'hide'} key={7} value="All">
                    All
                  </option>
                  {this.state.schools.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    )
                  })}
                </Select>
              </div>
            </div>

            <div className="filters-block-1x">
              <div className="filter-label">Grade</div>

              <div className="filter-input">
                <Select
                  placeholder={'All'}
                  defaultValue={this.state.filters.grade}
                  value={this.state.viewFilters.grade}
                  style={{ width: '100%' }}
                  onChange={(e) => this.handleChange(e, 'grade')}
                  suffixIcon={<DropdownIcon />}
                >
                  <option className={'hide'} key={8} value="All">
                    All
                  </option>
                  {this.state.grade.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    )
                  })}
                </Select>
              </div>
            </div>
          </div>

          <div className="filters-footer">
            <div className="filters-button">
              <Button
                className="btn btn-grey"
                type="danger"
                onClick={this.resetFilters}
              >
                Reset
              </Button>
            </div>

            <div className="filters-button">
              <Button
                className="btn btn-orange"
                type="primary"
                onClick={this.applyFilters}
                style={{ marginRight: '1px' }}
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  changeDropdownState = () => {
    this.setState({
      visible: !this.state.visible,
    })
  }

  render() {
    return (
      <div id="fieldTripFilter">
        <div
          className={classnames('filters-block', {
            ['active']: this.state.filtersActive,
          })}
        >
          <div className="filters-button">
            <span className="button-icon">
              <FilterToolIcon />
            </span>

            <span className="button-text" onClick={this.changeDropdownState}>
              Filters
              <span className="button-icon">
                <DropdownIcon
                  className={
                    this.state.visible ? 'expanded arrDown' : 'anticon arrRight'
                  }
                />
              </span>
            </span>
          </div>

          <div
            className={classnames('active-filters', {
              ['visible']: this.state.filtersActive,
            })}
          >
            <div
              className={
                this.state.filters.name.length
                  ? 'active-filterMainPage-item visible'
                  : 'active-filterMainPage-item hidden'
              }
              onClick={(e) => this.removeFilter('name')}
            >
              <span className="active-filter-text">Name / ID</span>

              <span className="active-filter-icon">
                <CloseOutlined style={{ color: 'gray', cursor: 'pointer' }} />
              </span>
            </div>

            <div
              className={
                this.state.filters.schools.length &&
                this.state.filters.schools !== 'All'
                  ? 'active-filterMainPage-item visible'
                  : 'active-filterMainPage-item hidden'
              }
              onClick={(e) => this.removeFilter('schools')}
            >
              <span className="active-filter-text">School Name</span>

              <span className="active-filter-icon">
                <CloseOutlined style={{ color: 'gray', cursor: 'pointer' }} />
              </span>
            </div>

            <div
              className={
                this.state.filters.grade.length &&
                this.state.filters.grade !== 'All'
                  ? 'active-filterMainPage-item visible'
                  : 'active-filterMainPage-item hidden'
              }
              onClick={(e) => this.removeFilter('grade')}
            >
              <span className="active-filter-text">Grade</span>

              <span className="active-filter-icon">
                <CloseOutlined style={{ color: 'gray', cursor: 'pointer' }} />
              </span>
            </div>

            <div
              className={
                this.state.filters.trans.length &&
                this.state.filters.trans !== 'All'
                  ? 'active-filterMainPage-item visible'
                  : 'active-filterMainPage-item hidden'
              }
              onClick={(e) => this.removeFilter('trans')}
            >
              <span className="active-filter-text">
                Transportation Category
              </span>

              <span className="active-filter-icon">
                <CloseOutlined style={{ color: 'gray', cursor: 'pointer' }} />
              </span>
            </div>

            <div className="clear-active-filters">
              <span onClick={this.resetFilters}>Clear Filter</span>
            </div>
          </div>
        </div>

        {this.renderFilters()}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    fieldTripRequests: state.fieldTripRequests.data,
    childrenList: state.children.childrenList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter)
