import { create } from 'zustand'

export interface IMenuStore {
  isMenuExpanded: boolean
  setMenuExpand: (v?: boolean) => void
}

export const useMenuStore = create<IMenuStore>((set, get) => ({
  isMenuExpanded: false,
  setMenuExpand: (isMenuExpanded) =>
    set({ isMenuExpanded: isMenuExpanded ?? !get().isMenuExpanded }),
}))
