export const TurnIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      {...props}
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M9.426 2.63L7.153.07a.204.204 0 0 0-.306 0L4.574 2.63a.205.205 0 0 0 .153.341h1.28v10.822c0 .113.092.205.205.205h1.577a.205.205 0 0 0 .204-.205V2.971h1.28a.204.204 0 0 0 .153-.341z" />
        <path d="M6.212 13.998h1.577a.205.205 0 0 0 .204-.205V4.304c-.382.219-.93.669-1.169.899-.208.202-.583.54-.817.918v7.672c0 .113.092.205.205.205z" />
        <path d="M13.93 5.381l-2.587-2.25a.204.204 0 0 0-.338.155v1.172H9.966a3.863 3.863 0 0 0-2.793 1.09 3.785 3.785 0 0 0-1.166 2.741v5.504c0 .113.091.205.204.205h1.577a.205.205 0 0 0 .205-.205V8.289c0-.505.2-.977.566-1.33a1.88 1.88 0 0 1 1.36-.531H11.004v1.357a.205.205 0 0 0 .338.155l2.587-2.25a.205.205 0 0 0 0-.309z" />
        <path d="M6.211 13.998h1.577a.205.205 0 0 0 .205-.205V8.79c-.255-.334-.638-.78-.817-.953-.209-.201-.63-.56-.986-.717-.12.373-.183.767-.183 1.17v5.504c0 .113.091.205.204.205z" />
        <path d="M6.827 7.19c-.75-.85-1.742-1.302-2.792-1.275H2.995v-1.37a.245.245 0 0 0-.12-.22.181.181 0 0 0-.218.038L.07 6.995a.26.26 0 0 0-.07.182c0 .07.026.135.07.181L2.657 9.99c.06.062.146.077.218.038a.245.245 0 0 0 .12-.219V8.22h1.087c.511-.012.994.209 1.359.622.365.413.566.966.566 1.557v3.36c0 .132.092.24.205.24h1.577c.113 0 .204-.108.204-.24v-3.36c0-1.218-.414-2.358-1.166-3.209z" />
      </g>
    </svg>
  )
}
