import * as types from './actionTypes'
import api from '../apiConfig'
import * as Sentry from '@sentry/browser'
import { browserHistory } from 'react-router'

const rq = api.user
const userCompany = api.common
const out = api.run

// TODO сделать проверку на авторузацию если она уже есть редиректить юзера.

export function companySave(data) {
  return function (dispatch) {
    // cut the companyOptions from sent data

    delete data['companyOptions']

    rq.put('companies/update', JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.COMPANY_EDIT,
            payload: response.data,
          })
        } else {
          console.warn('error ')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
export function companyUpdate(response) {
  return function (dispatch) {
    dispatch({
      type: types.EMAIL_TEMPLATE,
      payload: response,
    })
  }
}
export function signOut() {
  return function (dispatch) {
    out
      .post('users/logout')
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.UNAUTHENTICATED,
            payload: response.data,
          })

          Sentry.configureScope((scope) => scope.setUser(null))
        } else {
          console.warn('error')
        }
      })
      .catch((error) => {
        console.log(error)
      })
    localStorage.clear()
    browserHistory.push('/client/signin')
  }
}

export function paymentMethod() {
  return function (dispatch) {
    userCompany
      .get('companies/payment-method')
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.PAYMENT,
            payload: response.data,
          })
        } else {
          console.warn('error')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
export function paymentMethodSave(data) {
  return function (dispatch) {
    userCompany
      .post('companies/set-payment-card', data)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.PAYMENT_SAVE,
            payload: response.data,
          })
        } else {
          console.warn('error')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
