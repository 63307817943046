import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModalParent from '../../../../../../components/BaseModal'
import Select from '../../../../../../components/customSelect/index'
import moment from 'moment'
import i18n from '../../../../../../i18n'
import { ClearIcon, TimeIcon } from '../../../../../../img/icons'
import { NewSingleDatePicker } from '../../../../../../entities/ui/NewSingleDatePicker/NewSingleDatePicker'

class ModalDepotAdd extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formErrors: {
        busStopTitle: false,
        address: false,
      },
      formEmpty: {
        busStopTitle: true,
        address: true,
      },
      depot_id: 0,
      depots: props.depots,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.depots !== this.props.depots) {
      this.setState({
        depots: this.props.depots,
      })
    }
  }

  addStopInRun = () => {
    let errors = this.state.formErrors

    if (this.state.formEmpty.address) {
      errors.address = true

      this.setState({
        formErrors: errors,
      })
    }

    if (this.state.formEmpty.busStopTitle) {
      errors.busStopTitle = true

      this.setState({
        formErrors: errors,
      })
    }

    if (this.state.formErrors.busStopTitle || this.state.formErrors.address)
      return

    let { depot_id, routeWaittime, position, anchor_time } = this.state

    const points =
      this.props.activeRun &&
      this.props.activeRun.points.length &&
      this.props.activeRun.points
    let newPosition = this.props.getStopPosition(position, points)

    let depot = {
      depotId: depot_id,
      waittime: routeWaittime ? routeWaittime * 60 : null,
      position: +newPosition,
      time: anchor_time,
      isAnchor: !!anchor_time,
    }

    this.props.addInRun('depot', depot)
    this.props.close()
  }

  handleChange = (type, e) => {
    e.preventDefault()

    this.setState({
      [type]: e.target.value,
    })

    let errors = this.state.formErrors
    let empty = this.state.formEmpty

    if (!e.target.value || e.target.value === '') {
      errors[type] = true
      empty[type] = true
    } else {
      errors[type] = false
      empty[type] = false
    }

    this.setState({
      formErrors: errors,
      formEmpty: empty,
    })
  }

  selectDepot = (id) => {
    let errors = this.state.formErrors
    let empty = this.state.formEmpty

    errors.address = false
    empty.address = false
    errors.busStopTitle = false
    empty.busStopTitle = false

    this.setState({
      depot_id: id,
      formErrors: errors,
      formEmpty: empty,
    })
  }

  handleChangeAnchor = (type, value) => {
    this.setState({
      anchor_time: value,
    })
  }

  disabledDate = (current) => {
    return current && current < moment().subtract(1, 'days')
  }

  render() {
    let { routeWaittime, position, depots } = this.state
    const { close } = this.props

    return (
      <ModalParent
        title={i18n.t('Select Bus Depot')}
        closeFn={close}
        save={this.addStopInRun}
      >
        <div
          className={
            this.state.formErrors.address ? 'line-header error' : 'line-header'
          }
        >
          {i18n.t('Depot')}
        </div>
        <div className="line">
          <Select
            data-testid="depotNameSel"
            value={this.state.depot_id}
            placeholder={'Select Depot'}
            className={this.state.formErrors.busStopTitle ? 'error' : ''}
            onChange={(e) => this.selectDepot(e)}
            style={{ width: '100%' }}
          >
            <option className="hide" key={this.state.depot_id} value={0}>
              Select Depot
            </option>
            {depots.map((depot) => {
              if (!depot.name) return false
              return (
                <option key={depot.id} value={depot.id}>
                  {depot.name}
                </option>
              )
            })}
          </Select>
        </div>
        <div className="line flex">
          <div className="half">
            <span>{i18n.t('Wait Time')}</span>
            <input
              data-testid="depotWaitTimeInp"
              value={routeWaittime}
              onChange={(e) => this.handleChange('routeWaittime', e)}
              type="text"
              placeholder={i18n.t('In Minutes')}
            />
          </div>
          <div className="half">
            <span>{i18n.t('Enter Stop Number')}</span>
            <input
              data-testid="depotStopNumInp"
              value={position}
              onChange={(e) => this.handleChange('position', e)}
              type="text"
              placeholder={i18n.t('or Stop Will Be Placed Last on the List')}
            />
          </div>
        </div>

        <div className="line flex">
          <div className="half">
            <span>{i18n.t('Choose an Anchor Time if needed')}</span>
            <div style={{ position: 'relative' }}>
              <span id="runBuildModal">
                <NewSingleDatePicker
                  style={{ width: '100%' }}
                  use12Hours
                  id={'runBuildModal'}
                  className={
                    this.state.anchor_time
                      ? 'ant-calendar-picker changes'
                      : 'ant-calendar-picker'
                  }
                  format="h:mm A"
                  disabledHours={() => this.state.disabledTime}
                  defaultValue={
                    this.state.anchor_time &&
                    moment(this.state.anchor_time, 'h:mm A')
                  }
                  onChange={(e) =>
                    this.handleChangeAnchor(
                      'anchor_time',
                      e ? e.format('HH:mm') : null
                    )
                  }
                  picker="time"
                  divider
                  clearIcon={<ClearIcon />}
                  suffixIcon={<TimeIcon />}
                />
              </span>
            </div>
          </div>
        </div>

        <div className="center">
          <div className="buttons">
            <button className="cancel" onClick={() => close('modalStop')}>
              {i18n.t('Cancel')}
            </button>
            <button
              data-testid="depotSaveBtn"
              onClick={this.addStopInRun}
              className="save"
            >
              {i18n.t('Save')}
            </button>
          </div>
        </div>
      </ModalParent>
    )
  }
}

function mapStateToProps(state) {
  return {
    depots: state.depots,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDepotAdd)
