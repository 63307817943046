/** @enum {number} */

/** @enum {number} */
export const FTRStatus = {
  ALL: 0,
  STATUS_NEW: 1,
  STATUS_IN_PROGRESS: 2,
  STATUS_COMPLEATE: 3,
  STATUS_REJECTED: 4,
}

/** @enum {number} */
export const FTRErrorType = {
  FieldTripSubmit: 0,
}
