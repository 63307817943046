import React, { ReactNode, useMemo } from 'react'
import { SessionIcon } from '@/assets/icons/SessionIcon'
import { routeLinks } from '@/shared/routeLinks'
import { CurrentSessionMenuItem } from '@/features/containers/components/Menu/components/CurrentSessionMenuItem/CurrentSessionMenuItem'
import { ViewRunsIcon } from '@/assets/icons/ViewRunsIcon'
import { RunBuilderIcon } from '@/assets/icons/RunBuilderIcon'
import { AttendanceIcon } from '@/assets/icons/AttendanceIcon'
import { WarningIcon } from '@/assets/icons/WarningIcon'
import { BusDepotsIcons } from '@/assets/icons/BusDepotsIcons'
import { BusesIcon } from '@/assets/icons/BusesIcon'
import { SchoolIcon } from '@/assets/icons/SchoolIcon'
import { ConsolesIcon } from '@/assets/icons/ConsolesIcon'
import { OverviewIcon } from '@/assets/icons/OverviewIcon'
import { SettingsIcon } from '@/assets/icons/SettingsIcon'
import { BillingIcon } from '@/assets/icons/BillingIcon'
import { browserHistory } from 'react-router'
import { useAppSelector } from '@/redux'
import { useGetCompanyCountersQuery } from '@/store/common/common'
import { selectFtRequestsData } from '@/store/fieldTrip/FieldTripApi.selectors'
import { useRouter } from '@/shared/hooks/routeHooks/useRouter'
import { RiderIcon } from '@/assets/icons/RiderIcon'

interface IMenuOption {
  Icon?: any
  text?: ReactNode
  haveBackground?: boolean
  to?: string
  Component?: () => JSX.Element
  count?: number
  show?: boolean
  active?: boolean
}

export const useMenuList = () => {
  const pathName = useRouter()?.pathName

  const ftRequestsCount = useAppSelector(selectFtRequestsData)
  const { data } = useGetCompanyCountersQuery()
  const registrationRequestCount =
    data?.data?.newParentPortalRegistrationRequests
  const requestsCount = data?.data?.newPortalRequests
  const permissions = useAppSelector((state) => state?.auth?.permissions)
  const companyOptions = useAppSelector((state) => state.auth.companyOptions)

  const routeToolOptions: IMenuOption[] = useMemo(
    () => [
      {
        Icon: SessionIcon,
        text: 'Sessions',
        haveBackground: false,
        to: routeLinks.sessions,
        show: true,
        active:
          (browserHistory as any).getCurrentLocation().pathname ===
          '/client/RouteTool',
      },
      {
        Component: CurrentSessionMenuItem,
        show: true,
      },
      {
        Icon: RiderIcon,
        haveBackground: true,
        text: 'Riders',
        to: routeLinks.riders,
        show: !!permissions?.read_children,
      },
      {
        Icon: ViewRunsIcon,
        text: 'View Runs',
        haveBackground: true,
        to: routeLinks.routeView,
        active: pathName?.includes('RouteTool/routes-view'),
        show: !!permissions?.read_routes,
      },
      {
        Icon: RunBuilderIcon,
        text: 'Run Builder',
        haveBackground: true,
        active: pathName?.includes('RouteTool/routes/'),
        to: routeLinks.runBuilder,
        show: !!permissions?.read_routes,
      },
      {
        Icon: AttendanceIcon,
        text: 'Attendance',
        haveBackground: true,
        active: pathName?.includes('RouteTool/attendance'),
        to: routeLinks.attendance,
        show: !!permissions?.read_attendance,
      },
      {
        Icon: WarningIcon,
        text: 'Requests',
        haveBackground: true,
        to: routeLinks.requests,
        count: requestsCount,
        show:
          !!companyOptions?.is_parent_portal &&
          !!permissions?.read_parent_requests,
      },
      {
        Icon: WarningIcon,
        text: 'Registrations',
        haveBackground: true,
        to: routeLinks.registrations,
        count: registrationRequestCount,
        show:
          !!companyOptions?.is_parent_portal &&
          !!permissions?.read_parent_requests,
      },
    ],
    [
      companyOptions?.is_parent_portal,
      pathName,
      permissions?.read_attendance,
      permissions?.read_children,
      permissions?.read_parent_requests,
      permissions?.read_routes,
      registrationRequestCount,
      requestsCount,
    ]
  )

  const fieldTripsOptions: IMenuOption[] = useMemo(
    () => [
      {
        Component: CurrentSessionMenuItem,
        show: true,
      },
      {
        Icon: WarningIcon,
        active: pathName === '/client/FieldTrips',
        text: 'Requests',
        haveBackground: true,
        count: ftRequestsCount,
        to: routeLinks.ftRequests,
        show: true,
      },
      {
        Icon: ViewRunsIcon,
        text: (
          <>
            View Field <br /> Trips
          </>
        ),
        active:
          pathName?.includes('FieldTrips/run') ||
          pathName?.includes('FieldTrips/routes-view'),
        haveBackground: true,
        to: routeLinks.ftRoutesView,
        show: !!permissions?.read_field_trip || !!permissions?.write_field_trip,
      },
      {
        Icon: AttendanceIcon,
        text: 'Attendance',
        haveBackground: true,
        active: pathName?.includes('FieldTrips/attendance'),
        to: routeLinks.ftAttendance,
        show: true,
      },
    ],
    [
      pathName,
      ftRequestsCount,
      permissions?.read_field_trip,
      permissions?.write_field_trip,
    ]
  )

  const resurcesOptions: IMenuOption[] = useMemo(
    () => [
      {
        Icon: BusDepotsIcons,
        text: 'Bus Depot',
        haveBackground: true,
        to: routeLinks.resBusDepots,
        show: true,
      },
      {
        Icon: BusesIcon,
        text: 'Buses',
        haveBackground: true,
        to: routeLinks.resBuses,
        show: true,
      },
      {
        Icon: RiderIcon,
        text: 'Drivers',
        haveBackground: true,
        show: true,

        to: routeLinks.resDrivers,
      },
      {
        Icon: SchoolIcon,
        text: 'Schools / Sites',
        haveBackground: true,
        show: !!permissions?.read_schools,
        to: routeLinks.resSs,
      },
      {
        Icon: ConsolesIcon,
        text: 'Consoles',
        haveBackground: true,
        to: routeLinks.resConsole,
        show: true,
      },
    ],
    [permissions?.read_schools]
  )

  const lcOptions: IMenuOption[] = useMemo(
    () => [
      {
        Icon: RiderIcon,
        text: 'Profile',
        haveBackground: true,
        to: routeLinks.lcProfile,
        show: true,
        active:
          (browserHistory as any).getCurrentLocation().pathname ===
          '/client/lc',
      },
      {
        Icon: OverviewIcon,
        text: 'Overview',
        haveBackground: true,
        to: routeLinks.lcOverview,
        show: true,
      },
      {
        Icon: SettingsIcon,
        text: 'Settings',
        haveBackground: true,
        to: routeLinks.lcSettings,
        show: true,
      },
      {
        Icon: BillingIcon,
        text: 'Billing',
        haveBackground: true,
        show: !!permissions?.read_schools,
        to: routeLinks.lcBilling,
      },
    ],
    [permissions?.read_schools]
  )

  const options = useMemo(() => {
    if (pathName?.includes('RouteTool')) {
      return routeToolOptions
    } else if (pathName?.includes('FieldTrips')) {
      return fieldTripsOptions
    } else if (pathName?.includes('Resources')) {
      return resurcesOptions
    } else if (pathName?.includes('lc')) {
      return lcOptions
    } else {
      return []
    }
  }, [
    fieldTripsOptions,
    lcOptions,
    pathName,
    resurcesOptions,
    routeToolOptions,
  ])

  return useMemo(() => options.filter((el) => !!el.show), [options])
}
