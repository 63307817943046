import { Address, Rider, BaseEntity } from '@/pages/Riders/models'

// @ts-check
export class CreatePointRequest {
  time = 0
  waittime = 0
  address = ''
  type = 0
  position = 1
  title = ''
  run_id = 0
  is_anchor = 0

  constructor(
    time = 0,
    waittime = 0,
    address = '',
    type = 0,
    position = 1,
    title = '',
    run_id = 0
  ) {
    this.time = time
    this.waittime = waittime
    this.address = address
    this.type = type
    this.position = position
    this.title = title
    this.run_id = run_id

    if (time) {
      this.is_anchor = 1
    } else {
      this.is_anchor = 0
    }
  }
}

export class FieldTripRider extends BaseEntity {
  /** @type {number} */
  child_id

  /** @type {number} */
  point_id

  /** @type {Rider} */
  child
}

export class FieldTripRiderAdult extends BaseEntity {
  /** @type {number} */
  point_id

  /** @type {string} */
  name

  /** @type {string} */
  notes

  /** @type {string} */
  phone
}

/**
 * @enum {number}
 */
export const StopPointType = {
  Depot: 1,
  Stop: 2,
  School: 3,
}

export class StopPoint extends BaseEntity {
  /** @type {number} */
  run_id

  /** @type {StopPointType} */
  type

  /** @type {number} */
  position = 1

  /** @type {Address} */
  address

  /** @type {number} */
  address_id = null

  /** @type {number} */
  address_link_id

  /** @type {number} */
  waittime = 0

  /** @type {number} */
  time = 0

  /** @type {boolean} */
  is_anchor

  /** @type {string} */
  title

  /** @type {number} */
  curbside_pickup_only = 0

  /** @type {number} */
  use_default_rule = 0

  /** @type {FieldTripRider[]} */
  fieldTripRiders

  /** @type {FieldTripRiderAdult[]} */
  fieldTripRiderAdults = []
}

export class Bus extends BaseEntity {
  /** @type {number} */
  bus_depot_id

  /** @type {string} */
  bus_number

  /** @type {number} */
  capacity

  /** @type {number} */
  company_id

  /** @type {boolean} */
  is_active

  /** @type {string} */
  type

  /** @type {string} */
  vin_number
}

export class Driver extends BaseEntity {
  /** @type {string} */
  birthday

  /** @type {number} */
  bus_depot_id

  /** @type {number} */
  calendar_id

  /** @type {number} */
  company_id

  /** @type {string} */
  driver_license

  /** @type {string} */
  email

  /** @type {boolean} */
  is_active

  /** @type {string} */
  name

  /** @type {string} */
  password_hash

  /** @type {string} */
  password_reset_token

  /** @type {string} */
  phone

  /** @type {string} */
  username

  /** @type {number} */
  vehicle_capacity_permit_level

  /** @type {number} */
  vehicle_type_permit_level
}

export class FieldTripRun extends BaseEntity {
  /** @type {number} */
  bus_id

  /** @type {number} */
  driver_id

  /** @type {number} */
  trip_id

  /** @type {number} */
  curbside_pickup

  /** @type {number} Timestamp */
  date_start

  /** @type {number} Timestamp */
  date_end

  /** @type {string} */
  description

  /** @type {string} */
  name

  /** @type {boolean} */
  no_uturns

  /** @type {string} */
  status_reason

  /** @type {boolean} */
  use_default_rules

  /** @type {Bus} */
  bus

  /** @type {Driver} */
  driver

  /** @type {StopPoint[]} */
  fieldTripPoints
}

export class User extends BaseEntity {
  /** @type {any} */
  cardBrand

  /** @type {any} */
  cardLastFour

  /** @type {boolean} */
  company_admin

  /** @type {number} */
  company_id

  /** @type {number} Timestamp */
  created_at

  /** @type {string} */
  email

  /** @type {string} */
  first_name

  /** @type {string} */
  last_name

  /** @type {string} */
  home

  /** @type {boolean} */
  is_verified

  /** @type {number} */
  lead_status

  /** @type {string} */
  nameS3

  /** @type {string} */
  pathS3

  /** @type {number} */
  read_eula

  /** @type {number} */
  status

  /** @type {string} */
  token

  /** @type {any} */
  trialEndAt

  /** @type {number} Timestamp */
  updated_at

  /** @type {number} */
  user_type

  /** @type {string} */
  username
}

export class UserRelation extends BaseEntity {
  /** @type {number} */
  district_id

  /** @type {number} */
  school_id

  /** @type {number} */
  user_id

  /** @type {number} */
  level

  /** @type {User} */
  user
}

export class FieldTripApproval extends BaseEntity {
  /** @type {number} */
  trip_id

  /** @type {number} */
  user_relation_id

  /** @type {number} Timestamp */
  date

  /** @type {string} */
  description

  /** @type {boolean} */
  is_approved

  /** @type {UserRelation} */
  userRelation
}

export class BusDepot extends BaseEntity {
  /** @type {number} */
  address_id

  /** @type {number} */
  address_link_id

  /** @type {number} */
  company_id

  /** @type {number} Timestamp */
  created_at

  /** @type {number} Timestamp */
  updated_at

  /** @type {string} */
  name

  /** @type {Address} */
  address
}

export class FieldTrip extends BaseEntity {
  /** @type {number} */
  company_id

  /** @type {string} */
  name

  /** @type {string} */
  cost

  /** @type {string} */
  description

  /** @type {number} Timestamp */
  date_start

  /** @type {number} Timestamp */
  date_end

  /** @type {boolean} */
  lock

  /** @type {number} */
  status

  /** @type {string} */
  status_reason

  /** @type {FieldTripRun[]} */
  fieldTripRuns

  /** @type {FieldTripApproval[]} */
  fieldTripApprovals
}

export class FieldTripWithRequests extends FieldTrip {
  /** @type {FieldTripRequest[]} */
  fieldTripRequests
}

export class FieldTripRequestDestination extends BaseEntity {
  /** @type {string} */
  address

  /** @type {number} */
  date

  /** @type {number} */
  request_id

  /** @type {number} */
  time_type

  /** @type {number} */
  type
}

export class FieldTripRequest extends BaseEntity {
  /** @type {boolean} */
  active

  /** @type {number} */
  company_id

  /** @type {number} */
  count_adults

  /** @type {number} */
  count_kids

  /** @type {number} Timestamp */
  created_at

  /** @type {number} Timestamp */
  updated_at

  /** @type {number} Timestamp */
  dateTrip

  /** @type {number} Timestamp */
  date_round_trip

  /** @type {string} */
  description

  /** @type {FieldTripRequestDestination[]} */
  fieldTripRequestDestinations

  /** @type {number} */
  field_trip_id

  /** @type {boolean} */
  is_round_trip

  /** @type {string} */
  name

  /** @type {number} */
  requestor_email

  /** @type {string} */
  requestor_name

  /** @type {string} */
  requestor_phone

  /** @type {School} */
  school

  /** @type {number} */
  school_id

  /** @type {boolean} */
  special_needs

  /** @type {string} */
  special_note_request

  /** @type {number} */
  status

  /** @type {string} */
  status_reason

  /** @type {number} */
  type_round_trip
}

export class FieldTripRequestWithTrip extends FieldTripRequest {
  /** @type {FieldTrip} */
  fieldTrip
}
