import { ModalProps } from 'antd'
import { create } from 'zustand'
import { MonthlyAttendanceModal } from '@/entities/modals/MonthlyAttendanceModal/MonthlyAttendanceModal'
import { DeleteDriverModal } from '@/entities/modals/DeleteDriverModal/DeleteDriverModal'
import { AddDriverModal } from '@/entities/modals/AddDriverModal/AddDriverModal'
import { AddBusDepot } from '@/entities/modals/AddBusDepot/AddBusDepot'
import { AddBusModal } from '@/entities/modals/AddBusModal/AddBusModal'
import { EditBusDepotModal } from '@/entities/modals/EditBusDepot/EditBusDepot'
import { DeleteBusDepotModal } from '@/entities/modals/DeleteBusDepotModal/DeleteBusDepotModal'
import { ParentEmailModal } from '@/entities/modals/ParentEmailModal/ParentEmailModal'
import { RidersImportModal } from '@/entities/modals/RidersImportModal/RidersImportModal'
import { RequestsOpenedModal } from '@/entities/modals/Requests/RequestsOpenedModal/RequestsOpenedModal'
import { RequestsClosedModal } from '@/entities/modals/Requests/RequestsClosedModal/RequestsClosedModal'
import { DeleteBusModal } from '@/entities/modals/DeleteBusModal/DeleteBusModal'
import { RTRegistrationsOpenedModal } from '@/entities/modals/RouteToolRegistrations/RTRegistrationsOpenedModal/RTRegistrationsOpenedModal'
import { DeleteFTRequestModal } from '@/entities/modals/DeleteFTRequestModal/DeleteFTRequestModal'
import { EditFieldTripModal } from '@/entities/modals/EditFieldTripModal/EditFieldTripModal'
import { ResendToApproverModal } from '@/entities/modals/ResendToApproverModal/ResendToApproverModal'
import { CreateUserModal } from '@/entities/modals/CreateUserModal/CreateUserModal'
import { AddSubSessionModal } from '@/pages/Sessions/components/modals/AddSubSessionModal/AddSubSessionModal'
import { DeleteSessionsModal } from '@/entities/modals/DeleteSessionsModal/DeleteSessionsModal'
import { SessionTokenModal } from '@/entities/modals/SessionTokenModal/SessionTokenModal'
import { ForceEditRiderAddress } from '@/entities/modals/ForceEditRiderAddress/ForceEditRiderAddress'
import { CreateAndEditTransCatModal } from '@/entities/modals/CreateAndEditTransCatModal/CreateAndEditTransCatModal'
import { AddAndEditRole } from '@/entities/modals/AddAndEditRole/AddAndEditRole'
import { BatchDeleteRidersModal } from '@/entities/modals/BatchDeleteRidersModal/BatchDeleteRidersModal'
import { TurnByTurnModal } from '@/entities/modals/TurnByTurnModal/TurnByTurnModal'
import { ForceEditRidingRider } from '@/entities/modals/ForceEditRidingRider/ForceEditRidingRider'
import { EditBusWarningModal } from '@/entities/modals/EditBusWarningModal/EditBusWarningModal'
import { CloneRunModal } from '@/entities/modals/CloneRunModal/CloneRunModal'
import { useModalStore } from '@/store/modal/ModalStore'
import { AddNewSessionModal } from '@/pages/Sessions/components/modals/AddNewSessionModal/AddNewSessionModal'
import { AddNewSession } from '@/entities/modals/addNewSession/AddNewSession'
import { AddNewRunModal } from '@/entities/modals/AddNewRunModal/AddNewRunModal'

// import { CreateUserModal } from '@/entities/modals/CreateUserModal/CreateUserModal'
// import { AddRiderToStopModal } from '@/entities/modals/AddRiderToStopModal/AddRiderToStopModal'
// import { EditAnchorModal } from '@/entities/modals/EditAnchorModal/EditAnchorModal'
// import { EditBusDepotStop } from '@/entities/modals/EditBusDepotStop/EditBusDepotStop'
// import { DeleteStopFromRunModal } from '@/entities/modals/DeleteStopFromRunModal/DeleteStopFromRunModal'
// import { DeleteRiderFromStopModal } from '@/entities/modals/DeleteRiderFromStopModal/DeleteRiderFromStopModal'
// import { AddRiderToTransferStop } from '@/entities/modals/AddRiderToTransferStop/AddRiderToTransferStop'
// import { AddSchoolModal } from '@/entities/modals/AddSchoolModal/AddSchoolModal'
// import { AddEditBusStop } from '@/entities/modals/AddEditBusStop/AddEditBusStop'
// import { AddBusDepotToStop } from '@/entities/modals/AddBusDepotToStop/AddBusDepotToStop'
// import { OptimazeRunModal } from '@/entities/modals/OptimazeRunModal/OptimazeRunModal'
// import { AddRiderInRunWarningModal } from '@/entities/modals/AddRiderInRunWarningModal/AddRiderInRunWarningModal'

export interface IModalFlowStore {
  currentModal: keyof ModalsConfig | null
  modalSettings: Omit<ModalProps, 'onCancel' | 'modalRender' | 'style'> & {
    'data-testid'?: string
    disableClose?: boolean
  }
  modalProps:
    | (ModalsConfig[keyof ModalsConfig] extends React.ComponentType<infer P>
        ? P
        : never)
    | { modal: { title?: string } }
  onClose: () => void
  open: (params: Partial<IModalFlowStore>) => void
  setDisabledModal: (v: boolean) => void
}

export const useModalFlowStore = create<IModalFlowStore>((set, get) => ({
  currentModal: null,
  modalProps: null,
  modalSettings: null,
  setDisabledModal: (disableClose) => {
    set({ modalSettings: { ...(get().modalSettings ?? {}), disableClose } })
  },
  onClose: () =>
    set({ currentModal: null, modalProps: null, modalSettings: null }),
  open: (params) => {
    set({ ...get(), ...params })
  },
}))

export const openModal = (params: Partial<IModalFlowStore>) => {
  useModalFlowStore.getState().open(params)
}

export const closeModal = () => {
  useModalFlowStore.getState().onClose()
  useModalStore.getState().onCloseModal()
}

export const onDisabledToggleModal = (v: boolean) => {
  useModalFlowStore.getState().setDisabledModal(v)
}

export const modalConfig = {
  monthlyAttendanceModal: MonthlyAttendanceModal,
  forceEditRiderAddress: ForceEditRiderAddress,
  forceEditRidingRider: ForceEditRidingRider,
  addSubSession: AddSubSessionModal,
  deleteSessionsModal: DeleteSessionsModal,
  sessionTokenModal: SessionTokenModal,
  batchDeleteRidersModal: BatchDeleteRidersModal,
  turnByTurnModal: TurnByTurnModal,
  editBusWarningModal: EditBusWarningModal,
  cloneRunModal: CloneRunModal,
  // addEditBusStop: AddEditBusStop,
  // addRiderInRunWarningModal: AddRiderInRunWarningModal,
  // optimazeRunModal: OptimazeRunModal,
  // addBusDepotToStop: AddBusDepotToStop,
  // addRiderToTransferStop: AddRiderToTransferStop,
  // addSchoolModal: AddSchoolModal,
  // deleteStopFromRunModal: DeleteStopFromRunModal,
  // deleteRiderFromStopModal: DeleteRiderFromStopModal,
  deleteDriverModal: DeleteDriverModal,
  // editBusDepotStop: EditBusDepotStop,
  // editAnchorModal: EditAnchorModal,
  // addRiderToStopModal: AddRiderToStopModal,
  addDriverModal: AddDriverModal,
  addBusDepot: AddBusDepot,
  addBusModal: AddBusModal,
  editBusDepotModal: EditBusDepotModal,
  deleteBusDepotModal: DeleteBusDepotModal,
  parentEmailModal: ParentEmailModal,
  ridersImportModal: RidersImportModal,
  requestsOpenedModal: RequestsOpenedModal,
  requestsClosedModal: RequestsClosedModal,
  deleteBusModal: DeleteBusModal,
  rtRegistrationsOpenedModal: RTRegistrationsOpenedModal,
  deleteFTRequestModal: DeleteFTRequestModal,
  editFieldTripModal: EditFieldTripModal,
  resendToApproverModal: ResendToApproverModal,
  createUserModal: CreateUserModal,
  createAndEditTransCat: CreateAndEditTransCatModal,
  addAndEditRole: AddAndEditRole,
  addNewBaseSession: AddNewSessionModal,
  addNewSession: AddNewSession,
  addNewRunModal: AddNewRunModal,
}

export type ModalsConfig = typeof modalConfig
