import { createSelector } from 'reselect'

export const getAuthRequest = (state) => state.auth

export const getCompanyOptionsRequests = createSelector(
  getAuthRequest,
  (state) => state.companyOptions
)

export const getCompanyPermissions = createSelector(
  getAuthRequest,
  (state) => state.permissions
)

export const companySelector = createSelector(getAuthRequest, (state) => {
  return state?.company
})
