import React, { Component } from "react";
import { connect } from "react-redux";
import BaseModal from "../../../../../../components/BaseModal";
import { Spin } from "antd"
import "./index.less";
import moment from 'moment'
import api from "../../../../../../redux/apiConfig";
import {NewRangePicker} from "../../../../../../entities/ui/NewRangePicker/NewRangePicker";

let rq = api.routesPdf;
const dateFormat = 'MM/DD/YYYY';

let startDate = moment().unix() ;
let endDate = moment().unix() ;

class Modal extends Component {

  constructor(props) {
    super(props);
    this.state= {
      spinning: false
    }
  }


  print=()=>{
    this.setState({ spinning: true });

    rq.get(`/route-prints/schedule?id=${this.props.id}&start=${startDate}&end=${endDate}`)
      .then((response)=>{
        if (response.status === 200) {

          const file = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);

          this.setState({ spinning: false });
          this.props.closeFn();
        } else {
          this.props.closeFn();
        }

      })
      .catch((error)=>{
        this.setState({ spinning: false });
      })

  }

  saveDate=(date,v)=>{
    startDate = date[0].unix()
    endDate = date[1].unix()

  }


  render() {
    let spinning = this.state.spinning;
    let closeFn = this.props.closeFn;
    return (
      <div>
        <BaseModal
          title={"Run's Schedule"}
          closeFn={closeFn}
        >
          <Spin spinning={spinning} tip="Loading...">
            <div id="modalEmail" className="content">
              <div className="modal-body">

                <NewRangePicker
                  defaultValue={[moment(), moment()]}
                  format={dateFormat}
                  onChange={(e,v)=>this.saveDate(e,v)}
                />

              </div>
            </div>

            <div className="center">
              <div className="buttons">
                <button
                  className="cancel"
                  onClick={closeFn}
                >
                  Cancel
                </button>
                <button

                  onClick={this.print}
                  className={`save`}
                >
                  Print
                </button>
              </div>
            </div>
          </Spin>
        </BaseModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
