import { TCloneTypes } from '@/entities/modals/CloneRunModal/CloneRunModal'
import { useCloneRunStore } from '@/entities/modals/CloneRunModal/store'

export const getPoints = (type: TCloneTypes, errosIds) => {
  const getRiderFromList = useCloneRunStore.getState().getRiderFromList
  const currentRun = useCloneRunStore.getState().currentRun
  const points = currentRun?.points

  switch (type) {
    case 'base':
      return points ?? []
    case 'reverse': {
      const result = []
      const reversePoints = removeTransferAndRedirectionStop([
        ...(points ?? []),
      ])?.reverse()

      reversePoints.forEach((point) => {
        if (point.type === 1) {
          const riderInRun = getRiderFromList(point?.object?.id)
          !errosIds?.[riderInRun.id] && result.push(point)
        } else if (point.type === 2) {
          const _point = {
            ...point,
            routePointChildren: (point?.routePointChildren ?? []).filter(
              (rider) => {
                const riderFromList = getRiderFromList(rider?.child_id)
                return !errosIds?.[riderFromList?.id]
              }
            ),
          }
          result.push(_point)
        } else {
          result.push(point)
        }
      })
      return result
    }
    case 'mirror': {
      const result = []
      const reversePoints = removeTransferAndRedirectionStop([
        ...(points ?? []),
      ])

      reversePoints.forEach((point) => {
        if (point.type === 1) {
          const riderInRun = getRiderFromList(point?.object?.id)
          !errosIds?.[riderInRun.id] && result.push(point)
        } else if (point.type === 2) {
          const _point = {
            ...point,
            routePointChildren: (point?.routePointChildren ?? []).filter(
              (rider) => {
                const riderFromList = getRiderFromList(rider?.child_id)
                return !errosIds?.[riderFromList?.id]
              }
            ),
          }
          result.push(_point)
        } else {
          result.push(point)
        }
      })

      const totalStops = result.length
      const mirroredResult = new Array(totalStops).fill(null)

      // 3. Расставляем школы и депо в зеркальные позиции
      result.forEach((point, index) => {
        if (point.type === 3 || point.type === 6) {
          const mirroredIndex = totalStops - 1 - index
          mirroredResult[mirroredIndex] = { ...point }
        }
      })

      // 4. Заполняем остальные типы остановок
      result.forEach((point) => {
        if (point.type !== 3 && point.type !== 6) {
          const firstNullIndex = mirroredResult.indexOf(null)
          mirroredResult[firstNullIndex] = { ...point }
        }
      })

      // 5. Пересчитываем позиции остановок в mirroredResult
      mirroredResult.forEach((point, index) => {
        point.position = index + 1
      })

      console.log(mirroredResult)
      return mirroredResult
    }
    case 'repeat': {
      const result = []
      const repeatPoints = removeTransferAndRedirectionStop([...(points ?? [])])

      repeatPoints.forEach((point) => {
        if (point.type === 1) {
          const riderInRun = getRiderFromList(point?.object?.id)

          const childAddressType = riderInRun?.address?.childaddress?.type
          const isPossibilityDouble = getPossibilityDouble(
            riderInRun?.addresses,
            childAddressType
          )

          if (!errosIds?.[riderInRun.id] && isPossibilityDouble) {
            result.push(point)
          }
        } else if (point.type === 2) {
          const _point = {
            ...point,
            routePointChildren: (point?.routePointChildren ?? []).filter(
              (rider) => {
                const riderFromList = getRiderFromList(rider?.child_id)
                const childAddressType =
                  riderFromList?.address?.childaddress?.type
                const isPossibilityDouble = getPossibilityDouble(
                  riderFromList?.addresses,
                  childAddressType
                )

                return !errosIds?.[riderFromList?.id] && isPossibilityDouble
              }
            ),
          }
          result.push(_point)
        } else {
          result.push(point)
        }
      })
      return result
    }
    default:
      return points ?? []
  }
}

const removeTransferAndRedirectionStop = (points: any[]) => {
  return points?.filter((item) => ![4, 5].includes(item.type))
}

// export const isCopyableSchool = (runType, points, schoolPoint) => {
//   if (runType === 'PU') {
//     return true
//   }
//
//   const ridersIdsInTransfers =
//     runType == 'DO'
//       ? points
//           .filter((point) => point.type === 5)
//           ?.flatMap((stop) => stop?.routePointChildren)
//           ?.map((item) => item.child_id)
//       : []
//
//   const ridersInSchool = schoolPoint?.routePointChildren?.map(
//     (rider) => rider?.child_id
//   )
//   const isAllRidersInTransfer = ridersInSchool.every((element) =>
//     ridersIdsInTransfers.includes(element)
//   )
//
//   return !isAllRidersInTransfer
// }

export const getPossibilityDouble = (addresses = [], childAddressType) => {
  return (
    addresses?.filter(
      (address) => address?.type === childAddressType && address.is_use === 0
    )?.length >= 2
  )
}
