import * as types from './actionTypes'
import api from '../apiConfig'
import { store } from '@/redux'
import { getActiveSeason } from '@/redux/children/selectors'
import { sessionApi, sessionsSelector } from '@/store/session/sessionApi'

const rq = api.session
const runV2 = api.runV2

export function getApiToken(seasonId) {
  return function (dispatch) {
    runV2
      .get(`season/token/${seasonId}`)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: types.SESSIONS_GET_TOKEN,
            payload: response.data,
          })
        } else {
          console.warn('error')
        }
      })
      .catch((error) => {
        console.warn(error)
      })
  }
}

export function refreshImport_socket(data) {
  return function (dispatch) {
    dispatch({
      type: types.SESSIONS_REFRESH_IMPORT,
      payload: data,
    })
  }
}

export function addItem_socket(response) {
  return function (dispatch) {
    dispatch(
      sessionApi.util.updateQueryData('getAllSeasons', undefined, (data) => {
        data.data = [...data.data, response]
      })
    )
  }
}

// export function copySession(id) {
//   return function (dispatch) {
//     rq.get(`seasons/copy?id=${id}&isDeleteStop=0`).then(function (response) {
//       if (response.status === 200) {
//         dispatch({
//           type: types.SESSIONS_ADD_ITEM,
//           payload: response.data,
//         })
//       } else {
//         console.warn('error')
//       }
//     })
//   }
// }

export function exportSession(id) {
  return function (dispatch) {
    runV2.post(`company/export/all`).then(function (response) {
      if (response.status === 200) {
        console.log('success')
      } else {
        console.warn('error')
      }
    })
  }
}

export function addItemCampMode({
  copy_id,
  start_date,
  end_date,
  weeks,
  title,
  sub,
}) {
  return function (dispatch, state) {
    rq.get(
      `seasons/copy?id=${copy_id}&start_date=${start_date}&end_date=${end_date}&weeks=${weeks}&title=${title}&sub=${sub}`
    ).then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: types.SESSIONS_RELOAD,
          payload: response.data,
        })
      } else {
        console.warn('error')
      }
    })
  }
}

export function editItems_socket(response) {
  return function (dispatch) {
    dispatch({
      type: types.SESSIONS_EDIT_ITEMS,
      payload: response,
    })
  }
}

export function editItem(ids, item) {
  return function (dispatch) {
    ids.map((id) => {
      rq.put(
        'seasons/' + id,
        JSON.stringify(item === 'closeRecord' ? { status: 0 } : item)
      ).then(function (response) {
        if (response.status === 200) {
          console.warn(response)
          dispatch({
            type: types.SESSIONS_EDIT_ITEM,
            payload: response.data,
          })
        } else {
          console.warn('error ' + id)
        }
      })
      return null
    })
  }
}

export function removeItem(ids) {
  return function (dispatch) {
    const state = store.getState()
    const activeSeasonId = getActiveSeason(state)?.id
    if (ids?.includes(activeSeasonId)) {
      const seasons = sessionsSelector(state)
      const firstEnableSeason = seasons?.find(
        (season) => !ids.includes(season.id)
      )?.id
      if (firstEnableSeason) {
        dispatch(currentSession(firstEnableSeason))
        localStorage.setItem('ActiveSessionId', String(firstEnableSeason))
      }
    }

    ids.map((id) => {
      rq.delete(`seasons/${id}`)
        .then(function (response) {
          if (response.status === 204) {
            dispatch({
              type: types.SESSIONS_REMOVE_ITEM,
              payload: id,
            })
          } else {
            console.warn('status <>204 ' + id)
          }
        })
        .catch(() => {
          console.warn('catch', id)
        })
      return null
    })
  }
}
export function removeItem_socket(id) {
  return function (dispatch) {
    dispatch({
      type: types.SESSIONS_REMOVE_ITEM,
      payload: id,
    })
  }
}

export function currentSession(id) {
  return {
    type: types.SESSIONS_CURRENT,
    payload: id,
  }
}

export function ChangeActive(id) {
  return {
    type: types.SESSIONS_CHANGE_ACTIVE,
    payload: id,
  }
}

export function ActiveMenu(id) {
  return {
    type: types.ACTIVE_MENU,
    payload: id,
  }
}

export function GetTitleSubMenu(title) {
  return {
    type: types.SUBMENU_TITLE,
    payload: title,
  }
}

export function fileLog(fileId) {
  return function (dispatch) {
    rq(
      'file-logs/' + fileId + '?expand=logs,countNew,countUpdate,logs.child'
    ).then(function (response) {
      if (response.status === 200) {
        dispatch({
          type: types.SESSIONS_FILE_LOG,
          payload: response.data,
        })
      } else {
        console.warn('error')
      }
    })
  }
}
