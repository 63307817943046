export const SpeedMeterIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      viewBox="0 0 18 12"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M4.937 4.17a.209.209 0 0 0-.051-.15L3.23 2.138a.209.209 0 0 0-.295-.02 8.606 8.606 0 0 0-1.877 2.343.209.209 0 0 0 .083.283l2.2 1.202a.208.208 0 0 0 .284-.083 5.69 5.69 0 0 1 1.24-1.549.208.208 0 0 0 .071-.143zM5.624 3.45a.21.21 0 0 0 .284.086 5.691 5.691 0 0 1 1.891-.602.208.208 0 0 0 .18-.233L7.64.237a.21.21 0 0 0-.236-.178 8.64 8.64 0 0 0-2.873.914.206.206 0 0 0-.087.28l1.18 2.197zM2.948 6.801L.55 6.151a.207.207 0 0 0-.254.145A8.55 8.55 0 0 0 .01 8.932a.207.207 0 0 0 .216.197l2.48-.115a.207.207 0 0 0 .198-.216 5.66 5.66 0 0 1 .19-1.743.207.207 0 0 0-.146-.254zM12.285.806A8.414 8.414 0 0 0 9.422 0a.208.208 0 0 0-.226.187l-.238 2.48a.207.207 0 0 0 .186.226 5.542 5.542 0 0 1 1.885.53.205.205 0 0 0 .276-.096l1.076-2.245a.208.208 0 0 0-.096-.277zM15.955 4.303a.208.208 0 0 0-.023-.158 8.583 8.583 0 0 0-1.958-2.261.209.209 0 0 0-.293.03l-1.577 1.938a.208.208 0 0 0 .03.293c.518.42.954.924 1.296 1.495a.208.208 0 0 0 .285.072l2.145-1.281a.208.208 0 0 0 .095-.128zM16.722 5.946a.206.206 0 0 0-.26-.137l-2.365.75a.21.21 0 0 0-.135.263 5.766 5.766 0 0 1 .25 1.972.21.21 0 0 0 .053.151.205.205 0 0 0 .143.068l2.477.116h.01c.109 0 .2-.087.206-.2a8.76 8.76 0 0 0-.38-2.983zM8.562 8.104a1.99 1.99 0 0 0-.106.003L5.088 6.116a.213.213 0 0 0-.297.285l1.837 3.45a1.937 1.937 0 0 0 .56 1.579c.367.367.855.57 1.374.57a1.93 1.93 0 0 0 1.376-.57c.367-.369.57-.858.57-1.378 0-.52-.203-1.01-.57-1.378a1.93 1.93 0 0 0-1.376-.57z" />
      </g>
    </svg>
  )
}
