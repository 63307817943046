import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { Popover } from 'antd'
import { useContextBuilder } from '../../context'
import { useSelector } from 'react-redux'
import { getDepots } from '@/redux/depot/selectors'
import { getLoaderState } from '@/redux/common/selectors'
import { OptimizeRun } from '@/pages/Run/components/BuildRun/components/RunButtons/OptimizeRun/OptimizeRun'
import { RoutingRules } from '@/pages/Run/components/BuildRun/components/RunButtons/RoutingRules/RoutingRules'

export default function ({ activeRunsIds }) {
  let { setOpenModal, activeRun, transferMode, auth } = useContextBuilder()

  const depots = useSelector(getDepots)

  const loaderState = useSelector(getLoaderState)
  const [isLoading, setLoading] = useState(false)
  const isLoadingStart = loaderState === 'loadingStart'

  const isWriteRoutes = auth?.permissions?.write_routes !== 1

  useEffect(() => {
    setLoading(isLoadingStart)
  }, [loaderState, isLoading])

  let content = null
  let active = true

  if (activeRunsIds[0] === 0 || activeRunsIds[1] === 0) {
    content = (
      <div className="popover-padding">
        <Trans>Need choose two runs</Trans>
      </div>
    )
    active = false
  } else if (activeRunsIds[0] === activeRunsIds[1]) {
    content = (
      <div className="popover-padding">
        <Trans>Need choose two different runs</Trans>
      </div>
    )
    active = false
  }

  let pointCount = activeRun.points.filter((x) => x.type !== 4).length

  let contentText = ''
  if (pointCount < 4) {
    contentText = (
      <p style={{ margin: -8 }}>
        <Trans>4 or more route stops required for optimization</Trans>
      </p>
    )
  }

  return (
    <div>
      <button
        data-testid="schoolBtn"
        disabled={isLoading || isWriteRoutes}
        className={isLoading ? 'schoolBtn disabledAtPending' : 'schoolBtn'}
        onClick={() => setOpenModal('modalSchool')}
      >
        <Trans>+ Add School</Trans>
      </button>

      {!transferMode && (
        <button
          data-testid="stopBtn"
          disabled={isLoading || isWriteRoutes}
          className={isLoading ? 'stopBtn disabledAtPending' : 'stopBtn'}
          onClick={() => setOpenModal('modalStop')}
        >
          <Trans>+ Add Stop</Trans>
        </button>
      )}

      {transferMode && (
        <Popover
          placement="bottomLeft"
          content={content}
          id={'popoverUnassigned'}
          trigger="hover"
        >
          <button
            data-testid="transferBtn"
            disabled={isLoading || isWriteRoutes}
            className={
              isLoading ? 'transferBtn disabledAtPending' : 'transferBtn'
            }
            onClick={active ? () => setOpenModal('modalTransfer') : ''}
          >
            <Trans>+ Add Transfer</Trans>
          </button>
        </Popover>
      )}

      <button
        data-testid="depotBtn"
        disabled={depots.length === 0 || isLoading || isWriteRoutes}
        className={
          !depots.length >= 1 || isLoading || isWriteRoutes
            ? 'depotBtn disabledAtPending'
            : 'depotBtn '
        }
        onClick={() => setOpenModal('modalDepot')}
      >
        <Trans>+ Add Depot</Trans>
      </button>
      <OptimizeRun
        isLoading={isLoading}
        contentText={contentText}
        runId={activeRunsIds?.[0]}
        isDisabled={contentText || isLoading || isWriteRoutes}
      />
      <RoutingRules disable={isLoading || isWriteRoutes} />
    </div>
  )
}
