export const MessageIcon = (props) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 7.934C18 4.75 18 3.158 17.242 2.014C16.914 1.51987 16.492 1.09513 16 0.764C14.865 -5.96046e-08 13.285 0 10.125 0H7.875C4.715 0 3.135 -5.96046e-08 2 0.764C1.509 1.094 1.087 1.52 0.758003 2.014C3.21865e-06 3.158 0 4.75 0 7.934C0 11.117 3.21865e-06 12.709 0.758003 13.853C1.087 14.348 1.508 14.773 2 15.103C2.991 15.771 4.323 15.855 6.75 15.866V15.867L7.994 18.373C8.08642 18.561 8.22965 18.7193 8.40746 18.83C8.58526 18.9407 8.79054 18.9994 9 18.9994C9.20946 18.9994 9.41474 18.9407 9.59254 18.83C9.77035 18.7193 9.91358 18.561 10.006 18.373L11.25 15.867V15.866C13.677 15.856 15.009 15.771 16 15.103C16.492 14.7718 16.914 14.3471 17.242 13.853C18 12.709 18 11.117 18 7.933V7.934ZM5.625 4.534C5.32565 4.53532 5.03906 4.65538 4.82814 4.8678C4.61722 5.08022 4.4992 5.36765 4.5 5.667C4.4992 5.96635 4.61722 6.25379 4.82814 6.46621C5.03906 6.67863 5.32565 6.79868 5.625 6.8H12.375C12.6743 6.79868 12.9609 6.67863 13.1719 6.46621C13.3828 6.25379 13.5008 5.96635 13.5 5.667C13.5008 5.36765 13.3828 5.08022 13.1719 4.8678C12.9609 4.65538 12.6743 4.53532 12.375 4.534H5.625ZM5.625 9.067C5.32565 9.06832 5.03906 9.18837 4.82814 9.40079C4.61722 9.61322 4.4992 9.90065 4.5 10.2C4.49894 10.4995 4.61683 10.7872 4.82778 10.9999C5.03873 11.2125 5.32548 11.3327 5.625 11.334H9C9.29952 11.3327 9.58627 11.2125 9.79722 10.9999C10.0082 10.7872 10.1261 10.4995 10.125 10.2C10.1258 9.90065 10.0078 9.61322 9.79686 9.40079C9.58594 9.18837 9.29935 9.06832 9 9.067H5.625Z"
        fill="currentColor"
      />
    </svg>
  )
}
