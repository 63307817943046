import PolylineTextGradient from '@/components/MapDefault/src/polyline-text/PolylineTextGpsColor'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { getUrlWithSearchParams } from '@/helpers/getUrlWithSearchParams'
import api from '@/redux/apiConfig'
import { checkIfToday } from '@/pages/Gps/NewGps/helpers'
import { geometryToColorPolylineMapper } from '@/pages/Gps/NewGpsDetails/helpers/geometryMapper'
import * as Sentry from '@sentry/react'
import { useGpsDetailsStore } from '@/pages/Gps/NewGpsDetails/store'

type RunGeometryProps = {
  timestamp: number
  canAnimate: boolean
}

export const RunGeometry: FC<RunGeometryProps> = ({
  timestamp,
  canAnimate,
}) => {
  const run = useGpsDetailsStore.getState().gpsInfo
  const [realGeometry, setRealGeometry] = useState([])

  const interval = useRef(null)

  const isToday = checkIfToday(timestamp)

  const getActiveGeometry = useCallback(() => {
    if (run.id) {
      const params = isToday
        ? { route_id: run.id }
        : { timestamp, route_id: run.id }

      api.common
        .get(getUrlWithSearchParams(`/gps-routes/geometries`, params))
        .then((response) => {
          const geometryData = canAnimate
            ? response.data.slice(0, -1)
            : response.data
          setRealGeometry(
            geometryToColorPolylineMapper(geometryData, run.points)
          )
        })
        .catch(Sentry.captureException)
    }
  }, [isToday, run, timestamp, canAnimate])

  useEffect(() => {
    if (isToday && !run.endTime) {
      interval.current = setInterval(() => getActiveGeometry(), 5000)
    } else {
      getActiveGeometry()
    }
    return () => {
      clearInterval(interval.current)
    }
  }, [isToday, getActiveGeometry, run.endTime])

  if (!realGeometry.length) {
    return null
  }

  return (
    <>
      {realGeometry.map((x) => (
        <PolylineTextGradient
          key={x.geometry}
          geometry={x.geometry}
          late={x.late}
          nextLate={x.nextLate}
        />
      ))}
    </>
  )
}
