export const AcceptPickUpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="27"
      height="27"
      viewBox="0 0 27 27"
    >
      <defs>
        <filter
          id="prefix__a"
          width="163.2%"
          height="163.2%"
          x="-31.6%"
          y="-31.6%"
          filterUnits="objectBoundingBox"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.280400815 0"
          />
        </filter>
        <circle id="prefix__b" cx="9.5" cy="9.5" r="9.5" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(4 4)">
        <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
        <use fill="#F6BF4F" xlinkHref="#prefix__b" />
        <path
          fill="#737373"
          fillRule="nonzero"
          d="M6.094 9.182h.87c.128 0 .251-.01.37-.029.119-.019.223-.056.314-.11.09-.055.162-.132.217-.232.054-.1.082-.23.082-.392 0-.161-.028-.292-.082-.392-.055-.1-.127-.176-.218-.231-.09-.055-.194-.091-.313-.11-.119-.02-.242-.029-.37-.029h-.87v1.525zM4.976 6.788H7.27c.318 0 .589.046.812.139.223.092.405.215.545.367s.242.325.307.52c.064.195.096.396.096.605 0 .205-.032.405-.096.602-.065.198-.167.372-.307.524-.14.152-.322.274-.545.367-.223.093-.494.139-.812.139H6.094v1.824H4.976V6.788zm9.07 3.163c0 .689-.193 1.2-.577 1.536-.385.335-.917.502-1.596.502-.69 0-1.222-.166-1.6-.499-.378-.332-.566-.845-.566-1.539V6.788h1.118V9.95c0 .138.012.273.036.406.024.133.073.25.15.353.076.102.181.185.317.25.135.064.317.096.545.096.399 0 .674-.09.826-.268.152-.178.228-.457.228-.837V6.788h1.119V9.95z"
        />
      </g>
    </svg>
  )
}
