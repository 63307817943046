import { IBusesStore } from '@/pages/Resources/buses/store'
import { IBus } from '@/store/resources/resources.types'

export const capacityFilter = (
  record: IBus,
  filters: IBusesStore['filters']
) => {
  const capacity = record?.capacity
  const _capacityFrom = parseFloat(filters.capacityFrom ?? '')
  const capacityFrom = !isNaN(_capacityFrom) ? _capacityFrom : null

  const _capacityTo = parseFloat(filters.capacityTo ?? '')
  const capacityTo = !isNaN(_capacityTo) ? _capacityTo : null

  if (filters.capacityFrom && filters.capacityTo) {
    return capacity >= capacityFrom && capacity <= capacityTo
  } else if (filters.capacityFrom) {
    return capacity >= capacityFrom
  } else if (filters.capacityTo) {
    return capacity <= capacityTo
  }

  return true
}

export const capacitySort = (a: IBus, b: IBus) => {
  const capacityA = a.capacity ? parseFloat(String(a.capacity)) : -Infinity
  const capacityB = b.capacity ? parseFloat(String(b.capacity)) : -Infinity
  return capacityA - capacityB
}

export const busDepotSorter = (a: IBus, b: IBus) => {
  if (a.depotName && b.depotName) {
    return a.depotName.localeCompare(b.depotName)
  }
  return a.depotName ? -1 : 1
}
