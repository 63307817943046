import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getChildrenList } from '../../../../../../redux/children/selectors'
import _ from 'lodash'
import moment from 'moment'
import './index.less'
import * as routesActions from '../../../../../../redux/routesView/actions'
import { afterCloneAddress } from '../../../../../../redux/children/actions'
import i18n from '../../../../../../i18n'
import Row from 'antd/es/row/index'
import Col from 'antd/es/col/index'
import { Input } from 'antd/lib/index'
import BaseModal from '../../../../../../components/BaseModal'
import { momentTzHelper } from '../../../../../../helpers/momentTz'
import {
  CalendarIcon,
  ClearIcon,
  ModalCloneIcon,
} from '../../../../../../img/icons'
import { NewSingleDatePicker } from '../../../../../../entities/ui/NewSingleDatePicker/NewSingleDatePicker'

/**
 * @deprecated This component is deprecated.
 */
const ModalClone = ({ run, closeFn }) => {
  const dispatch = useDispatch()
  const childrenList = useSelector(getChildrenList)
  const [reverseType, setReverseType] = useState(1)
  const [runCopyName, setRunCopyName] = useState('')
  const [errorName, setErrorName] = useState(false)
  const [errors, setErrors] = useState([])
  const [deleteFromMap, setDeleteFromMap] = useState([])
  const [anchorTime, setAnchorTime] = useState(0)
  const [points, setPoints] = useState([])

  const title = run && run.title

  useEffect(() => {
    const runPoints = [...run.points]
    setPoints(secondAddresses(runPoints))
  }, [run, childrenList])
  const secondAddresses = (points) => {
    if (childrenList.length < 1) return
    points = points.map((x) => {
      if (x.type !== 2 && x.type !== 1) return x

      if (x.type === 1) {
        let childId = x.object.id
        let r = childrenList.find((x) => x.id === childId)

        let pointTypeAddress = x.routePointChildren[0].childaddress.type
        x.addresses = r?.addresses
        x.address2 = r.addresses.find((a) => {
          if (a.type !== pointTypeAddress && !a.is_use && !a.is_school) return a
        })

        r.addresses.filter((a) => {
          if (a.type !== pointTypeAddress && !a.is_use && !a.is_school) {
            deleteFromMap.push(a)
          }
        })

        let unic = _.uniq(deleteFromMap)
        setDeleteFromMap(unic)

        if (x.routePointChildren[0].childaddress.type === 1) {
          x.typeP = 'PU'
          if (x.address2) {
            x.address2.typeP = 'DO'
            x.error = false
          } else {
            x.error = true
            errors.push({
              name: x.object.full_name,
              stop: x.routePointRoute.view_position,
              id: x.id,
            })
          }
        } else {
          x.typeP = 'DO'
          if (x.address2) x.address2.typeP = 'PU'
        }

        if (x.routePointChildren[0].childaddress.type === 2) {
          x.typeP = 'DO'
          if (x.address2) {
            x.address2.typeP = 'PU'
            x.error = false
          } else {
            x.error = true
            errors.push({
              name: x.object.full_name,
              stop: x.routePointRoute.view_position,
              id: x.id,
            })
          }
        } else {
          x.typeP = 'PU'
          if (x.address2) x.address2.typeP = 'DO'
        }
        return x
      }
      if (x.type === 2) {
        x.routePointChildren.forEach((rChild) => {
          let rider = childrenList.find((child) => child.id === rChild.child_id)

          let type = rChild.childaddress.type
          let r = rider.addresses.findIndex(
            (ad) => ad.type !== type && !ad.is_use && !ad.is_school
          )
          rChild.full_name = rider.full_name
          rChild.ttype = rChild.childaddress.type === 1 ? 'PU' : 'DO'

          if (r > -1) {
            rChild.address2 = true
            rChild.error = false
          } else {
            rChild.address2 = false
            rChild.error = true

            let obj = {
              name: rider.full_name,
              stop: x.routePointRoute.view_position,
            }
            errors.push(obj)
          }
        })
        return x
      }
    })
    setErrors(errors)
    return _.compact(points)
  }

  const pointSolverBase = () => {
    if (!points) return false
    let position = 0

    return points.map((x) => {
      return (
        <div className="point">
          <div className={`block type${x.type}`} />

          <div className="position ">{position}.</div>
          <span style={{ display: 'none' }}>{++position}</span>
          <div className="pointName">
            <div className="d">
              <div className={`name ${x.error ? 'error' : ''}`}>
                {' '}
                {x.busStopTitle ||
                  (x.object && x.object.name) ||
                  (x.object && x.object.full_name)}
                <span className="type"> {x.typeP}</span>
              </div>

              {x.type === 2 && x.object.length > 0 && (
                <div className="tableChild">
                  {x.routePointChildren.map((y, ii) => {
                    return (
                      <div className="pointChild">
                        <span>{ii + 1}.</span>{' '}
                        <span
                          className={` "name" ${
                            x.routePointChildren[ii].error ? 'error' : ''
                          } `}
                        >
                          {y.full_name}
                        </span>{' '}
                        <span className="type">{y.ttype}</span>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      )
    })
  }

  const typeREVERSE = () => {
    if (!points) return false
    let pointsCopy = [...points]
    const reversedPoints = _.reverse(pointsCopy)

    let position = 0
    return reversedPoints.map((x) => {
      if (x.type === 1 && x.error) return false
      return (
        <div className="point">
          <div className={`block type${x.type}`} />

          <div className="position ">{position}.</div>
          <span style={{ display: 'none' }}>{++position}</span>
          <div className="pointName">
            <div className="d">
              <div className={`name ${x.error ? 'error' : ''}`}>
                {' '}
                {x.busStopTitle ||
                  (x.object && x.object.name) ||
                  (x.object && x.object.full_name)}
                <span className="type"> {x.address2 && x.address2.typeP}</span>
              </div>

              {x.type === 2 && x.object.length > 0 && (
                <div className="tableChild">
                  {x.routePointChildren.map((y, ii) => {
                    return (
                      <div className="pointChild">
                        <span>{ii + 1}.</span>{' '}
                        <span
                          className={` "name" ${
                            x.routePointChildren[ii].error ? 'error' : ''
                          } `}
                        >
                          {y.full_name}
                        </span>{' '}
                        <span className="type">
                          {y.ttype === 'PU' ? 'DO' : 'PU'}
                        </span>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      )
    })
  }

  const typeMIRROR = () => {
    if (!points) return false

    let p2 = []
    if (points.length > 1) {
      let length = points.length - 1
      points.forEach((x, i) => {
        if (i === 0) {
          p2.push(points[length])
        } else if (i === length) {
          p2.push(points[0])
        } else {
          p2.push(x)
        }
      })
    }
    let position = 0
    return p2.map((x) => {
      if (x.type === 1 && x.error) return false
      return (
        <div className="point">
          <div className={`block type${x.type}`} />
          <div className="position ">{position}.</div>
          <span style={{ display: 'none' }}>{++position}</span>
          <div className="pointName">
            <div className="d">
              <div className={`name ${x.error ? 'error' : ''}`}>
                {' '}
                {x.busStopTitle ||
                  (x.object && x.object.name) ||
                  (x.object && x.object.full_name)}
                <span className="type"> {x.address2 && x.address2.typeP}</span>
              </div>

              {x.type === 2 && x.object.length > 0 && (
                <div className="tableChild">
                  {x.routePointChildren.map((y, ii) => {
                    return (
                      <div className="pointChild">
                        <span>{ii + 1}.</span>{' '}
                        <span
                          className={` "name" ${
                            x.routePointChildren[ii].error ? 'error' : ''
                          } `}
                        >
                          {y.full_name}
                        </span>{' '}
                        <span className="type">
                          {y.ttype === 'PU' ? 'DO' : 'PU'}
                        </span>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      )
    })
  }

  const typeREPEAT = () => {
    if (!points) return false

    let position = 0
    return points.map((x) => {
      if (x.type === 1) return
      return (
        <div key={x?.id} className="point">
          <div className={`block type${x.type}`} />

          <div className="position ">{position}.</div>
          <span style={{ display: 'none' }}>{++position}</span>
          <div className="pointName">
            <div className="d">
              <div className={`name ${x.error ? 'error' : ''}`}>
                {' '}
                {x.busStopTitle ||
                  (x.object && x.object.name) ||
                  (x.object && x.object.full_name)}
                <span className="type"> {x.typeP}</span>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  const pointSolverByType = () => {
    if (reverseType === 0) {
      return typeREPEAT()
    }
    if (reverseType === 1) {
      return typeREVERSE()
    }
    if (reverseType === 2) {
      return typeMIRROR()
    }
  }

  const clone = () => {
    if (!runCopyName) {
      setErrorName(true)
      setTimeout(() => {
        setErrorName(false)
      }, 3000)
      return false
    }

    let time = moment().unix()
    let currentAnchorTime = anchorTime
    if (currentAnchorTime) {
      currentAnchorTime = anchorTime.utc(true).unix()
    } else {
      currentAnchorTime = 0
    }

    let localUTCOffset = momentTzHelper()(time * 1000, run.time_zone)._offset
    if (localUTCOffset < 0) {
      localUTCOffset = Math.abs(localUTCOffset)
      currentAnchorTime = currentAnchorTime + localUTCOffset * 60
      currentAnchorTime = moment(currentAnchorTime * 1000)
        .utc(false)
        .format('HH:mm')
    }
    setAnchorTime(currentAnchorTime)

    dispatch(
      routesActions.cloneRun(
        run.id,
        runCopyName,
        reverseType,
        currentAnchorTime
      )
    )

    if (reverseType === 1) {
      deleteFromMap.forEach((x) => {
        dispatch(afterCloneAddress(x.id, x.child_id))
      })
    }

    closeFn()
  }

  const getName = (e) => {
    setRunCopyName(e.target.value)
  }

  const click = (position) => {
    setReverseType(position)
  }

  const handleChangeAnchor = (type, value) => {
    setAnchorTime(value)
  }

  return (
    <div>
      <BaseModal
        title={i18n.t(`Clone Run`)}
        closeFn={closeFn}
        close={'closeM'}
        save={clone}
      >
        <div className="content modalClone">
          <Row>
            <Col span={12} className="titleRun">
              <span className="cloneSchoolName">
                <div className="title1">{i18n.t('Run Name')}</div>
                <div className="titleR">{title}</div>
              </span>
            </Col>

            <Col span={12}>
              <div
                style={{ marginTop: '-40px' }}
                className={`addresses-switch`}
              >
                <div className="switcher">
                  <div
                    className={
                      reverseType === 1
                        ? 'switch-button active'
                        : 'switch-button'
                    }
                    onClick={() => click(1)}
                  >
                    {i18n.t('Reverse')}
                  </div>
                  <div
                    className={
                      reverseType === 2
                        ? 'switch-button separator active'
                        : 'switch-button separator'
                    }
                    onClick={() => click(2)}
                  >
                    {i18n.t('Mirror')}
                  </div>
                  <div
                    className={
                      reverseType === 0
                        ? 'switch-button separator active'
                        : 'switch-button separator'
                    }
                    onClick={() => click(0)}
                  >
                    {i18n.t('Repeat')}
                  </div>
                </div>
              </div>

              <div className={` ${errorName ? 'errorNameL' : 'title2'} `}>
                {i18n.t('Starting Time')}
              </div>
              <span style={{ position: 'relative' }}>
                <NewSingleDatePicker
                  style={{ width: '100%' }}
                  use12Hours
                  id={'runBuildModal'}
                  className={
                    anchorTime
                      ? 'ant-calendar-picker changes'
                      : 'ant-calendar-picker'
                  }
                  placeholder={null}
                  format="h:mm A"
                  disabledHours={undefined}
                  onChange={(e) =>
                    handleChangeAnchor('anchorTime', e ? e : null)
                  }
                  picker="time"
                  clearIcon={<ClearIcon />}
                  suffixIcon={<CalendarIcon />}
                />
                <sapn className={'borderPicker'} />
              </span>

              <div className={` ${errorName ? 'errorNameL' : 'title2'} `}>
                {i18n.t('New Run Name')}
              </div>
              <Input
                className={`${errorName ? 'errorName' : ''} `}
                onChange={getName}
              />
            </Col>
          </Row>

          {errors.length > 0 && (
            <div className="errorBlock">
              {errors.map((x) => {
                return (
                  <div className={'err'}>
                    <ModalCloneIcon />
                    <span className="point">
                      {i18n.t('Stop')} {x.stop}.{' '}
                    </span>
                    <span
                      className="text"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {x.name}
                    </span>
                    <span style={{ marginLeft: '5px' }} className="text">
                      {i18n.t('can not be cloned')}
                    </span>
                  </div>
                )
              })}
            </div>
          )}

          <Row>
            <Col span={12}>
              <div className="pointsList">{pointSolverBase()}</div>
            </Col>

            <Col span={12}>
              <div className="pointsList">{pointSolverByType()}</div>
            </Col>
          </Row>
        </div>

        <div className="center">
          <div className="buttons">
            <button className="cancel" onClick={closeFn}>
              {i18n.t('Cancel')}
            </button>
            <button onClick={clone} className="save">
              {i18n.t('Clone')}
            </button>
          </div>
        </div>
      </BaseModal>
    </div>
  )
}

export default ModalClone
