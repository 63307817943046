import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import DragItems from './index'
import './index.less'
import { useContextBuilder } from '../../context'

function DragWrap(props) {
  let { schools, onDragEnd } = props
  let { activeRun, auth } = useContextBuilder()

  let osrm = getOsrm(activeRun)

  function getOsrm(activeRun) {
    if (
      activeRun.hasOwnProperty('osrm') &&
      typeof activeRun.osrm === 'object'
    ) {
      return activeRun.osrm
    } else {
      return false
    }
  }

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'fff' : 'fff',
  })

  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      userSelect: 'none',
      background: '#fff',
      ...draggableStyle,
    }
  }

  let getPointOsrmLeg = (osrm, index) => {
    if (osrm && osrm.legs) {
      if (index === osrm.legs.length) {
        let leg = osrm.legs[index - 1]
        return leg.end_point
      } else {
        let leg = osrm.legs[index]
        if (leg) {
          let distance = leg.distance
          let duration = leg.duration
          return { ...leg.start_point, distance, duration }
        }
      }
    }
  }

  let deletePositionFromRun = (point) => {
    let { routeId, position, id } = point
    props.removeStopFromRun(id, position, routeId, point)
  }

  let removeChildFromStop = (id, child, address, runID) => {
    let data = {
      id: id,
      child_address_id: address.child_address_id,
      childId: child.id,
      type: address.childaddress.type,
      isSchool: address.childaddress.is_school,
    }

    props.removeChildFromStop(data, runID, address)
  }

  let removeChildFromTransfer = (id, child, address, runID) => {
    let data = {
      id: id,
      child_address_id: address,
      childId: child.id,
    }

    props.removeChildFromStop(data, runID)
  }

  let getPropData = (point, index) => {
    let pointOsrmLeg = getPointOsrmLeg(osrm, index)

    return {
      id: point.id,
      key: point.id,
      i: index,
      elem: point,
      schools: schools,
      route: activeRun,
      position: point.position,
      pointOsrmLeg: pointOsrmLeg,
      delFn: () => deletePositionFromRun(point),
      removeChildFromStop: removeChildFromStop,
      removeChildFromTransfer: removeChildFromTransfer,
      lastIndex: activeRun.points.length,
      activeRun: activeRun,
      is_morning: activeRun.is_morning,
      isWriteRoutes: auth.permissions?.write_routes !== 1,
    }
  }

  const draggableItems = () => {
    return activeRun.points.map((point, index) => {
      let propData = getPropData(point, index)

      return (
        <Draggable
          key={point.id.toString()}
          draggableId={point.id.toString()}
          index={index}
          isDragDisabled={propData.isWriteRoutes}
        >
          {(provided, snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                {DragItems(propData)}
              </div>
            )
          }}
        </Draggable>
      )
    })
  }

  if (!activeRun) return <div></div>

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" isCombineEnabled>
        {(provided, snapshot) => {
          return (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {draggableItems()}

              {provided.placeholder}
            </div>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}

export default DragWrap
