import { IRequestOpenedItem } from '@/store/routeTool/routeTool.types'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Input } from 'antd'
import styles from './index.module.less'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { useRequestsStore } from '@/entities/modals/Requests/RequestsOpenedModal/store'
import {
  useLazyPostRejectMessageRequestQuery,
  useLazyPostRejectRequestQuery,
} from '@/store/routeTool/routeTool'
export const RejectStep = (item: IRequestOpenedItem) => {
  const methods = useForm({ defaultValues: { message: '' } })
  const [requestReject, responseReject] = useLazyPostRejectRequestQuery()
  const [sendMessage, responseSendMessage] =
    useLazyPostRejectMessageRequestQuery()
  const onSubmit = ({ message }: { message: string }) => {
    if (item.id) {
      requestReject(String(item.id)).then((res) => {
        if (res.isSuccess) {
          sendMessage({ id: item.id, message })
        }
      })
    }
  }

  const onCancel = () => {
    useRequestsStore.getState().setStep('main')
  }

  return (
    <section>
      <p className={styles.title}>
        You are going to reject the guardian request. Please provide reject
        message:
      </p>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Controller
            rules={{
              required: {
                message: 'Please provide reject message',
                value: true,
              },
            }}
            render={({ field, fieldState }) => (
              <div>
                <Input.TextArea
                  status={fieldState.error?.message ? 'error' : undefined}
                  {...field}
                />
                {fieldState.error?.message && (
                  <p className={styles.errorMessage}>
                    {fieldState.error?.message}
                  </p>
                )}
              </div>
            )}
            name="message"
          />
          <div className={styles.footer}>
            <Button onClick={onCancel} type="button" buttonColor="gray">
              Cancel
            </Button>
            <Button
              isLoading={
                responseReject.isFetching || responseSendMessage.isFetching
              }
              type="submit"
              buttonColor="orange"
            >
              Send
            </Button>
          </div>
        </form>
      </FormProvider>
    </section>
  )
}
