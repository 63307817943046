import { Empty, Popover } from 'antd'
import styles from './NewMultiplySelect.module.less'
import { CheckOutlined } from '@ant-design/icons'
import { useEffect, useMemo, useRef, useState } from 'react'
import classnames from 'classnames'
import { filterArrByAbcInt } from '../../../helpers/arrayWork'
import './NewMultiplySelect.less'
import { SuffixIcon } from '../../../img/iconsComponents/SuffixIcon'

interface INewMultiplySelect {
  options: (string | Record<'id', any>)[]
  filterProp?: string
  disabledOptions?: string[]
  placeholder?: string
  label?: string
  value?: (string | number)[]
  onChange?: (v: any) => void
}
export const NewMultiplySelect = ({
  options = [],
  disabledOptions,
  placeholder,
  filterProp,
  value = [],
  label = '',
  onChange,
}: INewMultiplySelect) => {
  const [isOpen, setIsOpen] = useState(false)
  const childRef = useRef<HTMLDivElement | null>(null)
  const [childWidth, setChildWidth] = useState(0)

  const newOptions = useMemo(
    () => filterArrByAbcInt(options, filterProp),
    [options, filterProp]
  )

  useEffect(() => {
    if (childRef.current?.offsetWidth && isOpen) {
      console.log(childRef.current.offsetWidth)
      setChildWidth(childRef.current.offsetWidth)
    }
  }, [childRef.current?.offsetWidth, isOpen])
  return (
    <div>
      {label && <label className={classnames(styles.label)}>{label}</label>}
      <Popover
        onOpenChange={setIsOpen}
        trigger="click"
        placement="bottom"
        content={
          <Content
            filterProp={filterProp}
            disabledOptions={disabledOptions}
            options={newOptions}
            width={childWidth}
            value={value}
            onChange={onChange}
          />
        }
        showArrow={false}
      >
        <div ref={childRef}>
          <Preview
            count={value.length}
            placeholder={placeholder}
            isOpen={isOpen}
          />
        </div>
      </Popover>
    </div>
  )
}

const Preview = ({
  count,
  isOpen,
  placeholder,
}: {
  count?: number
  isOpen: boolean
  placeholder: INewMultiplySelect['placeholder']
}) => {
  return (
    <div tabIndex={1} className={styles.preview}>
      <span>{count ? `Selected: ${count}` : `Selected: ${placeholder}`} </span>
      <SuffixIcon
        style={{ fontSize: '12px' }}
        className={classnames(styles.arrowIcon, { [styles.active]: isOpen })}
      />
    </div>
  )
}

const Content = ({
  width,
  options = [],
  disabledOptions = [],
  value = [],
  onChange,
  filterProp,
}: {
  width: number
  options: INewMultiplySelect['options']
  disabledOptions?: INewMultiplySelect['disabledOptions']
  value?: INewMultiplySelect['value']
  onChange?: INewMultiplySelect['onChange']
  filterProp?: INewMultiplySelect['filterProp']
}) => {
  const getActive = (option: any) => {
    if (option?.id as any) {
      return value.includes(option.id)
    } else {
      return value.includes(option)
    }
  }

  const handleChange = (option: any) => {
    const currentOption = typeof option === 'string' ? option : option.id
    if (value.includes(currentOption as string | number)) {
      return onChange(value.filter((op: any) => op !== currentOption))
    } else {
      return onChange([...value, currentOption])
    }
  }
  return (
    <ul style={{ width }} className={styles.content}>
      {options.length > 0 ? (
        options.map((option: any, index) => {
          return (
            <li
              key={index}
              onClick={() => handleChange(option)}
              className={classnames(styles.contentItem, {
                [styles.contentItemActive]: getActive(option),
                [styles.disableOption]: option?.id
                  ? disabledOptions.includes(option?.id)
                  : disabledOptions.includes(option),
              })}
            >
              <span>
                {typeof option === 'string'
                  ? option
                  : option[filterProp || 'id']}
              </span>
              <CheckOutlined />
            </li>
          )
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      {}
    </ul>
  )
}
