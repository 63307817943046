import React, { useEffect, useState } from 'react'
import { Map } from 'react-leaflet'
import ReactLeafletGoogleLayer from '../GoogleLayer'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import './style/index.less'
import './style/index2.less'
import { useMap } from './MapContext'
import L from 'leaflet'
import Paint from './Paint'
import _ from 'lodash'
import Rulers from './ruler'
import { useRulerStore } from '@/components/MapDefault/ruler/rulerStore'

export default function (props) {
  const {
    mapRef,
    scope,
    drawTool,
    setPopUp,
    setSelectedTool,
    setDrawTool,
    figureClick,
  } = useMap()
  let [load, setLoad] = useState(false)
  let [lastBounds, setLastBounds] = useState([])
  const [startRule, setStartRule] = useState(false)
  const [boundsProps, setBoundsProps] = useState([])
  const [targetNewRun, setTargetNewRun] = useState(true)
  scope.current = props.scope

  useEffect(() => {
    setTimeout(() => {
      setLoad(true)
      if (props.getMapRef) props.getMapRef(mapRef)
    }, 1)
  }, [])

  useEffect(() => {
    if (props.targetNewRun) {
      setTargetNewRun(true)
      setBoundsProps([])
      setLastBounds([])
    }
  }, [props.targetNewRun])

  useEffect(() => {
    if (props.targetBtn) {
      let b = getBounds()
      mapRef.current.contextValue.map.fitBounds(b)
    }
  }, [props.targetBtn])

  useEffect(() => {
    if (drawTool === `polyline`) {
      setStartRule(true)
    }
  }, [drawTool])

  useEffect(() => {
    if (boundsProps && props.bounds !== boundsProps) {
      setBoundsProps(props.bounds)
    }
  }, [props.bounds])

  function getBounds() {
    let depots = null
    let childs = null
    let depotsFind = null

    if (scope.current && scope.current.props)
      depots = scope.current.props.depots

    if (lastBounds.length > 1 && !targetNewRun) {
      return lastBounds
    }

    if (boundsProps && targetNewRun) {
      let upDate = boundsProps

      if (upDate.length === 1) {
        upDate = [boundsProps[0], boundsProps[0]]

        let latlng = L.latLng(boundsProps[0][0], boundsProps[0][1])
        if (mapRef.current && mapRef.current.contextValue) {
          setTimeout(() => {
            mapRef.current.contextValue.map.setView(latlng, 17)
          }, 100)
        }
      }

      if (JSON.stringify(upDate) !== JSON.stringify(lastBounds)) {
        setTargetNewRun(false)
        setLastBounds(upDate)
        return upDate
      }
    }

    if (_.isEmpty(lastBounds) && mapRef.current) {
      let childPos = []
      mapRef.current.contextValue.map.eachLayer((layer) => {
        if (layer.hasOwnProperty(`_pixiContainer`)) {
          let markers = layer.options.markers
          if (markers.length > 0) {
            childPos = markers.map((x) => x.position)
          }
        }
      })
      if (!_.isEmpty(childPos)) childs = childPos
    }

    if (!_.isEmpty(depots)) {
      let arr = []
      depots.forEach((dep) => {
        if (!dep.address) return false
        arr.push([dep.address.lat, dep.address.lon])
      })
      if (arr.length === 1) arr.push(arr[0])
      if (arr.length > 1) depotsFind = arr
    }

    let defBounds = [
      [24.664018, -123.461071],
      [46.088543, -56.280215],
    ]

    return childs || depotsFind || defBounds
  }

  function handleEsc(e) {
    if (e.keyCode === 27) {
      setSelectedTool('')
      setDrawTool('')
      setStartRule(false)
    }
  }

  function handleWhen() {
    document.addEventListener('keydown', handleEsc, false)
  }

  function onContextmenu(e) {
    const isRuler = useRulerStore.getState().isRule
    if (isRuler) {
      return
    }
    if (e.originalEvent.button !== 2) return false

    if (figureClick.current) {
      figureClick.current = null
      return
    }

    let popUpPosition = {
      x: e.latlng.lat,
      y: e.latlng.lng,
    }

    setPopUp(popUpPosition)

    e.originalEvent.preventDefault()
    e.originalEvent.stopPropagation()
  }

  function escClick() {
    setStartRule(false)
    setSelectedTool('')
    setDrawTool('')
  }

  if (!load) return <div />
  let b = getBounds()
  return (
    <Map
      bounds={b}
      className={props?.className || ''}
      id={'MapDefault'}
      zoomControl={false}
      ref={mapRef}
      onContextmenu={onContextmenu}
      doubleClickZoom={false}
      whenReady={handleWhen}
      wheelPxPerZoomLevel={3000}
    >
      {props.children}

      <Paint saveNewShape={props.saveNewShape} />

      <Rulers escClick={escClick} startRule={startRule} />

      <ReactLeafletGoogleLayer />
    </Map>
  )
}
