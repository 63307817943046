import L from 'leaflet'
import _ from 'lodash'

export function getRouteMarker(type) {
  if (type * 1 === 2) {
    return 'b'
  } else if (type * 1 === 3) {
    return 'school'
  } else if (type * 1 === 4) {
    return 'circle'
  } else if (type * 1 === 6) {
    return 'dpt'
  } else if (type * 1 === 5) {
    return 'transfer'
  }
}

export function getIconBus(bearing) {
  let icon
  let url = ''

  url = `
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
      <defs>
        <radialGradient id="prefix__a" cx="60%" cy="50%" r="50%" fx="50%" fy="50%">
          <stop offset="0%" stop-color="#FFF"/>
          <stop offset="100%" stop-color="#104FE0" />
        </radialGradient>
      </defs>\\n" +
      <g fill="none" fill-rule="evenodd" transform="rotate(${bearing} 25 25 )" >
        <circle cx="25" cy="25" r="25" fill="url(#prefix__a)" fill-opacity=".2"/>
        <g transform="rotate(2 -140.544 409.881)">
          <path fill="#1E334D" d="M11.625 0L17.25 11.25 6 11.25z"/>
          <circle cx="11.25" cy="19" r="11.25" fill="#FFF"/>
        </g>
        <circle cx="24.375" cy="24.375" r="8.125" fill="#248FC1"/>
      </g>
    </svg>
  `

  icon = {
    mapIconUrl: url,
  }

  return L.divIcon({
    className: 'leaflet-data-marker',
    html: L.Util.template(icon.mapIconUrl, {}),
    iconSize: [50, 50],
    popupAnchor: [0, 0],
  })
}

export function getBounds(gpsRun) {
  let bounds = []

  if (gpsRun.points) {
    return gpsRun.points.map((point) => {
      return [point.geo.lat, point.geo.lon]
    })
  }

  if (_.isEmpty(bounds)) {
    return [
      [24.664018, -123.461071],
      [46.088543, -56.280215],
    ]
  }
}

export function getBoundsHistory(gpsRun) {
  if (!_.isEmpty(gpsRun.history)) {
    let points = gpsRun.history.points
    return points.map((point) => {
      return [point.location.lat, point.location.lon]
    })
  }

  return [
    [24.664018, -123.461071],
    [46.088543, -56.280215],
  ]
}

export const arrowOptions = {
  repeat: true,
  offset: 3,
  attributes: {
    fill: 'white',
    'font-weight': 'normal',
    'font-size': '12',
  },
}

export function getBounds2(gpsRuns) {
  let bounds = []
  gpsRuns.forEach((b) => {
    b.forEach((x) => {
      if (x.bus.lastGps && x.bus.lastGps.lat) {
        bounds.push([x.bus.lastGps.lat, x.bus.lastGps.lng])
      }
    })
  })

  bounds = _.compact(bounds)
  if (_.isEmpty(bounds)) {
    return [
      [24.664018, -123.461071],
      [46.088543, -56.280215],
    ]
  }
  return bounds
}
