import React, { Component } from "react";
import Modal from "../../BaseModal";

import Row from "antd/es/row";
import Col from "antd/es/col";

import "./index.less";

class ModalPointDelete extends Component {
  render() {
    let { title, deleteBtn, deleteObject } = this.props;

    return (
      <Modal
        title={title}
        saveBtn={"Save"}
        closeBtn={"Cancel"}
        close={"closeM DeleteStop "}
        closeFn={this.props.closeFn}
        class={"ModalClose open"}
      >
        <div className="profile">
          <div>
            <Row>
              <Col span={24}>
                <span className="Text">
                  Are you sure you want to remove <b>{deleteObject}</b>
                </span>
              </Col>
            </Row>
          </div>
        </div>

        <div className="center">
          <div className="buttons">
            <button className="cancel" onClick={this.props.closeFn}>
              Cancel
            </button>
            <button
              onClick={this.props.deleteFn}
              className="save"
              style={{ whitespace: "pre" }}
            >
              {this.props.child ? "Remove" : "Delete"}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalPointDelete;
