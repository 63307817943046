import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Marker, Popup } from 'react-leaflet'
import MapControls from './components/mapControl'
import ErrorBoundary from '../../components/ErrorBoundary'
import LeftMenu from './components/LeftMenu'
import { get, edit, create } from './components/crud'
import Shapes from './components/Shapes'
import L from 'leaflet'
import Context from './components/context'
import { pointIcon } from '../../components/MapDefault/icon'
import _ from 'lodash'
import {
  fnMouseOut,
  fnMouseOver,
  getIcon,
} from '../../components/MapDefault/mapSpecialFns'
import MapDefault from '../../components/MapDefault'
import { MapProvider, MapContext } from '../../components/MapDefault/MapContext'
import { useSelector } from 'react-redux'
import { getCompanyPermissions } from '../../redux/auth/selectors'
import { getLoaderState } from '../../redux/common/selectors'
import { loaderStart, loaderFinish } from '../../redux/common/actions'
import styles from './zones.module.less'
let allLayersView = {}
let countId = 0

export default function (props) {
  const [list, setList] = useState([])
  const [activeID, setActiveID] = useState(undefined)
  const [plotAddressMarker, setPlotAddressMarker] = useState(undefined)
  const map = useRef(null)
  const drawToolNow = useRef(null)
  const drawToolFn = useRef(null)
  const selectedToolBtn = useRef(null)
  const [isUploaded, setIsUploaded] = useState(false)
  const userPermissions = useSelector(getCompanyPermissions)
  const loader = useSelector(getLoaderState)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!_.isEmpty(list) && !activeID) {
      setActiveZone(list[0].id)
    }
  }, [list])

  useEffect(() => {
    dispatch(loaderStart())
    get().then((response) => {
      getIds(response)
      setList(response)
      if (response) dispatch(loaderFinish())
    })
  }, [isUploaded])

  useEffect(() => {
    setIsLoading(loader === 'loadingStart')
  }, [loader])

  function getIds(list) {
    countId = 0
    list.forEach((item) => {
      if (item.data && item.data.type === 'FeatureCollection') {
        item.data.features.forEach((x) => {
          x.id = countId
          countId = countId + 1
        })
      }
    })
  }

  function saveStyle(attr, id, activeIdZone) {
    let item = (list || []).find((x) => x.id === activeIdZone)
    if (item.data && item.data.type === 'FeatureCollection') {
      item.data.features.forEach((y) => {
        if (y.id === id) {
          y.properties = { ...y.properties, ...attr }
        }
      })
    }
    edit(activeIdZone, item)
  }

  function saveLayer(geoJson) {
    if (!activeID) return false

    geoJson.id = countId
    countId = countId + 1

    let item = (list || []).find((x) => x.id === activeID)
    if (item.data && item.data.type === 'FeatureCollection') {
      item.data.features.push(geoJson)
    } else {
      item.data = { type: 'FeatureCollection', features: [geoJson] }
    }

    edit(activeID, item).then((r) => {
      let newList = [...list]
      newList = newList.map((x) => {
        if (x.id === item.id) {
          x.data = item.data
        }
        return x
      })

      setList(newList)
    })
  }

  function setActiveZone(id) {
    if (!map.current) return

    setActiveID(id)
    list.forEach((x) => {
      if (!x.data || !x.data.features) return

      if (x.id === id) {
        getCenter(x.data.features)
        x.data.features.forEach((y) => {
          addGeoOneLayerShape(y, id)
        })
      } else {
        x.data.features.forEach((y) => {
          if (allLayersView[`l${y.id}`]) {
            allLayersView[`l${y.id}`].remove()
          }
        })
      }
    })
  }

  function getCenter(all) {
    if (!map.current) return
    let allB = []
    all.forEach((y, i) => {
      if (y.geometry.type == 'Point') {
        if (!y.properties.radius) return
        let circle = L.circle(
          [y.geometry.coordinates[1], y.geometry.coordinates[0]],
          { radius: y.properties.radius }
        ).addTo(map.current.contextValue.map)

        allB.push(circle.getBounds())
        circle.remove()
      } else {
        allB.push(L.geoJSON(y).getBounds())
      }
    })
    if (_.isEmpty(allB)) return
    map.current.contextValue.map.fitBounds(allB)
  }

  function deleteRecord(id) {
    let item = (list || []).find((x) => x.id === id)
    if (item.data && item.data.type === 'FeatureCollection') {
      item.data.features.forEach((y) => {
        if (allLayersView[`l${y.id}`]) allLayersView[`l${y.id}`].remove()
      })
    }
  }

  function _onCreated(e) {
    let layer = e.layer
    let type = e.layerType

    let shape = e.layer.toGeoJSON()

    if (type === 'circle') {
      layer.types = 'circle'
      layer.id_flag = Math.round(1)

      shape.properties = {
        subType: 'Circle',
        radius: layer._mRadius,
      }

      if (drawToolNow.current === 'radius') {
        let lat = layer._latlng.lat
        let lng = layer._latlng.lng

        var pointA = new L.LatLng(lat, lng)
        var pointB = new L.LatLng(lat, layer.getBounds()._northEast.lng)
        var pointList = [pointA, pointB]

        shape.properties = {
          subType: 'Radius',
          radius: layer._mRadius,
          pointList: pointList,
        }
      }
    }

    drawToolFn.current(null)

    saveLayer(shape)
    layer.remove()
    addGeoOneLayerShape(shape, activeID)
  }

  function deleteShape(id, activeIdZone) {
    let item = (list || []).find((x) => x.id === activeIdZone)
    if (item.data && item.data.type === 'FeatureCollection') {
      item.data.features = item.data.features.filter((y) => y.id !== id)
    }

    edit(activeIdZone, item).then((r) => {
      let newList = [...list]
      newList = newList.map((x) => {
        if (x.id === item.id) {
          x.data = item.data
        }
        return x
      })

      setList(newList)
    })
  }

  function handleColorPicker(dom, popupLayer, layer, lay, type, activeIdZone) {
    dom.addEventListener('change', function (e) {
      let colors = e.target.value
      layer.setStyle({ [type]: colors })
      dom.value = e.target.value
      lay.closePopup()
      saveStyle({ [type]: colors }, layer.feature.id, activeIdZone)
    })
  }

  function radiusPaint(shape, thisMap) {
    let pointList = shape.properties.pointList
    let radius = shape.properties.radius
    let pointA = pointList[0]
    let pointB = pointList[1]

    let drawnItems = new L.FeatureGroup()
    thisMap.addLayer(drawnItems, { sad: '123' })
    allLayersView[`l${shape.id}`] = drawnItems
    new L.circle(
      [shape.geometry.coordinates[1], shape.geometry.coordinates[0]],
      {
        radius: shape.properties.radius,
        fillColor: 'rgba(0,0,0,0)',
        color: '#000',
      }
    ).addTo(drawnItems)

    new L.marker(pointA, {
      icon: pointIcon(),
    }).addTo(drawnItems)

    var line = new L.polyline(pointList, {
      color: '#000',
      weight: 3,
      opacity: 1,
      id: 2,
      className: 'lineCircle',
      lineJoin: 'round',
    })
    line.addTo(drawnItems)

    let r = Math.floor(radius) / 1000
    r = r / 1.609
    r = r.toFixed(2)

    let tooltips = line.bindTooltip(r + ' mi', {
      permanent: true,
      interactive: true,
      placement: 'right',
      direction: 'left',
      className: 'toolTipCirckle',
    })

    tooltips.addTo(drawnItems).openTooltip({
      lat: pointA.lat,
      lng: pointB.lng,
    })
  }

  function addGeoOneLayerShape(shape, activeIdZone) {
    if (!map.current) return
    let thisMap = map.current.contextValue.map

    if (shape.properties.subType === 'Radius') {
      radiusPaint(shape, thisMap)

      allLayersView[`l${shape.id}`].feature = shape
      eachLayerPaint(allLayersView[`l${shape.id}`], activeIdZone, 1)
      return
    } else if (shape.properties.subType === 'Circle') {
      let circle = new L.circle(
        [shape.geometry.coordinates[1], shape.geometry.coordinates[0]],
        { radius: shape.properties.radius }
      ).addTo(thisMap)
      allLayersView[`l${shape.id}`] = circle
    } else {
      allLayersView[`l${shape.id}`] = L.geoJSON(shape).addTo(thisMap)
    }

    if (allLayersView[`l${shape.id}`].hasOwnProperty('_layers')) {
      allLayersView[`l${shape.id}`].eachLayer((y) =>
        eachLayerPaint(y, activeIdZone)
      )
    } else {
      allLayersView[`l${shape.id}`].feature = { id: shape.id }
      eachLayerPaint(allLayersView[`l${shape.id}`], activeIdZone)
    }
  }

  const handleClickContext = (e, ul, y, activeIdZone, lay) => {
    let thisMap = map.current.contextValue.map

    let popupLayer
    let popupLayerStroke

    let target = e.target // Clicked element
    while (target && target.parentNode !== ul) {
      target = target.parentNode
      if (!target) {
        return
      }
    }

    if (target.tagName === 'LI') {
      if (target.className === 'del') {
        y.remove() // TODO
        deleteShape(y.feature.id, activeIdZone)
      }

      if (target.className === 'fillC') {
        popupLayer = L.popup()
          .setLatLng(lay._popup._latlng)
          .setContent(
            "<div class='colorBlock'><p> <input id='btnColor' list=\"colorList\" type='color'></p></div>"
          )
          .openOn(thisMap)
      }

      if (target.className === 'strokeColor') {
        popupLayerStroke = L.popup()
          .setLatLng(lay._popup._latlng)
          .setContent(
            "<div class='colorBlock'> <p> <input id='btnColorStroke' list=\"colorList\" type='color'></p></div>"
          )
          .openOn(thisMap)
      }
    }

    let color = document.getElementById('btnColor')
    let colorStroke = document.getElementById('btnColorStroke')

    if (color) {
      handleColorPicker(color, popupLayer, y, lay, 'fillColor', activeIdZone)
    }

    if (colorStroke) {
      handleColorPicker(colorStroke, popupLayer, y, lay, 'color', activeIdZone)
    }
  }

  function eachLayerPaint(y, activeIdZone, radius) {
    let thisMap = map.current.contextValue.map

    if (y.feature && _.isObject(y.feature.properties)) {
      if (y.feature.properties.fillColor) {
        y.setStyle({ fillColor: y.feature.properties.fillColor })
      }
      if (y.feature.properties.color) {
        y.setStyle({ color: y.feature.properties.color })
      }
    }

    y.bindPopup(radius ? bindPopupRad() : bindPopup(), {
      className: 'contextMenu',
      closeOnEscapeKey: true,
      direction: 'bottom',
    })

    y.on('click', (e) => {
      e.target.closePopup()
    })

    y.on('contextmenu', (e) => {
      e.target.bringToFront()
      e.target.openPopup()

      let lay = e.target
      const ul = e.target && e.target._popup._contentNode.firstChild

      ul.addEventListener('click', (e) =>
        handleClickContext(e, ul, y, activeIdZone, lay)
      )

      e.originalEvent.preventDefault()
      e.originalEvent.stopPropagation()
    })
  }

  function bindPopup() {
    return (
      '<ul oncontextmenu = "return false" id=\'contextMenu\'>' +
      "<li class='del'>Remove Shape</li>" +
      "<li class='fillC'>Fill Color</li>" +
      "<li  class='strokeColor'>Border Color</li>" +
      '</ul>'
    )
  }

  function bindPopupRad() {
    return (
      '<ul oncontextmenu = "return false" id=\'contextMenu\'>' +
      "<li class='del'>Remove Shape</li>" +
      '</ul>'
    )
  }

  function addZone() {
    if (isLoading) {
      return
    }

    let data = { name: 'default' }

    create(data).then((response) => {
      setList((old) => {
        return [...old, response]
      })
    })
  }

  function loadZonesFromFile(response) {
    setIsLoading(!!response)
    setList([...list, response])
    setIsUploaded(!isUploaded)
  }

  function plotAddress(obj) {
    if (!obj) {
      setPlotAddressMarker(null)
      return false
    }

    let { lat, lon, address } = obj

    address = address.replaceAll(`"`, '')

    let marker = {
      location: [lat, lon],
      icon: getIcon('men_pickup'),
      address: address,
    }

    setPlotAddressMarker(marker)
    map.current.contextValue.map.setView([lat, lon], 15)
  }

  return (
    <div id="zonePage">
      <ErrorBoundary>
        <MapProvider>
          <MapContext.Consumer>
            {({
              onCreatedFn,
              mapRef,
              setDrawTool,
              selectedToolType,
              drawTool,
            }) => {
              onCreatedFn.current = _onCreated
              map.current = mapRef.current
              drawToolFn.current = setDrawTool
              selectedToolBtn.current = selectedToolType
              drawToolNow.current = drawTool
              return (
                <MapDefault className={styles.root} scope={this}>
                  {plotAddressMarker && (
                    <Marker
                      key={'plot'}
                      position={plotAddressMarker.location}
                      icon={getIcon('stops')}
                      onMouseOver={(e) => fnMouseOver(e)}
                      onMouseOut={(e) => fnMouseOut(e)}
                    >
                      <Popup
                        offset={[0, -14]}
                        direction="top"
                        onMouseOut={(e) => fnMouseOut(e)}
                      >
                        <div className="full_name">
                          {' '}
                          {plotAddressMarker.address}
                        </div>
                      </Popup>
                    </Marker>
                  )}

                  <Context.Provider value={{ list, setList }}>
                    <LeftMenu
                      setActiveID={setActiveZone}
                      activeID={activeID}
                      deleteRecord={deleteRecord}
                      userPermissions={userPermissions}
                      isLoading={isLoading}
                    />
                  </Context.Provider>

                  <Shapes
                    addZone={addZone}
                    loadZones={loadZonesFromFile}
                    activeID={activeID}
                    plotCb={plotAddress}
                    userPermissions={userPermissions}
                    isLoading={isLoading}
                  />

                  <MapControls />
                </MapDefault>
              )
            }}
          </MapContext.Consumer>
        </MapProvider>
      </ErrorBoundary>
    </div>
  )
}
