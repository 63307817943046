import { Alert, Progress } from 'antd'
import { useImportRidersStore } from '@/entities/modals/RidersImportModal/RidersImportModal'
import styles from './ProcessStep.module.less'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { CloseAlert } from '@/entities/modals/RidersImportModal/components/CloseAlert/CloseAlert'
import { useModalStore } from '@/store/modal/ModalStore'
import { useImportSocketResponse } from '@/features/containers/SocketConnect/store'

export const ProcessStep = ({
  cancelProcess,
  onCloseModal,
}: {
  cancelProcess: boolean
  onCloseModal: () => void
}) => {
  const total = useImportRidersStore((state) => state.total)

  const { added, ignored, changed, currentCount, ignoreMessage } = useConfig()
  const isEnd = useImportSocketResponse((state) => state.process === 'end')
  const percent = Math.round((currentCount / total) * 100) || 0

  const showPercent = isEnd ? 100 : percent >= 100 ? 99 : percent

  const handleDone = () => {
    if (isEnd) {
      useModalStore.getState().onCloseModal()
    } else {
      onCloseModal()
    }
  }

  const isShowAlert = useImportRidersStore((state) => state.tryClose)

  return (
    <section>
      <p>Importing Riders</p>
      <CloseAlert />
      <Progress percent={showPercent} showInfo={false} />
      <div className={styles.countInfo}>
        <span>Updated:{changed}</span>
        <span>Created:{added}</span>
        <span>Ignored:{ignored.length}</span>
        <span style={{ marginLeft: 'auto' }}>
          {currentCount || 0}/{total || 0} ({showPercent}% Done)
        </span>
      </div>
      {!total && !currentCount && (
        <Alert
          style={{ marginTop: 12 }}
          message="Import is queued. Please wait, it will be started soon"
        />
      )}
      {(ignoreMessage?.length || 0) > 0 && (
        <div className={styles.alertWrapper}>
          {ignoreMessage.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </div>
      )}
      <div className={styles.footer}>
        {isEnd ? (
          <Button onClick={handleDone}>Done</Button>
        ) : (
          <Button onClick={onCloseModal} isLoading={cancelProcess}>
            {isShowAlert ? 'Stop' : 'Close'}
          </Button>
        )}
      </div>
    </section>
  )
}

const useConfig = () => {
  const data = useImportSocketResponse((state) => state.data)

  const params = { added: 0, changed: 0, untouched: 0, ignored: [] }
  data.forEach((item) => {
    if (item.statusMessage === 'count_added') {
      params.added += 1
    } else if (item.statusMessage === 'count_changed') {
      params.changed += 1
    } else if (item.statusMessage === 'count_ignored') {
      params.ignored.push(item)
    } else {
      params.untouched += 1
    }
  })

  const ignoreMessage = []
  data.forEach((el: any) => {
    if (el.statusMessage === 'count_ignored' && el.meta?.errors) {
      if (Array.isArray(el.meta?.errors)) {
        el.meta?.errors
          ?.filter((el) => !!el)
          ?.forEach((error) => {
            if (!error.toLowerCase().includes('cid')) {
              ignoreMessage.push(`Cid: ${el.meta?.data?.cid}, ` + error)
            } else {
              ignoreMessage.push(error)
            }
          })
      } else {
        const erorsArr = Object.values(el.meta?.errors || {})
          .flat()
          ?.filter((el) => !!el)

        const isHaveCid = erorsArr.some((el: string) =>
          el?.toLowerCase().includes('cid')
        )
        ignoreMessage.push(
          `${!isHaveCid ? `Cid:${el.meta?.data?.cid ?? ''}, ` : ''}${erorsArr.join(
            ','
          )} ${Object.values(el?.meta?.data || {})?.join(',')}`
        )
      }
    }
  })

  return { ...params, currentCount: data.length, ignoreMessage }
}
