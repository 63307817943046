import {
  averageSpeed,
  totalDist,
  totalTimeWithWT2,
} from '../../../../../components/RepeatFunctions/timeWork'
import _ from 'lodash'

export function totalInfoByOsrm(osrm, points) {
  let time = '0 min'
  let dist = '0 mi'
  let speed = '0 mph'
  let countRiders,
    cP = 0

  let s = []

  if (typeof osrm === 'object' && osrm && osrm.duration) {
    time = totalTimeWithWT2(osrm)
    dist = totalDist(osrm.distance)
    speed = averageSpeed(osrm.distance, osrm.duration)
  }

  _.each(points, (point) => {
    _.each(point.routePointChildren, (child) => {
      s.push(child.child_id)
    })
    if (point.type !== 6) cP += 1
  })
  countRiders = _.uniq(s).length
  return {
    time: time,
    dist: dist,
    speed: speed,
    countRiders: countRiders,
    countPoints: cP,
  }
}
