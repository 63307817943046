import React, { FC, useMemo } from 'react'
import moment from 'moment'
import { useGpsDetailsStore } from '@/pages/Gps/NewGpsDetails/store'
import { getTimestampWithOffset } from '@/pages/Gps/NewGpsDetails/helpers/common'
import { GpsRunPoint, GpsRunWithDetails, PointStatus } from '@/redux/gps/types'

type PopupHeaderProps = {
  run: GpsRunWithDetails
  stop: GpsRunPoint
}

enum PopupStatus {
  Behind = 'popup-type-behind headerRed',
  OnTime = 'popup-type-ontime headerBase',
  Inactive = 'popup-type-inactive headerGreen',
  Skipped = 'popup-type-behind headerSkip',
}

export const PopupHeader: FC<PopupHeaderProps> = ({ run, stop }) => {
  const checkPoints = useGpsDetailsStore.getState().checkPoints

  const socketData = useMemo(
    () => checkPoints?.[stop?.id || ''],
    [checkPoints, stop?.id]
  )

  let popupStatus = PopupStatus.Inactive

  let time: string
  const isSkip = socketData?.is_skip || stop.status === PointStatus.Skipped

  const scheduleTime = getTimestampWithOffset(stop.scheduledTime, run.timeZone)

  const factTime = getTimestampWithOffset(
    socketData?.date ? socketData.date : stop.factTime,
    run.timeZone
  )

  let title = 'Start Time'
  if (factTime > 0) {
    time = moment(factTime * 1000)
      .utc()
      .format('MM/DD/YYYY hh:mm A')
    if (factTime > scheduleTime) {
      popupStatus = PopupStatus.Behind
    }
    title = 'Arrival Time'
  } else {
    time = moment(scheduleTime * 1000)
      .utc()
      .format('hh:mm A')
  }

  if (stop.scheduledTime - stop.factTime < 0) {
    popupStatus = PopupStatus.Behind
  }

  if (isSkip) {
    popupStatus = PopupStatus.Skipped
    title = 'Skipped'
  }

  return (
    <div className={`headerPopUp medium ${popupStatus}`}>
      <span className="fontSt"> {title} </span>
      {!isSkip && <span className="fontSt">{time}</span>}
    </div>
  )
}
