export const WarningShapeIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M6 0a6 6 0 1 0 0 12A6 6 0 0 0 6 0zm0 10.875a4.875 4.875 0 1 1 0-9.75 4.875 4.875 0 0 1 0 9.75zm0-8.244c-.43 0-.755.225-.755.587V6.54c0 .363.326.586.755.586.42 0 .755-.232.755-.586V3.218c0-.354-.335-.587-.755-.587zm0 5.244a.75.75 0 0 0 0 1.498.75.75 0 0 0 0-1.498z"
      />
    </svg>
  )
}
