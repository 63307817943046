import { NewModal } from '@/entities/ui/NewModal/NewModal'
import { ModalsEnum, useModalSelector } from '@/store/modal/ModalStore'
import { IRequestOpenedItem } from '@/store/routeTool/routeTool.types'
import { useEffect, useMemo } from 'react'
import { format } from 'date-fns'
import { newRequestStatuses } from '@/pages/Requests/constants'
import styles from './RequestsOpenedModal.module.less'
import { useRequestsStore } from '@/entities/modals/Requests/RequestsOpenedModal/store'
import { MainStep } from '@/entities/modals/Requests/RequestsOpenedModal/steps/MainStep'
import { RejectStep } from '@/entities/modals/Requests/RequestsOpenedModal/steps/RejectStep'
import { ManualAcceptStep } from '@/entities/modals/Requests/RequestsOpenedModal/steps/ManualAcceptStep'
import { AutoAccept } from '@/entities/modals/Requests/RequestsOpenedModal/steps/AutoAccept/AutoAccept'
import { useGetRTRequestsMessageQuery } from '@/store/routeTool/routeTool'

export const RequestsOpenedModal = () => {
  const { isOpen, onCloseModal, state } = useModalSelector(
    ModalsEnum.OPENED_PARENT_REQUEST
  )

  return isOpen ? (
    <_RequestsOpenedModal
      item={state?.item as any}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
    />
  ) : null
}

const _RequestsOpenedModal = ({
  isOpen,
  onCloseModal,
  item,
}: {
  isOpen: boolean
  onCloseModal: () => void
  item: IRequestOpenedItem
}) => {
  const title = useMemo(() => {
    return `Change request received on: ${
      item?.date ? format(item.date * 1000, 'MM/dd/yyyy h:mm a') : ''
    }`
  }, [item?.date])

  const step = useRequestsStore((state) => state.step)

  useEffect(() => {
    return () => {
      useRequestsStore.getState().setStep('main')
    }
  }, [])

  const response = useGetRTRequestsMessageQuery(String(item.id || '0'))

  return (
    <NewModal
      className={styles.root}
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {title}
          <span className={styles.status}>
            {newRequestStatuses[item?.status]}
          </span>
        </div>
      }
      open={isOpen}
      onClose={onCloseModal}
    >
      {step === 'main' && (
        <MainStep
          refetch={response.refetch}
          comments={response.data?.data}
          isFetching={response.isFetching}
          {...item}
        />
      )}
      {step === 'reject' && <RejectStep {...item} />}
      {step === 'manual-accept' && <ManualAcceptStep {...item} />}
      {step === 'auto-accept' && <AutoAccept {...item} />}
    </NewModal>
  )
}
