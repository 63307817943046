import * as types from './actionTypes'

export const initState = {
  loading: null,
}

export default (state = initState, action, dispatch) => {
  switch (action.type) {
    case types.PENDING_START: {
      return {
        loading: 'loadingStart',
      }
    }
    case types.PENDING_FINISH: {
      return {
        ...state,
        loading: 'loadingFinish',
      }
    }
    default:
      return state
  }
}
