export const ChronometerIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M16.758 11.053c-.363 3.647-3.318 6.578-6.967 6.914-4.315.396-7.994-2.776-8.42-6.899H.61a.6.6 0 0 1-.52-.9l1.7-2.946a.6.6 0 0 1 1.04 0l1.7 2.945a.6.6 0 0 1-.52.901h-.662a5.779 5.779 0 0 0 6.582 4.905c2.43-.356 4.403-2.274 4.816-4.697a5.78 5.78 0 0 0-5.684-6.78c-1.14 0-2.239.33-3.183.956-.439.29-1.046.233-1.368-.183a.981.981 0 0 1 .226-1.415 7.684 7.684 0 0 1 3.366-1.262V1.476h-.788a.49.49 0 0 1-.49-.49V.49c0-.271.22-.491.49-.491h3.653c.271 0 .491.22.491.49v.495a.49.49 0 0 1-.49.491h-.868v1.128c4.025.543 7.078 4.192 6.656 8.449zm.254-7.428l-1.48-1.367a.49.49 0 0 0-.694.027l-.391.423a.49.49 0 0 0 .027.694l1.48 1.368c.2.184.51.171.694-.028l.391-.423a.49.49 0 0 0-.027-.694zM9.04 5.505v4.799h4.752c0-2.923-2.218-4.8-4.752-4.8z"
      />
    </svg>
  )
}
