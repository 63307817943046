import { SessionWeekId } from '@/pages/Sessions/components/modals/AddSubSessionModal/AddSubSessionModal'
import { ISeasonWeek } from '@/pages/Sessions/components/modals/AddNewSessionModal/useAddNewSessionConfig'

export const formatTitleFromWeeks = (
  weeksIds: SessionWeekId[],
  seasonWeeks: ISeasonWeek[]
) => {
  let title
  if (!weeksIds?.length || !seasonWeeks?.length) {
    title = ''
  } else if (weeksIds.length === 1) {
    const week = seasonWeeks.find((w) => w.id === weeksIds[0])
    title = `Week ${week.week_number}`
  } else {
    title = `Weeks ${weeksIds
      .map((id) => seasonWeeks.find((w) => w.id === id).week_number)
      .sort()
      .join(',')}`
  }
  return title
}
