import React from 'react'
import { getValid } from '../../../../../../../components/validPhone/index'

function StopChildAddress(props) {
  let { point_id, child_address_id, id, childAddress } = props.address
  let { child, school } = childAddress

  let getPhone = (child) => {
    if (props.phone) {
      if (child.first_guardian_phone) {
        return child.first_guardian_phone
      }

      if (child.second_guardian_phone) {
        return child.second_guardian_phone
      }

      if (child.emergency_contact_phone) {
        return child.emergency_contact_phone
      }
    }
  }

  return (
    <div key={id}>
      <div style={{ width: '100%' }}>
        <div>
          <div>
            <div className="riderInfo pickUpList PUP">
              <div className="marginBttom" style={{ display: 'flex' }}>
                <div style={{ width: '100%' }}>
                  <span className="SchoolNames">{child.full_name}</span>
                </div>
                {!props.readOnly && !props.isWriteRoutes && (
                  <div>
                    <button
                      disabled={props.isLoading}
                      className="disabledBtn removeSpan"
                      onClick={() =>
                        props.delChildFromStop(
                          point_id,
                          child,
                          child_address_id
                        )
                      }
                    >
                      <span> </span>
                    </button>
                  </div>
                )}
              </div>

              {getPhone(child) && (
                <div className="ant-row MarginBlock">
                  <div
                    className="ant-col ant-col-24 PhoneIco transIcon"
                    style={{ left: '10px' }}
                  >
                    <span>{getValid(getPhone(child), '+1 ###-###-####')}</span>
                  </div>
                </div>
              )}

              <div className="marginBttom">
                <span className="Address"> {school.name}</span>
                {child.classs && (
                  <span className="normal" style={{ marginLeft: '26px' }}>
                    <span className="gray">Grade: </span>
                    {child.classs}
                  </span>
                )}
              </div>

              <div
                className="calendars marginBttom"
                style={{ marginLeft: '26px' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StopChildAddress
