export const SearchIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M7.969 7.977C8.656 7.289 9 6.464 9 5.5a3.37 3.37 0 0 0-1.027-2.473A3.37 3.37 0 0 0 5.5 2a3.37 3.37 0 0 0-2.473 1.027A3.37 3.37 0 0 0 2 5.5a3.37 3.37 0 0 0 1.027 2.473A3.37 3.37 0 0 0 5.5 9c.964 0 1.786-.341 2.469-1.023zM13 12c0 .27-.099.505-.297.703A.961.961 0 0 1 12 13a.928.928 0 0 1-.703-.297l-2.68-2.672A5.35 5.35 0 0 1 5.5 11a5.404 5.404 0 0 1-2.137-.434 5.5 5.5 0 0 1-1.758-1.171A5.5 5.5 0 0 1 .434 7.637 5.404 5.404 0 0 1 0 5.5c0-.745.145-1.457.434-2.137a5.5 5.5 0 0 1 1.171-1.758A5.5 5.5 0 0 1 3.363.434 5.404 5.404 0 0 1 5.5 0c.745 0 1.457.145 2.137.434a5.5 5.5 0 0 1 1.758 1.171 5.5 5.5 0 0 1 1.171 1.758c.29.68.434 1.392.434 2.137a5.35 5.35 0 0 1-.969 3.117l2.68 2.68c.193.193.289.427.289.703z"
      />
    </svg>
  )
}
