import { ModalsEnum, useModalSelector } from '@/store/modal/ModalStore'
import { FormProvider, useForm } from 'react-hook-form'
import {
  useCreateBusMutation,
  useGetBusDepotListQuery,
  useUpdateBusMutation,
} from '@/store/resources/resources'
import { NewModal } from '@/entities/ui/NewModal/NewModal'
import styles from './AddBusModal.module.less'
import { FormInput } from '@/entities/ui/NewInput/FormInput'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { FormSelect } from '@/entities/ui/NewSelect/NewSelect'
import { IBus } from '@/store/resources/resources.types'
import { useEffect } from 'react'

export const AddBusModal = () => {
  const { isOpen, onCloseModal, state } = useModalSelector(
    ModalsEnum.ADD_BUS_MODAL
  )

  return isOpen ? (
    <_AddBusModal
      isOpen={isOpen}
      defaultValues={{
        bus_depot_id: state?.bus?.bus_depot_id ?? null,
        bus_number: state?.bus?.bus_number ?? '',
        capacity: state?.bus?.capacity ?? '',
        id: state?.bus?.id ?? null,
        type: state?.bus?.type ?? '',
        vin_number: state?.bus?.vin_number ?? '',
      }}
      bus={state?.bus}
      variant={state?.bus?.id ? 'edit' : 'create'}
      onCloseModal={onCloseModal}
    />
  ) : null
}

const _AddBusModal = ({
  isOpen,
  onCloseModal,
  defaultValues,
  variant,
  bus,
}: {
  isOpen: boolean
  onCloseModal: () => void
  defaultValues: any
  variant: 'edit' | 'create'
  bus?: IBus
}) => {
  const methods = useForm({
    defaultValues,
  })
  const { data } = useGetBusDepotListQuery()

  const [request, response] = useCreateBusMutation()
  const [update, updateResponse] = useUpdateBusMutation()

  const onSubmit = (formData) => {
    if (variant === 'create') {
      request(formData)
    } else {
      update({
        ...(bus || {}),
        ...formData,
      })
    }
  }
  const rules = {
    required: {
      value: true,
      message: 'This field is required',
    },
  }
  const options = data?.data.map((depot) => ({
    label: depot.name,
    value: depot.id,
  }))

  useEffect(() => {
    const submit = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        methods.handleSubmit(onSubmit)()
      }
    }

    document.addEventListener('keydown', submit)
    return () => {
      document.removeEventListener('keydown', submit)
    }
  }, [])

  return (
    <NewModal
      title={bus?.id ? `Bus ID ${bus.id}` : 'Add a Bus'}
      width={600}
      open={isOpen}
      onClose={onCloseModal}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.root}>
          <FormSelect
            rules={rules}
            showSearch={false}
            options={options}
            label="Depots"
            name="bus_depot_id"
            data-testid="selectDepot"
          />
          <FormInput
            wrapperClassName={styles.firstInput}
            label="Vin Number"
            name="vin_number"
            rules={rules}
            data-testid="vinInp"
          />
          <div className={styles.lastInputsWrapper}>
            <FormInput
              wrapperClassName={styles.firstInput}
              label="Bus Type"
              name="type"
              data-testid="busTypeInp"
            />
            <FormInput
              wrapperClassName={styles.firstInput}
              label="Bus Number"
              name="bus_number"
              rules={rules}
              data-testid="busNumInp"
            />
            <FormInput
              wrapperClassName={styles.firstInput}
              label="Capacity"
              name="capacity"
              data-testid="busCapInp"
              rules={{
                min: { value: 1, message: "Capacity can't be below 1" },
              }}
              type="number"
            />
          </div>
          <div className={styles.footer}>
            <Button onClick={onCloseModal} buttonColor="gray">
              Cancel
            </Button>
            <Button
              data-testid="saveBtn"
              isLoading={response.isLoading || updateResponse.isLoading}
            >
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </NewModal>
  )
}
