import * as types from "./actionTypes";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TRIPS: {
      return action.payload;
    }
    case types.DEL_FT: {
      const id = action.payload;
      let newArr = "";
      newArr = state.filter(item => {
        return item.id !== id;
      });
      return newArr;
    }
    case types.CANCEL_FT: {
      const id = action.payload;
      let newArr = [...state];

      newArr.forEach(item => {
        if (item.id === id) {
          item.status = 4;
        }
      });

      return newArr;
    }

    default:
      return state;
  }
};
