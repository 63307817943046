import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { axiosBaseQuery } from '@/helpers/axiosBaseQuery'
import api from '@/redux/apiConfig'
import { IAddBusDepotForm } from '@/entities/modals/AddBusDepot/AddBusDepot.types'
import { getUrlWithSearchParams } from '@/helpers/getUrlWithSearchParams'
import { notification } from 'antd'
import { useModalStore } from '@/store/modal/ModalStore'
import {
  IBus,
  IBusDepot,
  IBuseDepotsResponse,
  IDriver,
} from '@/store/resources/resources.types'
import { IAddDriverForm } from '@/entities/modals/AddDriverModal/AddDriverModal.types'
import moment from 'moment'
import * as types from '@/redux/depot/actionTypes'
import { GET_BUSES } from '@/redux/auth/buses/actionTypes'
import { DRIVER_GET_ITEMS } from '@/redux/driver/actionTypes'
import { closeModal } from '@/features/containers/ModalContainer/modalConfig'
import { useBusesListStore } from '@/pages/Resources/buses/useBusesConfig'

export const resourcesApi = createApi({
  reducerPath: 'resourcesApi',
  baseQuery: axiosBaseQuery(api.common),
  tagTypes: ['depot-list', 'buses-list'],
  endpoints: (build) => ({
    addBusDepot: build.mutation<
      any,
      IAddBusDepotForm & { onErrorAddress: () => void }
    >({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ onErrorAddress, ...data }) => ({
        method: 'post',
        url:
          api.common.defaults.baseURL.replace('api-site', 'api/v2') +
          '/depot/create',
        data,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['depot-list']),
      onQueryStarted: async ({ onErrorAddress }, { queryFulfilled }) => {
        try {
          await queryFulfilled
          useModalStore.getState().onCloseModal()
          notification.success({
            message: 'Bus depot was created successfully',
          })
        } catch (e) {
          if (e?.error?.data?.message?.includes('Address is not valid')) {
            onErrorAddress?.()
          } else {
            notification.error({
              message:
                'An error occurred while adding bus depot, please try again',
            })
          }
        }
      },
    }),
    updateBusDepot: build.mutation<
      any,
      IAddBusDepotForm & { depot: IBusDepot }
    >({
      invalidatesTags: ['depot-list'],

      query: ({ depot, ...data }) => ({
        method: 'put',
        url: getUrlWithSearchParams(
          api.common.defaults.baseURL + `/bus-depots/${depot.id}`
        ),
        data: data,
      }),
      onQueryStarted: async ({ depot }, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled
          dispatch(
            resourcesApi.util.updateQueryData(
              'getBusDepotList',
              undefined,
              (state) => {
                state.data = state.data.map((el) => {
                  if (el.id === depot.id) {
                    return {
                      ...depot,
                      ...data.data,
                      address: { ...depot, ...data.data.address },
                    }
                  } else {
                    return el
                  }
                })
              }
            )
          )
          useModalStore.getState().onCloseModal()
          notification.success({
            message: 'Bus depot was updated successfully',
          })
        } catch (e) {
          notification.error({
            message:
              'An error occurred while updating bus depot, please try again',
          })
        }
      },
    }),
    deleteBusDepot: build.mutation<any, number>({
      invalidatesTags: ['depot-list'],
      query: (depot_id) => ({
        url: getUrlWithSearchParams(
          api.common.defaults.baseURL + `/bus-depots/${depot_id}`
        ),
        method: 'delete',
      }),

      onQueryStarted: async (depot_id, { queryFulfilled }) => {
        try {
          await queryFulfilled
          // dispatch(
          //   resourcesApi.util.updateQueryData(
          //     'getBusDepotList',
          //     undefined,
          //     (data) => {
          //       data.data = data.data.filter((el) => el.id !== depot_id)
          //     }
          //   )
          // )
          notification.success({
            message: 'Bus depot was successfully deleted',
          })
          useModalStore.getState().onCloseModal()
        } catch (e) {
          notification.error({
            message: 'An error has occurred. Bus depot could not be deleted',
          })
        }
      },
    }),
    getBusDepotList: build.query<IBuseDepotsResponse, void>({
      providesTags: ['depot-list'],
      query: () => ({
        url: getUrlWithSearchParams(
          api.common.defaults.baseURL + '/bus-depots',
          { expand: 'isDepends' }
        ),
      }),

      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled

          dispatch({
            type: types.DEPOT_GET,
            payload: response.data?.data,
          })
        } catch (e) {
          //
        }
      },
    }),

    getBuses: build.query<{ data: IBus[]; meta: any }, void>({
      query: () => ({
        url: getUrlWithSearchParams('/buses', { expand: 'isDepends' }),
      }),

      providesTags: ['buses-list'],

      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const resp = await queryFulfilled
          dispatch({
            payload: resp.data.data,
            type: GET_BUSES,
          })
        } catch (e) {
          //
        }
      },
    }),
    updateBuse: build.mutation<IBus, IBus>({
      invalidatesTags: ['buses-list'],
      query: (bus) => ({
        url: getUrlWithSearchParams(`/buses/${bus.id}`, {
          expand: 'isDepends',
        }),
        method: 'put',
        data: bus,
      }),

      onQueryStarted: async (params, { queryFulfilled }) => {
        const setLoadingBuses = useBusesListStore.getState().setLoadingList
        try {
          setLoadingBuses(params.id)
          await queryFulfilled
          // TODO расскомментировать после того как инвалидация тега будет не нужна

          // dispatch(
          //   resourcesApi.util.updateQueryData('getBuses', undefined, (data) => {
          //     data.data = data.data.map((bus) =>
          //       bus.id === params.id ? response.data?.data : bus
          //     )
          //   })
          // )
          closeModal()
          notification.success({
            message: 'Bus was successfully updated',
          })
          useModalStore.getState().onCloseModal()
        } catch (e) {
          notification.error({
            message: 'An error occurred. Bus could not be updated',
          })
        } finally {
          setLoadingBuses(params.id)
        }
      },
    }),
    deleteBus: build.mutation<IBus, number>({
      invalidatesTags: ['buses-list'],
      query: (id) => ({
        url: getUrlWithSearchParams(`/buses/${id}`),
        method: 'delete',
      }),
      onQueryStarted: async (id, { queryFulfilled }) => {
        try {
          await queryFulfilled
          // TODO расскомментировать после того как инвалидация тега будет не нужна

          // dispatch(
          //   resourcesApi.util.updateQueryData('getBuses', undefined, (data) => {
          //     data.data = data.data.filter((bus) => bus.id !== id)
          //   })
          // )
          notification.success({
            message: 'Bus was successfully deleted',
          })
          useModalStore.getState().onCloseModal()
        } catch (e) {
          notification.error({
            message:
              e.error.data.message ||
              'An error has occurred. Bus could not be deleted',
          })
        }
      },
    }),
    createBus: build.mutation<IBus, IBus>({
      invalidatesTags: ['buses-list'],
      query: (bus) => ({
        url: getUrlWithSearchParams('/buses', { expand: 'busDepot' }),
        method: 'post',
        data: bus,
      }),
      onQueryStarted: async (params, { queryFulfilled }) => {
        try {
          await queryFulfilled
          // TODO расскомментировать после того как инвалидация тега будет не нужна
          // dispatch(
          //   resourcesApi.util.updateQueryData('getBuses', undefined, (data) => {
          //     data.data = [...data.data, response.data.data]
          //   })
          // )
          notification.success({
            message: 'Bus was successfully created',
          })
          useModalStore.getState().onCloseModal()
        } catch (e) {
          notification.error({
            message: 'An error has occurred. Bus could not be created',
          })
        }
      },
    }),
    updateBus: build.mutation<IBus, IBus>({
      invalidatesTags: ['buses-list'],
      query: (data) => ({
        url: getUrlWithSearchParams(`/buses/${data.id}`, {
          expand: 'busDepot',
        }),
        method: 'put',
        data,
      }),
      onQueryStarted: async (params, { queryFulfilled }) => {
        try {
          await queryFulfilled
          // TODO расскомментировать после того как инвалидация тега будет не нужна

          // dispatch(
          //   resourcesApi.util.updateQueryData('getBuses', undefined, (data) => {
          //     data.data = data.data.map((bus) =>
          //       bus.id === params.id ? response.data.data : bus
          //     )
          //   })
          // )
          notification.success({
            message: 'Bus was successfully updated',
          })
          useModalStore.getState().onCloseModal()
        } catch (e) {
          notification.error({
            message: 'An error has occurred. Bus could not be updated',
          })
        }
      },
    }),
    getDrivers: build.query<{ data?: IDriver[] }, void>({
      query: () => {
        return {
          url: '/bus-drivers?expand=depot,isDepends',
          method: 'get',
        }
      },
      keepUnusedDataFor: 0,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled
          dispatch({
            type: DRIVER_GET_ITEMS,
            payload: data.data,
          })
        } catch (e) {
          //
        }
      },
    }),
    updateDriver: build.mutation<any, IDriver>({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ calendar, depot, ...driver }) => {
        return {
          url: `/bus-drivers/${driver.id}?expand=depot,isDepends`,
          method: 'put',
          data: {
            ...driver,
            birthday: driver.birthday
              ? moment(driver.birthday).unix()
              : driver.birthday,
          },
        }
      },
      onQueryStarted: async (params, { queryFulfilled, dispatch }) => {
        try {
          const response: any = await queryFulfilled
          dispatch(
            resourcesApi.util.updateQueryData(
              'getDrivers',
              undefined,
              (data) => {
                data.data = data.data.map((driver) =>
                  driver.id === params.id ? response.data.data : driver
                )
              }
            )
          )
          notification.success({
            message: 'Driver was successfully updated',
          })
          useModalStore.getState().onCloseModal()
        } catch (e) {
          notification.error({
            message: 'An error has occurred. Driver could not be updated',
          })
        }
      },
    }),
    addNewDriver: build.mutation<any, IAddDriverForm & { id?: number }>({
      query: (driver) => {
        return {
          url: `/bus-drivers?expand=depot,isDepends`,
          method: 'post',
          data: driver,
        }
      },
      onQueryStarted: async (params, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled
          dispatch(
            resourcesApi.util.updateQueryData(
              'getDrivers',
              undefined,
              (data) => {
                data.data = [...data.data, response.data.data]
              }
            )
          )
          notification.success({
            message: 'Driver was successfully created',
          })
          useModalStore.getState().onCloseModal()
        } catch (e) {
          notification.error({
            message: 'An error has occurred. Driver could not be created',
          })
        }
      },
    }),
    deleteDriver: build.mutation<IBus, number>({
      query: (id) => ({
        url: getUrlWithSearchParams(`/bus-drivers/${id}`),
        method: 'delete',
      }),
      onQueryStarted: async (id, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled
          dispatch(
            resourcesApi.util.updateQueryData(
              'getDrivers',
              undefined,
              (data) => {
                data.data = data.data.filter((driver) => driver.id !== id)
              }
            )
          )
          notification.success({
            message: 'Driver was successfully deleted',
          })
          useModalStore.getState().onCloseModal()
        } catch (e) {
          notification.error({
            message:
              e.error.data.message ||
              'An error has occurred. Driver could not be deleted',
          })
        }
      },
    }),
  }),
})

export const {
  useAddBusDepotMutation,
  useUpdateBusDepotMutation,
  useDeleteBusDepotMutation,
  useGetBusDepotListQuery,
  useGetBusesQuery,
  useUpdateBuseMutation,
  useDeleteBusMutation,
  useCreateBusMutation,
  useUpdateBusMutation,
  //drivers
  useGetDriversQuery,
  useUpdateDriverMutation,
  useAddNewDriverMutation,
  useDeleteDriverMutation,
} = resourcesApi
