import styles from './Settings.module.less'
import { NewSingleDatePicker } from '@/entities/ui/NewSingleDatePicker/NewSingleDatePicker'
import { CalendarIcon, ClearIcon } from '@/img/icons'
import React from 'react'
import { FormInput } from '@/entities/ui/NewInput/FormInput'
import { Controller } from 'react-hook-form'

export const Settings = ({ runName }: { runName: string }) => {
  return (
    <div className={styles.middleSection}>
      <div className={styles.runNameWrapper}>
        <p className={styles.runNameLabel}>Run Name</p>
        <p className={styles.runName}>{runName}</p>
      </div>
      <div className={styles.inputs}>
        <Controller
          rules={{
            validate: {
              message: (v) => {
                return v ? true : 'error'
              },
            },
          }}
          render={({ field, fieldState, formState }) => {
            return (
              <article>
                <label
                  data-error={!!formState?.errors?.anchor}
                  className={styles.label}
                >
                  Starting Time
                </label>
                <div className={styles.datePickerWrapper}>
                  <NewSingleDatePicker
                    use12Hours
                    placeholder={null}
                    format="h:mm A"
                    status={formState?.errors?.anchor ? 'error' : undefined}
                    disabledHours={undefined}
                    picker="time"
                    clearIcon={<ClearIcon />}
                    suffixIcon={<CalendarIcon />}
                    {...field}
                  />
                  <div
                    className={styles.dataPickerDevider}
                    data-error={!!formState?.errors?.anchor}
                    // style={{
                    //   width: 1,
                    //   height: '100%',
                    //   background: formState?.errors?.anchor
                    //     ? '#ed4d49'
                    //     : '#c9c9c9',
                    //   position: 'absolute',
                    //   right: 31,
                    //   top: 0,
                    // }}
                  />
                </div>
              </article>
            )
          }}
          name={'anchor'}
        />
        <FormInput
          rules={{ required: { value: true, message: 'error' } }}
          showErrorText={false}
          name="name"
          label="New Run Name"
        />
      </div>
    </div>
  )
}
