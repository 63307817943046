import { useMemo } from 'react'
import {
  useLazyResetReportQuery,
  useRefreshPrintReportQuery,
} from '@/store/fieldTrip/FieldTripApi'
import moment from 'moment'
import { dateFormat, defaultValues, timeFormat } from './PrintModal.constants'

export const usePrintModalConfig = (run: any) => {
  const { data, isFetching } = useRefreshPrintReportQuery(run?.id)
  const [resetReport, resetReportResponse] = useLazyResetReportQuery()

  const defaultValuesForm = useMemo(
    () => ({
      ...defaultValues,
      ...(data?.data ?? {}),
      ...(resetReportResponse.data || {}),
    }),
    [data, resetReportResponse.data]
  )

  const sources =
    run?.osrm?.legs?.length > 0
      ? [
          {
            key: '1',
            number: 1,
            address: run.fieldTripPoints[0].title,
            date: moment(run.osrm.legs[0].start_point.time * 1000).format(
              dateFormat
            ),
            time: moment(run.osrm.legs[0].start_point.time * 1000).format(
              timeFormat
            ),
          },
        ]
      : []
  const destinations = []
  if (run.osrm?.legs?.length) {
    for (let i = 1; i < run.fieldTripPoints.length; i++) {
      destinations.push({
        key: i,
        number: i,
        address: run.fieldTripPoints[i].title,
        date: moment(run.osrm.legs[i - 1].end_point.time * 1000).format(
          dateFormat
        ),
        time: moment(run.osrm.legs[i - 1].end_point.time * 1000).format(
          timeFormat
        ),
      })
    }
  }

  return {
    isLoading: isFetching || resetReportResponse.isFetching,
    resetReport,
    defaultValuesForm,
    sources,
    destinations,
  }
}
