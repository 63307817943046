import { Path, withLeaflet } from "react-leaflet";
import { Polyline as LeafletPolyline } from "leaflet";
import * as L from "leaflet";
import "./leaflet.textpath";
import "./polyline.encoded";

class PolylineText extends Path {
  createLeafletElement(props) {
    let l = LeafletPolyline.fromEncoded(props.geometry);
    l.setText("  >  ", props.arrowOptions);
    l.setStyle(props.pathOptions);
    if (props.name) {
      l.bindPopup(props.name);
    }

    return l;
  }

  updateLeafletElement(fromProps, toProps) {
    if(toProps.active){
      this.leafletElement.bringToFront()
      this.leafletElement.setStyle(toProps.pathOptions)
    }else{
      this.leafletElement.bringToBack()
      this.leafletElement.setStyle(toProps.pathOptions)
    }


    if (toProps.geometry !== fromProps.geometry) {
      this.leafletElement.setLatLngs(L.Polyline.fromEncoded(toProps.geometry).getLatLngs());
    }
    this.setStyleIfChanged(fromProps, toProps);
  }
}

export default withLeaflet(PolylineText);
