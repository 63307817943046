import moment from 'moment'

/**
 * Turn string yyyy-mm-dd to mm/dd/yyyy
 * @param str
 */
export const formatDate = (str: string) => {
  if (!str?.length) return ''
  const arr = str.split('-')
  return `${arr[1]}/${arr[2]}/${arr[0]}`
}

/**
 * Converts strings start date and end date to moment range array
 * @param startDate yyyy-mm-dd
 * @param endDate yyyy-mm-dd
 */
export const makeValueForDatePicker = (startDate: string, endDate: string) => {
  const startDateArr = startDate.split('-')
  const endDateArr = endDate.split('-')
  return [
    moment(new Date(+startDateArr[0], +startDateArr[1] - 1, +startDateArr[2])),
    moment(new Date(+endDateArr[0], +endDateArr[1] - 1, +endDateArr[2])),
  ]
}
