import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { renderChildrenListBuildRun } from '../../../../../../components/RepeatFunctions/filter'
import { MapContext } from '../../../../../../components/MapDefault/MapContext'
import i18n from '../../../../../../i18n'

import {
  fnMouseOut,
  fnMouseOver,
  getIcon,
  getPosition,
} from '../../../../../../components/MapDefault/mapSpecialFns'
import {
  busStopForGroupedPins,
  childrenInStop,
  childrenInStopV2,
} from '../../../../../../redux/routesView/actions'
import * as childrenActions from '../../../../../../redux/children/actions'
import { moveToRunV2 } from '../../../../../../redux/children/actions'
import L from 'leaflet'
import PixiOverlay from '../../../../../../components/Pixi/PixiOverlay'
import { renderToString } from 'react-dom/server'
import ErrorModal from '../../../UnassignedRiders/components/RiderAddress/ErrorModal'
import ErrorModalCapacity from '../../../UnassignedRiders/components/RiderAddress/ErrorModalCapacity'
import capacityCheck from '../../../UnassignedRiders/components/RiderAddress/capacityCheck'
import { Icon } from '@/entities/ui/Icon/Icon'

// const ChildrenPins = (props) => {
//   return <_ChildrenPins {...props} />
// }

class ChildrenPins extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showWarning: false,
      filters: props.filters,
      moveChildWarning: false,
      childStopsCount: undefined,
      isLoading: false,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.sessionsId !== this.state.sessionsId) {
      this.setState({
        sessionsId: nextProps.sessionsId,
      })
    }

    if (nextProps.filters && nextProps.filters !== this.state.filters) {
      this.setState({
        filters: nextProps.filters,
      })
    }

    if (this.props.childrenList !== nextProps.childrenList) {
      this.setState({
        childrenList: nextProps.childrenList,
      })
    }

    if (this.state.routes !== nextProps.routes) {
      this.setState({
        routes: nextProps.routes,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loader !== this.props.loader) {
      this.setState({
        isLoading: this.props.loader === 'loadingStart',
      })
    }
  }

  distance = (lat1, lon1, lat2, lon2, unit) => {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0
    } else {
      var radlat1 = (Math.PI * lat1) / 180
      var radlat2 = (Math.PI * lat2) / 180
      var theta = lon1 - lon2
      var radtheta = (Math.PI * theta) / 180
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
      if (dist > 1) {
        dist = 1
      }
      dist = Math.acos(dist)
      dist = (dist * 180) / Math.PI
      dist = dist * 60 * 1.1515
      if (unit == 'K') {
        dist = dist * 1.609344
      }
      if (unit == 'N') {
        dist = dist * 0.8684
      }
      return dist
    }
  }

  getStops = () => {
    if (!this.props.activeRuns) return false

    let runActive
    this.state.routes.forEach((gr) => {
      gr.routes.find((x) => {
        if (x.id === this.props.activeRuns[0]) {
          runActive = x
        }
      })
    })

    if (runActive) {
      let stops = runActive.points.map((x) => {
        if (x.type === 2) return x
      })

      return _.compact(stops)
    }
  }

  handleMove2 = (e, unassigned) => {
    if (this.state.isLoading) return
    let stops = this.getStops()
    let lanLon = {}
    let d = []
    if (stops) {
      stops.map((item) => {
        if (item.address.lat === '' && item.address.lon === '') {
          let currentAddress = item.address.address.split(';')
          lanLon = {
            lat: currentAddress[0],
            lng: currentAddress[1],
          }
        } else {
          lanLon = {
            lat: item.address.lat,
            lng: item.address.lon,
          }
        }
        let n = this.distance(lanLon.lat, lanLon.lng, e.lat, e.lng, 'K')
        d.push({ distance: n, id: item.id })
      })
      let min = 20000000000
      let c = 0
      let itemId = 0
      d.map((item) => {
        if (min > item.distance) {
          min = item.distance
          itemId = item.id
        }
      })

      let distance = min.toFixed(1)
      let obj = {
        routeId: this.props.activeRuns[0],
        childrenPoints: [
          {
            pointId: itemId,
            childAddressId: unassigned.childSchool.child_address_id,
          },
        ],
      }

      if (parseFloat(distance) <= 0.2) {
        if (obj) {
          let seasonId = this.state.sessionsId
          let id = unassigned.child_id
          if (unassigned.type !== this.getTypeRun()) {
            this.setState({
              showWarning: unassigned.type,
              moveChildWarning: true,
              reqData: obj,
            })
          } else {
            this.props.dispatch(childrenInStopV2(obj))
            this.props.dispatch(childrenActions.moveToRunV2(obj))
            return 1
          }
        }
      }
    }
    return 0
  }

  handleMove = (e, unassigned) => {
    let stops = this.getStops()
    let lanLon = {}
    let d = []

    if (_.isEmpty(stops)) {
      let old = {
        lat: parseFloat(unassigned.address.lat),
        lng: parseFloat(unassigned.address.lon),
      }
      e.target.setLatLng(old)
    }

    if (stops) {
      stops.map((item) => {
        if (item.address.lat === '' && item.address.lon === '') {
          let currentAddress = item.address.address.split(';')
          lanLon = {
            lat: currentAddress[0],
            lng: currentAddress[1],
          }
        } else {
          lanLon = {
            lat: item.address.lat,
            lng: item.address.lon,
          }
        }

        let n = this.distance(
          lanLon.lat,
          lanLon.lng,
          e.target._latlng.lat,
          e.target._latlng.lng,
          'K'
        )

        d.push({ distance: n, id: item.id })
      })

      let min = 20000000000
      let c = 0
      let itemId = 0
      d.map((item) => {
        if (min > item.distance) {
          min = item.distance
          itemId = item.id
        }
      })

      let distance = min.toFixed(1)

      let obj = {
        id: itemId,
        children: [
          { child_address_id: unassigned.childSchool.child_address_id },
        ],
        route_id: this.props.activeRuns[0],
      }

      if (parseFloat(distance) <= 0.2) {
        if (obj) {
          this.props.dispatch(childrenInStop(obj))

          this.props.dispatch(childrenActions.moveToRun(obj))
        }
      } else {
        let newLatLng = new L.LatLng(
          unassigned.address.lat,
          unassigned.address.lon
        )
        e.target.setLatLng(newLatLng)
      }
    } else {
      let newLatLng = new L.LatLng(
        unassigned.address.lat,
        unassigned.address.lon
      )
      e.target.setLatLng(newLatLng)
    }
  }
  iconRender2 = (unassignedAddress, unassignedChild) => {
    let transCat = this.props.transCat
    if (!transCat) return
    let find = transCat.find(
      (x) => x.name === unassignedChild.transportation_category
    )

    let abr = ''
    let caps = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let str = ''

    if (unassignedChild.id === unassignedAddress.child_id) {
      unassignedAddress.abr = ''
    }

    if (find) {
      if (!find.short_name) {
        abr =
          unassignedChild.transportation_category &&
          unassignedChild.transportation_category.split('').filter((i) => {
            return ~caps.indexOf(i)
          })
        abr && abr.forEach((x) => (str += x))

        if (str && unassignedChild.id === unassignedAddress.child_id)
          unassignedAddress.abr = str
      } else {
        unassignedAddress.abr = find.short_name
      }
    }

    if (unassignedAddress.abr !== '') {
      return {
        base64: getIcon(
          unassignedAddress.type === 1 ? 'men_pickup_abr' : 'men_dropoff_abr',
          unassignedAddress.abr,
          unassignedAddress.is_primary,
          true
        ),
      }
    } else {
      if (unassignedAddress.type === 1) {
        if (unassignedAddress.is_primary) {
          return iconPO.mapIconUrlBorder
        } else {
          return iconPO.mapIconUrl
        }
      } else {
        if (unassignedAddress.is_primary) {
          return iconDO.mapIconUrlBorder
        } else {
          return iconDO.mapIconUrl
        }
      }
    }
  }
  iconRender = (unassignedAddress, unassignedChild) => {
    let transCat = this.props.transCat
    if (!transCat) return
    let find = transCat.find(
      (x) => x.name === unassignedChild.transportation_category
    )

    let abr = ''
    let caps = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let str = ''

    if (unassignedChild.id === unassignedAddress.child_id) {
      unassignedAddress.abr = ''
    }

    if (unassignedChild.transportation_category && !find) {
      abr = unassignedChild.transportation_category.split('').filter((i) => {
        return ~caps.indexOf(i)
      })
      abr.forEach((x) => (str += x))

      if (str && unassignedChild.id === unassignedAddress.child_id)
        unassignedAddress.abr = str
    } else if (find) {
      unassignedAddress.abr = find.short_name
    }

    if (unassignedAddress.abr !== '') {
      return getIcon(
        unassignedAddress.type === 1 ? 'men_pickup_abr' : 'men_dropoff_abr',
        unassignedAddress.abr,
        unassignedAddress.is_primary
      )
    } else {
      if (unassignedAddress.type === 1) {
        if (unassignedAddress.is_primary) {
          return divPickUpBorder
        } else {
          return divPickUp
        }
      } else {
        if (unassignedAddress.is_primary) {
          return divDropOffBorder
        } else {
          return divDropOff
        }
      }
    }
  }
  iconId = (unassignedAddress, unassignedChild) => {
    let transCat = this.props.transCat
    if (!transCat) return
    let find = transCat.find(
      (x) => x.name === unassignedChild.transportation_category
    )

    let abr = ''
    let caps = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let str = ''

    if (unassignedChild.id === unassignedAddress.child_id) {
      unassignedAddress.abr = ''
    }

    if (unassignedChild.transportation_category && !find) {
      abr = unassignedChild.transportation_category.split('').filter((i) => {
        return ~caps.indexOf(i)
      })
      abr.forEach((x) => (str += x))

      if (str && unassignedChild.id === unassignedAddress.child_id)
        unassignedAddress.abr = str
    } else if (find) {
      unassignedAddress.abr = find.short_name
    }

    if (unassignedAddress.abr !== '') {
      if (unassignedAddress.type === 1) {
        return 'PU' + unassignedAddress.abr + unassignedAddress.is_primary
      } else {
        return 'DO' + unassignedAddress.abr + unassignedAddress.is_primary
      }
      //return getIcon(unassignedAddress.type === 1 ? "men_pickup_abr":"men_dropoff_abr",unassignedAddress.abr,unassignedAddress.is_primary)
    } else {
      if (unassignedAddress.type === 1) {
        if (unassignedAddress.is_primary) {
          return 'PUBD'
        } else {
          return 'PU'
        }
      } else {
        if (unassignedAddress.is_primary) {
          return 'DOBD'
        } else {
          return 'DO'
        }
      }
    }
  }
  handleClosed = (e) => {
    if (!this.state.startRule) {
      this.setState({
        redirectPointModal: false,
        mapPointModal: false,
      })
    }
  }

  handleDraw = (e) => {
    this.dragPositon = e.sourceTarget._zIndex
    e.sourceTarget.setZIndexOffset(5000)
  }

  contentClick = (e) => {
    this.setState({
      mapPointModal: false,
    })
  }

  getTypeRun = () => {
    let route

    this.props.routes.forEach((r) => {
      r.routes.forEach((x) => {
        if (x.id === this.props.activeRun) route = x
      })
    })

    return route && route.is_morning
  }

  beforeSaveGroupsPins = (pinsOnMapInGroup, shapeRemove, unassignedAddress) => {
    let contAdd = 0

    for (let key in pinsOnMapInGroup) {
      if (pinsOnMapInGroup[key].length > 1)
        contAdd += pinsOnMapInGroup[key].length
    }

    let difference = this.getDifference(contAdd)
    const hasPuDoDifference = Object.values(pinsOnMapInGroup).some((group) => {
      return group.some((child) => {
        return child.unassignedAddress.type !== this.getTypeRun()
      })
    })

    if (difference < 0) {
      this.setState({
        showWarningCapacity: true,
        showWarningCapacityType: `GroupsPins`,
        showWarningCapacityDifference: difference * -1,
        showWarningCapacityGroup: pinsOnMapInGroup,
        showWarningCapacityShapeRemove: shapeRemove,
      })
      return false
    } else if (hasPuDoDifference) {
      this.setState({
        showWarningCapacityType: 'pickUpDropOffDifference',
        showWarning: unassignedAddress && unassignedAddress.type,
        showWarningCapacityGroup: pinsOnMapInGroup,
        showWarningCapacityShapeRemove: shapeRemove,
      })
    } else {
      this.setState(
        {
          showWarningCapacityGroup: pinsOnMapInGroup,
          showWarningCapacityShapeRemove: shapeRemove,
        },
        () => {
          this.saveGroupPins()
        }
      )
    }
  }

  beforeSaveStopAllPins = (pinsOnMapInGroup, shapeRemove) => {
    let contAdd = 0
    for (let key in pinsOnMapInGroup) contAdd += pinsOnMapInGroup[key].length

    let difference = this.getDifference(contAdd)

    if (difference < 0) {
      this.setState({
        showWarningCapacity: true,
        showWarningCapacityType: `GroupsStopsPins`,
        showWarningCapacityDifference: difference * -1,
        showWarningCapacityGroup: pinsOnMapInGroup,
        showWarningCapacityShapeRemove: shapeRemove,
      })
      return false
    } else {
      this.setState(
        {
          showWarningCapacityType: '',
          showWarningCapacityGroup: pinsOnMapInGroup,
          showWarningCapacityShapeRemove: shapeRemove,
        },
        () => {
          this.saveGroupStopsPins()
        }
      )
    }
  }

  saveGroupStopsPins = () => {
    let pinsOnMapInGroup = this.state.showWarningCapacityGroup
    let link
    let reqData = []

    for (let key in pinsOnMapInGroup) {
      let iter = pinsOnMapInGroup[key]
      let riders = []
      let unassignedAddress = []

      iter.forEach((x) => {
        link = x.addStops
        riders.push(x.unassignedChild.id)
        unassignedAddress.push(x.unassignedAddress.id)
      })

      reqData.push({
        address: iter[0].unassignedAddress.address.address,
        children: riders,
        unassignedAddress: unassignedAddress,
        name: iter[0].unassignedAddress.address.address,
        is_anchor: 0,
        route_id: this.props.activeRun,
      })
    }

    link({ points: reqData })

    this.state.showWarningCapacityShapeRemove()
  }

  saveGroupPins = () => {
    let pinsOnMapInGroup = this.state.showWarningCapacityGroup
    let schoolsIds = {}
    let groupedPins = []
    let requestData = []
    let ridersToMove = {
      childrenPoints: [],
      routeId: this.props.activeRun,
    }
    let hasDifference = false

    for (let key in pinsOnMapInGroup) {
      if (pinsOnMapInGroup[key].length > 1) {
        let _schools = []

        pinsOnMapInGroup[key].forEach((x) => {
          let id = x.unassignedAddress.school.id
          _schools.push(id)
        })
        _schools = _.uniq(_schools)
        let t = 0

        _schools.forEach((x) => {
          if (schoolsIds.hasOwnProperty(x)) {
            t += 1
          } else {
            schoolsIds[x] = true
          }
        })
        groupedPins.push(pinsOnMapInGroup[key][0])
      }
    }
    groupedPins.map((item) => {
      let { lat, lon } = item.unassignedAddress.address
      let ridersId = []
      this.props.childrenList.forEach((x) => {
        if (!x.addressesView) return false

        x.addressesView.forEach((y) => {
          if (!y.address) return
          if (
            y.address.lat * 1 === lat * 1 &&
            y.address.lon * 1 === lon * 1 &&
            !y.is_school &&
            !y.is_use
          ) {
            ridersToMove.childrenPoints.push({
              childAddressId: y.id,
              pointId: y.child_id,
            })
            ridersId.push(y.id)
          }
        })
      })
      let oneStopData = {
        routeId: this.props.activeRun,
        address: item.unassignedAddress.address.address,
        is_anchor: 0,
        name: item.unassignedAddress.address.address,
        children: ridersId,
      }
      requestData.push(oneStopData)

      if (this.getTypeRun() !== item.unassignedAddress.type) {
        hasDifference = true
        this.setState({
          showWarning: item.unassignedAddress.type,
          showWarningCapacityType: 'groupedPins',
          requestData,
          ridersToMove,
        })
      }
    })
    if (!hasDifference) {
      this.props.dispatch(busStopForGroupedPins(requestData))
      this.props.dispatch(moveToRunV2(ridersToMove))
    }
    this.state.showWarningCapacityShapeRemove()
  }

  postStopsGroupedPins = () => {
    const { requestData, ridersToMove } = this.state
    this.props.dispatch(busStopForGroupedPins(requestData))
    this.props.dispatch(moveToRunV2(ridersToMove))
  }

  getDifference = (addCount) => {
    let { buses, activeRun, routes } = this.props
    let conf = {
      buses: buses,
      routes: routes,
      activeRun: activeRun,
      addCount: addCount,
    }
    return capacityCheck(conf)
  }

  beforeSave = (checkCapacity = true, type, data, addCount, shapeRemove) => {
    let { unassignedAddress, unassignedChild } = data

    let difference = this.getDifference(addCount)
    //проверка вместимости
    if (checkCapacity && difference < 0) {
      this.setState({
        showWarningCapacity: true,
        showWarningCapacityData: data,
        showWarningCapacityType: type,
        showWarningCapacityDifference: difference * -1,
        showWarningCapacityShapeRemove: shapeRemove,
      })
      return false
    }

    //2 проверка на тип
    if (this.getTypeRun() !== unassignedAddress.type && this.props.activeRun) {
      this.setState({
        showWarning: unassignedAddress.type,
        showWarningCapacityData: data,
        showWarningCapacityType: type,
        childStopsCount: addCount,
      })
      return false
    }

    this.addRider(type, unassignedAddress, unassignedChild, addCount)
  }

  beforeSaveByType = (
    checkCapacity,
    showWarningCapacityType,
    showWarningCapacityData
  ) => {
    if (showWarningCapacityType === 'GroupsPins') {
      this.saveGroupPins()
    } else if (showWarningCapacityType === 'GroupsStopsPins') {
      this.saveGroupStopsPins()
    } else {
      this.beforeSave(
        checkCapacity,
        showWarningCapacityType,
        showWarningCapacityData,
        1
      )
    }
  }

  addRider = (type, unassignedAddress, unassignedChild, data) => {
    if (this.state.isLoading) return
    if (type === `makeStopsInRun`) {
      this.props.makeAnyStops(data)
    }
    if (type === `addRidersInRun`) {
      this.props.addRiderInRun(
        unassignedAddress.id,
        unassignedAddress.childSchool.school_id,
        unassignedChild.id
      )
    }

    if (type === `makeStopInRun`) {
      this.props.makeStopInRun(unassignedAddress, unassignedChild)
    }

    if (this.state.showWarningCapacityShapeRemove) {
      this.state.showWarningCapacityShapeRemove()
    }
    this.closeModal()
  }

  closeModal = () => {
    this.setState({
      showWarningCapacity: false,
      showWarning: false,
      moveChildWarning: false,
      showWarningCapacityType: undefined,
      showWarningCapacityGroup: undefined,
      showWarningCapacityShapeRemove: undefined,
      childStopsCount: undefined,
    })
  }

  saveAfterWarning = () => {
    let {
      showWarningCapacityType,
      moveChildWarning,
      reqData,
      childStopsCount,
    } = this.state
    if (showWarningCapacityType === 'addRidersInRun') this._addRidersInRun()
    if (showWarningCapacityType === 'makeStopInRun') {
      this._makeStopInRun()
    }
    if (showWarningCapacityType === 'pickUpDropOffDifference') {
      this.saveGroupPins()
    }
    if (showWarningCapacityType === 'makeStopsInRun') {
      this.props.makeAnyStops(childStopsCount)
    }
    if (moveChildWarning) {
      this.props.dispatch(childrenInStopV2(reqData))
      this.props.dispatch(childrenActions.moveToRunV2(reqData))
    }
    if (showWarningCapacityType === 'groupedPins') {
      this.postStopsGroupedPins()
    }

    this.closeModal()
  }

  _addRidersInRun = () => {
    let { unassignedAddress, unassignedChild } =
      this.state.showWarningCapacityData
    this.props.addRiderInRun(
      unassignedAddress.id,
      unassignedAddress.childSchool.school_id,
      unassignedChild.id
    )
  }

  _makeStopInRun = () => {
    let { unassignedAddress, unassignedChild } =
      this.state.showWarningCapacityData
    this.props.makeStopInRun(unassignedAddress, unassignedChild)
  }

  loadPins = () => {
    let pins = this.state.filters.pinsShow
    const isRule = this.props.ctx.isRule
    let markers = []
    if (!pins) return <PixiOverlay markers={markers} />

    const isWriteRoutes = this.props?.userPermissions?.write_routes !== 1

    renderChildrenListBuildRun
      .call(this, true, 1)
      .forEach((unassignedChild) => {
        const weeksText = (unassignedChild?.weeks ?? '')
          .toLowerCase()
          .replaceAll(' ', '')
          .split(',')
          .join(', ')

        unassignedChild.addressesView.forEach((unassignedAddress) => {
          if (
            !unassignedAddress.address ||
            !unassignedAddress.address.lat ||
            unassignedAddress.viewOnMap === false ||
            unassignedAddress.is_school ||
            !unassignedAddress.childSchool
          ) {
            return null
          }

          if (Number(unassignedAddress.is_use) === 0) {
            let data = {
              unassignedAddress: unassignedAddress,
              unassignedChild: unassignedChild,
            }

            const popup = renderToString(
              <div>
                <button
                  id={'disabledBtn'}
                  className={'full_name'}
                  disabled={this.state.isLoading}
                  onClick={() =>
                    this.beforeSave(true, `addRidersInRun`, data, 1)
                  }
                >
                  {' '}
                  {unassignedChild.full_name}{' '}
                  {!isWriteRoutes && <span className="addToRun">&nbsp;</span>}
                </button>

                <button
                  id={'disabledBtn'}
                  className="createStopInRider"
                  disabled={this.state.isLoading}
                  onClick={() =>
                    this.beforeSave(true, `makeStopInRun`, data, 1)
                  }
                >
                  {i18n.t('Make a Stop')}
                  {!isWriteRoutes && <span className="addToRun">&nbsp;</span>}
                </button>

                {unassignedAddress.address.address && (
                  <div
                    className={
                      'address ' +
                      (unassignedAddress.type * 1 === 1 ? 'in' : 'out')
                    }
                  >
                    {' '}
                    {unassignedAddress.address.address}
                  </div>
                )}

                {unassignedAddress.school && (
                  <div
                    style={{
                      padding: '4px 0',
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 6,
                    }}
                  >
                    <Icon
                      icon="schoolIcon"
                      style={{
                        marginRight: 0,
                        marginTop: 4,
                        color: '#A6A6A6',
                        flexShrink: 0,
                      }}
                    />
                    {unassignedAddress.school.name}
                  </div>
                )}
                {!!this.props.auth.camp_mode && !!unassignedChild?.weeks && (
                  <div
                    style={{
                      padding: '4px 0',
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 6,
                    }}
                  >
                    <Icon
                      icon="calendar"
                      style={{
                        marginRight: 0,
                        marginLeft: 2,
                        marginTop: 3,
                        color: '#A6A6A6',
                        flexShrink: 0,
                      }}
                      width={12}
                      height={12}
                    />
                    <span>{weeksText}</span>
                  </div>
                )}
              </div>
            )

            markers.push({
              id: unassignedAddress.id,
              position: getPosition(unassignedAddress),
              addRider: (countAdd) =>
                this.beforeSave(true, 'addRidersInRun', data, countAdd),
              addStop: (countAdd) =>
                this.beforeSave(true, 'makeStopInRun', data, countAdd),
              addStops: (countAdd) =>
                this.beforeSave(true, 'makeStopsInRun', data, countAdd),
              addGroupsPins: (...args) =>
                this.beforeSaveGroupsPins(...args, unassignedAddress),
              addGroupsStopsPins: !isRule && this.beforeSaveStopAllPins,
              dragEnd: (e) => !isRule && this.handleMove2(e, unassignedAddress),
              unassignedAddress: unassignedAddress,
              unassignedChild: unassignedChild,
              popup: popup,
              onClick: () => {
                if (this.state.isLoading || isRule) {
                  //
                } else {
                  this.beforeSave(true, 'addRidersInRun', data, 1)
                }
              },
              interactive: !isRule,
              customIcon: this.iconRender2(unassignedAddress, unassignedChild),
              iconId: this.iconId(unassignedAddress, unassignedChild),
              mouseOver: (e) => !isRule && fnMouseOver(e),
              mouseOut: (e) => !isRule && fnMouseOut(e),
            })
          }
        })
      })
    this.context.ridersOnMap.current = markers

    return <PixiOverlay markers={markers} />
  }

  render() {
    let {
      showWarning,
      showWarningCapacity,
      showWarningCapacityData,
      showWarningCapacityType,
      showWarningCapacityDifference,
    } = this.state
    return (
      <div>
        {this.loadPins()}

        {showWarning && (
          <ErrorModal
            close={this.closeModal}
            save={this.saveAfterWarning}
            addressType={showWarning}
          />
        )}

        {showWarningCapacity && (
          <ErrorModalCapacity
            close={this.closeModal}
            save={() =>
              this.beforeSaveByType(
                false,
                showWarningCapacityType,
                showWarningCapacityData
              )
            }
            difference={showWarningCapacityDifference}
          />
        )}
      </div>
    )
  }
}

let iconDO = {
  mapIconUrlBorder:
    '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="47" viewBox="0 0 17 29"><defs><linearGradient id="prefix__a" x1="50%" x2="50%" y1="0%" y2="100%"><stop offset="0%" stop-color="#FB7266"></stop><stop offset="100%" stop-color="#F44940"></stop></linearGradient></defs><g fill="none"><path fill="url(#prefix__a)" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".5" d="M13.48 12.125c1.25-2 1.395-3.188 1.395-4.792C14.875 3.283 11.417 0 7.417 0 3.367 0 0 3.283 0 7.333c0 1.604.125 2.792 1.375 4.792.557.892 6.042 6.708 6.042 15.625 0-8.917 5.505-14.733 6.062-15.625z" transform="translate(1 1)"></path><path fill="#FFE23D" d="M7.333 2.667c1.105 0 2 .895 2 2 0 1.104-.895 2-2 2-1.104 0-2-.896-2-2 0-1.105.896-2 2-2zm0 5c2.21 0 4 .895 4 2v1h-8v-1c0-1.105 1.79-2 4-2z" transform="translate(1 1)"></path></g></svg>',
  mapIconUrl:
    '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="47" viewBox="0 0 28 47" data-reactroot=""><defs><linearGradient id="fb7266f44940" x1="50%" x2="50%" y1="0%" y2="100%"><stop offset="0%" stop-color="#fb7266"></stop><stop offset="100%" stop-color="#f44940"></stop></linearGradient></defs><g fill="none" fill-rule="evenodd"><path fill="url(#fb7266f44940)" stroke="#d62b22" stroke-linecap="round" stroke-linejoin="round" d="M20.219 18.187c1.875-3 2.094-4.782 2.094-7.187 0-6.075-5.188-11-11.188-11C5.05 0 0 4.925 0 11c0 2.405.188 4.187 2.063 7.187.835 1.338 9.062 10.063 9.062 23.438 0-13.375 8.258-22.1 9.094-23.438z" transform="translate(1 1)"></path><path fill="#ffe23d" d="M12 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 7.5c3.315 0 6 1.342 6 3V17H6v-1.5c0-1.658 2.685-3 6-3z"></path></g></svg>',
  mapIconColor: '',
  mapIconColorInnerCircle: '',
  pinInnerCircleRadius: 0,
}

let iconPO = {
  mapIconUrlBorder:
    '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="47" viewBox="0 0 17 29"><defs><linearGradient id="prefix__a" x1="50%" x2="50%" y1="0%" y2="100%"><stop offset="0%" stop-color="#FB7266"></stop><stop offset="100%" stop-color="#F44940"></stop></linearGradient></defs><g fill="none"><path fill="url(#prefix__a)" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".5" d="M13.48 12.125c1.25-2 1.395-3.188 1.395-4.792C14.875 3.283 11.417 0 7.417 0 3.367 0 0 3.283 0 7.333c0 1.604.125 2.792 1.375 4.792.557.892 6.042 6.708 6.042 15.625 0-8.917 5.505-14.733 6.062-15.625z" transform="translate(1 1)"></path><path fill="#FFF" d="M7.333 2.667c1.105 0 2 .895 2 2 0 1.104-.895 2-2 2-1.104 0-2-.896-2-2 0-1.105.896-2 2-2zm0 5c2.21 0 4 .895 4 2v1h-8v-1c0-1.105 1.79-2 4-2z" transform="translate(1 1)"></path></g></svg>',
  mapIconUrl:
    '<svg xmlns="http://www.w3.org/2000/svg"  width="28" height="47" viewBox="0 0 28 47" data-reactroot=""><defs><linearGradient id="fb7266f44940" x1="50%" x2="50%" y1="0%" y2="100%"><stop offset="0%" stop-color="#fb7266"></stop><stop offset="100%" stop-color="#f44940"></stop></linearGradient></defs><g fill="none" fill-rule="evenodd"><path fill="url(#fb7266f44940)" stroke="#d62b22" stroke-linecap="round" stroke-linejoin="round" d="M20.219 18.187c1.875-3 2.094-4.782 2.094-7.187 0-6.075-5.188-11-11.188-11C5.05 0 0 4.925 0 11c0 2.405.188 4.187 2.063 7.187.835 1.338 9.062 10.063 9.062 23.438 0-13.375 8.258-22.1 9.094-23.438z" transform="translate(1 1)"></path><path fill="#fff" d="M12 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 7.5c3.315 0 6 1.342 6 3V17H6v-1.5c0-1.658 2.685-3 6-3z"></path></g></svg>',
  mapIconColor: '',
  mapIconColorInnerCircle: '',
  pinInnerCircleRadius: 0,
}

let divPickUp = L.divIcon({
  className: 'leaflet-data-marker',
  html: L.Util.template(iconPO.mapIconUrl, {}),
  iconSize: [24, 43],
  iconAnchor: [12, 43],
  popupAnchor: [0, -20],
})

let divPickUpBorder = L.divIcon({
  className: 'leaflet-data-marker',
  html: L.Util.template(iconPO.mapIconUrlBorder, {}),
  iconSize: [24, 43],
  iconAnchor: [12, 43],
  popupAnchor: [0, -20],
})

let divDropOff = L.divIcon({
  className: 'leaflet-data-marker',
  html: L.Util.template(iconDO.mapIconUrl, {}), //.replace('#','%23'),
  iconSize: [24, 43],
  iconAnchor: [12, 43],
  popupAnchor: [0, -20],
})

let divDropOffBorder = L.divIcon({
  className: 'leaflet-data-marker',
  html: L.Util.template(iconDO.mapIconUrlBorder, {}), //.replace('#','%23'),
  iconSize: [24, 43],
  iconAnchor: [12, 43],
  popupAnchor: [0, -20],
})

ChildrenPins.contextType = MapContext

function mapStateToProps(state) {
  return {
    childrenList: state.children.childrenList,
    routes: state.routesView.routes,
    sessionsId: state.sessions.activeSeason.id,
    buses: state.buses,
    loader: state.headerLoader.loading,
    userPermissions: state.auth.permissions,
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildrenPins)
