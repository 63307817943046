import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { axiosBaseQuery } from '@/helpers/axiosBaseQuery'
import api from '@/redux/apiConfig'
import { notification } from 'antd'
import { useModalStore } from '@/store/modal/ModalStore'
import { PrintDriverForm } from '@/pages/FieldTrip/Run/components/PrintModal/PrintModal.types'
import { getUrlWithSearchParams } from '@/helpers/getUrlWithSearchParams'
import { IFTRequestItem } from '@/store/fieldTrip/FieldTrip.types'
import * as types from '@/redux/fieldTrip/requests/actionTypes'
import { transformResponseFTRequests } from '@/store/fieldTrip/FieldTrip.helpers'
import { useFtRequestsStore } from '@/pages/FieldTrip/Requests/tabs/RequestTab/store'
import { closeModals } from '@/helpers/modalActions'

export const fieldTripApi = createApi({
  reducerPath: 'fieldTripApi',
  baseQuery: axiosBaseQuery(api.common),
  tagTypes: ['list'],
  endpoints: (build) => ({
    refreshPrintReport: build.query<any, number>({
      query: (tripId) => {
        return {
          method: 'get',
          url: `../api/v2/field-trip/run/driver-sheet/${tripId}`,
        }
      },
      keepUnusedDataFor: 0,
    }),
    getPrintPdf: build.query<{ data: string }, number>({
      query: (tripId) => {
        return {
          method: 'get',
          url: `../api/v2/pdf/field-trip-run/driver-sheet/${tripId}`,
          data: { responseType: 'arraybuffer' },
        }
      },

      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const response = await queryFulfilled
          console.log(response.data)
          const file = new Blob([response.data.data], {
            type: 'application/pdf',
          })
          window.open(URL.createObjectURL(file))
          useModalStore.getState().onCloseModal()
        } catch (e) {
          notification.error({ message: 'Failed to get print PDF' })
        }
      },
      keepUnusedDataFor: 0,
    }),
    savePrintDriver: build.query<
      any,
      { data: PrintDriverForm; tripId: number }
    >({
      query: ({ data, tripId }) => {
        return {
          method: 'post',
          url: `../api/v2/field-trip/run/driver-sheet/${tripId}`,
          data,
        }
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled
          notification.success({ message: 'Driver sheet successfully saved' })
          useModalStore.getState().onCloseModal()
        } catch (e) {
          notification.error({ message: 'Driver sheet could not be saved' })
        }
      },
      keepUnusedDataFor: 0,
    }),
    resetReport: build.query<PrintDriverForm, number>({
      query: (tripId) => {
        return {
          method: 'post',
          url: `../api/v2/field-trip/run/driver-sheet-reset/${tripId}`,
        }
      },
    }),
    getFtRequests: build.query<{ data: IFTRequestItem[] }, void>({
      query: () => ({
        url: getUrlWithSearchParams('field-trip-requests', {
          expand: 'fieldTrip.fieldTripRequests',
        }),
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled
          dispatch({
            payload: { data: data.data },
            type: types.GET_REQUESTS,
          })
        } catch (e) {
          //
        }
      },
      keepUnusedDataFor: 0,
      providesTags: ['list'],
      transformResponse: (response: { data: IFTRequestItem[] }) =>
        transformResponseFTRequests(response),
    }),
    getBuildFT: build.query<{ data: any }, number>({
      keepUnusedDataFor: 0,
      query: (id) => ({
        url: getUrlWithSearchParams('/field-trip-requests/build', {
          id,
          expand: 'fieldTrip',
        }),
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled
          dispatch({
            type: types.BUILD_TRIP,
            payload: { data: response.data?.data },
          })
        } catch (e) {
          //
        }
      },
    }),
    editFTTripActive: build.mutation<
      { data: IFTRequestItem },
      { id: number; value: number }
    >({
      query: (data) => ({
        method: 'put',
        url: getUrlWithSearchParams('/field-trip-requests/change-active', {
          expand: 'fieldTrip.fieldTripRequests',
        }),
        data,
      }),
      onQueryStarted: async ({ id }, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled
          dispatch(
            fieldTripApi.util.updateQueryData(
              'getFtRequests',
              undefined,
              (data) => {
                data.data = data.data.map((item) =>
                  item.id === id ? response.data.data : item
                )
              }
            )
          )
        } catch (e) {
          notification.error({ message: 'Server error' })
        }
      },
    }),

    deleteFTRequest: build.mutation<{ data: IFTRequestItem }, number>({
      query: (id) => ({
        method: 'delete',
        url: getUrlWithSearchParams(`/field-trip-requests/${id}`, {
          expand: 'fieldTrip.fieldTripRequests',
        }),
      }),
      onQueryStarted: async (id, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled
          dispatch(
            fieldTripApi.util.updateQueryData(
              'getFtRequests',
              undefined,
              (data) => {
                data.data = data.data.filter((item) => item.id !== id)
              }
            )
          )
          useModalStore.getState().onCloseModal()
          notification.success({
            message: 'The field trip request has been successfully deleted.',
          })
        } catch (e) {
          notification.error({
            message: 'The field trip request could not be deleted.',
          })
        }
      },
    }),

    postCombineTrips: build.mutation<{ data: IFTRequestItem[] }, number[]>({
      query: (ids) => ({
        method: 'post',
        url: getUrlWithSearchParams('/field-trip-requests/combine', {
          expand: 'fieldTrip.fieldTripRequests',
        }),
        data: { ids },
      }),
      // invalidatesTags: ['list'],
      onQueryStarted: async (ids, { queryFulfilled, dispatch }) => {
        try {
          const setFilter = useFtRequestsStore.getState().setFilter

          const response = await queryFulfilled
          dispatch(
            fieldTripApi.util.updateQueryData(
              'getFtRequests',
              undefined,
              (data) => {
                const recordItems = response.data.data.reduce(
                  (acc, item) => ({ ...acc, [item.id]: item }),
                  {}
                )
                data.data = transformResponseFTRequests({
                  data: data.data.map((item) =>
                    recordItems?.[item.id] ? recordItems?.[item.id] : item
                  ),
                }).data
              }
            )
          )
          setFilter({ selectionRows: [] })
        } catch (e) {
          notification.error({
            message: 'Server error',
          })
        }
      },
    }),
    editFieldTrip: build.mutation<void, number>({
      invalidatesTags: ['list'],
      query: (id) => {
        return {
          url: `field-trips/${id}`,
          method: 'put',
          data: {
            lock: 0,
            status: 1,
          },
        }
      },
      onQueryStarted: async (id, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled
          notification.success({
            message:
              'Field Trip has been successfully added to the list of requests',
          })
          dispatch(
            fieldTripApi.util.updateQueryData(
              'getFtRequests',
              undefined,
              (data) => {
                data.data = data.data.filter((item) => item.id !== id)
              }
            )
          )
          closeModals()
        } catch (e) {
          notification.error({ message: 'Server error' })
        }
      },
    }),

    resendFieldTripToApprover: build.mutation<void, number>({
      query: (id) => {
        return {
          url: getUrlWithSearchParams(`field-trips/current-resend`, { id }),
        }
      },
      onQueryStarted: async (id, { queryFulfilled }) => {
        try {
          await queryFulfilled
          notification.success({
            message:
              'The approval message was successfully sent to the current approver',
          })
          closeModals()
        } catch (e) {
          notification.error({ message: 'Server error' })
        }
      },
    }),
  }),
})

export const {
  useResendFieldTripToApproverMutation,
  useEditFieldTripMutation,
  usePostCombineTripsMutation,
  useDeleteFTRequestMutation,
  useLazyGetBuildFTQuery,
  useEditFTTripActiveMutation,
  useGetFtRequestsQuery,
  useLazyGetFtRequestsQuery,
  useRefreshPrintReportQuery,
  useLazySavePrintDriverQuery,
  useLazyGetPrintPdfQuery,
  useLazyResetReportQuery,
} = fieldTripApi
