import { FC, useState } from 'react'
import { Radio } from 'antd'
import { NewRadio } from '@/entities/ui/NewRadio/NewRadio'
import { ModalFooter } from '@/entities/ui/NewModal/components/ModalFooter'
import styles from './BatchDeleteRidersModal.module.less'
import { useBatchDeleteRidersMutation } from '@/store/common/common'
import { closeModal } from '@/features/containers/ModalContainer/modalConfig'

interface IProps {
  selectedRidersIds: number[]
  ridersList: any[]
}

export const BatchDeleteRidersModal: FC<IProps> = ({
  selectedRidersIds,
  ridersList,
}) => {
  const [value, setValue] = useState('1')
  const [request, response] = useBatchDeleteRidersMutation()
  const ridersOnRun = ridersList.filter((rider) => {
    if (rider.addresses.length === 0 || !selectedRidersIds.includes(rider.id)) {
      return false
    } else return !!rider.addresses.some((address) => address.is_use === 1)
  })

  const isOnlyOnRun = ridersOnRun?.length === selectedRidersIds.length
  const inOnlyUnassignedRiders = ridersOnRun?.length === 0

  const isOnlyOneCase = isOnlyOnRun || inOnlyUnassignedRiders

  const text =
    isOnlyOnRun && !inOnlyUnassignedRiders
      ? `You are about to delete ${selectedRidersIds.length} selected riders marked as and/or
        in runs.`
      : `You are about to delete ${selectedRidersIds.length} selected riders.`

  const onSubmit = () => {
    if (isOnlyOneCase) {
      return request(selectedRidersIds)
    } else {
      const ids = []
      if (value === '1') {
        selectedRidersIds.forEach((id) => ids.push(id))
      } else if (value === '2') {
        const selectedRidersIdOnRuns = ridersOnRun.map((rider) => rider.id)
        selectedRidersIds.forEach((id) => {
          if (!selectedRidersIdOnRuns.includes(id)) {
            ids.push(id)
          }
        })
      }
      if (ids.length === 0) {
        return closeModal()
      }

      return request(ids)
    }
  }

  return (
    <>
      <p className={styles.title}>
        {isOnlyOneCase ? (
          text
        ) : (
          <>
            You are about to delete {selectedRidersIds?.length ?? 0} selected
            riders.{' '}
            {`${ridersOnRun?.length} of them are marked as riders and/or
        in runs.`}
          </>
        )}
      </p>

      {isOnlyOnRun && (
        <NewRadio checked>
          Remove selected riders from runs and delete from system
        </NewRadio>
      )}

      {!isOnlyOneCase && (
        <>
          <p className={styles.subtitle}>
            Please choose one of the following actions:
          </p>

          <Radio.Group
            className={styles.group}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          >
            <NewRadio value={'1'}>
              Remove selected riders from runs and delete from system
            </NewRadio>
            <NewRadio value={'2'}>Delete only non-riders</NewRadio>
          </Radio.Group>
        </>
      )}

      <ModalFooter
        success={{
          action: onSubmit,
          text: 'Delete',
          isLoading: response.isLoading,
        }}
      />
    </>
  )
}
