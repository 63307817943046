import { useCallback, useEffect, useRef, useState } from 'react'

export const useMouseController = () => {
  const [mousePos, setMousePos] = useState<number>(0)
  const rectRef = useRef<DOMRect | null>(null)

  const updateRectRef = useCallback(() => {
    const block2 = document.querySelector('#block1')
    if (block2) {
      rectRef.current = block2.getBoundingClientRect()
    }
  }, [])

  const handleMouseMove = useCallback(({ pageX, pageY }) => {
    if (rectRef.current) {
      const { height, x, bottom } = rectRef.current
      const isInsideRect =
        pageY < bottom && pageY > bottom - height && pageX > x && pageX < x + 15

      setMousePos(isInsideRect ? bottom - pageY : 0)
    }
  }, [])

  useEffect(() => {
    const intervalID = setInterval(updateRectRef, 1000)

    return () => {
      clearInterval(intervalID)
    }
  }, [updateRectRef])

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [handleMouseMove])

  return { mousePos, top: (rectRef.current?.height ?? 0) - mousePos }
}
