import React, { useState, useEffect } from 'react'
import { get } from '../../../Zones/components/crud'
import L from 'leaflet'
import _ from 'lodash'

// todo backgound lock заливка 2 цвета под углом (запретная зона атомная версия ;D)

let allLayersView = []

export default function (filtersAllObj, filtersActive, mapp) {
  let [zonesList, setZones] = useState([])
  let [mapLeaflet, setMapLeaflet] = useState(null)

  useEffect(() => {
    if (!mapLeaflet && mapp && mapp.current && mapp.current.contextValue) {
      get().then((response) => {
        // получаю зоны
        setZones(response)

        if (mapp && mapp.current && mapp.current.contextValue)
          setMapLeaflet(mapp.current.contextValue.map)
      })
    }
  }, [mapp])

  useEffect(() => {
    if (filtersAllObj) paintShapeFn(filtersAllObj)
  }, [filtersAllObj, filtersActive])

  useEffect(() => {
    if (!filtersAllObj) {
      shapeDel()
    }
  }, [filtersAllObj])

  const shapeDel = () => {
    allLayersView.forEach((x) => x.remove())
    allLayersView = []
  }

  const paintShapeFn = (zonesActive) => {
    shapeDel()
    if (!zonesActive || !zonesActive.in) return false
    ;[...zonesActive.in, ...zonesActive.ex].forEach((id) => {
      zonesList.forEach((shape) => {
        if (id === shape.id && !_.isEmpty(shape.data)) {
          shape.data.features.map((s, i) => {
            addGeoOneLayerShape(s, i)
          })
        }
      })
    })
  }

  const addGeoOneLayerShape = (shape, activeIdZone) => {
    if (shape.properties.subType === 'Circle') {
      let circle = new L.circle(
        [shape.geometry.coordinates[1], shape.geometry.coordinates[0]],
        { radius: shape.properties.radius }
      )
        .setStyle({ pane: 'tilePane' })
        .addTo(mapLeaflet)
      allLayersView.push(circle)
    } else {
      allLayersView.push(
        L.geoJSON(shape).setStyle({ pane: 'tilePane' }).addTo(mapLeaflet)
      )
    }

    let el = allLayersView[allLayersView.length - 1]
    if (el.hasOwnProperty('_layers')) {
      el.eachLayer((y) => eachLayerPaint(y))
    } else {
      el.feature = { id: shape.id }
      eachLayerPaint(el)
    }
  }

  const eachLayerPaint = (y) => {
    if (y.feature && _.isObject(y.feature.properties)) {
      if (y.feature.properties.fillColor) {
        y.setStyle({ fillColor: y.feature.properties.fillColor })
      }
      if (y.feature.properties.color) {
        y.setStyle({ color: y.feature.properties.color })
      }
    }
  }

  return <div />
}
