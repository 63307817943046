import { create } from 'zustand'

export type IRTRegistrationScreens =
  | 'main'
  | 'reject'
  | 'manualAccept'
  | 'autoAccept'
export interface IRTRegistrationStore {
  screen: IRTRegistrationScreens
  setScreen: (screen: IRTRegistrationScreens) => void
}

export const useRTRegistrationsStore = create<IRTRegistrationStore>(
  (set, get) => ({
    screen: 'main',
    setScreen: (screen) => set({ screen }),
  })
)
