export const CHILDREN_GET_ITEMS = 'children.GET_ITEMS'
export const CHILDREN_GET_ITEMS_USE = 'children.GET_ITEMS_USE'
export const CHILDREN_ADD_ITEM = 'children.ADD_ITEM'
export const CHILDREN_ADD_ITEM_ERROR = 'children.ADD_ITEM_ERROR'
export const CHILDREN_REMOVE_ITEM = 'children.REMOVE_ITEM'
export const CHILDREN_REMOVE_BY_IDS = 'children.CHILDREN_REMOVE_BY_IDS'
export const CHILDREN_EDIT_ITEM = 'children.EDIT_ITEM'
export const CHILDREN_EDIT_ITEM_ADDRESS = 'children.CHILDREN_EDIT_ITEM_ADDRESS'
export const CHILDREN_EDIT_ACTIVE = 'children.EDIT_ACTIVE'
export const CHILDREN_REMOVE_ADDRESS = 'children.REMOVE_ADDRESS'
export const CHILDREN_ALL_INACTIVE = 'children.ALL_INACTIVE'
export const CHILDREN_CLEAR_DAILY = 'children.CLEAR_DAILY'

export const CHILDREN_ADD_NOTE = 'children.ADD_NOTE'
export const CHILDREN_REMOVE_NOTE = 'children.REMOVE_NOTE'
export const CHILDREN_ATTENDANCE = 'children.CHILDREN_ATTENDANCE'
export const CHILDREN_ATTENDANCE_BY_ID = 'children.CHILDREN_ATTENDANCE_BY_ID'
export const CHILDREN_LOG = 'children.CHILDREN_LOG'
export const CHILDREN_EDIT_NOTE = 'children.EDIT_NOTE'
export const CHILDREN_GET_NOTES = 'children.GET_NOTES'
export const CHILDREN_EDIT_ADDRESS = 'children.EDIT_ADDRESS '
export const CHILDREN_MOVE_TO_RUN = 'children.MOVE_TO_RUN'
export const CHILDREN_MOVE_TO_RUN_V2 = 'children.MOVE_TO_RUN_V2'
export const CHILDREN_REMOVE_FROM_TRANSFER =
  'children.CHILDREN_REMOVE_FROM_TRANSFER'

export const CHILDREN_EDIT_CHILDNODE = 'children.CHILDREN_EDIT_CHILDNODE '
export const CHILDREN_ADD_CHILDNODE = 'children.CHILDREN_ADD_CHILDNODE '

export const CHILDREN_EDIT_ADDRESS_AFTER_CLONE =
  'children.CHILDREN_EDIT_ADDRESS_AFTER_CLONE '
export const CHILDREN_EDIT_ADDRESS_FROM_SCHOOL =
  'children.EDIT_ADDRESS_FROM_SCHOOL'
export const CHILDREN_SHOW_ADDRESS = 'children.SHOW_ADDRESS '
export const CHILDREN_ADDRESS_SWITCH = 'children.ADDRESS_SWITCH '
export const CHILDREN_RESET_FILTERS = 'children.RESET_FILTERS'

export const UPLOAD_CHILD_PHOTO = 'children.UPLOAD_CHILD_PHOTO'
