import React from 'react'

export const TrashIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="12"
      viewBox="0 0 11 12"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M4 9.75v-5.5a.243.243 0 0 0-.07-.18.243.243 0 0 0-.18-.07h-.5a.243.243 0 0 0-.18.07.243.243 0 0 0-.07.18v5.5c0 .073.023.133.07.18.047.047.107.07.18.07h.5a.243.243 0 0 0 .18-.07.243.243 0 0 0 .07-.18zm2 0v-5.5a.243.243 0 0 0-.07-.18.243.243 0 0 0-.18-.07h-.5a.243.243 0 0 0-.18.07.243.243 0 0 0-.07.18v5.5c0 .073.023.133.07.18.047.047.107.07.18.07h.5a.243.243 0 0 0 .18-.07.243.243 0 0 0 .07-.18zm2 0v-5.5a.243.243 0 0 0-.07-.18.243.243 0 0 0-.18-.07h-.5a.243.243 0 0 0-.18.07.243.243 0 0 0-.07.18v5.5c0 .073.023.133.07.18.047.047.107.07.18.07h.5a.243.243 0 0 0 .18-.07.243.243 0 0 0 .07-.18zM3.75 2h3.5l-.375-.914A.22.22 0 0 0 6.742 1H4.266a.22.22 0 0 0-.133.086L3.75 2zm7.25.25v.5a.243.243 0 0 1-.07.18.243.243 0 0 1-.18.07H10v7.406c0 .433-.122.806-.367 1.121-.245.315-.54.473-.883.473h-6.5c-.344 0-.638-.152-.883-.457C1.122 11.238 1 10.87 1 10.438V3H.25a.243.243 0 0 1-.18-.07.243.243 0 0 1-.07-.18v-.5c0-.073.023-.133.07-.18A.243.243 0 0 1 .25 2h2.414L3.211.695c.078-.192.219-.356.422-.492C3.836.068 4.042 0 4.25 0h2.5c.208 0 .414.068.617.203.203.136.344.3.422.492L8.336 2h2.414c.073 0 .133.023.18.07.047.047.07.107.07.18z"
      />
    </svg>
  )
}
