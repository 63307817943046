import styles from './ErrorMessage.module.less'
import { DetailedHTMLProps, HTMLAttributes } from 'react'
import classNames from 'classnames'

interface IProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {}
export const ErrorMessage = ({ children, className, ...rest }: IProps) => {
  return (
    <p {...rest} className={classNames(styles.errorMessage, className)}>
      {children}
    </p>
  )
}
