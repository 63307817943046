import React, { Component } from 'react'
import { connect } from 'react-redux'
import Checkbox from 'antd/es/checkbox'
import BaseModal from '../../../../components/BaseModal'
import DatePicker from 'antd/es/date-picker'
import moment from 'moment-with-locales-es6'
import mom from 'moment'
import './index.less'
import { Spin } from 'antd'
import api from '../../../../redux/apiConfig'
import { Trans } from 'react-i18next'
import i18n from '../../../../i18n'
import { momentTzHelper } from '../../../../helpers/momentTz'
import { NewCheckbox } from '../../../../entities/ui/NewCheckbox/NewCheckbox'
import { NewSingleDatePicker } from '../../../../entities/ui/NewSingleDatePicker/NewSingleDatePicker'
import { CalendarIcon, ClearIcon } from '../../../../img/icons'

let rq = api.routesPdf

class Modal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      map_section: false,
      stoplist_section: false,
      turnbyturn_section: false,
      student_cards: false,
      activeDate: moment().utc(false).unix(),
      clear: false,
      errorDate: false,
      spinning: false,
    }
  }

  componentDidMount() {
    let dom = document.querySelector('.ant-calendar-picker-clear')
    if (dom) {
      dom.addEventListener('click', () => {
        this.setState({
          clear: true,
        })
      })
    }
  }

  handleChange = (type, e) => {
    e.preventDefault()
    this.setState({
      [type]: e.target.value,
    })
  }

  onChange = (type, e) => {
    if (!e) {
      this.setState({
        activeDate: '',
      })
    } else {
      if (type === 'activeDate') {
        this.setState({
          [type]: e.utc(false).unix(),
          errorDate: false,
          clear: false,
        })
      } else {
        this.setState({
          [type]: e.target.checked,
          clear: false,
        })
      }
    }
  }

  saveBtn = () => {
    let { activeDate } = this.state

    let string = ''
    if (this.props.id) {
      string = this.props.id
    } else {
      this.props.checkedList.forEach((x, i) => {
        string += x
        if (i !== this.props.checkedList.length - 1) {
          string += ','
        }
      })
    }

    if (!activeDate) {
      this.setState({
        errorDate: true,
      })
      return
    }
    let { map_section, stoplist_section, turnbyturn_section, student_cards } =
      this.state

    let canPrint =
      map_section || stoplist_section || turnbyturn_section || student_cards

    if (!canPrint) {
      return false
    }

    this.setState({ spinning: true })

    let tzHeader = { headers: { tz: momentTzHelper().guess() } }

    let url = `/route-prints/pdf?date=${activeDate}&id=${string}&map_section=${
      map_section * 1
    }&stoplist_section=${stoplist_section * 1}&turnbyturn_section=${
      turnbyturn_section * 1
    }&student_cards=${student_cards * 1}`

    rq.get(url, tzHeader).then((response) => {
      if (response.status === 200) {
        const file = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)

        this.props.closeFn('print_trip', '')
      } else {
        this.props.closeFn('print_trip', '')
      }
    })
  }

  handleSave = () => {}

  render() {
    let closeFn = this.props.closeFn
    let errorDate = this.state.errorDate

    let {
      map_section,
      stoplist_section,
      turnbyturn_section,
      student_cards,
      spinning,
      activeDate,
    } = this.state
    let canPrint =
      map_section || stoplist_section || turnbyturn_section || student_cards

    return (
      <BaseModal
        title={i18n.t('Route Sheets')}
        closeFn={() => closeFn('print_trip', '')}
        save={this.saveBtn}
      >
        <Spin spinning={spinning} tip="Loading...">
          <div id="modalPrint" className="content">
            <div className="modal-body">
              <div
                className={
                  this.state.activeDate
                    ? 'dateBlock dpNotEmpty'
                    : 'dateBlock dpEmpty'
                }
              >
                <NewSingleDatePicker
                  getCalendarContainer={(trigger) => trigger.parentNode}
                  format={'L'}
                  className={`datePicker  ${errorDate ? 'errorDate' : ' '}`}
                  onChange={(t) => this.onChange('activeDate', t)}
                  defaultValue={moment().utc(true)}
                  suffixIcon={<CalendarIcon />}
                  clearIcon={<ClearIcon />}
                  divider
                />
              </div>
              <span className="text">
                <Trans>Select section(s) to print</Trans>
              </span>
              <div className="form-group">
                <NewCheckbox onChange={(e) => this.onChange('map_section', e)}>
                  <Trans>Map overview</Trans>
                </NewCheckbox>
                <br />
                <NewCheckbox
                  onChange={(e) => this.onChange('stoplist_section', e)}
                >
                  <Trans>Stop list</Trans>
                </NewCheckbox>
                <br />
                <NewCheckbox
                  onChange={(e) => this.onChange('turnbyturn_section', e)}
                >
                  <Trans>Turn-by-turn directions</Trans>
                </NewCheckbox>
                <br />
                <NewCheckbox
                  onChange={(e) => this.onChange('student_cards', e)}
                >
                  <Trans>Student cards</Trans>
                </NewCheckbox>
                <br />
              </div>
            </div>
          </div>

          <div className="center">
            <div className="buttons">
              <button
                className="cancel"
                onClick={() => closeFn('print_trip', '')}
              >
                <Trans>Cancel</Trans>
              </button>
              <button
                disabled={!canPrint}
                onClick={this.saveBtn}
                className={`save ${!canPrint ? 'blockBtn' : ''}`}
              >
                <Trans>Print</Trans>
              </button>
            </div>
          </div>
        </Spin>
      </BaseModal>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
