import { create } from 'zustand'
import { IBus } from '@/store/resources/resources.types'
export interface IBusesStore {
  filters: {
    id: string
    depot: null | number
    vinNumber: string
    busNumber: string
    capacityFrom: null | string
    capacityTo: null | string
    // capacity: null | number
    show: string
  }
  setFilter: (filters: Partial<IBusesStore['filters']>) => void
  setFilteredBuses: (filteredBuses: IBus[]) => void
  filteredBuses: IBus[]
}

export const initialBusesFilters = {
  id: '',
  depot: null,
  vinNumber: '',
  busNumber: '',
  capacityFrom: null,
  capacityTo: null,
  show: '',
}

export const useBusesStore = create<IBusesStore>((set, get) => ({
  filters: initialBusesFilters,
  filteredBuses: [],
  setFilteredBuses: (filteredBuses) => set({ filteredBuses }),
  setFilter: (filters: Partial<IBusesStore['filters']>) =>
    set({ filters: { ...get().filters, ...filters } }),
}))

export type FilterKeys = keyof IBusesStore['filters']
