import React, { Component } from 'react'
import Checkbox from 'antd/es/checkbox'
import Popover from 'antd/es/popover'

import * as fieldTripRequestsActions from '../../../../../redux/fieldTrip/requests/actions'
import { connect } from 'react-redux'
import _ from 'lodash'
import { NewCheckbox } from '../../../../../entities/ui/NewCheckbox/NewCheckbox'
import './index.less'

class BlockMore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      over: false,
      position: 'bottomLeft',
      use_default_rule: 0,
      curbside_pickup_only: 0,
    }
    this.Block = React.createRef()
  }

  onMouseMove() {
    this.setState({ over: false })
  }

  componentDidMount() {
    this.setState({
      use_default_rule: this.props.point.use_default_rule,
      curbside_pickup_only: this.props.point.curbside_pickup_only,
    })
  }

  HandleChecked = (e) => {
    let id = this.props.point.id
    let name = e.target.name
    let value = e.target.checked ? 1 : 0

    this.setState({
      [e.target.name]: value,
    })

    this.props.dispatch(
      fieldTripRequestsActions.editTripRulesPosint(id, name, value)
    )
  }

  handleEditStop = () => {
    this.props.handleEditStop(this.props.modalType, this.props.point)
  }

  handleAnchor = () => {
    this.props.handleAnchor(this.props.point.id)
  }

  handleDelete = () => {
    this.props.handleDelete(this.props.point.id)
  }

  onMouseEvent = (e) => {
    let position = { x: e.screenX, y: e.screenY }

    let ScreenY = window.innerHeight

    let diff = Math.abs(ScreenY - position.y)

    this.setState({
      position: diff < 116 ? 'topLeft' : 'bottomLeft',
    })
  }

  render() {
    const Data = (
      <div ref={this.Block} className="BlockMore">
        <a className="EditStop" onClick={this.handleEditStop}>
          Edit Stop
        </a>
        <a className="MarkStop" onClick={this.handleAnchor}>
          {this.props.point.is_anchor
            ? 'Unmark Stop as Anchor'
            : 'Mark Stop as Anchor'}
        </a>
        <a className="RemovefromRun" onClick={this.handleDelete}>
          Remove from Run
        </a>
        <div className="BlockInput">
          <label>
            <NewCheckbox
              name="use_default_rule"
              className={'checked'}
              onChange={(e) => this.HandleChecked(e)}
              checked={_.includes([this.state.use_default_rule], 1)}
              disa
              type="checkbox"
            />
            Use Routing Rules
          </label>
          <label>
            <NewCheckbox
              className={'checked'}
              name="curbside_pickup_only"
              value={this.state.curbside_pickup_only}
              onChange={(e) => this.HandleChecked(e)}
              checked={_.includes([this.state.curbside_pickup_only], 1)}
              disabled={_.includes([this.state.use_default_rule], 1)}
              type="checkbox"
            />
            Curbside Pickup Only
          </label>
        </div>
      </div>
    )
    return (
      <div className="moreContainer">
        {!this.props.isReadonly && (
          <Popover
            overlayClassName="morePopover"
            content={Data}
            onMouseEnter={this.onMouseEvent}
            onMouseLeave={this.onMouseEvent}
            placement={this.state.position}
            trigger="hover"
          >
            <span className="More ant-popover-open">
              <span className="round" />
              <span className="round" />
              <span className="round" />
            </span>
          </Popover>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return { fieldTripRequestsActions, dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockMore)
