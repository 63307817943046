import { NewModal } from '@/entities/ui/NewModal/NewModal'
import { ModalsEnum, useModalSelector } from '@/store/modal/ModalStore'
import { FormProvider, useForm } from 'react-hook-form'
import { FormInput } from '@/entities/ui/NewInput/FormInput'
import styles from './AddBusDepot.module.less'
import { Button } from '@/entities/ui/NewButton/NewButton'
import { IAddBusDepotForm } from '@/entities/modals/AddBusDepot/AddBusDepot.types'
import { useAddBusDepotMutation } from '@/store/resources/resources'
import { notification } from 'antd'
import { useEffect } from 'react'

export const AddBusDepot = () => {
  const { isOpen, onCloseModal } = useModalSelector(ModalsEnum.ADD_BUS_DEPOT)
  return isOpen ? (
    <_AddBusDepot isOpen={isOpen} onCloseModal={onCloseModal} />
  ) : null
}

const _AddBusDepot = ({
  isOpen,
  onCloseModal,
}: {
  isOpen: boolean
  onCloseModal: () => void
}) => {
  const methods = useForm<IAddBusDepotForm>({
    defaultValues: { address: '', name: '' },
  })
  const [request, response] = useAddBusDepotMutation()
  const onErrorAddress = () => {
    notification.error({ message: 'Address is not valid' })
    methods.setError('address', { message: 'Address is not valid' })
  }

  const onSubmit = (data: IAddBusDepotForm) => {
    request({ ...data, onErrorAddress })
  }

  useEffect(() => {
    const submit = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        methods.handleSubmit(onSubmit)()
      }
    }

    document.addEventListener('keydown', submit)
    return () => {
      document.removeEventListener('keydown', submit)
    }
  }, [])

  return (
    <NewModal
      title="Add a Bus Depot"
      width={420}
      open={isOpen}
      onClose={onCloseModal}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.root}>
          <FormInput
            rules={{
              required: {
                value: true,
                message: 'Bus Depot Name field is required',
              },
            }}
            wrapperClassName={styles.firstInput}
            label="Bus Depot Name"
            name="name"
            data-testid="depotNameInp"
          />
          <FormInput
            rules={{
              required: {
                value: true,
                message: 'Address field is required',
              },
            }}
            wrapperClassName={styles.secondInput}
            label="Address"
            name="address"
            data-testid="depotAddressInp"
          />
          <p className={styles.text}>
            This will be used as a starting point for your trips
          </p>
          <div className={styles.footer}>
            <Button onClick={onCloseModal} buttonColor="gray">
              Cancel
            </Button>
            <Button data-testid="saveBtn" isLoading={response.isLoading}>
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </NewModal>
  )
}
