import { useRef } from 'react'
import { useLazySendEmailQuery } from '@/store/routeTool/routeTool'
import { IEmailTemplate } from '@/store/routeTool/RouteToolTemplateApi/RouteToolTemplateApi.types'
import {
  useGetEmailTemplatesQuery,
  useUpdateEmailTemplateMutation,
} from '@/store/routeTool/RouteToolTemplateApi/RouteToolTemplateApi'
import { create } from 'zustand'

interface IStore {
  currentTemplate: IEmailTemplate | null
  setStore: (params: Partial<IStore>) => void
}

export const useParentState = create<IStore>((set, get) => ({
  currentTemplate: null,
  setStore: (params) => set({ ...get(), ...params }),
}))

export const useNewVariantParentEmailModalConfig = (arrayId) => {
  const editorRef = useRef(null)
  const templates = useGetEmailTemplatesQuery()

  const currentTemplate = useParentState((state) => state.currentTemplate)

  const handleSetTemplate = (template: IEmailTemplate) => {
    const state = useParentState.getState()
    state.setStore({ currentTemplate: template })
    editorRef.current.setContent(template.text ?? '')
  }

  const [onSendRequest, sendResponse] = useLazySendEmailQuery()

  const onSend = (customSubject: string) => {
    const template = editorRef.current.getContent()
    const ids = arrayId
    const requestData = {
      ids,
      template,
      customSubject,
    }
    onSendRequest(requestData)
  }

  const [onUpdate, updateResponse] = useUpdateEmailTemplateMutation()

  const onSave = () => {
    onUpdate({
      id: currentTemplate.id,
      text: editorRef.current.getContent(),
      title: currentTemplate.title,
    })
  }

  const getEditorContent = () => editorRef.current.getContent()
  const onInit = (editor) => {
    editorRef.current = editor
  }

  const isLoading = sendResponse.isFetching || templates.isFetching

  const isSave = updateResponse.isLoading
  const isSend = sendResponse.isFetching

  return {
    isLoading,
    onSend,
    onSave,
    onInit,
    handleSetTemplate,
    currentTemplate,
    initLoading: templates.isFetching,
    isSave,
    isSend,
    getEditorContent,
    isShowSaveButton: (templates?.data?.data?.length ?? 0) > 0,
  }
}
